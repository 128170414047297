import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import ActivePriceTableQueryGql from './ActivePriceTableQueryGql.graphql';
import ModeloVendasQueryGql from './ModeloVendasQueryGql.graphql';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import { LoadingContained } from '../../../utils/Loading';

export const ActivePriceTableQuery = graphql(ActivePriceTableQueryGql, {
  name: 'activePriceTable',
  options: ({ match, idOportunidade }) => ({
    variables: {
      idOportunidade: idOportunidade ?? match?.params?.idOportunidade,
    },
  }),
  skip: ({ getOpportunitySegmento: { oportunidade } = {} }) =>
    !oportunidade?.utilizaTabelaPreco,
});

export const ActivePriceTableQueryHoc = compose(
  ActivePriceTableQuery,
  displayLoadingState({
    name: 'activePriceTable',
    onCacheLoadOnlyQueryName: 'tabelasPreco',
    LoadingComponent: LoadingContained,
  })
);

export const ModeloVendasQuery = graphql(ModeloVendasQueryGql, {
  options: ({ match = null, idOportunidade }) => ({
    variables: {
      id: idOportunidade || match.params.idOportunidade,
    },
  }),
  skip: props => {
    const { getIdTabelaPreco } = props;

    return !!getIdTabelaPreco();
  },
  name: 'modelosVenda',
});

export const AnosModeloQueryGql = gql`
  query AnosModeloQuery {
    anosModelo {
      anoModelo
      modelosIds
    }
  }
`;

export const AnosModeloQuery = graphql(AnosModeloQueryGql, {
  name: 'anosModelo',
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
});
