import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const VENDEDORES_ATIVOS_CRM_GQL = gql`
  query vendedoresAtivosIndicadoresQuery {
    vendedoresAtivosIndicadores {
      id
      nome
      dealersGrupo {
        id
      }
    }
  }
`;

export const useVendedoresAtivosCRM = () => {
  const { data = {} } = useQuery(VENDEDORES_ATIVOS_CRM_GQL, {
    fetchPolicy: 'cache-first',
  });
  const { vendedoresAtivosIndicadores = [] } = data;

  return { vendedoresAtivos: vendedoresAtivosIndicadores };
};
