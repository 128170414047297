import { makeStyles } from '@material-ui/styles';
import {
  cardGreen,
  cardGreenDark,
  cardOrange,
  cardRedDark,
  defaultGrey,
} from '../../../../../../app/globalStyle';
import { cardStyles } from '../../../../../../Home/ActivityCard/activityCardFunctions';

export const useExecellenceClubCardStyles = makeStyles(theme => ({
  subCardTitle: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  subCardTitleSub: {
    marginLeft: theme.spacing(1),
    color: 'black',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  subCardSubTitle: {
    color: 'gray',
    fontSize: '11px',
  },
  percentage: {
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
    marginTop: -12,
  },
  subCard: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '10px',
    border: '1px solid lightGray',
    borderTop: 'transparent',
    borderRight: 'transparent',
    borderBottom: 'transparent',
    paddingLeft: 10,
  },
  colorPrimary: {
    backgroundColor: props =>
      props &&
      props.valorIndicadorExcellenceClub &&
      props.valorIndicadorExcellenceClub > 70
        ? cardGreen
        : cardOrange,
  },
  barColorPrimary: {
    backgroundColor: props =>
      props &&
      props.valorIndicadorExcellenceClub &&
      props.valorIndicadorExcellenceClub > 70
        ? cardGreenDark
        : cardRedDark,
  },
  chipTitle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    fontWeight: 500,
  },
  timeSelect: {
    fontSize: '12px',
  },
  marca: {
    color: defaultGrey,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  valueSliderContainer: {
    margin: theme.spacing(2),
  },
  porcentagemSliderContainer: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  ...cardStyles(theme),
}));
