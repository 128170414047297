import React from 'react';
import { ThemeProvider } from '@tecsinapse/ui-kit';
import { disableBackgroundColor } from '../components/app/globalStyle';

export const AppThemeProvider = ({ children }) => (
  <ThemeProvider
    spacing={8}
    variant="greyLight"
    overrides={{
      spacing: factor => `${10 * factor}px`,
      MuiButton: {
        sizeLarge: {
          height: '56px',
        },
      },
      MuiCollapse: {
        entered: {
          height: 'auto',
          overflow: 'visible',
        },
      },
      MuiGrid: {
        item: {
          padding: '8px',
        },
        'spacing-xs-8': {
          padding: '8px',
        },
      },
      MuiChip: {
        label: {
          fontWeight: 600,
        },
      },
      MuiToggleButton: {
        root: {
          border: 'none',
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$disabled': {
            color: '#ffd08acc',
          },
        },
        track: {
          '$disabled$disabled + &': {
            opacity: 1.0,
            backgroundColor: disableBackgroundColor,
          },
        },
      },
    }}
  >
    {children}
  </ThemeProvider>
);
