import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { ProposalApprovalMutation } from '../../opportunity/data/mutations/OpportunityMutations';
import { getAlertContext } from '../../utils/alertContext';
import { RemoveHOC } from '../../utils/crud/RemoveHOC';
import { displayLoadingState } from '../../utils/displayLoadingState';
import { getLoadingSpinnerContext } from '../../utils/loadingSpinnerContext';
import { ProposalPaymentMethods } from './ProposalPaymentMethods';
import { graphqlOfflineMutation } from '../../utils/graphql/graphqlOfflineMutation';
import GetProposalPaymentMethodsGql from './GetProposalPaymentMethodsGql.graphql';
import removerFormaPagamentoGql from './removerFormaPagamentoGql.graphql';

const mutation = graphqlOfflineMutation(removerFormaPagamentoGql, {
  name: 'removerFormaPagamento',
  mutationName: 'removerFormaPagamentoGql',
});

const withQuery = graphql(GetProposalPaymentMethodsGql, {
  options: ({ idProposta }) => ({
    variables: {
      id: idProposta || 0,
    },
  }),
});

const enhance = compose(
  withRouter,
  getLoadingSpinnerContext,
  ProposalApprovalMutation,
  withQuery,
  RemoveHOC({ mutation, mutationName: 'removerFormaPagamento' }),
  displayLoadingState({ onCacheLoadOnlyQueryName: 'proposta' }),
  getAlertContext,
  withHandlers({
    onEditPayment: ({ history, idOportunidade, idProposta }) => ({
      id: idPayment,
    }) => {
      history.push(
        `/oportunidade/${idOportunidade}/pagamentos/${idProposta}/editar/${idPayment}`
      );
    },
    goToCreation: ({ history, idProposta, idOportunidade }) => () => {
      history.push(
        `/oportunidade/${idOportunidade}/pagamentos/${idProposta}/editar/`
      );
    },
  })
);

export const ProposalPaymentMethodsContainer = enhance(ProposalPaymentMethods);
