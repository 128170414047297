import Sentry from '../../../core/SentryWrapper';

/* eslint no-console: 0 */
export const Logger = {
  error(error) {
    console.log(error);
    // eslint-disable-next-line
    Sentry.captureException(new Error(error));
  },
};
