import CircularProgressbar from 'react-circular-progressbar';
import { Chip, Typography } from '@material-ui/core';
import { formatMoney } from '@tecsinapse/es-utils/build/money';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { flexDivStyle, oportunidadeCircularStyles } from '../indicadoresStyles';
import { getColorOfTemperatura } from '../../../../utils/cardStyleUtils';
import { printPorcentagemMaxima } from '../IndicadoresCardFunctions';
import { stylesOfCirularProgressbar } from '../indicadoresStyleUtils';

const useStyles = makeStyles(oportunidadeCircularStyles);

export const OportunidadeCircularProgressbar = React.memo(
  ({ quantidade, temperatura, temperaturaNome, valor, onClick }) => {
    const mainColor = getColorOfTemperatura(temperatura);
    const classes = useStyles();

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={classes.circularTinySize}
        style={{ ...flexDivStyle }}
        onClick={onClick}
      >
        <CircularProgressbar
          background
          percentage={100}
          text={`${printPorcentagemMaxima(quantidade)}`}
          strokeWidth={10}
          styles={stylesOfCirularProgressbar(mainColor)}
        />

        <Typography
          variant="subtitle2"
          classes={{
            root: classes.typographyMargin,
          }}
        >
          Oportunidade(s)
        </Typography>

        <Chip
          label={temperaturaNome}
          classes={{
            root: classes.chipMargin,
          }}
          style={{
            backgroundColor: mainColor,
          }}
        />
        <Typography
          variant="caption"
          classes={{
            root: classes.typographyTinyyMargin,
          }}
        >
          {formatMoney(valor)}
        </Typography>
      </div>
    );
  }
);
