import Icon from '@mdi/react';
import { BadgeIndicator } from 'components/BadgeIndicator';
import { Timer } from 'components/Timer';
import React from 'react';
import { useHotleadCountDownHook } from './HotleadCountDownHook';

export const HotleadCountDown = ({ atividade, renderizar }) => {
  const {
    icon,
    timerAtivo,
    badgeColor,
    contentColor,
    tempoRestante,
    descricaoAposTerminoTempo,
    handleCountDownOver,
  } = useHotleadCountDownHook(atividade);

  if (renderizar && timerAtivo) {
    return (
      <BadgeIndicator
        badgeColor={badgeColor}
        contentColor={contentColor}
        badge={<Icon path={icon} color="white" size={0.7} />}
        label={
          <Timer
            remainingSeconds={tempoRestante}
            label={descricaoAposTerminoTempo}
            onCountDownOver={handleCountDownOver}
          />
        }
      />
    );
  }

  return null;
};
