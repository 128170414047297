export default `
    id
    dataHora
    dataHoraExibicao
    dataHoraRealizada
    finalizado
    tipo
    tipoOrigem
    idOrigem
    descricao
    avulsa
    statusOportunidade
    isReagendado
    atrasada
    temperatura
    clienteNome
    responsavel
    naoPermiteNegocioFechadoOuNegocioPerdido
    tipoOrigemOportunidade
    resultadoProspeccao
    prospeccao {
        id
        status
        __typename
    }
    hotLeadCountDownInfo {
        onTime
        remainingSeconds
        unavailable
        deadline
        __typename
    }
    unidadeNegocio {
        id
        descricao
        tipoVenda
        segmento {
            id
            descricaoNormalizado
        }
        __typename
    }
    cliente {
        id
        nome
        email
        cpfCnpj
        telefone
        celular
        telefoneComercial
        idDealer
        podeRealizarAtividadeFichaVisita
        ultimaAtividadeFichaVisitaAbertaPk
        consultorExternoPk
        divisaoRegional {
            id
            nome
        }
        endereco {
            id
            cep
            logradouro
            bairro
            numero
            complemento
            cidade
            estado {
            id
            nome
            sigla
        }
    }
    contatos {
        id
        nome
        telefone
        celular
        email
        cargo
        setor
        aceitaReceberEmailCam
        idCliente
        idSegmento
        __typename
    }
    clienteFrota {
        id
        anoFabricacao
        anoModelo
        segmento {
            id
            descricao
            subSegmentos {
                id
                descricao
                ativo
                segmento {
                    id
                }
            }
            __typename
        }
        subSegmento {
            id
            descricao
        }
        marca {
            id
            nome
        }
        modelo {
            id
            nome
        }
        quantidade
        anoFabricacao
        anoModelo
        veiculos {
            id
        }
        __typename
    }
    unidadesNegocioAtendidos {
        id
        descricao
    }
    __typename
    }
    __typename
`;
