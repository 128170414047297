import { Typography } from '@material-ui/core';
import { IconButton } from '@tecsinapse/ui-kit';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { Tunnel } from 'react-tunnels';
import { saveMutationOffline } from 'core/offline/offlineHelpers/offlineMutationHelpers';
import { ErrorOffline } from 'core/offline/exception/ErrorOffline';
import { useGlobalStyle } from '../app/globalStyle';
import { Logger } from './log/logUtils';

export const updateAppTitle = (
  title,
  updateDocumentTitle = true,
  Component
) => {
  if (document) {
    document.title =
      title && updateDocumentTitle
        ? `${title} | ${process.env.REACT_APP_NAME}`
        : process.env.REACT_APP_NAME;
  }

  if (Component) {
    return (
      <Tunnel id="app-title">
        <Component />
      </Tunnel>
    );
  }

  const style = {
    display: 'inline-block',
    flex: 1,
    fontWeight: 900,
    textTransform: 'capitalize',
  };

  return (
    <Tunnel id="app-title">
      <Typography
        variant="subtitle1"
        color="inherit"
        className="app-header-name"
        style={style}
      >
        {title || process.env.REACT_APP_NAME}
      </Typography>
    </Tunnel>
  );
};

export const updateAppCenter = Component => {
  const exibeTitulo = process.env.REACT_APP_SHOW_APP_TITLE === 'true';
  const style = {
    display: 'inline-block',
    flex: 1,
    fontWeight: 900,
    textTransform: 'capitalize',
  };
  const style1 = { marginRight: 'auto' };

  return (
    <Tunnel id="app-center">
      {Component ? (
        <Component />
      ) : (
        <div style={style1}>
          {
            <Typography
              variant="subtitle1"
              color="inherit"
              className="app-header-name"
              style={style}
            >
              {exibeTitulo && process.env.REACT_APP_NAME}
            </Typography>
          }{' '}
        </div>
      )}
    </Tunnel>
  );
};

export const RenderAppLogo = ({
  show = process.env.REACT_APP_SHOW_LOGO_ON_INTERNAL_PAGES === 'true',
  logoFileName = 'logo.png',
}) => {
  const style = {
    maxHeight: '30px',
    marginRight: '8px',
  };

  return (
    <Tunnel id="toolbar-logo">
      {show && (
        <img
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_MANIFEST_AND_ICONS_FOLDER}/images/${logoFileName}`}
          alt="logo"
          style={style}
        />
      )}
    </Tunnel>
  );
};

export function ShowBackButton({
  action,
  history,
  IconComponent = ArrowBack,
} = {}) {
  const classes = useGlobalStyle();

  return (
    <Tunnel id="toolbar-left-icon">
      <IconButton
        id="show-back-button"
        classes={{ root: classes.iconBackButton }}
        onClick={action || history.goBack}
        color="inherit"
      >
        <IconComponent />
      </IconButton>
    </Tunnel>
  );
}
export const showRightIcon = ({
  action,
  history,
  route,
  component,
  componentRight = null,
  menuRight = null,
  menuInner = null,
  actionRight,
} = {}) => (
  <Tunnel id="toolbar-right-icon">
    {menuInner || (
      <IconButton
        style={{}}
        onClick={action || (() => history.push(route))}
        color="inherit"
      >
        {component}
      </IconButton>
    )}

    {componentRight && (
      <IconButton style={{}} onClick={actionRight} color="inherit">
        {componentRight}
      </IconButton>
    )}

    {menuRight && menuRight}
  </Tunnel>
);

export const updateAppBarBottom = component => (
  <Tunnel id="app-bar-bottom">{component}</Tunnel>
);

export const resetAppTitle = () => updateAppTitle(process.env.REACT_APP_NAME);

export function getErrorMessage(graphQLErrors = []) {
  const isHomologOrDev = process.env.REACT_APP_KC_ENV_TYPE === 'staging';

  return (
    Array.isArray(graphQLErrors) &&
    (graphQLErrors.map(e => e.message).join('\n') ||
      `Erro de Conexão ${
        isHomologOrDev ? process.env.REACT_APP_GRAPHQL_URL : ''
      }`)
  );
}

export function getErrorTitle(graphQLErrors = []) {
  return (
    Array.isArray(graphQLErrors) &&
    (graphQLErrors
      .map(e => (e.extensions && e.extensions.title ? e.extensions.title : ''))
      .join('\n') ||
      `Erro!`)
  );
}

export const mutationsErrorsHandler = ({
  showAlert,
  hideLoading,
  mutationName = null,
  variables = null,
  description = null,
  chip = false,
}) => ({ graphQLErrors = [] } = {}) => {
  if (navigator.onLine) {
    Logger.error(JSON.stringify(graphQLErrors));
  }

  const message = getErrorMessage(graphQLErrors);

  const title = getErrorTitle(graphQLErrors);

  if (hideLoading) {
    hideLoading();
  }

  if (graphQLErrors instanceof ErrorOffline && description !== null) {
    saveMutationOffline({
      showAlert,
      mutationName,
      entityName: graphQLErrors.entityName,
      savedEntityName: graphQLErrors.savedEntityName,
      idName: graphQLErrors.idName,
      variables,
      description,
    });
  } else {
    const params = { message, title };

    if (chip) {
      params.chip = true;
      params.variant = 'error';
    }

    showAlert(params);
  }
};
