import moment from 'moment';
import { isEmptyOrNull, omitDeep } from '@tecsinapse/es-utils/build';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import { getAlertContext } from '../utils/alertContext';
import { getLocalDateTimeString } from '../utils/dateUtils';
import {
  displayErrorState,
  displayLoadingState,
} from '../utils/displayLoadingState';
import { validateRequired } from '../utils/FormUtils';
import { graphqlOfflineMutation } from '../utils/graphql/graphqlOfflineMutation';
import { reduxFormAutoFocus } from '../utils/reduxFormAutoFocus';
import reagendarAtividadeMutationGql from './reagendarAtividadeMutationGql.graphql';
import getAtividadeReagendamentoByIdQueryGql from './getAtividadeReagendamentoByIdQueryGql.graphql';
import {
  eventReescheduleActivity,
  eventSaveNextActivityDate,
} from '../utils/GAUtils';

export const reagendarAtividadeMutation = graphqlOfflineMutation(
  reagendarAtividadeMutationGql,
  {
    name: 'reagendarAtividade',
    mutationName: 'reagendarAtividadeMutationGql',
  }
);

export const getAtividadeByIdQuery = graphql(
  getAtividadeReagendamentoByIdQueryGql,
  {
    skip: ({ idAtividade = null, dataHoraReagendamento }) =>
      isNotUndefOrNull(dataHoraReagendamento) || !isNotUndefOrNull(idAtividade),
    options: ({ idAtividade }) => ({
      variables: {
        id: idAtividade || 0,
        nullId: isEmptyOrNull(idAtividade),
      },
    }),
  }
);
const formName = 'ReagendarAtividadeForm';

const mapStateToProps = undefined;

function mapDispatchToProps(dispatch) {
  return {
    resetForm: () => dispatch(reset(formName)),
  };
}

export const enhanceReagendarAtividade = from =>
  compose(
    displayErrorState,
    withRouter,
    withProps(({ idAtividade, match }) => ({
      idAtividade: idAtividade ?? match?.params?.id,
    })),
    getAtividadeByIdQuery,
    displayLoadingState(),
    reagendarAtividadeMutation,
    withProps(({ dataHoraReagendamento, idAtividade, data }) => ({
      initialValues: {
        dataHoraReagendamento:
          (dataHoraReagendamento && moment(dataHoraReagendamento)) ||
          (data?.atividade && moment(data.atividade.dataHora)) ||
          moment(),
        idAtividade,
      },
    })),
    getAlertContext,
    connect(mapStateToProps, mapDispatchToProps),
    reduxFormAutoFocus({
      form: formName,
      validate: validateRequired(['idAtividade', 'dataHoraReagendamento']),
      enableReinitialize: true,
      onSubmit: (
        values,
        dispatch,
        { reagendarAtividade, history, location: { state } }
      ) => {
        let idAtividade = null;
        let isOportuniadeIcompleta = false;
        let urlRetorno = null;

        if (state && state.pageState) {
          idAtividade = state.pageState.idAtividadeRealizada;
          isOportuniadeIcompleta = state.pageState.oportunidadeIncompleta;
          urlRetorno = state.pageState.urlRetorno;
        }

        const submitValues = omitDeep(values, '__typename');

        const dataHoraReagendamento = getLocalDateTimeString(
          values.dataHoraReagendamento
        );

        const optimisticResponse = {
          __typename: 'Mutation',
          reagendarAtividade: {
            __typename: 'Atividade',
            id: values.idAtividade,
            dataHora: dataHoraReagendamento,
            isReagendado: true,
          },
        };

        eventReescheduleActivity(from);

        return reagendarAtividade({
          variables: { ...submitValues, dataHoraReagendamento },
          optimisticResponse,
          chip: true,
        }).then(() => {
          eventSaveNextActivityDate();

          if (isOportuniadeIcompleta) {
            if (urlRetorno) {
              history.push(`/realizarAtividade/${idAtividade}/${urlRetorno}`, {
                selectedPage: 2,
              });
            } else {
              history.push(`/realizarAtividade/${idAtividade}`, {
                selectedPage: 2,
              });
            }
          } else if (urlRetorno) {
            history.push(`/${urlRetorno}`, { tab: 1 });
          } else {
            history.push('/agenda/reload');
          }
        });
      },
    })
  );
