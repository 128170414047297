import React from 'react';
import { Snackbar } from '@material-ui/core/index';
import { ShowBackButton, updateAppTitle, RenderAppLogo } from '../uiUtils';

export const Error = ({ history }) => (
  <>
    <RenderAppLogo />
    <ShowBackButton history={history} />
    {updateAppTitle('Voltar')}
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span id="message-id">
          Um erro ocorreu! Verifique sua conexão com a internet.
        </span>
      }
    />
  </>
);
