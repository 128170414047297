import gql from 'graphql-tag';

export const modeloInteresseInativoGraql = gql`
  query GetOpportunityVehicles($id: ID!) {
    oportunidade(id: $id) {
      id
      status
      tipoModeloInteresse
      propostas {
        id
        propostaUrl
        valorTotalVeiculosNegociados
        status {
          id
          podeEditarProposta
          podeEditarPrecoUnitarioModelo
          podeSolicitarAprovacao
          podeImprimirProposta
        }
        formasPagamento {
          id
          percentual
          valor
          numeroParcelas
        }
        __typename
      }
      modelosInteresse {
        id
        anoFabricacao
        anoModelo
        precoUnitario
        emNegociacao
        precoTotal
        modeloInteresseInativo
        marca {
          id
          nome
        }
        modelo {
          id
          nome
          ativo
        }
        familia {
          id
          nome
          ativo
        }
        versao {
          id
          nome
          ativo
        }
        __typename
      }
      __typename
    }
  }
`;
