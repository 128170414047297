import Accordion from '@material-ui/core/Accordion';
import {
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Chip,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { isNotEmptyOrNull } from '@tecsinapse/es-utils/build';
import FaceIcon from '@material-ui/icons/Face';

import {
  defaultGreen,
  defaultOrange,
  defaultRed,
  globalBackgroundColor,
  globalStyle,
} from '../../app/globalStyle';
import { ConseguiContato } from './ConseguiContato';
import { InformacoesInvalidas } from './InformacoesInvalidas';
import { LeadButton } from './LeadButton';
import { NaoConseguiContato } from './NaoConseguiContato';
import { withTextTransform } from '../../utils/ui/withTextTransform';
import {
  eventClientContactedProspection,
  eventClientInvalidInformationProspection,
  eventClientNotContactedProspection,
} from '../../utils/GAUtils';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionPanelNoPadding: {
    padding: 0,
  },
  content: {
    paddingRight: 0,
    ':last-child': {
      paddingRight: 0,
    },
  },
  expansionPanelSummaryRoot: {
    padding: theme.spacing(2),
  },
  expansionPanelDetailsRoot: {
    padding: 0,
  },
  contentJustify: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 0,
  },
  container: {
    '& div': { overflow: 'visible' },
  },
  toggleContainer: {
    width: '100%',
    height: 56,
    padding: `0 !important`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing(1)}px 0`,
  },
  button: {
    borderRadius: '3px',
    border: 0,
    color: 'white',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
  },
  expasionPanelSummary: {
    display: 'flex',
    padding: '0',
  },
  expansionPanelDetails: {
    flexDirection: 'column',
    paddingTop: '30px',
    overflow: 'visible',
  },
  toggleButtonGroup: {
    width: '100%',
    display: 'flex',
    margin: 0,
  },
  clientChip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  marginTopZeroPanel: {
    marginTop: '0',
    backgroundColor: globalBackgroundColor,
  },
  ...globalStyle(theme),
});

const styleButton = { flexGrow: 1, margin: 1, border: 0 };
const style = { ...styleButton, paddingRight: 0 };
const style1 = { boxShadow: 'none', padding: 0 };

const enhance = compose(
  withStyles(styles),
  withState('expanded', 'setExpanded', null),
  withState('alignment', 'setAlignment', null),
  withTextTransform({
    formName: 'RealizarAtividadeForm',
    fields: ['justificativa', 'observacao'],
  }),
  withHandlers({
    handleChange: ({ setExpanded }) => panel => {
      setExpanded(panel);
    },
  })
);

export const ContactLeadPanel = enhance(
  ({
    classes,
    expanded,
    handleChange,
    alignment,
    setAlignment,
    clienteNome,
    ...props
  }) => {
    return (
      <>
        {clienteNome && (
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.clientChip}>
                <Chip
                  avatar={
                    <Avatar>
                      <FaceIcon />
                    </Avatar>
                  }
                  label={clienteNome}
                />
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12} className={classes.expansionPanelNoPadding}>
            <Accordion
              expanded={isNotEmptyOrNull(expanded)}
              classes={{
                root: classes.marginTopZeroPanel,
                expanded: classes.overflow,
              }}
              style={style1}
            >
              <AccordionSummary
                classes={{
                  root: classes.expansionPanelSummaryRoot,
                  content: classes.contentJustify,
                }}
              >
                <div className={classes.toggleContainer}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    className={classes.toggleButtonGroup}
                    onChange={(e, v) => setAlignment(v)}
                  >
                    {/* esses flexGrow tem que ficar aqui mesmo */}
                    <LeadButton
                      color={defaultGreen}
                      selected={expanded === 'panel1'}
                      onClick={() => {
                        eventClientContactedProspection();
                        handleChange('panel1');
                      }}
                      title={
                        <>
                          Conseguiu <br /> contato{' '}
                        </>
                      }
                      value="left"
                      style={styleButton}
                      icon={
                        <i className="fa fa-thumbs-up" aria-hidden="true" />
                      }
                    />
                    <LeadButton
                      color={defaultOrange}
                      selected={expanded === 'panel2'}
                      onClick={() => {
                        eventClientNotContactedProspection();
                        handleChange('panel2');
                      }}
                      title={
                        <>
                          Não conseguiu <br /> contato{' '}
                        </>
                      }
                      value="center"
                      style={styleButton}
                      icon={
                        <i className="fa fa-thumbs-down" aria-hidden="true" />
                      }
                    />
                    <LeadButton
                      color={defaultRed}
                      selected={expanded === 'panel3'}
                      onClick={() => {
                        eventClientInvalidInformationProspection();
                        handleChange('panel3');
                      }}
                      title="Informações Inválidas"
                      value="right"
                      style={style}
                      icon={<i className="fa fa-ban" aria-hidden="true" />}
                    />
                  </ToggleButtonGroup>
                </div>
              </AccordionSummary>

              <AccordionDetails
                classes={{
                  root: classes.expansionPanelDetailsRoot,
                }}
              >
                {expanded === 'panel1' && <ConseguiContato {...props} />}
                {expanded === 'panel2' && <NaoConseguiContato {...props} />}
                {expanded === 'panel3' && <InformacoesInvalidas {...props} />}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </>
    );
  }
);
