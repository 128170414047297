import { useState } from 'react';

export const useLeadsSemTratamentoDialogHook = () => {
  const [dialogOpened, setDialogOpened] = useState(false);

  return {
    dialogOpened,
    setDialogOpened,
  };
};
