/* eslint no-console: 0 */
import { omitDeep } from '@tecsinapse/es-utils/build';
import localforage from 'localforage';
import ReactDOM from 'react-dom';
import React from 'react';
import { PendingOfflineMutationsSelect } from '../ui/PendingOfflineMutationsSelect';
import { mutationsList } from '../../../components/utils/mutationsList';
import { Logger } from '../../../components/utils/log/logUtils';
import { store } from '../../../index';
import { newAgendaKeyAction } from '../../../reducers/home';
import { removeEntityId } from '../../../reducers/offlineMutationsEntityId';

export async function renderOfflineMutationUI({
  client,
  offlineButton = false,
}) {
  const offlineMutationsLenght = (
    (await localforage.getItem('offlineMutations')) || []
  ).length;

  if (!offlineButton && (offlineMutationsLenght <= 0 || !navigator.onLine)) {
    return;
  }

  ReactDOM.unmountComponentAtNode(document.getElementById('offlineMutations'));
  ReactDOM.render(
    <PendingOfflineMutationsSelect
      lastUpdateTime={store.getState().offlineBadge.lastUpdated}
      online={navigator.onLine}
      runMutationsFunc={selectedOfflineMutations =>
        restoreMutations({ client, offlineMutations: selectedOfflineMutations })
      }
    />,
    document.getElementById('offlineMutations')
  );
}

/**
 * Executa as mutations armazenadas no localforage no parametro {offlineMutations}
 *
 * @param {client} Cliente do apollo
 * @returns console.log das mutations realizadas com sucesso
 */
export const restoreMutations = async ({ client, offlineMutations }) => {
  const allOfflineMutations =
    (await localforage.getItem('offlineMutations')) || [];

  console.log('online');

  const failedMutations = [];
  const failedMutationsMessages = new Array(offlineMutations.length);

  return Promise.all(
    (offlineMutations || []).map(({ mutationName, variables }, index) => {
      console.log(`Realizando mutation ${mutationName} após retorno online`);
      const mutation = mutationsList[mutationName];

      return client.mutate({ mutation, variables }).catch(e => {
        let errorType = 'Erro';

        let message = e;

        if (navigator.onLine) {
          const { graphQLErrors } = e;

          Logger.error(JSON.stringify(graphQLErrors));
          message =
            (graphQLErrors || []).map(e2 => e2.message).join('\n') ||
            'Erro conexão';
          errorType = 'Exception';
        }
        console.log(`${errorType} ao realizar a Mutation ${mutationName} ${e}`);
        failedMutations.push(index);
        failedMutationsMessages[index] = message;
      });
    })
  ).then(() => {
    const newMutations = (offlineMutations || []).reduce(
      (filtered, val, index) => {
        if (index in failedMutations) {
          filtered.push({
            failedMessage: failedMutationsMessages[index],
            ...omitDeep(val, 'failedMessage'),
          });
        }

        return filtered;
      },
      []
    );

    const mutationsDone = (allOfflineMutations || []).filter(
      (val, index) => !(index in failedMutations)
    );

    mutationsDone.forEach(mutation => {
      const {
        savedEntityName,
        entityName,
        idName,
        mutationName,
        variables,
      } = mutation;

      if (entityName != null && idName != null) {
        return store.dispatch(
          removeEntityId({
            id: variables[entityName][idName],
            mutationName,
            entityName: savedEntityName,
          })
        );
      }

      return true;
    });

    store.dispatch(newAgendaKeyAction());

    return localforage.setItem(
      'offlineMutations',
      newMutations.map(mutation => ({
        failed: true,
        secondary: `Falha na sincronização: ${mutation.failedMessage}`,
        ...omitDeep(mutation, 'secondary'),
      }))
    );
  });
};

/**
 * Salva mutations e variaveis no localforage para ser executado posteriormente
 *
 * @param {client} Cliente do apollo
 * @returns console.log das mutations salvas
 */
export const saveMutationOffline = ({
  showAlert,
  mutationName,
  entityName,
  idName,
  savedEntityName,
  description,
  variables,
}) => {
  showAlert({
    message:
      'Offline - Ação pendente. Conecte-se novamente para a ação ser finalizada',
    variant: 'warning',
    chip: true,
  });
  localforage.ready().then(() =>
    localforage.getItem('offlineMutations').then(async offlineMutations => {
      const newOfflineMutations = offlineMutations || [];
      const descriptionPromise = await description;

      newOfflineMutations.push({
        mutationName,
        description: descriptionPromise,
        variables,
        savedEntityName,
        entityName,
        idName,
      });

      localforage
        .setItem('offlineMutations', newOfflineMutations)
        .then(array => {
          console.log('Mutation salva');
          console.log(array);
        });
    })
  );
};
