import React from 'react';
import { ThumbDown } from '@material-ui/icons';
import { EmptyState } from '@tecsinapse/ui-kit';
import { componentePorTipo } from './excellenceClubListingFunctions';

export const ExcellenceClubListing = ({
  loading,
  entidades,
  componente,
  entidadeProps,
}) => {
  const totalVendedores = entidades && entidades.length;
  const { Component, ordenacaoPadraoLista } = componentePorTipo(componente);

  return (
    <>
      {totalVendedores > 0 ? (
        entidades.sort(ordenacaoPadraoLista).map((entidade, index) => {
          return (
            <Component
              key={entidade.nomeUsuario}
              {...entidade}
              {...entidadeProps}
              colocacao={index + 1}
              totalVendedores={totalVendedores}
            />
          );
        })
      ) : (
        <>
          {!loading && (
            <EmptyState
              IconComponent={ThumbDown}
              message="Não há nenhuma atividade por aqui ainda."
            />
          )}
        </>
      )}
    </>
  );
};
