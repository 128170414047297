import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import { LoadingContained } from '../../../utils/Loading';
import GetOpportunitySegmentoGql from './GetOpportunitySegmento.graphql';

export const GetOpportunitySegmento = compose(
  graphql(GetOpportunitySegmentoGql, {
    name: 'getOpportunitySegmento',
    options: ({ match, idOportunidade }) => {
      const id = idOportunidade ?? match?.params?.idOportunidade;

      return {
        variables: {
          id,
        },
      };
    },
    skip: ({ match, idOportunidade }) => {
      const id = idOportunidade ?? match?.params?.idOportunidade;

      return !id;
    },
  }),
  displayLoadingState({
    name: 'getOpportunitySegmento',
    LoadingComponent: LoadingContained,
  })
);
