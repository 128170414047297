import gql from 'graphql-tag';
import { withQuery } from '../../../utils/crud/withGenericQuery';

export const tipoOrigemOportunidadeQueryGql = gql`
  query tipoOrigemOportunidadeQuery {
    tipoOrigemOportunidade {
      id
      descricao
    }
  }
`;

export const withTipoOrigemOportunidade = withQuery({
  name: 'tipoOrigemOportunidade',
  queryPropsName: 'tipoOrigemOportunidade',
  gqlInput: tipoOrigemOportunidadeQueryGql,
  options: {
    fetchPolicy: 'cache-first',
  },
  skip: null,
});
