import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import MetodosPagamentoAtivosQueryGql from './MetodosPagamentoAtivosQueryGql.graphql';
import { displayLoadingState } from '../../../../../utils/displayLoadingState';

export const MetodosPagamentoAtivosQuery = graphql(
  MetodosPagamentoAtivosQueryGql,
  {
    name: 'metodosPagamentoAtivos',
  }
);

export const MetodosPagamentoAtivosQueryHOC = compose(
  MetodosPagamentoAtivosQuery,
  displayLoadingState({
    name: 'metodosPagamentoAtivos',
    onCacheLoadOnlyQueryName: 'metodosPagamentoAtivos',
  })
);
