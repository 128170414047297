import { useEffect, useState } from 'react';

export function useAtualizarModeloInativoState(modelosInativosInput) {
  const [current, setCurrent] = useState(0);
  const [veiculos] = useState(modelosInativosInput);
  const naoPodeExecutarAcao = modelosInativosInput.length > 0;
  const [
    openAtualizaModeloInativoDialog,
    setOpenAtualizaModeloInativoDialog,
  ] = useState(naoPodeExecutarAcao);

  useEffect(() => setOpenAtualizaModeloInativoDialog(naoPodeExecutarAcao), [
    naoPodeExecutarAcao,
  ]);

  return {
    current,
    setCurrent,
    veiculos,
    naoPodeExecutarAcao,
    openAtualizaModeloInativoDialog,
    setOpenAtualizaModeloInativoDialog,
  };
}
