import React from 'react';
import './timeline.scss';
import { TimelineItem } from './TimelineItem';

const style = { marginTop: 20 };

export const Timeline = ({ items, showEnd }) => {
  return (
    <div className="container">
      <div className="page-header" style={style} />
      <div
        className={
          !showEnd
            ? 'timeline-container timeline-container-after'
            : 'timeline-container'
        }
      >
        <ul className="timeline">
          {items.map(item => (
            <TimelineItem key={item.idEntidade} item={item} />
          ))}
        </ul>
      </div>
    </div>
  );
};
