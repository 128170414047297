import { compose, pure } from 'recompose';
import { SearchListing } from './SearchListing';
import { GenericSearchBar } from '../../utils/Search/GenericSearchBar';
import { withGenericSearchBarState } from '../../utils/Search/WithGenericSearchBarState';
import { withTipoOrigemOportunidade } from '../../RealizarAtividade/data/queries/TipoOrigemOportunidadeQuery';

const enhance = compose(
  pure,
  withTipoOrigemOportunidade,
  withGenericSearchBarState({
    textSearchPlaceHolder: 'O que você deseja buscar?',
    textSearchPlaceHolderExpansion: 'Nome',
    itemPlaceHolder:
      'Digite o nome ou CPF/CNPJ do cliente para realizar uma busca',
    SearchListener: SearchListing,
    isToUpdateAppTitle: false,
    isShowFloatingButton: false,
    isDialog: true,
    showAdvancedSearch: false,
    autoFocus: true,
  })
);

export const SearchTabContainer = enhance(GenericSearchBar);
