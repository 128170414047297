/* eslint jsx-a11y/aria-role : 0 */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ReactSVG } from 'react-svg';
import { ReactComponent as Electrons } from '../../images/eletrons.svg';

export const stylesLoading = {
  container: {
    position: 'relative',
  },
  refresh: {
    marginTop: '50px',
    marginLeft: '-30px',
  },
  refreshNoMargin: {
    marginTop: '15px',
    marginLeft: '-30px',
  },
};

const loadingContainerStyles = makeStyles({
  electrons: {
    width: props => (props && props.electronsWidth) || '120px',
    position: 'absolute',
    left: '50%',
    '& path': {
      fill: props => (props && props.color) || null,
    },
    marginLeft: props => (props && props.electronMarginLeft) || '-55px',
    animation: `spin 3s linear infinite`,
  },
  funil: {
    width: props => (props && props.funilWidth) || '55px',
    position: 'absolute',
    left: '50%',
    top: props => (props && props.funilTop) || '50%',
    '& path': {
      fill: props => (props && props.color) || null,
    },
    '& polygon': {
      fill: props => (props && props.color) || null,
    },
    marginLeft: props => (props && props.funilMarginLeft) || '-25px',
    marginTop: props => (props && props.funilMarginTop) || '-25px',
  },
  divSvgContainer: { position: 'relative', minHeight: '120px' },
});

export const LoadingLogo = ({ styleProps, style }) => {
  const classes = loadingContainerStyles(styleProps);

  return (
    <div className={classes.divSvgContainer} style={{ ...style }}>
      <Electrons alt="eletrons" className={classes.electrons} />
      <ReactSVG
        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_MANIFEST_AND_ICONS_FOLDER}/images/loadingIcon.svg`}
        className={classes.funil}
      />
    </div>
  );
};

const style2 = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
};

export const Loading = () => {
  const style = { margin: 'auto' };

  return (
    <div role="loading" style={style2}>
      <LoadingLogo style={style} />
    </div>
  );
};

const styleContained = {
  ...stylesLoading.container,
  marginTop: '10%',
  marginBottom: '10%',
};

export const LoadingContained = ({ style }) => (
  <div role="loading" style={{ ...styleContained, ...style }}>
    <LoadingLogo />
  </div>
);
