import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { displayLoadingState } from '../displayLoadingState';
import { LoadingContained } from '../Loading';

/*
 * Query deve obedecer esse padrão:
 * query (name)Query {
 *    (name) {
 *           ....
 *    }
 *  }
 * */
export function genericQueryHoc({
  name,
  queryDataName,
  gql,
  options,
  skip,
  card = false,
  verifyVariablesHasUpdate = null,
  LoadingComponent = LoadingContained,
}) {
  const queryName = `${name}Query`;

  const query = graphql(gql, {
    name: queryName,
    skip,
    options: props => {
      let object = {
        fetchPolicy: 'cache-first',
      };

      if (options != null) {
        object = {
          ...object,
          ...(typeof options === 'function' ? options(props) : options),
        };
      }

      return object;
    },
  });

  const hoc = compose(
    query,
    displayLoadingState({
      name: queryName,
      onCacheLoadOnlyQueryName: name,
      verifyVariablesHasUpdate,
      card,
      LoadingComponent,
    })
  );

  return [query, hoc, queryName, queryDataName || name];
}
