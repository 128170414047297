import { Chip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar/Avatar';
import { mdiAccountTie, mdiSyncOff } from '@mdi/js';
import Icon from '@mdi/react';
import { BadgeIndicator } from 'components/BadgeIndicator';
import React from 'react';
import styled from 'styled-components';
import IconUnidadeNegocio from '../../../IconUnidadeNegocio';
import {
  cardGreenDark,
  defaultGrey,
  defaultGreyTextChip,
} from '../../../app/globalStyle';
import { isProspeccao } from '../activityCardFunctions';
import { HotleadCountDown } from '../HotleadCountDown';

const style5 = {
  backgroundColor: '#4f7249',
};
const style6 = {
  backgroundColor: defaultGrey,
  color: '#FFF',
};

const style4 = {
  width: 'auto',
  height: '18px',
  fontSize: '12px',
  marginLeft: 0,
  marginRight: '-3px',
  padding: '1px 9px',
  borderRadius: '10px',
  fontWeight: 700,
  backgroundColor: cardGreenDark,
};

export const SubContent = ({
  offlinePendente,
  classes,
  style7,
  style8,
  temperatura,
  activity,
  descricaoTipoOrigemProspeccao,
  usuarioGerente,
  usuarioAdmin,
  responsavel,
  showCurrentUserPending,
  avulsa,
  exibirTimer,
  exibirBadgeUnidadeNegocio,
}) => {
  const { tipoVenda, segmento } = activity?.unidadeNegocio || {};
  const { descricaoNormalizado } = segmento || {};

  return (
    <div>
      {offlinePendente && (
        <Chip
          size="small"
          label="Sinc. Pendente"
          classes={{
            root: classes.chipMargin,
          }}
          style={style7}
          icon={
            <Icon path={mdiSyncOff} className={classes.icon} color="white" />
          }
        />
      )}

      {temperatura && (
        <Chip
          label={`${temperatura.nome}`}
          classes={{
            root: classes.chipMargin,
          }}
          style={style8}
        />
      )}

      {isProspeccao(activity) && (
        <Chip
          size="small"
          classes={{
            root: classes.chipMargin,
          }}
          style={style5}
          avatar={<Avatar style={style4}>Lead</Avatar>}
          label={descricaoTipoOrigemProspeccao}
          color="primary"
        />
      )}

      <HotleadCountDown atividade={activity} renderizar={exibirTimer} />

      {exibirBadgeUnidadeNegocio && !!segmento && (
        <BadgeIndicator
          badge={
            <IconUnidadeNegocio descricaoNormalizado={descricaoNormalizado} />
          }
          label={<TipoVenda>{tipoVenda?.toLowerCase()}</TipoVenda>}
        />
      )}

      {(usuarioGerente || usuarioAdmin) &&
        responsavel &&
        showCurrentUserPending && (
          <Chip
            label={`${responsavel}`}
            avatar={
              <Icon
                path={mdiAccountTie}
                color={defaultGreyTextChip}
                size={0.7}
              />
            }
            classes={{
              root: classes.chipMargin,
              label: classes.chipSeller,
            }}
            className={classes.chipSellerBackground}
          />
        )}

      {avulsa && (
        <Chip
          label="Avulsa"
          classes={{
            root: classes.chipMargin,
          }}
          style={style6}
        />
      )}
    </div>
  );
};

const TipoVenda = styled.span`
  text-transform: capitalize;
`;
