import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import MotivosArquivarContatoQueryGql from './MotivosArquivarContatoQueryGql.graphql';

const hoc = graphql(MotivosArquivarContatoQueryGql, {
  name: 'motivosArquivarContatoQuery',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const MotivosArquivarContatoQueryHoc = compose(
  hoc,
  displayLoadingState({
    name: 'motivosArquivarContatoQuery',
    onCacheLoadOnlyQueryName: 'motivosArquivarContato',
  })
);
