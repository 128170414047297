import { graphqlOfflineMutation } from '../../utils/graphql/graphqlOfflineMutation';
import createOportunityMutationGql from './createOportunityMutationGql.graphql';
import createProspectionMutationGql from './createProspectionMutationGql.graphql';
import createFichaVisitaAtividadeMutationGql from './createFichaVisitaAtividadeMutationGql';

export const createOportunityMutation = graphqlOfflineMutation(
  createOportunityMutationGql,
  {
    name: 'createOportunity',
    mutationName: 'createOportunityMutationGql',
  }
);

export const createProspectionMutation = graphqlOfflineMutation(
  createProspectionMutationGql,
  {
    name: 'criarProspeccao',
    mutationName: 'createProspectionMutationGql',
  }
);

export const createFichaVisitaAtividadeMutation = graphqlOfflineMutation(
  createFichaVisitaAtividadeMutationGql,
  {
    name: 'criarFichaVisitaAtividade',
    mutationName: 'createFichaVisitaAtividadeMutationGql',
  }
);
