import { createAction, handleActions } from 'redux-actions';

const defaultState = { show: false };

export const showLoadingSpinner = createAction('SHOW_LOADING_SPINNER');
export const hideLoadingSpinner = createAction('HIDE_LOADING_SPINNER');
export const loadingSpinner = handleActions(
  {
    [showLoadingSpinner]: state => ({
      ...state,
      show: true,
    }),
    [hideLoadingSpinner]: state => ({ ...state, ...defaultState }),
  },
  defaultState
);
