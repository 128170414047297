import gql from 'graphql-tag';
import React from 'react';
import { genericQueryHoc } from '../../utils/crud/genericQueryHoc';
import { enumGetNames, TipoAtividade } from '../../../core/Enums';
import { CardLoading } from '../../utils/CardLoading';
import { client } from '../../../index';

export const atividadesFollowUp48PendenteQuery = gql`
  query atividadesFollowUp48PendenteQuery(
    $dealerPk: ID
    $types: [TipoAtividade]
    $temperatura: TemperaturaNegociacao
    $unidadeNegocioId: ID
    $semTemperatura: Boolean
    $usuarioId: ID
    $somenteAtividadesGerente: Boolean = false
    $tipoIndicadorFup48: String
  ) {
    atividadesFollowUp48Pendente(
      filter: {
        dealerPk: $dealerPk
        tipos: $types
        finalizado: false
        temperatura: $temperatura
        unidadeNegocioId: $unidadeNegocioId
        semTemperatura: $semTemperatura
        usuarioId: $usuarioId
        somenteAtividadesGerente: $somenteAtividadesGerente
        tipoIndicadorFup48: $tipoIndicadorFup48
      }
    ) {
      ids
    }
  }
`;

const options = ({ dealer, tipoIndicadorFup48, usuario, unidadeNegocio }) => {
  const variables = {
    dealerPk: dealer && dealer.id,
    types: enumGetNames(TipoAtividade),
    usuarioId: usuario && usuario.id,
    somenteAtividadesGerente: false,
    unidadeNegocioId: unidadeNegocio && unidadeNegocio.id,
    tipoIndicadorFup48: tipoIndicadorFup48.name,
  };

  return {
    fetchPolicy: 'cache-and-network',
    variables,
  };
};

export const verifyVariablesHasUpdate = props => {
  try {
    client.readQuery({
      ...(typeof options === 'function' ? options(props) : options),
      query: atividadesFollowUp48PendenteQuery,
    });

    return false;
  } catch (e) {
    return true;
  }
};

export const [
  ,
  AtividadesFup48PendenteQueryHoc,
  AtividadesFup48PendenteQueryName,
  AtividadesFup48PendenteQueryDataName,
] = genericQueryHoc({
  card: true,
  name: 'atividadesFollowUp48Pendente',
  gql: atividadesFollowUp48PendenteQuery,
  options,
  verifyVariablesHasUpdate,
  LoadingComponent: () => (
    <>
      <CardLoading />
    </>
  ),
  skip: null,
});
