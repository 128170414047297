import React from 'react';
import { Query } from '@apollo/react-components';
import ReactGA from 'react-ga4';
import localforage from 'localforage';
import { Loading } from '../utils/Loading';
import { USUARIO_LOGADO_QUERY_GQL } from './data/USUARIO_LOGADO_QUERY_GQL';
import { keycloak } from '../../core/offline/kcOfflineStub';

const DEFAULT_STATE = {};

export const UsuarioLogadoContext = React.createContext(DEFAULT_STATE);

export const getUsuarioLogado = (data, modulo) => {
  return {
    ...data?.usuarioLogado,
    consultorExterno:
      (modulo || '').split(',').some(val => val === 'CONSULTOR_EXTERNO') &&
      data?.usuarioLogado?.consultorExterno,
  };
};

const ALLOWED_BRANDS = {
  mercedes: [-1],
  'man-dp': ['19', '20', '28'],
};

export default function UsuarioLogadoProvider(props) {
  return (
    <Query query={USUARIO_LOGADO_QUERY_GQL} fetchPolicy="cache-first">
      {({ loading, data }) => {
        if (loading) {
          return <Loading />;
        }

        const usuarioLogado = getUsuarioLogado(
          data,
          process.env.REACT_APP_MODULOS
        );

        localforage.getItem('subject').then(id => {
          ReactGA.gtag('set', 'user_properties', {
            userDealer: (usuarioLogado.dealersVendedorLogado || [])
              .map(dealer => dealer?.nome)
              .join(', '),
            keycloakId: id,
          });
        });

        return (
          <UsuarioLogadoContext.Provider
            value={{
              usuarioLogado,
              marcas: usuarioLogado?.marcas,
              usuarioGerente: usuarioLogado?.gerente,
              usuarioAdmin: usuarioLogado?.administrador,
              usuarioVendedor:
                usuarioLogado?.vendedorCrm &&
                !(usuarioLogado.gerente || usuarioLogado.administrador),
              usuarioConsultorExterno: usuarioLogado?.consultorExterno,
              exibeIndicadores: !usuarioLogado?.consultorExterno,
              usuarioInterno: usuarioLogado?.email?.includes(
                '@tecsinapse.com.br'
              ),
              exibeRoteirizacao: usuarioLogado && usuarioLogado.roteirizacaoV2,
              isAppDisabled:
                !usuarioLogado.consultorExterno &&
                !(ALLOWED_BRANDS[keycloak.realm.toLowerCase()] ?? []).includes(
                  -1
                ) &&
                !usuarioLogado.dealersVendedorLogado.some(dealer =>
                  (ALLOWED_BRANDS[keycloak.realm.toLowerCase()] ?? []).includes(
                    dealer.id
                  )
                ),
            }}
          >
            {props.children}
          </UsuarioLogadoContext.Provider>
        );
      }}
    </Query>
  );
}
