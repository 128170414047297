import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
  withStyles,
} from '@material-ui/core';
import { IconButton, Button } from '@tecsinapse/ui-kit';

import { ArrowBack } from '@material-ui/icons';
import { withRouter } from 'react-router';
import { compose, withHandlers, withState } from 'recompose';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import styled from 'styled-components';
import moment from 'moment';
import { withSettingsState } from '../Settings/withSettingsState';
import { useGlobalStyle } from '../app/globalStyle';
import { allowActionsModeloAtivo } from '../Atividade/atividadeFunctions';

const style = {
  noWrapText: { wordWrap: 'break-word' },
};

const InformarContato = ({
  open,
  handleClose,
  handleChange,
  idAtividade,
  atividades,
  error,
  goToRealizarAtividade,
}) => {
  const classes = useGlobalStyle();

  const textoOrigem = atividade => {
    const { unidadeNegocio, id } = atividade;
    const { descricao } = unidadeNegocio;

    return `${descricao} #${id}`;
  };

  const openNotNull = isNotUndefOrNull(open) ? open : false;

  return (
    <Dialog open={openNotNull} onClose={handleClose}>
      <DialogTitle id="simple-dialog-title">
        <IconButton
          classes={{ root: classes.iconBackButton }}
          onClick={handleClose}
          color="inherit"
        >
          <ArrowBack />
        </IconButton>
        Informar Contato
      </DialogTitle>
      <DialogContent>
        Qual atividade você deseja informar contato?
        {error && (
          <Typography variant="caption" color="error">
            Por favor, selecione o grupo segmento
          </Typography>
        )}
        <RadioGroup value={idAtividade} onChange={handleChange}>
          {atividades.map(
            atividade =>
              atividade && (
                <>
                  <FormControlLabel
                    key={`atividade${atividade.id}`}
                    value={atividade.id}
                    control={<Radio />}
                    label={textoOrigem(atividade)}
                  />
                  <DadosAtividade>
                    <Typography variant="subtitle2">
                      {moment(atividade.dataHoraExibicao).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                    </Typography>
                    <Typography variant="caption">
                      {atividade.descricao}
                    </Typography>
                  </DadosAtividade>
                </>
              )
          )}
        </RadioGroup>
        <List />
      </DialogContent>
      <DialogActions>
        <Button onClick={goToRealizarAtividade} color="primary" autoFocus>
          Informar
        </Button>

        <Button onClick={handleClose} color="primary" autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const InformarContatoDialog = compose(
  withStyles(style),
  withState('atividade', 'setAtividade', null),
  withState('idAtividade', 'setIdAtividade', null),
  withState('error', 'setError', false),
  withRouter,
  withHandlers({
    goToRealizarAtividade: ({
      history,
      setError,
      idAtividade,
      handleClose,
      atividade,
      urlRetorno,
    }) => () => {
      allowActionsModeloAtivo(atividade);

      if (idAtividade) {
        history.push(
          `/realizarAtividade/${idAtividade}${
            urlRetorno ? `/${urlRetorno}` : ''
          }`
        );
        handleClose();

        return;
      }
      setError(true);
    },
    handleChange: ({ setIdAtividade, setAtividade, atividades }) => event => {
      setIdAtividade(event.target.value);
      setAtividade(
        atividades.filter(
          atividade => atividade && atividade.id === event.target.value
        )
      );
    },
  }),
  withSettingsState
)(InformarContato);

const DadosAtividade = styled.div`
  transform: translateY(-10px);
  margin-left: 31px;
  margin-bottom: 5px;
`;
