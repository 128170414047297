import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';

import React from 'react';

const share = (propostaUrl, setPropostaUrl) => {
  window.open(propostaUrl, '_blank');
  setPropostaUrl(null);
};

export const SharePdfProposalModal = ({ propostaUrl, setPropostaUrl }) => (
  <Dialog
    open={!!propostaUrl}
    onClose={() => setPropostaUrl(null)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">PDF Gerado</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Você gostaria de compartilhar esse pdf?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => share(propostaUrl, setPropostaUrl)}
        color="primary"
        autoFocus
      >
        OK
      </Button>
      <Button onClick={() => setPropostaUrl(null)} color="primary" autoFocus>
        Cancelar
      </Button>
    </DialogActions>
  </Dialog>
);
