import pick from 'lodash.pick';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps } from 'recompose';
import {
  flatMap,
  getFirstFromSingleElementArrayNotNull,
  isNotEmptyOrNull,
} from '@tecsinapse/es-utils/build';
import { getAlertContext } from '../../../utils/alertContext';
import {
  connectToFormFields,
  validateRequired,
} from '../../../utils/FormUtils';
import { ClienteFrotaEditor } from './ClienteFrotaEditor';
import { ModelosFrotaQuery } from '../data/queries/ModelosFrotaQuery/ModelosFrotaQuery';
import { reduxFormAutoFocus } from '../../../utils/reduxFormAutoFocus';
import { EditarVeiculoFrotaMutation } from '../data/mutations/EditarVeiculoFrotaMutation';
import { withTermoModelo } from '../../../opportunity/data/queries/crmTermosQuery';

const enhance = compose(
  withRouter,
  withTermoModelo,
  connectToFormFields('EditVehicleForm', [
    'idSegmento',
    'idSubSegmento',
    'idMarca',
    'quantidade',
  ]),
  ModelosFrotaQuery,
  withProps(({ location, match }) => {
    const data = location.state;
    const {
      marcasFrota,
      segmentosVendaAtivos,
      dealersVendedorLogado,
      idDealer,
    } = data || {};
    const veiculo =
      data !== undefined && data.veiculo !== undefined ? data.veiculo : {};
    const {
      segmento = {},
      subSegmento = {},
      marca = {},
      modelo = {},
    } = veiculo;

    const subSegmentos = flatMap(
      c => c.subSegmentos ?? [],
      segmentosVendaAtivos ?? []
    );

    return {
      data,
      subSegmentos,
      initialValues: {
        ...veiculo,
        idCliente: match.params.idCliente,
        idSegmento:
          segmento.id ||
          getFirstFromSingleElementArrayNotNull(segmentosVendaAtivos).id,
        idSubSegmento: (subSegmento || {}).id,
        idMarca:
          marca.id || getFirstFromSingleElementArrayNotNull(marcasFrota).id,
        idModelo: modelo ? modelo.id : null,
        idDealer:
          idDealer ||
          getFirstFromSingleElementArrayNotNull(dealersVendedorLogado).id,
      },
    };
  }),
  EditarVeiculoFrotaMutation,
  getAlertContext,
  reduxFormAutoFocus({
    // a unique name for the form
    form: 'EditVehicleForm',
    validate: validateRequired([
      'nome',
      'idDealer',
      'quantidade',
      'idSegmento',
      'idMarca',
    ]),
    onSubmit: (
      values,
      dispatch,
      { salvarVeiculoFrota, history, showAlert, hideLoading }
    ) => {
      const submitValues = pick(values, [
        'id',
        'idSubSegmento',
        'idSegmento',
        'idCliente',
        'idMarca',
        'idModelo',
        'quantidade',
        'placa',
        'chassi',
        'anoFabricacao',
        'anoModelo',
      ]);

      submitValues.anoModelo =
        submitValues.anoModelo === '' ? null : submitValues.anoModelo;
      const variables = { itemFrota: submitValues };

      return salvarVeiculoFrota({ variables })
        .then(() => history.goBack())
        .catch(() => history.goBack());
    },
  }),
  withHandlers({
    onChassiPlacaChange: ({ change }) => (e, newValue) => {
      if (isNotEmptyOrNull(newValue) && newValue > 1) {
        change('chassi', '');
        change('placa', '');
      }
    },
    addQuantidade: ({ change, quantidade, showAlert }) => e => {
      if (!navigator.onLine) {
        showAlert('Offline - Não é possível realizar ação');
      }
      change('quantidade', quantidade + 1);
    },
  })
);

export const ClienteFrotaEditorContainer = enhance(ClienteFrotaEditor);
