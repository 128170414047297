import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import divisoesRegionaisDealerVendedorLogadoQueryGql from './divisoesRegionaisQueryGql.graphql';
import { displayLoadingState } from '../../../../utils/displayLoadingState';

export const divisoesRegionaisDealerVendedorLogadoQuery = graphql(
  divisoesRegionaisDealerVendedorLogadoQueryGql,
  {
    name: 'divisoesRegionaisQuery',
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
  }
);

export const GetDivisoesRegionaisDealerVendedorLogadoQueryHoc = compose(
  divisoesRegionaisDealerVendedorLogadoQuery,
  displayLoadingState({
    name: 'divisoesRegionaisQuery',
    onCacheLoadOnlyQueryName: 'divisoesRegionaisDealerVendedorLogado',
  })
);
