import React from 'react';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClienteSearchTabDialog } from '../../ClienteSearch/ClienteSearchTabDialog';
import { checkInBuscarClientes } from './utils/checkInStylesUtils';

const useCheckInBuscarClientes = makeStyles(checkInBuscarClientes);

export const CheckInRealizarComponent = ({
  showSearchDialog,
  setShowSearchDialog,
  ...props
}) => {
  const classes = useCheckInBuscarClientes();

  return (
    <div className={classes.content}>
      <Grid container>
        <Grid item xs={12}>
          <Button
            customVariant="warning"
            onClick={() => setShowSearchDialog(true)}
            fullWidth
          >
            Buscar Cliente na Base
          </Button>
        </Grid>
      </Grid>

      <ClienteSearchTabDialog
        setShowSearchDialog={setShowSearchDialog}
        showSearchDialog={showSearchDialog}
        {...props}
      />
    </div>
  );
};
