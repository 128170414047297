/* eslint no-console: 0 */

import { flatMap, isEmptyOrNull, resolveObj } from '@tecsinapse/es-utils/build';
import { queryList } from '../../../components/utils/queryList';
import { Logger } from '../../../components/utils/log/logUtils';

const resolveQueryVariables = queryListElement1 => {
  let variables = {};

  if (
    queryListElement1.hasNullId !== undefined &&
    queryListElement1.hasNullId
  ) {
    variables.nullId = false;
  }

  if (
    queryListElement1.defaultVariables !== undefined &&
    queryListElement1.defaultVariables
  ) {
    variables = {
      ...variables,
      ...queryListElement1.defaultVariables,
    };
  }

  return variables;
};

const getIdsOfResults = (nameQ, resultListIn, idPath, isVector, vectorPath) => {
  let idsToQuery = [];
  let resultList = resultListIn;

  if (!(resultList.constructor === Array)) {
    resultList = [resultList];
  }

  if (isVector) {
    const vectorOfFirstElement = flatMap(
      c => resolveObj(vectorPath, c),
      resultList
    );

    if (vectorOfFirstElement.length === 0) {
      return [];
    }

    idsToQuery = vectorOfFirstElement.map(c => resolveObj(idPath, c));
  } else if (idPath === null) {
    idsToQuery = [{}];
  } else {
    idsToQuery = resultList.map(c => resolveObj(idPath, c));
  }

  return idsToQuery;
};

const getVariablesId = (cascadeSubQueryObject, variables, id) => {
  let newVariables = variables;

  if (cascadeSubQueryObject.haveId) {
    newVariables = {
      ...newVariables,
      [!isEmptyOrNull(cascadeSubQueryObject.idName)
        ? cascadeSubQueryObject.idName
        : 'id']: id,
    };
  }

  return newVariables;
};

/**
 * Executa queries armazenadas na lista {queryList} em cascata ateh ter todas as queries no cache
 *
 * @param {args} args uriundos do cache write
 * @param {apolloClient} Cliente do apollo
 * @returns console.debug das queries executadas
 */
export const queryCascadeCaching = ({ args = [], apolloClient }) => {
  const { query, result } = args[0];
  const nameQ = query.definitions[0].name.value;
  const queryListElement = queryList[nameQ];

  if (
    isEmptyOrNull(queryListElement) ||
    (queryListElement && isEmptyOrNull(queryListElement.nextQueries))
  ) {
    console.debug(`${nameQ} sem proximas queries ou não existe`);

    return;
  }

  const { entityName, queryName } = queryListElement;

  const resultList = queryName
    ? result[queryName][entityName]
    : result[entityName];

  const qListsBatchs = [];

  queryListElement.nextQueries.forEach(
    ({
      name: qString,
      idPath,
      isVector,
      vectorPath,
      isArrayIdParameter = false,
      writeDataQuery,
    }) => {
      const cascadeSubQueryObject = queryList[qString];
      let variables = resolveQueryVariables(cascadeSubQueryObject);

      console.debug(`Pai:${nameQ} - Filha:${qString}`);

      const ids = getIdsOfResults(
        nameQ,
        resultList,
        idPath,
        isVector,
        vectorPath
      );

      if (isArrayIdParameter) {
        qListsBatchs.push({
          obj: { variables: { ids }, query: cascadeSubQueryObject.query },
          name: cascadeSubQueryObject.name,
          writeDataQuery,
        });

        return;
      }
      ids.forEach(id => {
        variables = getVariablesId(cascadeSubQueryObject, variables, id);

        qListsBatchs.push({
          obj: { variables, query: cascadeSubQueryObject.query },
          name: cascadeSubQueryObject.name,
        });
      });
    }
  );

  qListsBatchs.forEach(({ obj, name, writeDataQuery }) => {
    setTimeout(() => {
      try {
        const queryResult = apolloClient.readQuery({ ...obj });

        console.debug(queryResult);
        console.debug('Não precisa disparar request pq já tem no cache.');
      } catch (e) {
        setTimeout(() => {
          console.debug(e);
          const queryDetails = {
            ...obj,
            fetchPolicy: 'network-only',
            context: { batch: true },
          };

          console.debug(queryDetails);
          apolloClient
            .query(queryDetails)
            .then(({ data }) => {
              console.debug('data');
              console.debug(data);

              if (writeDataQuery == null) {
                console.debug(`${name} cacheada`);

                return data;
              }
              const batchQuery = queryList[name];
              const writeDataQueryObject = queryList[writeDataQuery.name];

              data[batchQuery.queryName][batchQuery.entityName].forEach(
                iterateResult => {
                  const writeData = {
                    [writeDataQuery.dataPath]: iterateResult,
                  };

                  console.debug(writeData);
                  const writeQueryDetails = {
                    variables: {
                      id: iterateResult.id,
                      nullId: false,
                    },
                    query: writeDataQueryObject.query,
                    data: writeData,
                  };

                  console.debug(name);
                  console.debug(batchQuery);
                  console.debug(writeQueryDetails);
                  setTimeout(() => {
                    apolloClient.writeQuery(writeQueryDetails);
                  }, 1);
                }
              );

              return Promise.resolve();
            })
            .catch(e2 => console.debug(`${e2} - ${name}`));
        }, 1);
      }
    }, 1);
  });
};

export const cacheQueryWithOutId = ({ apolloClient }) => {
  console.debug(Object.values(queryList).filter(c => c.haveId === false));

  Object.values(queryList)
    .filter(c => c.haveId === false)
    .forEach(query => {
      try {
        apolloClient.readQuery({ query: query.query });
      } catch (e) {
        setTimeout(() => {
          apolloClient
            .query({
              query: query.query,
              fetchPolicy: 'network-only',
              context: { batch: true },
            })
            .then(data => {
              console.debug(`${query.name} cacheada`);
              console.debug(data);
              console.debug('data');
            })
            .catch(e2 => Logger.error(`${query.name} ${JSON.stringify(e2)}`));
        }, 1);
      }
    });
};
