import React from 'react';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { IconButton } from '@tecsinapse/ui-kit';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ShareIcon from '../../images/shareios.svg';
import { globalStyle } from '../app/globalStyle';

const useSnackStyle = makeStyles(theme => ({
  snackMinFont: {
    fontSize: '10px',
  },
  snackbarContent: {
    margin: props => props && props.locationMargin,
  },
  close: {
    paddingLeft: 0,
  },
  img: {
    width: '20px',
    height: '20px',
  },
  ...globalStyle(theme),
}));

const getMarginAndOrientation = location => {
  if (location.pathname === '/agenda') {
    return {
      positionVertical: 'top',
      locationMargin: '161px 0 0 0',
    };
  }

  if (location.pathname === '/clientes') {
    return {
      positionVertical: 'top',
      locationMargin: '110px 0 0 0',
    };
  }

  if (location.pathname === '/rotas') {
    return {
      positionVertical: 'top',
      locationMargin: '56px 0 0 0',
    };
  }

  return {
    positionVertical: 'top',
    locationMargin: '60px 0 0 0',
  };
};

const SnackbarComponent = ({
  showInstallMessage,
  setShowInstallMessage,
  classes,
  positionVertical,
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: positionVertical,
      horizontal: 'left',
    }}
    open={showInstallMessage}
    onClose={() => setShowInstallMessage(false)}
    autoHideDuration={15000}
    message={
      <div className={classes.snackbarFragment}>
        <span id="message-id">Adicione a sua tela inicial clicando em</span>
        <img
          alt="share icon"
          src={ShareIcon}
          className={clsx(classes.img, classes.marginRight10)}
        />
      </div>
    }
    ContentProps={{
      'aria-describedby': 'snackbar-fab-message-id',
      className: classes.snackbarContent,
    }}
    action={[
      <IconButton
        key="close"
        aria-label="Fechar"
        color="inherit"
        className={classes.close}
        onClick={() => setShowInstallMessage(false)}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

export const IosAddSnack = ({ showInstallMessage, setShowInstallMessage }) => {
  const { positionVertical, locationMargin } = getMarginAndOrientation(
    window.location
  );

  const classes = useSnackStyle({
    locationMargin,
  });

  const props = {
    showInstallMessage,
    setShowInstallMessage,
    positionVertical,
    classes,
  };

  return <SnackbarComponent {...props} />;
};
