import React from 'react';
import { Form } from 'redux-form';
import { Grid } from '@material-ui/core';
import { SubmitButton, submitWithAction } from '../../utils/FormUtils';
import { WarningSign } from './WarningSign';

const style = { width: '100%' };

export const InformacoesInvalidas = ({
  handleSubmit,
  onSubmit,
  classes,
  initialValues,
  goToNextPage,
  formName = null,
  setTextAction,
  justificativa,
  ...rest
}) => (
  <Form onSubmit={handleSubmit} style={style} name={formName}>
    <Grid container>
      <Grid item xs={12}>
        <WarningSign />
      </Grid>
      <Grid item xs={12}>
        <SubmitButton
          label="Arquivar"
          fullWidth
          secundary
          onClick={submitWithAction({
            handleSubmit,
            onSubmit,
            action: 'INFORMACOES_INVALIDAS',
          })}
          {...rest}
        />
      </Grid>
    </Grid>
  </Form>
);
