import moment from 'moment';
import { useEffect, useState } from 'react';

const ONE_SECOND = 1000;

export const Timer = ({ remainingSeconds, label, onCountDownOver }) => {
  const [currentTime, setCurrentTime] = useState(remainingSeconds);

  useEffect(() => {
    let timer;

    const tick = () => {
      setCurrentTime(lastTime => {
        if (lastTime <= 1) {
          clearInterval(timer);
          onCountDownOver && onCountDownOver();
        }

        return lastTime - 1;
      });
    };

    if (remainingSeconds > 0) {
      timer = setInterval(tick, ONE_SECOND);
    }

    return () => clearInterval(timer);
  }, [remainingSeconds, onCountDownOver]);

  useEffect(() => {
    setCurrentTime(remainingSeconds);
  }, [remainingSeconds]);

  if (currentTime <= 0) {
    return label;
  }

  return moment.utc(currentTime * ONE_SECOND).format('HH:mm:ss');
};
