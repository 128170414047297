import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { Field, Form } from 'redux-form';
import { compose } from 'recompose';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import {
  allowInformarNegociacaoAceita,
  allowInformarNegocioPerdido,
  allowOnlyInformarNegocioPerdido,
} from '../Atividade/atividadeFunctions';
import {
  RenderDateTimePicker,
  RenderInput,
  required,
  SubmitButton,
  submitWithAction,
} from '../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../utils/uiUtils';
import { RenderSelectField } from '../utils/select/RenderSelectField';
import { withTextTransform } from '../utils/ui/withTextTransform';
import { defaultGreen, defaultRed } from '../app/globalStyle';
import { IconButtonMaiusculas } from './IconButtonMaiusculas/IconButtonMaiusculas';
import { withIsNegociacaoAceitaAtivo } from '../opportunity/data/queries/crmTermosQuery';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';

const TextoInformarContato = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
`;

const Elipse = styled.div`
  width: 32px;
  height: 32px;
  border: solid 2px #cccccc;
  border-radius: 32px;
  background-color: #f5f5f5;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -16px;
  z-index: 100;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const useRealizarAtividadeFirstPageComponentStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  negociacaoContainer: {
    backgroundColor: '#d7d7d7',
    border: '1px solid #f5f5f5',
    padding: theme.spacing(2),
    flexGrow: 100,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
  },
  margin: {
    margin: '20px',
  },
  menuItem: {
    color: 'grey',
  },
}));

export const REWARDS_CONFIG = {
  lifetime: '200',
  angle: '90',
  decay: '0.81',
  spread: '45',
  startVelocity: '35',
  elementCount: '5',
  elementSize: '20',
  emoji: ['💲'],
};

const RealizarAtividadeFirstPageComponent = ({
  handleSubmit,
  onSubmit,
  data: { atividade = {} },
  temperaturasQuery: { temperaturas = [] },
  tiposDeContatoQuery: { tiposContato = [] },
  goToNextPage,
  formName = null,
  history,
  observacao,
  setTextAction,
  tipoAcaoInformarContato,
  crmIsNegociacaoAceitaAtivo,
  usuarioLogado,
  ...rest
}) => {
  const { cliente: { contatos = [] } = {} } = atividade;
  const classes = useRealizarAtividadeFirstPageComponentStyles();
  const podeInformarNegocioAceito = allowInformarNegociacaoAceita(
    atividade,
    tipoAcaoInformarContato,
    crmIsNegociacaoAceitaAtivo,
    usuarioLogado
  );
  const podeInformarNegocioPerdido = allowInformarNegocioPerdido(
    atividade,
    tipoAcaoInformarContato
  );
  const podeRealizarSomenteNegocioPerdido = allowOnlyInformarNegocioPerdido(
    atividade
  );

  return (
    <Form
      onSubmit={handleSubmit}
      name={formName}
      className={classes.formContainer}
    >
      <RenderAppLogo />
      <ShowBackButton history={history} />
      {updateAppTitle('Realizar Atividade')}
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Field
              id="temperatura"
              name="temperatura"
              label="Temperatura"
              required
              validate={[required]}
              component={RenderSelectField}
              fullWidth
            >
              {temperaturas.map(({ id, nome }) => (
                <MenuItem value={id} key={id}>
                  {nome}
                </MenuItem>
              ))}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field
              id="tipoContato"
              name="tipoContato"
              validate={[required]}
              label="Tipo do Contato"
              required
              component={RenderSelectField}
              fullWidth
            >
              {tiposContato.map(({ id, nome }) => (
                <MenuItem value={id} key={id}>
                  {nome}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="dataHoraRealizacao"
              label="Data e hora de realização"
              required
              maxDate={moment()}
              component={RenderDateTimePicker}
            />
          </Grid>

          {(contatos || []).length > 0 && (
            <Grid item xs={12}>
              <Field
                name="idContato"
                label="Contato"
                component={RenderSelectField}
                fullWidth
              >
                {contatos.map(({ id, nome, telefone }) => (
                  <MenuItem value={id} key={id}>
                    {nome}
                    {telefone && (
                      <span
                        className={classes.menuItem}
                      >{` - ${telefone}`}</span>
                    )}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}

          <Grid item xs={12}>
            <Field
              name="observacao"
              label="Observações"
              required
              multiline
              component={RenderInput}
              fullWidth
              endAdornment={
                <IconButtonMaiusculas
                  observacao={observacao}
                  setTextAction={setTextAction}
                />
              }
            />
          </Grid>

          {!podeRealizarSomenteNegocioPerdido && (
            <Grid item xs={12}>
              <SubmitButton
                label="Realizar Atividade"
                fullWidth
                onClick={submitWithAction({
                  handleSubmit,
                  onSubmit,
                  action: 'REALIZAR_ATIVIDADE',
                })}
                disableElevation
                {...rest}
              />
            </Grid>
          )}
        </Grid>
      </div>

      {(podeInformarNegocioAceito || podeInformarNegocioPerdido) && (
        <>
          {!podeRealizarSomenteNegocioPerdido && <Elipse>OU</Elipse>}
          <div className={classes.negociacaoContainer}>
            <TextoInformarContato>
              Informe status da negociação
            </TextoInformarContato>

            <Grid container>
              {podeInformarNegocioPerdido && (
                <Grid item xs={!podeInformarNegocioAceito ? 12 : 6}>
                  <SubmitButton
                    third
                    variant="outlined"
                    onClick={submitWithAction({
                      handleSubmit,
                      onSubmit,
                      action: 'GO_TO_LOST_BUSINESS',
                    })}
                    label="Perdida"
                    disableElevation
                    fullWidth
                    textColor={defaultRed}
                    borderColor={defaultRed}
                    {...rest}
                  />
                </Grid>
              )}
              {podeInformarNegocioAceito && (
                <Grid item xs={6}>
                  <SubmitButton
                    id="negociacao-aceita-button"
                    variant="outlined"
                    onClick={() => {
                      submitWithAction({
                        handleSubmit,
                        onSubmit,
                        action: 'NEGOCIACAO_ACEITA',
                      })();
                    }}
                    label="Aceita"
                    disableElevation
                    fullWidth
                    textColor={defaultGreen}
                    borderColor={defaultGreen}
                    {...rest}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </>
      )}
    </Form>
  );
};

export const RealizarAtividadeFirstPage = compose(
  withIsNegociacaoAceitaAtivo,
  withUsuarioLogado,
  withTextTransform({
    formName: 'RealizarAtividadeForm',
    fields: ['observacao'],
  })
)(RealizarAtividadeFirstPageComponent);
