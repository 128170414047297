import { IconButton } from '@tecsinapse/ui-kit';
import { withStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { compose } from 'recompose';
import { blur, Field, FieldArray, Form } from 'redux-form';
import { globalStyle } from '../../app/globalStyle';
import {
  CheckBoxComponent,
  ChipsInput,
  RenderDateTimePicker,
  RenderInput,
  required,
  requiredArray,
  SubmitButton,
  submitWithAction,
} from '../../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../utils/uiUtils';
import { RealizarAtividadeSteps } from './RealizarAtividadeSteps';
import { withTextTransform } from '../../utils/ui/withTextTransform';
import { VISITA_STEPS } from './visitaActions';
import { IconButtonMaiusculas } from '../IconButtonMaiusculas/IconButtonMaiusculas';

const style = {
  margin: {
    margin: '20px',
  },
  marginTop: {
    marginTop: '20px',
  },
  flexColumnCenter: globalStyle.flexColumnCenter,
};
const enhance = compose(
  withTextTransform({
    formName: 'RealizarAtividadeForm',
    fields: ['observacao'],
  }),
  withStyles(style)
);

export const RealizarAtividadeVisitaFirstPage = enhance(
  ({
    handleSubmit,
    onSubmit,
    classes,
    initialValues,
    goToNextPage,
    dispatch,
    formName = null,
    razoesVisitaDisponiveisQuery: { razoesVisitaDisponiveis = [] },
    data: { atividade = {} },
    history,
    setTextAction,
    observacao,
    ...rest
  }) => {
    const { submitting } = rest;

    return (
      <div>
        <RenderAppLogo />
        <ShowBackButton history={history} />
        {atividade && !atividade.avulsa && (
          <RealizarAtividadeSteps {...rest} steps={VISITA_STEPS} />
        )}
        <Form
          onSubmit={handleSubmit}
          className={classes.margin}
          name={formName}
        >
          {updateAppTitle('Realizar Atividade')}
          <Field
            fullWidth
            name="dataHoraRealizacao"
            label="Data e hora de realização"
            required
            maxDate={moment()}
            validate={[required]}
            component={RenderDateTimePicker}
          />
          <FieldArray
            type="checkbox"
            className={classes.marginTop}
            component={CheckBoxComponent}
            name="razoesVisita"
            label="Razões da Visita"
            required
            validate={[requiredArray]}
            options={razoesVisitaDisponiveis.map(({ id, nome }) => ({
              _id: id,
              label: nome,
            }))}
            row
          />
          <Field
            name="observacao"
            label="Observações"
            required
            validate={[required]}
            multiline
            component={RenderInput}
            fullWidth
            endAdornment={
              <IconButtonMaiusculas
                observacao={observacao}
                setTextAction={setTextAction}
              />
            }
          />

          <div className={classes.flexColumnCenter}>
            <FieldArray
              name="participantes"
              fullWidth
              label="Participantes"
              component={ChipsInput}
              withRef
            />
            <IconButton
              onClick={() => {
                dispatch(blur('RealizarAtividadeForm', 'participantes'));
              }}
            >
              <Add />
            </IconButton>
          </div>
          {atividade && !atividade.avulsa && (
            <SubmitButton
              className={classes.marginTop}
              color="secondary"
              variant="contained"
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'GO_TO_PERFORM_VISIT_ACTIVITY',
              })}
              label="Avançar"
              fullWidth
              {...rest}
            />
          )}

          {atividade && atividade.avulsa && (
            <SubmitButton
              className={classes.marginTop}
              variant="contained"
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'REALIZAR_ATIVIDADE_VISITA',
              })}
              label="Realizar Atividade"
              fullWidth
              submitting={submitting}
            />
          )}
        </Form>
      </div>
    );
  }
);
