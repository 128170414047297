import axios from 'axios';
import Base64Binary from '../utils/base64Binary';
import { Logger } from '../utils/log/logUtils';

const PushUtils = {
  postToServer(subscription, subscribeUrl, callback) {
    // Get public key and user auth from the subscription object
    let body = {};

    if (subscription != null) {
      const {
        keys: { p256dh: key, auth },
        endpoint,
      } = subscription.toJSON();

      body = {
        endpoint,
        key,
        auth,
      };
    }

    axios
      .post(subscribeUrl, body)
      .then(response => {
        if (!(response.status === 200)) {
          Logger.error(response);

          return;
        }
        localStorage.setItem('registeredForPush', true);

        if (callback) {
          callback(true, subscribeUrl);
        }
      })
      .catch(() => {
        localStorage.setItem('registeredForPush', false);

        if (callback) {
          callback(false, subscribeUrl);
        }
      });
  },
  subscribeToServer(subscriptionUrl, vapidPubKey, callback) {
    navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
      if (
        Notification.permission !== 'granted' ||
        JSON.parse(localStorage.getItem('registeredForPush'))
      ) {
        return;
      }
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then(subscription => {
          if (subscription == null) {
            serviceWorkerRegistration.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: Base64Binary.urlB64ToUint8Array(
                  vapidPubKey
                ),
              })
              .then(
                s => PushUtils.postToServer(s, subscriptionUrl, callback),
                a => {
                  Logger.error(a);
                }
              )
              .catch(error => {
                Logger.error(error);
              });
          } else {
            PushUtils.postToServer(subscription, subscriptionUrl, callback);
          }
        });
    });
  },
  unsubscribeFromServer(callback) {
    if (!navigator.serviceWorker) {
      callback(false);
    }

    navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
      if (!JSON.parse(localStorage.getItem('registeredForPush'))) {
        if (callback) {
          callback(false);
        }

        return;
      }

      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then(subscription => {
          if (subscription != null) {
            subscription.unsubscribe().then(unsubscribe => {
              if (!unsubscribe) {
                Logger.error('Erro unsubscribe no servidor do browser.');
              }
            });
          }

          PushUtils.postToServer(
            subscription,
            process.env.REACT_APP_PUSH_UNSUBSCRIBE_ENDPOINT,
            result => {
              if (result) {
                localStorage.setItem('registeredForPush', false);
              }

              if (callback) {
                callback();
              }
            }
          );
        })
        .catch(error => {
          Logger.error(`Erro unsubscribe no servidor do browser. ${error}`);
        });
    });
  },
  testarEnvioNotificacao() {
    axios
      .post(process.env.REACT_APP_PUSH_TEST_ENDPOINT)
      .then(response => {
        if (!(response.status === 200)) {
          Logger.error(response);
        }
      })
      .catch(error => {
        Logger.error('Erro ao testar notificação:', error);
      });
  },
};

export default PushUtils;
