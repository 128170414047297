import { Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import { globalBackgroundColor } from '../../app/globalStyle';

export const RealizarAtividadeSteps = ({ selectedPage, steps }) => (
  <Stepper
    activeStep={selectedPage}
    alternativeLabel
    style={{ backgroundColor: globalBackgroundColor }}
  >
    {steps.map(label => {
      const props = {};
      const labelProps = {};

      return (
        <Step key={label} {...props}>
          <StepLabel {...labelProps}>{label}</StepLabel>
        </Step>
      );
    })}
  </Stepper>
);
