import React from 'react';
import { FilterList } from '@material-ui/icons';
import { enumGetNames, TipoAtividade } from 'core/Enums';
import { ReagendamentoAtividadeDialogContainer } from '../ReagendarAtividade/ReagendarAtividadeContainer';
import { EntrarEmContatoDialog } from '../utils/EntrarEmContatoDialog';
import {
  RenderAppLogo,
  updateAppBarBottom,
  updateAppCenter,
} from '../utils/uiUtils';
import { ActivitiesListContainer } from './ActivitiesListContainer';
import { RouteDialog } from '../utils/RouteDialog';
import { Weekdays } from './Weekdays/Weekdays';
import { SearchTabDialog } from './Search/SearchTabDialog';
import { searchClick } from '../utils/GAUtils';
import { AtividadeBottomSheet } from './AtividadeBottomSheet';
import { FloatingButton } from '../utils/FloatingButton';
import { AgendaFilterBottomSheet } from './AgendaFilterBottomSheet';
import { showAgendaSearch } from './AgendaSearchBar';

export const Agenda = ({
  setShowCallClientModal,
  showCallClientModal,
  showRescheduleModal,
  setShowRescheduleModal,
  showRouteModal,
  setShowRouteModal,
  selectedDay,
  setSelectedDay,
  showSearchDialog,
  setShowSearchDialog,
  clearSelected,
  onBottomSheetClick,
  showAtrasadas,
  setShowAtrasadas,
  qtdAtrasadas,
  setQtdAtrasadas,
  setDiasComAtrasadas,
  diasComAtrasadas,
  agendaKey,
  usuarioLogado,
  atualizaModeloInativoEAbreDialog,
  data,
  atividadesAtrasadas,
  activities,
  showMenuBottomSheet,
  menuOpen,
  menuBottomSheetOnClose,
  origensOportunidade,
  prospeccoesSemContato,
  notRenderIfConsultorExterno,
  ...props
}) => {
  const { selectedActivity, activityOnClick, tipoOrigemOportunidade } = props;

  const tiposAtividade = enumGetNames(TipoAtividade).filter(
    e => e !== TipoAtividade.FATURAMENTO.name
  );

  return (
    <>
      {showAgendaSearch({
        action: () => {
          setShowSearchDialog(true);
          searchClick();
        },
      })}

      <RenderAppLogo show />
      {updateAppCenter()}

      {updateAppBarBottom(
        <Weekdays
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          showAtrasadas={showAtrasadas}
          setShowAtrasadas={setShowAtrasadas}
          qtdAtrasadas={qtdAtrasadas}
          diasComAtrasadas={diasComAtrasadas}
        />
      )}

      <ActivitiesListContainer
        activities={activities}
        data={data}
        atividadesAtrasadas={atividadesAtrasadas}
        selectedDay={selectedDay}
        showAtrasadas={showAtrasadas}
        key={agendaKey}
        activityOnClick={activityOnClick}
        onBottomSheetClick={onBottomSheetClick}
        origensOportunidade={origensOportunidade}
        prospeccoesSemContato={prospeccoesSemContato}
        tipoOrigemOportunidade={tipoOrigemOportunidade}
      />

      <AtividadeBottomSheet
        {...props}
        onBottomSheetClick={onBottomSheetClick}
        usuarioLogado={usuarioLogado}
      />

      <SearchTabDialog
        setShowSearchDialog={setShowSearchDialog}
        showSearchDialog={showSearchDialog}
        activityOnClick={activityOnClick}
        onBottomSheetClick={onBottomSheetClick}
        tiposAtividade={tiposAtividade}
        exibirTimer
        exibirBadgeUnidadeNegocio
        somenteAtividadesGerente={
          usuarioLogado?.gerente || usuarioLogado?.administrador
        }
      />

      <EntrarEmContatoDialog
        selectedActivityId={selectedActivity ? selectedActivity.id : undefined}
        selectedActivity={selectedActivity}
        client={selectedActivity ? selectedActivity.cliente : undefined}
        open={showCallClientModal}
        handleClose={() => clearSelected()}
      />

      <RouteDialog
        client={selectedActivity ? selectedActivity.cliente : undefined}
        open={showRouteModal}
        handleClose={() => setShowRouteModal(false)}
      />

      <ReagendamentoAtividadeDialogContainer
        idAtividade={selectedActivity ? selectedActivity.id : undefined}
        dataHoraReagendamento={
          selectedActivity ? selectedActivity.dataHora : undefined
        }
        open={showRescheduleModal}
        handleClose={() => setShowRescheduleModal(false)}
      />

      {notRenderIfConsultorExterno() && (
        <FloatingButton
          icon={<FilterList />}
          onClick={() => {
            showMenuBottomSheet({});
          }}
        />
      )}

      <AgendaFilterBottomSheet
        open={menuOpen}
        onClose={menuBottomSheetOnClose}
        origensOportunidade={origensOportunidade}
        prospeccoesSemContato={prospeccoesSemContato}
        {...props}
      />
    </>
  );
};
