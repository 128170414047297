import CardHeader from '@material-ui/core/CardHeader';
import { Card, CardContent, Chip, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCheckDecagram, mdiTrophy } from '@mdi/js';
import CircularProgressbar from 'react-circular-progressbar';
import CardActions from '@material-ui/core/CardActions';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build';
import { defaultGrey } from '../../../../app/globalStyle';
import { SelectData } from '../../../../utils/SelectData';
import {
  getRgba,
  getStyleOfCard,
  stylesOfCirularProgressbar,
} from '../indicadoresStyleUtils';
import {
  getMessageOportunidades,
  printPorcentagemMaxima,
} from '../IndicadoresCardFunctions';
import { flexDivStyle, indicadoresStyles } from '../indicadoresStyles';
import { eventIndicatorFilterByPeriod } from '../../../../utils/GAUtils';

const objetivosStyles = makeStyles(indicadoresStyles);

const useStylesObjetivos = ({
  customSubtitleColor,
  backgroundColor,
  mainColor,
}) => {
  const style = {
    padding: 0,
    marginBottom: 0,
    customSubtitleColor,
    ...backgroundColor,
  };
  const style1 = {
    minWidth: 100,
    margin: 8,
    marginTop: 0,
  };
  const style2 = {
    margin: '5px 0',
    fontWeight: '600',
    color: defaultGrey,
  };
  const style3 = {
    backgroundColor: 'white',
    color: defaultGrey,
  };
  const style4 = {
    margin: '5px 0',
    fontWeight: 600,
    color: mainColor,
  };
  const style5 = {
    backgroundColor: mainColor,
    color: 'white',
  };
  const style6 = {
    paddingLeft: '16px',
    paddingRight: '16px',
  };
  const style7 = {
    color: mainColor,
  };

  return { style, style1, style2, style3, style4, style5, style6, style7 };
};

export const ObjetivosCardComponent = React.memo(
  ({
    porcentagem,
    titulo,
    diasUteisAteFinalPeriodo,
    meta,
    realizado,
    setPeriodo,
    periodo,
    exibirMensagens,
  }) => {
    const porcentagemString = isNotUndefOrNull(porcentagem)
      ? `${printPorcentagemMaxima(porcentagem)}%`
      : `${0}%`;
    const porcentagemToShow = porcentagem !== null ? porcentagem : 0;

    const {
      customSubtitleColor,
      backgroundColorRgb,
      titleColorRgb,
    } = getStyleOfCard(porcentagemToShow);
    const mainColor = getRgba(titleColorRgb);
    const backgroundColor = {
      backgroundColor: getRgba({ ...backgroundColorRgb }),
    };

    const classes = objetivosStyles({ mainColor });

    const {
      style,
      style2,
      style3,
      style4,
      style5,
      style6,
      style7,
    } = useMemo(
      () =>
        useStylesObjetivos({ customSubtitleColor, backgroundColor, mainColor }),
      [customSubtitleColor, backgroundColor, mainColor]
    );

    const styles = useMemo(() => stylesOfCirularProgressbar(mainColor), [
      mainColor,
    ]);

    return (
      <Card className={classes.card} style={style}>
        <CardHeader
          className={classes.paddingCard}
          classes={{
            title: classes.title,
            action: classes.action,
          }}
          style={style7}
          title={titulo}
          onChange={eventIndicatorFilterByPeriod}
          action={
            <>
              <SelectData
                periodo={periodo}
                setPeriodo={setPeriodo}
                cor={defaultGrey}
              />
            </>
          }
        />

        <CardContent className={classes.cardContent}>
          <div className={classes.cardContentFlex}>
            <div style={flexDivStyle}>
              <Icon path={mdiTrophy} color={defaultGrey} size={1} />
              <Typography variant="subtitle2" style={style2}>
                Meta atual
              </Typography>
              <Chip
                label={meta}
                classes={{
                  root: classes.chipMargin,
                  label: classes.labelChipIndicadores,
                }}
                style={style3}
              />
            </div>

            <div className={classes.circularSize}>
              <CircularProgressbar
                background
                percentage={porcentagemToShow}
                text={porcentagemString}
                strokeWidth={10}
                styles={styles}
              />
            </div>

            <div style={flexDivStyle}>
              <Icon path={mdiCheckDecagram} size={1} color={mainColor} />
              <Typography variant="subtitle2" style={style4}>
                Realizados
              </Typography>

              <Chip
                label={realizado}
                classes={{
                  root: classes.chipMargin,
                }}
                style={style5}
              />
            </div>
          </div>
        </CardContent>

        {exibirMensagens && diasUteisAteFinalPeriodo >= 0 && (
          <CardActions style={style6}>
            {getMessageOportunidades(
              porcentagem,
              diasUteisAteFinalPeriodo,
              meta,
              realizado,
              mainColor,
              classes
            )}
          </CardActions>
        )}
      </Card>
    );
  }
);
