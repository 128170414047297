import { compose, withProps } from 'recompose';
import { genericQueryHoc } from './genericQueryHoc';

export const withQuery = ({
  name,
  queryPropsName,
  gqlInput,
  options,
  skip,
}) => {
  const [, queryHoc, queryName, queryDataName] = genericQueryHoc({
    card: false,
    name,
    gql: gqlInput,
    options,
    skip,
  });

  return component =>
    compose(
      queryHoc,
      withProps(propsCompose => {
        const {
          [queryName]: { [queryDataName]: returnValue },
        } = propsCompose;

        return {
          [queryPropsName]: returnValue,
        };
      })
    )(component);
};
