import { makeStyles } from '@material-ui/styles';
import { mdiMapMarkerRadius } from '@mdi/js/mdi';
import styled from 'styled-components';
import React from 'react';
import Icon from '@mdi/react';
import { defaultOrange } from '../../../app/globalStyle';

export const listStyles = makeStyles(theme => ({
  fullList: {
    width: 'auto',
    paddingBottom: theme.spacing(1),
  },
  title: {
    margin: '10px',
  },
  snackbar: {
    top: 60,
    backgroundColor: defaultOrange,
    color: 'white',
  },
  snackbarContent: {
    width: 360,
  },
  marginRight10: {
    marginRight: '10px',
  },
  moveUp: {
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  moveDown: {
    paddingTop: 60,
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  snackbarFragment: {
    display: 'flex',
    alignContent: 'center',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  chipMargin: {
    marginTop: `${theme.spacing(1 / 2)}px`,
    marginRight: `${theme.spacing(1)}px`,
    height: '20px',
    weight: '20px',
    fontWeight: 500,
    fontSize: '12px',
  },
  divHeader: {
    padding: '10px 10px 0px 10px',
  },
  roteirizar: {
    margin: '20px',
  },
  tituloRota: {
    fontWeight: 'bold',
    padding: '10px 10px 0px 10px',
  },
}));

const IconComponent = ({ className }) => (
  <Icon path={mdiMapMarkerRadius} size={4} className={className} />
);

export const RotasIcon = styled(IconComponent)`
  && {
    margin-top: 50%;
  }
`;
