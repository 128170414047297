import { Typography } from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';
import React from 'react';
import { compose } from 'recompose';
import { updateAppTitle, RenderAppLogo } from './uiUtils';
import { logoutHOC } from '../../core/logout';

const enhance = compose(logoutHOC);

export const NotAllowed = enhance(({ logout }) => (
  <div>
    <RenderAppLogo />
    {updateAppTitle('Permissão Negada')}
    <Typography align="center" variant="body1">
      Você não possui permissão para acessar esse módulo. Efetue o logout
      clicando aqui:
      <br />{' '}
      <Button variant="raised" size="small" onClick={logout}>
        Logout
      </Button>
    </Typography>
  </div>
));
