import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
} from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from 'react-router';

export const SelecaoModeloInteresseDialog = ({
  oportunidadeId,
  open,
  handleClose,
  veiculos,
}) => {
  const history = useHistory();

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle id="simple-dialog-title">
        Test Drive em qual veículo?
      </DialogTitle>
      <DialogContent>
        <List>
          {(veiculos || []).map(veiculo => (
            <ListItem
              key={veiculo && veiculo.id}
              onClick={() => {
                history.push(
                  `/oportunidade/${oportunidadeId}/realizarTestDrive/${veiculo.id}?`
                );
                handleClose();
              }}
            >
              <ListItemText
                primary={
                  veiculo.modelo &&
                  `${veiculo.modelo.nome} - ${veiculo.versao.nome}`
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
