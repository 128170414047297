import React, { useState } from 'react';
import { ExcellenceClubDealerCard } from './ExcellenceClubDealerCard/ExcellenceClubDealerCard';
import { BonusPerformanceCard } from './BonusPerformanceCard/BonusPerformanceCard';
import { DialogIndicadoresExcellenceClub } from './DialogIndicadoresExcellenceClub/DialogIndicadoresExcellenceClub';

export const IndicadoresExcellenceClubDealer = ({
  showDialogAtividades,
  marcas,
  dealerId,
  unidadeNegocioId,
  exibir = true,
  setVendedorFiltro,
}) => {
  const [
    showVendedoresExcellenceClub,
    setShowVendedoresExcellenceClub,
  ] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(null);
  const [tituloDialogVendedores, setTituloDialogVendedores] = useState(false);
  const [porcentagemDialog, setPorcentagemDialog] = useState(null);
  const [dataInicialDialog, setDataInicialDialog] = useState(null);
  const [dataFinalDialog, setDataFinallDialog] = useState(null);
  const [marcaDialog, setMarcaDialog] = useState(null);

  if (!exibir) {
    return <></>;
  }

  return (
    <>
      <ExcellenceClubDealerCard
        setMarcaDialog={setMarcaDialog}
        setDataInicialDialog={setDataInicialDialog}
        setDataFinallDialog={setDataFinallDialog}
        setTituloDialogVendedores={setTituloDialogVendedores}
        showVendedoresExcellenceClub={showVendedoresExcellenceClub}
        setShowVendedoresExcellenceClub={setShowVendedoresExcellenceClub}
        setDialogComponent={setDialogComponent}
        setPorcentagemDialog={setPorcentagemDialog}
        marcas={marcas}
        dealerId={dealerId}
        unidadeNegocioId={unidadeNegocioId}
        showDialogAtividades={showDialogAtividades}
        setVendedorFiltro={setVendedorFiltro}
      />
      <BonusPerformanceCard
        setMarcaDialog={setMarcaDialog}
        setDataInicialDialog={setDataInicialDialog}
        setDataFinallDialog={setDataFinallDialog}
        setTituloDialogVendedores={setTituloDialogVendedores}
        showVendedoresExcellenceClub={showVendedoresExcellenceClub}
        setShowVendedoresExcellenceClub={setShowVendedoresExcellenceClub}
        setDialogComponent={setDialogComponent}
        setPorcentagemDialog={setPorcentagemDialog}
        marcas={marcas}
        dealerId={dealerId}
        unidadeNegocioId={unidadeNegocioId}
        showDialogAtividades={showDialogAtividades}
        setVendedorFiltro={setVendedorFiltro}
      />
      <DialogIndicadoresExcellenceClub
        marca={marcaDialog}
        dealerId={dealerId}
        unidadeNegocioId={unidadeNegocioId}
        dataInicialDialog={dataInicialDialog}
        dataFinalDialog={dataFinalDialog}
        titulo={tituloDialogVendedores}
        show={showVendedoresExcellenceClub}
        setShow={setShowVendedoresExcellenceClub}
        Component={dialogComponent}
        porcentagem={porcentagemDialog}
        entidadeProps={{
          exibirMarca: false,
          showDialogAtividades,
          marcas,
          setVendedorFiltro,
        }}
      />
    </>
  );
};
