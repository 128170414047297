import Big from 'big.js';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import { printProposal } from '../proposal/printProposalHelper';
import { getAlertContext } from '../utils/alertContext';
import {
  displayErrorState,
  displayLoadingState,
} from '../utils/displayLoadingState';
import { getLoadingSpinnerContext } from '../utils/loadingSpinnerContext';
import { podeEditarOportunidade } from './oportunidadeRules';
import { OpportunityComponent } from './OpportunityComponent';
import {
  ProposalGenerateMutation,
  reeditarPropostaMutation,
} from './data/mutations/OpportunityMutations';
import { reeditarPropostaHandler } from './OpportunityTopMenu';
import GetOpportunityVehiclesQueryGql from './data/queries/GetOpportunityVehiclesQueryGql.graphql';
import { OpportunitySelectedTab } from '../../core/Enums';
import { eventClickOpportunityTabs } from '../utils/GAUtils';
import { withShareBottomSheet } from '../providers/ShareBottomSheet/withShareBottomSheet';
import {
  withObrigarModeloInteresseAtivo,
  withObrigarModeloInteresseComVarianteAtivo,
} from './data/queries/crmTermosQuery';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';

const withQuery = graphql(GetOpportunityVehiclesQueryGql, {
  options: ({ match }) => ({
    variables: { id: match.params.id || 0 },
  }),
});

export const getOpportunitySelectedTabName = selectedTab => {
  switch (selectedTab) {
    case OpportunitySelectedTab.VEICULOS.ordinal:
      return OpportunitySelectedTab.VEICULOS.name;
    case OpportunitySelectedTab.PAGAMENTOS.ordinal:
      return OpportunitySelectedTab.PAGAMENTOS.name;
    default:
      return 0;
  }
};

const enhance = compose(
  withUsuarioLogado,
  withObrigarModeloInteresseAtivo,
  withObrigarModeloInteresseComVarianteAtivo,
  withState('propostaUrl', 'setPropostaUrl', null),
  withState('snackBarOpen', 'setSnackBarOpen', true),
  withRouter,
  withQuery,
  displayErrorState,
  reeditarPropostaMutation,
  ProposalGenerateMutation,
  getLoadingSpinnerContext,
  displayLoadingState({ onCacheLoadOnlyQueryName: 'oportunidade' }),
  getAlertContext,
  withShareBottomSheet,
  withHandlers({
    setSelectedTab: ({ history }) => (tab, redirectHome) => {
      history.replace(history.location.pathname, { tab, redirectHome });
      const selectedTab = history.location.state
        ? history.location.state.tab
        : 0;

      eventClickOpportunityTabs(selectedTab);
    },
    reeditarPropostaInput: reeditarPropostaHandler,
    canShareProposal: () => ({ proposta, oportunidade }) => {
      if (!proposta || !oportunidade) {
        return false;
      }

      return (
        proposta?.status?.podeImprimirProposta ||
        !oportunidade.propostaNecessitaAprovacaoGerente
      );
    },
    printProposal,
  }),
  withProps(({ data: { oportunidade = {} }, location }) => {
    const { state: { urlRetorno = null } = {} } = location;

    const { propostas = [], modelosInteresse = [], id } = oportunidade;

    const modelosInteresseMapByAgrupamento = (modelosInteresse || []).reduce(
      (acc, modelo) => {
        const key = modelo.codigoAgrupamento;

        if (isEmptyOrNull(acc[key])) {
          acc[key] = modelo;
        } else {
          acc[key] = { ...modelo, quantidade: modelo.quantidade + 1 };
        }

        return acc;
      },
      {}
    );

    const idProposta = propostas && propostas.length && propostas[0].id;
    const proposta = idProposta ? propostas[0] : null;

    let paymentSumOk = false;

    if (proposta && proposta.formasPagamento) {
      const valorTotal = proposta.valorTotalVeiculosNegociados;
      const valorTotalBig = Big(valorTotal);
      const alreadyAdded = proposta.formasPagamento.reduce(
        (acc, f) => {
          acc.valor = acc.valor.plus(Big(f.valor));
          acc.percentual = acc.percentual.plus(Big(f.percentual));

          return acc;
        },
        { valor: Big(0), percentual: Big(0) }
      );

      paymentSumOk =
        alreadyAdded.valor.eq(valorTotalBig) && alreadyAdded.valor.gt(Big(0));
    }

    const disabledForm =
      !podeEditarOportunidade(oportunidade) || !navigator.onLine;

    return {
      disabledForm,
      modelosInteresseMapByAgrupamento,
      idOportunidade: id,
      idProposta,
      oportunidade,
      activityBranch: { oportunidade, tipoOrigem: 'OPORTUNIDADE' },
      proposta,
      paymentSumOk,
      selectedTab: location.state ? location.state.tab : 0,
      urlRetorno,
    };
  })
);

export const OpportunityContainer = enhance(OpportunityComponent);
