import { Grid } from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';
import React from 'react';
import { Field } from 'redux-form';
import { useGlobalStyle } from '../../../app/globalStyle';
import { RenderInput, SubmitButton } from '../../../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../../utils/uiUtils';
import { eventSaveContact } from '../../../utils/GAUtils';

const style = { marginTop: '10px' };

export const ClientContactsEditor = ({
  handleSubmit,
  initialValues,
  history,
  remove,
  formName = null,
  ...rest
}) => {
  const classes = useGlobalStyle();

  const onClick = () => remove(initialValues.id);

  return (
    <form onSubmit={handleSubmit} className={classes.form} name={formName}>
      <RenderAppLogo />
      {updateAppTitle('Editar Contato')}
      <ShowBackButton history={history} />
      <Grid container>
        <Grid item xs={12}>
          <Field name="nome" label="Nome" component={RenderInput} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="celular"
            label="Celular"
            textMask="cell"
            component={RenderInput}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="telefone"
            label="Telefone"
            textMask="cellphone"
            component={RenderInput}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Field name="email" label="Email" component={RenderInput} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <Field name="cargo" label="Cargo" component={RenderInput} fullWidth />
        </Grid>

        <SubmitButton
          fullWidth
          label="Salvar"
          {...rest}
          onClick={eventSaveContact()}
        />

        {initialValues.id && (
          <Button
            size="large"
            fullWidth
            customVariant="warning"
            style={style}
            onClick={onClick}
          >
            Remover Contato
          </Button>
        )}
      </Grid>
    </form>
  );
};
