import localforage from 'localforage';
import { compose, withProps } from 'recompose';
import { withApollo } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import PushUtils from '../components/PushNotification/pushUtils';

export function logoutHOC(Component) {
  return compose(
    connect(state => ({ keycloak: state.keycloak })),
    withApollo,
    withProps(({ keycloak, client }) => ({
      logout: () => logout(keycloak, client),
    }))
  )(Component);
}

export function logout(keycloakInstance, apolloClient) {
  PushUtils.unsubscribeFromServer(() => {
    // eslint-disable-next-line
    console.log('Unsubscribe from push notification');
    apolloClient
      .clearStore()
      .then(() => localforage.clear())
      .then(() => {
        localStorage.clear();
        keycloakInstance.logout({ redirectUri: window.location.origin });
      });
  });
}
