import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Settings } from './Settings';
import { withSettingsStateDialog } from './withSettingsState';
import { logoutHOC } from '../../core/logout';
import { getAlertContext } from '../utils/alertContext';

const enhance = compose(
  withRouter,
  withSettingsStateDialog,
  logoutHOC,
  getAlertContext
);

export const SettingsContainer = enhance(Settings);
