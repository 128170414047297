import React from 'react';
import { IndicadorExcellenceClubVendedorGenerico } from '../IndicadorExcellenceClubVendedorGenerico/IndicadorExcellenceClubVendedorGenerico';
import { eventOfertaTestDriveIndicadorClick } from '../../../../../../utils/GAUtils';

const IndicadorOfertaTestDrive = React.memo(
  ({
    showDialogAtividades,
    exibirMarca,
    marcas,
    marca,
    setMarca,
    totalTD,
    pendentesTD,
    idsTD,
    colocacao,
    totalVendedores,
    porcentagemTD,
    nomeUsuario,
    idUsuario,
    setVendedorFiltro,
  }) => {
    return (
      <IndicadorExcellenceClubVendedorGenerico
        titulo={nomeUsuario || 'Ofertas de Test-Drive'}
        total={totalTD}
        executado={pendentesTD}
        idsAtividades={idsTD}
        showDialogAtividades={showDialogAtividades}
        mensagemAction="MELHORAR ESSE INDICADOR"
        textoItemDireita="Oportunidades de TD"
        textoItemEsquerda="TD ainda não oferecidos"
        marca={marca}
        marcas={marcas}
        setMarca={setMarca}
        exibirMarca={exibirMarca}
        mensagemDialog="Oferta de Test-drive"
        gaEventClick={eventOfertaTestDriveIndicadorClick}
        colocacao={colocacao}
        totalVendedores={totalVendedores}
        porcentagem={porcentagemTD}
        idUsuario={idUsuario}
        setVendedorFiltro={setVendedorFiltro}
      />
    );
  }
);

export { IndicadorOfertaTestDrive };
export default IndicadorOfertaTestDrive;
