import { useLazyQuery } from '@apollo/client';
import { tipoOrigemOportunidadeQueryGql } from 'components/RealizarAtividade/data/queries/TipoOrigemOportunidadeQuery';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';
import LeadFragment from './LeadFragment';
import { useLeadsSemTratamentoDialogHook } from './LeadsSemTratamentoDialogHook';

const situacaoAtendimentoLead = ['NO_PRAZO', 'FORA_DO_PRAZO'];
const opcoes = ['No prazo', 'Fora do prazo'];
const COR_NO_PRAZO = '#5F8E58';
const COR_FORA_DO_PRAZO = '#E6433F';
const TAMANHO_CONSULTA = 20;

const BUSCA_QUANTIDADE_LEADS_INDICADOR = gql(`
    query BuscaQuantidadeLeadsIndicador($situacao: SituacaoAtendimentoLead!, $status: StatusProspeccao) {
        buscaQuantidadeLeadsIndicador(situacao: $situacao, status: $status)
    }
`);

const BUSCA_LEADS_INDICADOR = gql(`
    query BuscaLeadsIndicador($situacao: SituacaoAtendimentoLead!, $pagina: Int!, $limite: Int!) {
      buscaLeadsIndicador(situacao: $situacao, pagina: $pagina, limite: $limite) {
        ${LeadFragment}
      }
    }
`);

export const useIndicadorLeadsSemTratamentoHook = () => {
  const [buscaQuantidadeLeadsIndicadorGQL, qtdLeadsData] = useLazyQuery(
    BUSCA_QUANTIDADE_LEADS_INDICADOR
  );

  const [
    buscaQuantidadeLeadsQualificacaoIndicadorGQL,
    qtdLeadsQualificacaoData,
  ] = useLazyQuery(BUSCA_QUANTIDADE_LEADS_INDICADOR);

  const [buscaLeadsIndicadorGQL, leadsData] = useLazyQuery(
    BUSCA_LEADS_INDICADOR
  );

  const [tipoOrigemOportunidadeGQL, tiposOrigemData] = useLazyQuery(
    tipoOrigemOportunidadeQueryGql
  );

  const { dialogOpened, setDialogOpened } = useLeadsSemTratamentoDialogHook();

  const [situacaoLead, setSituacaoLead] = useState(0);
  const [data, setData] = useState([]);
  const cor = situacaoLead === 0 ? COR_NO_PRAZO : COR_FORA_DO_PRAZO;
  const qtdLeads = qtdLeadsData?.data?.buscaQuantidadeLeadsIndicador || 0;
  const qtdLeadsQualificacao =
    qtdLeadsQualificacaoData?.data?.buscaQuantidadeLeadsIndicador || 0;
  const temMaisLeadsCarregar = qtdLeads - qtdLeadsQualificacao > data.length;
  const loading =
    qtdLeadsData?.data?.buscaQuantidadeLeadsIndicador === undefined;
  const dialogTitle =
    situacaoLead === 0
      ? 'Leads sem tratamento no prazo'
      : 'Leads sem tratamento fora do prazo';
  const tiposOrigemOportunidade =
    tiposOrigemData.data?.tipoOrigemOportunidade || [];

  const handleVisualizarLeads = useCallback(() => {
    setData([]);
    setDialogOpened(true);
    buscaLeadsIndicadorGQL({
      variables: {
        situacao: situacaoAtendimentoLead[situacaoLead],
        pagina: 0,
        limite: TAMANHO_CONSULTA,
      },
    });
    buscaQuantidadeLeadsQualificacaoIndicadorGQL({
      variables: {
        situacao: situacaoAtendimentoLead[situacaoLead],
        status: 'QUALIFICACAO',
      },
    });
  }, [
    situacaoLead,
    setDialogOpened,
    buscaLeadsIndicadorGQL,
    buscaQuantidadeLeadsQualificacaoIndicadorGQL,
  ]);

  const onChange = useCallback(
    event => {
      setSituacaoLead(event.target.value);
      qtdLeadsData.data = undefined;
    },
    [setSituacaoLead, qtdLeadsData]
  );

  const carregaMaisLeads = useCallback(
    pagina => {
      // acaua.amantea@carrera.com.br
      // adailton.farias@nissauto.com.br
      if (temMaisLeadsCarregar && !leadsData.loading) {
        buscaLeadsIndicadorGQL({
          variables: {
            situacao: situacaoAtendimentoLead[situacaoLead],
            pagina,
            limite: TAMANHO_CONSULTA,
          },
        });
      }
    },
    [
      buscaLeadsIndicadorGQL,
      leadsData.loading,
      situacaoLead,
      temMaisLeadsCarregar,
    ]
  );

  useEffect(() => {
    buscaQuantidadeLeadsIndicadorGQL({
      variables: {
        situacao: situacaoAtendimentoLead[situacaoLead],
      },
    });
  }, [situacaoLead, buscaQuantidadeLeadsIndicadorGQL]);

  useEffect(() => {
    if (leadsData?.data && !leadsData?.loading) {
      const novosLeads = leadsData?.data.buscaLeadsIndicador;

      if (novosLeads.length > 0) {
        setData(prev => [...prev, ...novosLeads]);
      }
    }
  }, [leadsData, setData]);

  useEffect(() => {
    tipoOrigemOportunidadeGQL();
  }, [tipoOrigemOportunidadeGQL]);

  return {
    leads: data,
    situacaoLead,
    cor,
    loading,
    qtdLeads,
    opcoes,
    dialogTitle,
    dialogOpened,
    tiposOrigemOportunidade,
    qtdLeadsQualificacao,
    temMaisLeadsCarregar,
    isCarregandoMaisLeads: leadsData.loading,
    setDialogOpened,
    onChange,
    handleVisualizarLeads,
    carregaMaisLeads,
  };
};
