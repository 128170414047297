import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { getAlertContext } from '../alertContext';
import { getLoadingSpinnerContext } from '../loadingSpinnerContext';
import { ErrorOffline } from '../../../core/offline/exception/ErrorOffline';
import { mutationsErrorsHandler } from '../mutationsErrorsHandler';

export const StatusOportunidadeHOC = ({ mutation, mutationName }) =>
  compose(
    mutation,
    getLoadingSpinnerContext,
    getAlertContext,
    withRouter,
    withHandlers({
      onChangeEmNegociacao: ({
        history,
        hideLoading,
        showLoading,
        showAlert,
        ...props
      }) => ({ id, emNegociacao, goBack = false }) => {
        showLoading();
        props[mutationName]({ variables: { id, emNegociacao: !emNegociacao } })
          .then(() => {
            hideLoading();

            if (goBack) {
              history.goBack();
            }
          })
          .catch(e => {
            if (e instanceof ErrorOffline) {
              hideLoading();

              return;
            }

            mutationsErrorsHandler({ showAlert, hideLoading })(e);
          });
      },
    })
  );
