import { makeStyles } from '@material-ui/styles';
import { Card } from '@tecsinapse/ui-kit';
import { useLembreteAtividadeHook } from 'components/Agendador/LembreteAtividadeHook';
import React from 'react';
import { BottomSheetClickEnum } from '../../../core/Enums';
import { useUsuarioLogado } from '../../../hooks/useUsuarioLogado';
import { defaultOrange } from '../../app/globalStyle';
import { realizarAtividadeVisita } from '../../Atividade/atividadeFunctions';
import {
  eventCallClientActivityCard,
  eventEditClientActivityCard,
  eventEditOpportunityActivityCard,
  eventInformCallActivityCard,
  eventPerformTestDriveActivityCard,
  eventPerformVisitActivityCard,
  eventRescheduleActivityActivityCard,
  eventSeeHistoryActivityCard,
} from '../../utils/GAUtils';
import {
  cardStyles,
  getDadosOportunidade,
  getDescricaoTipoOrigemProspeccao,
  getStyleOfCardNew,
  isSemContato,
} from './activityCardFunctions';
import { CardActions } from './CardActions/CardActions';
import { SubContent } from './SubContent/SubContent';
import { title } from './title';
import { Content } from './Content/Content';

const useCardStyles = makeStyles(cardStyles);

export const ActivityCard = ({
  activity,
  activityOnClick,
  onBottomSheetClick,
  style,
  onLoad,
  showSegments,
  isIdInEntitiesList,
  usuarioGerente,
  usuarioAdmin,
  tipoOrigemOportunidade,
  crmProspeccaoNomePattern,
  usuarioLogadoNome,
  exibirTimer,
  exibirBadgeUnidadeNegocio,
}) => {
  const { usuarioLogado } = useUsuarioLogado();

  const classes = useCardStyles();
  const { verificaPossuiLembrete } = useLembreteAtividadeHook(activity);

  const {
    finalizado,
    dataHoraExibicao,
    clienteNome,
    atrasada,
    avulsa,
    temperatura: temperaturaInput,
    responsavel: responsavelInput,
  } = activity;

  const descricaoTipoOrigemProspeccao = getDescricaoTipoOrigemProspeccao(
    activity,
    tipoOrigemOportunidade
  );

  const { responsavel, temperatura } = getDadosOportunidade(
    temperaturaInput,
    responsavelInput
  );

  const offlinePendente =
    isIdInEntitiesList &&
    isIdInEntitiesList({
      id: parseInt(activity.id, 10),
      entityName: 'atividade',
      mutationName: null,
    });

  const styleCard = getStyleOfCardNew(
    offlinePendente,
    finalizado,
    atrasada,
    isSemContato(activity)
  );

  const eventGA = selectedActivity => {
    if (realizarAtividadeVisita(selectedActivity)) {
      eventPerformVisitActivityCard();
    } else {
      eventInformCallActivityCard();
    }
  };

  const subtitle = clienteNome.toLowerCase();
  const showCurrentUserPending = responsavel !== usuarioLogadoNome;

  const style7 = {
    backgroundColor: defaultOrange,
    color: '#FFF',
  };
  const style8 = {
    backgroundColor: temperatura && temperatura.cor,
    color: '#FFF',
  };

  const handleClickInformCall = () => {
    eventGA(activity);
    onBottomSheetClick(BottomSheetClickEnum.INFORM_CALL, activity);
  };

  const handleClickCallClient = () => {
    eventCallClientActivityCard();
    onBottomSheetClick(BottomSheetClickEnum.CALL_CLIENT, activity);
  };

  const handleClickEditOpportunity = () => {
    eventEditOpportunityActivityCard();
    onBottomSheetClick(BottomSheetClickEnum.EDIT_OPPORTUNITY, activity);
  };

  const handleClickEditClient = () => {
    eventEditClientActivityCard();
    onBottomSheetClick(BottomSheetClickEnum.EDIT_CLIENT, activity);
  };

  const handleClickRescheduleActivity = () => {
    eventRescheduleActivityActivityCard();
    onBottomSheetClick(BottomSheetClickEnum.RESCHEDULE_ACTIVITY, activity);
  };

  const handleClickSeeHistory = () => {
    eventSeeHistoryActivityCard();
    onBottomSheetClick(BottomSheetClickEnum.SEE_HISTORY, activity);
  };

  const handleClickTestDrive = () => {
    eventPerformTestDriveActivityCard();
    onBottomSheetClick(BottomSheetClickEnum.TEST_DRIVE, activity);
  };

  const handleCardClick = e => activityOnClick(e, activity, offlinePendente);

  return (
    <Card
      key={activity.id}
      style={style}
      styleCard={styleCard}
      onClick={handleCardClick}
      title={title({
        dataHoraExibicao,
        activity,
        showSegments,
        crmProspeccaoNomePattern,
        finalizado,
        classes,
        possuiLembrete: !!verificaPossuiLembrete(),
      })}
      subtitle={subtitle}
      content={<Content activity={activity}/>}
      subContent={
        <SubContent
          offlinePendente={offlinePendente}
          classes={classes}
          style7={style7}
          style8={style8}
          temperatura={temperatura}
          activity={activity}
          descricaoTipoOrigemProspeccao={descricaoTipoOrigemProspeccao}
          usuarioGerente={usuarioGerente}
          usuarioAdmin={usuarioAdmin}
          responsavel={responsavel}
          showCurrentUserPending={showCurrentUserPending}
          avulsa={avulsa}
          exibirTimer={exibirTimer}
          exibirBadgeUnidadeNegocio={exibirBadgeUnidadeNegocio}
        />
      }
      actions={
        <CardActions
          usuarioLogado={usuarioLogado}
          activity={activity}
          offlinePendente={offlinePendente}
          classes={classes}
          handleClickCallClient={handleClickCallClient}
          handleClickInformCall={handleClickInformCall}
          handleClickEditOpportunity={handleClickEditOpportunity}
          handleClickEditClient={handleClickEditClient}
          handleClickRescheduleActivity={handleClickRescheduleActivity}
          handleClickSeeHistory={handleClickSeeHistory}
          handleClickTestDrive={handleClickTestDrive}
        />
      }
      classes={classes}
      onLoad={onLoad}
    />
  );
};
