import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useUploadButton = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

export const UploadButton = ({ label, icon, capture, accept, onChange }) => {
  const classes = useUploadButton();

  return (
    <>
      <input
        type="file"
        id="contained-button-file"
        accept={accept}
        capture={capture}
        className={classes.input}
        onChange={e => onChange(e)}
      />
      <label htmlFor="contained-button-file">
        <Button color="primary" variant="contained" fullWidth component="span">
          {icon}
          {label}
        </Button>
      </label>
    </>
  );
};
