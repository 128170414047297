import { compose, getContext, withContext } from 'recompose';
import PropTypes from 'prop-types';
import { App } from './App';
import { displayLoadingState } from '../utils/displayLoadingState';
import { showAlertAction } from '../../reducers/alert';
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from '../../reducers/loadingSpinner';
import { showCreateOpportunity } from '../../reducers/modal';
import { ClientCreateOptionEnum } from '../../core/Enums';
import { withStoreConsumer } from '../utils/StoreConsumer';
import { setRegistroAcesso } from '../../reducers/registroAcessoReducer';
import { RegistrarAcessoMutation } from './RegistrarAcessoMutation';

export const AppContainer = compose(
  displayLoadingState(),
  withStoreConsumer,
  withContext(
    {
      setRegistraAcesso: PropTypes.func,
      showAlert: PropTypes.func,
      showLoading: PropTypes.func,
      hideLoading: PropTypes.func,
      showCreateOpportunityModal: PropTypes.func,
      showCreateProspectionModal: PropTypes.func,
      showCreateAtividadeFichaVisitaModal: PropTypes.func,
      showCreateRoteirizacaoModal: PropTypes.func,
      createModal: PropTypes.func,
      keycloak: PropTypes.object,
    },
    ({ store }) => ({
      setRegistraAcesso: () => {
        store.dispatch(setRegistroAcesso());
      },
      showAlert: ({ title, message, ...rest }) => {
        store.dispatch(showAlertAction({ title, message, ...rest }));
      },
      showLoading: () => {
        store.dispatch(showLoadingSpinner());
      },

      hideLoading: () => {
        store.dispatch(hideLoadingSpinner());
      },
      showCreateOpportunityModal: ({ idCliente }) => {
        store.dispatch(
          showCreateOpportunity({
            idCliente,
            clientCreateOption: ClientCreateOptionEnum.OPPORTUNITY,
          })
        );
      },
      showCreateProspectionModal: ({ idCliente }) => {
        store.dispatch(
          showCreateOpportunity({
            idCliente,
            clientCreateOption: ClientCreateOptionEnum.PROSPECTION,
          })
        );
      },
      showCreateAtividadeFichaVisitaModal: ({
        idCliente,
        roteirizacaoClienteId,
        ultimaAtividadeFichaVisitaAbertaPk,
      }) => {
        store.dispatch(
          showCreateOpportunity({
            idCliente,
            roteirizacaoClienteId,
            ultimaAtividadeFichaVisitaAbertaPk,
            clientCreateOption:
              ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA,
          })
        );
      },
      showCreateRoteirizacaoModal: ({ urlMapa }) => {
        store.dispatch(
          showCreateOpportunity({
            urlMapa,
            clientCreateOption: ClientCreateOptionEnum.GERAR_ROTEIRIZACAO,
          })
        );
      },
      createModal: ({ id, clientCreateOption, message, ...rest }) =>
        store.dispatch(
          showCreateOpportunity({
            idCliente: id,
            clientCreateOption,
            message,
            ...rest,
          })
        ),
      keycloak: store.getState().keycloak,
    })
  ),
  getContext({
    setRegistraAcesso: PropTypes.func,
  }),
  RegistrarAcessoMutation
)(App);
