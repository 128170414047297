import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import { displayLoadingState } from 'components/utils/displayLoadingState';
import GetClientByIdQueryGql from './GetClientByIdQueryGql.graphql';

export const EditClientQuery = graphql(GetClientByIdQueryGql, {
  name: 'editClientQuery',
  options: ({ idClient }) => ({
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
    variables: {
      id: idClient || 0,
      nullId: isEmptyOrNull(idClient),
    },
  }),
});

export const EditClientQueryHoc = compose(
  EditClientQuery,
  displayLoadingState({
    name: 'editClientQuery',
    onCacheLoadOnlyQueryName: 'cliente',
  })
);
