import React, { Component } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurer';
import List from 'react-virtualized/dist/commonjs/List';
import isEqual from 'lodash.isequal';
import { CalendarToday } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import { EmptyState } from '@tecsinapse/ui-kit';

import { ActivityCard } from './ActivityCard/ActivityCard';
import { isModuloCRM } from '../utils/utils';

const rowRenderer = ({ key, index, style, parent }) => {
  const {
    props: {
      activities,
      activityOnClick,
      history,
      onBottomSheetClick,
      cache,
      showSegments,
      isIdInEntitiesList,
      usuarioGerente,
      usuarioAdmin,
      usuarioLogado,
      tipoOrigemOportunidade,
      crmProspeccaoNomePattern,
    } = {},
  } = parent;

  return (
    <CellMeasurer
      cache={cache}
      key={key}
      parent={parent}
      rowIndex={index}
      columnIndex={0}
    >
      <ActivityCard
        key={key}
        showSegments={showSegments}
        activity={activities[index]}
        history={history}
        style={style}
        activityOnClick={activityOnClick}
        onBottomSheetClick={onBottomSheetClick}
        isIdInEntitiesList={isIdInEntitiesList}
        usuarioGerente={usuarioGerente}
        usuarioAdmin={usuarioAdmin}
        usuarioLogado={usuarioLogado}
        tipoOrigemOportunidade={tipoOrigemOportunidade}
        crmProspeccaoNomePattern={crmProspeccaoNomePattern}
        exibirTimer={isModuloCRM}
        exibirBadgeUnidadeNegocio={isModuloCRM}
      />
    </CellMeasurer>
  );
};

export class ActivitiesList extends Component {
  constructor(props) {
    super(props);
    const cachedHeight = localStorage.getItem('cache');

    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      // eslint-disable-next-line
      keyMapper: index => this.props.activities[index].id,
    });

    if (cachedHeight != null) {
      this._cache._rowHeightCache = JSON.parse(cachedHeight);
    }
    this.listRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { activities } = this.props;

    if (!isEqual(nextProps.activities, activities)) {
      if (
        this.listRef &&
        this.listRef.current &&
        this.listRef.current.recomputeRowHeights
      ) {
        this.listRef.current.recomputeRowHeights(0);
        this.listRef.current.forceUpdateGrid();
      }

      return true;
    }

    return false;
  }

  componentWillUnmount() {
    localStorage.setItem(
      'cache',
      JSON.stringify(this._cache._rowHeightCache || [])
    );
  }

  render() {
    const {
      activities,
      showSegments,
      isIdInEntitiesList,
      usuarioGerente,
      usuarioAdmin,
      usuarioLogado,
      crmProspeccaoNomePattern,
      tipoOrigemOportunidade,
      ...props
    } = this.props;

    return (
      <>
        {activities?.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                ref={this.listRef}
                rowCount={activities.length}
                rowHeight={this._cache.rowHeight}
                deferredMeasurementCache={this._cache}
                overscanRowCount={10}
                rowRenderer={rowRenderer}
                activities={activities}
                showSegments={showSegments}
                usuarioGerente={usuarioGerente}
                usuarioAdmin={usuarioAdmin}
                cache={this._cache}
                isIdInEntitiesList={isIdInEntitiesList}
                scrollToIndex={0}
                usuarioLogado={usuarioLogado}
                crmProspeccaoNomePattern={crmProspeccaoNomePattern}
                tipoOrigemOportunidade={tipoOrigemOportunidade}
                {...props}
              />
            )}
          </AutoSizer>
        ) : (
          <EmptyState
            message="Não há atividades disponíveis."
            IconComponent={CalendarToday}
          />
        )}
      </>
    );
  }
}

ActivitiesList.propTypes = {
  activities: PropTypes.arrayOf(Object).isRequired,
  showSegments: PropTypes.bool.isRequired,
};
