import { mdiClock, mdiClockAlert } from '@mdi/js';
import moment from 'moment';
import { useCallback, useState } from 'react';

const AGUARDE = 'Aguarde...';
const NO_PRAZO = 'No prazo';
const FORA_DO_PRAZO = 'Fora do prazo';

export const useHotleadCountDownHook = atividade => {
  const { hotLeadCountDownInfo } = atividade;
  const [labelInterno, setLabelInterno] = useState(AGUARDE);
  const timerAtivo =
    !!hotLeadCountDownInfo && !hotLeadCountDownInfo?.unavailable;
  const diferencaTempoLocal = hotLeadCountDownInfo?.deadline
    ? moment(hotLeadCountDownInfo?.deadline).diff(Date.now())
    : 0;
  const statusAberto =
    hotLeadCountDownInfo?.onTime &&
    hotLeadCountDownInfo?.remainingSeconds > 0 &&
    diferencaTempoLocal > 0;
  const statusNoPrazo =
    hotLeadCountDownInfo?.onTime &&
    hotLeadCountDownInfo?.remainingSeconds === 0;
  const statusForaDoPrazo =
    (!hotLeadCountDownInfo?.onTime &&
      hotLeadCountDownInfo?.remainingSeconds === 0) ||
    (!statusAberto && diferencaTempoLocal <= 0);
  const tempoRestante = diferencaTempoLocal / 1000;

  let icon;
  let badgeColor;
  let contentColor;
  let descricaoBadge = labelInterno;

  if (statusAberto) {
    contentColor = '#5F8E58';
    badgeColor = '#3B5937';
    icon = mdiClock;
  }

  if (statusForaDoPrazo) {
    contentColor = '#E6433F';
    badgeColor = '#982C2A';
    icon = mdiClockAlert;
    descricaoBadge = FORA_DO_PRAZO;
  }

  if (statusNoPrazo) {
    contentColor = '#5F8E58';
    badgeColor = '#3B5937';
    icon = mdiClock;
    descricaoBadge = NO_PRAZO;
  }

  const handleCountDownOver = useCallback(() => {
    setLabelInterno(FORA_DO_PRAZO);
  }, [setLabelInterno]);

  return {
    icon,
    timerAtivo,
    badgeColor,
    contentColor,
    tempoRestante: statusAberto ? tempoRestante : 0,
    descricaoAposTerminoTempo: descricaoBadge,
    handleCountDownOver,
  };
};
