import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';

const informarContatoClienteMutationGql = gql`
  mutation informarContatoClienteMutation(
    $idCliente: ID
    $idUnidadeNegocio: ID
    $idTipoContato: ID
  ) {
    informarContatoCliente(
      idCliente: $idCliente
      idUnidadeNegocio: $idUnidadeNegocio
      idTipoContato: $idTipoContato
    ) {
      tipoAcaoInformarContato
      idCliente
      atividades {
        id
        tipoOrigem
        idOrigem
        descricao
        dataHoraExibicao
        unidadeNegocio {
          id
          descricao
        }
        oportunidade {
          id
          modelosInteresse {
            id
            versao {
              id
              nome
              ativo
            }
            modelo {
              id
              nome
              ativo
              familia {
                id
                nome
                ativo
              }
            }
          }
        }
      }
      unidadeNegocio {
        id
      }
    }
  }
`;

export const informarContatoClienteMutation = graphqlOfflineMutation(
  informarContatoClienteMutationGql,
  {
    name: 'informarContatoCliente',
    mutationName: 'informarContatoClienteMutationGql',
    showSucessAlert: false,
  }
);
