import { Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'redux-form';
import { RenderInput, SubmitButton } from '../../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../utils/uiUtils';
import { useGlobalStyle } from '../../app/globalStyle';
import { eventSaveCommentTimeLine } from '../../utils/GAUtils';

export const AddCommentEditor = ({
  handleSubmit,
  history,
  formName,
  ...rest
}) => {
  const classes = useGlobalStyle();

  return (
    <form onSubmit={handleSubmit} className={classes.form} name={formName}>
      <RenderAppLogo />
      {updateAppTitle('Adicionar Comentário')}
      <ShowBackButton history={history} />
      <Grid container>
        <Grid item xs={12}>
          <Field
            name="comment"
            label="Comentário"
            multiline
            component={RenderInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            onClick={eventSaveCommentTimeLine()}
            fullWidth
            label="Salvar Comentário"
            {...rest}
          />
        </Grid>
      </Grid>
    </form>
  );
};
