import { Avatar, Chip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const BadgeIndicator = ({ badge, badgeColor, label, contentColor }) => {
  return (
    <ChipIndicator
      size="small"
      color="primary"
      label={label}
      contentColor={contentColor}
      avatar={
        <Avatar style={{ ...AvatarStyle, backgroundColor: badgeColor }}>
          {badge}
        </Avatar>
      }
    />
  );
};

const AvatarStyle = {
  fontSize: '12px',
  marginLeft: 0,
  marginRight: '-3px',
  padding: '1px 3px',
  borderRadius: '10px',
  fontWeight: 700,
};

const ChipIndicator = styled(Chip)`
  background-color: ${({ contentColor }) => contentColor};
  height: 20px;
  margin-top: 4px;
  margin-right: 5px;
  font-size: 12px;
`;
