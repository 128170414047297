import React, { useContext, useEffect, useState } from 'react';
import { decodeChatData } from '@tecsinapse/chat-component/build/utils/decodeChatData';
import { ClientSelectedTab } from '../../../../core/Enums';
import {
  ShowBackButton,
  updateAppBarBottom,
  updateAppTitle,
  RenderAppLogo,
} from '../../../utils/uiUtils';
import { ClientContactsContainer } from '../../clienteContacts/ui/ClientContactsContainer';
import { ClienteTabs } from './ClienteTabs';
import { ClienteFrotaContainer } from '../../clienteFrota/ui/ClienteFrotaContainer';
import { ClienteEditorContainer } from './ClienteEditorContainer';
import { keycloak } from '../../../../core/offline/kcOfflineStub';
import { chatStore } from '../../../Chat/ChatStore';

export const getClientSelectedTabName = selectedTab => {
  switch (selectedTab) {
    case ClientSelectedTab.CLIENTE.ordinal:
      return ClientSelectedTab.CLIENTE.name;
    case ClientSelectedTab.FROTA.ordinal:
      return ClientSelectedTab.FROTA.name;
    case ClientSelectedTab.CONTATOS.ordinal:
      return ClientSelectedTab.CONTATOS.name;
    default:
      return 0;
  }
};

export const ClienteComponent = ({
  selectedTab,
  idClient,
  history,
  urlRetorno,
  chatData,
  centralClienteContatosAtivo: { centralClienteContatosAtivo: contatosAtivo },
  centralClienteFrotaAtivo: { centralClienteFrotaAtivo: frotaAtiva },
  ...props
}) => {
  const { state, dispatch } = useContext(chatStore);
  const renewChat = () => dispatch({ type: 'update' });
  const [render, setRender] = useState(false);

  let action = null;

  if (urlRetorno) {
    const url = decodeURIComponent(urlRetorno);

    action = () => history.push(url !== 'home' ? `/${url}` : '/agenda');
  }

  let chatObj;

  if (chatData) {
    const chatDecoded = decodeChatData(chatData, keycloak.idTokenParsed.sub);

    chatObj = JSON.parse(chatDecoded.data);
  }

  /**
   * BAD, VERY BAD: Necessário para forçar um re-render quando é acessado a tela
   * de clientes pelo chat.
   */
  useEffect(() => {
    setRender(false);
    setTimeout(() => setRender(true), 50);
    // eslint-disable-next-line
  }, [window.location.pathname, setRender]);

  return (
    <>
      <RenderAppLogo />
      {updateAppTitle('Cliente')}
      <ShowBackButton history={history} action={action} />
      {updateAppBarBottom(
        <ClienteTabs
          selectedTab={selectedTab}
          renderFrota={!!idClient && frotaAtiva}
          renderContacts={!!idClient && contatosAtivo}
          {...props}
        />
      )}
      {render && selectedTab === ClientSelectedTab.CLIENTE.ordinal && (
        <ClienteEditorContainer
          chatAtivo={state?.chatAtivo}
          updateChat={renewChat}
          chatData={chatObj}
          idClient={idClient}
        />
      )}
      {selectedTab === ClientSelectedTab.FROTA.ordinal && (
        <ClienteFrotaContainer idClient={idClient} />
      )}
      {selectedTab === ClientSelectedTab.CONTATOS.ordinal && (
        <ClientContactsContainer idClient={idClient} />
      )}
    </>
  );
};
