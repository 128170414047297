import React from 'react';
import { CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { defaultGreyLight3, useGlobalStyle } from '../app/globalStyle';
import { LoadingLogo } from './Loading';

const styleProps = size => ({
  color: defaultGreyLight3,
  electronsWidth: `${size}px`,
  electronMarginLeft: `-${size / 2}px`,
  funilWidth: `${size / 2}px`,
  funilTop: `${size / 2}px`,
  funilMarginLeft: `-${size / 4}px`,
  funilMarginTop: `-${size / 4}px`,
});

const style = {
  minHeight: '177px',
};

export const CardLoading = () => {
  const classes = useGlobalStyle();

  const styleProps1 = styleProps(60);

  return (
    <div>
      <Card
        className={classes.card}
        classes={{ root: classes.cardBasis }}
        style={style}
      >
        <CardContent>
          <div className={classes.marginTop15}>
            <LoadingLogo styleProps={styleProps1} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
