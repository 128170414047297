import { compose, withHandlers, withState } from 'recompose';
import { withApollo } from '@apollo/react-hoc';
import { BottomSheetClickEnum, TipoOrigemAtividade } from '../../core/Enums';
import { getAdressStringOfActivity, goToRoute } from '../utils/RouteDialog';
import {
  allowActionsModeloAtivo,
  realizaTesteDrive,
} from '../Atividade/atividadeFunctions';
import { withAtualizaModeloInativo } from '../AtualizaModeloInativo/withAtualizaModeloInativo';
import { getAlertContext } from '../utils/alertContext';
import { useLazyAtividadeOportunidadeQuery } from '../../hooks/useLazyAtividadeOportunidadeQuery';

const showAlertSeOffline = ({ showAlert }) => {
  if (!navigator.onLine) {
    showAlert({
      message: 'Não é possível realizar esta ação',
      title: 'Offline',
      variant: 'warning',
    });

    return true;
  }

  return false;
};

export const withActivityBottomSheet = Component =>
  compose(
    withApollo,
    withState('showSearchDialog', 'setShowSearchDialog', false),
    withState('selectedActivity', 'setSelectedActivity', null),
    withState('showCallClientModal', 'setShowCallClientModal', false),
    withState('showBottomSheetActions', 'setShowBottomSheetActions', false),
    withState('showRescheduleModal', 'setShowRescheduleModal', false),
    withState('showRouteModal', 'setShowRouteModal', false),
    withState('isOfflinePendente', 'setIsOfflinePendente', false),
    withAtualizaModeloInativo,
    getAlertContext,
    withHandlers({
      clearSelected: ({
        setShowCallClientModal,
        setShowBottomSheetActions,
      }) => () => {
        setShowCallClientModal(false);
        setShowBottomSheetActions(false);
      },
      activityOnClick: ({
        setSelectedActivity,
        setShowBottomSheetActions,
        setIsOfflinePendente,
      }) => (e, activity, offlinePendente) => {
        e.preventDefault();
        setSelectedActivity(activity);
        setIsOfflinePendente(offlinePendente);
        setShowBottomSheetActions(true);
      },
      onBottomSheetClick: ({
        setShowCallClientModal,
        setShowRescheduleModal,
        setShowRouteModal,
        selecaoModelosEAbreDialog,
        setSelectedActivity,
        history,
        showAlert,
        selectedActivity,
        atualizaModeloInativoEAbreDialog,
        client,
        usuarioConsultorExterno,
      }) => async (event, activity) => {
        const actionActivity = await useLazyAtividadeOportunidadeQuery({
          event,
          activity,
          selectedActivity,
          client,
        });

        switch (event) {
          case BottomSheetClickEnum.CALL_CLIENT:
            setSelectedActivity(actionActivity);
            setShowCallClientModal(true);
            break;
          case BottomSheetClickEnum.TEST_DRIVE:
            setSelectedActivity(actionActivity);

            if (showAlertSeOffline({ showAlert })) {
              return;
            }

            if (
              !allowActionsModeloAtivo(
                actionActivity,
                (veiculos, idOportunidade) =>
                  atualizaModeloInativoEAbreDialog({
                    veiculos,
                    idOportunidade,
                    postAction: () =>
                      realizaTesteDrive({
                        actionActivity,
                        showAlert,
                        history,
                        selecaoModelosEAbreDialog,
                      }),
                  })
              )
            ) {
              break;
            } else {
              realizaTesteDrive({
                actionActivity,
                showAlert,
                history,
                selecaoModelosEAbreDialog,
              });
            }
            break;
          case BottomSheetClickEnum.RESCHEDULE_ACTIVITY:
            if (showAlertSeOffline({ showAlert })) {
              return;
            }

            setSelectedActivity(actionActivity);

            if (
              !allowActionsModeloAtivo(
                actionActivity,
                (veiculos, idOportunidade) =>
                  atualizaModeloInativoEAbreDialog({
                    veiculos,
                    idOportunidade,
                    postAction: () => setShowRescheduleModal(true),
                  })
              )
            ) {
              break;
            } else {
              setShowRescheduleModal(true);
            }
            break;
          case BottomSheetClickEnum.PERFORM_CHECKIN: {
            const telaCheckIn = usuarioConsultorExterno
              ? 'checkInConfirmacao'
              : 'checkInCRMConfirmacao';

            history.push(`/${telaCheckIn}/${actionActivity.cliente.id}/agenda`);
            break;
          }
          case BottomSheetClickEnum.INFORM_CALL:
            if (
              !navigator.onLine &&
              actionActivity.tipoOrigem ===
                TipoOrigemAtividade.FICHA_VISITA.name
            ) {
              showAlert({
                message:
                  'Offline - Não é possível realizar atividade de visita',
                title: 'Erro',
                variant: 'warning',
                chip: true,
              });
              break;
            }
            history.push(`/realizarAtividade/${actionActivity.id}`);
            break;
          case BottomSheetClickEnum.EDIT_CLIENT:
            history.push(`/editarCliente/${actionActivity.cliente.id}`);
            break;
          case BottomSheetClickEnum.EDIT_OPPORTUNITY:
            history.push(`/oportunidade/${actionActivity.idOrigem}`);
            break;
          case BottomSheetClickEnum.SEE_HISTORY:
            if (showAlertSeOffline({ showAlert })) {
              return;
            }

            history.push(`/timeline/${actionActivity.id}`);
            break;
          case BottomSheetClickEnum.GO_TO_ROUTE: {
            const addressString = getAdressStringOfActivity(
              actionActivity.cliente
            );

            if (actionActivity?.cliente?.endereco?.numero) {
              goToRoute(addressString);
            } else {
              setShowRouteModal(true);
            }
            break;
          }
          default:
            break;
        }
      },
      hideDrawer: ({
        setSelectedActivity,
        setShowBottomSheetActions,
      }) => () => {
        setSelectedActivity(null);
        setShowBottomSheetActions(false);
      },
    })
  )(Component);
