import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import { genericQueryHoc } from '../../../utils/crud/genericQueryHoc';

export const MotivosTestDriveNaoRealizadoQueryGql = gql`
  query motivosTestDriveNaoRealizado {
    motivosTestDriveNaoRealizado {
      id
      nome
    }
  }
`;

export const withMotivosTestDriveNaoRealizado = () => {
  const [
    MotivosTestDriveNaoRealizadoQueryHoc,
    ,
    MotivosTestDriveNaoRealizadoQueryDataName,
  ] = genericQueryHoc({
    card: false,
    name: 'motivosTestDriveNaoRealizado',
    gql: MotivosTestDriveNaoRealizadoQueryGql,
    options: () => ({
      fetchPolicy: 'cache-first',
    }),
    skip: null,
  });

  return component =>
    compose(
      MotivosTestDriveNaoRealizadoQueryHoc,
      withProps(propsCompose => {
        const { motivosTestDriveNaoRealizado } = propsCompose[
          MotivosTestDriveNaoRealizadoQueryDataName
        ];

        return {
          motivosTestDriveNaoRealizado,
        };
      })
    )(component);
};
