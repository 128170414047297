import moment from 'moment';
import divisoesRegionaisDealerVendedorLogadoQueryGql from '../Client/client/data/queries/divisoesRegionaisQueryGql.graphql';
import GetClientByIdQueryGql from '../Client/client/data/queries/GetClientByIdQueryGql.graphql';
import GetClientesComPaginacaoQueryGql from '../Client/client/data/queries/GetClientesComPaginacaoQueryGql.graphql';
import { getLocalDateTimeString } from './dateUtils';
import marcasFrotaQueryGql from '../Home/data/queries/marcasFrotaQuery.graphql';
import TemperaturasQueryGql from '../RealizarAtividade/data/queries/TemperaturasQueryGql.graphql';
import TiposDeContatoQueryGql from '../RealizarAtividade/data/queries/TiposDeContatoQueryGql.graphql';
import MotivosEncerramentoFichaVisitaSelecionaveisQueryGql from '../RealizarAtividade/data/queries/MotivosEncerramentoFichaVisitaSelecionaveisQueryGql.graphql';
import RazoesVisitaDisponiveisQueryGql from '../RealizarAtividade/data/queries/RazoesVisitaDisponiveisQueryGql.graphql';
import clienteDuplicadoQueryGql from '../Client/clientesDuplicadosDialog/clienteDuplicadoQueryGql.graphql';
import GetSegmentosVendaAtivosQueryGql from '../Home/data/queries/GetSegmentosVendaAtivosQueryGql.graphql';
import GetWeekActivitiesQueryGql from '../Home/data/queries/GetWeekActivitiesQueryGql.graphql';
import { AnosModeloQueryGql } from '../opportunity/data/queries/activePriceTableQuery';
import ActivePriceTableQueryGql from '../opportunity/data/queries/ActivePriceTableQueryGql.graphql';
import {
  crmAnoModeloAtivoQueryGql,
  crmCamposModeloAtivosQueryGql,
  crmIsNegociacaoAceitaAtivoQueryGql,
  crmModeloTermoQueryGql,
  crmNovosMostrarModeloQueryGql,
  crmNovosMostrarSerieQueryGql,
  crmVarianteModeloAtivoQueryGql,
  crmVarianteTermoQueryGql,
  crmVersaoTermoQueryGql,
} from '../opportunity/data/queries/crmTermosQuery';
import GetCrmTipoVendaPerdidaObrigatorioGql from '../opportunity/data/queries/crmTipoVendaPerdidaObrigatorio/GetCrmTipoVendaPerdidaObrigatorioGql.graphql';
import GetOpportunityVehiclesQueryGql from '../opportunity/data/queries/GetOpportunityVehiclesQueryGql.graphql';
import GetPodeEditarPrecoUnitarioQueryGql from '../opportunity/data/queries/GetPodeEditarPrecoUnitarioQueryGql.graphql';
import ModeloInteresseQueryGql from '../opportunity/data/queries/ModeloInteresseQueryGql.graphql';
import PriceTableQueryQueryGql from '../opportunity/data/queries/PriceTableQueryQueryGql.graphql';
import TemModeloInteresseNovoEUsadoAtivosQueryGql from '../opportunity/data/queries/TemModeloInteresseNovoEUsadoAtivosQueryGql.graphql';
import FinanceirasQueryGql from '../proposal/payment/editor/data/query/FinanceirasQueryGql.graphql';
import MetodosPagamentoAtivosQueryGql from '../proposal/payment/editor/data/query/MetodosPagamentoAtivosQueryGql.graphql';
import OportunidadeStatusQueryGql from '../proposal/payment/editor/data/query/OportunidadeStatusQueryGql.graphql';
import GetPaymentsQueryGql from '../proposal/payment/GetPaymentsQueryGql.graphql';
import GetProposalPaymentMethodsGql from '../proposal/payment/GetProposalPaymentMethodsGql.graphql';
import getAtividadeReagendamentoByIdQueryGql from '../ReagendarAtividade/getAtividadeReagendamentoByIdQueryGql.graphql';
import getAtividadeQueryGql from '../RealizarAtividade/data/queries/getAtividadeQueryGql.graphql';
import marcasConcorrentesQueryGql from '../RealizarAtividade/data/queries/marcasConcorrentesQuery.graphql';
import motivosVendaPerdidaQueryGql from '../RealizarAtividade/data/queries/motivosVendaPerdidaQueryGql.graphql';
import { tipoOrigemOportunidadeQueryGql } from '../RealizarAtividade/data/queries/TipoOrigemOportunidadeQuery';
import tiposVendaPerdidaQueryGql from '../RealizarAtividade/data/queries/tiposVendaPerdidaQueryGql.graphql';
import GetDataSistematicaQueryGql from '../RealizarAtividade/visita/data/GetDataSistematicaQueryGql.graphql';
import { UltimaRoteirizacaoByConsultorQueryGql } from '../Rotas/data/ultimaRoterizacaoByConsultorQuery';
import GetAtividadeTimelineQueryGql from '../timeline/GetAtividadeTimelineQueryGql.graphql';
import { USUARIO_LOGADO_QUERY_GQL } from '../UsuarioLogado/data/USUARIO_LOGADO_QUERY_GQL';
import ModelosFrotaQueryGql from '../Client/clienteFrota/data/queries/ModelosFrotaQuery/ModelosFrotaQueryGql';

/**
 * Esta lista deve conter todas as queries do sistema
 * E funciona de modo que tenhamos uma hieraquia de queries,
 * sabendo que tela chama qual, então colocamos que a querie pai chama a query filha em suas nextQueries
 *
 * Basicamente a query tem:
 *
 * {name}: seu nome definido no gql
 * {haveId}: se ela tem id
 * {hasNullId}: se ela tem verificacao para não realizar a query
 * {query}: seu gql
 * {queryName}:e {entityName} Os nomes dados a query e sua entidade dentro do gql
 * {nextQueries}: as proximas queries a serem executadas
 *
 * Cada {nextQuery} tem os seguintes campos:
 *
 * {name}: nome da query
 * {idPath}: caminho para chegar no id
 * {isVector}: caso o caminho esteja dentro de um vetor no objeto
 * {vectorPath}: caminho ate o vetor
 *
 */
export const queryList = {
  GetWeekActivities: {
    name: 'GetWeekActivities',
    haveId: true,
    hasNullId: false,
    query: GetWeekActivitiesQueryGql,
    queryName: 'getAtividades',
    entityName: 'atividades',
  },

  GetClientById: {
    name: 'GetClientById',
    haveId: true,
    hasNullId: true,
    queryName: null,
    entityName: 'cliente',
    query: GetClientByIdQueryGql,
    nextQueries: [],
  },

  GetAtividadeByIdReagendamento: {
    name: 'GetAtividadeByIdReagendamento',
    hasNullId: true,
    haveId: true,
    query: getAtividadeReagendamentoByIdQueryGql,
    queryName: 'getAtividades',
    entityName: 'atividades',
    nextQueries: [],
  },

  GetAtividadeById: {
    name: 'GetAtividadeById',
    hasNullId: false,
    queryName: null,
    haveId: true,
    entityName: 'atividade',
    query: getAtividadeQueryGql,
    nextQueries: [],
  },

  TiposVendaPerdidaQuery: {
    name: 'TiposVendaPerdidaQuery',
    hasNullId: false,
    haveId: false,
    queryName: 'tiposVendaPerdida',
    query: tiposVendaPerdidaQueryGql,
    nextQueries: [],
  },

  TemperaturasQuery: {
    name: 'TemperaturasQuery',
    hasNullId: false,
    haveId: false,
    queryName: 'temperaturas',
    query: TemperaturasQueryGql,
    nextQueries: [],
  },

  TiposDeContatoQuery: {
    name: 'tiposDeContatoQuery',
    hasNullId: false,
    haveId: false,
    queryName: 'tiposDeContato',
    query: TiposDeContatoQueryGql,
    nextQueries: [],
  },

  MotivosEncerramentoFichaVisitaSelecionaveis: {
    name: 'MotivosEncerramentoFichaVisitaSelecionaveisQuery',
    hasNullId: false,
    haveId: false,
    queryName: 'motivosEncerramentoFichaVisitaSelecionaveis',
    query: MotivosEncerramentoFichaVisitaSelecionaveisQueryGql,
    nextQueries: [],
  },

  RazoesVisitaDisponiveis: {
    name: 'RazoesVisitaDisponiveisQuery',
    hasNullId: false,
    haveId: false,
    queryName: 'RazoesVisitaDisponiveis',
    query: RazoesVisitaDisponiveisQueryGql,
    nextQueries: [],
  },

  MotivosVendaPerdidaQuery: {
    name: 'MotivosVendaPerdidaQuery',
    hasNullId: false,
    haveId: false,
    queryName: 'motivosVendaPerdida',
    query: motivosVendaPerdidaQueryGql,
    nextQueries: [],
  },
  GetDataSistematica: {
    name: 'GetDataSistematica',
    hasNullId: false,
    haveId: true,
    queryName: 'dataProximaAtividadePelaSistematica',
    entityName: 'opcoesDataProximaAtividade',
    defaultVariables: {
      dataHora: getLocalDateTimeString(moment()),
    },
    query: GetDataSistematicaQueryGql,
    nextQueries: [],
  },

  GetOpportunityVehicles: {
    name: 'GetOpportunityVehicles',
    query: GetOpportunityVehiclesQueryGql,

    hasNullId: false,
    haveId: true,
    queryName: null,
    entityName: 'oportunidade',
  },

  GetProposalPaymentMethods: {
    name: 'GetProposalPaymentMethods',
    query: GetProposalPaymentMethodsGql,

    hasNullId: false,
    haveId: true,
    queryName: null,
    entityName: 'proposta',

    nextQueries: [
      {
        name: 'GetPayments',
        idPath: 'id',
        isVector: true,
        vectorPath: 'formasPagamento',
      },
    ],
  },

  ActivePricesTableQuery: {
    name: 'ActivePricesTableQuery',
    query: ActivePriceTableQueryGql,
    hasNullId: false,
    idName: 'idOportunidade',
    haveId: true,
    queryName: null,
    entityName: 'tabelasPreco',
    nextQueries: [],
  },

  GetPayments: {
    name: 'GetPayments',
    query: GetPaymentsQueryGql,
    hasNullId: true,
    haveId: true,
    queryName: null,
    entityName: 'proposta',
    nextQueries: [],
  },

  OportunidadeStatusQuery: {
    name: 'OportunidadeStatusQuery',
    query: OportunidadeStatusQueryGql,
    hasNullId: false,
    haveId: true,
    queryName: null,
    entityName: 'oportunidade',
    nextQueries: [],
  },

  MetodosPagamentoAtivosQuery: {
    name: 'MetodosPagamentoAtivosQuery',
    query: MetodosPagamentoAtivosQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'metodosPagamentoAtivos',
    nextQueries: [],
  },

  FinanceirasQuery: {
    name: 'FinanceirasQuery',
    query: FinanceirasQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'financeirasAtivas',
    nextQueries: [],
  },

  ClientesDuplicadosQuery: {
    name: 'ClientesDuplicadosQuery',
    query: clienteDuplicadoQueryGql,
    hasNullId: false,
    haveId: true,
    queryName: 'clientesDuplicadosQuery',
    entityName: 'clientesDuplicados',
    nextQueries: [],
  },

  GetPodeEditarPrecoUnitario: {
    name: 'GetPodeEditarPrecoUnitario',
    query: GetPodeEditarPrecoUnitarioQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'podeEditarPrecoUnitarioModeloInteresse',
    nextQueries: [],
  },

  GetCrmTipoVendaPerdidaObrigatorio: {
    name: 'GetCrmTipoVendaPerdidaObrigatorio',
    query: GetCrmTipoVendaPerdidaObrigatorioGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmTipoVendaPerdidaObrigatorio',
    nextQueries: [],
  },

  GetModeloInteresse: {
    name: 'GetModeloInteresse',
    query: ModeloInteresseQueryGql,
    hasNullId: true,
    haveId: true,
    queryName: null,
    entityName: 'modeloInteresse',
    nextQueries: [],
  },

  GetAtividadeTimeline: {
    name: 'GetAtividadeTimeline',
    query: GetAtividadeTimelineQueryGql,
    hasNullId: false,
    haveId: true,
    queryName: null,
    entityName: 'atividade',
    nextQueries: [],
  },

  PriceTableQuery: {
    name: 'PriceTableQuery',
    query: PriceTableQueryQueryGql,
    hasNullId: false,
    haveId: true,
    queryName: null,
    entityName: 'tabelaPreco',
    nextQueries: [],
  },

  GetSegmentosVendaAtivos: {
    name: 'GetSegmentosVendaAtivos',
    query: GetSegmentosVendaAtivosQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'segmentosVendaAtivos',
    nextQueries: [],
  },
  GetClientesComPaginacao: {
    name: 'GetClientesComPaginacao',
    query: GetClientesComPaginacaoQueryGql,
    hasNullId: false,
    haveId: true,
    queryName: 'clientes',
    entityName: 'clientes',
    nextQueries: [],
  },

  modelosFrotaQuery: {
    name: 'ModelosAtivosQuery',
    query: ModelosFrotaQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'modelosAtivos',
    nextQueries: [],
  },

  GetMarcasFrota: {
    name: 'GetMarcasFrota',
    query: marcasFrotaQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'marcasFrota',
    nextQueries: [],
  },

  GetMarcasConcorrentes: {
    name: 'GetMarcasConcorrentes',
    query: marcasConcorrentesQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'marcasFrota',
    nextQueries: [],
  },

  GetUsuarioLogado: {
    name: 'GetUsuarioLogado',
    query: USUARIO_LOGADO_QUERY_GQL,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'usuarioLogado',
    nextQueries: [],
  },

  divisoesRegionaisQuery: {
    name: 'divisoesRegionaisQuery',
    query: divisoesRegionaisDealerVendedorLogadoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'divisoesRegionaisDealerVendedorLogado',
    nextQueries: [],
  },

  ultimaRoteirizacaoByConsultorQuery: {
    name: 'ultimaRoteirizacaoByConsultorQuery',
    query: UltimaRoteirizacaoByConsultorQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'ultimaRoteirizacaoByConsultor',
    nextQueries: [],
  },

  crmVersaoTermoQueryGql: {
    name: 'crmVersaoTermoQuey',
    query: crmVersaoTermoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmVersaoTermo',
    nextQueries: [],
  },

  crmModeloTermoQueryGql: {
    name: 'crmModeloTermoQuey',
    query: crmModeloTermoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmModeloTermo',
    nextQueries: [],
  },

  crmVarianteTermoQueryGql: {
    name: 'crmVarianteTermoQuey',
    query: crmVarianteTermoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmVarianteTermo',
    nextQueries: [],
  },

  crmCamposModeloAtivosQueryGql: {
    name: 'crmCamposModeloAtivosQuey',
    query: crmCamposModeloAtivosQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmCamposModeloAtivos',
    nextQueries: [],
  },

  crmNovosMostrarModeloQueryGql: {
    name: 'crmNovosMostrarModeloQuery',
    query: crmNovosMostrarModeloQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmNovosMostrarModelo',
    nextQueries: [],
  },

  crmNovosMostrarSerieQueryGql: {
    name: 'crmNovosMostrarSerieQuery',
    query: crmNovosMostrarSerieQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmNovosMostrarSerie',
    nextQueries: [],
  },

  crmVarianteModeloAtivoQueryGql: {
    name: 'crmVarianteModeloAtivoQuery',
    query: crmVarianteModeloAtivoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmVarianteModeloAtivo',
    nextQueries: [],
  },

  crmIsNegociacaoAceitaAtivoQueryGql: {
    name: 'crmIsNegociacaoAceitaAtivoQuery',
    query: crmIsNegociacaoAceitaAtivoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmIsNegociacaoAceitaAtivo',
    nextQueries: [],
  },

  crmAnoModeloAtivoQueryGql: {
    name: 'crmAnoModeloAtivoQuery',
    query: crmAnoModeloAtivoQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'crmAnoModeloAtivo',
    nextQueries: [],
  },

  tipoOrigemOportunidadeQueryGql: {
    name: 'tipoOrigemOportunidadeQuery',
    query: tipoOrigemOportunidadeQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'tipoOrigemOportunidade',
    nextQueries: [],
  },

  AnosModeloQueryGql: {
    name: 'AnosModeloQuery',
    query: AnosModeloQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'anosModelo',
    nextQueries: [],
  },

  TemModeloInteresseNovoEUsadoAtivos: {
    name: 'TemModeloInteresseNovoEUsadoAtivos',
    query: TemModeloInteresseNovoEUsadoAtivosQueryGql,
    hasNullId: false,
    haveId: false,
    queryName: null,
    entityName: 'temModeloInteresseNovoEUsadoAtivos',
    nextQueries: [],
  },
};
