/* eslint no-console: 0 */
import React from 'react';
import { callGAEventOpenTicket } from '../GAUtils';

const style = { textAlign: 'center' };

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log('Error Catch');
  }

  render() {
    const sadFaceEmoji = `:'(`;

    const { state, props } = this;

    if (state.hasError) {
      console.log('hasError');

      return (
        <div style={style}>
          <h1>{sadFaceEmoji}</h1>
          <p>Ops, algo aconteceu</p>
          <a href="/">Volte aqui</a>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div onClick={() => callGAEventOpenTicket()}>
            <p>
              Caso esse problema persista{' '}
              <a
                href={`${process.env.REACT_APP_HELPDESK_LINK}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                clique aqui
              </a>{' '}
              para abrir um ticket.
            </p>
          </div>
        </div>
      );
    }

    return props.children;
  }
}
