import { getFirstFromSingleElementArrayNotNull } from '@tecsinapse/es-utils/build';
import {
  eventCallClientBottomSheet,
  eventCreateOpportunityBottomSheet,
  eventCreateProspectionBottomSheet,
  eventEditClientBottomSheet,
  eventEditOpportunityBottomSheet,
  eventInformCallBottomSheet,
  eventPerformVisitActivityBottomSheet,
  eventSeeHistoryBottomSheet,
} from '../../utils/GAUtils';
import {
  BottomSheetClickEnum,
  ClientCreateOptionEnum,
} from '../../../core/Enums';
import { getAdressStringOfActivity, goToRoute } from '../../utils/RouteDialog';
import { postInformCallActions } from './postInformCallActions';

export const clienteBottomSheet = ({
  setShowCallClientModal,
  history,
  selectedClient,
  selectedUnidadeNegocio,
  showCreateOpportunityModal,
  showCreateProspectionModal,
  showCreateAtividadeFichaVisitaModal,
  setShowRouteModal,
  informarContatoCliente,
  setAtividades,
  setShowInformCallModal,
  createModal,
  atualizaModeloInativoEAbreDialog,
}) => event => {
  const unidadeNegocioUnica = getFirstFromSingleElementArrayNotNull(
    selectedClient.unidadesNegocioAtendidos || []
  );

  switch (event) {
    case BottomSheetClickEnum.CALL_CLIENT:
      eventCallClientBottomSheet();
      setShowCallClientModal(true);
      break;
    case BottomSheetClickEnum.INFORM_CALL:
      eventInformCallBottomSheet();

      if (
        selectedClient &&
        (selectedClient.unidadesNegocioAtendidos || []).length > 1
      ) {
        createModal({
          id: selectedClient.id,
          clientCreateOption: ClientCreateOptionEnum.INFORM_CALL,
          message: null,
          fullWidth: true,
        });
      } else {
        informarContatoCliente({
          variables: {
            idCliente: selectedClient.id,
            idUnidadeNegocio:
              selectedUnidadeNegocio ||
              (unidadeNegocioUnica && unidadeNegocioUnica.id),
            idTipoContato: null,
          },
        }).then(
          postInformCallActions({
            history,
            createModal,
            setAtividades,
            setShowInformCallModal,
            atualizaModeloInativoEAbreDialog,
          })
        );
      }

      break;
    case BottomSheetClickEnum.EDIT_CLIENT:
      eventEditClientBottomSheet();
      history.push(`/editarCliente/${selectedClient.id}`);
      break;
    case BottomSheetClickEnum.EDIT_OPPORTUNITY:
      eventEditOpportunityBottomSheet();
      history.push(
        `/oportunidade/${selectedClient.ultimaOportunidadeAberta.id}`
      );
      break;
    case BottomSheetClickEnum.SEE_HISTORY:
      eventSeeHistoryBottomSheet();
      history.push(`/timeline/cliente/${selectedClient.id}`);
      break;
    case BottomSheetClickEnum.CREATE_OPPORTUNITY:
      eventCreateOpportunityBottomSheet();
      showCreateOpportunityModal({ idCliente: selectedClient.id });
      break;
    case BottomSheetClickEnum.CREATE_PROSPECTION:
      eventCreateProspectionBottomSheet();
      showCreateProspectionModal({ idCliente: selectedClient.id });
      break;
    case BottomSheetClickEnum.PERFORM_FICHA_VISITA_ACTIVITY:
      eventPerformVisitActivityBottomSheet();
      showCreateAtividadeFichaVisitaModal({
        idCliente: selectedClient.id,
        roteirizacaoClienteId: selectedClient.roteirizacaoClienteId
          ? selectedClient.roteirizacaoClienteId
          : null,
        ultimaAtividadeFichaVisitaAbertaPk:
          selectedClient.ultimaAtividadeFichaVisitaAbertaPk,
      });
      break;
    case BottomSheetClickEnum.GO_TO_ROUTE: {
      const addressString = getAdressStringOfActivity(selectedClient);

      if (
        selectedClient &&
        selectedClient.endereco &&
        selectedClient.endereco.numero
      ) {
        goToRoute(addressString);
      } else {
        setShowRouteModal(true);
      }
      break;
    }
    default:
      break;
  }
};
