import React from 'react';
import { compose, withProps } from 'recompose';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { GOOGLE_MAP_URL } from '../utils/constants';
import { googleMapsStyles } from './styles';

const useGoogleMapsStyles = makeStyles(googleMapsStyles);

export const GoogleMaps = compose(
  withProps(({ height = '100%' }) => {
    const classes = useGoogleMapsStyles();

    return {
      googleMapURL: GOOGLE_MAP_URL,
      loadingElement: (
        <CircularProgress
          color="secondary"
          className={classes.circularProgress}
        />
      ),
      containerElement: <div style={{ height }} />,
      mapElement: <div style={{ height: `100%` }} />,
    };
  }),
  withScriptjs,
  withGoogleMap
)(({ center, markerPosition, zoom = 12, defaultZoom = 12 }) => {
  return (
    <GoogleMap
      defaultZoom={defaultZoom}
      zoom={zoom}
      center={center}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      {Object.keys(markerPosition).length > 0 && (
        <Marker position={markerPosition} />
      )}
    </GoogleMap>
  );
});
