import { Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import { StyledSnackbarContent } from './StyledSnackbarContent';

export const UpdatableContentAvailableSnack = () => {
  const [show, setShow] = useState(true);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={show}
      autoHideDuration={6100}
      onClose={() => setShow(false)}
    >
      <StyledSnackbarContent
        onClose={() => setShow(false)}
        variant="warning"
        message="Uma nova atualização para o aplicativo está disponível. Por favor, feche completamente as janelas do aplicativo para atualizar."
      />
    </Snackbar>
  );
};
