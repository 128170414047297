import React, { useState } from 'react';
import { Drawer, ListItem, ListItemText } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Share } from '@material-ui/icons';
import { ShareBottomSheetContext } from './ShareBottomSheetContext';

export const ShareBottomSheetProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);

  const openAndSetUrl = ({ url: urlInput }) => {
    setOpen(true);
    setUrl(urlInput);
  };

  const hideDrawer = () => {
    setOpen(false);
  };

  return (
    <ShareBottomSheetContext.Provider
      value={{
        openAndSetUrl,
      }}
    >
      {children}
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          hideDrawer();
        }}
      >
        <div>
          <ListItem
            component="a"
            href={url}
            download="proposta.pdf"
            target="_top"
            color="primary"
            onClick={() => {
              hideDrawer();
            }}
          >
            <ListItemIcon>
              <Share />
            </ListItemIcon>
            <ListItemText primary="Compartilhar PDF" color="primary" />
          </ListItem>
        </div>
      </Drawer>
    </ShareBottomSheetContext.Provider>
  );
};
