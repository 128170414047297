import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  acessoRegistrado: false,
};

export const setRegistroAcesso = createAction('SET_REGISTRO_ACESSO');

export const registroAcessoReducer = handleActions(
  {
    [setRegistroAcesso]: state => ({
      ...state,
      acessoRegistrado: true,
    }),
  },
  defaultState
);
