import gql from 'graphql-tag';

const createFichaVisitaAtividadeMutationGql = gql`
  mutation fichaVisitaAgendarAtividade($idCliente: ID!) {
    fichaVisitaAgendarAtividade(idCliente: $idCliente) {
      id
    }
  }
`;

export default createFichaVisitaAtividadeMutationGql;
