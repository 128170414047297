import React from 'react';
import { Close } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { Field, Form } from 'redux-form';
import moment from 'moment';
import Typography from '@material-ui/core/Typography/Typography';
import { makeStyles } from '@material-ui/styles';
import { RenderDateTimePicker, SubmitButton } from '../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../utils/uiUtils';
import { enhanceReagendarAtividade } from '../ReagendarAtividade/enhanceReagendarAtividade';
import { globalStyle } from '../app/globalStyle';
import { eventCancelReescheduleActivity } from '../utils/GAUtils';

const useStyleAtividadeCompletadaEDataProxima = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  ...globalStyle(theme),
}));

const AtividadeCompletadaEDataProxima = ({
  handleSubmit,
  onSubmit,
  tipoVendaPerdida,
  history,
  formName = null,
  location,
  ...rest
}) => {
  const classes = useStyleAtividadeCompletadaEDataProxima();
  const {
    state: {
      pageState: { idAtividadeRealizada, oportunidadeIncompleta, urlRetorno },
    },
  } = location;

  const action = () => {
    eventCancelReescheduleActivity();

    if (oportunidadeIncompleta) {
      if (urlRetorno) {
        history.push(
          `/realizarAtividade/${idAtividadeRealizada}/${urlRetorno}`,
          {
            selectedPage: 2,
          }
        );
      } else {
        history.push(`/realizarAtividade/${idAtividadeRealizada}`, {
          selectedPage: 2,
        });
      }
    } else if (urlRetorno) {
      history.push(`/${urlRetorno}`, { tab: 1 });
    } else {
      history.push('/agenda');
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className={classes.margin} name={formName}>
        <RenderAppLogo />
        {updateAppTitle('Atividade Realizada')}
        <ShowBackButton IconComponent={Close} action={action} />
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Deseja selecionar a data da próxima atividade?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="dataHoraReagendamento"
              label="Data de próxima atividade"
              minDate={moment()}
              component={RenderDateTimePicker}
              className={classes.marginBottom20}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              label="Salvar "
              classe={classes}
              fullWidth
              {...rest}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export const AtividadeCompletadaEDataProximaContainer = enhanceReagendarAtividade(
  'REALIZACAO_ATIVIDADE'
)(AtividadeCompletadaEDataProxima);
