import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

const defaultState = { show: false, lastUpdated: moment().toISOString() };

export const showOfflineBadge = createAction('SHOW_OFFLINE_BADGE');
export const hideOfflineBadge = createAction('HIDE_OFFLINE_BADGE');

export const offlineBadgeReducer = handleActions(
  {
    [showOfflineBadge]: (state, { payload: { lastUpdated = '' } }) => ({
      ...state,
      show: true,
      lastUpdated,
    }),
    [hideOfflineBadge]: state => ({
      ...state,
      show: false,
    }),
  },
  defaultState
);
