import React from 'react';
import clsx from 'clsx';
import {
  Grid,
  MenuItem,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@material-ui/core';
import { IconButton, Input } from '@tecsinapse/ui-kit';
import Accordion from '@material-ui/core/Accordion';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography/Typography';
import { FilterList, Search } from '@material-ui/icons';
import SearchBar from 'material-ui-search-bar';
import { compose, withHandlers, withState } from 'recompose';
import { isStringNotBlank, verifyIfString } from '@tecsinapse/es-utils/build';
import { makeStyles } from '@material-ui/styles';
import { Estados } from '../../../core/Enums';
import { globalStyle } from '../../app/globalStyle';
import { FloatingButton } from '../FloatingButton';
import { updateAppTitle, RenderAppLogo } from '../uiUtils';
import { SelectComponentStyled } from '../select/RenderSelectField';
import {
  eventAddClient,
  eventAdvancedSearch,
  eventClearFields,
  eventEnableAdvancedSearch,
} from '../GAUtils';

const useTypeStyles = makeStyles({
  container: {
    display: 'flex' /* establish flex container */,
    flexGrow: 1,
    flexDirection: 'column' /* make main axis vertical */,
    justifyContent: 'center' /* center items vertically, in this case */,
    alignItems: 'center' /* center items horizontally, in this case */,
    marginTop: '60px',
  },
  item: {
    textAlign: 'center',
  },
});

const tabStyles = theme => ({
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  searchBar: {
    flexGrow: '1',
    paddingRight: 0,
    boxShadow: 'none',
    width: '100%',
  },
  searchBarContainer: {
    marginRight: 0,
    paddingRight: 0,
  },
  expansionPanelDetails: {
    flexDirection: 'column',
    borderTop: 'solid 1px rgba(0,0,0,0.1)',
    paddingTop: '20px',
  },
  expasionPanelSummary: {
    display: 'flex',
    padding: '0',
  },
  buttonFilter: {},
  buttonFilterExpanded: {
    marginRight: '16px',
  },
  expandIcon: {
    transition: 'none',
    marginRight: '-8px',
  },
  noPaddingRight: {
    paddingRight: '0 !important',
  },
  ...globalStyle(theme),
});
const style = {
  height: '100%',
  margin: 0,
};
const style2 = { marginLeft: '5px' };
const style3 = { height: 'calc( 100% - 48px)' };

const TypeSearch = ({
  itemPlaceHolder = 'Digite o nome ou CPF/CNPJ do cliente para realizar uma busca',
}) => {
  const classes = useTypeStyles();

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <p>
          <Search /> {itemPlaceHolder}
        </p>
      </div>
    </div>
  );
};

const podePesquisar = (textSearchInput, cidadeInput, ufInput) =>
  (verifyIfString(textSearchInput) && isStringNotBlank(textSearchInput)) ||
  isStringNotBlank(cidadeInput) ||
  isStringNotBlank(ufInput);

const enhance = compose(
  withStyles(tabStyles),
  withState('searchBarText', 'setSearchBarText', ''),
  withState('searchBarCity', 'setSearchBarCity', ''),
  withState('searchBarUf', 'setSearchBarUf', ''),
  withHandlers({
    setSearchListenerState: ({
      searchBarText,
      searchBarCity,
      searchBarUf,
      setTextSearch,
      setCidade,
      setUf,
    }) => () => {
      setTextSearch(searchBarText);
      setCidade(searchBarCity);
      setUf(searchBarUf);
    },
  })
);

export const GenericSearchBar = enhance(
  ({
    searchBarText,
    searchBarCity,
    searchBarUf,
    setSearchBarText,
    setSearchBarCity,
    setSearchBarUf,
    setSearchListenerState,
    textSearch,
    cidade,
    uf,
    goToAddClient,
    classes,
    showAdvancedSearch,
    textSearchPlaceHolder,
    textSearchPlaceHolderExpansion,
    itemPlaceHolder,
    isToUpdateAppTitle,
    SearchListener,
    isShowFloatingButton,
    isDialog = false,
    setShowSearchDialog,
    children,
    DismissDialogIcon,
    autoFocus,
    ...props
  }) => {
    const { expansionPanelOpen, setExpansionPanelOpen } = props;
    const style1 = isDialog ? { minHeight: '56px' } : {};

    return (
      <form onSubmit={e => e.preventDefault()} action="." style={style}>
        <>
          <RenderAppLogo />
          {isToUpdateAppTitle && updateAppTitle('Clientes')}
          <Accordion
            expanded={expansionPanelOpen}
            className={clsx(classes.marginTopZero, classes.sticky)}
          >
            <AccordionSummary
              classes={{
                content: classes.marginZero,
                root: classes.expasionPanelSummary,
                expandIcon: classes.expandIcon,
              }}
              style={style1}
              expandIcon={
                showAdvancedSearch && (
                  <FilterList
                    onClick={() => {
                      eventEnableAdvancedSearch();
                      setExpansionPanelOpen(!expansionPanelOpen);
                    }}
                  />
                )
              }
            >
              {isDialog && (
                <IconButton
                  style={style2}
                  onClick={() => setShowSearchDialog(false)}
                >
                  <DismissDialogIcon color="inherit" />
                </IconButton>
              )}
              <SearchBar
                autoFocus={autoFocus}
                classes={{
                  root: [
                    classes.searchBar,
                    (!showAdvancedSearch && classes.noPaddingRight) || '',
                  ].join(' '),
                  searchContainer: classes.searchBarContainer,
                }}
                type="search"
                placeholder={textSearchPlaceHolder}
                onRequestSearch={setSearchListenerState}
                onCancelSearch={() => {
                  setSearchBarText('');
                }}
                value={searchBarText}
                onChange={newValue => setSearchBarText(newValue)}
              />
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelDetails}>
              {showAdvancedSearch && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>Pesquisa avançada</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name="textSearchPlaceHolderExpansion"
                      label={textSearchPlaceHolderExpansion}
                      fullWidth
                      margin="normal"
                      value={searchBarText}
                      onChange={e => {
                        setSearchBarText(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Cidade"
                      fullWidth
                      value={searchBarCity}
                      margin="normal"
                      onChange={e => {
                        setSearchBarCity(e.target.value);
                      }}
                      name="Cidade"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectComponentStyled
                      name="Estado"
                      input={{
                        value: searchBarUf,
                        onChange: e => {
                          setSearchBarUf(e);
                        },
                      }}
                      placeholder="Estado"
                      label="Estado"
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="">Nenhum</MenuItem>
                      {Estados.enumValues.map(({ sigla }) => (
                        <MenuItem value={sigla} key={sigla}>
                          {sigla}
                        </MenuItem>
                      ))}
                    </SelectComponentStyled>
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
            <AccordionActions>
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  eventClearFields();
                  setSearchBarText('');
                  setSearchBarCity('');
                  setSearchBarUf(null);
                  setSearchListenerState();
                }}
              >
                Limpar Campos
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  eventAdvancedSearch();
                  setExpansionPanelOpen(false);
                  setSearchListenerState();
                }}
              >
                <Search />
              </IconButton>
            </AccordionActions>
          </Accordion>
          <div style={style3}>
            {(children && children) ||
              (podePesquisar(textSearch, cidade, uf) ? (
                <SearchListener
                  textSearch={textSearch}
                  cidade={cidade}
                  uf={uf}
                  {...props}
                />
              ) : (
                <TypeSearch itemPlaceHolder={itemPlaceHolder} />
              ))}
          </div>
          {isShowFloatingButton && (
            <FloatingButton
              id="generic-searchbar-fb"
              onClick={() => {
                eventAddClient();
                goToAddClient();
              }}
            />
          )}
        </>
      </form>
    );
  }
);
