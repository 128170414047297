import { useQuery } from '@apollo/react-hooks';
import GetUnidadesNegocioVendaAtivasQueryGql from './GetUnidadesNegocioVendaAtivasQueryGql.graphql';

export function useUnidadeNegocio(idSegmento) {
  const { data: { unidadesNegocioVendaAtivas } = {}, loading } = useQuery(
    GetUnidadesNegocioVendaAtivasQueryGql,
    {
      variables: {
        idSegmento,
      },
      fetchPolicy: 'cache-first',
    }
  );

  return [unidadesNegocioVendaAtivas || [], loading];
}

export function useUnidadeNegocioResponsavel(
  idSegmento,
  unidadesNegocioCliente,
  idUsuarioLogado
) {
  const [unidadesNegocioVendaAtivas, loading] = useUnidadeNegocio(idSegmento);

  const idsUnidadeNegocioResponsaveisUsuarioLogado = (
    unidadesNegocioCliente || []
  )
    .filter(cun => cun.vendedor?.id === idUsuarioLogado)
    .map(cun => cun.unidadeNegocio.id);

  return [
    unidadesNegocioVendaAtivas.filter(un =>
      idsUnidadeNegocioResponsaveisUsuarioLogado.includes(un.id)
    ),
    loading,
  ];
}
