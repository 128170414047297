import { Grid, MenuItem } from '@material-ui/core';
import { reduxFormAutoFocus } from 'components/utils/reduxFormAutoFocus';
import { RenderSelectField } from 'components/utils/select/RenderSelectField';
import {
  RenderAppLogo,
  ShowBackButton,
  updateAppTitle,
} from 'components/utils/uiUtils';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { OportunidadeProdutoAgregadoResultado } from '../../../../core/Enums';
import { getAlertContext } from '../../../utils/alertContext';
import {
  RenderDatePicker,
  RenderInput,
  SubmitButton,
  validateRequired,
} from '../../../utils/FormUtils';
import { useProdutoAgregadoEditor } from './ProdutoAgregadoEditorHook';

const Form = ({ handleSubmit, initialize, ...rest }) => {
  const history = useHistory();
  const { location: { state } = {} } = history;
  const {
    produtos,
    produtoSelecionado,
    dealers,
    apenasUmDealer,
    handleAdicionaProduto,
    handleAlteracaoResultado,
    handleAlteracaoDealer,
  } = useProdutoAgregadoEditor(rest);

  useEffect(() => {
    initialize({ dealer: state.idDealer });
  }, [initialize, state]);

  return (
    <>
      <RenderAppLogo />
      <ShowBackButton history={history} />
      {updateAppTitle('Produtos agregados')}
      <form onSubmit={handleSubmit(handleAdicionaProduto)}>
        <Grid container>
          {!apenasUmDealer && !state?.idDealer && (
            <Grid item xs={12}>
              <Field
                name="dealer"
                label="Dealer"
                required
                component={RenderSelectField}
                onChange={handleAlteracaoDealer}
                fullWidth
              >
                {dealers.map(dealer => (
                  <MenuItem key={dealer.id} value={dealer.id}>
                    {dealer.nome}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              name="produtoAgregado"
              label="Produto agregado"
              required
              component={RenderSelectField}
              fullWidth
            >
              {produtos.map(produto => (
                <MenuItem key={produto.id} value={produto.id}>
                  {produto.nome}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="resultado"
              label="Resultado"
              required
              component={RenderSelectField}
              onChange={handleAlteracaoResultado}
              fullWidth
            >
              {OportunidadeProdutoAgregadoResultado.enumValues.map(item => (
                <MenuItem key={item.ordinal} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field
              name="dataFaturamento"
              label="Data de faturamento"
              required
              component={RenderDatePicker}
              fullWidth
            />
          </Grid>

          {produtoSelecionado.resultado ===
            OportunidadeProdutoAgregadoResultado.VENDIDO.name && (
            <>
              <Grid item xs={12}>
                <Field
                  name="dataInicioVigencia"
                  label="Data inicial da vigência"
                  required
                  component={RenderDatePicker}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="dataFimVigencia"
                  label="Data final da vigência"
                  required
                  component={RenderDatePicker}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="detalhes"
                  label="Detalhes"
                  component={RenderInput}
                  multiline
                  fullWidth
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <SubmitButton fullWidth label="Salvar" />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export const ProdutoAgregadoEditor = compose(
  getAlertContext,
  reduxFormAutoFocus({
    form: 'produtos_agregados',
    validate: validateRequired([
      'dealer',
      'produtoAgregado',
      'resultado',
      'dataInicioVigencia',
      'dataFimVigencia',
      'dataFaturamento',
    ]),
  })
)(Form);
