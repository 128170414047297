import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { ClienteSearchTabContainer } from './ClienteSearchTabContainer';
import { getLocation, registraLocalizacao } from '../../core/geolocationUtils';

export const ClienteSearchTabDialog = ({
  setShowSearchDialog,
  showSearchDialog,
  activityOnClick,
  onBottomSheetClick,
  ...props
}) => {
  const [localizacao, setLocalizacao] = useState(null);

  useEffect(() => {
    getLocation(registraLocalizacao(setLocalizacao));
  }, []);

  return (
    <Dialog
      fullScreen
      onClose={() => setShowSearchDialog(false)}
      open={showSearchDialog}
    >
      <ClienteSearchTabContainer
        setShowSearchDialog={setShowSearchDialog}
        localizacao={localizacao}
        {...props}
      />
    </Dialog>
  );
};
