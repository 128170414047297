import ReactGA from 'react-ga4';
import { getClientSelectedTabName } from '../Client/client/ui/ClienteComponent';
import { getOpportunitySelectedTabName } from '../opportunity/OpportunityContainer';

export const callGAEventClickContato = eventType => {
  ReactGA.event({
    category: 'Click Contato',
    action: `Click Contato de ${eventType}`,
  });
};

export const searchClick = () => {
  ReactGA.event({
    category: 'Click',
    action: `SEARCH CLICK`,
  });
};

export const todayClick = () => {
  ReactGA.event({
    category: 'Click no calendário',
    action: `TODAY CLICK`,
  });
};

export const showAtrasadasClick = () => {
  ReactGA.event({
    category: 'Click no calendário',
    action: `DELAYED CLICK`,
  });
};

export const weekClick = diff => {
  ReactGA.event({
    category: 'Click no calendário',
    action: `${diff} WEEK CLICK`,
  });
};

export const eventClickAdvancedSearch = () => {
  ReactGA.event({
    category: 'Click',
    action: `ADVANCED SEARCH CLICK`,
  });
};

export const eventTickCheckboxForSync = () => {
  ReactGA.event({
    category: 'Sync Offline',
    action: `TICK CHECKBOX SYNC`,
  });
};

export const eventUntickCheckboxForSync = () => {
  ReactGA.event({
    category: 'Sync Offline',
    action: `UNTICK CHECKBOX SYNC`,
  });
};

export const eventSyncChanges = payload => {
  ReactGA.event({
    category: payload,
    action: `SYNC CHANGES`,
  });
};

export const eventReescheduleActivity = from => {
  ReactGA.event({
    category: 'Activity',
    action: `${from} REESCHEDULE ACTIVITY`,
  });
};

export const eventCancelReescheduleActivity = () => {
  ReactGA.event({
    category: 'Perform Activity',
    action: `CANCEL REESCHEDULE ACTIVITY`,
  });
};

export const callGAEventOpenTicket = () => {
  ReactGA.event({
    category: 'Click',
    action: `Click abrir Ticket`,
  });
};

export const eventScheduleMenu = () => {
  ReactGA.event({
    category: 'Click App Menu',
    action: `SCHEDULE MENU `,
  });
};

export const eventClientMenu = () => {
  ReactGA.event({
    category: 'Click App Menu',
    action: `CLIENT MENU`,
  });
};

export const eventIndicatorsMenu = () => {
  ReactGA.event({
    category: 'Click App Menu',
    action: `INDICATORS MENU`,
  });
};

export const eventSettingsMenu = () => {
  ReactGA.event({
    category: 'Click App Menu',
    action: `SETTINGS MENU`,
  });
};

export const eventRoutesMenu = () => {
  ReactGA.event({
    category: 'Click App Menu',
    action: `ROUTES MENU`,
  });
};

export const eventPerformActivity = () => {
  ReactGA.event({
    category: 'Perform Activity',
    action: `PERFORM ACTIVITY`,
  });
};

export const eventNegociacaoAceita = () => {
  ReactGA.event({
    category: 'Perform Activity',
    action: `NEGOCIACAO ACEITA`,
  });
};

export const eventLostBusiness = () => {
  ReactGA.event({
    category: 'Perform Activity',
    action: `LOST BUSINESS`,
  });
};

export const eventSaveLostBusiness = () => {
  ReactGA.event({
    category: 'Perform Activity',
    action: `SAVE LOST BUSINESS`,
  });
};

export const eventSaveNextActivityDate = () => {
  ReactGA.event({
    category: 'Perform Activity',
    action: `SAVE NEXT ACTIVITY DATE`,
  });
};

export const eventButtonUpperCase = () => {
  ReactGA.event({
    category: 'Text Transform Buttons',
    action: `BUTTON UPPERCASE`,
  });
};

export const eventInformCallBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `INFORM CALL`,
  });
};

export const eventPerformVisitActivityBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `PERFORM VISIT ACTIVITY`,
  });
};

export const eventCallClientBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `CALL CLIENT`,
  });
};

export const eventGoToRouteBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `GO TO ROUTE`,
  });
};

export const eventRescheduleActivityBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `RESCHEDULE ACTIVITY`,
  });
};

export const eventEditOpportunityBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `EDIT OPPORTUNITY`,
  });
};

export const eventSeeHistoryBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `SEE HISTORY`,
  });
};

export const eventEditClientBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `EDIT CLIENT`,
  });
};

export const eventCreateOpportunityBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `CREATE OPPORTUNITY`,
  });
};

export const eventCreateProspectionBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `CREATE PROSPECTION`,
  });
};

export const eventPerformTestDriveBottomSheet = () => {
  ReactGA.event({
    category: 'Drawer Actions',
    action: `PERFORM TEST DRIVE`,
  });
};

export const eventInformCallActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `INFORM CALL`,
  });
};

export const eventPerformVisitActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `PERFORM VISIT`,
  });
};

export const eventCallClientActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `CALL CLIENT`,
  });
};

export const eventRescheduleActivityActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `RESCHEDULE ACTIVITY`,
  });
};

export const eventEditOpportunityActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `EDIT OPPORTUNITY`,
  });
};

export const eventSeeHistoryActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `SEE HISTORY`,
  });
};

export const eventEditClientActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `EDIT CLIENT`,
  });
};

export const eventPerformTestDriveActivityCard = () => {
  ReactGA.event({
    category: 'Activity Card Actions',
    action: `PERFORM TEST DRIVE`,
  });
};

export const eventSaveClientButton = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `SAVE CLIENT`,
  });
};

export const eventMoreOptionsClientButton = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `MORE OPTIONS CLIENT BUTTON`,
  });
};

export const eventAddFleet = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `ADD FLEET`,
  });
};

export const eventEditFleet = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `EDIT FLEET`,
  });
};

export const eventRemoveFleet = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `REMOVE FLEET`,
  });
};

export const eventAddQuantityFleet = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `ADD QUANTITY FLEET`,
  });
};

export const eventSaveFleet = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `SAVE FLEET`,
  });
};

export const eventAddContact = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `ADD CONTACT`,
  });
};

export const eventEditContact = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `EDIT CONTACT`,
  });
};

export const eventRemoveContact = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `REMOVE CONTACT`,
  });
};

export const eventSaveContact = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `SAVE CONTACT`,
  });
};

export const eventClickClientTabs = selectedTab => {
  const actionName = getClientSelectedTabName(selectedTab);

  ReactGA.event({
    category: 'Client Actions',
    action: `${actionName} TAB`,
  });
};

export const eventEnableAdvancedSearch = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `ENABLE ADVANCED SEARCH CLIENT`,
  });
};

export const eventAdvancedSearch = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `ADVANCED SEARCH CLIENT`,
  });
};

export const eventClearFields = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `CLEAR FIELDS ADVANCED SEARCH CLIENT`,
  });
};

export const eventAddClient = () => {
  ReactGA.event({
    category: 'Client Actions',
    action: `ADD CLIENT`,
  });
};

export const eventSeeMoreTimeline = () => {
  ReactGA.event({
    category: 'Timeline',
    action: `SEE MORE TIMELINE`,
  });
};

export const eventAddCommentTimeLine = () => {
  ReactGA.event({
    category: 'Timeline',
    action: `ADD COMMENT TIMELINE`,
  });
};

export const eventSaveCommentTimeLine = () => {
  ReactGA.event({
    category: 'Timeline',
    action: `SAVE COMMENT TIMELINE`,
  });
};

export const eventClickOpportunityTabs = selectedTab => {
  const actionName = getOpportunitySelectedTabName(selectedTab);

  ReactGA.event({
    category: 'Opportunity Actions',
    action: `${actionName} TAB`,
  });
};

export const eventPrintProposal = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `PRINT PROPOSAL`,
  });
};

export const eventOpportunityPerformTestDriveVehicle = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `PERFORM TEST DRIVE`,
  });
};

export const eventOpportunityEditVehicle = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `EDIT VEHICLE`,
  });
};

export const eventOpportunityRemoveVehicle = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `REMOVE VEHICLE`,
  });
};

export const eventOpportunityAddVehicle = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `ADD VEHICLE`,
  });
};

export const eventOpportunityVehicleStatus = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `OPPORTUNITY VEHICLE STATUS`,
  });
};

export const eventOpportunitySaveVehicle = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `SAVE VEHICLE`,
  });
};

export const eventAddPaymentMethods = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `ADD PAYMENT METHOD`,
  });
};

export const eventEditPaymentMethods = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `EDIT PAYMENT METHOD`,
  });
};

export const eventRemovePaymentMethods = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `REMOVE PAYMENT METHOD`,
  });
};

export const eventSavePaymentMethods = () => {
  ReactGA.event({
    category: 'Opportunity Actions',
    action: `REMOVE PAYMENT METHOD`,
  });
};

export const eventIndicatorDelayedActivitiesFilterByTemperature = () => {
  ReactGA.event({
    category: 'Indicator Actions',
    action: `DELAYED ACTIVITIES FILTER BY TEMPERATURE`,
  });
};

export const eventIndicatorDelayedActivitiesList = () => {
  ReactGA.event({
    category: 'Indicator Actions',
    action: `DELAYED ACTIVITIES LIST CIRCLE`,
  });
};

export const eventIndicatorFilterByPeriod = () => {
  ReactGA.event({
    category: 'Indicator Actions',
    action: `FILTER BY PERIOD`,
  });
};

export const eventIndicatorAdvancedSearchButton = () => {
  ReactGA.event({
    category: 'Indicator Actions',
    action: `ADVANCED SEARCH BUTTON`,
  });
};

export const eventIndicatorAdvancedSearch = () => {
  ReactGA.event({
    category: 'Indicator Actions',
    action: `ADVANCED SEARCH`,
  });
};

export const eventSettingsLogoff = () => {
  ReactGA.event({
    category: 'Settings Actions',
    action: `LOGOFF`,
  });
};

export const eventSettingsUseCarrierPrefix = () => {
  ReactGA.event({
    category: 'Settings Actions',
    action: `USE CARRIER PREFIX`,
  });
};

export const eventSettingsSaveCarrierPrefix = () => {
  ReactGA.event({
    category: 'Settings Actions',
    action: `SAVE CARRIER PREFIX`,
  });
};

export const eventSettingsCancelCarrierPrefix = () => {
  ReactGA.event({
    category: 'Settings Actions',
    action: `CANCEL CARRIER PREFIX`,
  });
};

export const eventAdvanceButtonPerformVisitActivity = () => {
  ReactGA.event({
    category: 'Perform Visit Activity',
    action: `ADVANCE`,
  });
};

export const eventCloseBusinessCard = () => {
  ReactGA.event({
    category: 'Perform Visit Activity',
    action: `CLOSE BUSINESS CARD`,
  });
};

export const eventPerformVisitActivity = () => {
  ReactGA.event({
    category: 'Perform Visit Activity',
    action: `PERFORM VISIT ACTIVITY`,
  });
};

export const eventClientContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `CLIENT CONTACTED PROSPECTION`,
  });
};

export const eventClientNotContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `CLIENT NOT CONTACTED PROSPECTION`,
  });
};

export const eventClientInvalidInformationProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `CLIENT INVALID INFORMATION PROSPECTION`,
  });
};

export const eventRescheduleClientContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `RESCHEDULE CLIENT CONTACT PROSPECTION`,
  });
};

export const eventCreateOpportunityProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `CREATE OPPORTUNITY PROSPECTION`,
  });
};

export const eventSaveClientContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `SAVE CLIENT CONTACTED PROSPECTION`,
  });
};

export const eventFileClientContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `FILE CLIENT CONTACTED PROSPECTION`,
  });
};

export const eventSaveClientNotContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `SAVE CLIENT NOT CONTACTED PROSPECTION`,
  });
};

export const eventFileClientNotContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `FILE CLIENT NOT CONTACTED PROSPECTION`,
  });
};

export const eventRescheduleClientNotContactedProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `RESCHEDULE NOT CLIENT CONTACT PROSPECTION`,
  });
};

export const eventFileClientInvalidInformationProspection = () => {
  ReactGA.event({
    category: 'Actions Prospection',
    action: `FILE CLIENT INVALID INFORMATION PROSPECTION`,
  });
};

export const eventAccessRoute = () => {
  ReactGA.event({
    category: 'Actions Routes',
    action: `ACCESS ROUTES`,
  });
};

export const eventReroute = () => {
  ReactGA.event({
    category: 'Actions Routes',
    action: `REROUTE`,
  });
};

export const eventSaveReroute = () => {
  ReactGA.event({
    category: 'Actions Routes',
    action: `SAVE REROUTE`,
  });
};

export const eventCancelReroute = () => {
  ReactGA.event({
    category: 'Actions Routes',
    action: `CANCEL REROUTE`,
  });
};

export const eventNextUpdateModeloInativo = () => {
  ReactGA.event({
    category: 'Click Modelo Inativo',
    action: `NEXT UPDATE MODELO INATIVO`,
  });
};

export const eventSaveUpdateModeloInativo = () => {
  ReactGA.event({
    category: 'Click Modelo Inativo',
    action: `SAVE UPDATE MODELO INATIVO`,
  });
};

export const eventCloseUpdateModeloInativo = () => {
  ReactGA.event({
    category: 'Click Modelo Inativo',
    action: `CLOSE UPDATE MODELO INATIVO`,
  });
};

export const eventBackUpdateModeloInativo = () => {
  ReactGA.event({
    category: 'Click Modelo Inativo',
    action: `BACK UPDATE MODELO INATIVO`,
  });
};

export const eventCancelTestDriveVehicleSelection = () => {
  ReactGA.event({
    category: 'TD Vehicle Selection',
    action: 'CANCEL TD VEHICLE SELECTION',
  });
};

export const eventConfirmPerformTestDrive = () => {
  ReactGA.event({
    category: 'Perform Test Drive',
    action: 'CONFIRM TEST DRIVE',
  });
};

export const eventFup48IndicadorClick = () => {
  ReactGA.event({
    category: 'Click Indicador FUP 48h',
    action: 'MELHORAR ESSE INDICADOR',
  });
};

export const eventOfertaTestDriveIndicadorClick = () => {
  ReactGA.event({
    category: 'Click Indicador Oferta Test Drive',
    action: 'Clicar no botão MELHORAR ESSE INDICADOR',
  });
};

export const gaEventExcellenceClubClick = (category, actionName) => {
  ReactGA.event({
    category: `Click Indicador ${category.name}`,
    action: `Abrir dialoog do Indicador de ${actionName}`,
  });
};

export const eventClickPerformCheckin = () => {
  ReactGA.event({
    category: 'Click realizar checkin',
    action: `click_realizar_checkin`,
  });
};
