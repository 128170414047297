import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  ListItemSecondaryAction,
  Switch,
} from '@material-ui/core';
import { AccountCircle, Info, Phone, Message, Send } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import { updateAppTitle, RenderAppLogo } from '../utils/uiUtils';
import { EditSettingsTextDialog } from './EditSettingsTextDialog';
import PoweredBy from '../../images/pwered-by.png';
import {
  eventSettingsLogoff,
  eventSettingsUseCarrierPrefix,
} from '../utils/GAUtils';
import PushUtils from '../PushNotification/pushUtils';
import { isIos } from '../utils/BrowserUtils';

const settingsStyles = makeStyles({
  switch: {
    width: '100%',
    maxWidth: 360,
  },
  buttonTestarNotification: {
    width: '100px',
  },
  poweredBy: {
    width: '86px',
  },
  footer: {
    textAlign: 'center',
    marginTop: '30px',
  },
});

const verifyNotification = () => {
  return (
    Notification.permission === 'granted' &&
    JSON.parse(localStorage.getItem('registeredForPush'))
  );
};

export const Settings = ({
  loading,
  onSubmit,
  settings,
  dialog,
  openDialog,
  closeDialog,
  logout,
  keycloak,
  showAlert,
}) => {
  const classes = settingsStyles();

  const [checked, setChecked] = useState(false);

  const onChangeNotification = () => {
    !isIos() && setChecked(verifyNotification());
  };

  useEffect(() => {
    onChangeNotification();
  }, []);

  const editOperatorCode = () => {
    eventSettingsUseCarrierPrefix();
    openDialog({
      type: 'text',
      text:
        'Digite o código da operadora que será acrescentado ao início dos números para facilitar as ligações',
      title: 'Código da Operadora',
      input: {
        label: 'Código da operadora',
        name: 'operatorCode',
        initialValue: settings.operatorCode,
      },
    });
  };

  const mostrarAlertaNotificacao = options => {
    onChangeNotification();
    showAlert(options);
  };

  const toggleNotificacoes = event => {
    if (event.target.checked) {
      Notification.requestPermission()
        .then(status => {
          if (status === 'granted') {
            PushUtils.subscribeToServer(
              process.env.REACT_APP_PUSH_SUBSCRIBE_ENDPOINT,
              process.env.REACT_APP_PUSH_PUB_VAPID,
              () =>
                mostrarAlertaNotificacao({
                  message: 'Notificações ativadas',
                  variant: 'success',
                  chip: true,
                })
            );
          } else {
            mostrarAlertaNotificacao({
              message: (
                <>
                  <p>
                    As notificações para o aplicativo estão bloqueadas no seu
                    celular. Por favor:
                  </p>
                  <p>
                    1) Habilite as notificações na configuração de aplicativos.
                  </p>
                  <p>
                    2) Libere o site {window.location.origin} no seu navegador.
                  </p>
                </>
              ),
              title: 'Alerta',
              chip: false,
            });
          }
        })
        .catch(err => {
          console.error(`Permission not granted due to error: ${err}`);
        });
    } else {
      PushUtils.unsubscribeFromServer(() =>
        mostrarAlertaNotificacao({
          message: 'Notificações desativadas',
          variant: 'success',
          chip: true,
        })
      );
    }
  };

  const renderNotificacoes = () => {
    if (isIos()) {
      return <></>;
    }

    return (
      <>
        <ListItem classes={{ container: classes.switch }}>
          <ListItemIcon>
            <Message />
          </ListItemIcon>
          <ListItemText
            primary="Notificações"
            secondary={
              <Button
                className={classes.buttonTestarNotification}
                size="small"
                variant="outlined"
                endIcon={<Send />}
                fullWidth
                onClick={() => PushUtils.testarEnvioNotificacao()}
                disabled={!checked}
              >
                Testar
              </Button>
            }
          />
          <ListItemSecondaryAction>
            <Switch checked={checked} onChange={toggleNotificacoes} />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </>
    );
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <RenderAppLogo />
      {updateAppTitle('Ajustes')}
      <EditSettingsTextDialog
        key={(dialog && dialog.input && dialog.input.name) || 'dialog'}
        open={(dialog && dialog.type === 'text') || false}
        handleSubmit={onSubmit}
        handleClose={closeDialog}
        sucessButton={{ label: 'OK' }}
        cancelButon={{ label: 'Cancelar' }}
        {...(dialog || {})}
      />
      <List component="nav">
        <ListItem
          button
          onClick={() => {
            eventSettingsLogoff();
            logout();
          }}
        >
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText
            primary={
              /* eslint-disable camelcase */ keycloak?.tokenParsed
                ?.given_name || 'Carregando...'
            }
            secondary="Deslogar"
          />
        </ListItem>
        <Divider />

        {renderNotificacoes()}

        <ListItem button onClick={editOperatorCode}>
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText
            primary="Usar prefixo de operadora nos números?"
            secondary={
              settings.operatorCode ? `Sim, ${settings.operatorCode}` : 'Não'
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary="Versão do aplicativo"
            secondary={`v${process.env.REACT_APP_VERSION}`}
          />
        </ListItem>
        {process.env.REACT_APP_KC_ENV_TYPE === 'staging' && (
          <>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText
                primary="URL do backend"
                secondary={`${process.env.REACT_APP_GRAPHQL_URL}`}
              />
            </ListItem>
          </>
        )}
      </List>
      <Divider />
      <div className={classes.footer}>
        <img alt="funil" src={PoweredBy} className={classes.poweredBy} />
      </div>
    </>
  );
};
