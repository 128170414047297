import { useQuery } from '@apollo/react-hooks';
import { CONSULTORES_EXTERNOS_QUERY_GQL } from './CONSULTORES_EXTERNOS_QUERY_GQL';

export const useConsultoresExternos = () => {
  const { data = {} } = useQuery(CONSULTORES_EXTERNOS_QUERY_GQL, {
    fetchPolicy: 'cache-first',
  });
  const { consultoresExternos = [] } = data;

  return { consultoresExternos };
};
