import { isStringNotBlank } from '@tecsinapse/es-utils/build';
import { getRawAdressString } from '../../../utils/RouteDialog';

export const allowEditarOportunidade = cliente =>
  cliente?.ultimaOportunidadeAberta?.id;

export const allowInformarContato = (cliente, usuario) =>
  navigator.onLine && cliente && usuario?.vendedorCrm;

export const allowRealizarFichaVisita = ({ usuarioLogado, selectedClient }) =>
  usuarioLogado?.consultorExterno &&
  selectedClient?.podeRealizarAtividadeFichaVisita;

export const clientAllowRoute = cliente =>
  cliente && isStringNotBlank(getRawAdressString(cliente)) > 0;
