import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback, useContext, useEffect } from 'react';
import { NotificadorContext } from './NotificadorContext';

const GET_NOTIFICACOES = gql(`
    query GetNotificacoes($keycloakId: String!, $topicos: [String]!){
        buscaNotificacoes(keycloakId: $keycloakId, topicos: $topicos){
            pk
            usuario {
              id
            }
            topicoNotificador
            relacionamentoId
            enviarSomenteApos
            enviado
            visualizado
            payload
        }
    }
`);

const SET_NOTIFICACAO_VISUALIZADA = gql(`
    mutation SetNotificacaoVisualizada($pks: [ID]!, $visualizado: Boolean!){
        atualizaStatusNotificacao(pks: $pks, visualizado: $visualizado)
    }
`);

const ADICIONA_NOTIFICACAO = gql(`
    mutation AdicionaNotificacao($notificacao: NotificacaoInput!){
        adicionaNotificacao(notificacao: $notificacao){
          pk
          usuario {
            id
          }
          topicoNotificador
          relacionamentoId
          enviarSomenteApos
          enviado
          visualizado
          payload
        }
    }
`);

const REMOVE_NOTIFICACAO = gql(`
    mutation RemoveNotificacao($pk: ID!){
        removeNotificacao(pk: $pk)
    }
`);

export const useNotificadorHook = () => {
  const { notificadorContext, setNotificadorContext } = useContext(
    NotificadorContext
  );
  const [buscaNotificacoesGQL, buscaNotificacaoData] = useLazyQuery(
    GET_NOTIFICACOES
  );
  const [atualizaStatusNotificacaoGQL] = useMutation(
    SET_NOTIFICACAO_VISUALIZADA
  );
  const [adicionaNotificacaoGQL] = useMutation(ADICIONA_NOTIFICACAO);
  const [removeNotificacaoGQL] = useMutation(REMOVE_NOTIFICACAO);
  const notificacoesNaoEnviadas =
    notificadorContext.filter(notificacao => !notificacao.enviado) || [];
  const notificacoesEnviadas =
    notificadorContext.filter(notificacao => notificacao.enviado) || [];
  const notificacoesNaoVisualizadas =
    notificadorContext.filter(
      notificacao => notificacao.enviado && !notificacao.visualizado
    ) || [];

  const adicionaItensContexto = useCallback(
    (notificacoes, reset = false) => {
      const normalizadas = notificacoes.map(notificacao => ({
        ...notificacao,
        payload: JSON.parse(notificacao.payload),
      }));

      if (reset) {
        setNotificadorContext(normalizadas);

        return;
      }
      setNotificadorContext(existentes => [...existentes, ...normalizadas]);
    },
    [setNotificadorContext]
  );

  const registraChegadaNotificacao = useCallback(
    novaNotificacao => {
      const notificacaoDoContexto = notificadorContext.find(
        notificacaoCtx => notificacaoCtx.pk === novaNotificacao.pk
      );

      if (!notificacaoDoContexto) {
        adicionaItensContexto([novaNotificacao]);

        return;
      }

      notificacaoDoContexto.enviado = true;
      setNotificadorContext([...notificadorContext]);
    },
    [notificadorContext, adicionaItensContexto, setNotificadorContext]
  );

  const buscaNotificacoes = useCallback(
    (keycloakId, topicos) => {
      buscaNotificacoesGQL({
        variables: {
          keycloakId,
          topicos,
        },
      });
    },
    [buscaNotificacoesGQL]
  );

  const deletaNotificacaoDoContexto = useCallback(
    pk => {
      const notificacoesFiltradas = notificadorContext.filter(
        notificacaoCtx => notificacaoCtx.pk !== pk
      );

      setNotificadorContext([...notificacoesFiltradas]);
    },
    [notificadorContext, setNotificadorContext]
  );

  const handleMarcarComoVisualizado = useCallback(
    pks => {
      atualizaStatusNotificacaoGQL({
        variables: {
          pks,
          visualizado: true,
        },
      });

      pks.forEach(pk => {
        const notificacaoDoContexto = notificadorContext.find(
          notificacaoCtx => notificacaoCtx.pk === pk
        );

        if (notificacaoDoContexto) {
          notificacaoDoContexto.visualizado = true;
          setNotificadorContext([...notificadorContext]);
        }
      });
    },
    [notificadorContext, atualizaStatusNotificacaoGQL, setNotificadorContext]
  );

  const handleAdicionaNotificacao = useCallback(
    async (
      keycloakId,
      topico,
      relacionamentoId,
      enviarSomenteApos,
      payload
    ) => {
      const { data } = await adicionaNotificacaoGQL({
        variables: {
          notificacao: {
            keycloakId,
            topico,
            relacionamentoId,
            enviarSomenteApos,
            payload,
          },
        },
      });

      if (data?.adicionaNotificacao) {
        const possuiItemContexto = !!notificadorContext.find(
          notificacao => notificacao.pk === data?.adicionaNotificacao.pk
        );

        if (!possuiItemContexto) {
          adicionaItensContexto([data?.adicionaNotificacao]);
        }
      }

      return data;
    },
    [adicionaNotificacaoGQL, adicionaItensContexto, notificadorContext]
  );

  const handleRemoveNotificacao = useCallback(
    async pk => {
      await removeNotificacaoGQL({
        variables: {
          pk,
        },
      });
      deletaNotificacaoDoContexto(pk);
    },
    [deletaNotificacaoDoContexto, removeNotificacaoGQL]
  );

  useEffect(() => {
    const { data } = buscaNotificacaoData;

    if (data?.buscaNotificacoes) {
      adicionaItensContexto(data?.buscaNotificacoes, true);
    }
  }, [adicionaItensContexto, buscaNotificacaoData]);

  return {
    notificadorContext,
    notificacoesEnviadas,
    notificacoesNaoEnviadas,
    notificacoesNaoVisualizadas,
    handleAdicionaNotificacao,
    handleRemoveNotificacao,
    handleMarcarComoVisualizado,
    buscaNotificacoes,
    registraChegadaNotificacao,
  };
};
