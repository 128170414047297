import { Button, IconButton } from '@tecsinapse/ui-kit';

import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { AccountCircle, Close, Share } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { OpportunitySelectedTab } from '../../core/Enums';
import { ProposalPaymentMethodsContainer } from '../proposal/payment/ProposalPaymentMethodsContainer';
import { SharePdfProposalModal } from '../proposal/SharePdfProposalModal';
import {
  RenderAppLogo,
  ShowBackButton,
  showRightIcon,
  updateAppBarBottom,
  updateAppTitle,
} from '../utils/uiUtils';
import { OpportunityTabs } from './OpportunityTabs';
import { OpportunityTopMenu } from './OpportunityTopMenu';
import { OpportunityPrintWithTemplate } from './OpportunityPrintWithTemplate';
import { OpportunityVehiclesContainer } from './OpportunityVehiclesContainer';
import { podeReeditarProposta, redirecionaAgenda } from './oportunidadeRules';
import { defaultGrey, globalStyle } from '../app/globalStyle';
import { eventPrintProposal } from '../utils/GAUtils';
import { modelosInativos } from '../Atividade/atividadeFunctions';
import { AtualizaModeloInativoDialog } from '../AtualizaModeloInativo/AtualizaModeloInativoDialog';
import { useAtualizarModeloInativoState } from '../AtualizaModeloInativo/useAtualizarModeloInativoState';
import { ProdutoAgregado } from './ProdutoAgrupado';

// para usar as consultas comgraphql

const style = theme => ({
  snackbar: {
    top: 104,
  },
  snackbarContent: {
    width: 360,
  },
  moveUp: {
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  moveDown: {
    paddingTop: '60px',
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  snackbarFragment: {
    display: 'flex',
    alignContent: 'center',
  },
  ...globalStyle(theme),
});

const DEALERS_IMPRESSAO_PROPOSTA = gql`
  query getDealersImpressaoPropostaQuery($id: ID!) {
    getDealersImpressaoProposta(id: $id) {
      filiais {
        id
        nome
      }
    }
  }
`;

export const OpportunityComponent = withStyles(style)(
  ({
    classes,
    selectedTab,
    history,
    disabledForm,
    data: { oportunidade },
    reeditarPropostaInput,
    idProposta,
    proposta,
    idOportunidade,
    propostaUrl,
    setPropostaUrl,
    paymentSumOk,
    snackBarOpen,
    setSnackBarOpen,
    canShareProposal,
    printProposal,
    activityBranch,
    crmObrigarModeloInteresseAtivo,
    location,
    urlRetorno,
    ...props
  }) => {
    const redirectHome = redirecionaAgenda(location);

    const { data: getDealersImpressaoProposta = {} } = useQuery(
      DEALERS_IMPRESSAO_PROPOSTA,
      {
        variables: {
          id: idOportunidade || null,
        },
      }
    );

    const { filiais = null } =
      getDealersImpressaoProposta.getDealersImpressaoProposta || {};
    const modelosInativosList = modelosInativos(activityBranch);

    // TODO - LIP  separar isso em um hoc

    const {
      current,
      setCurrent,
      veiculos,
      naoPodeExecutarAcao,
      openAtualizaModeloInativoDialog,
      setOpenAtualizaModeloInativoDialog,
    } = useAtualizarModeloInativoState(modelosInativosList);

    if (naoPodeExecutarAcao && crmObrigarModeloInteresseAtivo) {
      return (
        <div>
          <AtualizaModeloInativoDialog
            open={openAtualizaModeloInativoDialog}
            veiculos={veiculos}
            idOportunidade={idOportunidade}
            atualizaVeiculo={(vehicleData, currentIndex) => {
              if (currentIndex < veiculos.length - 1) {
                setCurrent(current + 1);
              } else {
                setOpenAtualizaModeloInativoDialog(false);
              }
            }}
            current={current}
            handleClose={() => {
              history.push('/agenda');

              return setOpenAtualizaModeloInativoDialog(false);
            }}
          />
        </div>
      );
    }

    const getBackAction = () => {
      if (urlRetorno) {
        history.push(`/${decodeURIComponent(urlRetorno)}`, { tab: 1 });
      } else {
        history.push('/agenda');
      }
    };

    const avatarChipDiv = { textAlign: 'center', marginTop: '10px' };
    const avatarChipStyle = {
      color: defaultGrey,
    };

    return (
      <>
        <RenderAppLogo />
        {updateAppTitle('Proposta')}
        <ShowBackButton
          IconComponent={Close}
          history={history}
          action={getBackAction}
        />

        {updateAppBarBottom(
          <OpportunityTabs
            selectedTab={selectedTab}
            redirectHome={redirectHome}
            {...props}
          />
        )}

        {navigator.onLine &&
          showRightIcon({
            history,
            action: () => {
              eventPrintProposal();

              if (canShareProposal({ proposta, oportunidade })) {
                printProposal(proposta.id);
              }
            },
            component: canShareProposal({ proposta, oportunidade }) ? (
              <Share />
            ) : (
              <></>
            ),
            menuRight: (
              <OpportunityTopMenu
                podeReeditarProposta={podeReeditarProposta(proposta)}
                oportunidade={oportunidade}
                proposta={proposta}
                paymentSumOk={paymentSumOk}
              />
            ),
            menuInner: filiais ? (
              <OpportunityPrintWithTemplate
                canShareProposal={canShareProposal}
                printProposal={printProposal}
                proposta={proposta}
                oportunidade={oportunidade}
                dealersImpressaoProposta={filiais}
              />
            ) : null,
          })}

        <div
          className={
            snackBarOpen && disabledForm ? classes.moveDown : classes.moveUp
          }
        />

        {oportunidade && oportunidade.clienteNome && (
          <div style={avatarChipDiv}>
            <Chip
              avatar={
                <Avatar>
                  <AccountCircle />
                </Avatar>
              }
              label={oportunidade.clienteNome}
              style={avatarChipStyle}
            />
          </div>
        )}

        {selectedTab === OpportunitySelectedTab.VEICULOS.ordinal && (
          <OpportunityVehiclesContainer
            oportunidade={oportunidade}
            opportunityId={idOportunidade}
            disabledForm={disabledForm}
            {...props}
          />
        )}

        {selectedTab === OpportunitySelectedTab.PAGAMENTOS.ordinal && (
          <ProposalPaymentMethodsContainer
            idOportunidade={idOportunidade}
            disabledForm={disabledForm}
            idProposta={idProposta}
            proposta={proposta}
            {...props}
          />
        )}

        {selectedTab === OpportunitySelectedTab.PRODUTOS_AGREGADOS.ordinal && (
          <ProdutoAgregado
            oportunidade={oportunidade}
            idOportunidade={idOportunidade}
            disabledForm={disabledForm}
            idProposta={idProposta}
            {...props}
          />
        )}

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={classes.snackbar}
          open={snackBarOpen && disabledForm}
          onClose={() => setSnackBarOpen(false)}
          autoHideDuration={10000}
          message={
            <div className={classes.snackbarFragment}>
              <ErrorIcon className={classes.marginRight10} />
              <span id="message-id">Edição bloqueada</span>
            </div>
          }
          ContentProps={{
            'aria-describedby': 'snackbar-fab-message-id',
            className: classes.snackbarContent,
          }}
          action={[
            ...(podeReeditarProposta(proposta)
              ? [
                  <Button
                    key="undo"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      reeditarPropostaInput({ oportunidade });
                    }}
                  >
                    Reeditar
                  </Button>,
                ]
              : []),
            <IconButton
              key="close"
              aria-label="Fechar"
              color="inherit"
              className={classes.close}
              onClick={() => setSnackBarOpen(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <SharePdfProposalModal
          propostaUrl={propostaUrl}
          setPropostaUrl={setPropostaUrl}
        />
      </>
    );
  }
);
