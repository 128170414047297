import Keycloak from 'keycloak-js';
import localforage from 'localforage';
import envs from '../../configs/envs';
import Sentry from '../SentryWrapper';

const keycloak = Keycloak({
  ...envs[process.env.REACT_APP_KC_ENV][process.env.REACT_APP_KC_ENV_TYPE],
});

const oldLoadUserProfile = keycloak.loadUserProfile;

keycloak.loadUserProfile = async function getUserProfile() {
  const profile = await localforage.getItem('userProfile');

  if (profile == null) {
    await oldLoadUserProfile();
    await localforage.setItem('userProfile', keycloak.profile);
    keycloak.profile = profile;
  } else {
    keycloak.profile = profile;
  }

  if (Sentry && keycloak && keycloak.profile) {
    Sentry.configureScope(scope =>
      scope.setUser({ email: keycloak.profile.email })
    );
  }

  return keycloak.profile;
};

export { keycloak };
