import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../../utils/graphql/graphqlOfflineMutation';

export const editClientMutationGql = gql`
  mutation editClient($client: ClienteInput!) {
    editarCliente(cliente: $client) {
      id
      nome
      cpfCnpj
      telefone
      telefoneComercial
      celular
      email
      idDealer
      ultimaOportunidadeAberta {
        id
      }
      divisaoRegional {
        id
        nome
      }
      endereco {
        id
        cep
        logradouro
        bairro
        numero
        complemento
        cidade
        estado {
          id
          nome
          sigla
        }
      }
      unidadesNegocioAtendidos {
        id
        descricao
      }
    }
  }
`;

export const editClientMutationOptions = {
  name: 'editClient',
  mutationName: 'editClientMutationGql',
  customErrorsHandler: true,
  description: async ({
    variables: {
      client: { id, nome },
    },
  }) =>
    id == null
      ? `Criação de novo cliente com nome: ${nome}`
      : `Edição de dados cadastrais do cliente ${nome}`,
};

export const EditClientMutation = graphqlOfflineMutation(
  editClientMutationGql,
  editClientMutationOptions
);
