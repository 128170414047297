import React from 'react';
import { AppThemeProvider } from '../../AppTheme';
import { OfflineSyncDialog } from '../../../components/utils/dialogs/OfflineSyncDialog';

export const PendingOfflineMutationsSelect = ({
  runMutationsFunc,
  lastUpdateTime,
  online,
}) => (
  <AppThemeProvider>
    <>
      <OfflineSyncDialog
        lastUpdateTime={lastUpdateTime}
        online={online}
        onConfirm={runMutationsFunc}
        onCancel={runMutationsFunc}
      />
    </>
  </AppThemeProvider>
);
