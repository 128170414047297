import { Typography } from '@material-ui/core';
import React from 'react';
import { styles } from '../Weekdays';

export const Day = ({
  today,
  dayLabel = null,
  day,
  index,
  selectedWeekDay,
  setSelectedDayAndSendToAnalytics,
  showUnderDot = true,
  classes,
  showAtrasadas,
}) => {
  const dayOfWeek = day ? day.moment : null;
  const isToday = day && day.moment.isSame(today, 'day');
  const dayStyle =
    isToday && !showAtrasadas
      ? {
          fontWeight: 'bold',
        }
      : {
          color: 'rgba(255, 255, 255, .5)',
        };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classes.day}
      style={
        index === selectedWeekDay && !showAtrasadas
          ? { backgroundColor: '#3a3a3a' }
          : {}
      }
      onClick={() => setSelectedDayAndSendToAnalytics({ isToday, dayOfWeek })}
    >
      <Typography
        variant="caption"
        color="inherit"
        className={classes.dayName}
        style={dayStyle}
      >
        {dayLabel || day.name}
      </Typography>
      <Typography
        variant="subtitle2"
        gutterBottom
        color="inherit"
        style={{ ...styles.dayTypography, ...dayStyle }}
      >
        {dayOfWeek && dayOfWeek.format('DD')}
      </Typography>

      {showUnderDot && (
        <div className={classes.dotContainer}>
          <div className={classes.textDot} />
        </div>
      )}
    </div>
  );
};
