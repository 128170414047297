import { useQuery } from '@apollo/react-hooks';
import { CHECK_INS_PAGINATION_QUERY_GQL } from './CHECK_INS_PAGINATION_QUERY_GQL';

export const useCheckIns = ({
  checkInSort,
  checkInApenasComImagem,
  checkInApenasVisitaRegistrada,
  checkInConsultorExterno,
  checkInDealer,
  modulo,
}) => {
  const {
    data: { listarCheckIns: { checkIns = [], hasNext, cursor } = {} } = {},
    fetchMore,
    loading,
  } = useQuery(CHECK_INS_PAGINATION_QUERY_GQL, {
    variables: {
      cursor: 0,
      sort: checkInSort,
      imagem: checkInApenasComImagem,
      visitaRegistrada: checkInApenasVisitaRegistrada,
      idConsultorExterno: parseInt(checkInConsultorExterno?.id, 10),
      idDealer: parseInt(checkInDealer?.id, 10),
      modulo,
    },
  });

  const loadMore = () =>
    fetchMore({
      variables: {
        cursor: cursor ? cursor + 1 : 0,
      },
      updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => ({
        ...previousResult,
        listarCheckIns: {
          ...fetchMoreResult.listarCheckIns,
          checkIns: [
            ...previousResult.listarCheckIns.checkIns,
            ...fetchMoreResult.listarCheckIns.checkIns,
          ],
        },
      }),
    });

  return {
    checkIns,
    loadMore,
    loading,
    hasNext,
  };
};
