import { branch, renderComponent } from 'recompose';
import { withRouter } from 'react-router-dom';
import { isNotEmptyOrNull } from '@tecsinapse/es-utils/build';
import { Loading } from './Loading';
import { Error } from './Errors/Error';

const passivelyRefetching = networkStatus =>
  networkStatus === 2 || networkStatus === 6;

export const displayLoadingState = ({
  name = 'data',
  onLoadMore = true,
  skipable = true,
  onPassiveRefetch = true,
  onPassiveRefetchOnly = false,
  onCacheLoadOnlyQueryName = null,
  verifyVariablesHasUpdate = null,
  LoadingComponent,
} = {}) =>
  branch(props => {
    if (!props[name]) {
      return !skipable;
    }

    if (onCacheLoadOnlyQueryName) {
      if (
        verifyVariablesHasUpdate &&
        verifyVariablesHasUpdate(props) &&
        props[name][onCacheLoadOnlyQueryName] &&
        props[name].loading
      ) {
        return true;
      }

      if (props[name][onCacheLoadOnlyQueryName]) {
        return false;
      }
    }

    if (
      !passivelyRefetching(props[name].networkStatus) &&
      onPassiveRefetchOnly
    ) {
      return false;
    }

    if (onLoadMore === false && props[name].networkStatus === 3) {
      return false;
    }

    if (passivelyRefetching(props[name].networkStatus) && !onPassiveRefetch) {
      return false;
    }

    return props[name] && props[name].loading;
  }, renderComponent(LoadingComponent || Loading));

export const displayErrorState = branch(props => {
  if (isNotEmptyOrNull(props.data) && isNotEmptyOrNull(props.data.error)) {
    // eslint-disable-next-line
    console.log(props.data);
    // eslint-disable-next-line
    console.log(props.data.error);

    return true;
  }

  return false;
}, renderComponent(withRouter(Error)));

export const displayLoadingStateQueries = (
  queriesNames,
  LoadingComp = Loading
) => {
  return branch(
    props =>
      isNotEmptyOrNull(
        queriesNames.filter(
          queryName => props[queryName] && props[queryName].loading
        )
      ),
    renderComponent(LoadingComp)
  );
};
