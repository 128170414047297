import Grid from '@material-ui/core/Grid';
import React from 'react';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import { mdiWifiOff } from '@mdi/js';
import Icon from '@mdi/react';
import {
  ExcellenceClubContainer,
  ExcellenceClubItemContainer,
  ExcellenceItemMainText,
  ExcellenceItemSecundaryText,
  ExcellenceItemSubtitle,
} from '../ExcellenceClubStyledComponents/ExcellenceClubStyledComponents';
import { cardGreenDark, cardRedDark } from '../../../../../../app/globalStyle';

export const ExcellenceClubItem = ({
  texto,
  valor,
  valorPorcentagem,
  size = 3,
  onClick,
  limite,
  borderColor,
}) => {
  const cor = valorPorcentagem >= limite ? cardGreenDark : cardRedDark;
  const s = valorPorcentagem >= 100 ? 100 : valorPorcentagem;
  const number = parseInt(s, 10);
  const iconStyle = { lineHeight: '80%' };

  return (
    <Grid item xs={size} onClick={onClick}>
      <ExcellenceClubContainer borderColor={borderColor}>
        <ExcellenceClubItemContainer>
          {!isNotUndefOrNull(valor) && (
            <ExcellenceItemSubtitle color={cor}>
              <Icon path={mdiWifiOff} size={0.75} style={iconStyle} />
            </ExcellenceItemSubtitle>
          )}
          {isNotUndefOrNull(valor) && (
            <ExcellenceItemMainText>{`${valor}`}</ExcellenceItemMainText>
          )}
          {isNotUndefOrNull(valorPorcentagem) && !isNaN(number) && (
            <ExcellenceItemSecundaryText color={cor}>
              {`${number}%`}
            </ExcellenceItemSecundaryText>
          )}
        </ExcellenceClubItemContainer>
        <ExcellenceItemSubtitle>{texto}</ExcellenceItemSubtitle>
      </ExcellenceClubContainer>
    </Grid>
  );
};
