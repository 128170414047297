import { Tab, Tabs, Toolbar } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { globalStyle } from '../app/globalStyle';

const useStyleTab = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: '48px',
  },
  child: {
    flex: 1,
  },
  tabVeiculos: {
    paddingLeft: '5px',
    minWidth: '48px',
  },
  tabPagamentos: {
    minWidth: '48px',
  },
  ...globalStyle(theme),
}));

export const OpportunityTabs = ({
  selectedTab,
  setSelectedTab,
  redirectHome,
}) => {
  const classes = useStyleTab();

  return (
    <Toolbar className={classes.root}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        className={classes.child}
        value={selectedTab}
        onChange={(e, v) => setSelectedTab(v, redirectHome)}
      >
        <Tab label="Veículos" className={classes.tabVeiculos} />
        <Tab label="Formas de Pagto." className={classes.tabPagamentos} />
        <Tab label="Produtos Agregados" className={classes.tabPagamentos} />
      </Tabs>
    </Toolbar>
  );
};
