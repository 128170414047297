import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import { procuraRelacaoSegmento } from 'components/Home/ActivityCard/IconesSegmento';
import moment from 'moment';
import React, { useMemo } from 'react';
import { TemperaturaNegociacao } from '../../core/Enums';
import {
  cardStyles,
  colorOfAvatar,
  labelOfActivity,
} from '../Home/ActivityCard/activityCardFunctions';
import { getColorOfTemperatura } from '../utils/cardStyleUtils';
import IconUnidadeNegocio from '../IconUnidadeNegocio';

const getTitle = ({ tipo = '' }) => {
  let titulo = '';

  if (tipo === 'LEAD') {
    titulo = 'Prospecçao';
  } else if (tipo === 'OPORTUNIDADE') {
    titulo = 'Oportunidade';
  } else if (tipo === 'CONTATO_NEGOCIO') {
    titulo = 'Contato de Negociação';
  } else if (tipo === 'FICHA_VISITA') {
    titulo = 'Ficha Visita';
  } else {
    titulo = tipo;
  }

  return titulo;
};

const userCardStyles = makeStyles(cardStyles);

const style = { padding: 0 };

export const HistoricoTimelineItemCard = ({ item, cardOnClick }) => {
  const classes = userCardStyles();
  const style1 = useMemo(
    () => ({
      backgroundColor: getColorOfTemperatura(item.temperatura),
      color: 'white',
    }),
    [item.temperatura]
  );

  return (
    <Card className={classes.card} style={style} onClick={cardOnClick}>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.paddingCard}
        avatar={
          <Avatar
            style={{
              ...colorOfAvatar({ tipoOrigem: item.tipo.toUpperCase() }),
            }}
            classes={{ root: classes.avatar }}
          >
            {item.segmento ? (
              <IconUnidadeNegocio
                descricaoNormalizado={procuraRelacaoSegmento(
                  item.segmento.toLowerCase()
                )}
              />
            ) : (
              labelOfActivity(item)
            )}
          </Avatar>
        }
        title={
          <>
            <div>{`${getTitle(item)} #${item.id}`}</div>
          </>
        }
        subheader={
          <Typography variant="subtitle2" display="block">
            <div style={{ color: item.corStatus }}>{item.status}</div>
          </Typography>
        }
      />

      <CardContent className={classes.cardContent}>
        <Typography variant="caption" display="block">
          {item.dataHoraUltimoContato
            ? `Último contato: ${moment(item.dataHoraUltimoContato).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : 'Nenhum Contato Realizado'}
        </Typography>

        {item.temperatura && (
          <Chip
            label={TemperaturaNegociacao.enumValueOf(item.temperatura).nome}
            classes={{
              root: classes.chipMargin,
            }}
            style={style1}
          />
        )}
      </CardContent>
    </Card>
  );
};
