import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';

export const goToRoute = addressString => {
  const url = `https://www.google.com/maps/search/?api=1&query=${addressString}`;

  window.open(url, '_blank');
};

export const getRawAdressString = cliente => {
  const address = cliente.endereco;
  const estadoNome = address.estado ? address.estado.nome : '';

  return `${address.cidade || ''} ${estadoNome} ${address.cep || ''} ${
    address.logradouro || ''
  } ${address.bairro || ''} ${address.numero || ''} ${
    address.complemento || ''
  }`;
};

export const getAdressStringOfActivity = cliente =>
  getRawAdressString(cliente).replace(' ', '+');

const enhance = compose(
  withRouter,
  withHandlers({
    goToEditClient: ({ history }) => ({ client }) => {
      history.push(`/editarCliente/${client.id}`);
    },
  })
);

export const RouteDialog = enhance(
  ({
    client = {},
    open,
    handleClose,
    goToEditClient,
    showEditButton = true,
  }) => (
    <Dialog open={open || false} onClose={handleClose}>
      <DialogTitle id="simple-dialog-title">
        Informações incompletas
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          As Informações de endereço não estão completas e podem não indicar o
          local corretamente, deseja traçar a rota mesmo assim?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => goToRoute(getAdressStringOfActivity(client))}
          color="primary"
          autoFocus
        >
          Sim
        </Button>
        {showEditButton && (
          <Button
            onClick={() => goToEditClient({ client })}
            color="primary"
            autoFocus
          >
            Editar Endereço
          </Button>
        )}
        <Button onClick={handleClose} color="primary" autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
);
