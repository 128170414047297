import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { hideAlertAction } from '../../reducers/alert';
import { StyledSnackbarContent } from './dialogs/StyledSnackbarContent';
import { withStoreConsumer } from './StoreConsumer';

const enhance = compose(
  withRouter,
  connect(state => ({ ...state.alert })),
  withStoreConsumer,
  withHandlers({
    closeAlert: ({ store }) => () => {
      store.dispatch(hideAlertAction());
    },
  })
);

export const Alert = enhance(
  ({ title, message, chip = false, variant = 'success', show, closeAlert }) => (
    <>
      {!chip && (
        <Dialog
          open={show}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {chip && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={show}
          autoHideDuration={6000}
          onClose={closeAlert}
        >
          <StyledSnackbarContent
            onClose={closeAlert}
            variant={variant}
            message={message}
          />
        </Snackbar>
      )}
    </>
  )
);
