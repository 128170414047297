import { compose, withHandlers, withProps, withState } from 'recompose';
import 'react-circular-progressbar/dist/styles.css';
import { getFirstElementOfArray, isNotUndefOrNull } from '@tecsinapse/es-utils';
import moment from 'moment';
import { displayErrorState } from '../../utils/displayLoadingState';
import {
  DealersAcessoIndicadoresUsuarioLogadoQueryDataName,
  DealersAcessoIndicadoresUsuarioLogadoQueryHoc,
  DealersAcessoIndicadoresUsuarioLogadoQueryName,
} from '../../Client/client/data/queries/DealersAcessoIndicadoresUsuarioLogadoQueryHoc';

import { Indicadores } from './Indicadores';
import { withVendedoresAtivos } from '../data/VendedoresAtivosQuery';
import { withActivityBottomSheet } from '../../Home/withActivityBottomSheet';
import { withConfirmarEntrarEmContatoModal } from '../../providers/InformarContato/withConfirmarEntrarEmContatoModal';
import { withSelecaoModeloInteresse } from '../../SelecaoModeloInteresse/withSelecaoModeloInteresse';
import { withTemperaturasQuery } from '../../RealizarAtividade/data/queries/TemperaturasQuery';
import { withIndicadorFupMobileAtivo } from '../../opportunity/data/queries/crmTermosQuery';
import { withUsuarioLogado } from '../../UsuarioLogado/withUsuarioLogado';

const enhance = compose(
  displayErrorState,
  DealersAcessoIndicadoresUsuarioLogadoQueryHoc,
  withUsuarioLogado,
  withIndicadorFupMobileAtivo,
  withTemperaturasQuery,
  withProps(({ match }) => ({
    urlRetorno: match.params.urlRetorno,
  })),
  withProps(
    ({
      [DealersAcessoIndicadoresUsuarioLogadoQueryName]: {
        [DealersAcessoIndicadoresUsuarioLogadoQueryDataName]: dealers,
      },
      usuarioLogado,
    }) => {
      const usuarioVendedor =
        usuarioLogado?.vendedorCrm &&
        !(usuarioLogado?.gerente || usuarioLogado?.administrador)
          ? usuarioLogado
          : null;

      return {
        dealers,
        usuarioVendedor,
        exibirMensagens:
          usuarioLogado &&
          !(usuarioLogado.gerente || usuarioLogado.administrador),
      };
    }
  ),
  withState('dealerFiltro', 'setDealerFiltro', ({ dealers }) =>
    getFirstElementOfArray(dealers)
  ),
  withState('unidadeNegocioFiltro', 'setUnidadeNegocioFiltro', null),
  withState(
    'vendedorFiltro',
    'setVendedorFiltro',
    ({ usuarioVendedor }) => usuarioVendedor
  ),
  withState('title', 'setTitle', null),
  withState('showActivitiesDialog', 'setShowActivitiesDialog', false),
  withState('periodoGlobal', 'setPeriodoGlobal', 'month'),
  withState('anoMesFinalGlobal', 'setAnoMesFinalGlobal', moment()),
  withState('menuOpen', 'setMenuOpen', false),
  withState('marcaGlobal', 'setMarcaGlobal', ({ usuarioLogado }) =>
    !isNotUndefOrNull ? null : getFirstElementOfArray(usuarioLogado.marcas).id
  ),
  withState('filtrosBusca', 'setFiltrosBusca', {
    dataInicio: null,
    dataFim: null,
    finalizado: null,
    temperatura: null,
    ids: null,
    marca: null,
  }),
  withVendedoresAtivos,
  withHandlers({
    menuBottomSheetOnClose: ({ setMenuOpen }) => () => {
      setMenuOpen(false);
    },
    showMenuBottomSheet: ({ setMenuOpen }) => () => {
      setMenuOpen(true);
    },
    onCloseDialogAtividadesIndicadores: ({
      setVendedorFiltro,
      usuarioVendedor,
    }) => () => {
      setVendedorFiltro(usuarioVendedor);
    },
    showDialogAtividades: ({
      setFiltrosBusca,
      setShowActivitiesDialog,
      dealerFiltro,
      unidadeNegocioFiltro,
      vendedorFiltro,
      setTitle,
    }) => (filtros, novoTitulo = null) => {
      const { exibirAtividadesDeAdmins } = filtros;
      let { ids } = filtros;

      if (filtros.ids !== undefined && filtros.ids !== null) {
        ids = filtros.ids.length > 0 ? filtros.ids : [-1];
      }

      setTitle(novoTitulo);

      setFiltrosBusca({
        ...filtros,
        exibirAtividadesDeAdmins: exibirAtividadesDeAdmins || null,
        usuarioId: vendedorFiltro ? vendedorFiltro.id : null,
        dealerPk: dealerFiltro && dealerFiltro.id,
        unidadeNegocioId: unidadeNegocioFiltro && unidadeNegocioFiltro.id,
        ids,
      });
      setShowActivitiesDialog(true);
    },
  }),
  withConfirmarEntrarEmContatoModal,
  withSelecaoModeloInteresse,
  withActivityBottomSheet
);

export const IndicadoresContainer = enhance(Indicadores);
