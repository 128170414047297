import { Logger } from './log/logUtils';
import { ErrorOffline } from '../../core/offline/exception/ErrorOffline';
import { saveMutationOffline } from '../../core/offline/offlineHelpers/offlineMutationHelpers';
import { getErrorMessage } from './uiUtils';

export const mutationsErrorsHandler = ({
  showAlert,
  hideLoading,
  mutationName = null,
  variables = null,
  description = null,
  chip = false,
}) => ({ graphQLErrors = [] } = {}) => {
  if (navigator.onLine) {
    Logger.error(JSON.stringify(graphQLErrors));
  }

  const message = getErrorMessage(graphQLErrors);

  const title = 'Erro!';

  if (hideLoading) {
    hideLoading();
  }

  if (graphQLErrors instanceof ErrorOffline && description !== null) {
    saveMutationOffline({
      showAlert,
      mutationName,
      entityName: graphQLErrors.entityName,
      savedEntityName: graphQLErrors.savedEntityName,
      idName: graphQLErrors.idName,
      variables,
      description,
    });
  } else {
    const params = { message, title };

    if (chip) {
      params.chip = true;
      params.variant = 'error';
    }

    showAlert(params);
  }
};
