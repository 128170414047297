import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';
import reeditarPropostaMutationGql from './reeditarPropostaMutationGql.graphql';
import ProposalApprovalMutationGql from './ProposalApprovalMutationGql.graphql';
import ProposalGenerateMutationGql from './ProposalGenerateMutationGql.graphql';
import removerModeloInteresseGql from './removerModeloInteresseGql.graphql';
import atualizarStatusModeloInteresseGql from './atualizarStatusModeloInteresseGql.graphql';
import PerformTestDriveMutationGql from './PerformTestDriveMutationGql.graphql';

export const reeditarPropostaMutation = graphqlOfflineMutation(
  reeditarPropostaMutationGql,
  {
    name: 'reeditarProposta',
    mutationName: 'reeditarPropostaMutationGql',
  }
);

export const ProposalApprovalMutation = graphqlOfflineMutation(
  ProposalApprovalMutationGql,
  {
    name: 'requestProposalApproval',
    mutationName: 'ProposalApprovalMutationGql',
  }
);

export const ProposalGenerateMutation = graphqlOfflineMutation(
  ProposalGenerateMutationGql,
  {
    name: 'generateProposal',
    mutationName: 'ProposalGenerateMutationGql',
    showSucessAlert: false,
  }
);

export const mutation = graphqlOfflineMutation(removerModeloInteresseGql, {
  name: 'removerModeloInteresse',
  mutationName: 'removerModeloInteresseGql',
});

export const atualizarStatusModeloInteresseMutation = graphqlOfflineMutation(
  atualizarStatusModeloInteresseGql,
  {
    name: 'atualizarStatusModeloInteresse',
    mutationName: 'atualizarStatusModeloInteresseGql',
  }
);

export const PerformTestDriveMutation = graphqlOfflineMutation(
  PerformTestDriveMutationGql,
  {
    name: 'performTestDrive',
    mutationName: 'PerformTestDriveMutation',
  }
);
