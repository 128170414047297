import React from 'react';
import { ThumbDown } from '@material-ui/icons';
import { compose } from 'recompose';
import { graphql } from '@apollo/react-hoc';
import { EmptyState } from '@tecsinapse/ui-kit';

import { useQuery } from '@apollo/react-hooks';
import InfiniteScroll from '../../utils/InfiniteScroll';
import { enumGetNames, TipoAtividade } from '../../../core/Enums';
import { ActivityCard } from '../ActivityCard/ActivityCard';
import GetAtividadesComPaginacaoQueryGql from '../data/queries/GetAtividadesComPaginacaoQueryGql.graphql';
import { displayLoadingState } from '../../utils/displayLoadingState';
import { LoadingContained } from '../../utils/Loading';
import { withUsuarioLogado } from '../../UsuarioLogado/withUsuarioLogado';
import { useUsuarioLogado } from '../../../hooks/useUsuarioLogado';
import { crmProspeccaoNomePatternQueryGql } from '../../opportunity/data/queries/crmTermosQuery';

const Listing = ({
  data: { getAtividades: { atividades = [], hasNext } = {}, loadMore, loading },
  showSegments,
  activityOnClick,
  onBottomSheetClick,
  tipoOrigemOportunidade,
  exibirTimer,
  exibirBadgeUnidadeNegocio,
}) => {
  const { usuarioGerente, usuarioLogado, usuarioAdmin } = useUsuarioLogado();
  const { data: { crmProspeccaoNomePattern } = {} } = useQuery(
    crmProspeccaoNomePatternQueryGql,
    {
      fetchPolicy: 'cache-first',
    }
  );

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        useCapture
        loadMore={loadMore}
        hasMore={hasNext}
        isLoading={loading}
        initialLoad={false}
        useWindow={false}
        loader={<LoadingContained key="loader" />}
      >
        {atividades.length ? (
          atividades.map(activity => (
            <ActivityCard
              key={activity.id}
              activity={activity}
              showSegments={showSegments}
              activityOnClick={activityOnClick}
              onBottomSheetClick={onBottomSheetClick}
              usuarioGerente={usuarioGerente}
              usuarioAdmin={usuarioAdmin}
              usuarioLogado={usuarioLogado}
              tipoOrigemOportunidade={tipoOrigemOportunidade}
              crmProspeccaoNomePattern={crmProspeccaoNomePattern}
              exibirTimer={exibirTimer}
              exibirBadgeUnidadeNegocio={exibirBadgeUnidadeNegocio}
            />
          ))
        ) : (
          <EmptyState
            IconComponent={ThumbDown}
            message="Não há nenhuma atividade por aqui ainda."
          />
        )}
      </InfiniteScroll>
    </>
  );
};

const AtividadesComPaginacaoQuery = graphql(GetAtividadesComPaginacaoQueryGql, {
  options: ({
    cursor = 0,
    textSearch,
    cidade,
    uf = null,
    finalizado = null,
    temperatura = null,
    unidadeNegocioId = null,
    dataInicio = null,
    dataFim = null,
    ids = [],
    semTemperatura = null,
    dealerPk = null,
    usuario = null,
    somenteAtividadesGerente = null,
    exibirAtividadesDeAdmins = null,
    tipoIndicadorFup48 = null,
    tiposAtividade = enumGetNames(TipoAtividade),
  }) => ({
    variables: {
      cursor,
      textSearch,
      cidade,
      uf,
      dataInicio,
      dataFim,
      finalizado,
      temperatura,
      unidadeNegocioId,
      ids,
      somenteAtividadesGerente,
      exibirAtividadesDeAdmins,
      tipoIndicadorFup48,
      usuarioId: usuario && usuario.id,
      semTemperatura:
        (temperatura && temperatura.semTemperatura) || semTemperatura,
      dealerPk,
      tipos: tiposAtividade,
    },
  }),
  props: ({ data }) => ({
    data: {
      ...data,
      loadMore: () =>
        data.fetchMore({
          variables: {
            cursor: data.getAtividades.cursor ? data.getAtividades.cursor : 0,
          },
          updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => ({
            ...previousResult,
            getAtividades: {
              ...fetchMoreResult.getAtividades,
              atividades: [
                ...previousResult.getAtividades.atividades,
                ...fetchMoreResult.getAtividades.atividades,
              ],
            },
          }),
        }),
    },
  }),
});

const enhance = compose(
  AtividadesComPaginacaoQuery,
  withUsuarioLogado,
  displayLoadingState({ onLoadMore: false, LoadingComponent: LoadingContained })
);

export const SearchListing = enhance(Listing);
