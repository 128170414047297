import { useQuery } from '@apollo/react-hooks';
import { USUARIO_LOGADO_QUERY_GQL } from './USUARIO_LOGADO_QUERY_GQL';

export const useUsuarioLogadoConsultorExterno = () => {
  const { data } = useQuery(USUARIO_LOGADO_QUERY_GQL);
  const usuarioLogado = data && data.usuarioLogado;

  const appConsultorExterno = (process.env.REACT_APP_MODULOS || '')
    .split(',')
    .find(val => val === 'CONSULTOR_EXTERNO');

  return appConsultorExterno && usuarioLogado && usuarioLogado.consultorExterno;
};
