import React, { useEffect } from 'react';
import { Card, CardContent, Grid, Switch, TextField } from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';
import CardActions from '@material-ui/core/CardActions/CardActions';
import Chip from '@material-ui/core/Chip';
import { mdiAnimationOutline, mdiCashUsd, mdiTruck } from '@mdi/js';
import Icon from '@mdi/react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import {
  defaultGreen,
  defaultGrey,
  defaultGreyLight4,
  globalStyle,
} from '../app/globalStyle';
import {
  eventOpportunityEditVehicle,
  eventOpportunityPerformTestDriveVehicle,
  eventOpportunityRemoveVehicle,
} from '../utils/GAUtils';
import { useMarca } from './components/useMarca';

const style1 = { textAlign: 'center' };
const style2 = { borderBottom: 'dotted 1px rgba(0, 0, 0, 0.42' };
const style3 = { textAlign: 'center' };
const style4 = { borderBottom: 'dotted 1px rgba(0, 0, 0, 0.42' };
const style6 = { textAlign: 'right' };
const style7 = { textAlign: 'center' };

const useOpportunityVehiclesCardStyles = makeStyles(theme => ({
  root: {
    backgroundColor: defaultGreyLight4,
    padding: 15,
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  ...globalStyle(theme),
}));

const grayTitle = {
  color: defaultGrey,
};

export const OpportunityVehiclesCard = ({
  vehicle,
  onEditClick,
  onRemoveClick,
  onPerformTestDrive,
  onChangeEmNegociacao,
  disableEdit,
  modeloLabel = 'Modelo',
  crmAnoModeloAtivo,
  oportunidade,
  crmVersaoTermo: versaoLabel,
}) => {
  const classes = useOpportunityVehiclesCardStyles();
  const { exibirMarca, verificarExibirMarca } = useMarca();

  useEffect(() => {
    verificarExibirMarca(oportunidade.id);
  }, []); // eslint-disable-line

  const utilizaMarca = exibirMarca && !vehicle.tabelaPreco;
  const { utilizaModelo, utilizaFamilia } = vehicle;
  const { utilizaTabelaPreco } = oportunidade;
  // 5 -> 3 xs=4 e 2 xs=6
  // 4 -> 2 xs=6 2 xs=6
  // 3 -> 3 xs=4
  // 2 -> 2 xs=6
  // 1 -> 1 xs=12

  // x < 4 = 12/x
  // x >= 4 = dois últimos sempre = 6, 14-2x
  const quantidadeCamposModelo =
    [utilizaMarca, utilizaModelo, utilizaFamilia, utilizaTabelaPreco].filter(
      Boolean
    ).length + 1;
  const xsPrimeiraLinha =
    quantidadeCamposModelo < 4
      ? 12 / quantidadeCamposModelo
      : 14 - 2 * quantidadeCamposModelo;
  const xsSegundaLinha =
    quantidadeCamposModelo < 4 ? 12 / quantidadeCamposModelo : 6;

  const style5 = {
    backgroundColor: vehicle.realizouTestDrive ? defaultGreen : defaultGrey,
    color: defaultGreyLight4,
  };
  const label = `${vehicle.realizouTestDrive ? '' : 'Não '}Realizado`;
  const onChange = () => onChangeEmNegociacao(vehicle);
  const onClick = () => {
    eventOpportunityRemoveVehicle();
    onRemoveClick(vehicle);
  };

  const onClick1 = () => {
    eventOpportunityEditVehicle();
    onEditClick(vehicle);
  };

  const onClick2 = () => {
    eventOpportunityPerformTestDriveVehicle();
    onPerformTestDrive(vehicle);
  };
  const veiculoPrecoTotal =
    (vehicle && vehicle.precoTotalFormatado && vehicle.precoTotalFormatado) ||
    'Não informado';
  const anoModeloString =
    (vehicle && vehicle.anoModelo && vehicle.anoModelo) || 'Não informado';
  const vehicleQuantidade =
    (vehicle && vehicle.quantidade && vehicle.quantidade) || 'Não informado';
  const vehicleVersao =
    (vehicle && vehicle.versao && vehicle.versao.nome) || 'Não informado';
  const vehicleFamilia =
    (vehicle && vehicle.familia && vehicle.familia.nome) || 'Não informado';
  const vehicleModelo =
    (vehicle && vehicle.modelo && vehicle.modelo.nome) || 'Não informado';
  const tabelaPrecoDescricao =
    (vehicle && vehicle.tabelaPreco && vehicle.tabelaPreco.descricao) ||
    'Não informado';
  const vehicleMarca =
    (vehicle && vehicle.marca && vehicle.marca.nome) || 'Não informado';

  return (
    <div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container>
            {utilizaMarca && (
              <Grid item xs={xsPrimeiraLinha}>
                <TextField
                  disabled
                  label="Marca"
                  fullWidth
                  value={vehicleMarca}
                />
              </Grid>
            )}
            {utilizaTabelaPreco && (
              <Grid item xs={xsPrimeiraLinha}>
                <TextField
                  disabled
                  label="Tabela de Preços"
                  fullWidth
                  value={tabelaPrecoDescricao}
                />
              </Grid>
            )}
            {utilizaModelo && (
              <Grid item xs={xsPrimeiraLinha}>
                <TextField
                  disabled
                  label={modeloLabel}
                  fullWidth
                  value={vehicleModelo}
                />
              </Grid>
            )}
            {utilizaFamilia && (
              <Grid item xs={xsSegundaLinha}>
                <TextField
                  disabled
                  label="Série"
                  fullWidth
                  value={vehicleFamilia}
                />
              </Grid>
            )}
            <Grid item xs={xsSegundaLinha}>
              <TextField
                disabled
                label={versaoLabel}
                fullWidth
                value={vehicleVersao}
              />
            </Grid>
          </Grid>
          <Grid container style={style2}>
            <Grid item xs={4}>
              <div style={style1}>
                <div>
                  <Icon
                    path={mdiAnimationOutline}
                    size={1}
                    style={
                      /* eslint-disable-next-line */
                      { opacity: 0.36 }
                    }
                  />
                </div>
                <div style={grayTitle}>
                  <Typography variant="caption">Quantidade</Typography>
                  <Typography variant="subtitle2">
                    {vehicleQuantidade}
                  </Typography>
                </div>
              </div>
            </Grid>
            {crmAnoModeloAtivo && (
              <Grid item xs={4}>
                <div style={style3}>
                  <div>
                    {/* eslint-disable-next-line */}
                    <Icon path={mdiTruck} size={1} style={{ opacity: 0.36 }} />
                  </div>
                  <div style={grayTitle}>
                    <Typography variant="caption">Ano/Modelo</Typography>
                    <Typography variant="subtitle2">
                      {anoModeloString}
                    </Typography>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={4}>
              <div style={style7}>
                <div>
                  {/* eslint-disable-next-line */}
                  <Icon path={mdiCashUsd} size={1} style={{ opacity: 0.36 }} />
                </div>
                <div style={grayTitle}>
                  <Typography variant="caption">Valor Total</Typography>
                  <Typography variant="subtitle2">
                    {veiculoPrecoTotal}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container style={style4}>
            <Grid item xs={8}>
              <Button color="primary" onClick={onClick2}>
                Informar Test-Drive
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Chip size="small" label={label} style={style5} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container>
            <Grid item xs={2}>
              <Button color="primary" onClick={onClick1}>
                Editar
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                color="secondary"
                onClick={onClick}
                disabled={disableEdit}
              >
                Remover
              </Button>
            </Grid>
            <Grid item xs={8} style={style6}>
              <Switch
                disabled={vehicle.permiteAlterarStatus || disableEdit}
                checked={vehicle.emNegociacao}
                onChange={onChange}
              />
              <Typography variant="caption">Em Negociação</Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};
