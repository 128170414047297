import React from 'react';
import { compose, withProps, withState } from 'recompose';
import {
  AtividadesAtrasadasQueryDataName,
  AtividadesAtrasadasQueryHoc,
  AtividadesAtrasadasQueryName,
} from '../../../data/AtividadesCountQuery';
import { IndicadorAtividadesAtrasadasComponent } from './IndicadorAtividadesAtrasadasComponent';

export const IndicadorAtividadesAtrasadasCard = React.memo(
  compose(
    withState(
      'temperaturaAtual',
      'setTemperaturaAtual',
      ({ temperaturas }) => temperaturas && temperaturas[0]
    ),
    AtividadesAtrasadasQueryHoc,
    withProps(
      ({
        [AtividadesAtrasadasQueryName]: {
          [AtividadesAtrasadasQueryDataName]: quantidade = 0,
        } = {},
      }) => ({
        quantidade,
      })
    )
  )(IndicadorAtividadesAtrasadasComponent)
);
