import React from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import { IndicadorOfertaTestDrive } from './IndicadorOfertaTesteDrive/IndicadorOfertaTestDrive';
import { IndicadorFollowUp48h } from './IndicadorFollowUp48h/IndicadorFollowUp48h';
import { getLocalDateTimeString } from '../../../../../utils/dateUtils';
import { CardLoading } from '../../../../../utils/CardLoading';
import { INDICADOR_FUP48_E_TD } from './data/INDICADOR_FUP48_E_TD';

export const IndicadoresExcellenceClubVendedor = ({
  showDialogAtividades,
  unidadeNegocioId,
  dealerId,
  userId,
  periodo,
  marca,
  marcas,
  setMarca,
  exibir,
  setVendedorFiltro,
}) => {
  let dataInicio = getLocalDateTimeString(moment().startOf(periodo));
  let dataFim = getLocalDateTimeString(moment().endOf(periodo));

  if (periodo === 'lastMonth') {
    dataInicio = getLocalDateTimeString(
      moment().subtract(1, 'months').startOf('month')
    );
    dataFim = getLocalDateTimeString(
      moment().subtract(1, 'months').endOf('month')
    );
  }

  const {
    loading,
    data: {
      indicadorFup48eTD: {
        totalTD,
        pendentesTD,
        idsTD,
        totalFup48,
        pendentesFup48,
        idsFup48,
        porcentagemTD,
        porcentagemFup,
      } = {},
    } = {},
  } = useQuery(INDICADOR_FUP48_E_TD, {
    variables: {
      dataInicio,
      dataFim,
      dealerPk: dealerId,
      marcaId: marca,
      userId,
      unidadeNegocioId,
    },
    skip:
      !exibir || !isNotUndefOrNull(dataInicio) || !isNotUndefOrNull(dataFim),
  });

  return (
    <>
      {loading && (
        <>
          <CardLoading />
          <CardLoading />
        </>
      )}

      {exibir && !loading && (
        <>
          <IndicadorOfertaTestDrive
            showDialogAtividades={showDialogAtividades}
            exibirMarca
            marca={marca}
            marcas={marcas}
            setMarca={setMarca}
            totalTD={totalTD}
            pendentesTD={pendentesTD}
            idsTD={idsTD}
            porcentagemTD={porcentagemTD}
            setVendedorFiltro={setVendedorFiltro}
          />
          <IndicadorFollowUp48h
            showDialogAtividades={showDialogAtividades}
            exibirMarca
            marca={marca}
            marcas={marcas}
            setMarca={setMarca}
            totalFup48={totalFup48}
            pendentesFup48={pendentesFup48}
            idsFup48={idsFup48}
            porcentagemFup={porcentagemFup}
            setVendedorFiltro={setVendedorFiltro}
          />
        </>
      )}
    </>
  );
};
