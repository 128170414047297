import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps } from 'recompose';
import { RemoveHOC } from '../../../utils/crud/RemoveHOC';
import { ClienteFrota } from './ClienteFrota';
import { EditClientQueryHoc } from '../../client/data/queries/EditClientQuery';
import { RemoverVeiculoFrotaMutation } from '../data/mutations/RemoverVeiculoFrotaMutation';
import { withSegmentosVendaAtivosQuery } from '../../../Home/data/queries/segmentosVendaAtivosQuery';
import { MarcasFrotaQueryHOC } from '../../../Home/data/queries/marcasFrotaQuery';
import { EditarVeiculoFrotaMutation } from '../data/mutations/EditarVeiculoFrotaMutation';
import { getAlertContext } from '../../../utils/alertContext';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import { withUsuarioLogado } from '../../../UsuarioLogado/withUsuarioLogado';

const enhance = compose(
  EditClientQueryHoc,
  withSegmentosVendaAtivosQuery,
  withUsuarioLogado,
  MarcasFrotaQueryHOC,
  EditarVeiculoFrotaMutation,
  getAlertContext,
  displayLoadingState(),
  RemoveHOC({
    mutation: RemoverVeiculoFrotaMutation,
    mutationName: 'removerVeiculoFrota',
  }),
  withRouter,
  withHandlers({
    editOnClick: ({
      history,
      editClientQuery: {
        cliente: { idDealer },
      },
      segmentos: { segmentosVendaAtivos },
      idSegmentoUnico,
      usuarioLogado: { dealersVendedorLogado },
      marcasFrotaQuery: { marcasFrota },
    }) => (idCliente, veiculo) => {
      history.push(`/editarVeiculoCliente/${idCliente}/${veiculo.id}/`, {
        veiculo,
        segmentosVendaAtivos,
        idSegmentoUnico,
        marcasFrota,
        dealersVendedorLogado,
        idDealer,
      });
    },
    addOnClick: ({
      history,
      editClientQuery: {
        cliente: { idDealer },
      },
      segmentos: { segmentosVendaAtivos },
      idSegmentoUnico,
      usuarioLogado: { dealersVendedorLogado },
      marcasFrotaQuery: { marcasFrota },
    }) => idCliente => {
      history.push(`/editarVeiculoCliente/${idCliente}`, {
        segmentosVendaAtivos,
        idSegmentoUnico,
        marcasFrota,
        dealersVendedorLogado,
        idDealer,
      });
    },
    addQuantidadeClick: ({
      salvarVeiculoFrota,
      showAlert,
      hideLoading,
      editClientQuery: {
        cliente: { id },
      },
    }) => itemFrota => {
      if (!navigator.onLine) {
        showAlert('Offline - Não é possível realizar ação');
      }

      const submitValues = {
        id: itemFrota.id,
        quantidade: itemFrota.quantidade + 1,
        idSegmento: itemFrota.segmento && itemFrota.segmento.id,
        idSubSegmento: itemFrota.subSegmento && itemFrota.subSegmento.id,
        idCliente: id,
        idMarca: itemFrota.marca && itemFrota.marca.id,
        idModelo: itemFrota.modelo && itemFrota.modelo.id,
        anoFabricacao: itemFrota.anoFabricacao && itemFrota.anoFabricacao,
        anoModelo: itemFrota.anoModelo && itemFrota.anoModelo,
      };
      const variables = { itemFrota: submitValues };

      return salvarVeiculoFrota({ variables });
    },
  }),
  withProps(
    ({
      editClientQuery: {
        cliente: { clienteFrota: frota = [], id },
      },
    }) => ({
      frota,
      id,
    })
  )
);

export const ClienteFrotaContainer = enhance(ClienteFrota);
