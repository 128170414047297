import { Divider, Drawer, ListItem, ListItemText } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
  AccountBox,
  CheckCircle,
  Edit,
  Navigation,
  Phone,
  Timeline as TimelineIcon,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { BottomSheetClickEnum } from '../../../../core/Enums';
import {
  eventCallClientBottomSheet,
  eventEditClientBottomSheet,
  eventEditOpportunityBottomSheet,
  eventGoToRouteBottomSheet,
  eventInformCallBottomSheet,
  eventPerformVisitActivityBottomSheet,
} from '../../../utils/GAUtils';
import { GerenteAlert } from './GerenteAlert';
import {
  allowEditarOportunidade,
  allowInformarContato,
  allowRealizarFichaVisita,
  clientAllowRoute,
} from './clienteRules';

const FullList = ({
  onBottomSheetClick,
  selectedClient,
  usuarioLogado,
  hideEditClient,
}) => {
  const [showAlertGerente, setShowAlertGerente] = useState(false);

  return (
    <>
      <GerenteAlert
        show={showAlertGerente}
        closeAlert={() => setShowAlertGerente(false)}
      />

      {allowInformarContato(selectedClient, usuarioLogado) && (
        <ListItem
          button
          onClick={() => {
            if (
              usuarioLogado?.gerente &&
              (selectedClient.gruposSegmentoAtendidos || []).length === 0
            ) {
              setShowAlertGerente(true);
              eventInformCallBottomSheet();
            } else {
              eventInformCallBottomSheet();
              onBottomSheetClick(BottomSheetClickEnum.INFORM_CALL);
            }
          }}
          component="div"
        >
          <ListItemIcon>
            <CheckCircle />
          </ListItemIcon>
          <ListItemText primary="Informar contato" />
        </ListItem>
      )}

      <ListItem
        button
        onClick={() => {
          eventCallClientBottomSheet();
          onBottomSheetClick(BottomSheetClickEnum.CALL_CLIENT);
        }}
      >
        <ListItemIcon>
          <Phone />
        </ListItemIcon>
        <ListItemText primary="Entrar em Contato" />
      </ListItem>
      {clientAllowRoute(selectedClient) && (
        <ListItem
          button
          onClick={() => {
            eventGoToRouteBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.GO_TO_ROUTE);
          }}
        >
          <ListItemIcon>
            <Navigation />
          </ListItemIcon>
          <ListItemText primary="Traçar Rota" />
        </ListItem>
      )}

      <Divider />

      <ListItem
        button
        onClick={() => onBottomSheetClick(BottomSheetClickEnum.SEE_HISTORY)}
      >
        <ListItemIcon>
          <TimelineIcon />
        </ListItemIcon>
        <ListItemText primary="Linha do Tempo" />
      </ListItem>

      {!hideEditClient && (
        <ListItem
          button
          onClick={() => {
            eventEditClientBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.EDIT_CLIENT);
          }}
        >
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="Editar Dados" />
        </ListItem>
      )}

      {allowEditarOportunidade(selectedClient) && (
        <ListItem
          button
          onClick={() => {
            eventEditOpportunityBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.EDIT_OPPORTUNITY);
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Editar Última Proposta Aberta" />
        </ListItem>
      )}

      {allowRealizarFichaVisita({
        usuarioLogado,
        selectedClient,
      }) && (
        <ListItem
          button
          onClick={() => {
            eventPerformVisitActivityBottomSheet();
            onBottomSheetClick(
              BottomSheetClickEnum.PERFORM_FICHA_VISITA_ACTIVITY
            );
          }}
        >
          <ListItemIcon>
            <CheckCircle />
          </ListItemIcon>
          <ListItemText
            primary={
              selectedClient.consultorExternoPk == null
                ? 'Realizar Atividade Avulsa'
                : 'Realizar Atividade'
            }
          />
        </ListItem>
      )}
    </>
  );
};

export const BottomSheet = ({
  selectedClient,
  hideDrawer,
  disableBackdropClick = false,
  hideEditClient = false,
  ...props
}) => (
  <Drawer
    anchor="bottom"
    open={(() => selectedClient !== null)()}
    disableBackdropClick={disableBackdropClick}
    onClose={hideDrawer}
  >
    <FullList
      selectedClient={selectedClient}
      {...props}
      hideEditClient={hideEditClient}
    />
  </Drawer>
);
