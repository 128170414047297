import { Grid, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Field, Form, formValueSelector, reset } from 'redux-form';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import {
  RenderInput,
  required,
  SubmitButton,
  submitWithAction,
} from '../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../utils/uiUtils';
import { RenderSelectField } from '../utils/select/RenderSelectField';
import { withTextTransform } from '../utils/ui/withTextTransform';
import { IconButtonMaiusculas } from './IconButtonMaiusculas/IconButtonMaiusculas';

const style = {
  margin: {
    margin: '20px',
  },
};

function mapDispatchToProps(dispatch) {
  return {
    resetForm: () => dispatch(reset('RealizarAtividadeForm')),
  };
}

const enhance = compose(
  connect(state => {
    const tipoVendaPerdida = formValueSelector('RealizarAtividadeForm')(
      state,
      'tipoVendaPerdida'
    );

    const justificativa = formValueSelector('RealizarAtividadeForm')(
      state,
      'justificativa'
    );

    return {
      tipoVendaPerdida,
      justificativa,
    };
  }, mapDispatchToProps),
  withHandlers({
    validateTipoVendaPerdidaObg: ({
      tipoVendaPerdidaObrigatorio: {
        crmTipoVendaPerdidaObrigatorio = false,
      } = {},
    }) => value => {
      if (crmTipoVendaPerdidaObrigatorio && isEmptyOrNull(value)) {
        return 'Campo obrigatório';
      }

      return undefined;
    },
  }),
  withTextTransform({
    formName: 'RealizarAtividadeForm',
    filds: ['justificativa'],
  })
);

const RealizarAtividadeSecondPageComponent = withStyles(style)(
  ({
    handleSubmit,
    onSubmit,
    classes,
    tipoVendaPerdida,
    motivosVendaPerdidaQuery: { motivosVendaPerdida = [] } = {},
    tiposVendaPerdidaQuery: { tiposVendaPerdida = [] } = {},
    marcasConcorrentesQuery: { marcasConcorrentes = [] } = {},
    validateTipoVendaPerdidaObg,
    formName = null,
    resetForm,
    history,
    setTextAction,
    justificativa,
    ...rest
  }) => (
    <Form onSubmit={handleSubmit} className={classes.margin} name={formName}>
      <RenderAppLogo />
      <ShowBackButton
        action={() => {
          resetForm();
          history.goBack();
        }}
        history={history}
      />
      <Grid container>
        {updateAppTitle('Negócio Perdido')}
        <Grid item xs={12}>
          <Field
            name="tipoVendaPerdida"
            label="Tipo da Venda Perdida"
            required
            validate={validateTipoVendaPerdidaObg}
            component={RenderSelectField}
            fullWidth
          >
            {tiposVendaPerdida.map(({ id, nome }) => (
              <MenuItem value={id} key={id}>
                {nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="motivoVendaPerdida"
            label="Motivo da Venda Perdida"
            component={RenderSelectField}
            fullWidth
          >
            {motivosVendaPerdida.map(({ id, nome }) => (
              <MenuItem value={id} key={id}>
                {nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        {tipoVendaPerdida === 'OUTRA_MARCA' && (
          <Grid item xs={12}>
            <Field
              name="outraMarca"
              label="Outra Marca"
              component={RenderSelectField}
              fullWidth
            >
              {marcasConcorrentes.map(({ id, nome }) => (
                <MenuItem value={id} key={id}>
                  {nome}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        )}
        <Grid item xs={12}>
          <Field
            name="justificativa"
            label="Justificativa"
            required
            validate={[required]}
            multiline
            component={RenderInput}
            fullWidth
            endAdornment={
              <IconButtonMaiusculas
                observacao={justificativa}
                setTextAction={setTextAction}
                fieldKey="justificativa"
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            className={classes.marginTop}
            label="Salvar"
            fullWidth
            onClick={submitWithAction({
              handleSubmit,
              onSubmit,
              action: 'NEGOCIO_PERDIDO',
            })}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            {...rest}
          />
        </Grid>
      </Grid>
    </Form>
  )
);

export const RealizarAtividadeSecondPage = enhance(
  RealizarAtividadeSecondPageComponent
);
