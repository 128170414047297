import omit from 'lodash.omit';
import moment from 'moment';
import { getLocalDateTimeString } from '../../utils/dateUtils';
import {
  eventCloseBusinessCard,
  eventPerformVisitActivity,
} from '../../utils/GAUtils';

export const VISITA_STEPS = ['Dados básicos', 'Próximos passos'];

export const visitaActions = (
  action,
  submitValues,
  { realizarAtividade, encerrarFichaVisita }
) => {
  const atividade = omit(submitValues, [
    'opcaoDataProximaAtividade',
    'encerrarFichaVisita',
  ]);

  if (action === 'REALIZAR_ATIVIDADE_VISITA') {
    eventPerformVisitActivity();
    atividade.dataProximaAtividade = getLocalDateTimeString(
      moment(submitValues.dataProximaAtividade)
    );

    return realizarAtividade({ variables: { atividade } });
  }

  if (action === 'ENCERRAR_FICHA_VISITA') {
    eventCloseBusinessCard();
    const fichaVisita = omit(atividade, 'dataProximaAtividade');

    return encerrarFichaVisita({ variables: { fichaVisita } });
  }

  return null;
};

export const realizarAtividadeVisitaPostAction = (
  postActionPromiseInput,
  data,
  history,
  urlRetorno,
  roteirizacaoClienteId
) => {
  if (urlRetorno) {
    const url = decodeURIComponent(urlRetorno);

    let state = { tab: 0 };

    if (url === 'checkIn') {
      state = { tab: 1 };
    }

    if (url === 'rotas') {
      state = { roteirizacaoClienteId };
    }

    return () => history.push(`/${url}`, state);
  }

  return postActionPromiseInput;
};
