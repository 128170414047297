import React from 'react';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../utils/uiUtils';
import { ContactLeadPanel } from './ContactLeadPanel';

export const RealizarAtividadeLeadFirstPage = props => {
  const { history } = props;

  return (
    <div>
      <RenderAppLogo />
      {updateAppTitle('Conseguiu Contato?')}
      <ShowBackButton history={history} />
      <ContactLeadPanel {...props} />
    </div>
  );
};
