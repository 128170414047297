import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import keycloak from './keycloak';
import { alertReducer } from './alert';
import { homeReducer } from './home';
import { createOpportunityModalReducer } from './modal';
import { loadingSpinner } from './loadingSpinner';
import { offlineMutationsEntityIdReducer } from './offlineMutationsEntityId';
import { offlineBadgeReducer } from './offlineBadgeReducer';
import { weekDaysReducer } from './weekDaysReducer';
import { clienteDuplicadoDialogReducer } from './clienteDuplicadoDialogReducer';
import { registroAcessoReducer } from './registroAcessoReducer';
import { routesReducer } from './routesReducer';
import { checkInReducer } from './checkInReducer';

const appState = combineReducers({
  keycloak,
  form: formReducer,
  alert: alertReducer,
  createOpportunityModal: createOpportunityModalReducer,
  clienteDuplicadoDialog: clienteDuplicadoDialogReducer,
  loadingSpinner,
  home: homeReducer,
  offlineMutationsEntityId: offlineMutationsEntityIdReducer,
  offlineBadge: offlineBadgeReducer,
  weekDays: weekDaysReducer,
  checkIn: checkInReducer,
  suggestedRoutes: routesReducer,
  registroAcesso: registroAcessoReducer,
});

export default appState;
