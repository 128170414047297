import { graphql } from '@apollo/react-hoc';
import { compose, withProps, withState } from 'recompose';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import { displayLoadingState } from '../utils/displayLoadingState';
import GetAtividadeTimelineQueryGql from './GetAtividadeTimelineQueryGql.graphql';
import GetTimelineByTipoOrigemGql from './GetTimelineByTipoOrigemGql.graphql';
import { AtividadeTimeline } from './AtividadeTimeline';

const withAtividadeTimelineQuery = graphql(GetAtividadeTimelineQueryGql, {
  name: 'atividadeTimelineQuery',
  skip: ({ match }) =>
    isEmptyOrNull(match.params.id) && isEmptyOrNull(match.params.clienteId),
  options: ({ match }) => ({
    skip:
      isEmptyOrNull(match.params.id) && isEmptyOrNull(match.params.clienteId),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: match.params.id,
      clienteId: match.params.clienteId,
    },
  }),
});

const withTimelineByTipoOrigemQuery = graphql(GetTimelineByTipoOrigemGql, {
  name: 'timelineByTipoOrigem',
  skip: ({ match }) => isEmptyOrNull(match.params.idOrigemAtividade),
  options: ({ match }) => ({
    fetchPolicy: 'network-only',
    skip: isEmptyOrNull(match.params.idOrigemAtividade),
    variables: {
      notifyOnNetworkStatusChange: true,
      idOrigemAtividade: match.params.idOrigemAtividade,
      tipoOrigemAtividade: match.params.tipoOrigemAtividade,
    },
  }),
});

// provavelmente essa regra vai precisar mudar para atender autos.
// BMW trabalha com o nome 'LEAD' mesmo
export const tipoFormatado = item =>
  item.tipo !== 'LEAD' ? item.tipo : 'PROSPECCAO';

const initialStateTimelineOpenSize = ({ timeline, id, itens }) => {
  if (isEmptyOrNull(id) || itens.length === 0) {
    return timeline.length;
  }
  const startSize = 1;

  return timeline.length <= startSize ? timeline.length : startSize;
};

const enhance = compose(
  withAtividadeTimelineQuery,
  withTimelineByTipoOrigemQuery,
  displayLoadingState({
    name: 'atividadeTimelineQuery',
    onCacheLoadOnlyQueryName: 'atividade',
  }),
  displayLoadingState({
    name: 'timelineByTipoOrigem',
    onCacheLoadOnlyQueryName: 'getTimelineByIdOrigem',
  }),
  withProps(props => {
    const {
      timelineByTipoOrigem: { getTimelineByIdOrigem = null } = {},
      atividadeTimelineQuery = {},
    } = props;

    let { atividade } = atividadeTimelineQuery;

    atividade = atividade == null ? {} : atividade;

    const {
      id = null,
      idOrigem = null,
      cliente: { clienteHistorico: { itens = [] } = {} } = {},
      timeline: timeLineFromAtividade = null,
    } = atividade;

    return {
      title: isEmptyOrNull(id) ? 'Linha do Tempo' : 'Histórico do Cliente',
      exibeFloat: !!id,
      timeline: getTimelineByIdOrigem || timeLineFromAtividade || [],
      timelineFullSize: (getTimelineByIdOrigem || timeLineFromAtividade || [])
        .length,
      itens,
      idOrigem,
      id,
    };
  }),
  withState(
    'timelineOpenSize',
    'setTimelineOpenSize',
    initialStateTimelineOpenSize
  )
);

export const AtividadeTimelineContainer = enhance(AtividadeTimeline);
