import React from 'react';
import { Drawer, Grid, Paper } from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';
import { mdiMapMarker } from '@mdi/js';
import Icon from '@mdi/react';
import { makeStyles } from '@material-ui/styles';
import localforage from 'localforage';
import { defaultGrey, globalStyle } from '../app/globalStyle';
import { getLocation, printLocation } from '../../core/geolocationUtils';

export const geoLocationStyles = makeStyles({
  styleDiv: {
    zIndex: 9999,
    margin: '0',
    padding: '20px',
  },

  bottom: {
    direction: 'row',
    alignItems: 'space-around',
  },

  imageStyle: {
    width: 'auto',
    height: 48,
    fill: `${globalStyle.defaultGrey}`,
  },
});

const style = { marginTop: 15 };
const style1 = { marginRight: '20px' };
const style2 = {
  width: 'auto',
  height: 48,
};

export const GeolocalizacaoPermissionContainer = ({
  rendered,
  setRendered,
}) => {
  const classes = geoLocationStyles();

  const allowGeolocation = () => {
    localforage.setItem('geolocationAllowed', true);
    getLocation(printLocation);
    setRendered(false);
  };

  const closeDrawer = () => {
    setRendered(false);
  };

  const onClick = () => {
    localforage.setItem('geolocationAllowed', false);
    closeDrawer();
  };

  return (
    <Drawer anchor="bottom" open={rendered} onClose={() => setRendered(false)}>
      <Paper className={classes.styleDiv} elevation={20}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Icon path={mdiMapMarker} color={defaultGrey} style={style2} />
          </Grid>
          <Grid item xs={9}>
            Você autoriza utilizarmos sua localização?
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          style={style}
        >
          <Button
            id="geolocation-button"
            color="primary"
            onClick={onClick}
            style={style1}
          >
            Não, obrigado
          </Button>
          <Button customVariant="success" onClick={() => allowGeolocation()}>
            Permitir
          </Button>
        </Grid>
      </Paper>
    </Drawer>
  );
};
