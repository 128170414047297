import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@tecsinapse/ui-kit/build/components/Buttons/IconButton';
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiRefresh } from '@mdi/js';
import { EmptyState } from '@tecsinapse/ui-kit/build/components/EmptyState/EmptyState';

import { getFirstElementOfArray } from '@tecsinapse/es-utils/build/object';
import { StyledSnackbarContent } from '../../utils/dialogs/StyledSnackbarContent';
import { RenderAppLogo, updateAppTitle } from '../../utils/uiUtils';
import { DiaRota } from './DiaRota';
import { listStyles, RotasIcon } from './utils/rotasStylesUtils';
import { BottomSheet } from '../../Client/client/ui/BottomSheet';
import { EntrarEmContatoDialog } from '../../utils/EntrarEmContatoDialog';
import { RouteDialog } from '../../utils/RouteDialog';
import { ReRoteirizacaoDialog } from './ReRoteirizacaoDialog';
import { FloatingButton } from '../../utils/FloatingButton';
import { eventCancelReroute, eventReroute } from '../../utils/GAUtils';
import { GeolocalizacaoPermissionContainer } from '../../Geolocation/GeolocationManager';
import { geolocationAllowed } from '../../../core/geolocationUtils';
import { SubmitButton } from '../../utils/FormUtils';
import { defaultOrange } from '../../app/globalStyle';
import { NotAllowed } from '../../utils/NotAllowed';

export const RotasComponent = ({ ...props }) => {
  const {
    snackBarOpen,
    setSnackBarOpen,
    selectedClient,
    showCallClientModal,
    setShowCallClientModal,
    showRouteModal,
    setShowRouteModal,
    ultimaRoteirizacao,
    showCreateRoteirizacaoModal,
    usuarioLogado,
    selectedRoute,
  } = props;
  const classes = listStyles();
  let roteirizacaoRotas = [];
  let urlMapa = null;

  if (ultimaRoteirizacao) {
    ({ roteirizacaoRotas, urlMapa } = ultimaRoteirizacao);
  }

  let roteirizacaoRotasFiltrada = roteirizacaoRotas;
  let rota = null;

  if (
    usuarioLogado &&
    usuarioLogado.roteirizacaoV2 &&
    roteirizacaoRotas &&
    roteirizacaoRotas.length > 0
  ) {
    rota = selectedRoute || roteirizacaoRotas[0];
    roteirizacaoRotasFiltrada = roteirizacaoRotasFiltrada.filter(
      r => r.id === rota.id
    );

    if (roteirizacaoRotasFiltrada.length === 0) {
      roteirizacaoRotasFiltrada = roteirizacaoRotas;
      rota = getFirstElementOfArray(roteirizacaoRotasFiltrada);
    }
  }

  const [rotaFiltrada, setRotaFiltrada] = useState(roteirizacaoRotasFiltrada);

  const temRoterizacao = rotaFiltrada && rotaFiltrada.length > 0;

  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    eventCancelReroute();
    setOpen(false);
  }, [setOpen]);

  const handleSnackBar = useCallback(() => setSnackBarOpen(false), [
    setSnackBarOpen,
  ]);

  const handleShowCallClientModal = useCallback(
    () => setShowCallClientModal(false),
    [setShowCallClientModal]
  );

  const handleShowRouteModal = useCallback(() => setShowRouteModal(false), [
    setShowRouteModal,
  ]);

  const handleRoteirizacao = useCallback(() => {
    if (usuarioLogado && usuarioLogado.roteirizacaoV2) {
      showCreateRoteirizacaoModal({
        urlMapa,
      });
    } else {
      geolocationAllowed(openReRoteirizacaoDialog);
    }
  }, [showCreateRoteirizacaoModal, urlMapa, usuarioLogado]);

  const ultimaDataRota = temRoterizacao
    ? rotaFiltrada[rotaFiltrada.length - 1].data
    : null;

  const [rendered, setRendered] = useState(false);

  const openReRoteirizacaoDialog = exibir => {
    eventReroute();
    setRendered(!exibir);
    setOpen(exibir);
  };

  const floatButtonIcon = mdiRefresh;

  const content = temRoterizacao ? (
    <div className={classes.fullList}>
      {rotaFiltrada.map(dia => (
        <DiaRota
          key={dia.id}
          data={moment(dia.data).format('DD/MM/YYYY')}
          mapLink={dia.mapLink}
          urlMapa={urlMapa}
          roteirizacaoClientes={dia.roteirizacaoClientes}
          setRotaFiltrada={setRotaFiltrada}
          usuarioLogado={usuarioLogado}
          roteirizacaoRotas={roteirizacaoRotas}
          {...props}
        />
      ))}
    </div>
  ) : (
    <div className={classes.roteirizar}>
      <EmptyState
        IconComponent={RotasIcon}
        message="Não há nenhuma rota por aqui ainda."
      />
      <SubmitButton
        backgroundColor={defaultOrange}
        fullWidth
        label="GERAR ROTEIRIZAÇÃO"
        onClick={() => handleRoteirizacao()}
      />
    </div>
  );

  return (
    <>
      {usuarioLogado.roteirizacaoV2 ? (
        <>
          <div>
            <RenderAppLogo />
            {updateAppTitle('Sugestão de Rota')}
            <div className={snackBarOpen ? classes.moveDown : classes.moveUp} />
            {content}
            {temRoterizacao && (
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                className={classes.snackbar}
                open={snackBarOpen}
                onClose={handleSnackBar}
                autoHideDuration={15000}
                message={
                  <div className={classes.snackbarFragment}>
                    <ErrorIcon className={classes.marginRight10} />
                    <span id="message-id">
                      Atenção esses dados não refletem sua agenda
                    </span>
                  </div>
                }
                ContentProps={{
                  'aria-describedby': 'snackbar-fab-message-id',
                  className: classes.snackbarContent,
                }}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Fechar"
                    color="inherit"
                    className={classes.close}
                    onClick={handleSnackBar}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              >
                <StyledSnackbarContent
                  onClose={handleSnackBar}
                  variant="warning"
                  message="Esses dados não refletem sua agenda"
                />
              </Snackbar>
            )}
            <BottomSheet {...props} selectedClient={selectedClient} />
            <EntrarEmContatoDialog
              client={selectedClient}
              open={showCallClientModal}
              handleClose={handleShowCallClientModal}
            />

            <RouteDialog
              client={selectedClient}
              open={showRouteModal}
              handleClose={handleShowRouteModal}
            />

            {temRoterizacao && (
              <FloatingButton
                icon={<Icon path={floatButtonIcon} size={1.5} color="black" />}
                onClick={handleRoteirizacao}
              />
            )}

            <ReRoteirizacaoDialog
              open={open}
              ultimaDataRota={moment(ultimaDataRota)}
              handleClose={handleClose}
            />

            <GeolocalizacaoPermissionContainer
              rendered={rendered}
              setRendered={setRendered}
            />
          </div>
        </>
      ) : (
        <NotAllowed />
      )}
    </>
  );
};
