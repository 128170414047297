import React from 'react';
import {Typography} from "@material-ui/core";

export const Content = ({activity}) => {
  const {
    descricao,
    cliente
  } = activity;

  const cidade = cliente?.endereco?.cidade;
  const estadoSigla = cliente?.endereco?.estado?.sigla;

  return (
    <div>
      {(cidade || estadoSigla) && (
          <Typography variant={"caption"} display={"block"}>
            {cidade || ''}
            {(cidade && estadoSigla) ? ' - ' : ''}
            {estadoSigla || ''}
          </Typography>
      )}
      {descricao && (
          <Typography variant={"caption"}>
            {descricao}
          </Typography>
      )}
    </div>
  );
};

