import localforage from 'localforage';
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import {
  eventSettingsCancelCarrierPrefix,
  eventSettingsSaveCarrierPrefix,
} from '../utils/GAUtils';

// FIXME - useEffect
// / Warning: Can't perform a React state update on an unmounted component.
// This is a no-op, but it indicates a memory leak in your application.
// To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
export const withSettingsState = Component =>
  compose(
    withState('settings', 'setSettingsValue', null),
    withProps(({ settings }) => ({
      loading: settings === null,
    })),
    lifecycle({
      componentWillMount() {
        localforage
          .getItem('settings')
          .then(settings => this.props.setSettingsValue(settings || {}));
      },
    })
  )(Component);

export const withSettingsStateDialog = Component =>
  compose(
    withSettingsState,
    withState('dialog', 'setDialog', null),
    withHandlers({
      onSubmit: ({ settings, setSettingsValue }) => o => {
        eventSettingsSaveCarrierPrefix();
        const newSettings = settings;

        Object.assign(newSettings, o);
        localforage.setItem('settings', newSettings);
        setSettingsValue(settings);
      },
      openDialog: ({ setDialog }) => d => setDialog(d),
      closeDialog: ({ setDialog }) => () => {
        eventSettingsCancelCarrierPrefix();
        setDialog(null);
      },
    })
  )(Component);
