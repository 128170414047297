import { withRouter } from 'react-router';
import { compose, withHandlers, withProps } from 'recompose';
import { ClienteComponent } from './ClienteComponent';
import { eventClickClientTabs } from '../../../utils/GAUtils';
import { GetCentralClienteContatosAtivoQueryGql } from '../data/queries/centralClienteContatosAtivo/centralClienteContatosAtivo';
import { GetCentralClienteFrotaAtivoQueryGql } from '../data/queries/centralClienteFrotaAtivo/centralClienteFrotaAtivo';

export const ClienteContainer = compose(
  withRouter,
  GetCentralClienteContatosAtivoQueryGql,
  GetCentralClienteFrotaAtivoQueryGql,
  withHandlers({
    setSelectedTab: ({ history }) => tab => {
      history.replace(history.location.pathname, { tab });
      const selectedTab = history.location.state
        ? history.location.state.tab
        : 0;

      eventClickClientTabs(selectedTab);
    },
  }),
  withProps(({ match, location }) => ({
    idClient: match.params.id,
    urlRetorno: match.params.urlRetorno,
    selectedTab: location.state ? location.state.tab : 0,
    chatData: match.params.data,
  }))
)(ClienteComponent);
