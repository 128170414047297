import { withStyles } from '@material-ui/styles';

export const GlobalCss = withStyles(theme => ({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    form: {
      margin: theme.spacing(2),
    },
  },
}))(() => null);
