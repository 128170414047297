import { defaultGrey } from '../../app/globalStyle';

export const chipStyles = {
  marginTop: '-1px',
  fontSize: '10px',
  height: 'auto',
  padding: '2px 4px',
  color: '#FFF',
  backgroundColor: defaultGrey,
  marginLeft: '6px',
};
