import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';

export const CheckInDialog = ({
  isOpenDialog,
  handleClose,
  content,
  contentDialogStyle,
}) => (
  <>
    <Dialog fullScreen open={isOpenDialog} onClose={handleClose}>
      <DialogContent style={contentDialogStyle}>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} customVariant="success" autoFocus>
          Sair
        </Button>
      </DialogActions>
    </Dialog>
  </>
);
