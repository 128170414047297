import { IconButton } from '@tecsinapse/ui-kit';
import Icon from '@mdi/react';
import { mdiFormatLetterCaseUpper } from '@mdi/js';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { defaultGrey } from '../../app/globalStyle';
import { eventButtonUpperCase } from '../../utils/GAUtils';

const stylesIconButtonMaiusculas = {
  iconPaddingZero: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

const useStylesIconButtonMaiusculas = makeStyles(stylesIconButtonMaiusculas);

export const IconButtonMaiusculas = ({
  setTextAction,
  observacao,
  fieldKey = 'observacao',
}) => {
  const classes = useStylesIconButtonMaiusculas();
  const [letterCase, setLetterCase] = useState(false);
  const onClick = () => {
    eventButtonUpperCase();
    setTextAction(letterCase ? 'lowercase' : 'uppercase', observacao, fieldKey);
    setLetterCase(!letterCase);
  };

  return (
    <IconButton
      classes={{
        root: classes.iconPaddingZero,
      }}
      onClick={onClick}
    >
      <Icon path={mdiFormatLetterCaseUpper} size={1} color={defaultGrey} />
    </IconButton>
  );
};
