import React from 'react';
import { UsuarioLogadoContext } from './UsuarioLogadoContext';

export default function UsuarioLogadoConsumer({ children, ...rest }) {
  return (
    <UsuarioLogadoContext.Consumer>
      {({
        usuarioLogado,
        usuarioGerente,
        usuarioAdmin,
        exibeIndicadores,
        usuarioInterno,
        usuarioConsultorExterno,
        exibeRoteirizacao,
        isAppDisabled,
      }) =>
        React.Children.map(children, child =>
          React.cloneElement(child, {
            usuarioLogado,
            usuarioGerente,
            exibeIndicadores,
            usuarioInterno,
            usuarioAdmin,
            usuarioConsultorExterno,
            exibeRoteirizacao,
            isAppDisabled,
            ...rest,
          })
        )
      }
    </UsuarioLogadoContext.Consumer>
  );
}
