import React from 'react';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../utils/uiUtils';

const MapaComponent = ({ history }) => {
  const urlMapa = history && history.location.state.urlMapa;
  const style = { border: 0 };

  return (
    <>
      <RenderAppLogo />
      {updateAppTitle('Sugestão de Rota')}
      <ShowBackButton
        action={() => {
          history.goBack();
        }}
        history={history}
      />
      <iframe
        id={Math.random()}
        title="rotas"
        src={urlMapa}
        height="100%"
        style={style}
      />
    </>
  );
};

export { MapaComponent };
export default MapaComponent;
