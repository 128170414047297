import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';

export const OpportunityCreateClientInfoQueryGql = gql`
  query OpportunityCreateClientInfoQuery($id: ID!, $nullId: Boolean!) {
    cliente(id: $id) @skip(if: $nullId) {
      id
      unidadesNegocioAtendidos {
        id
        descricao
      }
    }
    tiposContato {
      id
      nome
    }
  }
`;
export const OpportunityCreateClientInfoQuery = graphql(
  OpportunityCreateClientInfoQueryGql,
  {
    name: 'clienteGruposAtendidos',
    options: ({ idCliente }) => ({
      variables: {
        id: idCliente || 0,
        nullId: isEmptyOrNull(idCliente),
      },
    }),
  }
);
