import React from 'react';
import { Field } from 'redux-form';
import moment from 'moment';
import { Button } from '@tecsinapse/ui-kit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { RenderDateTimePicker, SubmitButton } from '../utils/FormUtils';
import { useGlobalStyle } from '../app/globalStyle';

export const RescheduleActionDialog = ({
  handleSubmit,
  history,
  open,
  handleClose,
  formName = null,
  resetForm,
  ...rest
}) => {
  const classes = useGlobalStyle();
  const handleCloseWithReset = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCloseWithReset}>
      <form onSubmit={handleSubmit} className={classes.form} name={formName}>
        <DialogTitle id="simple-dialog-title">Reagendamento</DialogTitle>
        <DialogContent>
          <Field
            name="dataHoraReagendamento"
            label="Data de Reagendamento"
            minDate={moment()}
            component={RenderDateTimePicker}
            className={classes.marginBottom20}
            disableToolbar
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCloseWithReset} color="primary">
            Cancelar
          </Button>
          <SubmitButton
            label="Salvar "
            autoFocus
            marginTop10={false}
            {...rest}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
