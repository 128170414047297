import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, getContext, withHandlers, withProps } from 'recompose';
import { destroy } from 'redux-form';
import { flatMap } from '@tecsinapse/es-utils/build';
import { hideClienteDuplicadoDialog } from '../../../reducers/clienteDuplicadoDialogReducer';
import { ClienteDuplicadoDialog } from './ClienteDuplicadoDialog';
import { ClientesDuplicadosQueryHOC } from './getClientesDuplicadosQuery';
import { getLoadingSpinnerContext } from '../../utils/loadingSpinnerContext';
import {
  EditClientMutation,
  editClientMutationOptions,
} from '../client/data/mutations/EditClientMutation';
import { getAlertContext } from '../../utils/alertContext';
import {
  mapDispatchToProps,
  postActionEditClient,
} from '../client/ui/ClienteEditorContainer';
import { withStoreConsumer } from '../../utils/StoreConsumer';
import { withUsuarioLogado } from '../../UsuarioLogado/withUsuarioLogado';
import { mutationsErrorsHandler } from '../../utils/mutationsErrorsHandler';

export const CLIENTE_DUPLICADO_DIALOG_NAME = 'OpportunityCreateForm';

const enhance = compose(
  withRouter,
  withStoreConsumer,
  getContext({
    createModal: PropTypes.func,
  }),
  withHandlers({
    closeAlert: ({ store }) => () => {
      store.dispatch(hideClienteDuplicadoDialog());
      store.dispatch(destroy(CLIENTE_DUPLICADO_DIALOG_NAME));
    },
  }),
  connect(
    state => ({
      ...state.clienteDuplicadoDialog,
    }),
    mapDispatchToProps
  ),
  withUsuarioLogado,
  ClientesDuplicadosQueryHOC,
  getLoadingSpinnerContext,
  EditClientMutation,
  getAlertContext,
  withProps(
    ({
      clientesDuplicadosQuery: {
        clientesDuplicados: { clientes = [] } = {},
      } = {},
      cliente,
    }) => {
      const podeCriarCliente =
        !clientes.filter(c => cliente.cpfCnpj === c.cpfCnpj).length > 0;

      return { podeCriarCliente };
    }
  ),
  withHandlers({
    usuarioPodeAtenderCliente: ({
      history,
      usuarioLogado: { id },
    }) => cliente =>
      flatMap(
        cgs => cgs.vendedor && cgs.vendedor.id,
        cliente.clienteUnidadesNegocio || []
      ).includes(id),
    getLinkToClientOrNull: ({ usuarioLogado: { id } }) => cliente => {
      const usuarioLogadoEhResponsavelPeloCliente = flatMap(
        cgs => cgs.vendedor && cgs.vendedor.id,
        cliente.clienteUnidadesNegocio || []
      ).includes(id);

      if (usuarioLogadoEhResponsavelPeloCliente) {
        return `/editarCliente/${cliente.id}`;
      }

      return '#';
    },
    getDescricaoNormalizada: () => cgs =>
      cgs.unidadeNegocio.descricao
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase() || '',
    criarCliente: ({
      history,
      editClient,
      createModal,
      closeAlert,
      showAlert,
      cliente,
      client,
      usuarioLogado,
    }) => () =>
      editClient({
        variables: { client: { ...cliente, ignorarDuplicados: true } },
      })
        .then(
          postActionEditClient({
            values: client,
            usuarioLogado,
            history,
            createModal,
            action: closeAlert,
          })
        )
        .catch(error => {
          const description = editClientMutationOptions.description({
            variables: { client: cliente },
            apolloClient: client,
          });

          mutationsErrorsHandler({
            showAlert,
            mutationName: editClientMutationOptions.mutationName,
            variables: { client: cliente },
            description,
            chip: false,
          })(error);
        }),
  })
);

export const ClienteDuplicadoDialogContainer = enhance(ClienteDuplicadoDialog);
