import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import clienteDuplicadoQueryGql from './clienteDuplicadoQueryGql.graphql';
import { displayLoadingState } from '../../utils/displayLoadingState';

export const ClientesDuplicadosQuery = graphql(clienteDuplicadoQueryGql, {
  name: 'clientesDuplicadosQuery',
  skip: ({ cliente }) => isEmptyOrNull(cliente),
  options: ({ cliente }) => ({
    variables: {
      client: cliente || 0,
    },
  }),
});

export const ClientesDuplicadosQueryHOC = compose(
  ClientesDuplicadosQuery,
  displayLoadingState({
    name: 'clientesDuplicadosQuery',
    onCacheLoadOnlyQueryName: 'clientesDuplicados',
  })
);
