import { createAction, handleActions } from 'redux-actions';
import { CheckInOrdenacao } from '../components/CheckIn/ui/CheckInList/enum/CheckInOrdenacaoEnum';

const defaultState = {
  checkInSort: CheckInOrdenacao.MAIS_RECENTES.name,
  checkInApenasComImagem: false,
  checkInApenasVisitaRegistrada: false,
  checkInConsultorExterno: null,
  checkInDealer: null,
};

export const setCheckinFiltroBottomSheet = createAction('SET_CHECKIN_FILTRO');

export const checkInReducer = handleActions(
  {
    [setCheckinFiltroBottomSheet]: (
      state,
      {
        payload: {
          checkInSort = CheckInOrdenacao.MAIS_RECENTES.name,
          checkInApenasComImagem = false,
          checkInApenasVisitaRegistrada = false,
          checkInConsultorExterno = null,
          checkInDealer = null,
        },
      }
    ) => ({
      ...state,
      checkInSort,
      checkInApenasComImagem,
      checkInApenasVisitaRegistrada,
      checkInConsultorExterno,
      checkInDealer,
    }),
  },
  defaultState
);
