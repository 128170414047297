import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import motivosVendaPerdidaQueryGql from './motivosVendaPerdidaQueryGql.graphql';
import { displayLoadingState } from '../../../utils/displayLoadingState';

export const motivosVendaPerdidaQuery = graphql(motivosVendaPerdidaQueryGql, {
  name: 'motivosVendaPerdidaQuery',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const MotivosVendaPerdidaQueryHOC = compose(
  motivosVendaPerdidaQuery,
  displayLoadingState({
    name: 'motivosVendaPerdidaQuery',
    onCacheLoadOnlyQueryName: 'motivosVendaPerdida',
  })
);
