import { compose, withHandlers, withProps, withState } from 'recompose';
import { ArrowBack } from '@material-ui/icons';
import { eventClickAdvancedSearch } from '../GAUtils';
import { SearchListing } from '../../Home/Search/SearchListing';

export const withGenericSearchBarState = ({
  textSearchPlaceHolder = '',
  textSearchPlaceHolderExpansion = '',
  itemPlaceHolder = '',
  SearchListener = SearchListing,
  isToUpdateAppTitle = false,
  isShowFloatingButton = false,
  isDialog = false,
  showAdvancedSearch = true,
  DismissDialogIcon = ArrowBack,
  autoFocus = false,
}) => Component =>
  compose(
    withState('atividades', 'setAtividades', []),
    withState('selectedTab', 'setSelectedTab', 0),
    withState('selectedClient', 'setSelectedClient', null),
    withState('textSearch', 'setTextSearch', ''),
    withState('cidade', 'setCidade', ''),
    withState('uf', 'setUf', ''),
    withState('showCallClientModal', 'setShowCallClientModal', false),
    withState('showInformCallModal', 'setShowInformCallModal', false),
    withState('showRouteModal', 'setShowRouteModal', false),
    withState('expansionPanelOpen', 'setStateExpansionPanelOpen', false),
    withHandlers({
      setExpansionPanelOpen: ({ setStateExpansionPanelOpen }) => newState => {
        setStateExpansionPanelOpen(newState);
        eventClickAdvancedSearch();
      },
    }),
    withProps(() => ({
      textSearchPlaceHolder,
      textSearchPlaceHolderExpansion,
      itemPlaceHolder,
      SearchListener,
      isToUpdateAppTitle,
      isShowFloatingButton,
      isDialog,
      showAdvancedSearch,
      DismissDialogIcon,
      autoFocus,
    }))
  )(Component);
