import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';
import AtividadeFragment from '../../../Atividade/AtividadeFragment.graphql';

export const informarResultadoProspeccaoMutationGql = gql`
  mutation informarResultadoProspeccaoMutation(
    $resultado: InformarResultadoProspeccao!
  ) {
    informarResultadoProspeccao(resultado: $resultado) {
      ...AtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

export const criarEInformarResultadoProspeccaoMutationGql = gql`
  mutation informarResultadoProspeccaoMutation(
    $resultado: InformarResultadoProspeccao!
  ) {
    criarEInformarResultadoProspeccao(resultado: $resultado) {
      ...AtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

const genericInformarResultadoProspeccaoMutation = ({
  mutationGql,
  name,
  mutationName,
}) =>
  graphqlOfflineMutation(mutationGql, {
    name,
    mutationName,
    idName: 'idAtividade',
    entityName: 'resultado',
    savedEntityName: 'atividade',
    description: async ({
      variables: {
        resultado: { idAtividade },
      },
      apolloClient,
    }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `Atividade:${idAtividade}`,
          fragment: gql`
            fragment RealizacaoProspeccaoOffline on Atividade {
              id
              cliente {
                id
                nome
              }
            }
          `,
        });
        const {
          cliente: { nome },
        } = data;

        return `Informe de contato em prospecção de cliente com nome: ${nome}.`;
      } catch (e) {
        return `Informe de contato em prospecção.`;
      }
    },
  });

export const informarResultadoProspeccaoMutation = genericInformarResultadoProspeccaoMutation(
  {
    mutationGql: informarResultadoProspeccaoMutationGql,
    name: 'informarResultadoProspeccao',
    mutationName: 'informarResultadoProspeccaoMutationGql',
  }
);

export const criarEInformarResultadoProspeccaoMutation = genericInformarResultadoProspeccaoMutation(
  {
    mutationGql: criarEInformarResultadoProspeccaoMutationGql,
    name: 'criarEInformarResultadoProspeccao',
    mutationName: 'criarEInformarResultadoProspeccaoMutationGql',
  }
);
