import {
  ClientCreateOptionEnum,
  TipoAcaoInformarContato,
} from '../../../core/Enums';
import { allowActionsModeloAtivo } from '../../Atividade/atividadeFunctions';

const redirectToRealizarAtividadeModeloInativo = ({
  idAtividade,
  urlRetorno,
}) => {
  if (urlRetorno) {
    return `/realizarAtividade/${idAtividade}/${urlRetorno}`;
  }

  return `/realizarAtividade/${idAtividade}`;
};

const redirectToRealizarAtividade = ({
  idAtividade,
  tipoContato,
  contatoId = null,
  history,
  idCliente,
  idUnidadeNegocio,
  tipoAcaoInformarContato,
  urlRetorno,
}) => {
  const parametrosDeContato = tipoContato ? `/${contatoId}/${tipoContato}` : '';

  if (idAtividade) {
    if (urlRetorno) {
      history.push(
        `/realizarAtividade/${idAtividade}${parametrosDeContato}/${urlRetorno}`
      );
    } else {
      history.push(`/realizarAtividade/${idAtividade}${parametrosDeContato}`);
    }
  } else {
    history.push(
      `/criarERealizarAtividade/${tipoAcaoInformarContato}/${idCliente}/${idUnidadeNegocio}${parametrosDeContato}`
    );
  }
};

export const postInformCallActions = ({
  history,
  createModal,
  closeAlert = null,
  setShowInformCallModal,
  setAtividades,
  contatoId,
  tipoContato,
  atualizaModeloInativoEAbreDialog,
  urlRetorno,
}) => result => {
  history.location.pathname = urlRetorno; // eslint-disable-line

  const {
    data: {
      informarContatoCliente: {
        tipoAcaoInformarContato,
        atividades,
        idCliente,
        unidadeNegocio: { id: idUnidadeNegocio },
      },
    },
  } = result;

  if (
    tipoAcaoInformarContato === TipoAcaoInformarContato.REALIZAR_ATIVIDADE.name
  ) {
    if (atividades && atividades.length === 1) {
      if (
        !allowActionsModeloAtivo(atividades[0], (veiculos, idOportunidade) =>
          atualizaModeloInativoEAbreDialog({
            veiculos,
            idOportunidade,
            push: redirectToRealizarAtividadeModeloInativo({
              idAtividade: atividades[0].id,
              urlRetorno,
            }),
          })
        )
      ) {
        return;
      }

      redirectToRealizarAtividade({
        history,
        idAtividade: atividades[0].id,
        contatoId,
        tipoContato,
        urlRetorno,
      });
    } else {
      setAtividades(atividades);
      setShowInformCallModal(true);
    }
  } else if (
    tipoAcaoInformarContato ===
    TipoAcaoInformarContato.PEDIR_FORMA_DE_CONTATO.name
  ) {
    createModal({
      id: idCliente,
      clientCreateOption: ClientCreateOptionEnum.INFORM_CALL,
      message: null,
      fullWidth: true,
      idUnidadeNegocio,
      contatoId,
      tipoContato,
    });
  } else if (
    tipoAcaoInformarContato ===
      TipoAcaoInformarContato.CRIAR_OPORTUNIDADE.name ||
    tipoAcaoInformarContato === TipoAcaoInformarContato.CRIAR_PROSPECCAO.name
  ) {
    createModal({
      id: idCliente,
      clientCreateOption:
        tipoAcaoInformarContato ===
        TipoAcaoInformarContato.CRIAR_OPORTUNIDADE.name
          ? ClientCreateOptionEnum.OPPORTUNITY
          : ClientCreateOptionEnum.PROSPECTION,
      message: null,
      fullWidth: true,
      idUnidadeNegocio,
      contatoId,
      tipoContato,
    });
  } else if (
    tipoAcaoInformarContato ===
      TipoAcaoInformarContato.CRIAR_ATIVIDADE_OPORTUNIDADE.name ||
    tipoAcaoInformarContato ===
      TipoAcaoInformarContato.CRIAR_ATIVIDADE_PROSPECCAO.name
  ) {
    redirectToRealizarAtividade({
      history,
      tipoAcaoInformarContato,
      idCliente,
      idUnidadeNegocio,
      contatoId,
      tipoContato,
      urlRetorno,
    });
  } else if (
    tipoAcaoInformarContato ===
    TipoAcaoInformarContato.PEDIR_TIPO_CRIAR_ATIVIDADE.name
  ) {
    createModal({
      clientCreateOption: ClientCreateOptionEnum.PEDIR_TIPO_CRIAR_ATIVIDADE,
      idCliente,
      idUnidadeNegocio,
      contatoId,
      tipoContato,
    });
  } else if (
    tipoAcaoInformarContato ===
    TipoAcaoInformarContato.ESCOLHER_TIPO_CRIACAO.name
  ) {
    createModal({
      id: idCliente,
      clientCreateOption: ClientCreateOptionEnum.CHOICE,
      message: null,
      fullWidth: true,
      idUnidadeNegocio,
      contatoId,
      tipoContato,
    });
  }

  closeAlert && closeAlert();
};
