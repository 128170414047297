import {
  Card,
  CardContent,
  Chip,
  MenuItem,
  Typography,
} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgressbar from 'react-circular-progressbar';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { indicadoresStyles } from '../indicadoresStyles';
import { getColorOfTemperatura } from '../../../../utils/cardStyleUtils';
import { getLocalDateTimeString } from '../../../../utils/dateUtils';
import { printPorcentagemMaxima } from '../IndicadoresCardFunctions';
import { stylesOfCirularProgressbar } from '../indicadoresStyleUtils';
import { TemperaturaNegociacao } from '../../../../../core/Enums';
import {
  eventIndicatorDelayedActivitiesFilterByTemperature,
  eventIndicatorDelayedActivitiesList,
} from '../../../../utils/GAUtils';

const atividadesAtrasadaStyles = makeStyles(indicadoresStyles);

// TODO: Well - extrair styles
const style = {
  color: 'transparent',
  fontWeight: 600,
};
const style1 = {
  position: 'absolute',
  width: '100%',
  left: 0,
  minWidth: '150px',
};

const style3 = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};
const style5 = {
  padding: 0,
  marginBottom: 0,
};
const style6 = {
  width: '85px',
  display: 'flex',
  flexDirection: 'column',
};
const style7 = {
  marginLeft: '20px',
};
const style8 = {
  position: 'relative',
  minWidth: '150px',
};

export const IndicadorAtividadesAtrasadasComponent = ({
  quantidade,
  temperaturaAtual,
  setTemperaturaAtual,
  onClick,
  temperaturas = [],
}) => {
  const classes = atividadesAtrasadaStyles();
  const mainColor = getColorOfTemperatura(
    (temperaturaAtual && temperaturaAtual.id) || null
  );

  const style2 = {
    backgroundColor: mainColor,
    color: 'white',
    padding: '2px',
  };

  const onClick1 = () => {
    eventIndicatorDelayedActivitiesList();
    onClick({
      dataInicio: null,
      dataFim: getLocalDateTimeString(moment().startOf('day')),
      finalizado: false,
      temperatura: temperaturaAtual && temperaturaAtual.id,
      semTemperatura: temperaturaAtual && temperaturaAtual.semTemperatura,
    });
  };

  const onChange = e => {
    eventIndicatorDelayedActivitiesFilterByTemperature();
    setTemperaturaAtual(e.target.value);
  };
  const selectProps = {
    style,
    disableUnderline: true,
  };
  const value = {
    id: null,
    nome: 'Sem Temperatura',
    semTemperatura: true,
  };

  return (
    <Card className={classes.card} style={style5}>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.paddingCard}
        title="Atividades Atrasadas"
      />

      <CardContent className={classes.cardContent}>
        <div style={style3}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div style={style6} onClick={onClick1}>
            <CircularProgressbar
              background
              percentage={100}
              text={`${printPorcentagemMaxima(quantidade)}`}
              strokeWidth={10}
              styles={stylesOfCirularProgressbar(mainColor)}
            />
          </div>

          <div style={style7}>
            <div style={style8}>
              <Typography variant="subtitle2">Temperatura</Typography>
              <Chip
                label={temperaturaAtual && temperaturaAtual.nome}
                classes={{
                  root: classes.chipMargin,
                }}
                style={style2}
              />
              <TextField
                select
                value={temperaturaAtual}
                onChange={onChange}
                SelectProps={selectProps}
                style={style1}
              >
                {temperaturas.find(
                  c => c.id === TemperaturaNegociacao.NAO_INFORMADA.name
                ) && (
                  <MenuItem value={value} key="sem_temperatura">
                    Sem temperatura
                  </MenuItem>
                )}
                {(temperaturas || []).map(temperatura => (
                  <MenuItem
                    value={temperatura}
                    key={temperatura && temperatura.id}
                  >
                    {temperatura && temperatura.nome}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
