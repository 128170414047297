import React from 'react';
import { CheckInList } from '../CheckInList';
import { CheckInOrdenacao } from '../CheckInList/enum/CheckInOrdenacaoEnum';
import { useConsultoresExternos } from '../CheckInList/data/useConsultoresExternos';

export const CheckInListCE = ({
  checkInSort = CheckInOrdenacao.MAIS_RECENTES.name,
  checkInApenasComImagem = false,
  checkInApenasVisitaRegistrada = false,
  checkInConsultorExterno = null,
  checkInDealer = null,
  history = null,
  setCheckInFiltro = null,
  criarFichaVisitaAtividade = null,
  isCheckInMobile = false,
  useWindowInfiniteScroll = false,
}) => {
  const { consultoresExternos } = useConsultoresExternos();

  const handleRealizarAtividade = clienteVo => {
    const { id: idCliente, ultimaAtividadeFichaVisitaAbertaPk } = clienteVo;

    if (ultimaAtividadeFichaVisitaAbertaPk) {
      return history.push(
        `/realizarAtividade/${ultimaAtividadeFichaVisitaAbertaPk}/checkIn`
      );
    }

    const variables = { idCliente };

    return criarFichaVisitaAtividade({
      variables,
    }).then(
      ({
        data: {
          fichaVisitaAgendarAtividade: { id },
        },
      }) => {
        history.push(`/realizarAtividade/${id}/checkIn`);
      }
    );
  };

  return (
    <CheckInList
      checkInSort={checkInSort}
      checkInApenasComImagem={checkInApenasComImagem}
      checkInApenasVisitaRegistrada={checkInApenasVisitaRegistrada}
      checkInConsultorExterno={checkInConsultorExterno}
      checkInDealer={checkInDealer}
      history={history}
      setCheckInFiltro={setCheckInFiltro}
      criarFichaVisitaAtividade={criarFichaVisitaAtividade}
      isCheckInMobile={isCheckInMobile}
      useWindowInfiniteScroll={useWindowInfiniteScroll}
      modulo="CONSULTOR_EXTERNO"
      vendedores={consultoresExternos}
      labelFiltroAtividadeRealizada="Apenas com visita registrada"
      labelFiltroVendedores="Consultor Externo"
      labelAtividadeRealizada="Visita Realizada"
      labelButtonRealizarAtividade="Registrar Visita"
      handleRealizarAtividade={handleRealizarAtividade}
    />
  );
};
