import { FormControl } from '@material-ui/core';
import React from 'react';

export const createRender = (render, { key = undefined } = {}) => props => {
  const {
    fullWidth,
    meta: { touched, error },
  } = props;

  return (
    <FormControl key={key} error={error && touched} fullWidth={fullWidth}>
      {render({ error: touched && error, ...props })}
    </FormControl>
  );
};
