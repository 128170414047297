import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withProps, withState } from 'recompose';
import moment from 'moment';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build/object';
import { Agenda } from './Agenda';
import { getAlertContext } from '../utils/alertContext';
import {
  setAgendaFiltroBottomSheet,
  setSelectedDayTab,
  setShowAtrasadasTab,
} from '../../reducers/weekDaysReducer';
import { withActivityBottomSheet } from './withActivityBottomSheet';
import { withSelecaoModeloInteresse } from '../SelecaoModeloInteresse/withSelecaoModeloInteresse';
import {
  atividadesAtrasadasQuery,
  atrasadasFilter,
  filterByDay,
  weekQuery,
} from './ActivitiesListContainer';
import { withTipoOrigemOportunidade } from '../RealizarAtividade/data/queries/TipoOrigemOportunidadeQuery';
import { StatusOportunidade } from '../../core/Enums';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';

const mapDispatchToProps = dispatch => ({
  setSelectedDay: obj => {
    dispatch(setSelectedDayTab(obj));
  },
  setShowAtrasadas: obj => {
    dispatch(setShowAtrasadasTab(obj));
  },
  setAgendaFiltro: (origensOportunidade, prospeccoesSemContato) => {
    dispatch(
      setAgendaFiltroBottomSheet({ origensOportunidade, prospeccoesSemContato })
    );
  },
});

const mapStateToProps = ({
  home: { agendaKey },
  weekDays: {
    showAtrasadas,
    selectedDay,
    origensOportunidade,
    prospeccoesSemContato,
  },
}) => ({
  agendaKey,
  showAtrasadas,
  selectedDay,
  origensOportunidade,
  prospeccoesSemContato,
});

export const filterLeads = (
  atividades,
  origensOportunidade,
  prospeccoesSemContato
) => {
  let atividadesFiltradas = atividades;

  if (origensOportunidade && origensOportunidade.length > 0) {
    atividadesFiltradas = atividadesFiltradas.filter(
      a =>
        origensOportunidade.includes(a.tipoOrigemOportunidade) ||
        origensOportunidade.includes(a.tipoOrigemOportunidade)
    );
  }

  if (prospeccoesSemContato) {
    atividadesFiltradas = atividadesFiltradas.filter(
      a =>
        isEmptyOrNull(a.resultadoProspeccao) ||
        a.statusOportunidade === StatusOportunidade.NOVO.name
    );
  }

  return atividadesFiltradas;
};

const enhance = compose(
  pure,
  withUsuarioLogado,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  getAlertContext,
  withSelecaoModeloInteresse,
  withTipoOrigemOportunidade,
  withProps(
    ({ history, location: { state: { modal = false } = {} } = {} }) => ({
      showCallClientModal: modal,
      setShowCallClientModal: show => {
        if (show === true && !modal) {
          history.push(history.location.pathname, {
            modal: true,
          });
        }

        if (show === false && modal) {
          history.goBack();
        }
      },
    })
  ),
  withActivityBottomSheet,
  withState('menuOpen', 'setMenuOpen', false),
  withHandlers({
    menuBottomSheetOnClose: ({ setMenuOpen }) => () => {
      setMenuOpen(false);
    },
    showMenuBottomSheet: ({ setMenuOpen }) => () => {
      setMenuOpen(true);
    },
    notRenderIfConsultorExterno: ({ usuarioLogado }) => () =>
      !usuarioLogado?.consultorExterno,
  }),
  weekQuery,
  atividadesAtrasadasQuery,
  withProps(
    ({
      data: { getAtividades = {} },
      atividadesAtrasadas: { getAtividades: getAtividadesAtrasadas = {} },
      selectedDay,
      showAtrasadas,
      origensOportunidade,
      prospeccoesSemContato,
    }) => {
      let { atividades = [] } = getAtividades;
      let { atividades: atividadesAtrasadas = [] } = getAtividadesAtrasadas;

      atividades = filterLeads(
        atividades,
        origensOportunidade,
        prospeccoesSemContato
      );
      atividadesAtrasadas = filterLeads(
        atividadesAtrasadas,
        origensOportunidade,
        prospeccoesSemContato
      );

      const selectedActitvities = showAtrasadas
        ? atividadesAtrasadas
        : atividades.filter(filterByDay(moment(selectedDay), showAtrasadas));

      const atividadesAtrasadasSemana = atividades.filter(atrasadasFilter);

      const ts = [
        ...new Set(
          atividadesAtrasadasSemana.map(a =>
            moment(a.dataHoraExibicao).startOf('day').format()
          )
        ),
      ];

      return {
        activities: selectedActitvities,
        diasComAtrasadas: ts,
        qtdAtrasadas: atividadesAtrasadas.length,
      };
    }
  )
);

export const AgendaContainer = enhance(Agenda);
