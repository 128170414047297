import { compose, withProps } from 'recompose';
import { getFirstElementOfArray } from '@tecsinapse/es-utils';
import {
  getMetasEResultados,
  IndicadoresQueryDataName,
  IndicadoresQueryHoc,
  IndicadoresQueryName,
} from '../../data/withIndicadoresQuery';
import { IndicadoresComponents } from './IndicadoresComponents';

export const IndicadoresComponentsContainer = compose(
  IndicadoresQueryHoc,
  withProps(propsCompose => {
    const {
      [IndicadoresQueryName]: {
        loading = false,
        [IndicadoresQueryDataName]: {
          indicadoresVendaPorDealerUsuario: indicadores = [],
          indicadoresOportunidades: { quantidadeOportunidades = [] } = {},
        } = {},
      },
    } = propsCompose;

    const firstElementOfSuperQuente = getFirstElementOfArray(
      quantidadeOportunidades.filter(qo => qo.temperatura === 'SUPER_QUENTE')
    );

    const [quantidadeSuperQuente, valorTotalSuperQuente, idsSuperQuente] =
      firstElementOfSuperQuente !== null
        ? [
            (firstElementOfSuperQuente.ids || []).length,
            firstElementOfSuperQuente.valorTotal || 0,
            firstElementOfSuperQuente.ids || [],
          ]
        : [0, 0, []];

    const firstElementOfQuente = getFirstElementOfArray(
      quantidadeOportunidades.filter(qo => qo.temperatura === 'QUENTE')
    );

    const [quantidadeQuente, valorTotalQuente, idsQuente] =
      firstElementOfQuente !== null
        ? [
            (firstElementOfQuente.ids || []).length,
            firstElementOfQuente.valorTotal || 0,
            firstElementOfQuente.ids || [],
          ]
        : [0, 0, []];

    const oportunidadeProps = {
      quantidadeSuperQuente,
      valorTotalSuperQuente,
      quantidadeQuente,
      valorTotalQuente,
      idsSuperQuente,
      idsQuente,
      loading,
    };

    const indicadoresProcessados = [];

    indicadores.forEach(indicador => {
      const [
        meta,
        realizado,
        porcentagem,
        diasUteisAteFinalPeriodo,
      ] = getMetasEResultados(indicador);

      indicadoresProcessados[indicador.indicador.indicador] = {
        meta,
        realizado,
        porcentagem,
        diasUteisAteFinalPeriodo,
      };
    });

    return {
      loading,
      ...oportunidadeProps,
      ...indicadoresProcessados,
    };
  })
)(IndicadoresComponents);
