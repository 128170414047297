import {
  defaultBronze,
  defaultGold,
  defaultGrey,
  defaultGreyLight2,
} from '../../../../../../../app/globalStyle';

export const useCorPorColocacao = position => {
  switch (position) {
    case 1:
      return defaultGold;
    case 2:
      return defaultGreyLight2;
    case 3:
      return defaultBronze;
    default:
      return defaultGrey;
  }
};
