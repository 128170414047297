import { formatMoney } from '@tecsinapse/es-utils/build/money';
import { Card, CardContent, Typography } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { defaultGrey } from '../../../../app/globalStyle';
import { indicadoresStyles } from '../indicadoresStyles';
import { OportunidadeCircularProgressbar } from './OportunidadeCircularProgressbar';
import { TemperaturaNegociacao } from '../../../../../core/Enums';

function getOportunidadeCardValues({
  valorTotalQuente,
  valorTotalSuperQuente,
}) {
  const valorTotal = valorTotalQuente + valorTotalSuperQuente || 0;
  const valorTotalFormatado = formatMoney(valorTotal);
  const message = `As oportunidades totalizam:`;

  return { valorTotalFormatado, message };
}

const indicadoresOportunidadesStyles = makeStyles(indicadoresStyles);

const style1 = {
  flexBasis: 1,
  minHeight: '100%',
  backgroundColor: `${defaultGrey}`,
};
const style2 = {
  color: defaultGrey,
};

export const OportunidadesCardComponent = React.memo(
  ({
    quantidadeSuperQuente,
    valorTotalQuente,
    valorTotalSuperQuente,
    quantidadeQuente,
    showDialogAtividades,
    idsSuperQuente,
    idsQuente,
    temperaturas,
  }) => {
    const classes = indicadoresOportunidadesStyles();

    const { valorTotalFormatado, message } = getOportunidadeCardValues({
      valorTotalQuente,
      valorTotalSuperQuente,
    });

    const onClickSuperQuente = useCallback(
      () =>
        showDialogAtividades({
          dataInicio: null,
          dataFim: null,
          finalizado: null,
          temperatura: null,
          ids: idsSuperQuente,
        }),
      [idsSuperQuente, showDialogAtividades]
    );

    const onClickQuente = useCallback(
      () =>
        showDialogAtividades({
          dataInicio: null,
          dataFim: null,
          finalizado: null,
          temperatura: null,
          ids: idsQuente,
        }),
      [idsQuente, showDialogAtividades]
    );

    const exibirSuperQuente = useMemo(
      () =>
        temperaturas &&
        temperaturas.find(
          t => t.id === TemperaturaNegociacao.SUPER_QUENTE.name
        ),
      [temperaturas]
    );

    return (
      <Card className={classes.card}>
        <CardHeader
          classes={{
            title: classes.title,
            action: classes.action,
          }}
          className={classes.paddingCard}
          title="Oportunidades"
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.cardContentFlexOportunidades}>
            {exibirSuperQuente && (
              <>
                <OportunidadeCircularProgressbar
                  quantidade={quantidadeSuperQuente}
                  temperatura="SUPER_QUENTE"
                  temperaturaNome="Super Quente"
                  valor={valorTotalSuperQuente}
                  onClick={onClickSuperQuente}
                />
                <div style={style1} />
              </>
            )}
            <OportunidadeCircularProgressbar
              quantidade={quantidadeQuente}
              temperaturaNome="Quente"
              temperatura="QUENTE"
              valor={valorTotalQuente}
              onClick={onClickQuente}
            />
          </div>
        </CardContent>
        {exibirSuperQuente && (
          <CardActions>
            <Typography
              variant="caption"
              className={classes.indicadoresMessage}
              style={style2}
            >
              {message}
            </Typography>
            <Typography variant="subtitle2">
              <b>{valorTotalFormatado}</b>
            </Typography>
          </CardActions>
        )}
      </Card>
    );
  }
);
