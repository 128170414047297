import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from '@material-ui/core';
import { DateRange, Person, Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { mdiChartAreaspline, mdiHome, mdiMapMarkerPath } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTE_CHECK_IN } from '../../constantes/constantes';
import { defaultGrey, defaultGreyDisabled } from '../app/globalStyle';
import { useIndicadoresMenu } from '../Menu/data/useIndicadoresMenu';
import { isIos } from './BrowserUtils';
import {
  eventClientMenu,
  eventIndicatorsMenu,
  eventRoutesMenu,
  eventScheduleMenu,
  eventSettingsMenu,
} from './GAUtils';

const getCurrentNavigation = location => {
  if (location.pathname === '/agenda') {
    return 0;
  }

  if (location.pathname === '/clientes') {
    return 1;
  }

  if (location.pathname === '/home') {
    return 2;
  }

  if (location.pathname === '/indicadores') {
    return 3;
  }

  if (location.pathname === ROUTE_CHECK_IN) {
    return 4;
  }

  if (location.pathname === '/rotas') {
    return 5;
  }

  if (location.pathname === '/settings') {
    return 6;
  }

  return -1;
};

const style = {
  root: {
    color: `${defaultGreyDisabled} !important`,
    fill: `${defaultGreyDisabled} !important`,
    minWidth: 0,
  },
  selected: {
    color: `${defaultGrey} !important`,
    fill: `${defaultGrey} !important`,
  },
  paperBoderTop: {
    paddingBottom: isIos() ? '20px' : '0',
    borderTop: 'solid 1px rgba(0, 0, 0, .1)',
  },
};

const usetBottomNavStyle = makeStyles(style);

const onClickBottomNav = (event, route, history) => () => {
  event();
  history.push(route);
};

export const BottomBarNavigation = withRouter(
  ({ className, history, location, exibeIndicadores, exibeRotas }) => {
    const currentNavigation = getCurrentNavigation(location);
    const classes = usetBottomNavStyle();
    const { notificacoesCount } = useIndicadoresMenu();

    return (
      <Paper elevation={0} className={clsx(className, classes.paperBoderTop)}>
        <BottomNavigation value={currentNavigation} showLabels>
          <BottomNavigationAction
            id="agenda-bottom-bar-nav"
            label="Agenda"
            icon={<DateRange />}
            onClick={onClickBottomNav(eventScheduleMenu, '/agenda', history)}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
          <BottomNavigationAction
            label="Clientes"
            icon={<Person />}
            onClick={onClickBottomNav(eventClientMenu, '/clientes', history)}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
          <BottomNavigationAction
            label="Início"
            icon={
              <Badge badgeContent={notificacoesCount} color="error">
                <Icon path={mdiHome} size={1} />
              </Badge>
            }
            onClick={onClickBottomNav(eventClientMenu, '/home', history)}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
          {exibeIndicadores && (
            <BottomNavigationAction
              id="indicadores-bottom-bar-nav"
              label="Indicadores"
              icon={<Icon path={mdiChartAreaspline} size={1} />}
              onClick={onClickBottomNav(
                eventIndicatorsMenu,
                '/indicadores',
                history
              )}
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
            />
          )}
          {exibeRotas && (
            <BottomNavigationAction
              id="rotas-bottom-bar-nav"
              label="Rotas"
              icon={<Icon path={mdiMapMarkerPath} size={1} />}
              onClick={onClickBottomNav(eventRoutesMenu, '/rotas', history)}
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
            />
          )}
          <BottomNavigationAction
            label="Ajustes"
            icon={<Settings />}
            onClick={onClickBottomNav(eventSettingsMenu, '/settings', history)}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
        </BottomNavigation>
      </Paper>
    );
  }
);
