import { withStyles } from '@material-ui/core';
import React from 'react';
import { Select } from '@tecsinapse/ui-kit';

import { flatten } from '@tecsinapse/es-utils/build';
import { selectInputStyle } from './SelectInputStyle';
import { createRender } from './FormUtils';

export const SelectComponent = ({
  input,
  children,
  tooltip,
  label,
  options = [],
  endAdornment,
  classes,
  disabled,
  placeholder,
  required,
  error = false,
  menuPlacement = 'bottom',
  ...rest
}) => {
  const { value, onChange, name } = input;

  const flattenChildren = childrenIn =>
    childrenIn
      ? flatten(childrenIn)
          .filter(c => !!c && !!c.props)
          .map(suggestion => ({
            value: suggestion.props.value,
            label: suggestion.props.children,
            disabled: suggestion.props.disabled || false,
          }))
      : [];

  const map =
    options !== undefined && options && options.length !== 0
      ? options
      : flattenChildren(children);

  return (
    <Select
      {...rest}
      value={value || null}
      error={error}
      options={map}
      onChange={input2 => onChange(input2)}
      disabled={disabled}
      label={required ? `${label} *` : label}
      placeholder={placeholder || label || ''}
      name={name}
      minWidth=""
      variant="mobile"
    />
  );
};

export const RenderSelectField = createRender(SelectComponent, {
  renderLabel: false,
});

export const SelectComponentStyled = withStyles(selectInputStyle)(
  SelectComponent
);
