import { omitDeep } from '@tecsinapse/es-utils/build';
import { useLembreteAtividadeHook } from 'components/Agendador/LembreteAtividadeHook';
import localforage from 'localforage';
import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import {
  ActivityActions,
  TipoAcaoInformarContato,
  TipoOrigemAtividade,
} from '../../core/Enums';
import { getLocation, registraLocalizacao } from '../../core/geolocationUtils';
import { ErrorOffline } from '../../core/offline/exception/ErrorOffline';
import { modelosInativos } from '../Atividade/atividadeFunctions';
import { AtualizaModeloInativoDialog } from '../AtualizaModeloInativo/AtualizaModeloInativoDialog';
import { useAtualizarModeloInativoState } from '../AtualizaModeloInativo/useAtualizarModeloInativoState';
import { getLocalDateTimeString } from '../utils/dateUtils';
import { validateRequired } from '../utils/FormUtils';
import {
  eventAdvanceButtonPerformVisitActivity,
  eventLostBusiness,
} from '../utils/GAUtils';
import { WizardForm } from '../utils/WizardForm';
import { prospeccaoActions } from './lead/prospeccaoActions';
import { RealizarAtividadeLeadFirstPage } from './lead/RealizarAtividadeLeadFirstPage';
import {
  atividadePadraoPostAction,
  isOportunidadeIncompleta,
  negociacaoAceitaErro,
  negocioPerdidoPostAction,
  oportunidadeActions,
  realizarAtividadePostAction,
} from './oportunidade/oportunidadeActions';
import { RealizarAtividadeFirstPage } from './RealizarAtividadeFirstPage';
import { RealizarAtividadePosAcao } from './RealizarAtividadePosAcao';
import { RealizarAtividadeSecondPage } from './RealizarAtividadeSecondPage';
import { RealizarAtividadeVisitaFirstPage } from './visita/RealizarAtividadeVisitaFirstPage';
import { RealizarAtividadeVisitaSecondPage } from './visita/RealizarAtividadeVisitaSecondPage';
import {
  realizarAtividadeVisitaPostAction,
  visitaActions,
} from './visita/visitaActions';

export const RealizarAtividadeWizard = props => {
  const {
    activityBranch,
    idOportunidade,
    crmObrigarModeloInteresseAtivo,
    crmObrigarPreenchimentoDadosVeiculoOportunidade,
  } = props;

  const modelosInativosList = modelosInativos(activityBranch);
  const [podeUsarLocalizacao, setPodeUsarLocalizacao] = useState(false);
  const [localizacao, setLocalizacao] = useState(null);

  const {
    notificacoesNaoVisualizadas,
    verificaPossuiLembreteNaoVisualizado,
    handleMarcarComoVisualizado,
  } = useLembreteAtividadeHook(activityBranch);

  useEffect(() => {
    getLocation(registraLocalizacao(setLocalizacao));
    localforage.ready().then(() => {
      localforage
        .getItem('geolocationAllowed')
        .then(podeUsar => setPodeUsarLocalizacao(podeUsar));
    });
  }, []);

  const { id: idAtividade } = activityBranch || {};

  useEffect(() => {
    const lembreteAtividade = verificaPossuiLembreteNaoVisualizado();

    if (lembreteAtividade) {
      handleMarcarComoVisualizado([lembreteAtividade.pk]);
    }
    // eslint-disable-next-line
  }, [activityBranch, notificacoesNaoVisualizadas]);

  // TODO - LIP  separar isso em um hoc
  const {
    current,
    setCurrent,
    veiculos,
    naoPodeExecutarAcao,
    openAtualizaModeloInativoDialog,
    setOpenAtualizaModeloInativoDialog,
  } = useAtualizarModeloInativoState(modelosInativosList);

  const exibirModalDeVeiculoInativo =
    naoPodeExecutarAcao && crmObrigarModeloInteresseAtivo;

  if (naoPodeExecutarAcao && crmObrigarModeloInteresseAtivo) {
    return (
      <>
        {exibirModalDeVeiculoInativo && (
          <div>
            <AtualizaModeloInativoDialog
              open={openAtualizaModeloInativoDialog}
              veiculos={veiculos}
              idOportunidade={idOportunidade}
              atualizaVeiculo={(vehicleData, currentIndex) => {
                if (currentIndex < veiculos.length - 1) {
                  setCurrent(current + 1);
                } else {
                  setOpenAtualizaModeloInativoDialog(false);
                }
              }}
              current={current}
              handleClose={() => {
                props.history.push('/agenda');

                return setOpenAtualizaModeloInativoDialog(false);
              }}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <WizardForm
        {...props}
        pages={pageProps => {
          const {
            data: { atividade: { tipoOrigem } = {} },
            tipoAcaoInformarContato,
          } = pageProps;

          if (
            tipoOrigem === TipoOrigemAtividade.FICHA_VISITA.name ||
            tipoAcaoInformarContato ===
              TipoAcaoInformarContato.CRIAR_FICHA_VISITA.name
          ) {
            return [
              RealizarAtividadeVisitaFirstPage,
              RealizarAtividadeVisitaSecondPage,
            ];
          }

          if (
            tipoOrigem === TipoOrigemAtividade.PROSPECCAO.name ||
            tipoAcaoInformarContato ===
              TipoAcaoInformarContato.CRIAR_ATIVIDADE_PROSPECCAO.name
          ) {
            return [RealizarAtividadeLeadFirstPage];
          }

          return [
            RealizarAtividadeFirstPage,
            RealizarAtividadeSecondPage,
            RealizarAtividadePosAcao,
          ];
        }}
        reduxOptions={{
          // a unique name for the form
          // eslint-disable-next-line
          form: props.formName,
          validate: validateRequired([
            'idAtividade',
            'dataHoraRealizacao',
            'observacao',
          ]),
          onSubmit: async (
            { action, payload, ...values },
            dispatch,
            innerProps
          ) => {
            const {
              goToNextPage,
              history,
              tipoAcaoInformarContato,
              clientId,
              unidadeNegocioId,
              data: { atividade: { tipoOrigem } = {} },
              urlRetorno,
              roteirizacaoClienteId,
            } = innerProps;

            const { temperatura } = values;
            let submitValues = {
              ...values,
              clientId,
              unidadeNegocioId,
              dataHoraRealizacao: getLocalDateTimeString(
                values.dataHoraRealizacao
              ),
              dataHoraReagendamento:
                values.dataHoraReagendamento &&
                getLocalDateTimeString(values.dataHoraReagendamento),
            };

            submitValues = omitDeep(submitValues, 'urlRetorno');

            if (podeUsarLocalizacao) {
              submitValues = {
                ...submitValues,
                ...localizacao,
              };
            }

            if (values.dataProximaAtividade) {
              submitValues = {
                ...omitDeep(submitValues, 'dataProximaAtividade'),
                dataProximaAtividade: getLocalDateTimeString(
                  values.dataProximaAtividade
                ),
              };
            }

            const criarEInformarContatoProspeccao =
              tipoAcaoInformarContato &&
              TipoAcaoInformarContato.CRIAR_ATIVIDADE_PROSPECCAO.name ===
                tipoAcaoInformarContato;

            if (
              tipoOrigem !== TipoOrigemAtividade.PROSPECCAO.name &&
              !criarEInformarContatoProspeccao
            ) {
              submitValues = {
                ...omit(submitValues, ['dataHoraReagendamento']),
              };
            }

            const oportunidadeIncompletaAposEnvio =
              isOportunidadeIncompleta({
                crmObrigarPreenchimentoDadosVeiculoOportunidade,
                temperatura,
                activityBranch,
                action,
              }) && action !== 'NEGOCIO_PERDIDO';

            let promise = null;

            promise =
              visitaActions(
                action,
                submitValues,
                innerProps,
                tipoAcaoInformarContato,
                urlRetorno
              ) || promise;
            promise =
              prospeccaoActions(
                action,
                payload,
                submitValues,
                innerProps,
                tipoAcaoInformarContato
              ) || promise;
            promise =
              oportunidadeActions(
                action,
                submitValues,
                innerProps,
                tipoAcaoInformarContato
              ) || promise;

            // FIXME esse código está muito fora do padrão
            switch (action) {
              case ActivityActions.GO_TO_LOST_BUSINESS.name:
                eventLostBusiness();
                goToNextPage(payload);
                break;
              case ActivityActions.GO_TO_PERFORM_VISIT_ACTIVITY.name:
                eventAdvanceButtonPerformVisitActivity();
                goToNextPage(payload);
                break;
              default:
            }

            if (promise) {
              return (
                promise
                  .then(({ data = null } = {}) => {
                    let postActionPromise = null;

                    postActionPromise = realizarAtividadeVisitaPostAction(
                      postActionPromise,
                      data,
                      history,
                      urlRetorno,
                      roteirizacaoClienteId
                    );
                    postActionPromise = realizarAtividadePostAction(
                      postActionPromise,
                      data,
                      history,
                      oportunidadeIncompletaAposEnvio,
                      idAtividade,
                      urlRetorno
                    );
                    postActionPromise = negocioPerdidoPostAction(
                      postActionPromise,
                      data,
                      history,
                      oportunidadeIncompletaAposEnvio,
                      idAtividade,
                      urlRetorno
                    );
                    postActionPromise = atividadePadraoPostAction(
                      postActionPromise,
                      history,
                      oportunidadeIncompletaAposEnvio,
                      idAtividade,
                      urlRetorno
                    );

                    return postActionPromise();
                  })
                  .catch(e => {
                    const houveErro = negociacaoAceitaErro(
                      history,
                      e?.graphQLErrors,
                      activityBranch
                    );

                    if (houveErro) {
                      return;
                    }

                    if (
                      e instanceof ErrorOffline ||
                      e?.graphQLErrors instanceof ErrorOffline
                    ) {
                      if (e?.defaultErrorHandler) {
                        e.defaultErrorHandler();
                      }
                      history.push('/agenda');

                      return;
                    }
                    e.defaultErrorHandler();
                  }) || true
              );
            }

            return null;
          },
        }}
      />
    </>
  );
};
