import moment from 'moment';
import { graphql, withApollo } from '@apollo/react-hoc';
import { withRouter } from 'react-router';
import { compose, onlyUpdateForKeys } from 'recompose';
import { enumGetNames, TipoAtividade } from '../../core/Enums';
import { getLocalDateTimeString } from '../utils/dateUtils';
import { displayLoadingState } from '../utils/displayLoadingState';
import { ActivitiesList } from './ActivitiesList';
import GetWeekActivitiesQueryGql from './data/queries/GetWeekActivitiesQueryGql.graphql';
import { getOfflineEntityIdContext } from '../utils/getOfflineEntityIdContext';
import { client } from '../../index';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';
import { withCrmProspeccaoNomePatternQuery } from '../opportunity/data/queries/crmTermosQuery';
import { withTipoOrigemOportunidade } from '../RealizarAtividade/data/queries/TipoOrigemOportunidadeQuery';

export const optionsWeekQuery = isAtrasadas => ({
  selectedDay,
  usuarioGerente,
  usuarioAdmin,
  fetchPolicy = 'cache-and-network',
}) => {
  const types = enumGetNames(TipoAtividade).filter(
    e => e !== TipoAtividade.FATURAMENTO.name
  );

  return {
    fetchPolicy,
    variables: {
      initialDate: isAtrasadas
        ? null
        : getLocalDateTimeString(moment(selectedDay).startOf('week')),
      endDate: isAtrasadas
        ? getLocalDateTimeString(moment().startOf('day'))
        : getLocalDateTimeString(moment(selectedDay).endOf('week')),
      types,
      somenteAtividadesGerente: usuarioGerente || usuarioAdmin,
      finalizado: isAtrasadas ? false : null,
    },
  };
};

export const weekQuery = graphql(GetWeekActivitiesQueryGql, {
  options: optionsWeekQuery(false),
});

// Use same getAtividades query, using with different name to avoid collision though.
export const atividadesAtrasadasName = 'atividadesAtrasadas';
export const atividadesAtrasadasQuery = graphql(GetWeekActivitiesQueryGql, {
  options: optionsWeekQuery(true),
  name: atividadesAtrasadasName,
});

export const atrasadasFilter = activity => activity.atrasada;

export const filterByDay = selectedDay => activity => {
  const activityMoment = moment(activity.dataHoraExibicao);

  return activityMoment.isSame(selectedDay, 'day');
};

const enhance = compose(
  withRouter,
  withUsuarioLogado,
  withApollo,
  withTipoOrigemOportunidade,
  withCrmProspeccaoNomePatternQuery,
  getOfflineEntityIdContext,
  displayLoadingState({
    name: 'data',
    onCacheLoadOnlyQueryName: 'getAtividades',
    verifyVariablesHasUpdate: ({
      selectedDay,
      usuarioGerente,
      usuarioAdmin,
      showAtrasadas,
    }) => {
      try {
        if (showAtrasadas) {
          return false;
        }

        client.readQuery({
          ...optionsWeekQuery(false)({
            selectedDay,
            usuarioGerente,
            usuarioAdmin,
          }),
          query: GetWeekActivitiesQueryGql,
        });

        return false;
      } catch (e) {
        return true;
      }
    },
  }),
  displayLoadingState({
    name: atividadesAtrasadasName,
    onCacheLoadOnlyQueryName: 'getAtividades',
    verifyVariablesHasUpdate: ({ usuarioGerente, showAtrasadas }) => {
      try {
        if (!showAtrasadas) {
          return false;
        }
        client.readQuery({
          ...optionsWeekQuery(true)({ usuarioGerente }),
          query: GetWeekActivitiesQueryGql,
        });

        return false;
      } catch (e) {
        return true;
      }
    },
  }),
  onlyUpdateForKeys([
    'selectedDay',
    'showAtrasadas',
    'selectedTab',
    'activities',
    'data',
    'origensOportunidade',
    'prospeccoesSemContato',
    atividadesAtrasadasName,
  ])
);

export const ActivitiesListContainer = enhance(ActivitiesList);
