import { useState } from 'react';
import { getFirstElementOfArray } from '@tecsinapse/es-utils/';

export function useStateMarca(marcas) {
  const primeiraMarca = getFirstElementOfArray(marcas);
  const [marca, setMarca] = useState(primeiraMarca && primeiraMarca.id);

  return [marca, setMarca];
}

export function useExibirExcellenceClub(
  crmIndicadorFupMobileAtivo,
  usuarioLogado
) {
  const exibeIndicadorExcellenceClubGerente =
    crmIndicadorFupMobileAtivo &&
    (usuarioLogado?.gerente || usuarioLogado?.administrador);
  const exibeIndicadorExcellenceClubVendedor =
    crmIndicadorFupMobileAtivo && usuarioLogado?.vendedorCrm;

  return {
    exibeIndicadorExcellenceClubGerente,
    exibeIndicadorExcellenceClubVendedor,
  };
}
