import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FloatingButton as FloatingButtonUiKit } from '@tecsinapse/ui-kit/build/components/Buttons/FloatingButton';

const styles = {
  button: {
    position: 'fixed',
    bottom: 80,
    right: 30,
    borderRadius: '50%',
    zIndex: 100,
  },
};

export const FloatingButton = withStyles(styles)(
  ({ classes, icon, disabled, ...props }) => (
    <FloatingButtonUiKit
      aria-label="add"
      className={classes.button}
      disabled={disabled}
      variantFab="warning"
      {...props}
    >
      {icon}
    </FloatingButtonUiKit>
  )
);
