import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  descricao: {
    fontWeight: 'bold',
    color: ({ visualizado }) => (visualizado ? 'gray' : 'black'),
  },
  cliente: {
    color: 'gray',
  },
  dataHora: {
    color: ({ visualizado }) => (visualizado ? 'gray' : 'black'),
  },
});

export const NotificacaoCardBase = ({
  titulo,
  descricao,
  dataHora,
  visualizado,
}) => {
  const classes = useStyles({ visualizado });

  return (
    <>
      <Typography variant="subtitle1" className={classes.descricao}>
        {titulo}
      </Typography>
      <Typography variant="subtitle2" className={classes.cliente}>
        {descricao}
      </Typography>
      <Typography variant="subtitle2" className={classes.dataHora}>
        {dataHora}
      </Typography>
    </>
  );
};
