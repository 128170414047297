import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import { genericQueryHoc } from '../../utils/crud/genericQueryHoc';

export const UltimaRoteirizacaoByConsultorQueryGql = gql`
  query ultimaRoteirizacaoByConsultorQuery {
    ultimaRoteirizacaoByConsultor {
      id
      consultorExterno {
        id
        nome
      }
      urlMapa
      roteirizacaoRotas {
        id
        data
        mapLink
        rotaId
        roteirizacaoClientes {
          id
          data
          obrigatorio
          distanciaKm
          tempoDeslocamento
          tempoDuracao
          endereco
          clienteVo {
            id
            nome
            email
            cpfCnpj
            telefone
            celular
            telefoneComercial
            idDealer
            podeRealizarAtividadeFichaVisita
            ultimaAtividadeFichaVisitaAbertaPk
            consultorExternoPk
            endereco {
              id
              cep
              logradouro
              bairro
              numero
              complemento
              cidade
              estado {
                id
                nome
                sigla
              }
            }
            contatos {
              id
              nome
              telefone
              celular
              email
              cargo
              setor
              aceitaReceberEmailCam
              idCliente
              idSegmento
            }
            unidadesNegocioAtendidos {
              id
              descricao
            }
            clienteVisitadoNoPeriodoDaUltimaRoteirizacao
          }
        }
      }
      __typename
    }
  }
`;

export const withUltimaRoteirizacaoByConsultor = () => {
  const [
    ,
    UltimaRoteirizacaoByConsultorQueryHoc,
    UltimaRoteirizacaoByConsultorQueryName,
    UltimaRoteirizacaoByConsultorQueryDataName,
  ] = genericQueryHoc({
    card: false,
    name: 'ultimaRoteirizacaoByConsultor',
    gql: UltimaRoteirizacaoByConsultorQueryGql,
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    skip: null,
  });

  return component =>
    compose(
      UltimaRoteirizacaoByConsultorQueryHoc,
      withProps(propsCompose => {
        const {
          [UltimaRoteirizacaoByConsultorQueryName]: {
            [UltimaRoteirizacaoByConsultorQueryDataName]: ultimaRoteirizacao = {},
          },
        } = propsCompose;

        return {
          ultimaRoteirizacao,
        };
      })
    )(component);
};
