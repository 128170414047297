import React, { Fragment } from 'react';
import { Card } from '@tecsinapse/ui-kit';
import {
  mdiCheckCircleOutline,
  mdiCheckDecagram,
  mdiHomeMapMarker,
  mdiMapMarker,
} from '@mdi/js';
import Icon from '@mdi/react';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { cardStyles, styleCardSemContato } from './styles';

export const getIconAvatar = (pontoPartida, pontochegada) => {
  let icon;

  if (pontoPartida) {
    icon = mdiHomeMapMarker;
  } else if (pontochegada) {
    icon = mdiCheckCircleOutline;
  } else {
    icon = mdiMapMarker;
  }

  return icon;
};

export const ClienteRotaCard = ({
  roteirizacaoCliente = {},
  cardOnClick,
  pontoPartida = false,
  pontochegada = false,
  ...props
}) => {
  const { key } = props;

  const classesCard = cardStyles();
  const {
    id: roteirizacaoClienteId,
    distanciaKm = 0,
    tempoDeslocamento = 0,
    endereco,
    clienteVo: cliente = null,
    atividadeRealizada = false,
  } = roteirizacaoCliente;

  const icon = getIconAvatar(pontoPartida, pontochegada);
  const titleCliente = (
    <Grid container>
      <Grid item xs={2} className={classesCard.gridItemAvatar}>
        <Avatar classeName={classesCard.titleClientAvatar}>
          <Icon path={icon} size={1} />
        </Avatar>
      </Grid>
      <Grid item xs={10} className={classesCard.gridItemTitulo}>
        {cliente && (
          <div className={classesCard.titulo}>
            <div className={classesCard.divClienteNome}>
              {cliente.nome.toLowerCase()}
            </div>
            <div className={classesCard.cpfCnpj}>{cliente.cpfCnpj}</div>
          </div>
        )}
        {pontoPartida && (
          <div className={classesCard.titulo}>
            <div>Ponto de Partida</div>
            <div className={classesCard.endereco}>{endereco.toLowerCase()}</div>
          </div>
        )}
        {pontochegada && (
          <div className={classesCard.titulo}>
            <div>Ponto de Chegada</div>
            <div className={classesCard.endereco}>{endereco.toLowerCase()}</div>
          </div>
        )}
      </Grid>
    </Grid>
  );

  const title = { name: titleCliente };

  const content = (
    <>
      {!(pontoPartida || pontochegada) && (
        <>
          <div className={classesCard.localizacao}>Localização: </div>
          <div className={classesCard.endereco}>
            {endereco && endereco.toLowerCase()}
          </div>

          {atividadeRealizada && (
            <div>
              <Chip
                label="Visita Realizada"
                size="small"
                avatar={
                  <Icon path={mdiCheckDecagram} color="white" size={0.65} />
                }
                classes={{
                  label: clsx(classesCard.chipTitle, classesCard.chipDoneLabel),
                }}
                className={classesCard.chipDone}
              />
            </div>
          )}
        </>
      )}
    </>
  );

  const subcontent = (
    <div className={classesCard.subContentMain}>
      <div className={classesCard.subContentLeft}>
        <div className={classesCard.subContentText}>{distanciaKm} Km</div>
        <div className={classesCard.titleKm}>Distância do cliente</div>
      </div>
      <div className={classesCard.subContentRight}>
        <div className={classesCard.subContentText}>{tempoDeslocamento}</div>
        <div className={classesCard.titleKm}>Tempo até o cliente</div>
      </div>
    </div>
  );

  return (
    <Fragment key={key}>
      <Card
        key={key}
        styleCard={styleCardSemContato}
        title={title}
        content={content}
        actions={!pontoPartida ? subcontent : ''}
        onClick={e => {
          cliente.roteirizacaoClienteId = roteirizacaoClienteId;
          cardOnClick(e, cliente);
        }}
      />
    </Fragment>
  );
};
