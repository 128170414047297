import { Drawer, ListItem, ListItemText, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  AccountBox,
  CheckCircle,
  Edit,
  Navigation,
  Phone,
  Timeline as TimelineIcon,
  Update,
} from '@material-ui/icons';
import { mdiSteering, mdiBell, mdiBellOff, mdiMapMarkerRadius } from '@mdi/js';
import Icon from '@mdi/react';
import { useLembreteAtividadeHook } from 'components/Agendador/LembreteAtividadeHook';
import React, { useState } from 'react';
import { BottomSheetClickEnum } from '../../core/Enums';
import { AgendadorDialog } from '../Agendador';
import {
  allowEditarOportunidade,
  allowInformarContato,
  allowReagendarAtividade,
  allowRoute,
  allowTestDrive,
  allowVerTimeline,
  isPerformCheckinBlocked,
  realizarAtividadeVisita,
  textRealizarAtividade,
} from '../Atividade/atividadeFunctions';
import { confirm } from '../utils/dialogs/ConfirmationDialog';
import {
  eventCallClientBottomSheet,
  eventEditClientBottomSheet,
  eventEditOpportunityBottomSheet,
  eventGoToRouteBottomSheet,
  eventInformCallBottomSheet,
  eventPerformTestDriveBottomSheet,
  eventPerformVisitActivityBottomSheet,
  eventRescheduleActivityBottomSheet,
  eventSeeHistoryBottomSheet,
} from '../utils/GAUtils';

const listStyles = {
  fullList: {
    width: 'auto',
  },
};

const eventGA = selectedActivity => {
  if (realizarAtividadeVisita(selectedActivity)) {
    eventPerformVisitActivityBottomSheet();
  } else {
    eventInformCallBottomSheet();
  }
};

const FullList = withStyles(listStyles)(({ classes, ...props }) => {
  const {
    selectedActivity,
    onBottomSheetClick,
    isOfflinePendente,
    usuarioLogado,
    possuiLembrete,
    onAdicionarLembrete,
    onRemoverLembrete,
  } = props;

  const checkinBlocked = isPerformCheckinBlocked(usuarioLogado);

  return (
    <div className={classes.fullList}>
      {allowInformarContato({ activity: selectedActivity, usuarioLogado }) &&
        !isOfflinePendente && (
          <ListItem
            button
            onClick={() => {
              eventGA(selectedActivity);
              onBottomSheetClick(BottomSheetClickEnum.INFORM_CALL);
            }}
          >
            <ListItemIcon>
              <CheckCircle />
            </ListItemIcon>
            <ListItemText primary={textRealizarAtividade(selectedActivity)} />
          </ListItem>
        )}
      <ListItem
        button
        onClick={() => {
          eventCallClientBottomSheet();
          onBottomSheetClick(BottomSheetClickEnum.CALL_CLIENT);
        }}
      >
        <ListItemIcon>
          <Phone />
        </ListItemIcon>
        <ListItemText primary="Entrar em Contato" />
      </ListItem>

      {allowTestDrive(selectedActivity) && (
        <ListItem
          button
          onClick={() => {
            eventPerformTestDriveBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.TEST_DRIVE);
          }}
        >
          <ListItemIcon>
            <SvgIcon>
              <Icon path={mdiSteering} size={1} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Informar Test-Drive" />
        </ListItem>
      )}

      {allowRoute(selectedActivity) && (
        <ListItem
          button
          onClick={() => {
            eventGoToRouteBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.GO_TO_ROUTE);
          }}
        >
          <ListItemIcon>
            <Navigation />
          </ListItemIcon>
          <ListItemText primary="Traçar Rota" />
        </ListItem>
      )}

      {allowReagendarAtividade(selectedActivity) && !isOfflinePendente && (
        <ListItem
          button
          onClick={() => {
            eventRescheduleActivityBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.RESCHEDULE_ACTIVITY);
          }}
        >
          <ListItemIcon>
            <Update />
          </ListItemIcon>
          <ListItemText primary="Reagendar Atividade" />
        </ListItem>
      )}

      {!isOfflinePendente && (
        <ListItem
          disabled={checkinBlocked}
          button
          onClick={() => {
            onBottomSheetClick(BottomSheetClickEnum.PERFORM_CHECKIN);
          }}
        >
          <ListItemIcon>
            <Icon path={mdiMapMarkerRadius} size={1} />
          </ListItemIcon>
          <ListItemText primary="Realizar check-in" />
        </ListItem>
      )}

      <Divider />
      {allowEditarOportunidade(selectedActivity) && !isOfflinePendente && (
        <>
          <ListItem
            button
            onClick={() => {
              eventEditOpportunityBottomSheet();
              onBottomSheetClick(BottomSheetClickEnum.EDIT_OPPORTUNITY);
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Editar Proposta" />
          </ListItem>
        </>
      )}

      {allowVerTimeline(selectedActivity) && (
        <ListItem
          button
          onClick={() => {
            eventSeeHistoryBottomSheet();
            onBottomSheetClick(BottomSheetClickEnum.SEE_HISTORY);
          }}
        >
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText primary="Linha do Tempo" />
        </ListItem>
      )}

      <ListItem
        button
        onClick={() => {
          eventEditClientBottomSheet();
          onBottomSheetClick(BottomSheetClickEnum.EDIT_CLIENT);
        }}
      >
        <ListItemIcon>
          <AccountBox />
        </ListItemIcon>
        <ListItemText primary="Editar Dados" />
      </ListItem>

      {!usuarioLogado.consultorExterno && window.navigator.onLine && (
        <ListItem
          button
          onClick={
            possuiLembrete
              ? () => onRemoverLembrete()
              : () => onAdicionarLembrete()
          }
        >
          <ListItemIcon>
            <SvgIcon>
              <Icon path={possuiLembrete ? mdiBellOff : mdiBell} size={1} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={possuiLembrete ? 'Remover lembrete' : 'Definir lembrete'}
          />
        </ListItem>
      )}
    </div>
  );
});

export const AtividadeBottomSheet = ({
  selectedActivity,
  showBottomSheetActions,
  hideDrawer,
  ...props
}) => {
  const {
    verificaPossuiLembrete,
    handleCriaLembrete,
    handleRemoveNotificacao,
  } = useLembreteAtividadeHook(selectedActivity);

  const possuiLembrete = verificaPossuiLembrete();
  const [agendarLembrete, setAgendarLembrete] = useState(false);

  return (
    <>
      <Drawer
        anchor="bottom"
        open={showBottomSheetActions}
        onClose={hideDrawer}
      >
        <div>
          <FullList
            selectedActivity={selectedActivity}
            onAdicionarLembrete={() => setAgendarLembrete(true)}
            onRemoverLembrete={() =>
              confirm().then(() => {
                handleRemoveNotificacao(possuiLembrete.pk);
                props.showAlert({
                  message: 'Lembrete de atividade removido com sucesso!',
                  variant: 'success',
                  chip: true,
                });
              })
            }
            possuiLembrete={!!possuiLembrete}
            {...props}
          />
        </div>
      </Drawer>

      <AgendadorDialog
        open={agendarLembrete}
        handleOpen={setAgendarLembrete}
        atividade={selectedActivity}
        onSelecionaHorario={handleCriaLembrete}
        {...props}
      />
    </>
  );
};
