import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const MARCAS_MODELO_INTERESSE = gql`
  query GetMarcasModeloInteresse($id: ID!) {
    marcasModeloInteresse(id: $id) {
      id
      nome
    }
  }
`;

const MOSTRAR_MARCA_QUERY = gql`
  query deveExibirMarcaQuery($id: ID!) {
    deveExibirMarca(id: $id)
  }
`;

export function useMarca() {
  const [verificarExibirMarcaLazy, dataExibir] = useLazyQuery(
    MOSTRAR_MARCA_QUERY
  );
  const [buscaMarcaLazy, dataMarcas] = useLazyQuery(MARCAS_MODELO_INTERESSE);

  const verificarExibirMarca = React.useCallback(
    idOportunidade => {
      verificarExibirMarcaLazy({
        variables: { id: idOportunidade },
        fetchPolicy: 'cache-and-network',
      });
    },
    [verificarExibirMarcaLazy]
  );

  const buscaMarca = React.useCallback(
    idOportunidade => {
      buscaMarcaLazy({
        variables: { id: idOportunidade },
        fetchPolicy: 'cache-and-network',
      });
    },
    [buscaMarcaLazy]
  );

  return {
    exibirMarca: dataExibir.data?.deveExibirMarca || false,
    dataMarcas,
    verificarExibirMarca,
    buscaMarca,
  };
}
