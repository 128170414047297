import React from 'react';
import PropTypes from 'prop-types';
import RegisterPush from './RegisterPush';
import { isIos } from '../utils/BrowserUtils';

const PushManagerContainer = props => {
  return <div>{!isIos() && <RegisterPush {...props} />}</div>;
};

PushManagerContainer.propTypes = {
  subscribeUrl: PropTypes.string.isRequired,
  vapidPubKey: PropTypes.string.isRequired,
};

export default PushManagerContainer;
