import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  show: false,
  chip: false,
  variant: null,
  title: '',
  message: '',
};

export const showAlertAction = createAction('SHOW_ALERT');
export const hideAlertAction = createAction('HIDE_ALERT');
export const alertReducer = handleActions(
  {
    [showAlertAction]: (
      state,
      { payload: { title = '', message = '', ...rest } }
    ) => ({
      ...state,
      show: true,
      title,
      message,
      ...rest,
    }),
    [hideAlertAction]: state => ({ ...state, ...defaultState }),
  },
  defaultState
);
