import { Drawer, ListItem, ListItemText, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build';
import { Button } from '@tecsinapse/ui-kit';
import React, { useEffect, useState } from 'react';
import { eventIndicatorAdvancedSearch } from '../../utils/GAUtils';
import { SelectComponentStyled } from '../../utils/select/RenderSelectField';
import { periodos } from '../../utils/SelectData';
import { FullDisplayContainer } from '../../utils/ui/styledComponents';

const indicadoresBottomSheetStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  rootListItem: {
    justifyContent: 'flex-end',
  },
}));

const IndicadoresBottomSheet = ({
  open,
  onClose,
  unidadesNegocioVendaAtivas,
  usuarioVendedor,
  dealers,
  vendedoresAtivos,
  setDealerFiltro,
  setVendedorFiltro,
  setUnidadeNegocioFiltro,
  setPeriodoGlobal,
  dealerFiltro,
  periodoGlobal,
  unidadeNegocioFiltro,
  vendedorFiltro,
  idSegmentoUnico,
  marcaGlobal,
  setMarcaGlobal,
  usuarioLogado: { marcas = [] } = {},
}) => {
  const [dealerBarraBusca, setDealerBarraBusca] = useState(dealerFiltro);
  const [marcaBarraDeBusca, setMarcaBarraDeBusca] = useState(marcaGlobal);
  const [periodoGlobalBarraBusca, setPeriodoGlobalBarraBusca] = useState(
    periodoGlobal
  );
  const [unidadeNegocioBarraBusca, setUnidadeNegocioBarraBusca] = useState(
    unidadeNegocioFiltro
  );
  const [vendedorBarraBusca, setVendedorBarraBusca] = useState(vendedorFiltro);

  const onRequestSearch = () => {
    setMarcaGlobal(marcaBarraDeBusca);
    setDealerFiltro(dealerBarraBusca);
    setVendedorFiltro(vendedorBarraBusca);
    setUnidadeNegocioFiltro(unidadeNegocioBarraBusca);
    setPeriodoGlobal(periodoGlobalBarraBusca);
    onClose();
  };

  const updateState = () => {
    setMarcaBarraDeBusca(marcaGlobal);
    setDealerBarraBusca(dealerFiltro);
    setVendedorBarraBusca(vendedorFiltro);
    setPeriodoGlobalBarraBusca(periodoGlobal);
    setUnidadeNegocioBarraBusca(unidadeNegocioFiltro);
  };
  const classes = indicadoresBottomSheetStyles();

  const onClose1 = () => {
    updateState();
    onClose();
  };

  useEffect(() => {
    unidadeNegocioFiltro && setUnidadeNegocioBarraBusca(unidadeNegocioFiltro);
  }, [unidadeNegocioFiltro, setUnidadeNegocioBarraBusca]);

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose1}>
      <div>
        <FullDisplayContainer>
          <ListItem className={classes.marginBottom}>
            <Typography variant="h6">Filtros</Typography>
          </ListItem>

          {(dealers || []).length > 1 && (
            <ListItem className={classes.marginBottom}>
              <ListItemText>
                <SelectComponentStyled
                  input={{
                    value: dealerBarraBusca,
                    onChange: e => {
                      setDealerBarraBusca(e);
                    },
                  }}
                  placeholder="Dealer"
                  label="Dealer"
                  classes={classes}
                  fullWidth
                  margin="normal"
                >
                  {(dealers || []).map(d => {
                    const { id, nome } = d;

                    return (
                      <MenuItem value={d} key={id}>
                        {nome}
                      </MenuItem>
                    );
                  })}
                </SelectComponentStyled>
              </ListItemText>
            </ListItem>
          )}

          {usuarioVendedor == null && (
            <ListItem className={classes.marginBottom}>
              <ListItemText>
                <SelectComponentStyled
                  input={{
                    value: vendedorBarraBusca,
                    onChange: e => {
                      setVendedorBarraBusca(e);
                    },
                  }}
                  placeholder="Vendedores"
                  label="Vendedores"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value={null} key={null}>
                    Nenhum
                  </MenuItem>
                  {(vendedoresAtivos || [])
                    .filter(
                      u =>
                        dealerBarraBusca &&
                        u &&
                        u.dealersGrupo &&
                        u.dealersGrupo
                          .map(d => d.id)
                          .includes(dealerBarraBusca.id)
                    )
                    .map(usuario => {
                      const { id, nome } = usuario;

                      return (
                        <MenuItem value={usuario} key={id}>
                          {nome}
                        </MenuItem>
                      );
                    })}
                </SelectComponentStyled>
              </ListItemText>
            </ListItem>
          )}

          {usuarioVendedor != null && marcas && marcas.length > 0 && (
            <ListItem className={classes.marginBottom}>
              <ListItemText>
                <SelectComponentStyled
                  input={{
                    value: marcaBarraDeBusca,
                    onChange: setMarcaBarraDeBusca,
                  }}
                  placeholder="Marcas"
                  label="Marcas"
                  fullWidth
                  margin="normal"
                >
                  {(marcas || []).map(m => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.nome}
                    </MenuItem>
                  ))}
                </SelectComponentStyled>
              </ListItemText>
            </ListItem>
          )}

          {!isNotUndefOrNull(idSegmentoUnico) && (
            <ListItem>
              <ListItemText disableTypography>
                <SelectComponentStyled
                  input={{
                    value: unidadeNegocioBarraBusca,
                    onChange: e => {
                      setUnidadeNegocioBarraBusca(e);
                    },
                  }}
                  placeholder="UnidadeNegocio"
                  label="Unidade de Negócio"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value={null} key={null}>
                    Nenhum
                  </MenuItem>
                  {(unidadesNegocioVendaAtivas || []).map(gs => {
                    const { id, descricao } = gs;

                    return (
                      <MenuItem value={gs} key={id}>
                        {descricao}
                      </MenuItem>
                    );
                  })}
                </SelectComponentStyled>
              </ListItemText>
            </ListItem>
          )}

          <ListItem>
            <ListItemText>
              <SelectComponentStyled
                input={{
                  value: periodoGlobalBarraBusca,
                  onChange: e => {
                    setPeriodoGlobalBarraBusca(e);
                  },
                }}
                placeholder="Período"
                label="Período"
                classes={classes}
                fullWidth
                margin="normal"
              >
                {(periodos || []).map(periodo => {
                  const { value, nome } = periodo;

                  return (
                    <MenuItem value={value} key={value}>
                      {nome}
                    </MenuItem>
                  );
                })}
              </SelectComponentStyled>
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classes.rootListItem }}>
            <Button
              onClick={() => {
                eventIndicatorAdvancedSearch();
                onRequestSearch();
              }}
              title="Pesquisar"
            >
              <Search /> Pesquisar
            </Button>
          </ListItem>
        </FullDisplayContainer>
      </div>
    </Drawer>
  );
};

export { IndicadoresBottomSheet };
