import React, { useContext, useState } from 'react';
import { keycloak } from '../../core/offline/kcOfflineStub';
import { UsuarioLogadoContext } from '../UsuarioLogado/UsuarioLogadoContext';
import { Notificador } from './Notificador';
import { NotificadorContext } from './NotificadorContext';
import { TOPICOS } from './topicos/Topicos';

export const NotificadorProvider = ({ children, topicosConfig }) => {
  const { hostname } = window.location;
  const usuarioContext = useContext(UsuarioLogadoContext);
  const isConsultorExterno = usuarioContext?.usuarioLogado?.consultorExterno;
  const [notificadorContext, setNotificadorContext] = useState([]);

  return (
    <NotificadorContext.Provider
      value={{ notificadorContext, setNotificadorContext }}
    >
      {!isConsultorExterno && (
        <Notificador
          host={
            process.env.REACT_APP_BACKEND_URL ||
            `ws://${hostname}:${process.env.REACT_APP_NOTIFICATION_SERVER_PORT}`
          }
          endpoint="/notificador-ws"
          userId={keycloak.idTokenParsed.sub}
          topicos={Array.from(TOPICOS.values())}
        />
      )}
      {children}
    </NotificadorContext.Provider>
  );
};
