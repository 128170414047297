import { isNotUndefOrNull } from '@tecsinapse/es-utils';
import {
  getLastFromArrayOrObject,
  isNotEmptyOrNull,
} from '@tecsinapse/es-utils/build';
import { accounting } from 'accounting';
import pick from 'lodash.pick';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  compose,
  withHandlers,
  withProps,
  withPropsOnChange,
  withState,
} from 'recompose';
import { CampoModeloInteresse } from '../../../core/Enums';
import { withUsuarioLogado } from '../../UsuarioLogado/withUsuarioLogado';
import { getAlertContext } from '../../utils/alertContext';
import { FuncionalidadeHOC } from '../../utils/crud/FuncionalidadeHOC';
import { getLocalDateString } from '../../utils/dateUtils';
import { displayLoadingStateQueries } from '../../utils/displayLoadingState';
import { connectToFormFields, validateRequired } from '../../utils/FormUtils';
import { graphqlOfflineMutation } from '../../utils/graphql/graphqlOfflineMutation';
import { LoadingContained } from '../../utils/Loading';
import { reduxFormAutoFocus } from '../../utils/reduxFormAutoFocus';
import {
  ActivePriceTableQueryHoc,
  AnosModeloQuery,
  ModeloVendasQuery,
} from '../data/queries/activePriceTableQuery';
import {
  withAnoModeloAtivo,
  withCamposModeloAtivos,
  withObrigarModeloInteresseComVarianteAtivo,
  withTermoModelo,
  withTermoVariante,
  withTermoVersao,
  withVarianteModeloAtivo,
  withVeiculosNovosMostrarModelo,
  withVeiculosNovosMostrarSerie,
} from '../data/queries/crmTermosQuery';
import { GetOpportunitySegmento } from '../data/queries/getOpportunitySegmentoQuery';
import {
  ModeloInteresseQuery,
  PodeEditarPrecoUnitarioQueryHOC,
} from '../data/queries/modeloInteresseQuery';
import { PriceTableQuery } from '../data/queries/priceTableDetailsQuery';
import { podeEditarProposta } from '../oportunidadeRules';
import editOpportunityVehicleMutationGql from './editOpportunityVehicleMutationGql.graphql';
import { OpportunityVehiclesEditor } from './OpportunityVehiclesEditor';
import {
  atualizaMapsModelosVeiculoVarianteModeloInativo,
  filtrarVersaoPorAnoFabricacaoEAnoModelo,
  getAnoFabricacaoEAnoModeloSeparados,
  getItemTabelaPreco,
  selecionarPrimeiraVariante,
  selecionarPrimeiraVersao,
  selecionarPrimeiroAnoModelo,
  setPrecoUnitario,
} from './opportunityVehiclesEditorFunctions';

export const editOpportunityVehicleMutation = graphqlOfflineMutation(
  editOpportunityVehicleMutationGql,
  {
    name: 'editOpportunityVehicle',
    mutationName: 'editOpportunityVehicleMutationGql',
  }
);

const formName = 'EditOpportunityVehicleForm';

const enhance = compose(
  withTermoModelo,
  withTermoVersao,
  withTermoVariante,
  withCamposModeloAtivos,
  withVeiculosNovosMostrarModelo,
  withVeiculosNovosMostrarSerie,
  withVarianteModeloAtivo,
  withAnoModeloAtivo,
  withObrigarModeloInteresseComVarianteAtivo,
  withProps(
    ({
      crmVarianteTermo: varianteLabel = 'Variante',
      crmVersaoTermo: versaoLabel = 'Versão',
      crmModeloTermo: modeloLabel = 'Modelo',
      crmCamposModeloAtivos,
      crmObrigarModeloInteresseComVarianteAtivo,
    }) => {
      const exibeCor = (crmCamposModeloAtivos || []).includes(
        CampoModeloInteresse.COR.name
      );
      const exibeInterior = (crmCamposModeloAtivos || []).includes(
        CampoModeloInteresse.COR_INTERIOR.name
      );

      return {
        varianteLabel,
        versaoLabel,
        modeloLabel,
        exibeCor,
        exibeInterior,
        obrigaVariante: crmObrigarModeloInteresseComVarianteAtivo,
      };
    }
  ),
  withRouter,
  getAlertContext,
  GetOpportunitySegmento,
  editOpportunityVehicleMutation,
  PodeEditarPrecoUnitarioQueryHOC,
  ActivePriceTableQueryHoc,
  ModeloInteresseQuery,
  withHandlers({
    getIdTabelaPreco: ({ modeloInteresse, activePriceTable, match }) => () => {
      const idModeloInteresse = match.params?.id;
      const idTabelaDefaultNaInclusaoModeloInteresse = getLastFromArrayOrObject(
        activePriceTable?.tabelasPreco || []
      ).id;

      return (
        (modeloInteresse &&
          modeloInteresse.modeloInteresse?.oportunidade?.utilizaTabelaPreco &&
          modeloInteresse.modeloInteresse &&
          modeloInteresse.modeloInteresse.tabelaPreco &&
          modeloInteresse.modeloInteresse.tabelaPreco.id) ||
        (!idModeloInteresse
          ? idTabelaDefaultNaInclusaoModeloInteresse
          : undefined)
      );
    },
  }),
  withState('graphqlOptions', 'setGraphqlOptions', ({ getIdTabelaPreco }) => {
    return { idTabelaPreco: getIdTabelaPreco() };
  }),
  connectToFormFields(formName, [
    'idModelo',
    'idVersao',
    'idMarca',
    'idFamilia',
    'idVariante',
    'anoModelo',
    'anoFabricacaoAnoModelo',
  ]),
  FuncionalidadeHOC(),
  PriceTableQuery,
  // Esta carregando aqui pois a query modeloInteresse usa pricetable
  displayLoadingStateQueries(['modeloInteresse'], LoadingContained),
  ModeloVendasQuery,
  withUsuarioLogado,
  displayLoadingStateQueries(['modelosVenda'], LoadingContained),
  AnosModeloQuery,
  displayLoadingStateQueries(['anosModelo'], LoadingContained),
  withProps(({ modeloInteresse }) => ({
    tabelaInativa: !!(
      modeloInteresse &&
      modeloInteresse.modeloInteresse &&
      modeloInteresse.modeloInteresse.tabelaPreco &&
      !modeloInteresse.modeloInteresse.tabelaPreco.ativa
    ),
  })),
  withHandlers({
    filtrarVersaoPorAnoFabricacaoEAnoModelo: () =>
      filtrarVersaoPorAnoFabricacaoEAnoModelo,
  }),
  withProps(({ variant, priceTable, modeloInteresse, tabelaInativa }) => {
    const somenteTabelasNovas = variant === 'embedded';

    let tabelaPreco = null;

    if (somenteTabelasNovas) {
      tabelaPreco = priceTable?.tabelaPreco;
    } else {
      tabelaPreco = tabelaInativa
        ? modeloInteresse?.modeloInteresse?.tabelaPreco
        : priceTable && priceTable.tabelaPreco;
    }

    return {
      tabelaPreco,
    };
  }),
  withProps(
    ({
      priceTable,
      tabelaPreco,
      getOpportunitySegmento = {},
      modeloInteresse,
    }) => {
      const {
        oportunidade: { unidadeNegocio = {} } = {},
      } = getOpportunitySegmento;
      let modeloVersaoMap = null;
      let versaoVarianteMap = null;
      let modeloMap = null;

      if (priceTable && tabelaPreco) {
        modeloMap = {};
        modeloVersaoMap = {};
        versaoVarianteMap = {};
        tabelaPreco.itens.reduce((acc, { modelo }) => {
          if (
            modelo &&
            modelo.segmento &&
            modelo.segmento.id === unidadeNegocio.segmento.id
          ) {
            acc[modelo.id] = modelo;
          }

          return acc;
        }, modeloMap);

        tabelaPreco.itens.reduce((acc, { modelo, versao }) => {
          if (modelo && versao) {
            const versoes = (acc[modelo.id] || []).filter(
              ({ id }) => id !== versao.id
            );

            acc[modelo.id] = [...versoes, versao];
          }

          return acc;
        }, modeloVersaoMap);

        tabelaPreco.itens.reduce((acc, { versao, variante }) => {
          if (versao && variante) {
            const variantes = (acc[versao.id] || []).filter(
              ({ id }) => id !== variante.id
            );

            acc[versao.id] = [...variantes, variante];
          }

          return acc;
        }, versaoVarianteMap);

        const {
          modeloMap: modeloMapUpdated,
          modeloVersaoMap: modeloVersaoMapUpdated,
          versaoVarianteMap: versaoVarianteMapUpdated,
        } = atualizaMapsModelosVeiculoVarianteModeloInativo(
          modeloInteresse,
          modeloMap,
          modeloVersaoMap,
          versaoVarianteMap
        );

        modeloMap = modeloMapUpdated;
        modeloVersaoMap = modeloVersaoMapUpdated;
        versaoVarianteMap = versaoVarianteMapUpdated;
      }

      return {
        modeloMap,
        modeloVersaoMap,
        versaoVarianteMap,
      };
    }
  ),
  withProps(
    ({
      modelosVenda: { modelosVenda = [] } = {},
      getOpportunitySegmento = {},
      modeloMap: modeloMapTabelaPreco,
      idMarca,
      modeloInteresse,
      idFamilia,
      crmNovosMostrarModelo,
      crmNovosMostrarSerie,
      crmVarianteModeloAtivo,
      crmAnoModeloAtivo,
      anosModeloMap,
    }) => {
      const {
        oportunidade: { unidadeNegocio = {} } = {},
      } = getOpportunitySegmento;
      let modeloMap = {};
      let modeloVersaoMap = {};
      let versaoVarianteMap = {};

      const utilizaModelo =
        (modeloInteresse.modeloInteresse &&
          modeloInteresse.modeloInteresse.utilizaModelo) ||
        crmNovosMostrarModelo;
      const utilizaFamilia =
        (modeloInteresse.modeloInteresse &&
          modeloInteresse.modeloInteresse.utilizaFamilia) ||
        crmNovosMostrarSerie;
      const utilizaVariante =
        (modeloInteresse.modeloInteresse &&
          modeloInteresse.modeloInteresse.oportunidade.utilizaVariante) ||
        crmVarianteModeloAtivo;
      const utilizaVersao =
        modeloInteresse.modeloInteresse &&
        modeloInteresse.modeloInteresse.utilizaVersao;

      // se loja nao usa tabela de preco, usamos os modelos de vendas ativos
      if (
        utilizaModelo &&
        !modeloMapTabelaPreco &&
        modelosVenda &&
        modelosVenda.length
      ) {
        modelosVenda
          .filter(
            m =>
              isNotEmptyOrNull(m) &&
              (idMarca === null || m.marca.id === idMarca) &&
              m.segmento.id === unidadeNegocio.segmento.id
          )
          .forEach(modelo => {
            modeloMap[modelo.id] = modelo;
            modeloVersaoMap[modelo.id] = modelo.versoes || [];
            // iterate versions reducing to map of [versao.id] : variantes
            (modelo.versoes || []).reduce((acc, versao) => {
              acc[versao.id] = versao.variantes || [];

              return acc;
            }, versaoVarianteMap);
          });

        const {
          modeloMap: modeloMapUpdated,
          modeloVersaoMap: modeloVersaoMapUpdated,
          versaoVarianteMap: versaoVarianteMapUpdated,
        } = atualizaMapsModelosVeiculoVarianteModeloInativo(
          modeloInteresse,
          modeloMap,
          modeloVersaoMap,
          versaoVarianteMap
        );

        modeloMap = modeloMapUpdated;
        modeloVersaoMap = modeloVersaoMapUpdated;
        versaoVarianteMap = versaoVarianteMapUpdated;

        return {
          modeloMap,
          modeloVersaoMap,
          versaoVarianteMap,
          utilizaModelo,
          utilizaFamilia,
          utilizaVariante,
          utilizaVersao,
          crmAnoModeloAtivo,
        };
      }

      const familiasFiltradasPorMarca = new Set();

      if (!modeloMapTabelaPreco && utilizaFamilia) {
        modelosVenda
          .filter(
            m =>
              isNotEmptyOrNull(m) &&
              (idMarca === null || m.marca.id === idMarca) &&
              m.familia &&
              m.familia.ativo
          )
          .forEach(modelo => {
            modeloMap[modelo.id] = modelo;
            familiasFiltradasPorMarca.add(modelo.familia);
          });

        modeloVersaoMap[idFamilia] = [];

        modelosVenda
          .filter(
            m =>
              isNotEmptyOrNull(m) &&
              (idMarca === null || m.marca.id === idMarca) &&
              m.familia &&
              m.familia.id === idFamilia &&
              m.familia.ativo
          )
          .forEach(modelo => {
            const versoes = modelo.versoes || [];

            versoes
              .filter(v => v.ativo)
              .forEach(v => modeloVersaoMap[idFamilia].push(v));
          });

        modeloVersaoMap[idFamilia].sort((a, b) => a.nome.localeCompare(b.nome));

        const iterator = familiasFiltradasPorMarca.values();
        let result = iterator.next();

        const familias = [];

        while (!result.done) {
          familias.push(result.value);
          result = iterator.next();
        }

        return {
          modeloMap,
          familiasFiltradasPorMarca: familias,
          modeloVersaoMap,
          utilizaModelo,
          utilizaFamilia,
          utilizaVariante,
          utilizaVersao,
          crmAnoModeloAtivo,
          anosModeloMap,
        };
      }

      return {
        utilizaModelo,
        utilizaFamilia,
        utilizaVariante,
        utilizaVersao,
        crmAnoModeloAtivo,
        anosModeloMap,
      };
    }
  ),
  withPropsOnChange(
    (props, nextProps) => {
      const length1 = (nextProps?.anosModelo?.anosModelo || []).length || 0;
      const length2 =
        (props &&
          props.anosModelo &&
          props.anosModelo.anosModelo &&
          props.anosModelo.anosModelo.length) ||
        0;

      return length2 !== length1;
    },
    ({
      modelosVenda,
      crmAnoModeloAtivo,
      anosModelo: { anosModelo = [] } = {},
    }) => {
      const anosModeloMap = [];

      if (crmAnoModeloAtivo) {
        for (let i = 0; i < anosModelo.length; i++) {
          const anoModelo = anosModelo[i];

          for (let x = 0; x < anoModelo.modelosIds.length; x++) {
            const modeloId = anoModelo.modelosIds[x];
            const obj = {};

            obj.modeloId = modeloId;

            const [anoFabricacao, ano] = getAnoFabricacaoEAnoModeloSeparados(
              anoModelo.anoModelo
            );

            obj.anoFabricacao = anoFabricacao;
            obj.ano = ano;
            obj.anoModelo = anoModelo.anoModelo;
            anosModeloMap.push(obj);
          }
        }
      }

      return { anosModeloMap };
    }
  ),
  withProps(
    ({
      modeloInteresse,
      permissoesUsuario,
      tabelaInativa,
      variant,
      idOportunidade,
      location: { state: vehicle },
      match,
      getIdTabelaPreco,
    }) => {
      const resetInputs = variant === 'embedded';

      const disabledForm =
        !resetInputs &&
        (tabelaInativa ||
          !podeEditarProposta(
            (modeloInteresse.modeloInteresse || {}).proposta,
            (modeloInteresse.modeloInteresse || {}).oportunidade
          ));

      return {
        initialValues: {
          ...(modeloInteresse ? modeloInteresse.modeloInteresse : {}),
          disabledForm,
          disabledPrice: permissoesUsuario
            ? !permissoesUsuario.podeEditarPrecoUnitarioModeloInteresse
            : false,
          previsaoAquisicao:
            modeloInteresse?.modeloInteresse?.previsaoAquisicao &&
            moment(modeloInteresse.modeloInteresse.previsaoAquisicao),
          idOportunidade:
            idOportunidade ||
            match?.params?.idOportunidade ||
            modeloInteresse?.modeloInteresse?.idOportunidade,
          idTabelaPreco: getIdTabelaPreco(),
          idModelo:
            (modeloInteresse &&
              modeloInteresse.modeloInteresse &&
              modeloInteresse.modeloInteresse.modelo &&
              modeloInteresse.modeloInteresse.modelo.id) ||
            null,
          idMarca:
            (modeloInteresse &&
              modeloInteresse.modeloInteresse &&
              modeloInteresse.modeloInteresse.marca &&
              modeloInteresse.modeloInteresse.marca.id) ||
            null,
          idFamilia:
            (modeloInteresse &&
              modeloInteresse.modeloInteresse &&
              modeloInteresse.modeloInteresse.familia &&
              modeloInteresse.modeloInteresse.familia.id) ||
            null,
          idVersao:
            (modeloInteresse &&
              modeloInteresse.modeloInteresse &&
              modeloInteresse.modeloInteresse.versao &&
              modeloInteresse.modeloInteresse.versao.id) ||
            null,
          idVariante:
            (modeloInteresse &&
              modeloInteresse.modeloInteresse &&
              modeloInteresse.modeloInteresse.variante &&
              modeloInteresse.modeloInteresse.variante.id) ||
            null,
          anoFabricacaoAnoModelo:
            (modeloInteresse &&
              modeloInteresse.modeloInteresse &&
              modeloInteresse.modeloInteresse.anoFabricacaoAnoModelo) ||
            null,
          disableStatus: (vehicle && vehicle.permiteAlterarStatus) || false,
          ...(resetInputs
            ? {
                idTabelaPreco: null,
                idModelo: null,
                idMarca: null,
                idFamilia: null,
                idVersao: null,
                idVariante: null,
                anoFabricacaoAnoModelo: null,
              }
            : {}),
        },
      };
    }
  ),
  reduxFormAutoFocus({
    // a unique name for the form
    form: formName,
    validate: validateRequired([
      'idOportunidade',
      'precoUnitario',
      'quantidade',
    ]),
    onSubmit: (
      values,
      dispatch,
      { history, editOpportunityVehicle, onSubmitCallBack, variant }
    ) => {
      const submitValues = pick(
        {
          ...values,
        },
        [
          'id',
          'idOportunidade',
          'idModelo',
          'idFamilia',
          'idVersao',
          'idVariante',
          'idTabelaPreco',
          'precoUnitario',
          'emNegociacao',
          'anoFabricacao',
          'anoModelo',
          'quantidade',
          'previsaoAquisicao',
          'corInteresse',
          'interiorVeiculo',
          'descricao',
        ]
      );

      submitValues.previsaoAquisicao = getLocalDateString(
        submitValues.previsaoAquisicao
      );

      if (isNotUndefOrNull(values.anoFabricacaoAnoModelo)) {
        const [anoFabricacao, anoModelo] = getAnoFabricacaoEAnoModeloSeparados(
          values.anoFabricacaoAnoModelo
        );

        submitValues.anoFabricacao = parseInt(anoFabricacao, 10);
        submitValues.anoModelo = parseInt(anoModelo, 10);
      }

      const variables = { vehicle: submitValues };

      return editOpportunityVehicle({
        variables,
      }).then(() => {
        if (variant !== 'embedded') {
          return history.goBack();
        }

        if (onSubmitCallBack) {
          return onSubmitCallBack();
        }

        return null;
      });
    },
  }),
  withHandlers({
    validateMoney: ({
      priceTable,
      idVariante,
      idModelo,
      idVersao,
    }) => value => {
      let selectedItemTabelaPreco = null;

      if (
        priceTable &&
        priceTable.tabelaPreco &&
        priceTable.tabelaPreco.itens
      ) {
        selectedItemTabelaPreco = getItemTabelaPreco({
          itens: priceTable.tabelaPreco.itens,
          idVariante,
          idModelo,
          idVersao,
        });
      }

      if (selectedItemTabelaPreco) {
        const moneyValue = accounting.unformat(value);

        if (
          moneyValue < selectedItemTabelaPreco.precoMinimo ||
          moneyValue > selectedItemTabelaPreco.precoMaximo
        ) {
          return 'Informe um valor entre o mínimo e o máximo';
        }
      }

      if (value <= 0) {
        return 'Informe um valor maior que zero';
      }

      return undefined;
    },
    onVarianteChange: ({
      change,
      priceTable,
      idModelo,
      idVersao,
      versaoVarianteMap,
    }) => (e, idVariante) => {
      setPrecoUnitario({
        change,
        priceTable,
        idModelo,
        idVersao,
        versaoVarianteMap,
        idVariante,
      });
    },
    onChangeAnoModelo: ({
      change,
      modeloVersaoMap,
      idModelo,
      versaoVarianteMap,
      priceTable,
    }) => (anoFabricacaoAnoModelo = []) => {
      if (
        !isNotUndefOrNull(idModelo) ||
        !isNotUndefOrNull(anoFabricacaoAnoModelo)
      ) {
        change('idVersao', null);
        change('idVariante', null);

        return;
      }

      const [, modelo] = getAnoFabricacaoEAnoModeloSeparados(
        anoFabricacaoAnoModelo
      );

      change('anoModelo', parseInt(modelo, 10));
      const idVersao = selecionarPrimeiraVersao(
        idModelo,
        modeloVersaoMap,
        anoFabricacaoAnoModelo,
        change
      );

      selecionarPrimeiraVariante(
        idVersao,
        versaoVarianteMap,
        change,
        anoFabricacaoAnoModelo,
        priceTable,
        idModelo
      );
    },
    onChangeModelo: ({
      change,
      anosModeloMap,
      modeloVersaoMap,
      versaoVarianteMap,
      priceTable,
    }) => idModelo => {
      change('descricao', null);
      change('precoUnitario', null);

      if (!isNotUndefOrNull(idModelo)) {
        change('anoFabricacaoAnoModelo', null);
        change('idVersao', null);
        change('idVariante', null);

        return;
      }

      const anoFabricacaoAnoModelo = selecionarPrimeiroAnoModelo(
        idModelo,
        anosModeloMap,
        change
      );
      const idVersao = selecionarPrimeiraVersao(
        idModelo,
        modeloVersaoMap,
        anoFabricacaoAnoModelo,
        change
      );

      selecionarPrimeiraVariante(
        idVersao,
        versaoVarianteMap,
        change,
        anoFabricacaoAnoModelo,
        priceTable,
        idModelo
      );
    },
    onChangeFamilia: ({ change }) => () => {
      change('descricao', null);
      change('precoUnitario', null);
    },
    onChangeVersao: ({
      change,
      versaoVarianteMap,
      idModelo,
      priceTable,
      anoFabricacaoAnoModelo,
    }) => idVersao => {
      change('descricao', null);
      change('precoUnitario', null);

      if (!isNotUndefOrNull(idVersao) || !isNotUndefOrNull(idModelo)) {
        change('idVariante', null);

        return;
      }

      selecionarPrimeiraVariante(
        idVersao,
        versaoVarianteMap,
        change,
        anoFabricacaoAnoModelo,
        priceTable,
        idModelo
      );
    },
    emptyPriceTable: ({ priceTable }) =>
      priceTable === undefined || priceTable == null || priceTable.length === 0,
  })
);

const OpportunityVehiclesEditorContainer = enhance(OpportunityVehiclesEditor);

OpportunityVehiclesEditorContainer.propTypes = {
  idModeloInteresse: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  idOportunidade: PropTypes.string,
  onSubmitCallBack: PropTypes.func,
  variant: PropTypes.string,
  submitLabel: PropTypes.string,
  formStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  LoadingComponent: PropTypes.func,
};

export { OpportunityVehiclesEditorContainer };
