import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withState,
  getContext,
  withHandlers,
} from 'recompose';
import { informarContatoClienteMutation } from 'components/Client/clienteBottomSheet/data/informarContatoClienteMutation';
import { withAtualizaModeloInativo } from 'components/AtualizaModeloInativo/withAtualizaModeloInativo';
import { getFirstFromSingleElementArrayNotNull } from '@tecsinapse/es-utils';
import { ClientCreateOptionEnum } from 'core/Enums';
import { postInformCallActions } from 'components/Client/clienteBottomSheet/postInformCallActions';
import { CheckInConfirmacaoComponent } from './CheckInConfirmacaoComponent';
import { EditClientQueryHoc } from '../../Client/client/data/queries/EditClientQuery';
import { createRegistrarCheckInMutation } from '../data/mutations/createRegistrarCheckInMutationGpl';
import { createFichaVisitaAtividadeMutation } from '../../opportunity/create/ClientCreateOptionsMutations';

export const CheckInCRMConfirmacaoContainer = compose(
  withProps(({ match }) => ({
    idClient: match.params.idCliente,
    titleButtonRealizarAtividade: 'Informar Contato',
  })),
  withProps(({ history }) => {
    const retornoInformado = history.location.pathname.split('/')[3];

    return {
      retornarPara: retornoInformado || 'checkIn',
    };
  }),
  EditClientQueryHoc,
  createRegistrarCheckInMutation,
  createFichaVisitaAtividadeMutation,
  withState('loading', 'setLoading', false),
  withState('atividades', 'setAtividades', []),
  withState('showInformCallModal', 'setShowInformCallModal', false),
  informarContatoClienteMutation,
  withAtualizaModeloInativo,
  getContext({
    createModal: PropTypes.func,
  }),
  withHandlers({
    handleRealizarAtividade: ({
      editClientQuery: { cliente },
      informarContatoCliente,
      history,
      createModal,
      atualizaModeloInativoEAbreDialog,
      setAtividades,
      setShowInformCallModal,
      retornarPara,
    }) => () => {
      const unidadeNegocioUnica = getFirstFromSingleElementArrayNotNull(
        cliente.unidadesNegocioAtendidos || []
      );

      if (cliente && (cliente.unidadesNegocioAtendidos || []).length > 1) {
        createModal({
          id: cliente.id,
          clientCreateOption: ClientCreateOptionEnum.INFORM_CALL,
          message: null,
          fullWidth: true,
        });
      } else {
        informarContatoCliente({
          variables: {
            idCliente: cliente.id,
            idUnidadeNegocio: unidadeNegocioUnica && unidadeNegocioUnica.id,
            idTipoContato: null,
          },
        }).then(
          postInformCallActions({
            history,
            createModal,
            atualizaModeloInativoEAbreDialog,
            urlRetorno: retornarPara,
            setAtividades,
            setShowInformCallModal,
          })
        );
      }
    },
  })
)(CheckInConfirmacaoComponent);
