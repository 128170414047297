import { AppBar, Toolbar, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { ArrowBack, ThumbDown } from '@material-ui/icons';
import { EmptyState, IconButton } from '@tecsinapse/ui-kit';
import { defaultOrange, useGlobalStyle } from 'components/app/globalStyle';
import { FullDisplayContainer } from 'components/utils/ui/styledComponents';
import { useUsuarioLogado } from 'hooks/useUsuarioLogado';
import React from 'react';
import styled from 'styled-components';
import { ActivityCard } from '../../../../Home/ActivityCard/ActivityCard';
import InfiniteScroll from '../../../../utils/InfiniteScroll';
import { LoadingContained } from '../../../../utils/Loading';

export const LeadsSemTratamentoDialog = ({
  title,
  open,
  onClose,
  loading,
  loadMore,
  hasNext,
  leads = [],
  qtdLeadsQualificacao = 0,
  tiposOrigemOportunidade = [],
  activityOnClick,
  onBottomSheetClick,
  setSelectedActivity,
}) => {
  const { usuarioGerente, usuarioLogado, usuarioAdmin } = useUsuarioLogado();
  const classes = useGlobalStyle();

  return (
    <Dialog fullScreen onClose={onClose} open={open}>
      <AppBar position="static" variant="outlined">
        <Toolbar>
          <IconButton
            classes={{ root: classes.iconBackButton }}
            onClick={onClose}
            color="inherit"
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="subtitle1" color="inherit">
            {title || 'Atividades'}
          </Typography>
        </Toolbar>
      </AppBar>
      <FullDisplayContainer>
        <InfiniteScroll
          pageStart={0}
          useCapture
          loadMore={loadMore}
          hasMore={hasNext}
          isLoading={loading}
          initialLoad={false}
          useWindow={false}
          loader={<LoadingContained key="loader" />}
        >
          {leads.length > 0 ? (
            leads.map(lead => (
              <ActivityCard
                key={lead.id}
                activity={lead}
                exibirTimer
                exibirBadgeUnidadeNegocio
                activityOnClick={(event, activity, offlinePendente) => {
                  setSelectedActivity(activity);
                  activityOnClick(event, activity, offlinePendente);
                }}
                onBottomSheetClick={(event, activity) => {
                  setSelectedActivity(activity);
                  onBottomSheetClick(event, activity);
                }}
                usuarioGerente={usuarioGerente}
                usuarioAdmin={usuarioAdmin}
                usuarioLogado={usuarioLogado}
                tipoOrigemOportunidade={tiposOrigemOportunidade}
                crmProspeccaoNomePattern="Lead"
              />
            ))
          ) : (
            <NadaAqui loading={hasNext || loading} />
          )}
        </InfiniteScroll>

        {!hasNext && qtdLeadsQualificacao > 0 && (
          <LeadsEmQualificacao>
            <Typography variant="subtitle2">
              Existem {qtdLeadsQualificacao} Leads em Qualificação no Contato
              Ativo
            </Typography>
          </LeadsEmQualificacao>
        )}
      </FullDisplayContainer>
    </Dialog>
  );
};

const NadaAqui = ({ loading }) => {
  if (!loading) {
    return (
      <EmptyState
        IconComponent={ThumbDown}
        message="Não há nenhum lead aqui."
      />
    );
  }

  return null;
};

const LeadsEmQualificacao = styled.div`
  color: white;
  border-radius: 5px;
  background-color: ${defaultOrange};
  text-align: center;
  margin: 20px;
  padding: 10px;
`;
