import React from 'react';

import { ShareBottomSheetContext } from './ShareBottomSheetContext';

export const ShareBottomSheetConsumer = props => {
  const { children, ...rest } = props;

  return (
    <ShareBottomSheetContext.Consumer>
      {({ openAndSetUrl }) => {
        return React.Children.map(children, child =>
          React.cloneElement(child, {
            openAndSetUrl,
            ...rest,
          })
        );
      }}
    </ShareBottomSheetContext.Consumer>
  );
};
