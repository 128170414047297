import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { getAlertContext } from '../../../utils/alertContext';
import { RemoveHOC } from '../../../utils/crud/RemoveHOC';
import { getLoadingSpinnerContext } from '../../../utils/loadingSpinnerContext';
import { ClientContacts } from './ClientContacts';
import { InativarContatoMutation } from '../data/mutations/InativarContatoMutation';
import { withSegmentosVendaAtivosQuery } from '../../../Home/data/queries/segmentosVendaAtivosQuery';
import { EditClientQueryHoc } from '../../client/data/queries/EditClientQuery';
import { withUsuarioLogado } from '../../../UsuarioLogado/withUsuarioLogado';

export const ClientContactsContainer = compose(
  withUsuarioLogado,
  getLoadingSpinnerContext,
  getAlertContext,
  withRouter,
  EditClientQueryHoc,
  withSegmentosVendaAtivosQuery,
  RemoveHOC({
    mutation: InativarContatoMutation,
    mutationName: 'inativarClienteContato',
  }),
  withHandlers({
    cardOnClick: ({ history, segmentos: { segmentosVendaAtivos } }) => (
      idCliente,
      contato
    ) => {
      history.push(`/editarContatoCliente/${idCliente}/${contato.id}`, {
        contato,
        segmentosVendaAtivos,
      });
    },
    addOnClick: ({ history }) => idCliente => {
      history.push(`/editarContatoCliente/${idCliente}/`);
    },
  })
)(ClientContacts);
