import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  show: false,
  message: null,
  cliente: null,
};

export const showClienteDuplicadoDialog = createAction(
  'SHOW_CLIENTE_DUPLICADO_DIALOG'
);
export const hideClienteDuplicadoDialog = createAction(
  'HIDE_CLIENTE_DUPLICADO_DIALOG'
);

export const clienteDuplicadoDialogReducer = handleActions(
  {
    [showClienteDuplicadoDialog]: (
      state,
      { payload: { message, cliente } }
    ) => ({
      ...state,
      message,
      cliente,
      show: true,
    }),
    [hideClienteDuplicadoDialog]: state => ({ ...state, ...defaultState }),
  },
  defaultState
);
