import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { Table } from '@tecsinapse/table/build/Table/Table';
import { makeStyles } from '@material-ui/styles';
import { ContactPhone, Delete, Edit } from '@material-ui/icons';

import { EmptyState } from '@tecsinapse/ui-kit';
import { FloatingButton } from '../../../utils/FloatingButton';
import { globalStyle } from '../../../app/globalStyle';
import {
  eventAddContact,
  eventEditContact,
  eventRemoveContact,
} from '../../../utils/GAUtils';

const useStyle = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ClientContacts = withStyles(globalStyle)(
  ({
    usuarioLogado,
    editClientQuery: {
      cliente: { contatos = [], id },
    },
    addOnClick,
    cardOnClick,
    removeOnClick,
    showAlert,
  }) => {
    const classes = useStyle();

    function avaliarPermissao(contato, callbackPermitido = () => {}) {
      if ((usuarioLogado?.setores || []).includes(contato.setor)) {
        callbackPermitido();
      } else {
        showAlert({
          message: 'Você não possui permissão para alterar esse contato.',
          title: 'Ação negada!',
          variant: 'warning',
        });
      }
    }

    const columns = [
      {
        title: 'Nome',
        field: 'nome',
      },
      {
        title: 'Telefone',
        field: 'telefone',
        customRender: row => (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {row.telefone ? row.telefone : 'Não informado'}
            </Typography>
          </div>
        ),
      },
      {
        title: 'Celular',
        field: 'celular',
        customRender: row => (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {row.celular ? row.celular : 'Não informado'}
            </Typography>
          </div>
        ),
      },
      {
        title: 'Email',
        field: 'email',
        customRender: row => (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {row.email ? row.email : 'Não informado'}
            </Typography>
          </div>
        ),
      },
    ];
    const contactActions = [
      {
        icon: <Edit />,
        tooltip: 'Editar Contato',
        label: 'Editar Contato',
        onClick: contato => {
          eventEditContact();
          avaliarPermissao(contato, () => cardOnClick(id, contato));
        },
      },
      {
        icon: <Delete />,
        tooltip: 'Deletar Contato',
        label: 'Deletar Contato',
        onClick: contato => {
          eventRemoveContact();
          avaliarPermissao(contato, () => removeOnClick(contato));
        },
        bottomDivider: true,
      },
    ];

    return (
      <>
        <Table
          columns={columns}
          data={contatos}
          rowId={row => row.id}
          onRowClick={contato => {
            eventEditContact();
            cardOnClick(id, contato);
          }}
          actions={contactActions}
          classes={classes}
          empytStateComponent={
            <EmptyState
              IconComponent={ContactPhone}
              message="Não há nenhum contato por aqui ainda."
            />
          }
        />

        <FloatingButton
          onClick={() => {
            eventAddContact();
            addOnClick(id);
          }}
        />
      </>
    );
  }
);
