import { Switch, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MapSharp } from '@material-ui/icons';
import { mdiCamera, mdiCheck, mdiMapMarker, mdiMapMarkerRadius } from '@mdi/js';
import Icon from '@mdi/react';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import { FullScreenLoading } from '@tecsinapse/ui-kit';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import { EmptyState } from '@tecsinapse/ui-kit/build/components/EmptyState/EmptyState';
import { InformarContatoDialog } from 'components/utils/InformarContatoDialog';
import React, { useEffect, useState } from 'react';
import { ROUTE_CHECK_IN } from '../../../constantes/constantes';
import {
  geolocationAllowed,
  getLocation,
  registraLocalizacao,
} from '../../../core/geolocationUtils';
import { getEnderecoAndCidade } from '../../utils/Endereco/enderecoUtils';
import { ShowBackButton, updateAppTitle } from '../../utils/uiUtils';
import { GoogleMaps } from './CheckInList/GoogleMaps/GoogleMaps';
import { UploadButton } from './UploadButton';
import { checkInConfirmacaoStyles } from './utils/checkInStylesUtils';
import { promiseConvertFileToBase64 } from './utils/checkInUtils';
import {eventClickPerformCheckin} from "../../utils/GAUtils";

const useCheckInConfirmacaoStyles = makeStyles(checkInConfirmacaoStyles);

export const CheckInConfirmacaoComponent = ({
  editClientQuery: { cliente },
  registrarCheckIn,
  loading,
  setLoading,
  history,
  titleButtonRealizarAtividade,
  handleRealizarAtividade,
  atividades,
  showInformCallModal,
  setShowInformCallModal,
  retornarPara,
}) => {
  const classes = useCheckInConfirmacaoStyles();
  const [locationAllowedByUser, setLocationAllowedByUser] = useState(undefined);
  const [localizacao, setLocalizacao] = useState(null);
  const [checkInRealizado, setCheckInRealizado] = useState(false);
  const [atualizaLocalizacao, setAtualizaLocalizacao] = useState(false);

  useEffect(() => {
    geolocationAllowed(allowed => {
      allowed && getLocation(registraLocalizacao(setLocalizacao));
      setLocationAllowedByUser(allowed);
    });
  }, []);

  const validMark = isNotUndefOrNull(
    localizacao && localizacao.latitude && localizacao.longitude
  );

  const mark = { lat: localizacao?.latitude, lng: localizacao?.longitude };

  const actionBackButton = () => history.push(ROUTE_CHECK_IN);

  const content = validMark ? (
    <>
      <Typography className={classes.atualizaLocalizacao} display="block">
        Você está aqui!
      </Typography>
      <GoogleMaps
        center={mark}
        markerPosition={mark}
        zoom={16}
        defaultZoom={6}
        height="250px"
      />
    </>
  ) : (
    <EmptyState
      IconComponent={MapSharp}
      titleMessage="Eitaaaaa..."
      message="Localização não informada."
    />
  );

  const [file, setFile] = useState(null);
  const endereco = getEnderecoAndCidade(cliente)
    ? getEnderecoAndCidade(cliente)
    : 'Endereço não informado';

  const saveCheckIn = fileBase64 => {
    const variables = {
      idCliente: cliente.id,
      registrouVisita: true,
      longitude: mark.lng.toString(),
      latitude: mark.lat.toString(),
      file: fileBase64,
      atualizaLocalizacao,
      modulo: process.env.REACT_APP_MODULOS,
    };

    return registrarCheckIn({
      variables,
    })
      .then(() => {
        setCheckInRealizado(true);
        setDisableButtonRegistrarCheckIn(false);
        setLoading(false);
      })
      .catch(() => {
        setDisableButtonRegistrarCheckIn(false);
        setLoading(false);
      });
  };

  const icon = <Icon path={mdiMapMarkerRadius} size={1} color="white" />;
  const [
    disableButtonRegistrarCheckIn,
    setDisableButtonRegistrarCheckIn,
  ] = useState(false);

  return (
    <>
      <div className={classes.form}>
        {updateAppTitle('Realizar Check-In')}
        {ShowBackButton({
          history,
          actionBackButton,
        })}

        {locationAllowedByUser && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.nomeCliente}>
                  {cliente.nome.toLowerCase()}
                </Typography>
                <Typography className={classes.cpfcnpj}>
                  {cliente.cpfCnpj ? cliente.cpfCnpj : 'CPF/CPNJ não informado'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                classes={{
                  root: classes.iconGridItem,
                }}
              >
                <Avatar
                  variant="rounded"
                  classes={{
                    root: classes.avatarMapMarker,
                  }}
                >
                  <Icon path={mdiMapMarker} size={1} />
                </Avatar>
              </Grid>
              <Grid item xs={9} className={classes.enderecoGridItem}>
                <Typography
                  variant="subtitle2"
                  display="block"
                  className={classes.endereco}
                >
                  {endereco}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {content}
              </Grid>
              {!checkInRealizado && (
                <>
                  <Grid item xs={10}>
                    <Typography className={classes.atualizaLocalizacao}>
                      Atualizar endereço do cliente com base na localização
                      atual?
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={atualizaLocalizacao}
                          onChange={(e, v) => setAtualizaLocalizacao(v)}
                        />
                      }
                      labelPlacement="end"
                    />
                  </Grid>
                </>
              )}
              {checkInRealizado && (
                <Grid item xs={12} className={classes.checkInRealizadoGridItem}>
                  <Icon
                    path={mdiCheck}
                    size={2}
                    className={classes.iconCheckinRealizado}
                  />
                  <Typography className={classes.labelCheckInRealizado}>
                    Check-In realizado com sucesso!
                  </Typography>
                </Grid>
              )}
              {checkInRealizado && (
                <Grid item xs={12} className={classes.gridItem}>
                  <Button
                    customVariant="warning"
                    fullWidth
                    onClick={() => handleRealizarAtividade()}
                  >
                    {titleButtonRealizarAtividade}
                  </Button>
                </Grid>
              )}
              {!checkInRealizado && (
                <Grid item xs={12} className={classes.gridItem}>
                  <UploadButton
                    label="Adicionar Imagem de Registro"
                    icon={
                      <Icon
                        path={mdiCamera}
                        size={1}
                        className={classes.iconButtonAdicionarImagem}
                      />
                    }
                    capture="environment"
                    accept="image/*"
                    onChange={e => {
                      if (e?.target?.files[0]) {
                        setFile(e.target.files[0]);
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {!checkInRealizado && (
              <div className={classes.divButtonRealizarCheckIn}>
                <Fab
                  variant="extended"
                  color="secondary"
                  disabled={disableButtonRegistrarCheckIn}
                  onClick={() => {
                    eventClickPerformCheckin()
                    setDisableButtonRegistrarCheckIn(true);
                    setLoading(true);
                    promiseConvertFileToBase64(file)
                      .then(fileBase64 => fileBase64)
                      .then(fileBase64 => {
                        return saveCheckIn(fileBase64);
                      });
                  }}
                >
                  {icon}
                </Fab>
              </div>
            )}
          </>
        )}

        {loading && <FullScreenLoading show />}
      </div>

      {locationAllowedByUser !== undefined && !locationAllowedByUser && (
        <EmptyState
          IconComponent={MapSharp}
          titleMessage="Impossível detectar sua localização!"
          message="Parece que você negou a permissão para utilizar-mos sua localização, assim não conseguimos realizar o check-in."
        />
      )}

      {cliente && atividades && (
        <InformarContatoDialog
          client={cliente}
          atividades={atividades}
          open={showInformCallModal}
          urlRetorno={retornarPara}
          handleClose={() => {
            setShowInformCallModal(false);
          }}
        />
      )}
    </>
  );
};
