import { graphqlOfflineMutation } from '../../../../utils/graphql/graphqlOfflineMutation';
import GetClientByIdQueryGql from '../../../client/data/queries/GetClientByIdQueryGql.graphql';
import editarVeiculoFrotaMutationGql from './EditarVeiculoFrotaMutationGql.graphql';

export const EditarVeiculoFrotaMutation = graphqlOfflineMutation(
  editarVeiculoFrotaMutationGql,
  {
    name: 'salvarVeiculoFrota',
    mutationName: 'editarVeiculoFrotaMutationGql',
    customErrorsHandler: false,
    description: async ({
      variables: {
        itemFrota: { id, idCliente },
      },
      apolloClient,
    }) => {
      let name;

      try {
        const {
          data: {
            cliente: { nome },
          },
        } = await apolloClient.query({
          variables: {
            id: idCliente,
            nullId: false,
          },
          fetchPolicy: 'cache-first',
          query: GetClientByIdQueryGql,
        });

        name = nome;
      } catch (e) {
        name = '';
      }

      return id == null
        ? `Adição de novo veículo de frota do cliente ${name}`
        : `Edição de veículo de frota do cliente ${name}`;
    },
  }
);
