import { defaultGrey, defaultOrange, defaultRed } from '../app/globalStyle';

export const getColorOfTemperatura = temperatura => {
  if (temperatura === 'FRIO') {
    return '#31b0d5';
  }

  if (temperatura === 'MORNO') {
    return defaultOrange;
  }

  if (temperatura === 'QUENTE') {
    return defaultRed;
  }

  if (temperatura === 'SUPER_QUENTE') {
    return '#d51d3f';
  }

  return defaultGrey;
};
