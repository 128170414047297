import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { compose, withHandlers } from 'recompose';
import { Field, Form } from 'redux-form';
import { getAnyFromArray } from '@tecsinapse/es-utils/build';
import {
  displayLoadingState,
  displayLoadingStateQueries,
} from '../../utils/displayLoadingState';
import {
  connectToFormFields,
  MapRadioField,
  RenderDateTimePicker,
  required,
  SubmitButton,
  submitWithAction,
  SwitchField,
} from '../../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../utils/uiUtils';
import { RealizarAtividadeSteps } from './RealizarAtividadeSteps';
import { OpcoesDataProximaAtividade } from '../../../core/Enums';
import { RenderSelectField } from '../../utils/select/RenderSelectField';
import { queryDataSistematicaHoc } from './data/queryDataSistematicaHoc';
import { VISITA_STEPS } from './visitaActions';

const style = {
  margin: {
    margin: '20px',
  },
  marginTop: {
    marginTop: '20px',
  },
};

const RealizarAtividadeVisitaSecondPageView = ({
  handleSubmit,
  onSubmit,
  classes,
  initialValues,
  motivosEncerramentoFichaVisitaSelecionaveisQuery: {
    motivosEncerramentoFichaVisitaSelecionaveis = [],
  },
  sistematica: {
    opcoesDataProximaAtividade = [],
    dataProximaAtividadePelaSistematica,
  },
  opcaoDataProximaAtividade,
  goToNextPage,
  encerrarFichaVisita,
  onChangeOpcaoDataProximaAtividade,
  formName = null,
  change,
  submitting,
  history,
  ...rest
}) => {
  useEffect(() => {
    if (
      !opcaoDataProximaAtividade ||
      opcaoDataProximaAtividade ===
        OpcoesDataProximaAtividade.CALCULADA_PELA_SISTEMATICA.name
    ) {
      change(
        'dataProximaAtividade',
        moment(dataProximaAtividadePelaSistematica)
      );
    }
  }, [opcaoDataProximaAtividade, change, dataProximaAtividadePelaSistematica]);

  return (
    <div>
      <RealizarAtividadeSteps {...rest} steps={VISITA_STEPS} />
      <RenderAppLogo />
      <ShowBackButton history={history} />
      <Form onSubmit={handleSubmit} className={classes.margin} name={formName}>
        {updateAppTitle('Realizar Atividade')}
        <Field
          name="encerrarFichaVisita"
          label="Encerrar ficha visita?"
          component={SwitchField}
          fullWidth
        />
        {encerrarFichaVisita ? (
          <>
            <Field
              name="motivoEncerramento"
              label="Motivo do Encerramento"
              required
              component={RenderSelectField}
              validate={[required]}
              fullWidth
            >
              <MenuItem value={null}>Selecione...</MenuItem>

              {motivosEncerramentoFichaVisitaSelecionaveis.map(
                ({ id, nome }) => (
                  <MenuItem value={id} key={id}>
                    {nome}
                  </MenuItem>
                )
              )}
            </Field>

            <SubmitButton
              className={classes.marginTop}
              variant="contained"
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'ENCERRAR_FICHA_VISITA',
              })}
              label="Encerrar Ficha"
              fullWidth
              submitting={submitting}
            />
          </>
        ) : (
          <>
            <Field
              fullWidth
              name="dataProximaAtividade"
              label="Data da próxima atividade"
              required
              validate={[required]}
              component={RenderDateTimePicker}
              disabled={
                opcaoDataProximaAtividade !==
                OpcoesDataProximaAtividade.DEFINIR_DATA.name
              }
            />
            <MapRadioField
              fullWidth
              name="opcaoDataProximaAtividade"
              map={opcoesDataProximaAtividade}
              onChange={onChangeOpcaoDataProximaAtividade}
            />
            <SubmitButton
              className={classes.marginTop}
              variant="contained"
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'REALIZAR_ATIVIDADE_VISITA',
              })}
              label="Realizar Atividade"
              fullWidth
              submitting={submitting}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export const RealizarAtividadeVisitaSecondPage = compose(
  connectToFormFields('RealizarAtividadeForm', [
    'encerrarFichaVisita',
    'dataHoraRealizacao',
    'opcaoDataProximaAtividade',
  ]),
  queryDataSistematicaHoc,
  displayLoadingState({
    onCacheLoadOnlyQueryName: 'opcoesDataProximaAtividade',
  }),
  displayLoadingStateQueries(['sistematica']),
  withHandlers({
    onChangeOpcaoDataProximaAtividade: ({
      sistematica: {
        dataProximaAtividadePelaSistematica,
        opcoesDataProximaAtividade,
      },
      dataHoraRealizacao,
      change,
    }) => (e, value) => {
      if (
        value === OpcoesDataProximaAtividade.CALCULADA_PELA_SISTEMATICA.name
      ) {
        change(
          'dataProximaAtividade',
          moment(dataProximaAtividadePelaSistematica)
        );

        return;
      }
      const daysToAdd = getAnyFromArray(
        opcoesDataProximaAtividade.filter(({ id }) => id === value)
      ).diasParaAdicionar;

      change(
        'dataProximaAtividade',
        moment(dataHoraRealizacao).add(daysToAdd, 'd')
      );
    },
  }),
  withStyles(style)
)(RealizarAtividadeVisitaSecondPageView);
