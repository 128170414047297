import { graphql } from '@apollo/react-hoc';
import TemModeloInteresseNovoEUsadoAtivosQueryGql from './TemModeloInteresseNovoEUsadoAtivosQueryGql.graphql';

export const TemModeloInteresseNovoEUsadoAtivosHOC = graphql(
  TemModeloInteresseNovoEUsadoAtivosQueryGql,
  {
    name: 'modeloNovoUsadoHOC',
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
  }
);
