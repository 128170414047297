import { BottomSheetClickEnum } from '../core/Enums';
import { modeloInteresseInativoGraql } from '../components/opportunity/data/queries/GetOpportunityVehiclesQueryGql';

export async function useLazyAtividadeOportunidadeQuery({
  event,
  activity,
  selectedActivity,
  client,
}) {
  let actionActivity = activity || selectedActivity;

  if (
    event === BottomSheetClickEnum.RESCHEDULE_ACTIVITY ||
    event === BottomSheetClickEnum.TEST_DRIVE
  ) {
    try {
      const { data: { oportunidade } = {} } = await client.query({
        variables: {
          id: (activity || selectedActivity)?.idOrigem,
        },
        fetchPolicy: navigator.onLine ? 'network-only' : 'cache-first',
        query: modeloInteresseInativoGraql,
      });

      actionActivity = {
        ...actionActivity,
        oportunidade,
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    } finally {
      // eslint-disable-next-line
      console.log('We do cleanup here');
    }
  }

  return actionActivity;
}
