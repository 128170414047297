import { Grid, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  mdiAlertDecagram,
  mdiBell,
  mdiCalendar,
  mdiClockOutline,
  mdiMapMarkerRadius,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Card, defaultBlack, defaultRed } from '@tecsinapse/ui-kit';
import { useNotificadorHook } from 'components/Notificacoes';
import { getLocalDateTimeBrazilString } from 'components/utils/dateUtils';
import { Loading } from 'components/utils/Loading';
import { RenderAppLogo, updateAppCenter } from 'components/utils/uiUtils';
import { isModuloCRM } from 'components/utils/utils';
import { useUsuarioLogado } from 'hooks/useUsuarioLogado';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import {
  setSelectedDayTab,
  setShowAtrasadasTab,
} from 'reducers/weekDaysReducer';
import { useAtividades } from './data/useAtividades';
import { CardStyles, MenuStyles } from './styles';
import { NewAppBanner } from './NewAppBanner';

const useMenuStyles = makeStyles(MenuStyles);

const mapDispatchToProps = dispatch => ({
  setSelectedDay: obj => {
    dispatch(setSelectedDayTab(obj));
  },
  setShowAtrasadas: obj => {
    dispatch(setShowAtrasadasTab(obj));
  },
});

const Menu = ({ history, setShowAtrasadas, setSelectedDay }) => {
  const classes = useMenuStyles();
  const { notificacoesNaoVisualizadas } = useNotificadorHook();
  const { usuarioLogado = {} } = useUsuarioLogado();
  const { atrasadas = 0, hoje = 0, loading } = useAtividades();

  const ultimoAcesso = getLocalDateTimeBrazilString(
    moment(usuarioLogado.ultimoAcesso)
  );

  const content = (title, icon, color, quantity = 0) => (
    <div className={classes.card}>
      <div>{icon}</div>
      <div>
        <Typography className={classes.cardTitle}>{title}</Typography>
      </div>
      <div>
        <Typography style={{ color }} className={classes.cardNumber}>
          {quantity}
        </Typography>
      </div>
    </div>
  );

  const cards = loading ? (
    <Loading />
  ) : (
    <Grid container className={classes.grid}>
      <Grid item xs={6}>
        <Card
          key={0}
          styleCard={CardStyles}
          title={<div />}
          content={content(
            'Atividades Atrasadas',
            <Icon path={mdiAlertDecagram} size={1} color={defaultRed} />,
            defaultRed,
            atrasadas
          )}
          onClick={() => {
            setShowAtrasadas(true);
            history.push('/agenda');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Card
          key={0}
          styleCard={CardStyles}
          title={<div />}
          content={content(
            'Atividades de Hoje',
            <Icon path={mdiCalendar} size={1} />,
            defaultBlack,
            hoje
          )}
          onClick={() => {
            setShowAtrasadas(false);
            setSelectedDay(moment());
            history.push('/agenda');
          }}
        />
      </Grid>
      {isModuloCRM && (
        <Grid item xs={6}>
          <Card
            key={0}
            styleCard={CardStyles}
            title={<div />}
            content={content(
              'Notificações',
              <Icon path={mdiBell} size={1} />,
              defaultBlack,
              notificacoesNaoVisualizadas.length
            )}
            onClick={() => history.push('/notificacoes')}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <RenderAppLogo show />
      {updateAppCenter()}
      <Grid container className={classes.gridTop}>
        <Grid item xs={12}>
          <Typography display="inline">Seja bem-vindo, </Typography>
          <Typography display="inline" className={classes.menuTitle}>
            {usuarioLogado.nome}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridItemMenuSubTitle}>
          <Icon path={mdiClockOutline} size={0.7} display="inline" />
          <Typography display="inline" className={classes.menuSubTitle}>
            {`Útimo Acesso: ${ultimoAcesso}`}
          </Typography>
        </Grid>
      </Grid>
      {cards}
      <Grid container className={classes.grid}>
        <Grid item xs={12} className={classes.gridItemRealizarCheckIn}>
          <Button
            customVariant="warning"
            onClick={() => history.push('/checkIn')}
            fullWidth
            size="large"
          >
            <Icon path={mdiMapMarkerRadius} size={1} color="black" />
            <Typography className={classes.mapIconTitle}>
              Realizar Check-in
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <NewAppBanner />
    </>
  );
};

export const MenuContainer = compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(Menu);
