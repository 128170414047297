const urlCepRest = 'https://cep-rest.tecsinapse.com.br/';

export const isValidCep = cep => {
  if (!cep || cep === '') {
    return false;
  }
  const validacep = /^[0-9]{8}$/;

  return validacep.test(cep);
};

export const getUrlPromisseOfCep = cep =>
  fetch(`${urlCepRest}endereco/buscar/${cep}`).then(response =>
    response.json()
  );
