import gql from 'graphql-tag';
import { genericQueryHoc } from '../../../../utils/crud/genericQueryHoc';

const name = 'dealersAcessoIndicadoresUsuarioLogado';

export const dealersAcessoIndicadoresUsuarioLogadoQueryGql = gql`
  query dealersAcessoIndicadoresUsuarioLogadoQuery {
    dealersAcessoIndicadoresUsuarioLogado {
      id
      nome
    }
  }
`;

// dataAccessor => data[nomeDaQuery][nomeDaQueryName]

export const [
  DealersAcessoIndicadoresUsuarioLogadoQuery,
  DealersAcessoIndicadoresUsuarioLogadoQueryHoc,
  DealersAcessoIndicadoresUsuarioLogadoQueryName,
  DealersAcessoIndicadoresUsuarioLogadoQueryDataName,
] = genericQueryHoc({
  name,
  gql: dealersAcessoIndicadoresUsuarioLogadoQueryGql,
  options: null,
  skip: null,
});
