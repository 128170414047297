import gql from 'graphql-tag';

const ModelosFrotaQueryGql = gql`
  query ModelosAtivosQuery {
    modelosAtivos {
      id
      nome
      subSegmento {
        id
      }
      marca {
        id
      }
      segmento {
        id
      }
    }
  }
`;

export default ModelosFrotaQueryGql;
