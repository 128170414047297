import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import marcasFrotaQuery from './marcasFrotaQuery.graphql';

const hoc = graphql(marcasFrotaQuery, {
  name: 'marcasFrotaQuery',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const MarcasFrotaQueryHOC = compose(
  hoc,
  displayLoadingState({
    name: 'marcasFrotaQuery',
    onCacheLoadOnlyQueryName: 'marcasFrota',
  })
);
