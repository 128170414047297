import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../utils/graphql/graphqlOfflineMutation';
import { UltimaRoteirizacaoByConsultorQueryGql } from './ultimaRoterizacaoByConsultorQuery';

const createRoteirizacaoMutationGql = gql`
  mutation roteirizar {
    roteirizar {
      id
      consultorExterno {
        id
        nome
      }
      urlMapa
      roteirizacaoRotas {
        id
        data
        mapLink
        rotaId
        roteirizacaoClientes {
          id
          data
          obrigatorio
          distanciaKm
          tempoDeslocamento
          tempoDuracao
          endereco
          clienteVo {
            id
            nome
            email
            cpfCnpj
            telefone
            celular
            telefoneComercial
            idDealer
            podeRealizarAtividadeFichaVisita
            ultimaAtividadeFichaVisitaAbertaPk
            consultorExternoPk
            endereco {
              id
              cep
              logradouro
              bairro
              numero
              complemento
              cidade
              estado {
                id
                nome
                sigla
              }
            }
            contatos {
              id
              nome
              telefone
              celular
              email
              cargo
              setor
              aceitaReceberEmailCam
              idCliente
              idSegmento
            }
            unidadesNegocioAtendidos {
              id
              descricao
            }
            clienteVisitadoNoPeriodoDaUltimaRoteirizacao
          }
        }
      }
    }
  }
`;

export const createRoteirizacaoMutation = graphqlOfflineMutation(
  createRoteirizacaoMutationGql,
  {
    name: 'criarRoteirizacao',
    mutationName: 'createRoteirizacaoMutationGql',
    options: {
      refetchQueries: [{ query: UltimaRoteirizacaoByConsultorQueryGql }],
    },
  }
);
