import { Drawer, ListItem, ListItemText, Switch } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import { CheckInOrdenacao } from '../enum/CheckInOrdenacaoEnum';
import {
  checkInFilterBottomSheetStyles,
  FullDisplayContainer,
} from '../styles';
import { SelectComponentStyled } from '../Select/RenderSelectField';
import { useUsuarioLogado } from '../data/useUsuarioLogado';

const useCheckInFilterStyles = makeStyles(checkInFilterBottomSheetStyles);

/* eslint-disable no-shadow */
const ordinations = [
  {
    name: 'Mais Recentes',
    value: CheckInOrdenacao.MAIS_RECENTES.name,
  },
  {
    name: 'Mais Antigos',
    value: CheckInOrdenacao.MAIS_ANTIGOS.name,
  },
];
/* es-lint-enable */

const CheckInFilterBottomSheetComponent = ({
  open,
  onClose,
  checkInSort,
  checkInApenasComImagem,
  checkInApenasVisitaRegistrada,
  checkInConsultorExterno,
  checkInDealer,
  setCheckInFiltro,
  vendedores,
  labelFiltroAtividadeRealizada,
  labelFiltroVendedores,
}) => {
  const classes = useCheckInFilterStyles();
  const [sortLocal, setSortLocal] = useState(checkInSort);
  const [checkInApenasComFotoLocal, setCheckInApenasComFotoLocal] = useState(
    checkInApenasComImagem
  );
  const [
    checkInApenasComVisitaLocal,
    setCheckInApenasComVisitaLocal,
  ] = useState(checkInApenasVisitaRegistrada);

  const onRequestSearch = () => {
    setCheckInFiltro(
      sortLocal,
      checkInApenasComFotoLocal,
      checkInApenasComVisitaLocal,
      checkInConsultorExternoLocal,
      checkInDealerLocal
    );
    onClose();
  };

  const { usuarioLogado = {} } = useUsuarioLogado();
  const userAdminOrManager =
    usuarioLogado.gerente || usuarioLogado.administrador;
  const [checkInDealerLocal, setCheckInDealerLocal] = useState(checkInDealer);
  const [
    checkInConsultorExternoLocal,
    setCheckInConsultorExternoLocal,
  ] = useState(checkInConsultorExterno);

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <div>
        <FullDisplayContainer>
          <ListItem>
            <Typography variant="h6">Filtros</Typography>
          </ListItem>
          {userAdminOrManager && (
            <ListItem>
              <ListItemText>
                <SelectComponentStyled
                  input={{
                    value: checkInDealerLocal,
                    onChange: e => {
                      setCheckInDealerLocal(e);
                    },
                  }}
                  placeholder="Dealer"
                  label="Dealer"
                  classes={classes}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value={null} key={null}>
                    Todos
                  </MenuItem>
                  {(usuarioLogado.dealersVendedorLogado || []).map(d => {
                    const { id, nome } = d;

                    return (
                      <MenuItem value={d} key={id}>
                        {nome}
                      </MenuItem>
                    );
                  })}
                </SelectComponentStyled>
              </ListItemText>
            </ListItem>
          )}
          {userAdminOrManager && (
            <ListItem>
              <ListItemText>
                <SelectComponentStyled
                  input={{
                    value: checkInConsultorExternoLocal,
                    onChange: e => {
                      setCheckInConsultorExternoLocal(e);
                    },
                  }}
                  placeholder={labelFiltroVendedores}
                  label={labelFiltroVendedores}
                  classes={classes}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value={null} key={null}>
                    Todos
                  </MenuItem>
                  {(vendedores || [])
                    .filter(
                      u =>
                        checkInDealerLocal &&
                        u &&
                        u.dealersGrupo &&
                        u.dealersGrupo
                          .map(d => d.id)
                          .includes(checkInDealerLocal.id)
                    )
                    .map(usuario => {
                      const { id, nome } = usuario;

                      return (
                        <MenuItem value={usuario} key={id}>
                          {nome}
                        </MenuItem>
                      );
                    })}
                </SelectComponentStyled>
              </ListItemText>
            </ListItem>
          )}
          <ListItem>
            <ListItemText>
              <SelectComponentStyled
                input={{
                  value: sortLocal,
                  onChange: e => {
                    setSortLocal(e);
                  },
                }}
                placeholder="Ordenar Por"
                label="Ordenar Por"
                classes={classes}
                fullWidth
                margin="normal"
              >
                {(ordinations || []).map(ordinations => {
                  const { value, name } = ordinations;

                  return (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  );
                })}
              </SelectComponentStyled>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.paddingTopBottomZero}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkInApenasComFotoLocal}
                  onChange={(e, v) => setCheckInApenasComFotoLocal(v)}
                />
              }
              label="Apenas com foto"
              labelPlacement="end"
            />
          </ListItem>
          <ListItem className={classes.paddingTopBottomZero}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkInApenasComVisitaLocal}
                  onChange={(e, v) => setCheckInApenasComVisitaLocal(v)}
                />
              }
              label={labelFiltroAtividadeRealizada}
              labelPlacement="end"
            />
          </ListItem>
          <ListItem>
            <Button
              customVariant="default"
              fullWidth
              onClick={() => {
                onRequestSearch();
              }}
            >
              Realizar Filtro
            </Button>
          </ListItem>
        </FullDisplayContainer>
      </div>
    </Drawer>
  );
};

export const CheckInFilterBottomSheet = CheckInFilterBottomSheetComponent;
