import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { IconButton, Button } from '@tecsinapse/ui-kit';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { mdiAlertCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';

import DialogActions from '@material-ui/core/DialogActions';
import { OpportunityVehiclesEditorContainer } from '../opportunity/editor/OpportunityVehiclesEditorContainer';
import { defaultGreen, defaultGrey } from '../app/globalStyle';
import {
  eventBackUpdateModeloInativo,
  eventNextUpdateModeloInativo,
  eventSaveUpdateModeloInativo,
} from '../utils/GAUtils';
import { LoadingContained } from '../utils/Loading';

const useStylesAtualizaModeloInativoDialog = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  styleDialogTitle: {
    boxShadow: '4px 4px 4px #f4f4f4',
  },
}));

const formStyle = {
  marginLeft: 0,
  marginRight: 0,
  flexGrow: 2,
};
const containerStyle = {
  height: '100%',
};
const divFlexStyle = {
  display: 'flex',
  flexGrow: 6,
  justifyContent: 'center',
};
const cardStyle = {
  backgroundColor: '#f4f4f4',
  marginTop: 8,
};
const style = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};
const style1 = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  marginBottom: '18px',
};
const style2 = {
  display: 'flex',
  alignItems: 'center',
};
const style3 = {
  marginLeft: '6px',
};
const style4 = {
  paddingTop: 0,
};

export const AtualizaModeloInativoDialog = ({
  open,
  handleClose,
  veiculos = [],
  idOportunidade,
  current,
  atualizaVeiculo,
}) => {
  const classes = useStylesAtualizaModeloInativoDialog();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // eslint-disable-next-line
  useEffect(() => {
    if (navigator.onLine !== isOnline) {
      setIsOnline(navigator.onLine);
    }
  });
  const isLast = veiculos.length - 1 === current;
  const veiculoInteresseAtual = veiculos[current];
  const nomeVersao =
    veiculoInteresseAtual?.modelo && veiculoInteresseAtual?.versao?.nome;
  const anoModelo = veiculoInteresseAtual?.anoModelo;
  const anoFabricacao = veiculoInteresseAtual?.anoFabricacao;
  const nomeModelo = veiculoInteresseAtual?.modelo?.nome;
  const idModeloInteresse = veiculoInteresseAtual?.id;

  const anoModeloFabricacao =
    anoModelo && anoFabricacao
      ? `${nomeVersao ? ' - ' : ''}${anoModelo} / ${anoFabricacao}`
      : '';

  const messagemAtualizacao = isOnline
    ? `Favor atualizar o veículo de interesse.`
    : 'Você está offline e não é possível atualizar os dados, encontre uma conexão com a internet e tente novamente.';

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle
        id="simple-dialog-title"
        onClose={handleClose}
        classes={{ root: classes.styleDialogTitle }}
      >
        Atualização de dados necessária
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={style4}>
        <div style={style}>
          <Grid container>
            <Grid item xs={12}>
              <div style={style1}>
                <div style={style2}>
                  <Icon path={mdiAlertCircleOutline} size={1.0} />
                  <Typography variant="h6" style={style3}>
                    Atenção
                  </Typography>
                </div>

                <Typography variant="subtitle2">
                  Essa proposta possui veículos inativos.
                  <br />
                  {messagemAtualizacao}
                </Typography>

                <Card style={cardStyle}>
                  <CardContent>
                    <Typography variant="h6">{nomeModelo}</Typography>
                    <Typography variant="caption">
                      {nomeVersao} {anoModeloFabricacao}
                    </Typography>
                  </CardContent>
                </Card>
              </div>

              <Divider />
            </Grid>
          </Grid>
          {/* TODO: Render the form to get updated data */}
          <div style={divFlexStyle}>
            {isOnline && (
              <OpportunityVehiclesEditorContainer
                idModeloInteresse={idModeloInteresse}
                idOportunidade={idOportunidade}
                variant="embedded"
                onSubmitCallBack={() => {
                  atualizaVeiculo(null, current);
                  isLast
                    ? eventNextUpdateModeloInativo()
                    : eventSaveUpdateModeloInativo();
                }}
                submitLabel={
                  !isLast
                    ? `PRÓXIMO (${current + 1} / ${veiculos.length})`
                    : `SALVAR  (${current + 1} / ${veiculos.length})`
                }
                formStyle={formStyle}
                containerStyle={containerStyle}
                backgroundColor={isLast ? defaultGreen : defaultGrey}
                textColor="white"
                LoadingComponent={LoadingContained}
              />
            )}
          </div>
        </div>
      </DialogContent>
      {!isOnline && (
        <DialogActions>
          <Button
            customVariant="success"
            onClick={() => {
              handleClose();
              eventBackUpdateModeloInativo();
            }}
            fullWidth
            size="large"
          >
            Voltar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
