import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DateTimePicker from '@tecsinapse/pickers/build/Picker/DateTimePicker';
import Button from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { keycloak } from '../../core/offline/kcOfflineStub';
import { globalStyle } from '../app/globalStyle';
import { getLocalDateTimeStringNoSeconds } from '../utils/dateUtils';
import { SubmitButton } from '../utils/FormUtils';

const style = { ...globalStyle };

export const AgendadorDialog = withStyles(style)(
  ({ open, handleOpen, onSelecionaHorario, atividade, showAlert, classes }) => {
    const [horario, setHorario] = useState(new Date());
    const [submitting, setSubmitting] = useState(false);
    const handleClose = useCallback(() => handleOpen(false), [handleOpen]);

    const handleSubmit = useCallback(async () => {
      const dataHoraFormatada = getLocalDateTimeStringNoSeconds(
        moment(horario)
      );

      if (onSelecionaHorario) {
        if (moment().isAfter(horario)) {
          showAlert({
            message:
              'Data/hora de agendamento não pode ser anterior à data/hora atual.',
            variant: 'warning',
            chip: true,
          });

          return;
        }

        setSubmitting(true);

        await onSelecionaHorario(keycloak.idTokenParsed.sub, dataHoraFormatada);

        showAlert({
          message: 'Lembrete de atividade adicionado com sucesso!',
          variant: 'success',
          chip: true,
        });

        setSubmitting(false);
        handleClose();
      }
    }, [horario, onSelecionaHorario, showAlert, handleClose]);

    return (
      <Dialog open={open} onClose={handleClose}>
        <div className={classes.form}>
          <DialogTitle id="simple-dialog-title">
            Lembrete de atividade
          </DialogTitle>
          <DialogContent>
            <DateTimePicker
              name="dataHoraLembrete"
              value={horario}
              onChange={setHorario}
              label="Agendar lembrete para"
              format="HH:mm DD/MM/y"
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <SubmitButton
              label="Salvar "
              autoFocus
              marginTop10={false}
              onClick={handleSubmit}
              submitting={submitting}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  }
);
