import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { Button, IconButton, EmptyState } from '@tecsinapse/ui-kit';

import { ArrowBack, ContactPhone, Email, Phone } from '@material-ui/icons';
import React from 'react';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { isNotUndefOrNull } from '@tecsinapse/es-utils';
import {
  cleanArray,
  flatten,
  isEmptyOrNull,
  isNotEmptyOrNull,
  removeDuplicates,
} from '@tecsinapse/es-utils/build';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SvgIcon from '@material-ui/core/SvgIcon';
import { callGAEventClickContato } from './GAUtils';
import { withSettingsState } from '../Settings/withSettingsState';
import { LoadingSpinner } from './LoadingSpinner';
import { ReactComponent as Whatsapp } from '../../images/whatsapp-logo.svg';
import { withConfirmarEntrarEmContatoModal } from '../providers/InformarContato/withConfirmarEntrarEmContatoModal';
import { TipoEntrarEmContato } from '../../core/Enums';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';

const style = {
  noWrapText: { wordWrap: 'break-word' },
};

export const clientGetEmailAndPhoneList = (client, usuarioLogado) => {
  const clientNonNull = client || {};
  const contatos = clientNonNull.contatos || [];
  const { telefone, celular, telefoneComercial, email } = clientNonNull;
  const contatosPhones = flatten(
    contatos.map(contato => [
      {
        id: contato.id,
        name: contato.nome,
        phone: contato.telefone,
        setor: contato.setor,
      },
      {
        id: contato.id,
        name: contato.nome,
        phone: contato.celular,
        setor: contato.setor,
      },
    ])
  ).filter(c => isNotEmptyOrNull(c.phone));

  const contatosEmail = contatos
    .map(contato => ({
      id: contato.id,
      name: contato.nome,
      email: contato.email,
      setor: contato.setor,
    }))
    .filter(c => isNotEmptyOrNull(c.email));

  const filtraSetoresAtendidosPorUsuario = contato => {
    const { setor } = contato;

    const isVendedor = usuarioLogado && usuarioLogado.vendedorCrm;

    if (isVendedor && isNotUndefOrNull(setor)) {
      return setor === 'VENDA';
    }

    return true;
  };

  const phones = cleanArray([telefone, celular, telefoneComercial]);
  const emails = cleanArray([email]);

  const emailList = removeDuplicates(
    [
      ...contatosEmail,
      ...emails.map(m => ({
        email: m,
      })),
    ],
    'email'
  ).filter(contato => filtraSetoresAtendidosPorUsuario(contato));
  const phoneList = removeDuplicates(
    [
      ...contatosPhones,
      ...phones.map(phone => ({
        phone,
      })),
    ],
    'phone'
  ).filter(contato => filtraSetoresAtendidosPorUsuario(contato));

  return {
    emailList,
    phoneList,
  };
};

const EntrarEmContatoDialogPureComponent = ({
  client = {},
  open,
  handleClose,
  goToEditClient,
  classes,
  loading,
  settings,
  selectedActivityId,
  selectedActivity,
  handleEntrarEmContatoClick,
  usuarioLogado,
  ...rest
}) => {
  if (loading) {
    return <LoadingSpinner />;
  }

  const { emailList, phoneList } = clientGetEmailAndPhoneList(
    client,
    usuarioLogado
  );

  const noContacts = isEmptyOrNull(phoneList) && isEmptyOrNull(emailList);
  const title = noContacts
    ? 'Cliente sem forma de contato'
    : 'Como você deseja entrar em contato com o cliente?';

  const content = noContacts ? (
    <EmptyState
      message="Não há nada por aqui, Deseja alterar os dados do cliente?"
      IconComponent={ContactPhone}
    />
  ) : (
    <List>
      {phoneList.map(({ id, phone, name }) => (
        <ListItem
          key={phone}
          button
          component="a"
          href={`tel:${settings.operatorCode || ''}${phone
            .replace(/\D/g, '')
            .replace(/^0+/, '')}`}
          target="_self"
          onClick={() => {
            handleEntrarEmContatoClick({
              selectedActivity,
              selectedActivityId,
              cliente: client,
              ultimaAtividadeFichaVisitaAbertaId:
                client.ultimaAtividadeFichaVisitaAbertaId,
              idClient: client.id,
              contatoId: id,
              tipoContato: TipoEntrarEmContato.LIGACAO.name,
            });

            return callGAEventClickContato('telefone');
          }}
        >
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText primary={phone} secondary={name} />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                handleEntrarEmContatoClick({
                  selectedActivity,
                  selectedActivityId,
                  cliente: client,
                  ultimaAtividadeFichaVisitaAbertaId:
                    client.ultimaAtividadeFichaVisitaAbertaId,
                  contatoId: id,
                  idClient: client.id,
                  tipoContato: TipoEntrarEmContato.ONLINE.name,
                });
                callGAEventClickContato('mensagem whatsapp');
                window.location = `https://api.whatsapp.com/send?phone=55${phone.replace(
                  /\D/g,
                  ''
                )}`;
              }}
            >
              <SvgIcon>
                <Whatsapp />
              </SvgIcon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      {emailList.map(({ name, email, id }) => (
        <ListItem
          key={email}
          button
          component="a"
          href={`mailto:${email}`}
          target="_self"
          onClick={() => {
            handleEntrarEmContatoClick({
              selectedActivity,
              selectedActivityId,
              cliente: client,
              ultimaAtividadeFichaVisitaAbertaId:
                client.ultimaAtividadeFichaVisitaAbertaId,
              idClient: client.id,
              contatoId: id,
              tipoContato: TipoEntrarEmContato.ONLINE.name,
            });

            return callGAEventClickContato('email');
          }}
        >
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText
            primary={email}
            secondary={name}
            className={classes.noWrapText}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Dialog open={open || false} onClose={handleClose} fullScreen>
      <DialogTitle id="simple-dialog-title">
        <IconButton
          classes={{ root: classes.iconBackButton }}
          onClick={handleClose}
          color="inherit"
        >
          <ArrowBack />
        </IconButton>
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {noContacts ? (
          <>
            <Button
              onClick={() => goToEditClient({ client })}
              color="primary"
              autoFocus
            >
              Sim
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancelar
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} color="primary" autoFocus>
            Fechar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const EntrarEmContatoDialog = compose(
  withStyles(style),
  withRouter,
  withHandlers({
    goToEditClient: ({ history }) => ({ client }) => {
      history.push(`/editarCliente/${client.id}`);
    },
  }),
  withSettingsState,
  withUsuarioLogado,
  withConfirmarEntrarEmContatoModal
)(EntrarEmContatoDialogPureComponent);
