import React from 'react';
import { Grid } from '@material-ui/core';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';
import { updateAppTitle } from '../utils/uiUtils';

const AdminComponent = ({ usuarioLogado }) => {
  if (usuarioLogado && !usuarioLogado.usuarioInterno) {
    throw new Error('Acesso negado');
  }

  const style = {
    margin: '20px',
  };

  return (
    <div style={style}>
      {updateAppTitle('Área restrita')}
      <Grid container>
        <Grid item>{usuarioLogado.nome}</Grid>
      </Grid>
    </div>
  );
};

export const Admin = withUsuarioLogado(AdminComponent);
