import Big from 'big.js';
import pick from 'lodash.pick';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps } from 'recompose';
import {
  getFirstFromSingleElementArrayNotNull,
  isEmptyOrNull,
} from '@tecsinapse/es-utils/build';
import { graphql } from '@apollo/react-hoc';
import { getAlertContext } from '../../../utils/alertContext';
import { displayLoadingStateQueries } from '../../../utils/displayLoadingState';
import {
  connectToFormFields,
  validateRequired,
} from '../../../utils/FormUtils';
import { PaymentQuery } from '../PaymentMethodQuery';
import { MetodosPagamentoAtivosQueryHOC } from './data/query/MetodosPagamentoAtivosQuery';
import { ProposalPaymentEditor } from './ProposalPaymentEditor';
import { OportunidadeStatusQuery } from './data/query/OportunidadeStatusQuery';
import { podeEditarProposta } from '../../../opportunity/oportunidadeRules';
import { reduxFormAutoFocus } from '../../../utils/reduxFormAutoFocus';
import { PaymentMutation } from './data/query/PaymentMutation';
import { FinanceirasQueryHOC } from './data/query/FinanceirasQuery';
import GetOpportunityVehiclesQueryGql from '../../../opportunity/data/queries/GetOpportunityVehiclesQueryGql.graphql';

const withQuery = graphql(GetOpportunityVehiclesQueryGql, {
  options: ({ oportunidadeStatus }) => ({
    variables: { id: oportunidadeStatus.oportunidade.id || 0 },
  }),
});

const enhance = compose(
  withRouter,
  PaymentQuery,
  OportunidadeStatusQuery,
  withQuery,
  MetodosPagamentoAtivosQueryHOC,
  FinanceirasQueryHOC,
  PaymentMutation,
  displayLoadingStateQueries([
    'formaPagamento',
    'oportunidadeStatus',
    'metodosPagamentoAtivos',
  ]),
  withProps(({ data: { oportunidade } }) => {
    return {
      temModelosSemPrecoUnitario: !!oportunidade.modelosInteresse.find(
        modelo => modelo.emNegociacao && !modelo.precoUnitario
      ),
    };
  }),
  withProps(({ formaPagamento, metodosPagamentoAtivos, match }) => {
    let initialValues = {};
    const newProps = {};

    if (formaPagamento && formaPagamento.proposta) {
      newProps.disabledForm =
        formaPagamento.proposta.status &&
        !formaPagamento.proposta.status.podeEditarProposta;

      if (formaPagamento.proposta.formasPagamento) {
        const valorTotal = formaPagamento.proposta.valorTotalVeiculosNegociados;
        const valorTotalBig = Big(valorTotal);

        const formaPagamentoSelecionada = getFirstFromSingleElementArrayNotNull(
          formaPagamento.proposta.formasPagamento.filter(
            f => f.id === match.params.id
          )
        );

        newProps.summedAlreadyAddedPaymentMethods = formaPagamento.proposta.formasPagamento.reduce(
          (acc, f) => {
            if (f.id !== match.params.id) {
              acc.valor = acc.valor.plus(Big(f.valor));
              acc.percentual = acc.percentual.plus(Big(f.percentual));
            }

            return acc;
          },
          { valor: Big(0), percentual: Big(0) }
        );
        initialValues = {
          ...formaPagamentoSelecionada,
        };

        if (formaPagamentoSelecionada.metodoPagamento) {
          initialValues.idMetodoPagamento =
            formaPagamentoSelecionada.metodoPagamento.id;
        }

        if (formaPagamentoSelecionada.financeira) {
          initialValues.idFinanceira = formaPagamentoSelecionada.financeira.id;
        }

        if (isEmptyOrNull(initialValues.valor)) {
          const valorBig = valorTotalBig.minus(
            newProps.summedAlreadyAddedPaymentMethods.valor
          );

          initialValues.valor = Number(valorBig) ? valorBig.toString() : null;
          initialValues.percentual = Number(valorBig)
            ? valorBig.div(valorTotalBig).times(100).toFixed(2).toString()
            : null;
        }
      }
    }

    if (
      metodosPagamentoAtivos &&
      metodosPagamentoAtivos.metodosPagamentoAtivos
    ) {
      newProps.mapMetodosPagamento = metodosPagamentoAtivos.metodosPagamentoAtivos.reduce(
        (acc, metodo) => ({ ...acc, [metodo.id]: metodo }),
        {}
      );
    }

    return {
      ...newProps,
      initialValues: {
        ...initialValues,
        idProposta: match.params.idProposta,
        valorTotal: formaPagamento.proposta.valorTotalVeiculosNegociados,
      },
    };
  }),
  withProps(
    ({
      oportunidadeStatus: { oportunidade } = {},
      formaPagamento: { proposta },
    }) => ({
      disabledForm: !podeEditarProposta(proposta, oportunidade),
    })
  ),
  getAlertContext,
  reduxFormAutoFocus({
    // a unique name for the form
    form: 'SavePayment',
    validate: validateRequired(['valor', 'percentual', 'idMetodoPagamento']),
    onSubmit: (values, dispatch, { savePayment, history }) => {
      const submitValues = pick(values, [
        'id',
        'idProposta',
        'idFinanceira',
        'idMetodoPagamento',
        'percentual',
        'valor',
        'numeroParcelas',
      ]);

      const variables = {
        input: {
          ...submitValues,
          numeroParcelas: Number(submitValues.numeroParcelas) || null,
        },
      };

      return savePayment({
        variables,
      }).then(() => history.goBack());
    },
  }),
  connectToFormFields([
    'idFinanceira',
    'idMetodoPagamento',
    'valor',
    'percentual',
  ]),
  withHandlers({
    validateIdMetodoPagamento: ({ mapMetodosPagamento }) => (
      idMetodoPagamento,
      { numeroParcelas }
    ) => {
      if (isEmptyOrNull(idMetodoPagamento)) {
        return 'Campo obrigatório';
      }

      const metodo = mapMetodosPagamento[idMetodoPagamento];

      if (metodo && metodo.parcelada && isEmptyOrNull(numeroParcelas)) {
        return 'Por favor, preencha o número de parcelas';
      }

      return undefined;
    },
    validatePricesValue: ({
      initialValues: { valorTotal },
      summedAlreadyAddedPaymentMethods,
    }) => (_, values) => {
      const { valor } = values;
      const valorTotalBig = Big(valorTotal);
      const valorAteAgora = summedAlreadyAddedPaymentMethods.valor;
      const valorBig = Big(valor || 0);

      if (valorBig.add(valorAteAgora).gt(valorTotalBig)) {
        return 'O valor fornecido extrapola o valor total da proposta';
      }

      return undefined;
    },
    recalculateFields: ({ initialValues: { valorTotal }, change }) => ({
      percentual,
      valor,
    }) => {
      const valorTotalBig = Big(valorTotal);

      if (valor && valorTotal) {
        const valorBig = Big(valor);
        const valorPercentual = valorTotal
          ? valorBig.div(valorTotalBig).times(100).toString()
          : '0,00';

        change('percentual', valorPercentual);
      } else {
        change('percentual', null);
      }

      if (percentual) {
        const percentualBig = Big(percentual);

        change(
          'valor',
          valorTotalBig.times(percentualBig.div(100)).toFixed(2).toString()
        );
      } else if (percentual === '') {
        change('valor', null);
      }
    },
  })
);

export const ProposalPaymentEditorContainer = enhance(ProposalPaymentEditor);
