import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../../utils/graphql/graphqlOfflineMutation';
import salvarContatoMutationGql from './salvarContatoMutationGql.graphql';

export const SalvarContatoMutation = graphqlOfflineMutation(
  salvarContatoMutationGql,
  {
    name: 'salvarContato',
    mutationName: 'salvarContatoMutationGql',
    description: async ({
      variables: {
        contato: { id, nome: nomeContato, idCliente },
      },
      apolloClient,
    }) => {
      let nomeCliente;

      try {
        const { nome } = await apolloClient.readFragment({
          id: `Cliente:${idCliente}`,
          fragment: gql`
            fragment ClienteNomeFragment on Cliente {
              id
              nome
            }
          `,
        });

        nomeCliente = nome;
      } catch (e) {
        nomeCliente = '';
      }

      return id == null
        ? `Adição de novo contato de nome ${nomeContato} ao cliente ${nomeCliente}`
        : `Edição de dados do contato de nome ${nomeContato} do cliente ${nomeCliente}`;
    },
  }
);
