import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@tecsinapse/ui-kit';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  getFirstElementOfArray,
  getFirstFromSingleElementArrayNotNull,
  isNotUndefOrNull,
} from '@tecsinapse/es-utils';
import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';
import { ConfirmarEntrarEmContatoModalContext } from './ConfirmarEntrarEmContatoModalContext';
import { useUsuarioLogado } from '../../../hooks/useUsuarioLogado';
import { informarContatoClienteMutation } from '../../Client/clienteBottomSheet/data/informarContatoClienteMutation';
import { postInformCallActions } from '../../Client/clienteBottomSheet/postInformCallActions';
import {
  ClientCreateOptionEnum,
  TipoEntrarEmContato,
} from '../../../core/Enums';
import { InformarContatoDialog } from '../../utils/InformarContatoDialog';
import TiposDeContatoQueryGql from '../../RealizarAtividade/data/queries/TiposDeContatoQueryGql.graphql';
import { createFichaVisitaAtividadeMutation } from '../../opportunity/create/ClientCreateOptionsMutations';
import { allowActionsModeloAtivo } from '../../Atividade/atividadeFunctions';
import { withAtualizaModeloInativo } from '../../AtualizaModeloInativo/withAtualizaModeloInativo';

const ConfirmarEntrarEmContatoModalPureComponent = ({
  children,
  createModal,
  informarContatoCliente,
  criarFichaVisitaAtividade,
  atualizaModeloInativoEAbreDialog,
}) => {
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [contatoId, setContatoId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [tipoContato, setTipoContato] = useState(null);
  const [atividades, setAtividades] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [showInformCallModal, setShowInformCallModal] = useState(null);
  const history = useHistory();
  const { usuarioLogado = {} } = useUsuarioLogado();
  const { data: { tiposContato = [] } = {} } = useQuery(TiposDeContatoQueryGql);

  const handleClose = () => setShowDialog(false);

  const handleEntrarEmContatoClick = ({
    cliente: clienteInput,
    selectedActivityId: selectedActivityIdInput,
    selectedActivity: selectedActivityInput,
    ultimaAtividadeFichaVisitaAbertaId,
    tipoContato: tipoContatoInput,
    contatoId: contatoIdInput = null,
  }) => {
    setSelectedActivityId(
      selectedActivityIdInput || ultimaAtividadeFichaVisitaAbertaId
    );
    setSelectedActivity(selectedActivityInput);
    setCliente(clienteInput);
    setTipoContato(tipoContatoInput);
    setContatoId(contatoIdInput);

    // Gerente não pode realizar atividade
    if (!usuarioLogado.gerente) {
      setShowDialog(true);
    }
  };

  const handleConfirmarEntrarEmContatoClick = () => {
    allowActionsModeloAtivo(selectedActivity);

    let tipoContatoId = null;

    if (tipoContato) {
      const tipoContatoName = TipoEntrarEmContato.enumValueOf(tipoContato);
      const tiposContatos = tiposContato.filter(
        tc => tc.nome === tipoContatoName.nome
      );
      const tipoContatoResolved = getFirstElementOfArray(tiposContatos);

      tipoContatoId = tipoContatoResolved ? tipoContatoResolved.id : null;
    }

    if (isNotUndefOrNull(selectedActivityId)) {
      history.push(
        `/realizarAtividade/${selectedActivityId}/${contatoId}/${tipoContato}`
      );
      setShowDialog(false);

      return;
    }

    if (usuarioLogado.consultorExterno) {
      criarFichaVisitaAtividade({
        variables: { idCliente: cliente.id },
      }).then(
        ({
          data: {
            fichaVisitaAgendarAtividade: { id },
          },
        }) => {
          history.push(`/realizarAtividade/${id}/${contatoId}/${tipoContato}`);
        }
      );
      setShowDialog(false);

      return;
    }

    const unidadeNegocioUnica = getFirstFromSingleElementArrayNotNull(
      (cliente && cliente.unidadesNegocioAtendidos) || []
    );

    if (usuarioLogado.vendedorCrm) {
      if (cliente && (cliente.unidadesNegocioAtendidos || []).length > 1) {
        createModal({
          id: cliente.id,
          clientCreateOption: ClientCreateOptionEnum.INFORM_CALL,
          message: null,
          contatoId,
          tipoContato,
          fullWidth: true,
        });
      } else {
        informarContatoCliente({
          variables: {
            idCliente: cliente.id,
            idUnidadeNegocio:
              (unidadeNegocioUnica && unidadeNegocioUnica.id) ||
              cliente.idUnidadeNegocio,
            idTipoContato: tipoContatoId,
          },
        }).then(
          postInformCallActions({
            history,
            createModal,
            setAtividades,
            setShowInformCallModal,
            contatoId,
            tipoContato,
            atualizaModeloInativoEAbreDialog,
          })
        );
      }
    }

    setShowDialog(false);
  };

  return (
    <ConfirmarEntrarEmContatoModalContext.Provider
      value={{
        handleEntrarEmContatoClick,
      }}
    >
      {children}

      <InformarContatoDialog
        client={cliente}
        atividades={atividades}
        open={showInformCallModal}
        handleClose={() => {
          setShowInformCallModal(false);
        }}
      />

      <Dialog onClose={handleClose} open={showDialog}>
        <DialogTitle id="alert-dialog-title">
          <strong>Conseguiu contato?</strong>
        </DialogTitle>

        <DialogContent>
          <Typography variant="subtitle2">
            <strong>Deseja informar contato com o cliente?</strong>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleConfirmarEntrarEmContatoClick}
            customVariant="success"
            autoFocus
          >
            Sim
          </Button>

          <Button onClick={handleClose} customVariant="warning" autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmarEntrarEmContatoModalContext.Provider>
  );
};

export const ConfirmarEntrarEmContatoModalProvider = compose(
  informarContatoClienteMutation,
  createFichaVisitaAtividadeMutation,
  withAtualizaModeloInativo,
  getContext({
    createModal: PropTypes.func,
  })
)(ConfirmarEntrarEmContatoModalPureComponent);
