import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import FinanceirasQueryGql from './FinanceirasQueryGql.graphql';
import { displayLoadingState } from '../../../../../utils/displayLoadingState';

export const FinanceirasQuery = graphql(FinanceirasQueryGql, {
  name: 'financeirasQuery',
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
});

export const FinanceirasQueryHOC = compose(
  FinanceirasQuery,
  displayLoadingState({
    name: 'financeirasQuery',
    onCacheLoadOnlyQueryName: 'financeirasAtivas',
  })
);
