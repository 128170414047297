import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { defaultGrey } from '../../../../../../app/globalStyle';

export const useSeletorDeMarcaStyles = makeStyles({
  timeSelect: {
    fontSize: '12px',
  },
  marca: {
    color: defaultGrey,
    fontFamily: 'Roboto',
    fontWeight: 500,
    paddingRight: props =>
      props && props.marcas && props.marcas.length === 1 && '0 !important',
  },
});

export const SeletorDeMarca = ({ marcas, marca, setMarca }) => {
  const classes = useSeletorDeMarcaStyles({ marcas });
  const disabled = marcas && marcas.length === 1;
  const marcasOrNull = marcas || [];
  const selectProps = {
    classes: {
      root: classes.marca,
    },
    disableUnderline: true,
    IconComponent: disabled ? () => <></> : undefined,
  };
  const onChange = e => setMarca(e.target.value);
  const inputProps = {
    className: classes.timeSelect,
  };

  return (
    <TextField
      select
      value={marca}
      disabled={disabled}
      className={classes.timeSelect}
      onChange={onChange}
      SelectProps={selectProps}
      InputProps={inputProps}
    >
      {marcasOrNull.map(m => (
        <MenuItem key={m.id} value={m.id}>
          {m.nome}
        </MenuItem>
      ))}
    </TextField>
  );
};
