import React from 'react';
import { Typography } from '@material-ui/core';
import { Delete, Edit, PlusOne, DirectionsCar } from '@material-ui/icons';
import { Table } from '@tecsinapse/table/build/Table/Table';
import { makeStyles } from '@material-ui/styles';
import { EmptyState } from '@tecsinapse/ui-kit';

import { FloatingButton } from '../../../utils/FloatingButton';
import {
  eventAddFleet,
  eventAddQuantityFleet,
  eventEditFleet,
  eventRemoveFleet,
} from '../../../utils/GAUtils';

const useStyle = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ClienteFrota = ({
  frota,
  id,
  editOnClick,
  addOnClick,
  removeOnClick,
  addQuantidadeClick,
  idSegmentoUnico,
}) => {
  const classes = useStyle();

  const columns = [
    {
      title: 'Marca',
      field: 'marca.nome',
    },
    {
      title: 'Modelo',
      field: 'modelo.nome',
      customRender: row => (
        <div>
          <Typography variant="subtitle2" color="textPrimary">
            {row.modelo && row.modelo.nome ? row.modelo.nome : 'Não informado'}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Quantidade',
      field: 'quantidade',
    },
  ];
  const columnSegmento = [
    {
      title: 'Segmento',
      field: 'segmento.descricao',
    },
  ];

  const contactActions = [
    {
      icon: <Edit />,
      tooltip: 'Editar Frota',
      label: 'Editar Frota',
      onClick: vehicle => {
        eventEditFleet();
        editOnClick(id, vehicle);
      },
    },
    {
      icon: <Delete />,
      tooltip: 'Deletar Frota',
      label: 'Deletar Frota',
      onClick: vehicle => {
        eventRemoveFleet();
        removeOnClick(vehicle);
      },
    },
    {
      icon: <PlusOne />,
      tooltip: 'Incrementar Quantidade',
      label: 'Incrementar Quantidade',
      onClick: vehicle => {
        eventAddQuantityFleet();
        addQuantidadeClick(vehicle);
      },
    },
  ];

  return (
    <>
      <Table
        columns={idSegmentoUnico ? columns : [...columnSegmento, ...columns]}
        data={frota}
        rowId={row => row.id}
        onRowClick={itemFrotaSelecionado => {
          eventEditFleet();
          editOnClick(id, itemFrotaSelecionado);
        }}
        actions={contactActions}
        classes={classes}
        empytStateComponent={
          <EmptyState
            IconComponent={DirectionsCar}
            message="Não há nenhum veículo por aqui ainda."
          />
        }
      />
      <FloatingButton
        onClick={() => {
          eventAddFleet();
          addOnClick(id);
        }}
      />
    </>
  );
};
