/* eslint no-console: 0 */
import localforage from 'localforage';

export function getLocation(registraLocalizacao2) {
  if (navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(registraLocalizacao2);
  }
  console.log('Geo Location not supported by browser');

  return false;
}

export const registraLocalizacao = setLocalizacao => position => {
  setLocalizacao({
    longitude: position.coords.longitude,
    latitude: position.coords.latitude,
  });
};

export const printLocation = position => {
  console.log({
    longitude: position.coords.longitude,
    latitude: position.coords.latitude,
  });
};

export const geolocationAllowed = callBack => {
  localforage.ready().then(() => {
    localforage.getItem('geolocationAllowed').then(podeUsar => {
      const allowed = podeUsar === null ? false : podeUsar;

      if (callBack) {
        callBack(allowed);
      }
    });
  });
};

export const geoLocationDefinida = async callback => {
  await localforage.ready();
  const isDefinido = await localforage.getItem('geolocationAllowed');

  callback && callback(isDefinido !== null);
};
