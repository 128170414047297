import PropTypes from 'prop-types';
import React from 'react';
import { Grid, List, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styled from 'styled-components';
import { Button } from '@tecsinapse/ui-kit';
import {
  RenderAppLogo,
  ShowBackButton,
  updateAppTitle,
} from '../../utils/uiUtils';
import { ErrorMessages } from './ErrorMessages';
import { useRealizarAtividadePosAcaoInfos } from './hooks/useRealizarAtividadePosAcaoInfos';

const DivFlexGrow = styled.div`
  flex-grow: 1;
`;

const DivFlex = styled.div`
  display: flex;
  flex-grow: 1;

  flex-direction: column;
`;

const GridPadding = styled(Grid)`
  padding: 12px;
`;

const RealizarAtividadePosAcao = ({
  activityBranch = {},
  location,
  history,
  urlRetorno,
}) => {
  const { msgErro, tab } = location && location.state;

  const {
    idOrigem,
    possuiVeiculoComPrecoUnitarioNaoInformado,
    showPagamentoPendente,
    showVeiculosInteresseSemModelo,
  } = useRealizarAtividadePosAcaoInfos({ activityBranch });

  const handleRedirect = () => {
    const routerPayload = {
      tab:
        tab === 0 ||
        (!tab &&
          (possuiVeiculoComPrecoUnitarioNaoInformado ||
            showVeiculosInteresseSemModelo ||
            (showVeiculosInteresseSemModelo && showPagamentoPendente)))
          ? 0
          : 1,
      redirectHome: true,
      urlRetorno,
    };

    history.push(`/oportunidade/${idOrigem}`, routerPayload);
  };

  const handleClose = () => {
    if (urlRetorno) {
      history.push(`/${decodeURIComponent(urlRetorno)}`, { tab: 0 });
    } else {
      history.push('/agenda');
    }
  };

  return (
    <DivFlex>
      <RenderAppLogo />
      {updateAppTitle('Informações Pendentes')}
      <ShowBackButton IconComponent={Close} action={() => handleClose()} />
      <GridPadding container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            É necessário preencher o(s) seguinte(s) dado(s):
          </Typography>
          <List>
            <ErrorMessages
              data-testid="list-item-container"
              msgErro={msgErro}
              showVeiculosInteresseSemModelo={showVeiculosInteresseSemModelo}
              showPagamentoPendente={showPagamentoPendente}
              possuiVeiculoComPrecoUnitarioNaoInformado={
                possuiVeiculoComPrecoUnitarioNaoInformado
              }
            />
          </List>
        </Grid>
      </GridPadding>
      <DivFlexGrow />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Deseja corrigir agora?</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button customVariant="success" fullWidth onClick={handleRedirect}>
            <Typography>Sim</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" fullWidth onClick={handleClose}>
            <Typography>Não</Typography>
          </Button>
        </Grid>
      </Grid>
    </DivFlex>
  );
};

RealizarAtividadePosAcao.propTypes = {
  activityBranch: PropTypes.object.isRequired,
};

export { RealizarAtividadePosAcao };
