import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import tiposVendaPerdidaQueryGql from './tiposVendaPerdidaQueryGql.graphql';
import { displayLoadingState } from '../../../utils/displayLoadingState';

export const tiposVendaPerdidaQuery = graphql(tiposVendaPerdidaQueryGql, {
  name: 'tiposVendaPerdidaQuery',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const TiposVendaPerdidaQueryHOC = compose(
  tiposVendaPerdidaQuery,
  displayLoadingState({
    name: 'tiposVendaPerdidaQuery',
    onCacheLoadOnlyQueryName: 'tiposVendaPerdida',
  })
);
