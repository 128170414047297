import { isEmptyOrNull } from '@tecsinapse/es-utils/build/object';
import {
  StatusOportunidade,
  TemperaturaNegociacao,
  TipoOrigemAtividade,
} from '../../../core/Enums';
import { getColorOfTemperatura } from '../../utils/cardStyleUtils';
import {
  cardGreen,
  cardGreenDark,
  cardRedDark,
  defaultGreen,
  defaultGrey,
  defaultGreyBackgroundChip,
  defaultGreyTextChip,
  defaultOrange,
  globalStyle,
} from '../../app/globalStyle';

export function getDadosOportunidade(temperatura, responsavel) {
  const dadosOportunidade = {
    responsavel,
  };

  if (temperatura !== null) {
    dadosOportunidade.temperatura = {
      nome: temperatura && TemperaturaNegociacao.enumValueOf(temperatura).nome,
      cor: getColorOfTemperatura(temperatura),
    };
  }

  return dadosOportunidade;
}

export function cardStyles(theme) {
  return {
    icon: {
      width: '20px',
      heigt: '20px',
      paddingLeft: '5px',
    },
    gridFlex: {
      display: 'flex',
      alignItems: 'center',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    opportunityTemperature: {
      height: '2px',
      width: '100%',
    },
    title: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    avatar: {
      fontWeight: '400',
      width: '32px',
      height: '32px',
      position: 'initial',
    },
    avatarWithMargin: {
      width: '24px',
      height: '24px',
      padding: '0px',
    },
    iconSegmento: {
      width: 24,
      fill: 'white',
    },
    chipMargin: {
      marginTop: `${theme.spacing(0.5)}px`,
      marginRight: `${theme.spacing(1)}px`,
      height: '20px',
      weight: '20px',
      fontWeight: 500,
      fontSize: '12px',
    },
    chipTitle: {
      paddingLeft: '5px',
      paddingRight: '5px',
      fontWeight: 500,
    },
    chipDoneLabel: {
      marginLeft: '1px',
    },
    chipSeller: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    chipSellerBackground: {
      backgroundColor: defaultGreyBackgroundChip,
      color: defaultGreyTextChip,
    },
    chipDone: {
      marginTop: '-1px',
      fontSize: '10px',
      height: 'auto',
      padding: '1px',
      color: '#FFF',
      marginLeft: '6px',
      backgroundColor: defaultGreen,
    },
    ...globalStyle(theme),
  };
}

export function colorOfAvatar({ tipoOrigem } = {}) {
  let color = defaultGrey;

  if (tipoOrigem === 'OPORTUNIDADE') {
    color = defaultOrange;
  } else if (tipoOrigem === 'FICHA_VISITA') {
    color = defaultGrey;
  } else if (tipoOrigem === 'PROSPECCAO') {
    color = defaultGrey;
  }

  return { backgroundColor: color };
}

export function isOportunidade({ tipoOrigem } = {}) {
  return tipoOrigem === 'OPORTUNIDADE';
}

export function generateOpportunityId(activity) {
  return isOportunidade(activity)
    ? `realizar-atividade-button-${activity.id}`
    : `realizar-atividade-button-não-oportunidade${activity.id}`;
}

export const labelOfActivity = ({ tipo, tipoOrigem } = {}) =>
  tipoOrigem != null ? tipoOrigem[0] : tipo[0];

export function getStyleOfCard(offlinePendente, finalizado, atrasada) {
  const styleCardAtrasado = {
    customSubtitleColor: 'error',
    backgroundColor: 'rgba(252,236,235, 1)',
  };

  const styleCardSyncPendente = {
    customSubtitleColor: 'primary',
    backgroundColor: 'oldlace',
  };

  const styleCardDone = {
    customSubtitleColor: 'primary',
    backgroundColor: 'rgba(76,175,80, 0.1)',
  };

  // Default
  let styleOfComp = {
    customSubtitleColor: 'primary',
    backgroundColor: '#FFFFFF',
  };

  if (finalizado) {
    styleOfComp = styleCardDone;
  }

  if (atrasada) {
    styleOfComp = styleCardAtrasado;
  }

  if (offlinePendente) {
    styleOfComp = styleCardSyncPendente;
  }

  return styleOfComp;
}

export const labelOfActivityNew = (
  { tipo, tipoOrigem, unidadeNegocio } = {},
  showSegments,
  crmProspeccaoNomePattern
) => {
  const unidadeNegocioDescricao =
    showSegments && unidadeNegocio ? unidadeNegocio.descricao : null;

  const origemDescricao =
    tipoOrigem && TipoOrigemAtividade.enumValueOf(tipoOrigem)
      ? TipoOrigemAtividade.enumValueOf(tipoOrigem).descricao(
          crmProspeccaoNomePattern
        )
      : null;

  const label =
    unidadeNegocioDescricao != null ? unidadeNegocioDescricao : origemDescricao;

  return label || 'Outros';
};

export const isProspeccao = activity =>
  activity.tipoOrigem === TipoOrigemAtividade.PROSPECCAO.name;

export const styleCardAlerta = {
  customSubtitleColor: defaultOrange,
  border: '1px solid rgba(252, 166, 64, .2)',
  boxShadow: 'none',
  borderLeft: `solid 4px ${defaultOrange}`,
};

export const styleCardAtrasado = {
  customSubtitleColor: cardRedDark,
  border: '1px solid rgba(237, 66, 66, .2)',
  boxShadow: 'none',
  borderLeft: `solid 4px ${cardRedDark}`,
};

export const styleCardDone = {
  customSubtitleColor: cardGreenDark,
  border: `1px solid ${cardGreen}`,
  boxShadow: 'none',
  borderLeft: `solid 4px ${cardGreenDark}`,
};

export function getStyleOfCardNew(
  offlinePendente,
  finalizado,
  atrasada,
  semContato
) {
  const styleCardSyncPendente = {
    customSubtitleColor: '#fca640',
    border: '1px solid rgba(252, 166, 64, .2)',
    boxShadow: 'none',
    borderLeft: 'solid 4px #fca640',
  };

  // Default
  let styleOfComp = {
    customSubtitleColor: 'primary',
    border: '1px solid rgba(108, 104, 98, .2)',
    boxShadow: 'none',
    borderLeft: '1px solid rgba(108, 104, 98, .2)',
  };

  const styleCardSemContato = {
    customSubtitleColor: 'primary',
    border: '1px solid rgb(217, 217, 217)',
    boxShadow: 'none',
    borderLeft: 'solid 4px #616161',
  };

  if (semContato) {
    styleOfComp = styleCardSemContato;
  }

  if (finalizado) {
    styleOfComp = styleCardDone;
  }

  if (atrasada) {
    styleOfComp = styleCardAtrasado;
  }

  if (offlinePendente) {
    styleOfComp = styleCardSyncPendente;
  }

  return styleOfComp;
}

export function getDescricaoTipoOrigemProspeccao(
  activity,
  tiposOrigemOportunidade
) {
  if (!isProspeccao(activity)) {
    return '';
  }

  const { tipoOrigemOportunidade: tipo = null } = activity;

  if (!tipo) {
    return '';
  }

  const tipoOrigemOportunidade = (tiposOrigemOportunidade || []).find(
    o => o.id === tipo
  );

  return tipoOrigemOportunidade ? tipoOrigemOportunidade.descricao : '';
}

export function isSemContato(activity) {
  return (
    isEmptyOrNull(activity?.resultadoProspeccao) ||
    (activity?.tipoOrigem === 'OPORTUNIDADE' &&
      activity?.statusOportunidade === StatusOportunidade.NOVO.name)
  );
}
