/* eslint  react/require-default-props: 0 */
import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, ValueSlider } from '@tecsinapse/ui-kit';
import { mdiDotsVertical, mdiMedal } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import {
  ExcellenceClubAction,
  ExcellenceClubPrizeContainer,
  ExcellenceClubPrizeText,
  ExcellenceClubSliderTitle,
  ExcellenceClubTitle,
  ExcellenceClubTitleContainer,
} from '../../utils/ExcellenceClubStyledComponents/ExcellenceClubStyledComponents';
import { ExcellenceClubItem } from '../../utils/ExcellenceClubItem/ExcellenceClubItem';
import {
  styleCardAtrasado,
  styleCardDone,
} from '../../../../../../Home/ActivityCard/activityCardFunctions';
import { SeletorDeMarca } from '../../utils/SeletorDeMarca/SeletorDeMarca';
import { defaultGrey } from '../../../../../../app/globalStyle';
import { CardLoading } from '../../../../../../utils/CardLoading';
import { useExecellenceClubCardStyles } from '../../utils/ExcellenceClubStyledComponents/useExecellenceClubCardStyles';
import { TipoIndicadorExcellenceClubVendedorCard } from '../../../../../../../core/Enums';
import { useCorPorColocacao } from './hooks/useCorPorColocacao';

const IndicadorExcellenceClubVendedorGenerico = ({
  total,
  executado,
  idsAtividades = [],
  showDialogAtividades,
  mensagemAction = `MELHORAR ESSE INDICADOR`,
  titulo,
  textoItemDireita,
  textoItemEsquerda,
  exibirMarca = false,
  marca,
  marcas,
  setMarca,
  mensagemDialog,
  gaEventClick,
  idUsuario,
  colocacao = null,
  totalVendedores = null,
  porcentagem: porcentagemInput,
  setVendedorFiltro,
  tipoIndicador,
}) => {
  const theme = useTheme();
  const exibirColocacao = colocacao !== null && totalVendedores !== null;
  const porcentagem = porcentagemInput >= 100 ? 100 : porcentagemInput;
  const classes = useExecellenceClubCardStyles({
    valorIndicadorExcellenceClub: porcentagem,
  });
  const styleCard = porcentagem > 70 ? styleCardDone : styleCardAtrasado;
  const cor = useCorPorColocacao(colocacao);
  const reticenciasStyles = { marginRight: theme.spacing(-1) };
  const valor =
    tipoIndicador !== TipoIndicadorExcellenceClubVendedorCard.FUP48.name
      ? executado
      : idsAtividades.length;
  const valorSlider = total + executado - executado;
  const max = total + executado;
  const indicadorNulo = porcentagem === 0 && total === 0 && executado === 0;
  const exibeActions =
    (tipoIndicador === TipoIndicadorExcellenceClubVendedorCard.FUP48.name &&
      idsAtividades.length > 0) ||
    (tipoIndicador !== TipoIndicadorExcellenceClubVendedorCard.FUP48.name &&
      porcentagem < 100 &&
      !indicadorNulo);

  const onClick = useCallback(() => {
    gaEventClick();
    setVendedorFiltro({ id: idUsuario });

    return showDialogAtividades(
      {
        dataInicio: null,
        dataFim: null,
        finalizado: null,
        temperatura: null,
        ids: idsAtividades,
      },
      mensagemDialog
    );
  }, [
    gaEventClick,
    idUsuario,
    idsAtividades,
    mensagemDialog,
    setVendedorFiltro,
    showDialogAtividades,
  ]);

  return (
    <Card
      styleCard={styleCard}
      singleTitle
      title={{
        components: (
          <ExcellenceClubTitleContainer>
            <ExcellenceClubTitle
              id="excellence-club-titulo"
              color={styleCard.customSubtitleColor}
            >
              {titulo}
            </ExcellenceClubTitle>

            <ExcellenceClubPrizeContainer>
              {exibirColocacao && (
                <>
                  <Icon path={mdiMedal} size={1} color={cor} />
                  <ExcellenceClubPrizeText>
                    {`${colocacao}/${totalVendedores}`}
                  </ExcellenceClubPrizeText>
                </>
              )}
            </ExcellenceClubPrizeContainer>

            {exibirMarca ? (
              <SeletorDeMarca
                marcas={marcas}
                marca={marca}
                setMarca={setMarca}
                classes={classes}
              />
            ) : (
              <Icon
                path={mdiDotsVertical}
                size={1}
                color={defaultGrey}
                style={reticenciasStyles}
              />
            )}
          </ExcellenceClubTitleContainer>
        ),
      }}
      content={
        <Grid container spacing={0}>
          <ExcellenceClubItem
            size={6}
            valor={total}
            texto={textoItemDireita}
            color={styleCard.customSubtitleColor}
          />
          <ExcellenceClubItem
            size={6}
            valor={valor}
            texto={textoItemEsquerda}
            color={styleCard.customSubtitleColor}
          />
        </Grid>
      }
      subContent={
        <>
          <ValueSlider
            colorSlider={styleCard.customSubtitleColor}
            value={valorSlider}
            valueLabelDisplay="auto"
            min={0}
            max={max}
          />

          {!indicadorNulo && (
            <ExcellenceClubSliderTitle>
              {parseInt(porcentagem, 10)}% da meta alcançada
            </ExcellenceClubSliderTitle>
          )}
        </>
      }
      actions={
        <>
          {exibeActions && (
            <ExcellenceClubAction onClick={onClick}>
              {mensagemAction}
            </ExcellenceClubAction>
          )}
        </>
      }
      classes={classes}
      onLoad={() => <CardLoading />}
    />
  );
};

IndicadorExcellenceClubVendedorGenerico.propTypes = {
  total: PropTypes.number,
  executado: PropTypes.number,
  idUsuario: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDialogAtividades: PropTypes.func,
  setVendedorFiltro: PropTypes.func,
  idsAtividades: PropTypes.array,
  mensagemAction: PropTypes.string,
  titulo: PropTypes.string,
  textoItemDireita: PropTypes.string,
  textoItemEsquerda: PropTypes.string,
  exibirMarca: PropTypes.bool,
  marca: PropTypes.string,
  marcas: PropTypes.array,
  setMarca: PropTypes.func,
  mensagemDialog: PropTypes.string,
  tipoIndicador: PropTypes.string,
  gaEventClick: PropTypes.func.isRequired,
  colocacao: PropTypes.number,
  totalVendedores: PropTypes.number,
};

export { IndicadorExcellenceClubVendedorGenerico };
