import styled from 'styled-components';
import { defaultGrey } from '../../../../../../app/globalStyle';

export const ExcellenceClubTitleContainer = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const ExcellenceClubPrizeContainer = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${props => props && props.theme && props.theme.spacing(0.5)}px;
`;

export const ExcellenceClubChipContainer = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const ExcellenceClubTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.16px;
  color: ${props => (props ? props.color : defaultGrey)};
  font-family: Roboto;
  font-weight: medium;
`;

export const ExcellenceClubItemContainer = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.24px;
  text-align: left;
  color: #727272;
  line-height: 80%;
  display: flex;
`;

export const ExcellenceClubContainer = styled.div`
  color: gray;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid
    ${props => (props.borderColor ? props.borderColor : 'lightGray')};
  border-top: transparent;
  border-right: transparent;
  border-bottom: transparent;
  padding-left: 10px;
`;

export const ExcellenceItemMainText = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
`;

export const ExcellenceItemSecundaryText = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props && props.color};
  margin-left: ${props => props && props.theme && props.theme.spacing(0.5)}px;
`;

export const ExcellenceItemIndisponivelText = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${defaultGrey};
  text-align: center;
  margin-left: ${props => props && props.theme && props.theme.spacing(0.5)}px;
`;

export const ExcellenceItemSubtitle = styled.div`
  color: gray;
  font-size: 12px;
  text-align: left;
  color: #727272;
  font-family: Roboto;
  font-weight: 400;
`;

export const ExcellenceClubSliderTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.24px;
  color: #000000;
  padding-top: -8px;
`;

export const ExcellenceClubAction = styled.div`
  color: gray;
  font-size: 10px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b6862;
`;

export const ExcellenceClubPrizeText = styled.span`
  font-size: 12px;
  text-align: left;
  letter-spacing: 0.24px;
  color: #000000;
  font-family: Roboto;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0.24px;
  color: #000000;
  font-family: Roboto;
  font-weight: medium;
`;

export const ExcellenceClubBottomActions = styled.div`
  color: gray;
  font-size: 10px;
  font-weight: bold;
  margin: ${props => props.theme.spacing(1)}px;
`;
