import { compose } from 'recompose';
import { withWidth } from '@material-ui/core';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import Typography from '@material-ui/core/Typography';
import { isPerformCheckinBlocked } from 'components/Atividade/atividadeFunctions';
import { checkInAbasStyles } from './utils/checkInStylesUtils';

export const CheckInTabs = compose(withWidth())(
  ({ classes, selectedTab, setSelectedTab, usuarioLogado }) => {
    /* eslint no-console: 0 */
    console.log('usuarioLogado');
    console.log(usuarioLogado);
    const checkinBlocked = isPerformCheckinBlocked(usuarioLogado);

    return (
      <Grid container className={classes.abas}>
        {!checkinBlocked && (
          <Grid item xs={12} className={classes.gridItemInfoAbas}>
            <Typography className={classes.labelInfoAbas}>
              Selecione uma opção para prosseguir
            </Typography>
          </Grid>
        )}
        {!checkinBlocked && (
          <Grid item xs={6}>
            <Button
              id="realizarCheckIn"
              variant="outlined"
              style={
                selectedTab === 0
                  ? checkInAbasStyles.selectedTab
                  : checkInAbasStyles.tab
              }
              fullWidth
              onClick={() => {
                setSelectedTab(0);
              }}
            >
              <Typography className={classes.buttonTitle}>Check-In</Typography>
            </Button>
          </Grid>
        )}
        <Grid item xs={checkinBlocked ? 12 : 6}>
          <Button
            id="CheckInsRealizados"
            variant="outlined"
            style={
              selectedTab === 1
                ? checkInAbasStyles.selectedTab
                : checkInAbasStyles.tab
            }
            fullWidth
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            <Typography className={classes.buttonTitle}>Realizados</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }
);
