import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { IconButton } from '@tecsinapse/ui-kit';
import React, { useCallback } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import { useQuery } from '@apollo/react-hooks';
import { useGlobalStyle } from '../../../../../../app/globalStyle';
import { FullDisplayContainer } from '../../../../../../utils/ui/styledComponents';
import { ExcellenceClubListing } from './ExcellenceClubListing';
import { getLocalDateTimeString } from '../../../../../../utils/dateUtils';
import { INDICADOR_EXCELLENCE_CLUB_VENDEDORES_QUERY } from '../data/EXCELLENCE_CLUB_USUARIOS';
import { Loading } from '../../../../../../utils/Loading';

export const MetaAtualDoIndicadorContainer = styled.div`
  font-size: 18px;
  margin: ${props => props && props.theme && props.theme.spacing(1)}px;
`;

export const MetaAtualDoIndicador = styled.span`
  text-align: left;
  letter-spacing: -0.8px;
  color: #616161;
  font-family: Roboto;
  font-weight: 700;
`;

export const ValorMetaAtualDoIndicador = styled.span`
  text-align: left;
  letter-spacing: -0.8px;
  color: #6e8d5b;
  font-family: Roboto;
  font-weight: 700;
`;

const MetaAlcancadaDoIndicadorAtual = ({ porcentagem = 0 }) => (
  <MetaAtualDoIndicadorContainer>
    <MetaAtualDoIndicador>Meta do atual do Indicador</MetaAtualDoIndicador>
    <ValorMetaAtualDoIndicador>
      {` ${parseInt(porcentagem, 10)}% alcançado`}
    </ValorMetaAtualDoIndicador>
  </MetaAtualDoIndicadorContainer>
);

export function DialogIndicadoresExcellenceClub({
  setShow,
  show,
  titulo,
  Component,
  entidadeProps,
  porcentagem,
  dataInicialDialog,
  dataFinalDialog,
  dealerId,
  marca,
  unidadeNegocioId,
}) {
  const classes = useGlobalStyle();
  const onClick = useCallback(() => setShow(false), [setShow]);
  const exibirPorcentagem = isNotUndefOrNull(porcentagem) && porcentagem >= 0;

  const {
    loading,
    data: { indicadoresExcellenceClubVendedor = [] } = {},
  } = useQuery(INDICADOR_EXCELLENCE_CLUB_VENDEDORES_QUERY, {
    variables: {
      dataInicio: getLocalDateTimeString(dataInicialDialog),
      dataFim: getLocalDateTimeString(dataFinalDialog),
      dealerPk: dealerId,
      marcaId: marca,
      unidadeNegocioId,
    },
    skip:
      !isNotUndefOrNull(dataInicialDialog) ||
      !isNotUndefOrNull(dataFinalDialog),
  });

  const entidades = indicadoresExcellenceClubVendedor || [];

  return (
    <Dialog fullScreen onClose={onClick} open={show}>
      <AppBar position="static" variant="outlined">
        <Toolbar>
          <IconButton
            onClick={onClick}
            color="inherit"
            classes={{ root: classes.iconBackButton }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {titulo}
          </Typography>
        </Toolbar>
      </AppBar>
      <FullDisplayContainer>
        {exibirPorcentagem && (
          <MetaAlcancadaDoIndicadorAtual porcentagem={porcentagem} />
        )}

        {loading ? (
          <Loading />
        ) : (
          <ExcellenceClubListing
            loading={loading}
            entidades={entidades}
            componente={Component}
            entidadeProps={entidadeProps}
          />
        )}
      </FullDisplayContainer>
    </Dialog>
  );
}
