import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose, withHandlers, withProps } from 'recompose';
import { defaultGrey, defaultOrange } from '../../app/globalStyle';
import { showAtrasadasClick, todayClick, weekClick } from '../../utils/GAUtils';
import { WeekdaysComponent } from './WeekdaysComponent/WeekdaysComponent';

export const styles = {
  root: {
    display: 'flex',
    zIndex: 5,
    padding: 0,
    margin: 0,
    listStyle: 'none',
    boxShadow: 'inset 0 -1px rgba(0,0,0,0.04)',
    minHeight: '30px',
    flexShrink: 0,
    backgroundColor: defaultGrey,
    color: 'white',
  },
  day: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0',
    flexGrow: 1,
    fontWeight: 500,
    textAlign: 'center',
  },
  dayName: { textTransform: 'uppercase', fontSize: '10px', marginBottom: '0' },
  dotContainer: { display: 'flex', justifyContent: 'center' },
  textDot: {
    borderRadius: '100%',
    background: defaultOrange,
    height: '4px',
    width: '4px',
    left: '50%',
  },
  dayTypography: {
    marginBottom: '0',
  },
};

const mapStateToProps = ({ weekDays: { showAtrasadas, selectedDay } }) => ({
  showAtrasadas,
  selectedDay,
});

export const Weekdays = compose(
  withStyles(styles),
  connect(mapStateToProps),
  withHandlers({
    setSelectedDayAndSendToAnalytics: ({
      setSelectedDay,
      setShowAtrasadas,
    }) => ({ isToday, dayOfWeek }) => {
      setSelectedDay(dayOfWeek);
      setShowAtrasadas(false);

      if (isToday) {
        todayClick();
      } else {
        weekClick(
          dayOfWeek.startOf('day').diff(moment().startOf('day'), 'days')
        );
      }
    },
    setShowAtrasadasAndSendToAnalytics: ({
      setSelectedDay,
      setShowAtrasadas,
    }) => () => {
      // setSelectedDay(null);
      setShowAtrasadas(true);
      showAtrasadasClick();
    },
  }),
  withProps(({ selectedDay }) => {
    const weekdays = moment.weekdaysShort(true);
    const weekStartsOn = 7;
    const selectedDayMoment = moment(selectedDay);

    const orderedWeekdays = [
      ...weekdays.slice(weekStartsOn, 7),
      ...weekdays.slice(0, weekStartsOn),
    ];

    const daysOfWeek = [];

    orderedWeekdays.forEach(day => {
      daysOfWeek.push({
        moment: moment(selectedDayMoment).startOf('week').day(day),
        name: day,
      });
    });

    return {
      orderedWeekdays,
      daysOfWeek,
      selectedDayMoment,
      selectedWeekDay: selectedDay && selectedDay.weekday(),
    };
  })
)(WeekdaysComponent);
