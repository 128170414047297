import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { destroy } from 'redux-form';
import { reduxFormAutoFocus } from './reduxFormAutoFocus';
import { withStoreConsumer } from './StoreConsumer';

const enhance = compose(
  withRouter,
  withProps(({ location }) => ({
    selectedPage: location.state ? location.state.selectedPage : 0,
    pageState: location.pageState ? location.state.pageState : {},
  })),
  withHandlers({
    goToNextPage: ({ history, selectedPage }) => (state = {}) => {
      history.push(history.location.pathname, {
        selectedPage: selectedPage + 1,
        pageState: state,
      });
    },
    goToPage: ({ history }) => (state = {}, pageNumber) => {
      history.push(history.location.pathname, {
        selectedPage: pageNumber,
        pageState: state,
      });
    },
    goBackPage: ({ history }) => () => {
      history.pop();
    },
  }),
  withStoreConsumer,
  lifecycle({
    componentWillUnmount() {
      const { store, reduxOptions } = this.props;

      store.dispatch(destroy(reduxOptions.form));
    },
  })
);

export const WizardForm = enhance(props => {
  const {
    goToNextPage,
    goBackPage,
    goToPage,
    pageState,
    selectedPage,
    reduxOptions,
    pages,
    history,
  } = props;
  const currentChild = pages(props)[selectedPage];
  const reduxFunc = reduxFormAutoFocus({
    ...reduxOptions,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  });
  const currentChildFunc = reduxFunc(currentChild);

  return (
    <>
      {React.createElement(currentChildFunc, {
        ...pageState,
        selectedPage,
        goToNextPage,
        goBackPage,
        goToPage,
        history,
        ...props,
      })}
    </>
  );
});
