import React from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { ExcellenceClubDealerCardGenerico } from '../ExcellenceClubDealerCardGenerico/ExcellenceClubDealerCardGenerico';
import { TipoIndicadorExcellenceClubCard } from '../../../../../../../core/Enums';
import { useStateMarca } from '../../../../indicadoresHooks';
import { CardLoading } from '../../../../../../utils/CardLoading';
import { getLocalDateTimeString } from '../../../../../../utils/dateUtils';
import { EXCELLENCE_CLUB_QUERY } from '../data/EXCELLENCE_CLUB_QUERY';

export const ExcellenceClubDealerCard = React.memo(
  ({
    setMarcaDialog,
    setDataInicialDialog,
    setDataFinallDialog,
    setTituloDialogVendedores,
    setShowVendedoresExcellenceClub,
    setDialogComponent,
    setPorcentagemDialog,
    marcas,
    dealerId,
    unidadeNegocioId,
    showDialogAtividades,
    setVendedorFiltro,
  }) => {
    const dataInicial = moment().startOf('year');
    const dataFinal = moment().endOf('day');
    const [marca, setMarca] = useStateMarca(marcas);

    const {
      loading,
      data: {
        indicadorExcellenceClub: {
          qtdFup48,
          qtdTD,
          qtdVendas,
          qtdEmplacados,
          idsVendas,
          porcentagemTDRealizado,
          porcentagemFup48Realizado,
          qtdPorcentagemVendasxEmplacados,
          qtdPontos,
          total,
          porcentagem,
          qtdIndicadoresIncompletos: valorIncompleto,
        } = {},
      } = {},
    } = useQuery(EXCELLENCE_CLUB_QUERY, {
      variables: {
        dataInicio: getLocalDateTimeString(dataInicial),
        dataFim: getLocalDateTimeString(dataFinal),
        dealerPk: dealerId,
        marcaId: marca,
        unidadeNegocioId,
      },
    });

    /**
     * FIXME: isso deve utilizar um wrapper componente como HOC, que exibe faz o branch
     * */
    if (loading) {
      return <CardLoading />;
    }

    return (
      <ExcellenceClubDealerCardGenerico
        setMarcaDialog={setMarcaDialog}
        setDataInicialDialog={setDataInicialDialog}
        setDataFinallDialog={setDataFinallDialog}
        dataInicial={dataInicial}
        dataFinal={dataFinal}
        titulo="Excellence Club"
        qtdFup48={qtdFup48}
        qtdTD={qtdTD}
        qtdVendas={qtdVendas}
        qtdEmplacados={qtdEmplacados}
        qtdPontos={qtdPontos}
        porcentagemFup48Realizado={porcentagemFup48Realizado}
        porcentagemTDRealizado={porcentagemTDRealizado}
        qtdPorcentagemVendasxEmplacados={qtdPorcentagemVendasxEmplacados}
        slidePorcentagem={false}
        porcentagem={porcentagem}
        total={total}
        marca={marca}
        marcas={marcas}
        setVendedorFiltro={setVendedorFiltro}
        setMarca={setMarca}
        setTituloDialogVendedores={setTituloDialogVendedores}
        setShowVendedoresExcellenceClub={setShowVendedoresExcellenceClub}
        setDialogComponent={setDialogComponent}
        setPorcentagemDialog={setPorcentagemDialog}
        showDialogAtividades={showDialogAtividades}
        idsVendas={idsVendas}
        valorIncompleto={valorIncompleto}
        tipoIndicadorExcellenceClubCard={
          TipoIndicadorExcellenceClubCard.EXCELLENCE_CLUB
        }
      />
    );
  }
);
