import React from 'react';
import { SelecaoModeloInteresseContext } from './SelecaoModeloInteresseContext';
import { SelecaoModeloInteresseDialog } from './SelecaoModeloInteresseDialog';
import { eventCancelTestDriveVehicleSelection } from '../utils/GAUtils';

export function SelecaoModeloInteresseConsumer(props) {
  const { children, ...rest } = props;

  return (
    <SelecaoModeloInteresseContext.Consumer>
      {({
        openSelecaoModeloInteresseDialog,
        setOpenSelecaoModeloInteresseDialog,
        veiculos,
        oportunidadeId,
        setSelecaoModeloInteresseVeiculos,
        selecaoModelosEAbreDialog,
      }) => (
        <>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              openSelecaoModeloInteresseDialog,
              setOpenSelecaoModeloInteresseDialog,
              veiculos,
              setSelecaoModeloInteresseVeiculos,
              selecaoModelosEAbreDialog,
              ...rest,
            })
          )}
          <SelecaoModeloInteresseDialog
            open={openSelecaoModeloInteresseDialog}
            veiculos={veiculos}
            oportunidadeId={oportunidadeId}
            handleClose={() => {
              eventCancelTestDriveVehicleSelection();
              setOpenSelecaoModeloInteresseDialog(false);
            }}
          />
        </>
      )}
    </SelecaoModeloInteresseContext.Consumer>
  );
}
