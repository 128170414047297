import { Typography } from '@material-ui/core';
import React from 'react';
import { defaultGrey } from '../../../app/globalStyle';

export const getMessageOportunidades = (
  porcentagem,
  diasUteisAteFinalPeriodo,
  meta,
  realizado,
  mainColor,
  classes
) => {
  const ty = (mensagem, color = mainColor) => (
    <Typography
      variant="caption"
      className={classes.indicadoresMessage}
      style={{
        color,
      }}
      key={mensagem}
    >
      {mensagem}
    </Typography>
  );

  let mensagem = '';

  if (porcentagem >= 100) {
    mensagem = `Sua meta já foi realizada`;
  }

  if (meta === undefined || meta === null || meta === 0) {
    mensagem = 'Não existem metas para este indicador';
  }

  if (mensagem !== '') {
    return ty(mensagem);
  }

  mensagem = [
    `Realize  `,
    `${meta - realizado}`,
    ` atividade(s) para chegar a sua meta`,
  ];

  return [ty(mensagem[0]), ty(mensagem[1], defaultGrey), ty(mensagem[2])];
};

export const printPorcentagemMaxima = porcentagem =>
  porcentagem > 999 ? '999+' : `${porcentagem}`;
