import { ListItem } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { TOPICOS } from './topicos/Topicos';

export const NotificacaoCard = ({ notificacao, onClick }) => {
  const { visualizado } = notificacao;
  const render = TOPICOS.get(notificacao.topicoNotificador);

  return (
    <Container
      visualizado={visualizado ? 'true' : 'false'}
      onClick={() => onClick(notificacao)}
    >
      <Info>{render.renderizar(notificacao)}</Info>
      {!visualizado && <Check />}
    </Container>
  );
};

const Container = styled(ListItem)`
  display: flex;
  background-color: ${({ visualizado }) =>
    visualizado === 'true' ? 'transparent' : 'white'};
  align-items: space-between;
  border-width: 2px;
  border-color: black;
`;

const Info = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const Check = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 5px;
`;
