import React from 'react';

import { ConfirmarEntrarEmContatoModalContext } from './ConfirmarEntrarEmContatoModalContext';

export const ConfirmarEntrarEmContatoModalConsumer = ({
  children,
  ...rest
}) => {
  return (
    <ConfirmarEntrarEmContatoModalContext.Consumer>
      {({ handleEntrarEmContatoClick }) => {
        return React.Children.map(children, child =>
          React.cloneElement(child, {
            handleEntrarEmContatoClick,
            ...rest,
          })
        );
      }}
    </ConfirmarEntrarEmContatoModalContext.Consumer>
  );
};
