export const podeReeditarProposta = proposta =>
  proposta && proposta.status && proposta.status.id !== 'PROPOSTA_ABERTA';

function isOportunidadeAberta(oportunidade) {
  return (
    oportunidade &&
    (oportunidade.status === 'NOVO' || oportunidade.status === 'EM_NEGOCIACAO')
  );
}

export const podeEditarProposta = (proposta = null, oportunidade = null) =>
  proposta == null ||
  oportunidade == null ||
  (proposta.status &&
    proposta.status.podeEditarProposta &&
    isOportunidadeAberta(oportunidade));

export const podeEditarOportunidade = oportunidade => {
  const { propostas } = oportunidade;

  return (
    propostas &&
    propostas.length > 0 &&
    isOportunidadeAberta(oportunidade) &&
    propostas[0].status &&
    propostas[0].status.podeEditarProposta
  );
};

export const redirecionaAgenda = location => {
  return location.state && location.state.redirectHome;
};
