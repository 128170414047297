import React, { useState } from 'react';
import { compose } from 'recompose';
import { useHistory } from 'react-router';
import { AtualizaModeloInativoContext } from './AtualizaModeloInativoContext';
import { withObrigarModeloInteresseAtivo } from '../opportunity/data/queries/crmTermosQuery';
import { AtualizaModeloInativoDialog } from './AtualizaModeloInativoDialog';
import { eventCloseUpdateModeloInativo } from '../utils/GAUtils';

const AtualizaModeloInativoProviderComponent = ({
  children,
  crmObrigarModeloInteresseAtivo,
}) => {
  const [veiculos, setAtualizaModeloInativoVeiculos] = useState([]);
  const [idOportunidade, setIdOportunidade] = useState(null);
  const [current, setCurrent] = useState(0);
  const [push, setPush] = useState(null);
  const [postAction, setPostAction] = useState(null);
  const [
    openAtualizaModeloInativoDialog,
    setOpenAtualizaModeloInativoDialog,
  ] = useState(false);
  const history = useHistory();
  const atualizaModeloInativoEAbreDialog = ({
    veiculos: veiculosInput,
    idOportunidade: idOportunidadeInput,
    push: pushInput,
    postAction: postActionInput,
  }) => {
    if (!crmObrigarModeloInteresseAtivo) {
      return;
    }
    setOpenAtualizaModeloInativoDialog(true);
    setAtualizaModeloInativoVeiculos(veiculosInput);
    setIdOportunidade(idOportunidadeInput);
    setCurrent(0);
    setPush(pushInput);

    setPostAction(() => postActionInput);
  };

  return (
    <AtualizaModeloInativoContext.Provider
      value={{
        openAtualizaModeloInativoDialog,
        setOpenAtualizaModeloInativoDialog,
        veiculos,
        setAtualizaModeloInativoVeiculos,
        atualizaModeloInativoEAbreDialog,
        current,
        setCurrent,
        idOportunidade,
        push,
        postAction,
      }}
    >
      {children}
      <AtualizaModeloInativoDialog
        open={openAtualizaModeloInativoDialog}
        veiculos={veiculos}
        idOportunidade={idOportunidade}
        atualizaVeiculo={(vehicleData, currentIndex) => {
          // Go to next vehicle (if it is the last, the dialog will close)
          if (currentIndex < veiculos.length - 1) {
            setCurrent(current + 1);
          } else {
            if (postAction) {
              postAction();
            }

            if (push) {
              history.push(push);
            }

            setOpenAtualizaModeloInativoDialog(false);
          }
        }}
        current={current}
        handleClose={() => {
          eventCloseUpdateModeloInativo();
          setOpenAtualizaModeloInativoDialog(false);
        }}
      />
    </AtualizaModeloInativoContext.Provider>
  );
};

export const AtualizaModeloInativoProvider = compose(
  withObrigarModeloInteresseAtivo
)(AtualizaModeloInativoProviderComponent);
