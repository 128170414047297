import {
  Card,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Delete, MoreVert } from '@material-ui/icons';
import { EmptyState } from '@tecsinapse/ui-kit';
import { getAlertContext } from 'components/utils/alertContext';
import moment from 'moment';
import React from 'react';
import { compose } from 'redux';
import styled from 'styled-components';
import { FloatingButton } from '../../../utils/FloatingButton';
import { useProdutoAgregado } from './ProdutoAgregadoHook';

const View = props => {
  const {
    produtos,
    temProdutosAgregados,
    exibeOpcoes,
    handleAdicionarProduto,
    handleExcluirProduto,
    handleOpcoes,
  } = useProdutoAgregado(props);

  const {
    oportunidade: { finalizada: disabledForm },
  } = props;

  return (
    <>
      <Conteudo>
        {temProdutosAgregados ? (
          <Cards
            produtos={produtos}
            onOpcoesClick={handleOpcoes}
            disabled={disabledForm}
          />
        ) : (
          <EmptyState />
        )}
        <FloatingButton
          disabled={disabledForm}
          onClick={handleAdicionarProduto}
        />
      </Conteudo>

      <Drawer open={exibeOpcoes} onClose={() => handleOpcoes()} anchor="bottom">
        <ListItem button onClick={handleExcluirProduto}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Excluir produto agregado" />
        </ListItem>
      </Drawer>
    </>
  );
};

const Cards = ({ produtos, onOpcoesClick, disabled }) => {
  return produtos.map(produto => (
    <ProdutoCard>
      <Box>
        <ItemCard
          descricao="Data da criação"
          valor={produto.dataCriacao}
          formatar={valor => moment(valor).format('DD/MM/YYYY')}
        />

        <ItemCard descricao="Resultado" valor={produto.resultado} />

        <ItemCard
          descricao="Produto agregado"
          valor={produto.produtoAgregado.nome}
        />

        <ItemCard
          descricao="Data de início da vigência"
          valor={produto.dataInicioVigencia}
          formatar={valor => moment(valor).format('DD/MM/YYYY')}
        />

        <ItemCard
          descricao="Data de término da vigência"
          valor={produto.dataFimVigencia}
          formatar={valor => moment(valor).format('DD/MM/YYYY')}
        />

        <ItemCard descricao="Detalhes" valor={produto.detalhes} />
      </Box>
      <IconButton disabled={disabled} onClick={() => onOpcoesClick(produto)}>
        <MoreVert />
      </IconButton>
    </ProdutoCard>
  ));
};

const ItemCard = ({ descricao, valor, formatar }) => {
  if (!valor) return null;

  return (
    <ItemContent>
      <Typography variant="body2" color="textSecondary">
        {descricao}
      </Typography>
      <Typography variant="subtitle2">
        {formatar ? formatar(valor) : valor}
      </Typography>
    </ItemContent>
  );
};

export const ProdutoAgregado = compose(getAlertContext)(View);

const Box = styled.div`
  flex: 1;
`;

const Conteudo = styled.div`
  margin-top: 15px;
`;

const ProdutoCard = styled(Card)`
  margin: 0 15px 15px 15px;
  padding: 10px 15px;
  overflow: unset;
  display: flex;
  align-items: flex-start;
`;

const ItemContent = styled.div`
  padding-bottom: 10px;
`;
