import omit from 'lodash.omit';
import {
  TemperaturaNegociacao,
  TipoAcaoInformarContato,
} from '../../../core/Enums';
import {
  calcularQuantidadeVeiculosComModelo,
  contarFormasPagamento,
  verificarPrecoUnitarioInformado,
} from '../../Atividade/atividadeFunctions';
import { isOportunidade } from '../../Home/ActivityCard/activityCardFunctions';
import {
  eventNegociacaoAceita,
  eventPerformActivity,
  eventSaveLostBusiness,
} from '../../utils/GAUtils';
import { ErrorOffline } from '../../../core/offline/exception/ErrorOffline';

export const oportunidadeActions = (
  action,
  submitValues,
  {
    realizarAtividade,
    criarERealizarAtividade,
    criarENegocioPerdido,
    negocioPerdido,
    negociacaoAceita,
    history,
  },
  tipoAcaoInformarContato
) => {
  if (action === 'REALIZAR_ATIVIDADE') {
    eventPerformActivity();
    const variables = { atividade: submitValues };

    const mutation =
      tipoAcaoInformarContato ===
      TipoAcaoInformarContato.CRIAR_ATIVIDADE_OPORTUNIDADE.name
        ? criarERealizarAtividade
        : realizarAtividade;

    return mutation({ variables });
  }

  if (action === 'NEGOCIACAO_ACEITA') {
    eventNegociacaoAceita();
    const variables = { atividade: submitValues };

    return negociacaoAceita({ variables });
  }

  if (action === 'NEGOCIO_PERDIDO') {
    eventSaveLostBusiness();
    const variables = {
      negocioPerdido: omit(submitValues, ['longitude', 'latitude']),
    };

    const mutation =
      tipoAcaoInformarContato ===
      TipoAcaoInformarContato.CRIAR_ATIVIDADE_OPORTUNIDADE.name
        ? criarENegocioPerdido
        : negocioPerdido;

    return mutation({
      variables,
    });
  }

  return null;
};

export const realizarAtividadePostAction = (
  postActionPromiseInput,
  data,
  history,
  oportunidadeIncompleta,
  idAtividadeRealizada,
  urlRetorno
) => {
  const idAtividade =
    data?.realizarAtividade?.oportunidade?.proximaAtividadeAbertaPk ||
    data?.criarERealizarAtividade?.oportunidade?.proximaAtividadeAbertaPk;

  const state = { oportunidadeIncompleta, idAtividadeRealizada, urlRetorno };

  if (idAtividade) {
    eventPerformActivity();

    return () =>
      history.push(`/atividade/${idAtividade}/dataProximaAtividade`, {
        pageState: state,
      });
  }

  return postActionPromiseInput;
};

export const negocioPerdidoPostAction = (
  postActionPromiseInput,
  data,
  history,
  oportunidadeIncompleta,
  idAtividadeRealizada,
  urlRetorno
) => {
  const state = { oportunidadeIncompleta, idAtividadeRealizada, urlRetorno };

  if (
    data &&
    data.informarNegocioPerdido &&
    data.informarNegocioPerdido.oportunidade &&
    data.informarNegocioPerdido.oportunidade.proximaAtividadeAbertaPk
  ) {
    return () =>
      history.push(
        `/atividade/${data.informarNegocioPerdido.oportunidade.proximaAtividadeAbertaPk}/dataProximaAtividade`,
        {
          pageState: state,
        }
      );
  }

  return postActionPromiseInput;
};

export const atividadePadraoPostAction = (
  postActionPromiseInput,
  history,
  oportunidadeIncompleta,
  idAtividadeRealizada,
  urlRetorno
) => {
  const redirectHome = () => history.push('/agenda');

  const redirectToUpdate = () =>
    history.push(`/realizarAtividade/${idAtividadeRealizada}`, {
      selectedPage: 2,
    });

  if (postActionPromiseInput == null) {
    return oportunidadeIncompleta ? redirectToUpdate : redirectHome;
  }

  return postActionPromiseInput;
};

export const isOportunidadeIncompleta = ({
  crmObrigarPreenchimentoDadosVeiculoOportunidade,
  temperatura,
  activityBranch,
  action,
}) => {
  const considerarTemperatura = !!temperatura;

  const numVeiculosComModelos = calcularQuantidadeVeiculosComModelo(
    activityBranch
  );

  const possuiVeiculoComPrecoUnitarioNaoInformado = verificarPrecoUnitarioInformado(
    activityBranch
  );

  const numFormasPagamento = contarFormasPagamento(activityBranch);

  const isOpportunity = isOportunidade(activityBranch);

  const naoTemTemperaturaAltaEVeiculo =
    (!considerarTemperatura ||
      [
        TemperaturaNegociacao.MORNO.name,
        TemperaturaNegociacao.QUENTE.name,
        TemperaturaNegociacao.SUPER_QUENTE.name,
      ].includes(temperatura)) &&
    numVeiculosComModelos < 1;

  const semModeloObrigatorio =
    crmObrigarPreenchimentoDadosVeiculoOportunidade &&
    numVeiculosComModelos < 1;

  const semQuenteEFormaDepagamento =
    (!considerarTemperatura ||
      temperatura === TemperaturaNegociacao.SUPER_QUENTE.name) &&
    numFormasPagamento < 1;

  if (action === 'NEGOCIACAO_ACEITA') {
    return (
      isOpportunity &&
      (naoTemTemperaturaAltaEVeiculo ||
        semModeloObrigatorio ||
        semQuenteEFormaDepagamento ||
        possuiVeiculoComPrecoUnitarioNaoInformado)
    );
  }

  return (
    isOpportunity &&
    (naoTemTemperaturaAltaEVeiculo || semQuenteEFormaDepagamento)
  );
};

export const negociacaoAceitaErro = (history, listaErros, activityBranch) => {
  if (listaErros === undefined || listaErros instanceof ErrorOffline) {
    return false;
  }

  const errors = {
    'validacoes.informar.veiculo.interesse': {
      tab: 0,
      redirecionar: true,
    },
    'validacoes.informar.veiculo.interesse.ativo': {
      tab: 0,
      redirecionar: true,
    },
    'proposta.nao.pode.solicitar.aprovacao.sem.dados.venda': {
      tab: 1,
      redirecionar: true,
    },
    null: {
      tab: null,
      redirecionar: null,
    },
  };

  const { tab, redirecionar } = errors[listaErros[0].bundle || null];

  if (redirecionar) {
    const { id: idAtividade } = activityBranch;

    history.push(`/realizarAtividade/${idAtividade}`, {
      selectedPage: 2,
      msgErro: listaErros[0].message,
      tab,
    });
  }

  return redirecionar;
};
