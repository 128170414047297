import { IconButton } from '@tecsinapse/ui-kit';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { CheckCircle, Edit, MoreVert } from '@material-ui/icons';
import React from 'react';
import { withRouter } from 'react-router';
import { compose, withHandlers, withState } from 'recompose';
import { useGlobalStyle } from '../app/globalStyle';
import { getAlertContext } from '../utils/alertContext';
import { displayLoadingState } from '../utils/displayLoadingState';
import { getLoadingSpinnerContext } from '../utils/loadingSpinnerContext';
import {
  ProposalApprovalMutation,
  reeditarPropostaMutation,
} from './data/mutations/OpportunityMutations';
import { confirm } from '../utils/dialogs/ConfirmationDialog';

export const reeditarPropostaHandler = ({
  reeditarProposta,
  showAlert,
  hideLoading,
  showLoading,
}) => ({ oportunidade }) =>
  confirm('Reeditar proposta').then(() => {
    const { id: idOportunidade, propostas } = oportunidade;

    if (propostas && propostas.length) {
      showLoading();

      const variables = {
        idProposta: propostas[0].id,
        idOportunidade,
      };

      return reeditarProposta({
        variables,
      }).then(() => {
        hideLoading();
        showAlert({ message: 'Edição habilitada.', title: 'Sucesso' });
      });
    }

    return null;
  });

const enhance = compose(
  withState('anchorEl', 'setAnchorEl', null),
  withState('open', 'setOpen', false),
  withRouter,
  reeditarPropostaMutation,
  ProposalApprovalMutation,
  getAlertContext,
  getLoadingSpinnerContext,
  getAlertContext,
  displayLoadingState(),
  withHandlers({
    canRequestApproval: () => ({ oportunidade, proposta }) => {
      if (!oportunidade || !proposta) {
        return false;
      }

      return (
        proposta?.status?.podeSolicitarAprovacao &&
        oportunidade.propostaNecessitaAprovacaoGerente
      );
    },
    reeditarPropostaInput: reeditarPropostaHandler,
    requestApprovalHandlerInput: ({
      requestProposalApproval,
      showAlert,
      showLoading,
      hideLoading,
    }) => ({ idProposta, idOportunidade, paymentSumOk }) => {
      if (!paymentSumOk) {
        showAlert({
          title: 'Erro ao solicitar aprovação',
          message: 'As formas de pagamento não totalizam o valor da proposta.',
        });

        return null;
      }
      showLoading();
      const variables = { idProposta, idOportunidade };

      return requestProposalApproval({
        variables,
      }).then(() => {
        hideLoading();
        showAlert({
          message: 'Aguarde a aprovação da proposta.',
          title: 'Aprovação solicitada',
        });
      });
    },
  })
);

export const OpportunityTopMenu = enhance(
  ({
    open,
    setOpen,
    anchorEl,
    setAnchorEl,
    podeReeditarProposta,
    requestApprovalHandlerInput,
    oportunidade,
    proposta,
    reeditarPropostaInput,
    canRequestApproval,
    paymentSumOk,
  }) => {
    const classes = useGlobalStyle();
    const onClick = event => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };
    const canRequestApprovalFlag = canRequestApproval({
      oportunidade,
      proposta,
    });
    const showMenu = podeReeditarProposta || canRequestApprovalFlag;

    return (
      <div>
        {showMenu && (
          <IconButton
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            color="inherit"
          >
            <MoreVert onClick={onClick} />
          </IconButton>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={() => setOpen(!open)}
        >
          {podeReeditarProposta && (
            <MenuItem
              onClick={() => {
                reeditarPropostaInput({ oportunidade });
                setOpen(false);
              }}
            >
              <ListItemIcon className={classes.icon}>
                <Edit />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                primary="Reeditar proposta"
              />
            </MenuItem>
          )}

          {canRequestApproval({ oportunidade, proposta }) && (
            <MenuItem
              onClick={() => {
                requestApprovalHandlerInput({
                  paymentSumOk,
                  idProposta: proposta.id,
                  idOportunidade: oportunidade.id,
                });
                setOpen(false);
              }}
            >
              <ListItemIcon className={classes.icon}>
                <CheckCircle />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.primary }}
                primary="Solicitar Aprovação"
              />
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
);
