import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import motivosEncerramentoFichaVisitaSelecionaveisQueryGql from './MotivosEncerramentoFichaVisitaSelecionaveisQueryGql.graphql';
import { displayLoadingState } from '../../../utils/displayLoadingState';

export const motivosEncerramentoFichaVisitaSelecionaveisQuery = graphql(
  motivosEncerramentoFichaVisitaSelecionaveisQueryGql,
  {
    name: 'motivosEncerramentoFichaVisitaSelecionaveisQuery',
    options: {
      fetchPolicy: 'cache-first',
    },
  }
);

export const MotivosEncerramentoFichaVisitaSelecionaveisQueryHOC = compose(
  motivosEncerramentoFichaVisitaSelecionaveisQuery,
  displayLoadingState({
    name: 'motivosEncerramentoFichaVisitaSelecionaveisQuery',
    onCacheLoadOnlyQueryName: 'motivosEncerramentoFichaVisitaSelecionaveis',
  })
);
