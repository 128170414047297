import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
  addEntityId,
  findEntity,
  removeEntityId,
} from '../../reducers/offlineMutationsEntityId';

const mapDispatchToProps = dispatch => ({
  addEntityId: ({ id, mutationName, entityName }) => {
    dispatch(addEntityId({ id, mutationName, entityName }));
  },
  removeEntityId: ({ id, mutationName, entityName }) => {
    dispatch(removeEntityId({ id, mutationName, entityName }));
  },
});

const mapStateToProps = state => ({ ...state.offlineMutationsEntityId });

export const getOfflineEntityIdContext = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    isIdInEntitiesList: ({ entitiesIds }) => ({
      id,
      entityName = null,
      mutationName = null,
      mutationNames = null,
    }) => {
      if (!entityName && !mutationName) {
        return null;
      }

      return (
        entitiesIds.filter(
          findEntity({
            entityName,
            mutationName: mutationName || mutationNames,
            id,
          })
        ).length > 0
      );
    },
  })
);
