import { Drawer, ListItem, ListItemText, Switch } from '@material-ui/core';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@tecsinapse/ui-kit';
import { Search } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SelectMultiComponent } from '../utils/FormUtils';
import { FullDisplayContainer } from '../utils/ui/styledComponents';

const listStyles = theme => ({
  marginBottom: { marginBottom: `${theme.spacing(1)}px` },
});

const useAtividadeFilterBottomSheetStyles = makeStyles({
  ...listStyles,
});

const AgendaFilterBottomSheetComponent = ({
  open,
  onClose,
  tipoOrigemOportunidade,
  setAgendaFiltro,
  origensOportunidade,
  prospeccoesSemContato,
}) => {
  const [origensSelecionadas, setOrigensSelecionadas] = useState(
    origensOportunidade
  );
  const [
    prospeccoesSemContatoSelecionado,
    setProspeccoesSemContatoSelecionado,
  ] = useState(prospeccoesSemContato);

  const onRequestSearch = () => {
    setAgendaFiltro(origensSelecionadas, prospeccoesSemContatoSelecionado);
    onClose();
  };

  const updateState = () => {
    setOrigensSelecionadas(origensOportunidade);
    setProspeccoesSemContatoSelecionado(prospeccoesSemContato);
  };

  const classes = useAtividadeFilterBottomSheetStyles();

  const tiposOrigem = [];

  (tipoOrigemOportunidade || []).forEach(o => {
    const tipoOrigem = {};

    tipoOrigem.value = o.id;
    tipoOrigem.label = o.descricao;
    tiposOrigem.push(tipoOrigem);
  });

  const style = {
    justifyContent: 'flex-end',
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => {
        updateState();
        onClose();
      }}
    >
      <div>
        <FullDisplayContainer>
          <ListItem className={classes.marginBottom}>
            <Typography variant="h6">Filtros</Typography>
          </ListItem>
          <ListItem className={classes.marginBottom}>
            <ListItemText>
              <SelectMultiComponent
                label="Leads"
                options={tiposOrigem}
                value={origensSelecionadas}
                setValue={setOrigensSelecionadas}
                fullWidth
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <FormControlLabel
                control={
                  <Switch
                    checked={prospeccoesSemContatoSelecionado}
                    onChange={(e, v) => setProspeccoesSemContatoSelecionado(v)}
                  />
                }
                label="Apenas Sem Contato"
                labelPlacement="end"
              />
            </ListItemText>
          </ListItem>
          <ListItem style={style}>
            <Button
              onClick={() => {
                onRequestSearch();
              }}
              title="Pesquisar"
            >
              <Search />
            </Button>
          </ListItem>
        </FullDisplayContainer>
      </div>
    </Drawer>
  );
};

export const AgendaFilterBottomSheet = AgendaFilterBottomSheetComponent;
