import { makeStyles } from '@material-ui/styles';
import { defaultGreen } from '../../../app/globalStyle';

export const styleCardSemContato = {
  border: '1px solid rgb(217, 217, 217)',
  boxShadow: 'none',
};

export const cardStyles = makeStyles(theme => ({
  titleClient: {
    display: 'flex',
  },
  titleClientAvatar: {
    position: 'inicial',
  },
  titleInitialPoint: {
    display: 'flex',
  },
  titleInitialPointAvatar: {
    position: 'inicial',
  },
  subContentMain: {
    display: 'flex',
    borderTop: '1px solid rgb(217, 217, 217)',
  },
  subContentLeft: {
    width: '50%',
    padding: 10,
    borderRight: '1px solid rgb(217, 217, 217)',
    paddingTop: '16px',
  },
  subContentRight: {
    width: '50%',
    padding: 10,
    paddingTop: '16px',
  },
  subContentText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '10px',
  },
  titleKm: {
    textAlign: 'center',
    fontWeight: 500,
    color: '#616161',
    fontSize: '10px',
  },
  endereco: {
    fontWeight: 500,
    color: '#616161',
    fontSize: '10px',
    textTransform: 'capitalize',
  },
  localizacao: {
    fontWeight: 'bold',
    color: 'black',
  },
  cpfCnpj: {
    color: '#616161',
  },
  gridItemAvatar: {
    paddingLeft: 0,
  },
  gridItemTitulo: {
    paddingLeft: 0,
  },
  titulo: {
    paddingTop: '2px',
    width: '72vw',
  },
  chipTitle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    fontWeight: 500,
  },
  chipDoneLabel: {
    marginLeft: '1px',
  },
  chipDone: {
    fontSize: '10px',
    height: 'auto',
    color: '#FFF',
    backgroundColor: defaultGreen,
  },
  divClienteNome: {
    textTransform: 'capitalize',
  },
}));
