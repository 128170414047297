import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { destroy } from 'redux-form';
import {
  hideCreateOpportunity,
  showCreateOpportunity,
} from '../../../reducers/modal';
import { globalStyle } from '../../app/globalStyle';
import { ClientCreateOptionsModal } from './ClientCreateOptionsModal';
import { OPPORTUNITY_CREATE_MODAL_NAME } from './CreateOpportunityFormConsts';
import { withStoreConsumer } from '../../utils/StoreConsumer';
import { setSelectedRoute } from '../../../reducers/routesReducer';

const enhance = compose(
  withRouter,
  withStoreConsumer,
  withHandlers({
    closeAlert: ({ store }) => () => {
      store.dispatch(hideCreateOpportunity());
      store.dispatch(destroy(OPPORTUNITY_CREATE_MODAL_NAME));
    },
  }),
  connect(
    state => ({
      ...state.createOpportunityModal,
    }),
    dispatch => ({
      setSelectedRoute: obj => {
        dispatch(setSelectedRoute(obj));
      },
      createModal: ({ id, clientCreateOption, message, ...rest }) =>
        dispatch(
          showCreateOpportunity({
            idCliente: id,
            clientCreateOption,
            message,
            ...rest,
          })
        ),
    })
  ),
  withStyles({ overflow: globalStyle.overflow, form: globalStyle.form })
);

export const ClientCreateOptionsModalContainer = enhance(
  ClientCreateOptionsModal
);
