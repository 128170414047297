import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Share } from '@material-ui/icons';
import { IconButton } from '@tecsinapse/ui-kit';
import { eventPrintProposal } from '../utils/GAUtils';

export const OpportunityPrintWithTemplate = props => {
  const {
    printProposal,
    canShareProposal,
    proposta,
    oportunidade,
    dealersImpressaoProposta,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickPrintProposal = idDealerFilial => {
    eventPrintProposal();

    if (canShareProposal({ proposta, oportunidade })) {
      printProposal(proposta.id, idDealerFilial);
    }
  };

  return (
    <div>
      <IconButton aria-owns="menu-appbar" aria-haspopup="true" color="inherit">
        <Share onClick={handleClick} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dealersImpressaoProposta &&
          Object.values(dealersImpressaoProposta).map(filial => (
            <MenuItem
              onClick={() => {
                handleClose();
                onClickPrintProposal(filial.id);
              }}
            >
              {filial.nome}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
