import { useNotificadorHook } from 'components/Notificacoes';
import { isModuloCRM } from 'components/utils/utils';
import { useAtividades } from './useAtividades';

export const useIndicadoresMenu = () => {
  const { atrasadas = 0 } = useAtividades();
  const { notificacoesNaoVisualizadas } = useNotificadorHook();

  if (isModuloCRM) {
    return {
      notificacoesCount: atrasadas + notificacoesNaoVisualizadas.length,
    };
  }

  return {
    notificacoesCount: atrasadas,
  };
};
