import { useLayoutEffect, useState } from 'react';

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /android/.test(userAgent);
};
// Detects if device is in standalone mode
export const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;

export const visibleWidth = () => {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    0
  );
};

export const visibleHeight = () => {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    0
  );
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    if (!isAndroid()) {
      window.addEventListener('resize', updateSize);
    }
    window.addEventListener('orientationchange', updateSize);
    updateSize();

    return () => {
      if (!isAndroid()) {
        window.removeEventListener('resize', updateSize);
      }

      return window.removeEventListener('orientationchange', updateSize);
    };
  }, []);

  return size;
}
