import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../../utils/graphql/graphqlOfflineMutation';
import inativarContatoMutationGql from './inativarContatoMutationGql.graphql';

export const InativarContatoMutation = graphqlOfflineMutation(
  inativarContatoMutationGql,
  {
    name: 'inativarClienteContato',
    mutationName: 'inativarContatoMutationGql',
    description: async ({ variables: { id }, apolloClient }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `ClienteContato:${id}`,
          fragment: gql`
            fragment ContatoInativacao on ClienteContato {
              id
              nome
              idCliente
            }
          `,
        });

        const { nome: nomeClient } = await apolloClient.readFragment({
          id: `Cliente:${data.idCliente}`,
          fragment: gql`
            fragment ClienteNomeFragment on Cliente {
              id
              nome
            }
          `,
        });

        const { nome } = data;

        return `Remoção de contato, nome: ${nome} do cliente ${nomeClient}`;
      } catch (e) {
        return `Remoção de contato.`;
      }
    },
  }
);
