import { ReactReduxContext } from 'react-redux';
import React from 'react';

export function StoreConsumer(props) {
  const { children, ...rest } = props;

  return (
    <ReactReduxContext.Consumer>
      {({ store: storeInput }) => {
        return React.Children.map(children, child =>
          React.cloneElement(child, {
            store: storeInput,
            ...rest,
          })
        );
      }}
    </ReactReduxContext.Consumer>
  );
}

export const withStoreConsumer = Component => props => (
  <StoreConsumer>
    <Component {...props} />
  </StoreConsumer>
);
