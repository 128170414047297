import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import TiposDeContatoQueryGql from './TiposDeContatoQueryGql.graphql';

const hoc = graphql(TiposDeContatoQueryGql, {
  name: 'tiposDeContatoQuery',
  options: ({ tipoAtividade }) => ({
    fetchPolicy: 'cache-first',
    variables: { tipoAtividade },
  }),
});

export const TiposDeContatoQueryHoc = compose(
  hoc,
  displayLoadingState({
    name: 'tiposDeContatoQuery',
    onCacheLoadOnlyQueryName: 'tiposDeContato',
  })
);
