import { compose, withHandlers } from 'recompose';
import { isStringNotBlank } from '@tecsinapse/es-utils/build';
import { connectToFormFields } from '../FormUtils';

export const withTextTransform = ({ formName, fields }) =>
  compose(
    withHandlers({
      setTextAction: ({ change }) => (tipo, input, fieldNameInput) => {
        let outPutText;
        const stringNotBlank = isStringNotBlank(input);

        if (input && stringNotBlank && tipo === 'uppercase') {
          outPutText = input.toUpperCase();
        } else if (input && stringNotBlank && tipo === 'lowercase') {
          outPutText = input.toLowerCase();
        } else {
          outPutText = input;
        }
        change(fieldNameInput, outPutText);
      },
    }),
    connectToFormFields(formName, fields)
  );
