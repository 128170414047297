import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { ChatBubble } from '@material-ui/icons';
import { Button } from '@tecsinapse/ui-kit';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import { useHistory } from 'react-router';
import { FloatingButton } from '../utils/FloatingButton';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../utils/uiUtils';
import { Timeline } from './Timeline';
import { globalStyle } from '../app/globalStyle';
import { HistoricoTimelineItemCard } from './HistoricoTimelineItemCard';
import { tipoFormatado } from './AtividadeTimelineContainer';
import {
  eventAddCommentTimeLine,
  eventSeeMoreTimeline,
} from '../utils/GAUtils';
import { FullDisplayContainer } from '../utils/ui/styledComponents';

const style = {
  borderBottom: 'solid 1px rgba(0, 0, 0, .1)',
  marginBottom: '4px',
};
const style1 = {
  textAlign: 'center',
  marginBottom: '8px',
};

export const atividadeTimelineStyle = makeStyles(theme => ({
  divFlexContainer: { display: 'flex', flexFlow: 'column' },
  ...globalStyle(theme),
}));

export const AtividadeTimeline = ({
  exibeFloat = false,
  timeline,
  timelineOpenSize,
  itens,
  id,
  timelineFullSize,
  title = 'Linha do Tempo',
  idOrigem = null,
  setTimelineOpenSize,
}) => {
  const history = useHistory();
  const classes = atividadeTimelineStyle();
  const timeLineFilted = timeline.slice(0, timelineOpenSize);
  const showEnd = timelineFullSize > timelineOpenSize && exibeFloat;
  const action = () => history.goBack();

  const redirectToNewTime = item => {
    history.push(`/timeline/${item.id}/${tipoFormatado(item)}`);
  };

  const handleMore = () => {
    setTimelineOpenSize(timelineOpenSize + 3);
  };

  return (
    <>
      <RenderAppLogo />
      {updateAppTitle(title)}
      <ShowBackButton history={history} action={action} />
      <div className={classes.divFlexContainer}>
        <div>
          <div className="bootstrap-style-container">
            <Timeline items={timeLineFilted} showEnd={showEnd} />
          </div>
        </div>

        {showEnd && (
          <div style={style}>
            <div style={style1}>
              <Button
                customVariant="warning"
                classes={{ root: classes.marginTop10 }}
                onClick={() => {
                  eventSeeMoreTimeline();
                  handleMore();
                }}
              >
                Ver Mais
              </Button>
            </div>
          </div>
        )}

        <FullDisplayContainer>
          {itens.length > 0 &&
            itens
              .filter(
                item => isEmptyOrNull(idOrigem) || item.idEntidade !== idOrigem
              )
              .map(item => (
                <HistoricoTimelineItemCard
                  key={item.id}
                  item={item}
                  cardOnClick={() => redirectToNewTime(item)}
                />
              ))}
        </FullDisplayContainer>
      </div>
      {exibeFloat && (
        <FloatingButton
          icon={<ChatBubble />}
          onClick={() => {
            eventAddCommentTimeLine();
            history.push(`/adicionarComentarioTimeline/${id}`);
          }}
        />
      )}
    </>
  );
};
