import React from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Table } from '@tecsinapse/table/build/Table/Table';
import { DirectionsRun } from '@material-ui/icons';

import { EmptyState } from '@tecsinapse/ui-kit/build/components/EmptyState/EmptyState';

import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import { mdiMapMarkerDistance } from '@mdi/js';
import Icon from '@mdi/react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  clientGetEmailAndPhoneList,
  EntrarEmContatoDialog,
} from '../../../utils/EntrarEmContatoDialog';
import { displayLoadingState } from '../../../utils/displayLoadingState';

import { RouteDialog } from '../../../utils/RouteDialog';
import { GetClientsComPaginacaoQuery } from '../data/queries/GetClientesComPaginacaoQuery';
import { BottomSheet } from './BottomSheet';
import { InformarContatoDialog } from '../../../utils/InformarContatoDialog';
import { getEnderecoAndCidade } from '../../../utils/Endereco/enderecoUtils';
import { globalStyle } from '../../../app/globalStyle';
import { withUsuarioLogado } from '../../../UsuarioLogado/withUsuarioLogado';

const useStyle = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  iconMap: {
    fontSize: '15px',
    color: '#616161',
  },
  labelMap: {
    fontSize: '11px',
    fontWeight: 700,
    color: '#616161',
    paddingLeft: '4px',
    paddingTop: '3px',
  },
  ...globalStyle,
});

const Listing = ({
  cardOnClick,
  selectedClient,
  showCallClientModal,
  setShowCallClientModal,
  goToAddClient,
  showRouteModal,
  showInformCallModal,
  setShowInformCallModal,
  setShowRouteModal,
  showCheckInConfirmacao,
  ...props
}) => {
  const {
    data: { loadMore, loading, ...rest },
    atividades,
    usuarioLogado,
    history,
  } = props;
  const { clientes: { clientes = [], hasNext } = {} } = rest || {};
  const classes = useStyle();

  const getClientes = async filters => {
    const { startIndex, stopIndex } = filters;

    if (hasNext && !clientes[stopIndex] && stopIndex !== null && !loading) {
      loadMore();
    }

    return {
      data: clientes.slice(
        startIndex,
        stopIndex !== null ? stopIndex : undefined
      ),
      totalCount: hasNext ? clientes.length + 15 : clientes.length,
    };
  };

  const columns = [];

  const iconMap = {
    field: 'iconMap',
    customRender: row => {
      if (!row.distanciaKm) {
        return null;
      }

      return (
        <div className={classes.gridFlex}>
          <SvgIcon className={classes.iconMap}>
            <Icon path={mdiMapMarkerDistance} />
          </SvgIcon>
          <Typography
            className={classes.labelMap}
          >{`${row.distanciaKm} Km`}</Typography>
        </div>
      );
    },
  };

  if (showCheckInConfirmacao) {
    columns.push(iconMap);
  }

  columns.push(
    {
      title: 'Nome',
      field: 'nome',
    },
    {
      title: 'CPF/CNPJ',
      field: 'cpfCnpj',
      customRender: row => (
        <div>
          <Typography variant="subtitle2" color="textPrimary">
            {row.cpfCnpj ? row.cpfCnpj : 'N/D'}
          </Typography>
        </div>
      ),
    },
    {
      title: 'Telefones',
      field: 'telefones',
      customRender: row => {
        const { phoneList } = clientGetEmailAndPhoneList(row, usuarioLogado);

        return (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {phoneList.length === 0 && 'N/D'}
              {phoneList
                .slice(0, 2)
                .map(({ phone }) => phone)
                .join(', ')}
              {phoneList.length > 2 && '...'}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Email',
      field: 'email',
      customRender: row => {
        const { emailList } = clientGetEmailAndPhoneList(row, usuarioLogado);

        return (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {emailList.length === 0 && 'N/D'}
              {emailList
                .slice(0, 2)
                .map(({ email }) => email)
                .join(', ')}
              {emailList.length > 2 && '...'}
            </Typography>
          </div>
        );
      },
    }
  );

  columns.push({
    title: 'Endereço',
    field: 'endereco',
    customRender: row => {
      const enderecoCompleto = getEnderecoAndCidade(row);

      return (
        <div>
          <Typography variant="subtitle2" color="textPrimary">
            {enderecoCompleto || 'N/D'}
          </Typography>
        </div>
      );
    },
  });

  const redirectToRealizarCheckIn = clienteId => {
    if (process.env.REACT_APP_MODULOS === 'CRM') {
      history.push(`/checkInCRMConfirmacao/${clienteId}`);
    } else {
      history.push(`/checkInConfirmacao/${clienteId}`);
    }
  };

  const buttonRealizarCheckIn = {
    field: 'realizarCheckIn',
    customRender: row => {
      return (
        <div>
          <Button
            customVariant="default"
            fullWidth
            onClick={() => {
              redirectToRealizarCheckIn(row.id);
            }}
          >
            Realizar Check-In
          </Button>
        </div>
      );
    },
  };

  if (showCheckInConfirmacao) {
    columns.push(buttonRealizarCheckIn);
  }

  return (
    <>
      <Table
        columns={columns}
        data={getClientes}
        rowId={row => row.id}
        onRowClick={client => {
          if (!showCheckInConfirmacao) {
            cardOnClick(null, client);
          }
        }}
        classes={classes}
        empytStateComponent={
          <EmptyState
            IconComponent={DirectionsRun}
            message="Não há nenhum cliente por aqui ainda."
          />
        }
      />

      {!showCheckInConfirmacao && (
        <BottomSheet {...props} selectedClient={selectedClient} />
      )}

      <EntrarEmContatoDialog
        client={selectedClient}
        open={showCallClientModal}
        handleClose={() => {
          setShowCallClientModal(false);
        }}
      />

      <InformarContatoDialog
        client={selectedClient}
        atividades={atividades}
        open={showInformCallModal}
        handleClose={() => {
          setShowInformCallModal(false);
        }}
      />

      <RouteDialog
        client={selectedClient}
        open={showRouteModal}
        handleClose={() => setShowRouteModal(false)}
      />
    </>
  );
};

const enhance = compose(
  withUsuarioLogado,
  GetClientsComPaginacaoQuery,
  displayLoadingState({ onLoadMore: false })
);

export const ClienteSearchListing = enhance(Listing);
