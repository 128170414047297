import { gql, useLazyQuery } from '@apollo/react-hoc';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mdiArrowLeft, mdiForum } from '@mdi/js';
import Icon from '@mdi/react';
import { defaultOrange } from 'components/app/globalStyle';
import { useUsuarioLogado } from 'hooks/useUsuarioLogado';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { keycloak } from '../../core/offline/kcOfflineStub';
import { chatStore } from './ChatStore';

const query = gql(`
    query ConfiguracaoChat($modulo: String) {
        configuracaoChat(modulo: $modulo) {
            ativo
            chatApiUrl
            params
            getInitialStatePath
            deleteChatPath
            createPath
            openImmediately
            clickOnUnreadOpenFirstAction
            showMessagesLabel
            navigateWhenCurrentChat
            onChatTitle
            showDiscardOption
            onlyMessageManagement
            canSendNotification
        }
    }
`);

const defaultChatInitConfig = {
  userkeycloakId: keycloak.idTokenParsed?.sub,
};

const style = { marginBottom: 16 };

export const Chat = () => {
  const usuario = useUsuarioLogado();
  const { state, dispatch } = useContext(chatStore);
  const [buscaConfiguracoesChat, chatConfig] = useLazyQuery(query);
  const { configuracaoChat } = chatConfig?.data || {};
  const [open, setOpen] = React.useState(false);

  defaultChatInitConfig.userkeycloakId = keycloak.idTokenParsed?.sub;

  useEffect(() => {
    if (defaultChatInitConfig.userkeycloakId) {
      buscaConfiguracoesChat({
        variables: {
          modulo: usuario.usuarioConsultorExterno
            ? 'CONSULTOR_EXTERNO'
            : 'VENDAS',
        },
      });
    }
  }, [buscaConfiguracoesChat, usuario.usuarioConsultorExterno]);

  if (configuracaoChat) {
    Object.keys(configuracaoChat).forEach(key => {
      if (!configuracaoChat[key]) {
        delete configuracaoChat[key];
      }
    });
  }

  const customConfigs = {
    ...defaultChatInitConfig,
    ...configuracaoChat,
  };

  useEffect(() => {
    if (customConfigs.ativo && !state.chatAtivo) {
      !state.chatAtivo && dispatch({ type: 'active' });
    }
  }, [customConfigs, dispatch, state]);

  if (customConfigs.ativo) {
    return (
      <>
        <ChatIcon onClick={() => setOpen(true)}>
          {chatConfig.loading ? (
            <CircularProgress size="1em" />
          ) : (
            <Icon path={mdiForum} size={1} />
          )}
        </ChatIcon>
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
          <AppBar variant="outlined" position="static" style={style}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
              >
                <Icon path={mdiArrowLeft} size={1} />
              </IconButton>
              <Typography variant="h6">Aviso de remoção</Typography>
            </Toolbar>
          </AppBar>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScu7iP6zU1ur7qMTL23Kmkrr7jz8MXZim-FRzivbl4KckWuPQ/viewform?embedded=true"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="chat_frame"
          >
            Carregando…
          </iframe>
        </Dialog>
      </>
    );
  }

  return null;
};

const ChatIcon = styled(IconButton)`
  position: absolute;
  top: 0.5em;
  right: 0;
  background-color: ${defaultOrange};
  padding: 5px 10px;
  border-radius: 0px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;
