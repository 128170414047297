import styled from 'styled-components';
import grey from '@material-ui/core/colors/grey';

export const defaultGreen = '#4caf50';
export const defaultGrey = grey[700];

export const checkInRealizadoCardStyles = theme => ({
  buttonRegistrarVisita: {
    padding: '8px 16px',
  },
  avatarMapMarker: {
    backgroundColor: defaultGrey,
    position: 'center',
  },
  verticalAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  gridContainer: {
    '& > .MuiGrid-item': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  chipTitle: {
    paddingLeft: '5px',
    paddingRight: '5px',
    fontWeight: 500,
  },
  chipDoneLabel: {
    marginLeft: '1px',
  },
  chipDone: {
    marginTop: '-6px',
    fontSize: '10px',
    height: 'auto',
    padding: '1px',
    color: '#FFF',
    marginLeft: '6px',
    backgroundColor: defaultGreen,
  },
  labelSemImagem: {
    marginTop: '-6px',
    fontSize: '10px',
    height: 'auto',
    padding: '1px',
    color: '#616161',
    fontWeight: 500,
    paddingLeft: '4px',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  labelVerMapa: {
    fontWeight: 500,
    fontSize: '13px',
    color: '#616161',
    paddingLeft: '5px',
  },
  labelSemImage: {
    fontSize: '10px',
    fontWeight: 500,
    color: '#616161',
    paddingLeft: '5px',
  },
  iconSemImagem: {
    fontSize: '15px',
    color: 'rgba(108, 104, 98, .85 )',
    paddingLeft: '10px',
    paddingTop: '3px',
  },
  iconCalendar: {
    fontSize: '15px',
    color: 'rgba(108, 104, 98, .85 )',
    paddingRight: '5px',
  },
  gridItemButtonRegistrarSemImage: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonRegistrarVisitaSmall: {
    fontSize: '10px',
  },
  title: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#616161',
  },
  divCalendar: {
    paddingTop: '3px',
  },
  gridFlex: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  titleComponents: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: '5px',
  },
  iconComponents: {
    textAlign: 'right',
    flexGrow: 1,
  },
  cpfcnpj: {
    paddingBottom: '5px',
  },
});

export const styleCardSemContato = {
  border: '1px solid rgb(217, 217, 217)',
  boxShadow: 'none',
};

export const checkInDialog = {
  imageMobile: {
    width: '100%',
    height: 'auto',
  },
  image: {
    width: 'auto',
    maxHeight: '91vh',
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paddingZero: {
    padding: 0,
  },
};

export const checkInListStyles = {
  circularProgress: {
    margin: '0 45%',
  },
  filterDiv: {
    textAlign: 'right',
    paddingRight: '11px',
  },
  filterIcon: {
    marginTop: '-3px',
  },
  filterTitle: {
    fontWeight: 600,
  },
};

export const checkInFilterBottomSheetStyles = {
  paddingTopBottomZero: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export const FullDisplayContainer = styled.div`
  width: auto;
  margin-bottom: ${props => props && props.theme && props.theme.spacing(1)}px;
`;
