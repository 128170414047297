import { compose, getContext, withHandlers, withState } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RotasComponent } from './RotasComponent';
import { clienteBottomSheet } from '../../Client/clienteBottomSheet/clienteBottomSheet';
import { createRoteirizacaoMutation } from '../data/createRoteirizacaoMutationGpl';
import { setSelectedRoute } from '../../../reducers/routesReducer';
import { withUltimaRoteirizacaoByConsultor } from '../data/ultimaRoterizacaoByConsultorQuery';
import { withUsuarioLogado } from '../../UsuarioLogado/withUsuarioLogado';

const mapDispatchToProps = dispatch => ({
  setSelectedRoute: obj => {
    dispatch(setSelectedRoute(obj));
  },
});

const mapStateToProps = ({ suggestedRoutes: { selectedRoute } }) => ({
  selectedRoute,
});

export const RotasContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('snackBarOpen', 'setSnackBarOpen', true),
  withState('selectedClient', 'setSelectedClient', null),
  withState('showCallClientModal', 'setShowCallClientModal', false),
  withState('showInformCallModal', 'setShowInformCallModal', false),
  withState('showRouteModal', 'setShowRouteModal', false),
  withState('loading', 'setLoading', false),
  createRoteirizacaoMutation,
  getContext({
    showCreateAtividadeFichaVisitaModal: PropTypes.func,
    showCreateRoteirizacaoModal: PropTypes.func,
  }),
  withUsuarioLogado,
  withHandlers({
    cardOnClick: ({ setSelectedClient }) => (e, selectedClient) => {
      e.preventDefault();
      setSelectedClient(selectedClient);
    },
    hideDrawer: ({ setSelectedClient }) => () => {
      setSelectedClient(null);
    },
    onBottomSheetClick: clienteBottomSheet,
  }),
  withUltimaRoteirizacaoByConsultor()
)(RotasComponent);
