import { useNotificadorHook } from 'components/Notificacoes';
import moment from 'moment';
import { useCallback } from 'react';

export const useLembreteAtividadeHook = atividade => {
  const notificacaoHook = useNotificadorHook();

  const {
    notificacoesNaoEnviadas,
    notificacoesNaoVisualizadas,
    handleAdicionaNotificacao,
  } = notificacaoHook;

  const verificaPossuiLembrete = useCallback(() => {
    return notificacoesNaoEnviadas.find(
      notificacao => String(notificacao.relacionamentoId) === atividade?.id
    );
  }, [notificacoesNaoEnviadas, atividade]);

  const verificaPossuiLembreteNaoVisualizado = useCallback(() => {
    return notificacoesNaoVisualizadas.find(
      notificacao => String(notificacao.relacionamentoId) === atividade?.id
    );
  }, [notificacoesNaoVisualizadas, atividade]);

  const handleCriaLembrete = useCallback(
    (keycloakId, horario) => {
      return handleAdicionaNotificacao(
        keycloakId,
        'LEMBRETE_ATIVIDADES_APP',
        atividade.id,
        horario,
        JSON.stringify({
          id: Number(atividade.id),
          dataHora: moment(horario).format('DD/MM/YYYY HH:mm'),
          descricao: atividade.descricao,
          nomeCliente: atividade.cliente.nome,
        })
      );
    },
    [atividade, handleAdicionaNotificacao]
  );

  return {
    verificaPossuiLembrete,
    verificaPossuiLembreteNaoVisualizado,
    handleCriaLembrete,
    ...notificacaoHook,
  };
};
