import { gql, useQuery } from '@apollo/react-hooks';
import { enumGetNames, TipoAtividade } from '../../../core/Enums';

const GetIndicadoresAtividadesQueryGql = gql`
  query GetIndicadoresAtividadesQuery($tipos: [TipoAtividade]) {
    indicadoresAtividades(filter: { tipos: $tipos }) {
      atrasadas
      hoje
    }
  }
`;

export const useAtividades = () => {
  const types = enumGetNames(TipoAtividade).filter(
    e => e !== TipoAtividade.FATURAMENTO.name
  );
  const { data = {}, loading } = useQuery(GetIndicadoresAtividadesQueryGql, {
    fetchPolicy: 'cache-and-network',
    variables: {
      tipos: types,
    },
  });
  const { atrasadas = 0, hoje = 0 } = data.indicadoresAtividades || {};

  return { atrasadas, hoje, loading };
};
