import { compose, withProps, withState } from 'recompose';
import { FollowUp48Component } from './FollowUp48Component';
import {
  AtividadesFup48PendenteQueryDataName,
  AtividadesFup48PendenteQueryHoc,
  AtividadesFup48PendenteQueryName,
} from '../../../data/AtividadesFup48PendenteQuery';
import { TipoIndicadorFup48 } from '../../../../../core/Enums';

export const FollowUp48Card = compose(
  withState(
    'tipoIndicadorFup48',
    'setTipoIndicadorFup48',
    TipoIndicadorFup48.HOJE
  ),
  AtividadesFup48PendenteQueryHoc,
  withProps(
    ({
      [AtividadesFup48PendenteQueryName]: {
        [AtividadesFup48PendenteQueryDataName]: idsOportunidade = [],
      } = {},
    }) => ({
      idsOportunidade,
    })
  )
)(FollowUp48Component);
