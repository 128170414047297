import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { getAlertContext } from '../alertContext';
import { confirm } from '../dialogs/ConfirmationDialog';
import { getLoadingSpinnerContext } from '../loadingSpinnerContext';
import { ErrorOffline } from '../../../core/offline/exception/ErrorOffline';
import { mutationsErrorsHandler } from '../mutationsErrorsHandler';

export const RemoveHOC = ({ mutation, mutationName }) =>
  compose(
    mutation,
    getLoadingSpinnerContext,
    getAlertContext,
    withRouter,
    withHandlers({
      removeOnClick: ({
        history,
        hideLoading,
        showLoading,
        showAlert,
        ...props
      }) => ({ id, goBack = false }) => {
        confirm('Você realmente deseja remover?').then(() => {
          showLoading();
          props[mutationName]({ variables: { id } })
            .then(() => {
              hideLoading();

              if (goBack) {
                history.goBack();
              }
            })
            .catch(e => {
              if (e instanceof ErrorOffline) {
                hideLoading();

                return;
              }

              mutationsErrorsHandler({ showAlert, hideLoading })(e);
            });
        });
      },
    })
  );
