import { createAction, handleActions } from 'redux-actions';

const generateRandomKey = () => Math.random().toString(36).substring(7);

const defaultState = {
  agendaKey: generateRandomKey(),
};

export const newAgendaKeyAction = createAction('NEW_KEY_ALERT');
export const homeReducer = handleActions(
  {
    [newAgendaKeyAction]: state => ({
      ...state,
      agendaKey: generateRandomKey(),
    }),
  },
  defaultState
);
