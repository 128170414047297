import gql from 'graphql-tag';
import React from 'react';
import moment from 'moment';
import { genericQueryHoc } from '../../utils/crud/genericQueryHoc';
import { getLocalDateTimeString } from '../../utils/dateUtils';
import { client } from '../../../index';
import { CardLoading } from '../../utils/CardLoading';

const IndicadorVendasAgrupadoGql = gql`
  query indicadoresCrmQueryl(
    $idDealer: ID!
    $idUnidadeNegocio: ID
    $usuarioId: ID
    $anoMesInicial: DateTime!
    $anoMesFinal: DateTime!
  ) {
    indicadoresCrm(
      idDealer: $idDealer
      usuarioId: $usuarioId
      idUnidadeNegocio: $idUnidadeNegocio
      anoMesInicial: $anoMesInicial
      anoMesFinal: $anoMesFinal
    ) {
      indicadoresOportunidades {
        dealer {
          id
          nome
        }
        usuario {
          id
        }
        quantidadeOportunidades {
          temperatura
          valorTotal
          ids
        }
      }
      indicadoresVendaPorDealerUsuario {
        dealer {
          id
          nome
        }
        usuario {
          id
        }
        diasUteisAteFinalPeriodo
        totalObjetivoDealer
        indicador {
          indicador
          quantidadeRealizado
          totalObjetivo
        }
      }
    }
  }
`;

export function getMetasEResultados(indicadores = {}) {
  if (indicadores.indicador == null) {
    return [0, 0, 0, 0];
  }

  const {
    diasUteisAteFinalPeriodo = 0,
    totalObjetivoDealer = 0,
    indicador: {
      totalObjetivo: meta = 0,
      quantidadeRealizado: realizado = 0,
    } = {},
  } = indicadores;
  const metaProcessed = totalObjetivoDealer > meta ? totalObjetivoDealer : meta;
  const porcentagem =
    metaProcessed !== 0 ? Math.round((100 * realizado) / metaProcessed) : null;

  return [metaProcessed, realizado, porcentagem, diasUteisAteFinalPeriodo];
}

const options = ({ dealer, unidadeNegocio, periodo, usuario, anoMesFinal }) => {
  const variables = {
    idDealer: dealer && dealer.id,
    usuarioId: usuario && usuario.id,
    idUnidadeNegocio: unidadeNegocio && unidadeNegocio.id,
    anoMesInicial: getLocalDateTimeString(moment(anoMesFinal).startOf(periodo)),
    anoMesFinal: getLocalDateTimeString(moment(anoMesFinal).endOf(periodo)),
  };

  if (periodo === 'lastMonth') {
    variables.anoMesInicial = getLocalDateTimeString(
      moment(anoMesFinal).subtract(1, 'months').startOf('month')
    );
    variables.anoMesFinal = getLocalDateTimeString(
      moment(anoMesFinal).subtract(1, 'months').endOf('month')
    );
  }

  return {
    fetchPolicy: 'cache-and-network',
    variables,
  };
};

export const verifyVariablesHasUpdate = props => {
  try {
    client.readQuery({
      ...(typeof options === 'function' ? options(props) : options),
      query: IndicadorVendasAgrupadoGql,
    });

    return false;
  } catch (e) {
    return true;
  }
};

export const [
  ,
  IndicadoresQueryHoc,
  IndicadoresQueryName,
  IndicadoresQueryDataName,
] = genericQueryHoc({
  name: 'indicadoresCrm',
  gql: IndicadorVendasAgrupadoGql,
  verifyVariablesHasUpdate,
  options,
  LoadingComponent: () => (
    <>
      <CardLoading />
      <CardLoading />
      <CardLoading />
      <CardLoading />
      <CardLoading />
    </>
  ),
  card: false,
});
