import React, { useState } from 'react';
import { SelecaoModeloInteresseContext } from './SelecaoModeloInteresseContext';

export const SelecaoModeloInteresseProvider = ({ children }) => {
  const [veiculos, setSelecaoModeloInteresseVeiculos] = useState([]);
  const [oportunidadeId, setOportunidadeId] = useState(null);
  const [
    openSelecaoModeloInteresseDialog,
    setOpenSelecaoModeloInteresseDialog,
  ] = useState(false);

  const selecaoModelosEAbreDialog = ({
    oportunidadeId: oportunidadeIdInput,
    veiculos: veiculosInput,
  }) => {
    setOpenSelecaoModeloInteresseDialog(true);
    setOportunidadeId(oportunidadeIdInput);
    setSelecaoModeloInteresseVeiculos(veiculosInput);
  };

  return (
    <SelecaoModeloInteresseContext.Provider
      value={{
        openSelecaoModeloInteresseDialog,
        setOpenSelecaoModeloInteresseDialog,
        veiculos,
        oportunidadeId,
        setSelecaoModeloInteresseVeiculos,
        selecaoModelosEAbreDialog,
      }}
    >
      {children}
    </SelecaoModeloInteresseContext.Provider>
  );
};
