import { defaultGrey, globalStyle } from '../../../app/globalStyle';

export const checkInConfirmacaoStyles = {
  ...globalStyle,
  form: {
    padding: '0px 8px 0px 8px',
  },
  nomeCliente: {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: '20px',
  },
  endereco: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#616161',
  },
  cpfcnpj: {
    color: '#616161',
    fontSize: '12px',
    fontWeight: 500,
  },
  iconGridItem: {
    maxWidth: '55px',
  },
  avatarMapMarker: {
    backgroundColor: defaultGrey,
    position: 'center',
  },
  enderecoGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  checkInRealizadoGridItem: {
    paddingTop: '15px',
    textAlign: 'center',
  },
  iconCheckinRealizado: {
    color: '#009933',
  },
  labelCheckInRealizado: {
    color: '#009933',
    fontSize: '12px',
    fontWeight: 500,
  },
  gridItem: {
    paddingTop: '15px',
  },
  divButtonRealizarCheckIn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90px',
    width: '100%',
  },
  iconButtonAdicionarImagem: {
    paddingRight: '5px',
  },
  atualizaLocalizacao: {
    fontSize: '12px',
    fontWeight: 500,
  },
};

export const checkInAbasStyles = {
  ...globalStyle,
  buttonTitle: {
    fontSize: '14px',
    fontWeight: 600,
  },
  abas: {
    paddingLeft: '2px',
    paddingRight: '2px',
    position: 'sticky',
    top: 0,
    backgroundColor: 'rgb(245, 245, 245)',
    zIndex: 10,
  },
  selectedTab: {
    backgroundColor: '#616161',
    color: 'white',
  },
  tab: {
    backgroundColor: 'white',
    color: 'black',
  },
  labelInfoAbas: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#616161',
  },
  gridItemInfoAbas: {
    textAlign: 'center',
  },
};

export const checkInBuscarClientes = {
  content: {
    padding: '2px',
  },
};
