import gql from 'graphql-tag';

export const CHECK_INS_PAGINATION_QUERY_GQL = gql`
  query GetCheckInsComPaginacao(
    $cursor: Int!
    $sort: String!
    $imagem: Boolean!
    $visitaRegistrada: Boolean!
    $idConsultorExterno: ID
    $idDealer: ID
    $modulo: String!
  ) {
    listarCheckIns(
      filter: {
        cursor: $cursor
        sort: $sort
        imagem: $imagem
        visitaRegistrada: $visitaRegistrada
        idConsultorExterno: $idConsultorExterno
        idDealer: $idDealer
        modulo: $modulo
      }
    ) {
      checkIns {
        id
        dataHora
        longitude
        latitude
        imagem
        atividadeVo {
          id
        }
        clienteVo {
          id
          nome
          cpfCnpj
          ultimaAtividadeFichaVisitaAbertaPk
          endereco {
            id
            numero
            logradouro
            estado {
              id
              nome
              sigla
            }
            complemento
            bairro
            cidade
            cep
          }
          unidadesNegocioAtendidos {
            id
            descricao
          }
        }
      }
      hasNext
      cursor
    }
  }
`;
