import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { indicadoresStyles } from '../indicadoresStyles';
import { TipoIndicadorFup48 } from '../../../../../core/Enums';
import { getBackgroudColor } from './followUp48CardRules';

const atividadesAtrasadaStyles = makeStyles(indicadoresStyles);

export const FollowUp48Component = ({
  idsOportunidade,
  tipoIndicadorFup48,
  setTipoIndicadorFup48,
  showDialogAtividades,
  urlRetorno,
}) => {
  const { ids } = idsOportunidade;
  const quantidade = ids ? ids.length : 0;
  const classes = atividadesAtrasadaStyles();

  useEffect(() => {
    if (urlRetorno && urlRetorno === 'atividades') {
      showDialogAtividades({
        ids,
        tipoIndicadorFup48: TipoIndicadorFup48.HOJE,
        finalizado: false,
        semTemperatura: null,
      });
    }
  }, [ids, showDialogAtividades, urlRetorno]);

  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{
          title: classes.titleBlack,
        }}
        className={classes.paddingCard}
        title="Follow-Up Pendentes"
      />
      <CardContent className={classes.cardContent}>
        <div>
          <Typography variant="caption" classes={{ root: classes.subtitle }}>
            Selecione o tipo de vencimento
          </Typography>
        </div>
        <div className={classes.alignCenter}>
          <Chip
            label="Hoje"
            classes={{
              root: classes.badgeFilter,
            }}
            style={{
              backgroundColor: getBackgroudColor(
                TipoIndicadorFup48.HOJE,
                tipoIndicadorFup48
              ),
            }}
            onClick={() => setTipoIndicadorFup48(TipoIndicadorFup48.HOJE)}
          />
          <Chip
            label="Próximo Dia Útil"
            classes={{
              root: classes.badgeFilter,
            }}
            style={{
              backgroundColor: getBackgroudColor(
                TipoIndicadorFup48.PROXIMO_DIA_UTIL,
                tipoIndicadorFup48
              ),
            }}
            onClick={() =>
              setTipoIndicadorFup48(TipoIndicadorFup48.PROXIMO_DIA_UTIL)
            }
          />
          <Chip
            label="Data Futura"
            classes={{
              root: classes.badgeFilter,
            }}
            style={{
              backgroundColor: getBackgroudColor(
                TipoIndicadorFup48.DEMAIS_DIAS,
                tipoIndicadorFup48
              ),
            }}
            onClick={() =>
              setTipoIndicadorFup48(TipoIndicadorFup48.DEMAIS_DIAS)
            }
          />
        </div>
        <div className={classes.centralizedcounter}>
          <Typography
            classes={{
              root: classes.counter,
            }}
            onClick={() => {
              showDialogAtividades({
                ids,
                finalizado: false,
                semTemperatura: null,
              });
            }}
          >
            {quantidade}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
