import React, { useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'redux-form';
import {
  RenderDatePicker,
  RenderInput,
  RenderTimePicker,
  required,
  SubmitButton,
} from '../../utils/FormUtils';
import { RenderSelectField } from '../../utils/select/RenderSelectField';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../utils/uiUtils';
import { ToggleSimNao } from '../../RealizarAtividade/lead/ToggleSimNao';

export const OpportunityVehiclesPerformTestDrive = ({
  history,
  handleSubmit,
  formName,
  change,
  temperaturasQuery: { temperaturas = [] },
  tiposDeContatoQuery: { tiposContato = [] },
  motivosTestDriveNaoRealizado,
  ...rest
}) => {
  const [testDriveRealizado, setTestDriveRealizado] = useState(true);

  change('testDriveRealizado', testDriveRealizado);

  return (
    <form onSubmit={handleSubmit} name={formName}>
      <input
        name="testDriveRealizado"
        type="hidden"
        value={testDriveRealizado}
      />
      <RenderAppLogo />
      {updateAppTitle('Test-Drive')}
      <ShowBackButton history={history} />
      <Grid container>
        <Grid item xs={12}>
          <ToggleSimNao
            label="Realizou Test-drive?"
            handleChange={(e, realizado) =>
              setTestDriveRealizado(realizado) &&
              change('testDriveRealizado', realizado)
            }
            booleanValue={testDriveRealizado}
          />
        </Grid>
        {testDriveRealizado ? (
          <>
            <Grid item xs={6}>
              <Field
                name="data"
                label="Data"
                required
                validate={[required]}
                component={RenderDatePicker}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="hora"
                label="Hora"
                required
                validate={[required]}
                component={RenderTimePicker}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="tipoContato"
                label="Tipo de Contato"
                required
                validate={[required]}
                component={RenderSelectField}
                fullWidth
              >
                {tiposContato.map(({ id, nome }) => (
                  <MenuItem value={id} key={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="temperatura"
                label="Temperatura"
                required
                validate={[required]}
                component={RenderSelectField}
                fullWidth
              >
                {temperaturas.map(({ id, nome }) => (
                  <MenuItem value={id} key={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="observacao"
                label="Observações"
                component={RenderInput}
                required
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <SubmitButton fullWidth label="Confirmar" {...rest} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Field
                name="motivo"
                label="Por que não?"
                required
                validate={[required]}
                component={RenderSelectField}
                fullWidth
              >
                {motivosTestDriveNaoRealizado.map(({ id, nome }) => (
                  <MenuItem value={id} key={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="justificativa"
                label="Justificativa"
                component={RenderInput}
                required
                multiline
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <SubmitButton fullWidth label="Confirmar" {...rest} />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};
