import gql from 'graphql-tag';

export const INDICADOR_EXCELLENCE_CLUB_VENDEDORES_QUERY = gql`
  query indicadoresExcellenceClubVendedoresQuery(
    $dataInicio: DateTime
    $dataFim: DateTime
    $dealerPk: ID
    $userId: ID
    $marcaId: ID
    $unidadeNegocioId: ID
  ) {
    indicadoresExcellenceClubVendedor(
      filter: {
        dataInicio: $dataInicio
        dataFim: $dataFim
        dealerPk: $dealerPk
        usuarioId: $userId
        marcaId: $marcaId
        unidadeNegocioId: $unidadeNegocioId
      }
    ) {
      totalTD
      pendentesTD
      idsTD
      totalFup48
      pendentesFup48
      idsFup48
      nomeUsuario
      porcentagemTD
      porcentagemFup
      idUsuario
    }
  }
`;
