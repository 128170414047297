import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { updateAppTitle } from '../../utils/uiUtils';
import { CheckInTabs } from './CheckInTabs';
import { CheckInSelectedTab } from '../../../core/Enums';
import { CheckInRealizarComponent } from './CheckInRealizarComponent';
import { CheckInListContainer } from './CheckInListContainer';
import { checkInAbasStyles } from './utils/checkInStylesUtils';
import { useUsuarioLogado } from './CheckInList/data/useUsuarioLogado';

const useCheckInAbasStyles = makeStyles(checkInAbasStyles);

const getCheckInSelectedTab = ({ usuarioLogado, location }) => {
  if (usuarioLogado?.administrador || usuarioLogado?.gerente) {
    return CheckInSelectedTab.CHECKIN_RELIZADOS.ordinal;
  }

  return location.state
    ? location.state.tab
    : CheckInSelectedTab.REALIZAR_CHECKIN.ordinal;
};

export const CheckInTabsContainer = ({
  showSearchDialog,
  setShowSearchDialog,
  ...props
}) => {
  const classes = useCheckInAbasStyles();
  const location = useLocation();
  const history = useHistory();
  const { usuarioLogado = {} } = useUsuarioLogado();
  const [selectedTab, _setSelectedTab] = useState(
    getCheckInSelectedTab({ usuarioLogado, location })
  );

  const setSelectedTab = tab => {
    _setSelectedTab(tab);
    history.replace(history.location.pathname, { tab });
  };

  return (
    <div>
      {updateAppTitle('Check-ins')}
      <CheckInTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        classes={classes}
        usuarioLogado={usuarioLogado}
      />
      {selectedTab === CheckInSelectedTab.REALIZAR_CHECKIN.ordinal && (
        <CheckInRealizarComponent
          showSearchDialog={showSearchDialog}
          setShowSearchDialog={setShowSearchDialog}
          {...props}
        />
      )}
      {selectedTab === CheckInSelectedTab.CHECKIN_RELIZADOS.ordinal && (
        <CheckInListContainer {...props} />
      )}
    </div>
  );
};
