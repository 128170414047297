import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps } from 'recompose';
import {
  getFirstFromSingleElementArrayNotNull,
  isEmptyOrNull,
  omitDeep,
} from '@tecsinapse/es-utils/build';
import { ClientContactsEditor } from './ClientContactsEditor';
import { getAlertContext } from '../../../utils/alertContext';
import { RemoveHOC } from '../../../utils/crud/RemoveHOC';
import { reduxFormAutoFocus } from '../../../utils/reduxFormAutoFocus';
import { validateRequired } from '../../../utils/FormUtils';
import { SalvarContatoMutation } from '../data/mutations/SalvarContatoMutation';
import { InativarContatoMutation } from '../data/mutations/InativarContatoMutation';

const enhance = compose(
  SalvarContatoMutation,
  getAlertContext,
  withRouter,
  RemoveHOC({
    mutation: InativarContatoMutation,
    mutationName: 'inativarClienteContato',
  }),
  withProps(
    ({
      match,
      location: {
        state: { contato = {}, segmentosVendaAtivos = [] } = {},
      } = {},
    }) => ({
      initialValues: {
        ...contato,
        idSegmentoInput:
          getFirstFromSingleElementArrayNotNull(segmentosVendaAtivos).id ||
          null,
        idClienteInput: match.params.idCliente,
      },
    })
  ),
  reduxFormAutoFocus({
    form: 'EditContactForm',
    validate: validateRequired(['nome']),
    onSubmit: (values, _, { salvarContato, history }) => {
      const idSegmento = values.idSegmento
        ? values.idSegmento
        : values.idSegmentoInput;

      const idCliente = values.idCliente
        ? values.idCliente
        : values.idClienteInput;

      let submitValues = omitDeep(values, '__typename');

      submitValues = omitDeep(submitValues, 'idSegmentoInput');
      submitValues = omitDeep(submitValues, 'idClienteInput');

      submitValues = { ...submitValues, idSegmento, idCliente };

      if (isEmptyOrNull(submitValues.id)) {
        submitValues.setor = 'VENDA';
        submitValues.aceitaReceberEmailCam = true;
      }

      const variables = { contato: submitValues };

      return salvarContato({ variables })
        .then(() => history.goBack())
        .catch(() => history.goBack());
    },
  }),
  withHandlers({
    remove: ({ removeOnClick }) => id => {
      removeOnClick({ id, goBack: true });
    },
  })
);

export const ClientContactsEditorContainer = enhance(ClientContactsEditor);
