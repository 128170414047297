import React, { useState } from 'react';
import { Field, Form } from 'redux-form';
import { Grid, MenuItem } from '@material-ui/core';

import moment from 'moment';
import {
  RenderDateTimePicker,
  RenderInput,
  required,
  SubmitButton,
  submitWithAction,
} from '../../utils/FormUtils';
import { RenderSelectField } from '../../utils/select/RenderSelectField';
import { ToggleSimNao } from './ToggleSimNao';
import { eventRescheduleClientNotContactedProspection } from '../../utils/GAUtils';
import { WarningSign } from './WarningSign';
import { IconButtonMaiusculas } from '../IconButtonMaiusculas/IconButtonMaiusculas';

const style = { width: '100%' };

export const NaoConseguiContato = ({
  handleSubmit,
  onSubmit,
  classes,
  initialValues,
  goToNextPage,
  formName = null,
  setTextAction,
  justificativa,
  motivosArquivarContatoQuery: { motivosArquivarContato = [] },
  ...rest
}) => {
  const [reagendarContato, setReagendarContato] = useState(true);
  const handleReagendamento = (event, reagendar) => {
    eventRescheduleClientNotContactedProspection();
    setReagendarContato(reagendar);
  };

  const [exibirCaixaInformativa, setExibirCaixaInformativa] = useState(false);
  const handleMotivoArquivarContato = (e, id) => {
    const { inativarClienteSetor } = motivosArquivarContato.find(
      m => m.id === id
    );

    setExibirCaixaInformativa(inativarClienteSetor);
  };

  return (
    <Form onSubmit={handleSubmit} style={style} name={formName}>
      <Grid container>
        <Grid item xs={12}>
          <ToggleSimNao
            label="Deseja reagendar o contato?"
            handleChange={handleReagendamento}
            booleanValue={reagendarContato}
          />
        </Grid>

        <Grid item xs={12}>
          {reagendarContato ? (
            <Field
              fullWidth
              name="dataHoraReagendamento"
              label="Data de reagendamento"
              minDate={moment()}
              component={RenderDateTimePicker}
            />
          ) : (
            <Field
              name="motivoArquivarContato"
              validate={[required]}
              label="Selecione um motivo para o arquivamento"
              required
              component={RenderSelectField}
              onChange={handleMotivoArquivarContato}
              fullWidth
            >
              {motivosArquivarContato.map(({ id, descricao }) => (
                <MenuItem value={id} key={id}>
                  {descricao}
                </MenuItem>
              ))}
            </Field>
          )}
        </Grid>

        {exibirCaixaInformativa && (
          <Grid item xs={12}>
            <WarningSign />
          </Grid>
        )}

        <Grid item xs={12}>
          <Field
            name="justificativa"
            label="Justificativa"
            required
            validate={[required]}
            multiline
            component={RenderInput}
            fullWidth
            endAdornment={
              <IconButtonMaiusculas
                observacao={justificativa}
                fieldKey="justificativa"
                setTextAction={setTextAction}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          {reagendarContato ? (
            <SubmitButton
              label="Confirmar"
              fullWidth
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'NAO_CONSEGUI_CONTATO',
                payload: { reagendarContato },
              })}
              {...rest}
            />
          ) : (
            <SubmitButton
              label="Arquivar"
              fullWidth
              secundary
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'NAO_CONSEGUI_CONTATO_ARQUIVAR',
              })}
              {...rest}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
};
