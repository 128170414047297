const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export async function promiseConvertFileToBase64(file) {
  if (!file) {
    return null;
  }
  const { name, type, size } = file;

  return { name, type, size, base64: await toBase64(file) };
}
