import React from 'react';
import moment from 'moment';
import { isNotEmptyOrNull } from '@tecsinapse/es-utils/build';
import { TipoItemTimeline } from '../../core/Enums';

export const TimelineItem = ({ item }) => {
  const mudancaStatus = item.statusAnterior !== item.statusPosterior;

  return (
    <li className="timeline-inverted">
      <div className={`timeline-badge ${item.tipo.style}`}>
        <img
          className="badge-icone"
          src={TipoItemTimeline.enumValueOf(item.tipo.id).icon()}
          alt="Icone"
        />
      </div>
      <div className={`timeline-panel ${item.tipo.style}-timeline-item`}>
        <div className="timeline-heading">
          <h5 className={`${item.tipo.style}-titulo`}>{item.titulo}</h5>
        </div>
        <div className="timeline-body">
          {isNotEmptyOrNull(item.statusPosterior) && (
            <p
              rel="tooltip-left"
              className="tooltip-status"
              title="Status da Oportunidade"
            >
              {isNotEmptyOrNull(item.statusAnterior) && mudancaStatus && (
                <>
                  <span className="label label-info">
                    {item.statusAnterior}
                  </span>
                  <i className="fa fa-arrow-right status-arrow" />
                </>
              )}
              <span className="label label-info">{item.statusPosterior}</span>
            </p>
          )}
          {item.tipoAtividade &&
            item.tipoAtividade.atividadeRelacionamentoComCliente &&
            (isNotEmptyOrNull(item.tipoContatoCliente) ||
              isNotEmptyOrNull(item.temperatura)) && (
              <p
                rel="tooltip-left"
                className="tooltip-cursor"
                title="Tipo de Contato"
              >
                {item.tipoContatoCliente.nome}
              </p>
            )}

          {item.tipoAtividade &&
            item.tipoAtividade.atividadeRelacionamentoComCliente &&
            (item.mudancaTemperatura || isNotEmptyOrNull(item.temperatura)) && (
              <p
                rel="tooltip-left"
                className="tooltip-badge"
                title="Temperatura"
              >
                {item.mudancaTemperatura && (
                  <>
                    <span
                      className="badge"
                      style={{ backgroundColor: item.temperaturaAnterior.cor }}
                    >
                      {item.temperaturaAnterior.nome}
                    </span>
                    <i className="fa fa-arrow-right" />
                  </>
                )}
                {isNotEmptyOrNull(item.temperatura) && (
                  <span
                    className="badge"
                    style={{ backgroundColor: item.temperatura.cor }}
                  >
                    {item.temperatura.nome}
                  </span>
                )}
              </p>
            )}

          {isNotEmptyOrNull(item.descricaoModelo) && (
            <p
              rel="tooltip-left"
              className="tooltip-cursor row-fluid"
              title={item.tooltipDescricaoModelo}
            >
              <span className="span1 line">
                <i className="fa fa-car" aria-hidden="true" />
              </span>
              <span className="span11 margin-l-0 line">
                {item.descricaoModelo}
              </span>
            </p>
          )}
          {isNotEmptyOrNull(item.mensagem) && (
            <p
              className="mais"
              dangerouslySetInnerHTML={{ __html: item.mensagem }} // eslint-disable-line react/no-danger
            />
          )}
        </div>
        <div className="timeline-footer">
          <small>
            <strong className="timeline-responsavel">{item.responsavel}</strong>
          </small>

          <strong className="pull-right">
            {isNotEmptyOrNull(item.dataHoraProgramada) ? (
              <div
                rel="tooltip-left"
                className={`tooltip-cursor ${
                  item.realizadaAtrazada ? 'timeline-atividade-atrasada' : ''
                }`}
                title={item.descricaoDataHoraProgramada}
              >
                {moment(item.dataHoraRealizacao).format('DD/MM/YYYY HH:mm')}
              </div>
            ) : (
              <>{moment(item.dataHoraRealizacao).format('DD/MM/YYYY HH:mm')}</>
            )}
          </strong>
        </div>
      </div>
    </li>
  );
};
