import { Divider, List } from '@material-ui/core';
import { EmptyState } from '@tecsinapse/ui-kit/build/components/EmptyState/EmptyState';
import React from 'react';
import { RenderAppLogo, updateAppTitle } from '../utils/uiUtils';
import { NotificacaoCard } from './NotificacaoCard';
import { useNotificacoesContainer } from './NotificacoesContainer';

export const Notificacoes = ({ history }) => {
  const {
    notificacoesEnviadas,
    possuiNotificacoes,
    handleNotificacaoClick,
  } = useNotificacoesContainer(history);

  const notificacoesOrdenadas = notificacoesEnviadas.sort(a =>
    a.visualizado ? 1 : -1
  );

  return (
    <>
      <RenderAppLogo />
      {updateAppTitle('Central De Notificações')}
      {possuiNotificacoes ? (
        <List>
          {notificacoesOrdenadas.map(notificacao => (
            <React.Fragment key={`id_${notificacao.pk}`}>
              <NotificacaoCard
                notificacao={notificacao}
                onClick={handleNotificacaoClick}
              />
              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <EmptyState />
      )}
    </>
  );
};
