import { omitDeep } from '@tecsinapse/es-utils/build';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { getAlertContext } from '../../utils/alertContext';
import { validateRequired } from '../../utils/FormUtils';
import GetAtividadeTimelineQueryGql from '../GetAtividadeTimelineQueryGql.graphql';
import { AddCommentEditor } from './AddCommentEditor';
import { reduxFormAutoFocus } from '../../utils/reduxFormAutoFocus';
import { graphqlOfflineMutation } from '../../utils/graphql/graphqlOfflineMutation';
import addCommentTimelineMutationGql from './addCommentTimelineMutationGql.graphql';

const addCommentTimelineMutation = graphqlOfflineMutation(
  addCommentTimelineMutationGql,
  {
    name: 'addCommentTimeline',
    mutationName: 'reagendarAtividadeMutationGql',
  }
);

const enhance = compose(
  addCommentTimelineMutation,
  withProps(({ match }) => ({
    initialValues: {
      id: match.params.id,
    },
  })),
  getAlertContext,
  reduxFormAutoFocus({
    // a unique name for the form
    form: 'AddCommentForm',
    validate: validateRequired(['idAtividade', 'comentario']),
    onSubmit: (values, dispatch, { addCommentTimeline, history, match }) => {
      const submitValues = omitDeep(values, '__typename');

      return addCommentTimeline({
        variables: { ...submitValues },
        update: (store, { data: { adicionarComentarioTimeline } }) => {
          // Read the data from our cache for this editClientQuery.
          const data = store.readQuery({
            query: GetAtividadeTimelineQueryGql,
            variables: { id: match.params.id },
          });

          // Add our comment from the mutation to the end.
          data.atividade.timeline.unshift(adicionarComentarioTimeline);
          // Write our data back to the cache.
          store.writeQuery({ query: GetAtividadeTimelineQueryGql, data });
        },
      }).then(() => history.goBack());
    },
  }),
  withRouter
);

export const AddCommentEditorContainer = enhance(AddCommentEditor);
