export class ErrorOffline extends Error {
  idName;

  mutationName;

  entityName;

  savedEntityName;

  constructor(mutationName, entityName, idName, savedEntityName) {
    super();
    this.mutationName = mutationName;
    this.entityName = entityName;
    this.savedEntityName = savedEntityName;
    this.idName = idName;
  }
}
