/* eslint-disable */
import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { Chip, Grid } from '@material-ui/core';
import Icon from '@mdi/react';
import { Select } from '@tecsinapse/ui-kit';
import { mdiCalendar } from '@mdi/js';
import Typography from '@material-ui/core/Typography';
import { getFirstElementOfArray } from '@tecsinapse/es-utils/build/object';
import { listStyles } from './utils/rotasStylesUtils';
import { defaultGrey, defaultOrange } from '../../app/globalStyle';
import { ClienteRotaCard } from './ClienteRotaCard';
import { SubmitButton } from '../../utils/FormUtils';

export const DiaRota = ({
  roteirizacaoClientes = [],
  data,
  mapLink,
  cardOnClick,
  urlMapa,
  history,
  usuarioLogado,
  setRotaFiltrada,
  selectedRoute,
  setSelectedRoute,
  roteirizacaoRotas,
}) => {
  const classes = listStyles();
  const kmTotal = roteirizacaoClientes.reduce(
    (acc, val) => acc + val.distanciaKm,
    0
  );

  const rotas = [];

  roteirizacaoRotas.forEach(o => {
    const rota = {};

    rota.value = o.id;
    rota.label = o.rotaId;
    rotas.push(rota);
  });

  const rota = selectedRoute || getFirstElementOfArray(roteirizacaoRotas);
  const [sel, setSel] = useState(rota.id);

  const handleChange = e => {
    const obj = roteirizacaoRotas.filter(r => r.id === e);

    setSelectedRoute(getFirstElementOfArray(obj));
    setSel(e);
    setRotaFiltrada(obj);
  };

  const { roteirizacaoV2 } = usuarioLogado;

  const col1 = 6;
  const col3 = 6;

  const style = { paddingLeft: 0, display: 'flex', alignItems: 'center' };
  const style1 = { paddingTop: '5px' };
  const style2 = { paddingLeft: 0 };
  const style3 = {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    paddingLeft: '20px',
  };
  const style4 = {
    float: 'right',
    backgroundColor: defaultGrey,
    color: '#FFF',
  };

  const style7 = { overflow: 'auto' };
  const style8 = { marginTop: '32px' };

  const { location } = history;
  const prefixoAtividadeRealizada = 'atividade_realizada_';

  if (location.state && location.state.roteirizacaoClienteId) {
    const { roteirizacaoClienteId } = history.location.state;
    roteirizacaoClientes.forEach(rc => {
      if (rc.id && rc.id === roteirizacaoClienteId) {
        localStorage.setItem(prefixoAtividadeRealizada + rc.id, 'true');
      }
    });
  }

  roteirizacaoClientes.forEach(rc => {
    rc.atividadeRealizada = localStorage.getItem(
      prefixoAtividadeRealizada + rc.id
    );
  });

  return (
    <div>
      <div className={classes.divHeader}>
        <Grid container>
          {!roteirizacaoV2 && (
            <Grid item xs={col1} style={style}>
              <Icon path={mdiCalendar} size={1} />
              <Typography
                style={style1}
                variant="subtitle2"
                color="inherit"
                className={classes.title}
                display="inline"
              >
                {data}
              </Typography>
            </Grid>
          )}
          {roteirizacaoV2 && (
            <Grid item xs={col1} style={style2}>
              <Select
                value={sel}
                onChange={handleChange}
                placeholder="Rotas"
                label="Rotas"
                options={rotas}
                minWidth="150px"
              />
            </Grid>
          )}
          <Grid item xs={col3} style={style3}>
            <Chip
              label={`${kmTotal}km`}
              classes={{
                root: classes.chipMargin,
              }}
              style={style4}
            />
          </Grid>
        </Grid>
      </div>

      {roteirizacaoClientes && roteirizacaoClientes.length > 0 && (
        <>
          <div style={{ padding: '0px 10px 0px 10px' }}>
            <SubmitButton
              backgroundColor={defaultOrange}
              fullWidth="true"
              label="Visualizar mapa das rotas"
              onClick={() => history.push('mapa', { urlMapa })}
            />
          </div>

          <div className={classes.tituloRota}>Informações da {rota.rotaId}</div>
          <div className={classes.fullList} style={style7}>
            {roteirizacaoClientes.map((roteirizacaoCliente, id) => (
              <ClienteRotaCard
                key={roteirizacaoCliente.id}
                roteirizacaoCliente={roteirizacaoCliente}
                cardOnClick={cardOnClick}
                pontoPartida={!roteirizacaoCliente.clienteVo && id === 0}
                pontochegada={
                  !roteirizacaoCliente.clienteVo &&
                  id === roteirizacaoClientes.length - 1
                }
              />
            ))}
          </div>
        </>
      )}
      <Divider style={style8} />
    </div>
  );
};
