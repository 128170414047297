import { mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import { Grid, MenuItem } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography/Typography';
import React, { useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import { makeStyles } from '@material-ui/styles';
import { defaultOrange, globalStyle } from '../../../app/globalStyle';
import {
  people,
  RenderInput,
  requiredIfNotConsultorExterno,
  SubmitButton,
} from '../../../utils/FormUtils';
import { Endereco } from '../../../utils/Endereco/Endereco';
import { RenderSelectField } from '../../../utils/select/RenderSelectField';
import { BottomSheet } from './BottomSheet';
import { FloatingButton } from '../../../utils/FloatingButton';
import { EntrarEmContatoDialog } from '../../../utils/EntrarEmContatoDialog';
import { RouteDialog } from '../../../utils/RouteDialog';
import { InformarContatoDialog } from '../../../utils/InformarContatoDialog';
import {
  eventMoreOptionsClientButton,
  eventSaveClientButton,
} from '../../../utils/GAUtils';
import { useNdiBloquearAlteracoesCpfCnpjCliente } from '../components/useNdiBloquearAlteracoesCpfCnpjCliente';
import { useUnidadeNegocio } from '../../../Home/data/queries/useUnidadeNegocio';

const useStylesClienteEditor = makeStyles(theme => ({
  speedDial: {
    position: 'fixed',
    bottom: 80,
    right: 30,
  },
  fab: {
    backgroundColor: defaultOrange,
  },
  ...globalStyle(theme),
}));

export const ClienteEditor = ({
  handleSubmit,
  initialValues,
  handleCepChange,
  endereco,
  history,
  change,
  notRenderIfConsultorExterno,
  editClientQuery: { cliente: { contatos = [] } = {} } = {},
  divisoesRegionaisQuery: { divisoesRegionaisDealerVendedorLogado = [] },
  formName,
  exibirMaisOpcoes,
  showCallClientModal,
  clearSelected,
  showRouteModal,
  setShowRouteModal,
  showInformCallModal,
  setShowInformCallModal,
  atividades,
  usuarioLogado,
  initialize,
  ...rest
}) => {
  const [unidadesNegocioVendaAtivas] = useUnidadeNegocio();

  useEffect(() => {
    if (!initialValues?.id && unidadesNegocioVendaAtivas.length === 1) {
      initialize({
        ...initialValues,
        idUnidadeNegocio: unidadesNegocioVendaAtivas[0].id,
      });
    }
  }, [unidadesNegocioVendaAtivas]); //eslint-disable-line

  const classes = useStylesClienteEditor();
  const client = {
    ...initialValues,
    contatos,
  };

  const filtraFaixasDeCep = f => {
    if (isEmptyOrNull(endereco.cep)) {
      return true;
    }
    const cep = Number(endereco.cep.replace('-', ''));
    const cepInicial = Number(f.cepInicial.replace('-', ''));
    const cepFinal = Number(f.cepFinal.replace('-', ''));

    return cepInicial <= cep && cep <= cepFinal;
  };

  const exibeOpcoesCriacao = initialValues?.id;
  const style = { paddingBottom: exibeOpcoesCriacao ? '72px' : null };
  const divisoesRegionais = divisoesRegionaisDealerVendedorLogado.filter(
    c => endereco && c.faixasCep.some(filtraFaixasDeCep)
  );

  const possuiAcessoDivisaoRegional = (
    usuarioLogado?.dealersVendedorLogado ?? []
  )
    .filter(Boolean)
    .filter(d => d.possuiAcessoDivisaoRegional)
    .map(d => d.pk)
    .includes(initialValues?.dealerPk);

  const {
    bloquearAlteracoesCpfCnpj,
    isBloquearAlteracoesCpfCnpjCliente,
  } = useNdiBloquearAlteracoesCpfCnpjCliente();

  useEffect(() => {
    if (client.id) {
      isBloquearAlteracoesCpfCnpjCliente(client.id);
    }
  }, [client.id, isBloquearAlteracoesCpfCnpjCliente]); // eslint-disable-line

  return (
    <>
      {process.env.REACT_APP_KC_ENV_TYPE === 'staging' && (
        <FloatingButton
          icon={<CloudDownload />}
          onClick={() =>
            people(person => {
              change('nome', `Ruither ${person.name}`);
              change('cpfCnpj', person.cpf);
              change('email', person.email);
            })
          }
        />
      )}

      <form
        onSubmit={handleSubmit}
        name={formName}
        className={classes.form}
        style={style}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Dados do Cliente
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="nome"
              label="Nome"
              autoFocus
              component={RenderInput}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="cpfCnpj"
              label="CPF/CNPJ"
              textMask="cpfcnpj"
              component={RenderInput}
              disabled={bloquearAlteracoesCpfCnpj}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="celular"
              label="Celular"
              textMask="cell"
              component={RenderInput}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="telefone"
              label="Telefone"
              textMask="cell"
              component={RenderInput}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="telefoneComercial"
              label="Telefone Comercial"
              textMask="cell"
              component={RenderInput}
              fullWidth
            />
          </Grid>

          {isEmptyOrNull(initialValues?.idDealer) && (
            <Grid item xs={12}>
              <Field
                name="idDealer"
                label="Dealer"
                component={RenderSelectField}
                disabled={!isEmptyOrNull(initialValues?.id)}
                fullWidth
              >
                {(usuarioLogado?.dealersVendedorLogado || []).map(
                  ({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  )
                )}
              </Field>
            </Grid>
          )}

          <Grid item xs={12}>
            <Field
              name="email"
              label="Email"
              component={RenderInput}
              fullWidth
            />
          </Grid>

          {unidadesNegocioVendaAtivas.length > 1 &&
            isEmptyOrNull(initialValues?.id) &&
            notRenderIfConsultorExterno() && (
              <Grid item xs={12}>
                <Field
                  name="idUnidadeNegocio"
                  label="Unidade Negócio"
                  required
                  component={RenderSelectField}
                  validate={[requiredIfNotConsultorExterno]}
                  fullWidth
                >
                  {unidadesNegocioVendaAtivas.map(({ id, descricao }) => (
                    <MenuItem value={id} key={id}>
                      {descricao}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            )}

          <FormSection name="endereco">
            <Endereco change={change} name="endereco" />
          </FormSection>

          {possuiAcessoDivisaoRegional && (
            <Grid item xs={12}>
              <Field
                name="divisaoRegional.id"
                label="Divisão Regional"
                disabled
                component={RenderSelectField}
                fullWidth
                menuPlacement="top"
              >
                {divisoesRegionais.map(({ id, nome }) => (
                  <MenuItem value={id} key={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}

          <Grid item xs={12}>
            <SubmitButton
              fullWidth
              label="Salvar"
              onClick={() => eventSaveClientButton()}
              {...rest}
            />
          </Grid>
        </Grid>
      </form>

      {exibeOpcoesCriacao && (
        <FloatingButton
          icon={<Icon path={mdiMenu} size={1} color="white" />}
          onClick={() => {
            eventMoreOptionsClientButton();
            exibirMaisOpcoes(initialValues);
          }}
        />
      )}

      <BottomSheet {...rest} usuarioLogado={usuarioLogado} hideEditClient />

      <EntrarEmContatoDialog
        client={client}
        open={showCallClientModal}
        handleClose={() => clearSelected()}
      />

      <InformarContatoDialog
        client={initialValues}
        atividades={atividades}
        open={showInformCallModal}
        handleClose={() => {
          setShowInformCallModal(false);
        }}
      />

      <RouteDialog
        client={initialValues}
        open={showRouteModal}
        showEditButton={false}
        handleClose={() => setShowRouteModal(false)}
      />
    </>
  );
};
