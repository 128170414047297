import { gql, useLazyQuery, useMutation } from '@apollo/react-hoc';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { confirm } from '../../../utils/dialogs/ConfirmationDialog';

const BUSCA_PRODUTO_AGREGADOS_POR_OPORTUNIDADE = gql(`
  query BuscaProdutosAgregados($oportunidadeId: ID!){
    buscaProdutosAgregadosPorOportunidade(oportunidadeId: $oportunidadeId) {
      id
      dataCriacao
      resultado
      dataInicioVigencia
      dataFimVigencia
      detalhes
      produtoAgregado{
        id
        nome
      }
    }
  }
`);

const REMOVE_PRODUTO_AGREGADO = gql(`
  mutation RemoveProdutoAgregado($id: ID!){
    removeProdutoAgregado(id: $id) {
      ativo
    }
  }
`);

export const useProdutoAgregado = ({
  idOportunidade,
  oportunidade: { idDealer } = {},
  showAlert,
}) => {
  const history = useHistory();
  const [exibeOpcoes, setExibeOpcoes] = useState(false);
  const [produtoSelecionado, setProdutoSelecionado] = useState({
    produtoAgregado: {},
  });

  const [removeProdutoAgregadoGQL] = useMutation(REMOVE_PRODUTO_AGREGADO);
  const [buscaProdutosAgregadosPorOportunidadeGQL, produtosData] = useLazyQuery(
    BUSCA_PRODUTO_AGREGADOS_POR_OPORTUNIDADE
  );

  const produtos = produtosData?.data?.buscaProdutosAgregadosPorOportunidade;
  const temProdutosAgregados = produtos?.length > 0;

  const handleAdicionarProduto = useCallback(
    () =>
      history.push(`/oportunidade/${idOportunidade}/produtoAgregado`, {
        idDealer,
      }),
    [history, idOportunidade, idDealer]
  );

  const handleExcluirProduto = useCallback(() => {
    setExibeOpcoes(false);
    const dataCriacao = moment(produtoSelecionado.dataCriacao).format(
      'DD/MM/YYYY'
    );
    const confirmation = `Deseja realmente excluir o produto agregado ${produtoSelecionado.produtoAgregado.nome} adicionado em ${dataCriacao}?`;

    confirm({ confirmation }).then(async () => {
      try {
        await removeProdutoAgregadoGQL({
          variables: {
            id: produtoSelecionado.id,
          },
        });

        buscaProdutosAgregadosPorOportunidadeGQL({
          variables: {
            oportunidadeId: idOportunidade,
          },
        });

        showAlert({
          message: 'Produto removido com sucesso!',
          variant: 'success',
          chip: true,
        });
      } catch (e) {
        showAlert({
          message: e.message,
          variant: 'error',
        });
      }
    });
  }, [
    produtoSelecionado.dataCriacao,
    produtoSelecionado.produtoAgregado.nome,
    produtoSelecionado.id,
    idOportunidade,
    removeProdutoAgregadoGQL,
    buscaProdutosAgregadosPorOportunidadeGQL,
    showAlert,
  ]);

  const handleOpcoes = useCallback(
    produto => {
      setExibeOpcoes(estado => !estado);
      produto && setProdutoSelecionado(produto);
    },
    [setExibeOpcoes]
  );

  useEffect(() => {
    buscaProdutosAgregadosPorOportunidadeGQL({
      variables: {
        oportunidadeId: idOportunidade,
      },
    });
  }, [buscaProdutosAgregadosPorOportunidadeGQL, idOportunidade]);

  return {
    produtos,
    temProdutosAgregados,
    exibeOpcoes,
    handleAdicionarProduto,
    handleExcluirProduto,
    handleOpcoes,
  };
};
