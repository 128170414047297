export function getRgba({ r, b, g, a = 1 }) {
  return `rgba(${r},${g},${b},${a})`;
}

export function getStyleOfCard(number) {
  const nenhum = {
    customSubtitleColor: 'error',
    backgroundColorRgb: {
      r: 156,
      g: 156,
      b: 156,
    },
    titleColorRgb: {
      r: 0,
      g: 0,
      b: 0,
    },
  };

  const vermelho = {
    customSubtitleColor: 'error',
    backgroundColorRgb: {
      r: 252,
      g: 227,
      b: 225,
    },
    titleColorRgb: {
      r: 213,
      g: 62,
      b: 58,
    },
  };

  const laranja = {
    customSubtitleColor: 'primary',
    backgroundColorRgb: {
      r: 255,
      g: 223,
      b: 176,
    },
    titleColorRgb: {
      r: 225,
      g: 139,
      b: 11,
    },
  };

  const verde = {
    customSubtitleColor: 'primary',
    backgroundColorRgb: {
      r: 201,
      g: 236,
      b: 197,
    },
    titleColorRgb: {
      r: 59,
      g: 136,
      b: 62,
    },
  };

  let styleOfComp = nenhum;

  if (number >= 0 && number <= 50) {
    styleOfComp = vermelho;
  } else if (number > 50 && number <= 90) {
    styleOfComp = laranja;
  } else if (number > 90) {
    styleOfComp = verde;
  }

  return styleOfComp;
}

export const stylesOfCirularProgressbar = mainColor => ({
  // Customize the root svg element
  root: {},
  // Customize the path, i.e. the part that's "complete"
  path: {
    // Tweak path color:
    stroke: mainColor,
    // Tweak path to use flat or rounded ends:
    strokeLinecap: 'butt',
    // Tweak transition animation:
    transition: 'stroke-dashoffset 0.5s ease 0s',
  },
  background: {
    fill: '#ffffff',
  },
  text: {
    fill: mainColor,
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    letterSpacing: '-1px',
  },
});
