import React from 'react';
import Icon from '@mdi/react';
import styled from 'styled-components';
import { getIconeSegmento } from '../Home/ActivityCard/IconesSegmento';
import { ReactComponent as ImplementosIcon } from '../../images/implementos.svg';
import { ReactComponent as TratorIcon } from '../../images/trator.svg';
import { ReactComponent as MaquinaConstrucaoIcon } from '../../images/maquina-construcao.svg';

const ImplementosIconFormated = styled(ImplementosIcon)`
  transform: scale(0.75);
  fill: #fff;
`;

const TratorFormated = styled(TratorIcon)`
  fill: #fff;
`;

const MaquinaConstrucaoIconFormated = styled(MaquinaConstrucaoIcon)`
  fill: #fff;
`;

const IconUnidadeNegocio = ({ descricaoNormalizado }) => {
  if (descricaoNormalizado === 'implementos') {
    return <ImplementosIconFormated />;
  }

  if (descricaoNormalizado === 'agricola') {
    return <TratorFormated />;
  }

  if (descricaoNormalizado === 'construcao') {
    return <MaquinaConstrucaoIconFormated />;
  }

  return (
    <Icon
      path={getIconeSegmento(descricaoNormalizado)}
      color="white"
      size={0.7}
    />
  );
};

export default IconUnidadeNegocio;
