import { Chip } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import { mdiBell, mdiCheckDecagram } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colorOfAvatar, labelOfActivityNew } from '../activityCardFunctions';
import { chipStyles } from '../ActivityCardStyles';

const NotificacaoContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
`;

export const title = ({
  dataHoraExibicao,
  activity,
  showSegments,
  crmProspeccaoNomePattern,
  classes,
  finalizado,
  possuiLembrete,
}) => {
  const titleName = moment(dataHoraExibicao).format('DD/MM/YYYY HH:mm');

  const chipStyle = {
    ...chipStyles,
    ...colorOfAvatar(activity),
  };

  const titleComponents = (
    <>
      <div className={classes.gridFlex}>
        {activity.isReagendado && <Update className={classes.icon} />}

        <Chip
          label={labelOfActivityNew(
            activity,
            showSegments,
            crmProspeccaoNomePattern
          )}
          size="small"
          classes={{
            label: classes.chipTitle,
          }}
          style={chipStyle}
        />
        {finalizado && (
          <Chip
            label="Realizado"
            size="small"
            avatar={<Icon path={mdiCheckDecagram} color="white" size={0.65} />}
            classes={{
              label: clsx(classes.chipTitle, classes.chipDoneLabel),
            }}
            className={classes.chipDone}
          />
        )}
      </div>

      {possuiLembrete && (
        <NotificacaoContainer>
          <Icon path={mdiBell} color="black" size={0.65} />
        </NotificacaoContainer>
      )}
    </>
  );

  return { name: titleName, components: titleComponents };
};
