import {
  ResultadoProspeccao,
  TipoAcaoInformarContato,
} from '../../../core/Enums';
import {
  eventFileClientContactedProspection,
  eventFileClientInvalidInformationProspection,
  eventFileClientNotContactedProspection,
  eventSaveClientContactedProspection,
  eventSaveClientNotContactedProspection,
} from '../../utils/GAUtils';

export const prospeccaoActions = (
  action,
  payload,
  submitValues,
  { informarResultadoProspeccao, criarEInformarResultadoProspeccao, history },
  tipoAcaoInformarContato
) => {
  const actionPayload = { encerrarProspeccao: false };

  switch (action) {
    case 'CONSEGUI_CONTATO':
      eventSaveClientContactedProspection();
      actionPayload.resultadoProspeccao =
        ResultadoProspeccao.CONSEGUI_CONTATO.name;
      actionPayload.criarOportunidade = payload.criarOportunidade;
      actionPayload.reagendar = payload.reagendarContato;
      break;
    case 'NAO_CONSEGUI_CONTATO':
      eventSaveClientNotContactedProspection();
      actionPayload.resultadoProspeccao =
        ResultadoProspeccao.NAO_CONSEGUI_CONTATO.name;
      actionPayload.reagendar = payload.reagendarContato;
      break;
    case 'NAO_CONSEGUI_CONTATO_ARQUIVAR':
      eventFileClientNotContactedProspection();
      actionPayload.resultadoProspeccao =
        ResultadoProspeccao.NAO_CONSEGUI_CONTATO.name;
      actionPayload.encerrarProspeccao = true;
      break;
    case 'CONSEGUI_CONTATO_ARQUIVAR':
      eventFileClientContactedProspection();
      actionPayload.resultadoProspeccao =
        ResultadoProspeccao.CONSEGUI_CONTATO.name;
      actionPayload.encerrarProspeccao = true;
      break;
    case 'INFORMACOES_INVALIDAS':
      eventFileClientInvalidInformationProspection();
      actionPayload.resultadoProspeccao =
        ResultadoProspeccao.INFORMACOES_INVALIDAS.name;
      actionPayload.encerrarProspeccao = true;
      // FIXME - isso está 11 por ser o valor do informar contato invalido, precisa passar pro back-end isso
      actionPayload.motivoArquivarContato = 11;
      break;
    default:
  }

  if (actionPayload.resultadoProspeccao) {
    const variables = {
      resultado: {
        ...submitValues,
        ...actionPayload,
      },
    };

    if (
      tipoAcaoInformarContato ===
      TipoAcaoInformarContato.CRIAR_ATIVIDADE_PROSPECCAO.name
    ) {
      return criarEInformarResultadoProspeccao({
        variables,
      }).then(() => history.push('/agenda'));
    }

    return informarResultadoProspeccao({
      variables,
    });
  }

  return null;
};
