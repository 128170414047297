import { compose, pure, withHandlers, withProps } from 'recompose';
import { withGenericSearchBarState } from '../utils/Search/WithGenericSearchBarState';
import { GenericSearchBar } from '../utils/Search/GenericSearchBar';
import { ClienteSearchListing } from '../Client/client/ui/ClienteSearchListing';

const enhance = compose(
  pure,
  withGenericSearchBarState({
    textSearchPlaceHolder: 'Busca por nome ou cpf/cnpj do cliente',
    textSearchPlaceHolderExpansion: 'Nome, CPF ou CNPJ',
    itemPlaceHolder:
      'Digite o nome ou CPF/CNPJ do cliente para realizar uma busca',
    SearchListener: ClienteSearchListing,
    isToUpdateAppTitle: true,
    isShowFloatingButton: false,
    autoFocus: true,
    isDialog: true,
  }),
  withProps(() => ({
    showCheckInConfirmacao: true,
  })),
  withHandlers({
    cardOnClick: ({ setSelectedClient }) => (e, selectedClient) => {
      if (e) {
        e.preventDefault();
      }
      setSelectedClient(selectedClient);
    },
    hideDrawer: ({ setSelectedClient }) => () => {
      setSelectedClient(null);
    },
  })
);

export const ClienteSearchTabContainer = enhance(GenericSearchBar);
