import axios from 'axios';
import { isIos } from '../utils/BrowserUtils';

const axiosWithoutInterceptors = axios.create();

// eslint-disable-next-line no-unused-vars
const showFile = blob => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');

  link.href = data;
  link.download = 'proposta.pdf';
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const viewFile = ({ propostaUrl, callback }) => {
  const amazonUrl = propostaUrl.includes('amazonaws.com');
  const url = amazonUrl
    ? propostaUrl
    : `${process.env.REACT_APP_PDF_REST_URL}/download?url=${propostaUrl}`;

  return (amazonUrl ? axiosWithoutInterceptors : axios)
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    })
    .then(response => {
      callback();
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });

      showFile(blob);
    });
};

export const printProposal = ({
  generateProposal,
  showLoading,
  hideLoading,
  openAndSetUrl,
}) => (idProposta, idDealerFilial = null) => {
  showLoading();

  const variables = { idProposta, idDealerFilial };

  generateProposal({
    variables,
  })
    .then(({ data: { gerarProposta: propostaUrl } }) => {
      if (isIos()) {
        hideLoading();
        openAndSetUrl({
          url: propostaUrl,
        });
      } else {
        viewFile({ propostaUrl, callback: () => hideLoading() });
      }
    })
    .catch(() => hideLoading());
};
