import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  show: false,
  idCliente: null,
  clientCreateOption: null,
  message: null,
  fullWidth: false,
  idUnidadeNegocio: null,
  ultimaAtividadeFichaVisitaAbertaPk: null,
};

export const showCreateOpportunity = createAction('SHOW_CREATE_OPPORTUNITY');
export const hideCreateOpportunity = createAction('HIDE_CREATE_OPPORTUNITY');

export const createOpportunityModalReducer = handleActions(
  {
    [showCreateOpportunity]: (
      state,
      {
        payload: {
          idCliente = '',
          roteirizacaoClienteId = '',
          clientCreateOption = '',
          message = null,
          fullWidth = false,
          ultimaAtividadeFichaVisitaAbertaPk = null,
          idUnidadeNegocio = null,
          contatoId,
          tipoContato,
          urlMapa,
        },
      }
    ) => ({
      ...state,
      show: true,
      idCliente,
      roteirizacaoClienteId,
      message,
      clientCreateOption,
      fullWidth,
      ultimaAtividadeFichaVisitaAbertaPk,
      idUnidadeNegocio,
      contatoId,
      tipoContato,
      urlMapa,
    }),
    [hideCreateOpportunity]: state => ({ ...state, ...defaultState }),
  },
  defaultState
);
