import gql from 'graphql-tag';

export const CONSULTORES_EXTERNOS_QUERY_GQL = gql`
  query GetConsultoresExternos {
    consultoresExternos {
      id
      nome
      dealersGrupo {
        id
      }
    }
  }
`;
