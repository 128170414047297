import React from 'react';
import { Button } from '@tecsinapse/ui-kit';

export const NotAllowed = ({ logoutHandler }) => (
  <div>
    <p>Acesso não autorizado. Favor solicitar liberação.</p>
    <p>
      ou realize o logout{' '}
      <Button size="small" color="primary" onClick={logoutHandler}>
        aqui
      </Button>
    </p>
  </div>
);
