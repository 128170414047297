import styled, { keyframes } from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';
import { defaultGreyLight3 } from '../../app/globalStyle';

const animation = keyframes`
  0% {height:0}
  100% {height:178px;}    
`;

const StyledAnimation = styled.div`
  animation: ${animation} 0.3s ease-in;
  background: ${defaultGreyLight3};
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

const style = { fontSize: 20, marginBottom: -4 };

export const WarningSign = () => {
  return (
    <StyledAnimation>
      <Grid container>
        <Grid item xs={12}>
          <Typography color="primary">
            Ao arquivar as seguintes ações serão tomadas:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" variant="subtitle2">
            <Info style={style} />
            Bloqueio de comunicação com o cliente.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" variant="subtitle2">
            <Info style={style} />
            Bloqueio de comunicação com o veículo.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" variant="subtitle2">
            <Info style={style} />
            Inativa o cliente no setor.
          </Typography>
        </Grid>
      </Grid>
    </StyledAnimation>
  );
};
