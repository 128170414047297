import gql from 'graphql-tag';
import { withQuery } from '../../../utils/crud/withGenericQuery';

export const crmVarianteTermoQueryGql = gql`
  query crmVarianteTermoQuery {
    crmVarianteTermo
  }
`;

export const crmModeloTermoQueryGql = gql`
  query crmModeloTermoQuery {
    crmModeloTermo
  }
`;

export const crmVersaoTermoQueryGql = gql`
  query crmVersaoTermoQuery {
    crmVersaoTermo
  }
`;

export const crmCamposModeloAtivosQueryGql = gql`
  query crmCamposModeloAtivosQuery {
    crmCamposModeloAtivos
  }
`;

export const crmNovosMostrarModeloQueryGql = gql`
  query crmNovosMostrarModeloQuery {
    crmNovosMostrarModelo
  }
`;

export const crmNovosMostrarSerieQueryGql = gql`
  query crmNovosMostrarSerieQuery {
    crmNovosMostrarSerie
  }
`;

export const crmVarianteModeloAtivoQueryGql = gql`
  query crmVarianteModeloAtivoQuery {
    crmVarianteModeloAtivo
  }
`;

export const crmIsNegociacaoAceitaAtivoQueryGql = gql`
  query crmIsNegociacaoAceitaAtivoQuery {
    crmIsNegociacaoAceitaAtivo
  }
`;

export const crmAnoModeloAtivoQueryGql = gql`
  query crmAnoModeloAtivoQuery {
    crmAnoModeloAtivo
  }
`;

export const crmUsadosPriorizarMarcasClienteQueryGql = gql`
  query crmUsadosPriorizarMarcasClienteQuery {
    crmUsadosPriorizarMarcasCliente
  }
`;

export const crmObrigarModeloInteresseAtivoQueryGql = gql`
  query crmObrigarModeloInteresseAtivoQuery {
    crmObrigarModeloInteresseAtivo
  }
`;

export const crmObrigarModeloInteresseComVarianteAtivoQueryGql = gql`
  query crmObrigarModeloInteresseComVarianteAtivoQuery {
    crmObrigarModeloInteresseComVarianteAtivo
  }
`;

export const crmIndicadorFupMobileAtivoQueryGql = gql`
  query crmIndicadorFupMobileAtivoQuery {
    crmIndicadorFupMobileAtivo
  }
`;

export const crmProspeccaoNomePatternQueryGql = gql`
  query crmProspeccaoNomePatternQuery {
    crmProspeccaoNomePattern
  }
`;

export const crmProspeccoesNomePatternQueryGql = gql`
  query crmProspeccoesNomePatternQuery {
    crmProspeccoesNomePattern
  }
`;

export const withCrmProspeccaoNomePatternQuery = withQuery({
  name: 'crmProspeccaoNomePattern',
  queryPropsName: 'crmProspeccaoNomePattern',
  gqlInput: crmProspeccaoNomePatternQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

const nameCrmObrigarPreenchimentoDadosVeiculoOportunidade =
  'crmObrigarPreenchimentoDadosVeiculoOportunidade';

export const crmObrigarPreenchimentoDadosVeiculoOportunidadeQueryGql = gql`
  query ${nameCrmObrigarPreenchimentoDadosVeiculoOportunidade}Query {
    ${nameCrmObrigarPreenchimentoDadosVeiculoOportunidade}
  }
`;

export const withCrmObrigarPreenchimentoDadosVeiculoOportunidade = withQuery({
  name: nameCrmObrigarPreenchimentoDadosVeiculoOportunidade,
  queryPropsName: nameCrmObrigarPreenchimentoDadosVeiculoOportunidade,
  gqlInput: crmObrigarPreenchimentoDadosVeiculoOportunidadeQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withTermoModelo = withQuery({
  name: 'crmModeloTermo',
  queryPropsName: 'crmModeloTermo',
  gqlInput: crmModeloTermoQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withTermoVersao = withQuery({
  name: 'crmVersaoTermo',
  queryPropsName: 'crmVersaoTermo',
  gqlInput: crmVersaoTermoQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withTermoVariante = withQuery({
  name: 'crmVarianteTermo',
  queryPropsName: 'crmVarianteTermo',
  gqlInput: crmVarianteTermoQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withCamposModeloAtivos = withQuery({
  name: 'crmCamposModeloAtivos',
  queryPropsName: 'crmCamposModeloAtivos',
  gqlInput: crmCamposModeloAtivosQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withVeiculosNovosMostrarModelo = withQuery({
  name: 'crmNovosMostrarModelo',
  queryPropsName: 'crmNovosMostrarModelo',
  gqlInput: crmNovosMostrarModeloQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withVeiculosNovosMostrarSerie = withQuery({
  name: 'crmNovosMostrarSerie',
  queryPropsName: 'crmNovosMostrarSerie',
  gqlInput: crmNovosMostrarSerieQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withVarianteModeloAtivo = withQuery({
  name: 'crmVarianteModeloAtivo',
  queryPropsName: 'crmVarianteModeloAtivo',
  gqlInput: crmVarianteModeloAtivoQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withIsNegociacaoAceitaAtivo = withQuery({
  name: 'crmIsNegociacaoAceitaAtivo',
  queryPropsName: 'crmIsNegociacaoAceitaAtivo',
  gqlInput: crmIsNegociacaoAceitaAtivoQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withAnoModeloAtivo = withQuery({
  name: 'crmAnoModeloAtivo',
  queryPropsName: 'crmAnoModeloAtivo',
  gqlInput: crmAnoModeloAtivoQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withUsadosPriorizarMarcasCliente = withQuery({
  name: 'crmUsadosPriorizarMarcasCliente',
  queryPropsName: 'crmUsadosPriorizarMarcasCliente',
  gqlInput: crmUsadosPriorizarMarcasClienteQueryGql,
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  skip: null,
});

export const withObrigarModeloInteresseAtivo = withQuery({
  name: 'crmObrigarModeloInteresseAtivo',
  queryPropsName: 'crmObrigarModeloInteresseAtivo',
  gqlInput: crmObrigarModeloInteresseAtivoQueryGql,
  options: {
    fetchPolicy: 'cache-first',
  },
  skip: null,
});

export const withObrigarModeloInteresseComVarianteAtivo = withQuery({
  name: 'crmObrigarModeloInteresseComVarianteAtivo',
  queryPropsName: 'crmObrigarModeloInteresseComVarianteAtivo',
  gqlInput: crmObrigarModeloInteresseComVarianteAtivoQueryGql,
  options: {
    fetchPolicy: 'cache-first',
  },
  skip: null,
});

export const withIndicadorFupMobileAtivo = withQuery({
  name: 'crmIndicadorFupMobileAtivo',
  queryPropsName: 'crmIndicadorFupMobileAtivo',
  gqlInput: crmIndicadorFupMobileAtivoQueryGql,
  options: {
    fetchPolicy: 'cache-first',
  },
  skip: null,
});
