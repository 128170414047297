import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core';
import pick from 'lodash.pick';
import { Button } from '@tecsinapse/ui-kit';

import React from 'react';
import { withRouter } from 'react-router';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { Field } from 'redux-form';
import {
  getFirstFromSingleElementArrayNotNull,
  isEmptyOrNull,
  omitDeep,
} from '@tecsinapse/es-utils/build';
import styled from 'styled-components';
import { getAlertContext } from '../../utils/alertContext';
import { displayLoadingState } from '../../utils/displayLoadingState';
import { OPPORTUNITY_CREATE_MODAL_NAME } from './CreateOpportunityFormConsts';
import { OpportunityCreateClientInfoQuery } from './OpportunityCreateClientInfoQuery';
import { reduxFormAutoFocus } from '../../utils/reduxFormAutoFocus';
import {
  RenderSelectField,
  SelectComponentStyled,
} from '../../utils/select/RenderSelectField';
import {
  ClientCreateOptionEnum,
  TipoAcaoInformarContato,
} from '../../../core/Enums';
import { required } from '../../utils/FormUtils';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import { informarContatoClienteMutation } from '../../Client/clienteBottomSheet/data/informarContatoClienteMutation';
import {
  createFichaVisitaAtividadeMutation,
  createOportunityMutation,
  createProspectionMutation,
} from './ClientCreateOptionsMutations';
import { postInformCallActions } from '../../Client/clienteBottomSheet/postInformCallActions';
import { withAtualizaModeloInativo } from '../../AtualizaModeloInativo/withAtualizaModeloInativo';
import { createRoteirizacaoMutation } from '../../Rotas/data/createRoteirizacaoMutationGpl';
import { LoadingContained } from '../../utils/Loading';

const GridPaddin = styled.div`
  margin: ${props => props?.theme && props.theme.spacing(1)}px;
`;

const LoadingComponent = styled(LoadingContained)`
  paddingbottom: ${props => props.theme.spacing(3)}px;
`;

const createFormEnhance = compose(
  withRouter,
  getAlertContext,
  createOportunityMutation,
  createProspectionMutation,
  createFichaVisitaAtividadeMutation,
  createRoteirizacaoMutation,
  informarContatoClienteMutation,
  OpportunityCreateClientInfoQuery,
  withAtualizaModeloInativo,
  displayLoadingState({
    name: 'clienteGruposAtendidos',
    onCacheLoadOnlyQueryName: 'OpportunityCreateClientInfoQuery',
    LoadingComponent,
  }),
  withState(
    'clientCreateOptionState',
    'setClientCreateOptionState',
    ({ clientCreateOption }) => clientCreateOption
  ),
  withProps(({ idCliente, clienteGruposAtendidos }) => {
    const initialValues = {
      idCliente,
    };

    if (
      clienteGruposAtendidos &&
      clienteGruposAtendidos.cliente &&
      clienteGruposAtendidos.cliente.unidadesNegocioAtendidos
    ) {
      initialValues.idUnidadeNegocio = getFirstFromSingleElementArrayNotNull(
        clienteGruposAtendidos.cliente.unidadesNegocioAtendidos
      ).id;
    }

    if (clienteGruposAtendidos && clienteGruposAtendidos.tiposContato) {
      initialValues.idTipoContato = getFirstFromSingleElementArrayNotNull(
        clienteGruposAtendidos.tiposContato
      ).id;
    }

    return { initialValues };
  }),
  reduxFormAutoFocus({
    // a unique name for the form
    form: OPPORTUNITY_CREATE_MODAL_NAME,
    onSubmit: (values, dispatch, props) => {
      const {
        history,
        createOportunity,
        informarContatoCliente,
        criarProspeccao,
        criarFichaVisitaAtividade,
        closeAlert,
        clientCreateOptionState,
        createModal,
        ultimaAtividadeFichaVisitaAbertaPk,
        location,
        setShowInformCallModal,
        setAtividades,
        contatoId,
        tipoContato,
        atualizaModeloInativoEAbreDialog,
        criarRoteirizacao,
        setSelectedRoute,
        roteirizacaoClienteId = null,
      } = props;
      let urlRetorno = location.pathname?.replace('/', '');

      urlRetorno = urlRetorno ? encodeURIComponent(urlRetorno) : null;

      const submitValues = pick(values, [
        'idUnidadeNegocio',
        'idCliente',
        'idTipoContato',
      ]);

      let variables =
        clientCreateOptionState === ClientCreateOptionEnum.OPPORTUNITY
          ? { oportunidade: submitValues }
          : {
              idCliente: submitValues.idCliente,
              idUnidadeNegocio: submitValues.idUnidadeNegocio,
            };

      if (clientCreateOptionState === ClientCreateOptionEnum.OPPORTUNITY) {
        return createOportunity({
          variables,
        }).then(
          ({
            data: {
              criarOportunidade: { id },
            },
          }) => {
            closeAlert();
            history.push(`/oportunidade/${id}`);
          }
        );
      }

      if (
        clientCreateOptionState ===
        ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA
      ) {
        if (roteirizacaoClienteId) {
          if (ultimaAtividadeFichaVisitaAbertaPk) {
            closeAlert();

            return history.push(
              `/realizarAtividade/${ultimaAtividadeFichaVisitaAbertaPk}/${roteirizacaoClienteId}/${urlRetorno}/rotas`
            );
          }
          variables = { ...omitDeep(variables, 'idUnidadeNegocio') };

          return criarFichaVisitaAtividade({
            variables,
          }).then(
            ({
              data: {
                fichaVisitaAgendarAtividade: { id },
              },
            }) => {
              closeAlert();
              history.push(
                `/realizarAtividade/${id}/${roteirizacaoClienteId}/${urlRetorno}/rotas`
              );
            }
          );
        }

        if (ultimaAtividadeFichaVisitaAbertaPk) {
          closeAlert();

          return history.push(
            `/realizarAtividade/${ultimaAtividadeFichaVisitaAbertaPk}/${urlRetorno}`
          );
        }
        variables = { ...omitDeep(variables, 'idUnidadeNegocio') };

        return criarFichaVisitaAtividade({
          variables,
        }).then(
          ({
            data: {
              fichaVisitaAgendarAtividade: { id },
            },
          }) => {
            closeAlert();
            history.push(`/realizarAtividade/${id}/${urlRetorno}`);
          }
        );
      }

      if (
        clientCreateOptionState === ClientCreateOptionEnum.GERAR_ROTEIRIZACAO
      ) {
        return criarRoteirizacao({}).then(resp => {
          closeAlert();
          const { roteirizar } = resp.data;

          window.localStorage.setItem(
            'ultimaRoteirizacao',
            JSON.stringify(roteirizar)
          );
          setSelectedRoute(null);
          history.push('/mapa', { urlMapa: roteirizar.urlMapa });
        });
      }

      if (clientCreateOptionState === ClientCreateOptionEnum.PROSPECTION) {
        return criarProspeccao({
          variables,
        }).then(
          ({
            data: {
              criarProspeccao: { id },
            },
          }) => {
            closeAlert();
            history.push(`/realizarAtividade/${id}`);
          }
        );
      }

      return informarContatoCliente({
        variables: {
          idCliente: submitValues.idCliente,
          idUnidadeNegocio: submitValues.idUnidadeNegocio,
          idTipoContato: submitValues.idTipoContato,
        },
      }).then(
        postInformCallActions({
          history,
          createModal,
          closeAlert,
          setAtividades,
          setShowInformCallModal,
          contatoId,
          tipoContato,
          atualizaModeloInativoEAbreDialog,
          urlRetorno,
        })
      );
    },
  }),
  withHandlers({
    goToCriarERealizarAtividade: ({
      history,
      idClient,
      idUnidadeNegocio,
    }) => tipoAcaoInformarContato =>
      history.push(
        `/criarERealizarAtividade/${tipoAcaoInformarContato}/${idClient}/${idUnidadeNegocio}`
      ),
  })
);
const CreateFormComponent = ({
  clienteGruposAtendidos,
  handleSubmit,
  initialValues,
  submitting,
  parentClasses,
  clientCreateOption,
  clientCreateOptionState,
  setClientCreateOptionState,
  formName,
  message,
  loading,
  closeAlert,
  classes,
  goToCriarERealizarAtividade,
}) => {
  if (loading || clientCreateOption === null) {
    return <LoadingSpinner />;
  }

  const { tiposContato = [], cliente } = clienteGruposAtendidos || {};
  const { unidadesNegocioAtendidos = [] } = cliente || {};

  const style = { overflow: 'visible' };

  return (
    <form
      onSubmit={handleSubmit}
      className={[parentClasses.form, parentClasses.overflow].join(' ')}
      name={formName}
    >
      <DialogContent style={style}>
        <DialogContentText id="alert-dialog-description">
          {!message &&
            clientCreateOption === ClientCreateOptionEnum.CHOICE.name && (
              <SelectComponentStyled
                input={{
                  value: clientCreateOptionState,
                  onChange: e => {
                    setClientCreateOptionState(e);
                  },
                }}
                placeholder="Dealer"
                label="Dealer"
                classes={classes}
                fullWidth
                margin="normal"
              >
                <MenuItem
                  value={ClientCreateOptionEnum.OPPORTUNITY.name}
                  button
                >
                  {ClientCreateOptionEnum.OPPORTUNITY.title}
                </MenuItem>
                <MenuItem
                  value={ClientCreateOptionEnum.PROSPECTION.name}
                  button
                >
                  {ClientCreateOptionEnum.PROSPECTION.title}
                </MenuItem>
              </SelectComponentStyled>
            )}

          {!!message && message}

          {!message &&
            clientCreateOptionState === ClientCreateOptionEnum.OPPORTUNITY &&
            'Por favor, preencha as informações abaixo.'}

          {!message &&
            clientCreateOptionState ===
              ClientCreateOptionEnum.PEDIR_TIPO_CRIAR_ATIVIDADE.name &&
            'Por favor, qual tipo de atividade você deseja criar?'}

          {!message &&
            clientCreateOptionState === ClientCreateOptionEnum.PROSPECTION &&
            'Tem certeza que deseja criar nova prospecção?'}

          {!message &&
            clientCreateOption ===
              ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA &&
            'Tem certeza que deseja realizar uma atividade para este cliente?'}

          {!message &&
            clientCreateOption ===
              ClientCreateOptionEnum.GERAR_ROTEIRIZACAO && (
              <>
                <div>Tem certeza que deseja roteirizar?</div>
                <div>
                  O tempo de processamento pode durar alguns segundos, se
                  necessário retorne ao app mais tarde!
                </div>
              </>
            )}
        </DialogContentText>
        <GridPaddin container>
          <Grid item xs={12}>
            {isEmptyOrNull(initialValues.idTipoContato) &&
              clientCreateOptionState !==
                ClientCreateOptionEnum.PEDIR_TIPO_CRIAR_ATIVIDADE.name &&
              !(
                clientCreateOptionState ===
                  ClientCreateOptionEnum.PROSPECTION ||
                clientCreateOption ===
                  ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA ||
                clientCreateOption === ClientCreateOptionEnum.GERAR_ROTEIRIZACAO
              ) && (
                <Field
                  name="idTipoContato"
                  label="Tipo do Contato"
                  required
                  component={RenderSelectField}
                  validate={[required]}
                  fullWidth
                >
                  {tiposContato.map(({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  ))}
                </Field>
              )}
            {isEmptyOrNull(initialValues.idUnidadeNegocio) &&
              !(
                clientCreateOption ===
                  ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA ||
                clientCreateOption === ClientCreateOptionEnum.GERAR_ROTEIRIZACAO
              ) &&
              clientCreateOptionState !==
                ClientCreateOptionEnum.PEDIR_TIPO_CRIAR_ATIVIDADE.name && (
                <Field
                  name="idUnidadeNegocio"
                  label="Unidade de Atendimento"
                  required
                  component={RenderSelectField}
                  validate={[required]}
                  fullWidth
                >
                  {unidadesNegocioAtendidos &&
                    unidadesNegocioAtendidos.map(({ id, descricao }) => (
                      <MenuItem value={id} key={id}>
                        {descricao}
                      </MenuItem>
                    ))}
                </Field>
              )}
          </Grid>
        </GridPaddin>
      </DialogContent>
      <DialogActions>
        {clientCreateOption !==
        ClientCreateOptionEnum.PEDIR_TIPO_CRIAR_ATIVIDADE.name ? (
          <>
            <Button
              id="create-options-submit-button"
              type="submit"
              customVariant="success"
              submitting={submitting}
              autoFocus
            >
              {clientCreateOption ===
                ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA ||
              clientCreateOption === ClientCreateOptionEnum.GERAR_ROTEIRIZACAO
                ? 'Sim'
                : 'Criar'}
            </Button>
          </>
        ) : (
          <>
            <Button
              customVariant="success"
              submitting={submitting}
              autoFocus
              onClick={() =>
                goToCriarERealizarAtividade(
                  TipoAcaoInformarContato.CRIAR_ATIVIDADE_OPORTUNIDADE.name
                )
              }
            >
              Criar Atividade Oportunidade
            </Button>
            <Button
              customVariant="success"
              submitting={submitting}
              autoFocus
              onClick={() =>
                goToCriarERealizarAtividade(
                  TipoAcaoInformarContato.CRIAR_ATIVIDADE_OPORTUNIDADE.name
                )
              }
            >
              Criar Atividade Prospeccao
            </Button>
          </>
        )}

        <Button
          customVariant="warning"
          submitting={submitting}
          autoFocus
          onClick={closeAlert}
        >
          {clientCreateOption ===
            ClientCreateOptionEnum.NOVA_ATIVIDADE_FICHA_VISITA ||
          clientCreateOption === ClientCreateOptionEnum.GERAR_ROTEIRIZACAO
            ? 'Não'
            : 'Cancelar'}
        </Button>
      </DialogActions>
    </form>
  );
};

const ClientCreateOptionsEnhancedForm = createFormEnhance(CreateFormComponent);

export const ClientCreateOptionsModal = ({
  classes,
  show,
  closeAlert,
  clientCreateOption,
  fullWidth = false,
  ...props
}) => (
  <Dialog
    open={show}
    fullWidth
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    classes={{ paper: classes.overflow }}
  >
    <DialogTitle id="alert-dialog-title">
      {(clientCreateOption && clientCreateOption.title) || ''}
    </DialogTitle>
    <ClientCreateOptionsEnhancedForm
      parentClasses={classes}
      closeAlert={closeAlert}
      clientCreateOption={clientCreateOption}
      {...props}
    />
  </Dialog>
);
