/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Button } from '@tecsinapse/ui-kit';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Typography from '@material-ui/core/es/Typography/Typography';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Field, Form } from 'redux-form';
import { omitDeep } from '@tecsinapse/es-utils/build';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import {
  RenderDateSlider,
  RenderInput,
  validateRequired,
} from '../../utils/FormUtils';
import { reduxFormAutoFocus } from '../../utils/reduxFormAutoFocus';
import { getLocalDateBrTracoString } from '../../utils/dateUtils';
import {
  getLocation,
  registraLocalizacao,
} from '../../../core/geolocationUtils';
import { createReRoteirizacaoMutation } from '../data/createReroteirizacaoMutationGpl';
import { eventSaveReroute } from '../../utils/GAUtils';

const style = { height: '5%' };
const style1 = { overflow: 'visible', height: '80%' };
const style2 = { height: '100%' };

const formName = 'ReRoterizacaoForm';
const enhance = compose(
  withHandlers({
    validateTime: () => (_, values) => {
      const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/);

      if (!regex.test(_)) {
        return 'Horário inválido';
      }

      return undefined;
    },
  }),
  withState('loading', 'setLoading', false),
  withRouter,
  withProps(() => ({
    initialValues: {
      latitudeOrigem: null,
      longitudeOrigem: null,
      diasCongeladosIds: [],
      horarioDaChegadaAoDestinoDeHoje: '',
    },
  })),
  createReRoteirizacaoMutation,
  reduxFormAutoFocus({
    form: formName,
    validate: validateRequired(['horarioDaChegadaAoDestinoDeHoje', 'diasRota']),
    onSubmit: (
      values,
      dispatch,
      { criarReRoteirizacao, setLoading, handleClose, history, ultimaDataRota }
    ) => {
      eventSaveReroute();
      setLoading(true);
      const submitValues = omitDeep(values, '__typename');

      const dataAtual = moment().startOf('day');
      const diffDays = ultimaDataRota.diff(dataAtual, 'days');
      const diasCongeladosIds = [];

      for (let i = 0; i <= diffDays; i++) {
        const isDiaCongelado =
          !values.dateSlider.filter(
            a =>
              getLocalDateBrTracoString(moment(a)) ===
              getLocalDateBrTracoString(dataAtual)
          ).length > 0;

        if (isDiaCongelado) {
          diasCongeladosIds.push(getLocalDateBrTracoString(dataAtual));
        }

        dataAtual.add(1, 'days');
      }

      const variables = {
        latitudeOrigem: submitValues.latitudeOrigem,
        longitudeOrigem: submitValues.longitudeOrigem,
        diasCongeladosIds,
        horarioDaChegadaAoDestinoDeHoje: `${submitValues.horarioDaChegadaAoDestinoDeHoje}:00`,
      };

      return criarReRoteirizacao({
        variables,
      })
        .then(() => {
          setLoading(false);
          handleClose();
          history.push(`/rotas`);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  })
);

const stylesReRoteirizacaoDialog = theme => ({
  captionTextSync: {
    display: 'inline-block',
    marginTop: theme.spacing(1),
  },
});

const useStylesReRoteirizacaoDialog = makeStyles(stylesReRoteirizacaoDialog);

const calculaDiasRota = ultimaDataRota => {
  const dataAtual = moment().startOf('day');
  const diffDays = ultimaDataRota.diff(dataAtual, 'days');
  const diasRota = [];

  for (let i = 0; i <= diffDays; i++) {
    diasRota.push(dataAtual.toDate());
    dataAtual.add(1, 'days');
  }

  return diasRota;
};

export const ReRoteirizacaoDialog = enhance(
  ({
    open,
    ultimaDataRota,
    handleClose,
    handleSubmit,
    change,
    loading,
    validateTime,
  }) => {
    const [dates, setDates] = useState([]);
    const theme = useTheme();

    useEffect(() => {
      const setLocalizacao = localizacaoInput => {
        change('latitudeOrigem', localizacaoInput.latitude);
        change('longitudeOrigem', localizacaoInput.longitude);
      };

      getLocation(registraLocalizacao(setLocalizacao));
    }, [change]);

    const classes = useStylesReRoteirizacaoDialog();
    const diasSlider = calculaDiasRota(ultimaDataRota);

    const labelDiasProgramado = calculaDiasRota(moment(dates[1]));
    const diasProgramado =
      labelDiasProgramado.length > 0 &&
      labelDiasProgramado.map((dia, idx) => {
        return idx === labelDiasProgramado.length - 1
          ? moment(dia).format('dddd - DD/MM/YY')
          : `${moment(dia).format('dddd - DD/MM/YY')}, `;
      });

    const sliderStyles = { padding: theme.spacing(2) };

    return (
      <>
        {loading && <div id="cover-spin" />}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <Form
            name={formName}
            onSubmit={handleSubmit}
            className={classes.form}
            style={style2}
          >
            <DialogTitle id="alert-dialog-title" style={style}>
              <strong>Reprogramar Rota</strong>
            </DialogTitle>
            <DialogContent style={style1}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.captionTextSync}
                  >
                    <strong>Atenção!</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.captionTextSync}
                  >
                    Certifique-se de ter registrado devidamente as visitas aos
                    clientes desta rota. Clientes agendados e não registrados
                    serão considerados na reprogramação da rota.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Horário de chegada ao destino de hoje"
                    required
                    validate={validateTime}
                    name="horarioDaChegadaAoDestinoDeHoje"
                    component={RenderInput}
                    textMask="time"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.captionTextSync}
                  >
                    <strong>
                      Selecione os dias que deseja reprogramar a rota *
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {diasSlider.length > 0 && (
                    <>
                      <div style={sliderStyles}>
                        <Field
                          component={RenderDateSlider}
                          name="dateSlider"
                          values={dates}
                          range={diasSlider}
                          props={{
                            onChange: setDates,
                            calculaDiasRota,
                          }}
                          fullWidth
                          simple
                        />
                      </div>
                      <Typography
                        variant="subtitle2"
                        className={classes.captionTextSync}
                        style={{ marginTop: '24px' }}
                      >
                        <strong>Reprogramado para:</strong>
                      </Typography>
                      <Grid item xs={12}>
                        <Typography>{diasProgramado}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button type="submit" customVariant="success" autoFocus>
                OK
              </Button>

              <Button onClick={handleClose} customVariant="warning" autoFocus>
                Cancelar
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
  }
);
