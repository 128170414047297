import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  entitiesIds: [],
};

export const addEntityId = createAction('ADD_ENTITY_ID');
export const removeEntityId = createAction('REMOVE_ENTITY_ID');

export const findEntity = ({ id, mutationName, entityName }) => entity => {
  let aux = entity.id === id;

  if (entityName) {
    aux = aux && entity.entityName === entityName;
  }

  if (mutationName && typeof mutationName === 'string') {
    aux = aux && entity.mutationName === mutationName;
  }

  if (mutationName && Array.isArray(mutationName)) {
    aux = aux && mutationName.includes(entity.mutationName);
  }

  return aux;
};

export const offlineMutationsEntityIdReducer = handleActions(
  {
    [addEntityId]: (
      state,
      { payload: { id = '', mutationName = '', entityName = '' } }
    ) => {
      const entitiesIds = state.entitiesIds.slice(0);

      entitiesIds.push({ id, mutationName, entityName });

      return {
        ...state,
        entitiesIds,
      };
    },
    [removeEntityId]: (
      state,
      { payload: { id = '', mutationName = '', entityName = '' } }
    ) => {
      const { entitiesIds } = state;

      const newEntitiesIds = entitiesIds.filter(
        e => !findEntity({ id: parseInt(id, 10), mutationName, entityName })(e)
      );

      return { ...state, entitiesIds: newEntitiesIds };
    },
  },
  defaultState
);
