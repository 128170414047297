import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { displayLoadingState } from 'components/utils/displayLoadingState';
import RazoesVisitaDisponiveisQueryGql from './RazoesVisitaDisponiveisQueryGql.graphql';

const hoc = graphql(RazoesVisitaDisponiveisQueryGql, {
  name: 'razoesVisitaDisponiveisQuery',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const RazoesVisitaDisponiveisQueryHoc = compose(
  hoc,
  displayLoadingState({
    name: 'razoesVisitaDisponiveisQuery',
    onCacheLoadOnlyQueryName: 'razoesVisitaDisponiveis',
  })
);
