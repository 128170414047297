import { Chip, Typography } from '@material-ui/core';
import React from 'react';
import { defaultRed } from '../../../app/globalStyle';

const labelStyle = {
  height: 'auto',
  margin: '1px auto 0',
  color: '#fff',
  background: defaultRed,
  fontSize: '0.875rem',
  padding: '2px 0',
};

export const BotaoAtividadesAtrasadas = ({
  showAtrasadas,
  dayLabel = null,
  qtdAtrasadas,
  setShowAtrasadasAndSendToAnalytics,
  classes,
}) => {
  const qtdAtrasadasLabel = qtdAtrasadas > 1000 ? '999+' : qtdAtrasadas;

  return (
    <div
      onKeyPress={() => null}
      key={`botao-atrasadas${qtdAtrasadas}`}
      className={classes.day}
      style={showAtrasadas ? { backgroundColor: '#3a3a3a' } : {}}
      onClick={() => setShowAtrasadasAndSendToAnalytics()}
    >
      <Typography variant="caption" color="inherit" className={classes.dayName}>
        {dayLabel}
      </Typography>

      <Chip label={qtdAtrasadasLabel} style={labelStyle} />
    </div>
  );
};
