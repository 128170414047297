import {
  Card,
  CardContent,
  Chip,
  MenuItem,
  Typography,
} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import { CardLoading } from '../../../../utils/CardLoading';
import { printPorcentagemMaxima } from '../IndicadoresCardFunctions';
import { indicadoresStyles } from '../indicadoresStyles';
import { stylesOfCirularProgressbar } from '../indicadoresStyleUtils';
import { useIndicadorLeadsSemTratamentoHook } from './IndicadorLeadsSemTratamentoHook';
import { LeadsSemTratamentoDialog } from './LeadsSemTratamentoDialog';

const atividadesAtrasadaStyles = makeStyles(indicadoresStyles);

const style = {
  color: 'transparent',
  fontWeight: 600,
};
const style1 = {
  position: 'absolute',
  width: '100%',
  left: 0,
  minWidth: '150px',
};

const style3 = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};
const style5 = {
  padding: 0,
  marginBottom: 0,
};
const style6 = {
  width: '85px',
  display: 'flex',
  flexDirection: 'column',
};
const style7 = {
  marginLeft: '20px',
};
const style8 = {
  position: 'relative',
  minWidth: '150px',
};

export const IndicadorLeadsSemTratamento = props => {
  const classes = atividadesAtrasadaStyles();

  const {
    cor,
    leads,
    situacaoLead,
    qtdLeads,
    opcoes,
    loading,
    dialogTitle,
    dialogOpened,
    tiposOrigemOportunidade,
    qtdLeadsQualificacao,
    temMaisLeadsCarregar,
    isCarregandoMaisLeads,
    setDialogOpened,
    onChange,
    handleVisualizarLeads,
    carregaMaisLeads,
  } = useIndicadorLeadsSemTratamentoHook();

  const style2 = {
    backgroundColor: cor,
    color: 'white',
    padding: '2px',
  };

  const selectProps = {
    style,
    disableUnderline: true,
  };

  if (loading) {
    return <CardLoading />;
  }

  return (
    <>
      <Card className={classes.card} style={style5}>
        <CardHeader
          classes={{ title: classes.title }}
          className={classes.paddingCard}
          title="Leads sem tratamento"
        />

        <CardContent className={classes.cardContent}>
          <div style={style3}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div style={style6} onClick={handleVisualizarLeads}>
              <CircularProgressbar
                background
                percentage={100}
                text={`${printPorcentagemMaxima(qtdLeads)}`}
                strokeWidth={10}
                styles={stylesOfCirularProgressbar(cor)}
              />
            </div>

            <div style={style7}>
              <div style={style8}>
                <Typography variant="subtitle2">Situação</Typography>
                <Chip
                  label={opcoes[situacaoLead]}
                  classes={{ root: classes.chipMargin }}
                  style={style2}
                />
                <TextField
                  select
                  value={situacaoLead}
                  onChange={onChange}
                  SelectProps={selectProps}
                  style={style1}
                >
                  <MenuItem value={0} key="no_prazo">
                    {opcoes[0]}
                  </MenuItem>
                  <MenuItem value={1} key="fora_do_prazo">
                    {opcoes[1]}
                  </MenuItem>
                </TextField>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <LeadsSemTratamentoDialog
        {...props}
        title={dialogTitle}
        open={dialogOpened}
        leads={leads}
        loading={isCarregandoMaisLeads}
        loadMore={carregaMaisLeads}
        hasNext={temMaisLeadsCarregar}
        qtdLeadsQualificacao={qtdLeadsQualificacao}
        tiposOrigemOportunidade={tiposOrigemOportunidade}
        onClose={() => setDialogOpened(false)}
      />
    </>
  );
};
