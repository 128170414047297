import gql from 'graphql-tag';
import AtividadeFragment from '../../../Atividade/AtividadeFragment.graphql';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';

export const negociacaoAceitaMutationGql = gql`
  mutation negociacaoAceita($atividade: RealizacaoAtividade!) {
    negociacaoAceita(atividade: $atividade) {
      ...AtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

export const NegociacaoAceitaMutation = graphqlOfflineMutation(
  negociacaoAceitaMutationGql,
  {
    name: 'negociacaoAceita',
    mutationName: 'negociacaoAceitaMutationGql',
    customErrorsHandler: true,
    description: async ({
      variables: {
        atividade: { idAtividade },
      },
      apolloClient,
    }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `Atividade:${idAtividade}`,
          fragment: gql`
            fragment RealizacaoAtividadeOffline on Atividade {
              id
              cliente {
                id
                nome
              }
            }
          `,
        });
        const {
          cliente: { nome },
        } = data;

        return `Negociação aceita do cliente com nome: ${nome}.`;
      } catch (e) {
        return `Negociação aceita.`;
      }
    },
  }
);
