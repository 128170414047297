import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ObjetivosCardComponent } from '../Cards/ObjetivosCards/ObjetivosCardComponent';
import { FollowUp48Card } from '../Cards/FollowUp48Card/FollowUp48Card';
import { OportunidadesCardComponent } from '../Cards/OportunidadesCard/OportunidadesCardComponent';
import { crmProspeccoesNomePatternQueryGql } from '../../../opportunity/data/queries/crmTermosQuery';

export const IndicadoresComponents = ({
  exibirMensagens,
  dealerFiltro,
  unidadeNegocioFiltro,
  vendedorFiltro,
  setPeriodoGlobal,
  periodo,
  anoMesFinalGlobal,
  idSegmentoUnico,
  getDescricaoNormalizada,
  temperaturas,
  crmIndicadorFupMobileAtivo,
  urlRetorno,
  ...rest
}) => {
  const { data = {} } = useQuery(crmProspeccoesNomePatternQueryGql);
  const { crmProspeccoesNomePattern } = data;
  const {
    quantidadeSuperQuente,
    valorTotalQuente,
    valorTotalSuperQuente,
    quantidadeQuente,
    showDialogAtividades,
    idsSuperQuente,
    idsQuente,
  } = rest;

  return (
    <>
      <ObjetivosCardComponent
        titulo={crmProspeccoesNomePattern}
        periodo={periodo}
        setPeriodo={setPeriodoGlobal}
        anoMesFinal={anoMesFinalGlobal}
        exibirMensagens={exibirMensagens}
        dealer={dealerFiltro}
        unidadeNegocio={unidadeNegocioFiltro}
        usuario={vendedorFiltro}
        idSegmentoUnico={idSegmentoUnico}
        getDescricaoNormalizada={getDescricaoNormalizada}
        {...rest.QUANTIDADE_PROSPECCOES_REALIZADAS}
      />
      {/* FIXME - Isso não deveria estar aqui, este componente é só para indicadores de objetivo */}
      {crmIndicadorFupMobileAtivo && (
        <FollowUp48Card
          showDialogAtividades={showDialogAtividades}
          dealer={dealerFiltro}
          unidadeNegocio={unidadeNegocioFiltro}
          usuario={vendedorFiltro}
          idSegmentoUnico={idSegmentoUnico}
          urlRetorno={urlRetorno}
        />
      )}
      <ObjetivosCardComponent
        titulo="Contatos de Relacionamento"
        setPeriodo={setPeriodoGlobal}
        periodo={periodo}
        anoMesFinal={anoMesFinalGlobal}
        exibirMensagens={exibirMensagens}
        dealer={dealerFiltro}
        unidadeNegocio={unidadeNegocioFiltro}
        usuario={vendedorFiltro}
        idSegmentoUnico={idSegmentoUnico}
        getDescricaoNormalizada={getDescricaoNormalizada}
        {...rest.QUANTIDADE_CONTATO_RELACIONAMENTO}
      />
      <ObjetivosCardComponent
        titulo="Test Drives Realizados"
        setPeriodo={setPeriodoGlobal}
        periodo={periodo}
        anoMesFinal={anoMesFinalGlobal}
        dealer={dealerFiltro}
        usuario={vendedorFiltro}
        unidadeNegocio={unidadeNegocioFiltro}
        idSegmentoUnico={idSegmentoUnico}
        exibirMensagens={exibirMensagens}
        getDescricaoNormalizada={getDescricaoNormalizada}
        {...rest.TEST_DRIVE}
      />
      <ObjetivosCardComponent
        titulo="Vendas"
        setPeriodo={setPeriodoGlobal}
        periodo={periodo}
        anoMesFinal={anoMesFinalGlobal}
        exibirMensagens={exibirMensagens}
        dealer={dealerFiltro}
        unidadeNegocio={unidadeNegocioFiltro}
        usuario={vendedorFiltro}
        idSegmentoUnico={idSegmentoUnico}
        getDescricaoNormalizada={getDescricaoNormalizada}
        {...rest.QUANTIDADE_VEICULOS_VENDIDOS}
      />
      {/* FIXME - Isso não deveria estar aqui, este componente é só para indicadores de objetivo */}
      <OportunidadesCardComponent
        quantidadeSuperQuente={quantidadeSuperQuente}
        valorTotalQuente={valorTotalQuente}
        valorTotalSuperQuente={valorTotalSuperQuente}
        quantidadeQuente={quantidadeQuente}
        showDialogAtividades={showDialogAtividades}
        idsSuperQuente={idsSuperQuente}
        idsQuente={idsQuente}
        dealer={dealerFiltro}
        unidadeNegocio={unidadeNegocioFiltro}
        usuario={vendedorFiltro}
        idSegmentoUnico={idSegmentoUnico}
        getDescricaoNormalizada={getDescricaoNormalizada}
        temperaturas={temperaturas}
      />
    </>
  );
};
