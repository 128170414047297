import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import { Input, Select, DateSlider } from '@tecsinapse/ui-kit';
import { DateTimePicker } from '@tecsinapse/pickers/build/Picker/DateTimePicker';
import { TimePicker } from '@tecsinapse/pickers/build/Picker/TimePicker';
import { DatePicker } from '@tecsinapse/pickers/build/Picker/DatePicker';
import {
  Help,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import ChipInput from 'material-ui-chip-input';
import React from 'react';
import { connect } from 'react-redux';
import { toClass } from 'recompose';
import { Field, formValueSelector } from 'redux-form';
import omit from 'lodash.omit';
import { isEmptyOrNull, isNotEmptyOrNull } from '@tecsinapse/es-utils/build';
import moment from 'moment';
import { useTheme } from '@material-ui/styles';

export const submitWithAction = ({
  handleSubmit,
  onSubmit,
  action,
  payload,
}) => () => {
  handleSubmit((values, dispatch, props) =>
    onSubmit(
      {
        ...values,
        action,
        payload,
      },
      dispatch,
      props
    )
  )();
};

export const createRender = (render, { key = undefined } = {}) => props => {
  const {
    fullWidth,
    meta: { touched, error },
  } = props;

  return (
    <FormControl key={key} error={error && touched} fullWidth={fullWidth}>
      {render({ error: touched && error, ...props })}
    </FormControl>
  );
};

export const connectToFormFields = (formName, fields = []) =>
  connect(state =>
    fields.reduce((acc, field) => {
      acc[field] = formValueSelector(formName)(state, field);

      return acc;
    }, {})
  );

export const NumberField = props => {
  const {
    label,
    fullWidth,
    input,
    meta: { touched, error },
    min,
    disabled,
  } = props;

  return (
    <Input
      {...input}
      disabled={disabled || false}
      fullWidth={fullWidth}
      label={label}
      inputProps={{ min, pattern: '[0-9]*' }}
      type="number"
      error={touched && error}
    />
  );
};
const styleSwitchField = { textAlign: 'center' };

export const SwitchField = ({ label, input, disabled }) => {
  return (
    <div style={styleSwitchField}>
      <Typography variant="caption">{label}</Typography>
      <Switch
        {...input}
        disabled={disabled}
        checked={!!input.value}
        value={input.value.toString()}
      />
    </div>
  );
};

export const RenderDateSlider = createRender(
  ({ range, values, onChange, simple, input, calculaDiasRota }) => {
    const handleChange = array => {
      onChange(array);
      input.onChange(calculaDiasRota(moment(array[1])));
    };

    return (
      <DateSlider
        range={range}
        values={values}
        onChange={handleChange}
        simple={simple}
      />
    );
  }
);

export const RenderInput = createRender(
  ({
    input,
    error,
    tooltip,
    endAdornment,
    inputComponent,
    textMask,
    multiline,
    showNumericKeyboard = false,
    ...rest
  }) => {
    const inputProps = {
      ...rest.inputProps,
    };

    if (showNumericKeyboard) {
      inputProps.pattern = '[0-9]*';
    }

    const endAdornmentInputed =
      endAdornment || tooltip ? (
        <>
          {endAdornment}
          {tooltip ? (
            <Tooltip title={tooltip} placement="right">
              <Help />
            </Tooltip>
          ) : null}
        </>
      ) : undefined;

    return (
      <Input
        {...input}
        {...rest}
        error={error}
        multiline={multiline}
        onBlur={() => {}}
        inputProps={inputProps}
        autoComplete="off"
        inputComponent={inputComponent || undefined}
        mask={textMask}
        endAdornment={endAdornmentInputed}
      />
    );
  }
);

const styleChipsInput = { marginBottom: 20 };

export const ChipsInput = toClass(
  ({ fields, label, fullWidth, meta: { error, submitFailed } }) => {
    return (
      <ChipInput
        label={label}
        fullWidth={fullWidth}
        blurBehavior="add"
        InputProps={{ multiline: true }}
        style={styleChipsInput}
        value={fields.getAll() || []}
        onAdd={chip => fields.push(chip)}
        onDelete={(chip, index) => fields.remove(index)}
        helperText={submitFailed ? error : null}
        error={isNotEmptyOrNull(error) && submitFailed}
      />
    );
  }
);

const ErrorMessage = ({ meta: { touched, error, warning } }) => {
  const errorOrWaning = error || warning;

  return touched && errorOrWaning ? (
    <FormHelperText>{error || warning}</FormHelperText>
  ) : null;
};

export const CheckBoxComponent = ({ fields, label, options, ...props }) => {
  const {
    required,
    fullWidth,
    row = false,
    height = 25,
    className,
    meta: { error, submitFailed },
  } = props;
  const checkboxes = options.map(({ _id, label: checkboxLabel }) => {
    const handleChange = (e, checked) => {
      if (checked) {
        fields.push(_id);
      } else {
        fields.remove(fields.getAll().indexOf(_id));
      }
    };
    const checked = (fields.getAll() || []).includes(_id);

    return createRender(
      () => (
        <FormControlLabel
          style={{ height }}
          label={checkboxLabel}
          control={
            <Checkbox value={_id} checked={checked} onChange={handleChange} />
          }
        />
      ),
      { key: `checkbox-${_id}`, renderLabel: false }
    )(props);
  });

  return (
    <FormControl
      component="fieldset"
      required={required}
      fullWidth={fullWidth}
      error={error && submitFailed}
      className={className}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={row}>{checkboxes}</FormGroup>
      <FormHelperText>{submitFailed && error}</FormHelperText>
    </FormControl>
  );
};

export const RadioGroupInput = props => {
  const {
    label,
    input,
    children,
    style,
    required,
    fullWidth,
    meta: { error, warning, touched },
  } = props;

  return (
    <FormControl
      component="fieldset"
      required={required}
      fullWidth={fullWidth}
      error={(error || warning) && touched}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={input.name} {...input} style={style}>
        {children}
      </RadioGroup>
      <ErrorMessage {...props} />
    </FormControl>
  );
};
export const MapRadioField = ({ map, ...props }) => (
  <Field {...props} component={RadioGroupInput}>
    {map.map(({ id, nome }) => (
      <FormControlLabel
        key={id}
        value={id}
        control={<Radio color="primary" />}
        label={nome}
      />
    ))}
  </Field>
);
export const RenderDateTimePicker = props => {
  const {
    showErrorsInline,
    input: { onChange, value, name },
    meta: { touched, error },
    ...other
  } = omit(props, ['dispatch']);

  const showError = showErrorsInline || touched;

  return (
    <DateTimePicker
      name={name}
      error={!!(showError && error)}
      helperText={showError ? error : undefined}
      value={value}
      showTabs={false}
      ampm={false}
      showTodayButton
      todayLabel="Hoje"
      cancelLabel="Cancelar"
      okLabel="OK"
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      invalidLabel="Selecione"
      onChange={onChange}
      format="DD/MM/YYYY HH:mm"
      {...other}
    />
  );
};

export const RenderDatePicker = props => {
  const {
    showErrorsInline,
    name,
    input: { onChange, value },
    meta: { touched, error },
    ...other
  } = omit(props, ['dispatch']);

  const showError = showErrorsInline || touched;

  return (
    <DatePicker
      name={name}
      error={!!(showError && error)}
      helperText={showError ? error : undefined}
      showTodayButton
      todayLabel="Hoje"
      cancelLabel="Cancelar"
      okLabel="OK"
      value={isEmptyOrNull(value) ? null : value}
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      onChange={onChange}
      format="LL"
      {...other}
    />
  );
};

export const RenderTimePicker = props => {
  const {
    showErrorsInline,
    name,
    input: { onChange, value },
    meta: { touched, error },
    ...other
  } = omit(props, ['dispatch']);

  const showError = showErrorsInline || touched;

  return (
    <TimePicker
      name={name}
      ampm={false}
      error={!!(showError && error)}
      cancelLabel="Cancelar"
      okLabel="OK"
      selectedTime={isEmptyOrNull(value) ? null : value}
      value={isEmptyOrNull(value) ? null : value}
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      onChange={onChange}
      format="HH:mm"
      {...other}
    />
  );
};

export const SubmitButton = ({
  label,
  submitting,
  fullWidth,
  color,
  backgroundColor,
  textColor,
  disabled,
  onClick,
  secundary = false,
  third = false,
  marginTop10 = true,
  disableElevation = true,
  variant,
  borderColor,
}) => {
  const theme = useTheme();

  const isStyled =
    color !== undefined ||
    backgroundColor !== undefined ||
    borderColor !== undefined ||
    textColor !== undefined;

  const styleByButton = () => {
    let style = isStyled
      ? {
          backgroundColor,
          color: textColor,
          borderColor,
        }
      : {};

    if (marginTop10) {
      style = {
        ...style,
        marginTop: theme.spacing(1),
      };
    }

    if (disabled) {
      style = {
        ...style,
        marginTop: theme.spacing(1),
      };
    }

    return style;
  };

  let customVariant = 'success';

  if (third) {
    customVariant = 'error';
  } else if (secundary) {
    customVariant = 'warning';
  }

  return (
    <Button
      size="large"
      type="submit"
      style={styleByButton()}
      color={isStyled ? color : undefined}
      fullWidth={fullWidth}
      disabled={disabled}
      submitting={submitting}
      onClick={onClick}
      customVariant={!isStyled ? customVariant : undefined}
      variant={variant}
      disableElevation={disableElevation}
    >
      {label}
    </Button>
  );
};

export const validateRequired = requiredFields => values => {
  const errors = {};

  requiredFields.forEach(field => {
    if (field.constructor === Array) {
      const isAnyFieldEmpty =
        field.filter(a => isNotEmptyOrNull(values[a])).length > 0;

      if (!isAnyFieldEmpty) {
        field.forEach(f => {
          errors[f] = 'Por favor preencha ao menos um destes campos';
        });
      }
    } else if (isEmptyOrNull(values[field])) {
      errors[field] = 'Campo obrigatório';
    }
  });

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i.test(values.email)
  ) {
    errors.email = 'Email inválido';
  }

  return errors;
};

export const required = value =>
  isEmptyOrNull(value) ? 'Campo obrigatório' : undefined;

export const requiredArray = value =>
  isNotEmptyOrNull(value) && value.length > 0 ? undefined : 'Campo obrigatório';

export const requiredIfNotConsultorExterno = (value, values, props) => {
  if (
    props &&
    props.data &&
    props.data.usuarioLogado &&
    props.data.usuarioLogado.consultorExterno
  ) {
    return undefined;
  }

  if (
    props &&
    props.GetUsuarioLogado &&
    props.GetUsuarioLogado.usuarioLogado &&
    props.GetUsuarioLogado.usuarioLogado.consultorExterno
  ) {
    return undefined;
  }

  return isEmptyOrNull(value) ? 'Campo obrigatório' : undefined;
};

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i.test(value)
    ? 'Email inválido'
    : undefined;

export const people = clb => {
  const xhr = new XMLHttpRequest();

  xhr.responseType = 'json';
  xhr.open('POST', 'https://peoplegen.herokuapp.com/graphql');
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.onload = () => clb(xhr.response.data.person);
  const query = `{person {name email cpf}}`;

  xhr.send(JSON.stringify({ query }));
};

export const SelectMultiComponent = ({
  label,
  options,
  value,
  setValue,
  ...rest
}) => {
  return (
    <Select
      value={value}
      options={options}
      onChange={setValue}
      label={label}
      selectPromptMessage="Selecionar todos"
      isMulti
      {...rest}
    />
  );
};
