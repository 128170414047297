import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import './spinner.scss';

const enhance = compose(
  connect(state => ({
    ...state.loadingSpinner,
  }))
);

export const LoadingSpinner = enhance(({ show }) =>
  show ? <div id="cover-spin" /> : <div />
);
