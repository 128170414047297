import React from 'react';
import { NotificacaoCardBase } from '../NotificacaoCardBase';

export const TopicoHotleads = {
  topicoURI: '/hotleads',

  onClick: (notificacao, hook, history) => {
    hook.handleMarcarComoVisualizado([notificacao.pk]);
    history.push(`/editarCliente/${notificacao.relacionamentoId}`);
  },

  renderizar: notificacao => {
    const { visualizado } = notificacao;
    const { titulo, conteudo } = notificacao.payload || {};

    return (
      <NotificacaoCardBase
        titulo={titulo}
        descricao={conteudo}
        visualizado={visualizado}
      />
    );
  },
};
