import { Chip, Typography, useMediaQuery } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  mdiCalendarClock,
  mdiCameraOff,
  mdiCheckDecagram,
  mdiMapMarker,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Card } from '@tecsinapse/ui-kit';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { CheckInDialog } from '../CheckInDialog/CheckInDialog';
import {
  checkInDialog,
  checkInRealizadoCardStyles,
  styleCardSemContato,
} from '../styles';
import { GoogleMaps } from '../GoogleMaps/GoogleMaps';
import { getEnderecoAndCidade } from '../utils/enderecoUtils';
import { DD_MM_YYYY_HH_MM } from '../utils/constants';

const useCardStyles = makeStyles(checkInRealizadoCardStyles);

export const CheckInCard = ({
  checkIn,
  isCheckInMobile,
  usuarioLogado,
  labelAtividadeRealizada,
  labelButtonRealizarAtividade,
  handleRealizarAtividade,
}) => {
  const classes = useCardStyles();
  const isUsuarioAdminOuGerente =
    usuarioLogado?.administrador || usuarioLogado?.gerente;
  const {
    clienteVo,
    dataHora,
    imagem,
    clienteVo: { nome, cpfCnpj },
    atividadeVo,
    latitude,
    longitude,
  } = checkIn;

  const enderecoCompleto = getEnderecoAndCidade(clienteVo);

  const [isOpenDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);
  const [contentDialog, setContentDialog] = useState(null);
  const [contentDialogStyle, setContentDialogStyle] = useState(null);

  const handleOpenDialogMap = useCallback(() => {
    const mark = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

    setContentDialog(
      <GoogleMaps
        center={mark}
        markerPosition={mark}
        zoom={16}
        defaultZoom={6}
      />
    );
    setContentDialogStyle(checkInDialog.paddingZero);
    setOpenDialog(true);
  }, [latitude, longitude]);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const mobileStyle = isMobile
    ? checkInDialog.imageMobile
    : checkInDialog.image;
  const handleOpenDialogImage = useCallback(() => {
    setContentDialog(<img style={mobileStyle} src={imagem} alt="checkIn" />);
    setContentDialogStyle(checkInDialog.dialogContent);
    setOpenDialog(true);
  }, [mobileStyle, imagem]);

  const title = (() => {
    const titleName = moment(dataHora).format(DD_MM_YYYY_HH_MM);

    const titleComponents = (
      <div className={classes.titleComponents}>
        <div className={classes.divCalendar}>
          <SvgIcon className={classes.iconCalendar}>
            <Icon path={mdiCalendarClock} />
          </SvgIcon>
        </div>
        <Typography className={classes.title}>{titleName}</Typography>
        <div className={classes.iconComponents}>
          {!imagem && (
            <SvgIcon className={classes.iconSemImagem}>
              <Icon path={mdiCameraOff} />
            </SvgIcon>
          )}
          {!atividadeVo && !imagem && (
            <Typography className={classes.labelSemImagem}>
              Sem Imagem
            </Typography>
          )}
          {atividadeVo && (
            <Chip
              label={labelAtividadeRealizada}
              size="small"
              avatar={<Icon path={mdiCheckDecagram} color="white" size={0.5} />}
              classes={{
                label: clsx(classes.chipTitle, classes.chipDoneLabel),
              }}
              className={classes.chipDone}
            />
          )}
        </div>
      </div>
    );

    return { components: titleComponents };
  })();

  const showButtomRegistrarVisitaCheckInSemImagem =
    isCheckInMobile && !isUsuarioAdminOuGerente && !imagem;

  const subContent = (
    <Grid container classes={{ root: classes.gridContainer }}>
      <Grid item xs={2}>
        <Avatar
          variant="rounded"
          classes={{
            root: classes.avatarMapMarker,
          }}
          onClick={handleOpenDialogMap}
        >
          <Icon path={mdiMapMarker} size={1} />
        </Avatar>
      </Grid>
      <Grid className={classes.verticalAlignCenter} item xs={4}>
        <Typography className={classes.labelVerMapa}>VER MAPA</Typography>
      </Grid>
      {imagem && (
        <>
          <Grid item xs={2}>
            <Avatar
              variant="rounded"
              src={imagem}
              onClick={handleOpenDialogImage}
            />
          </Grid>
          <Grid className={classes.verticalAlignCenter} item xs={4}>
            <Typography className={classes.labelVerMapa}>VER IMAGEM</Typography>
          </Grid>
        </>
      )}
      {showButtomRegistrarVisitaCheckInSemImagem && (
        <Grid item xs={6} className={classes.gridItemButtonRegistrarSemImage}>
          {!atividadeVo && (
            <Button
              classes={{ root: classes.buttonRegistrarVisitaSmall }}
              customVariant="success"
              fullWidth
              onClick={() => handleRealizarAtividade(clienteVo)}
            >
              {labelButtonRealizarAtividade}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );

  const showButtonRegistrarVisita =
    isCheckInMobile && !isUsuarioAdminOuGerente && !atividadeVo && imagem;

  const actions = (
    <>
      {showButtonRegistrarVisita && (
        <div className={classes.buttonRegistrarVisita}>
          <Button
            customVariant="success"
            fullWidth
            onClick={() => handleRealizarAtividade(clienteVo)}
          >
            {labelButtonRealizarAtividade}
          </Button>
        </div>
      )}
    </>
  );

  const content = (
    <>
      <div className={classes.cpfcnpj}>{cpfCnpj}</div>
      <div>{enderecoCompleto}</div>
    </>
  );

  return (
    <>
      <Card
        key={checkIn.id}
        styleCard={styleCardSemContato}
        title={title}
        subtitle={nome.toLowerCase()}
        content={content}
        subContent={subContent}
        actions={actions}
        classes={classes}
      />
      <CheckInDialog
        isOpenDialog={isOpenDialog}
        handleClose={handleCloseDialog}
        content={contentDialog}
        contentDialogStyle={contentDialogStyle}
      />
    </>
  );
};
