import { graphql } from '@apollo/react-hoc';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import GetPaymentsQueryGql from './GetPaymentsQueryGql.graphql';

export const PaymentQuery = graphql(GetPaymentsQueryGql, {
  name: 'formaPagamento',
  options: ({ match }) => ({
    variables: {
      id: match.params.idProposta,
      nullId: isEmptyOrNull(match.params.idProposta),
    },
  }),
});
