import Dialog from '@material-ui/core/Dialog';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { IconButton } from '@tecsinapse/ui-kit';
import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import { SearchListing } from '../../../Home/Search/SearchListing';
import { useGlobalStyle } from '../../../app/globalStyle';
import { FullDisplayContainer } from '../../../utils/ui/styledComponents';

export function DialogAtividadesIndicadores({
  setShowActivitiesDialog,
  showActivitiesDialog,
  filtrosBusca,
  title,
  onClose = () => {},
  ...rest
}) {
  const classes = useGlobalStyle();
  const onClick = () => {
    onClose();

    return setShowActivitiesDialog(false);
  };

  return (
    <Dialog fullScreen onClose={onClick} open={showActivitiesDialog}>
      <AppBar position="static" variant="outlined">
        <Toolbar>
          <IconButton
            classes={{ root: classes.iconBackButton }}
            onClick={onClick}
            color="inherit"
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {title || 'Atividades'}
          </Typography>
        </Toolbar>
      </AppBar>
      <FullDisplayContainer>
        <SearchListing {...rest} {...filtrosBusca} />
      </FullDisplayContainer>
    </Dialog>
  );
}
