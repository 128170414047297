import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Button } from '@tecsinapse/ui-kit';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { defaultRed, globalStyle } from '../../app/globalStyle';
import { cardStyles } from '../../Home/ActivityCard/activityCardFunctions';
import IconUnidadeNegocio from '../../IconUnidadeNegocio';

const enhance = compose(withRouter);

const ClienteDuplicadoComponent = withStyles({
  overflow: globalStyle.overflow,
  form: globalStyle.form,
  textRed: {
    color: defaultRed,
  },
  ...cardStyles,
})(
  ({
    show,
    closeAlert,
    message,
    clientesDuplicadosQuery: {
      clientesDuplicados: { clientes = [] } = {},
    } = {},
    criarCliente,
    classes,
    podeCriarCliente,
    getDescricaoNormalizada,
    usuarioPodeAtenderCliente,
    getLinkToClientOrNull,
  }) => (
    <Dialog open={show} onClose={closeAlert} fullScreen>
      <DialogTitle id="simple-dialog-title">Cliente Duplicado</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <List>
          {(clientes || []).map(c => (
            <Fragment key={c.id}>
              <ListItem
                button
                component="a"
                target="_self"
                href={getLinkToClientOrNull(c)}
              >
                <ListItemText
                  primary={`#${c.id} - ${c.nome}`}
                  classes={{
                    primary: !usuarioPodeAtenderCliente(c) && classes.textRed,
                  }}
                />
              </ListItem>
              <Collapse in timeout="auto" unmountOnExit>
                <List
                  dense
                  component="div"
                  disablePadding
                  className={!usuarioPodeAtenderCliente(c) && classes.erroText}
                >
                  {(c.clienteUnidadesNegocio || []).map(cgs => {
                    let secondaryText = `${cgs.unidadeNegocio.descricao}`;

                    if (cgs.vendedor) {
                      secondaryText = secondaryText.concat(
                        `/${cgs.vendedor.nome}`
                      );
                    }

                    return (
                      <ListItem
                        button
                        className={classes.nested}
                        key={cgs.id}
                        component="a"
                        target="_self"
                        href={getLinkToClientOrNull(c)}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <IconUnidadeNegocio
                              descricaoNormalizado={
                                cgs.unidadeNegocio.segmento.descricaoNormalizado
                              }
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          classes={{
                            secondary:
                              !usuarioPodeAtenderCliente(c) && classes.textRed,
                          }}
                          secondary={secondaryText}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeAlert} color="primary" autoFocus>
          Fechar
        </Button>
        {podeCriarCliente && (
          <Button onClick={() => criarCliente()} color="primary" autoFocus>
            Criar mesmo assim
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
);

export const ClienteDuplicadoDialog = enhance(ClienteDuplicadoComponent);
