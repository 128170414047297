import { defaultGrey, globalStyle } from '../../../app/globalStyle';

export const indicadoresStyles = theme => ({
  ...globalStyle(theme),
  card: {
    margin: theme.spacing(1),
    padding: 0,
    marginBottom: 0,
  },
  fullList: {
    width: 'auto',
  },
  icon: {
    width: '20px',
    heigt: '20px',
    paddingLeft: '5px',
  },
  gridFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  opportunityTemperature: {
    height: '2px',
    width: '100%',
  },
  title: {
    color: props => (props && props.mainColor) || defaultGrey,
    fontWeight: '600',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  avatar: {
    fontWeight: '400',
    width: '32px',
    height: '32px',
  },
  avatarWithMargin: {
    margin: '0 12px',
    width: '24px',
    height: '24px',
    padding: '0px',
  },
  cardContent: { padding: '8px 16px' },
  cardContentFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cardContentFlexOportunidades: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  iconSegmento: {
    width: 24,
    fill: 'white',
  },
  chipMargin: {
    height: '20px',
    weight: '20px',
    fontWeight: 500,
    fontSize: '12px',
  },
  labelChipIndicadores: {
    fontWeight: 600,
  },
  indicadoresMessage: {
    fontWeight: 600,
  },
  action: {
    marginTop: 0,
  },
  subtitle: {
    fontWeight: 450,
    color: 'rgb(97, 97, 97)',
  },
  badgeFilter: {
    height: '20px',
    weight: '20px',
    fontWeight: 500,
    fontSize: '12px',
    color: '#FFF',
    marginRight: '4px',
  },
  centralizedcounter: {
    textAlign: 'center',
    paddingTop: '15px',
  },
  counter: {
    margin: '5px 0',
    fontWeight: '900',
    color: 'black',
    fontSize: '1.6rem',
  },
  titleBlack: {
    color: 'black',
    fontWeight: '600',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  cardAviso: {
    backgroundColor: '#f4f4f4',
    marginTop: theme.spacing(1),
  },
});

export const oportunidadeCircularStyles = theme => ({
  ...globalStyle(theme),
  chipMargin: {
    height: '20px',
    weight: '20px',
    fontWeight: 500,
    fontSize: '12px',
    margin: '4px',
    color: 'white',
  },
  chipMarginTop: {
    marginTop: 16,
    height: '20px',
    weight: '20px',
    fontWeight: 500,
    fontSize: '12px',
    margin: '4px',
    color: 'white',
  },
  typographyMargin: {
    marginTop: '8px',
  },
  typographyTinyyMargin: {
    marginTop: '2px',
  },
});

export const flexDivStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
};
