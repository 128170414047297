import { createNumberMask } from 'redux-form-input-masks';

export const currencyMask = onChange =>
  createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
    locale: 'pt-BR',
    onChange,
  });

export const percentageMask = onChange =>
  createNumberMask({
    suffix: '%',
    decimalPlaces: 2,
    locale: 'pt-BR',
    onChange,
  });
