import { Button, Icon, Typography } from '@material-ui/core';
import {
  ArrowDropDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { DatePicker } from '@tecsinapse/pickers';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const style = {
  fontWeight: 500,
};

const TextFieldComponent = ({
  showAtrasadas = true,
  selectedDay = '',
  onOpen = () => {},
}) => {
  const pickerText = !showAtrasadas
    ? moment(selectedDay).format('MMM, YYYY')
    : 'Atrasadas';

  return (
    <WeekDatePickerButton onClick={onOpen}>
      <ArrowIcon>
        <Arrow />
      </ArrowIcon>
      <DatePick variant="h6">{pickerText}</DatePick>
    </WeekDatePickerButton>
  );
};

export const WeekDatePicker = ({
  setSelectedDay,
  selectedDay,
  renderWrappedWeekDay,
  showAtrasadas,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  // eslint-disable-next-line
  const onOpen = () => setIsOpen(true);

  const textFieldComponent = useCallback(
    () => (
      <TextFieldComponent
        showAtrasadas={showAtrasadas}
        selectedDay={selectedDay}
        onOpen={onOpen}
      />
    ),
    [showAtrasadas, selectedDay, onOpen]
  );

  return (
    <DatePicker
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onChange={setSelectedDay}
      value={selectedDay}
      showTodayButton
      todayLabel="Hoje"
      cancelLabel="Cancelar"
      okLabel="OK"
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      format="MMM YYYY"
      renderDay={renderWrappedWeekDay}
      style={style}
      TextFieldComponent={textFieldComponent}
    />
  );
};

const WeekDatePickerButton = styled(Button)`
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  border-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 4px 3px 0;
`;

const DatePick = styled(Typography)`
  font-size: 0.7em;
  font-weight: bold;
  color: white;
`;

const ArrowIcon = styled(Icon)`
  width: auto;
  display: flex;
  align-items: center;
`;

const Arrow = styled(ArrowDropDown)`
  width: 17px;
  color: white;
`;
