import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  defaultGreen,
  defaultGreyDisabled,
  defaultRed,
} from '../../app/globalStyle';

const button = {
  padding: 0,
  color: 'white',
  height: 36,
};

const style = { marginTop: 10 };
const style1 = { width: '100%' };

export const ToggleSimNao = ({ label, handleChange, booleanValue }) => {
  const style2 = {
    ...button,
    borderRadius: '6px 0px 0px 6px',
    backgroundColor: booleanValue ? defaultGreen : defaultGreyDisabled,
  };
  const style3 = {
    ...button,
    borderRadius: '0px 6px 6px 0px',
    backgroundColor: booleanValue ? defaultGreyDisabled : defaultRed,
  };

  return (
    <Grid container>
      <Typography variant="caption" color="primary" style={style}>
        {label}
      </Typography>
      <ToggleButtonGroup
        exclusive
        value={booleanValue}
        onChange={handleChange}
        style={style1}
      >
        <ToggleButton
          value
          disabled={booleanValue}
          component={Grid}
          item
          xs={6}
          style={style2}
        >
          Sim
        </ToggleButton>
        <ToggleButton
          value={false}
          disabled={!booleanValue}
          component={Grid}
          item
          xs={6}
          style={style3}
        >
          Não
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};
