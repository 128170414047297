import { withRouter } from 'react-router';
import { isEmptyOrNull } from '@tecsinapse/es-utils';
import { compose, withHandlers, withProps } from 'recompose';
import { getAlertContext } from '../utils/alertContext';
import { RemoveHOC } from '../utils/crud/RemoveHOC';
import { StatusOportunidadeHOC } from '../utils/crud/StatusOportunidadeHOC';
import { getLoadingSpinnerContext } from '../utils/loadingSpinnerContext';
import { formatMoney } from '../utils/moneyUtils';
import {
  atualizarStatusModeloInteresseMutation,
  mutation,
} from './data/mutations/OpportunityMutations';
import {
  withAnoModeloAtivo,
  withTermoModelo,
  withTermoVersao,
} from './data/queries/crmTermosQuery';
import { TemModeloInteresseNovoEUsadoAtivosHOC } from './data/queries/temModeloInteresseNovoEUsadoAtivos';
import { OpportunityVehicles } from './OpportunityVehicles';

const setPermiteAlterarStatus = modelosInteresseMapByAgrupamento => {
  const modelosInteresseAgrupados = Object.values(
    modelosInteresseMapByAgrupamento
  );
  const unicoEmNegociacao =
    modelosInteresseAgrupados.filter(m => m.emNegociacao).length < 2;

  for (let i = 0; i < modelosInteresseAgrupados.length; i++) {
    const m = modelosInteresseAgrupados[i];

    // provavelmente há um bug nesse código porque o atributo
    // emNegociacaoFaturado aparentemente não é carregado em nenhuma consulta
    m.permiteAlterarStatus =
      m.emNegociacao && (unicoEmNegociacao || m.emNegociacaoFaturado);
  }
};

const enhance = compose(
  withTermoModelo,
  withTermoVersao,
  withAnoModeloAtivo,
  TemModeloInteresseNovoEUsadoAtivosHOC,
  withProps(({ crmModeloTermo: modeloLabel = 'Modelo' }) => ({
    modeloLabel,
  })),
  getLoadingSpinnerContext,
  getAlertContext,
  withRouter,
  RemoveHOC({ mutation, mutationName: 'removerModeloInteresse' }),
  StatusOportunidadeHOC({
    mutation: atualizarStatusModeloInteresseMutation,
    mutationName: 'atualizarStatusModeloInteresse',
  }),
  withProps(({ oportunidade }) => {
    const { modelosInteresse } = oportunidade;

    const modelosInteresseMapByAgrupamento = (modelosInteresse || []).reduce(
      (acc, modelo) => {
        const key = modelo.codigoAgrupamento;

        if (isEmptyOrNull(acc[key])) {
          acc[key] = modelo;
        }

        const precoTotal = acc[key].precoUnitario * acc[key].quantidade;

        acc[key].precoTotalFormatado = formatMoney(precoTotal);

        return acc;
      },
      {}
    );

    setPermiteAlterarStatus(modelosInteresseMapByAgrupamento);

    return {
      oportunidade,
      modelosInteresseMapByAgrupamento,
      modelosInteresse,
    };
  }),
  withHandlers({
    onEditClick: ({
      history,
      oportunidade: { id: idOportunidade },
    }) => vehicle => {
      history.push(
        `/oportunidade/${idOportunidade}/editarVeiculoInteresse/${vehicle.id}/`,
        {
          ...vehicle,
        }
      );
    },
    onPerformTestDrive: ({ history, oportunidade }) => modelo => {
      history.push(
        `/oportunidade/${oportunidade.id}/realizarTestDrive/${modelo.id}/`,
        {
          ...modelo,
        }
      );
    },
    goToAddModeloInteresse: ({
      history,
      oportunidade: { id: idOportunidade },
    }) => () => {
      history.push(`/oportunidade/${idOportunidade}/editarVeiculoInteresse/`);
    },
  })
);

export const OpportunityVehiclesContainer = enhance(OpportunityVehicles);
