import { graphql } from '@apollo/react-hoc';
import { compose, withProps } from 'recompose';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import TemperaturasQueryGql from './TemperaturasQueryGql.graphql';

const hoc = graphql(TemperaturasQueryGql, {
  name: 'temperaturasQuery',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const TemperaturasQueryHoc = compose(
  hoc,
  displayLoadingState({
    name: 'temperaturasQuery',
    onCacheLoadOnlyQueryName: 'temperaturas',
  })
);
export const withTemperaturasQuery = component =>
  compose(
    TemperaturasQueryHoc,
    withProps(({ temperaturasQuery: { temperaturas = [] } }) => ({
      temperaturas,
    }))
  )(component);
