import gql from 'graphql-tag';

export const BONUS_PERFORMANCE_QUERY = gql`
  query indicadorBonusPerformanceQuery(
    $dataInicio: DateTime
    $dataFim: DateTime
    $dealerPk: ID
    $marcaId: ID
    $unidadeNegocioId: ID
  ) {
    indicadorBonusPerformance(
      filter: {
        dataInicio: $dataInicio
        dataFim: $dataFim
        dealerPk: $dealerPk
        marcaId: $marcaId
        unidadeNegocioId: $unidadeNegocioId
      }
    ) {
      qtdFup48
      qtdTD
      qtdVendas
      qtdEmplacados
      idsVendas
      porcentagemTDRealizado
      porcentagemFup48Realizado
      qtdPorcentagemVendasxEmplacados
      qtdPontos
      total
      porcentagem
      qtdIndicadoresIncompletos
    }
  }
`;
