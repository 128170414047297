import React, { useMemo } from 'react';
import { Select } from '@tecsinapse/ui-kit';

import { flatten } from '@tecsinapse/es-utils/build';
import { createRender } from '../FormUtils';
import { useSelectInputStyle } from './SelectInputStyle';

const flattenChildren = childrenIn =>
  childrenIn
    ? flatten(childrenIn)
        .filter(c => !!c && !!c.props)
        .map(suggestion => ({
          value: suggestion.props.value,
          label: suggestion.props.children,
          disabled: suggestion.props.disabled || false,
        }))
    : [];

export const SelectComponent = ({
  input,
  children,
  tooltip,
  label,
  options = [],
  endAdornment,
  classes,
  disabled,
  placeholder,
  required,
  error = false,
  menuPlacement = 'bottom',
  ...rest
}) => {
  const { value, onChange, name } = input;

  const map = useMemo(
    () =>
      options !== undefined && options && options.length !== 0
        ? options
        : flattenChildren(children),
    [options, children]
  );

  return (
    <Select
      className={classes?.fakeFormControl}
      {...rest}
      value={value || null}
      error={error}
      options={map}
      onChange={input2 => onChange(input2)}
      disabled={disabled}
      label={required ? `${label} *` : label}
      placeholder={placeholder || label || ''}
      name={name}
      minWidth=""
      variant="mobile"
    />
  );
};

export const RenderSelectField = createRender(SelectComponent, {
  renderLabel: false,
});

export const SelectComponentStyled = props => {
  const classes = useSelectInputStyle();

  return <SelectComponent classes={classes} {...props} />;
};
