import { compose, withProps } from 'recompose';
import { graphql, withApollo } from '@apollo/react-hoc';
import { getAlertContext } from '../alertContext';
import { ErrorOffline } from '../../../core/offline/exception/ErrorOffline';
import { getOfflineEntityIdContext } from '../getOfflineEntityIdContext';
import { mutationsErrorsHandler } from '../mutationsErrorsHandler';

export const graphqlOfflineMutation = (
  gql,
  {
    name,
    mutationName,
    idName = null,
    entityName = null,
    savedEntityName = null,
    description = null,
    customErrorsHandler = null,
    showSucessAlert = true,
    ...options
  }
) => component =>
  compose(
    getAlertContext,
    getOfflineEntityIdContext,
    withApollo,
    graphql(gql, { ...options, name: `__offlineMutation${name}` }),
    withProps(({ showAlert, client, addEntityId, ...props }) => ({
      [name]: async ({ variables, chip, ...rest }) => {
        let descriptionFilled = null;

        if (description != null) {
          descriptionFilled = await description({
            variables,
            apolloClient: client,
          });
        } else {
          console.warn(
            `Descrição não fornecida para mutation: ${mutationName}`
          );
        }

        if (navigator.onLine) {
          return props[`__offlineMutation${name}`]({
            variables,
            ...rest,
          })
            .then(params => {
              showSucessAlert &&
                showAlert &&
                showAlert({
                  message: 'Ação realizada com sucesso.',
                  variant: 'success',
                  chip: true,
                });

              return Promise.resolve(params);
            })
            .catch(error => {
              const errorHandler = mutationsErrorsHandler({
                showAlert,
                mutationName,
                variables,
                description: descriptionFilled,
                chip,
              });

              const defaultErrorHandler = () => errorHandler(error);

              if (customErrorsHandler !== undefined && !customErrorsHandler) {
                defaultErrorHandler();
              }

              // FIXME negociacao-aceita criar error de mutation
              // eslint-disable-next-line
              return Promise.reject({
                ...error,
                defaultErrorHandler: customErrorsHandler && defaultErrorHandler,
              });
            });
        }

        props[`__offlineMutation${name}`]({
          variables,
          ...rest,
        }).catch(() => {
          // eslint-disable-next-line
          console.log('Falha ao executar, motivo: offline');
        });

        if (descriptionFilled !== null) {
          if (entityName != null && idName != null) {
            addEntityId({
              id: parseInt(variables[entityName][idName], 10),
              mutationName,
              entityName: savedEntityName,
              idName,
            });
          }
        }

        const errorOffline = new ErrorOffline(
          mutationName,
          entityName,
          idName,
          savedEntityName
        );

        const mutationsErrorsHandlerDefaultOffline = () =>
          mutationsErrorsHandler({
            mutationName,
            entityName,
            idName,
            showAlert,
            name,
            variables,
            description: descriptionFilled,
            chip,
            customErrorsHandler,
          })({ graphQLErrors: errorOffline });

        if (customErrorsHandler !== undefined && !customErrorsHandler) {
          mutationsErrorsHandlerDefaultOffline();
          throw errorOffline;
        }
        // eslint-disable-next-line
        return Promise.reject({
          graphQLErrors: errorOffline,
          defaultErrorHandler:
            customErrorsHandler && mutationsErrorsHandlerDefaultOffline,
        });
      },
    }))
  )(component);
