import {
  calcularQuantidadeVeiculosComModelo,
  contarFormasPagamento,
  verificarPrecoUnitarioInformado,
} from '../../../Atividade/atividadeFunctions';
import { TemperaturaNegociacao } from '../../../../core/Enums';

export const useRealizarAtividadePosAcaoInfos = ({ activityBranch }) => {
  const { idOrigem, temperatura } = activityBranch;
  const numVeiculosComModelos = calcularQuantidadeVeiculosComModelo(
    activityBranch
  );
  const possuiVeiculoComPrecoUnitarioNaoInformado = verificarPrecoUnitarioInformado(
    activityBranch
  );
  const numFormasPagamento = contarFormasPagamento(activityBranch);
  const showPagamentoPendente =
    numFormasPagamento < 1 &&
    temperatura === TemperaturaNegociacao.SUPER_QUENTE.name;
  const showVeiculosInteresseSemModelo = numVeiculosComModelos < 1;

  return {
    idOrigem,
    possuiVeiculoComPrecoUnitarioNaoInformado,
    numFormasPagamento,
    showPagamentoPendente,
    showVeiculosInteresseSemModelo,
  };
};
