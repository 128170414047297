import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import {
  AccountBox,
  CheckCircle,
  Edit,
  Phone,
  Timeline as TimelineIcon,
  Update,
} from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '@mdi/react';
import { mdiSteering } from '@mdi/js';
import React from 'react';
import { generateOpportunityId } from '../activityCardFunctions';
import {
  allowEditarOportunidade,
  allowInformarContato,
  allowReagendarAtividade,
  allowTestDrive,
  allowVerTimeline,
} from '../../../Atividade/atividadeFunctions';

const style1 = {
  padding: '8px 15px',
  justifyContent: 'space-between',
  display: 'flex',
};

export const CardActions = ({
  activity,
  offlinePendente,
  classes,
  handleClickCallClient,
  handleClickInformCall,
  handleClickEditOpportunity,
  handleClickEditClient,
  handleClickRescheduleActivity,
  handleClickSeeHistory,
  handleClickTestDrive,
  usuarioLogado,
}) => (
  <div style={style1}>
    {allowInformarContato({ activity, usuarioLogado }) && !offlinePendente && (
      <IconButton
        id={generateOpportunityId(activity)}
        size="small"
        className={clsx(classes.buttonCardThird)}
        classes={{
          root: classes.avatarWithMargin,
        }}
        onClick={handleClickInformCall}
      >
        <CheckCircle />
      </IconButton>
    )}

    <IconButton
      size="small"
      className={clsx(classes.buttonCardThird)}
      classes={{
        root: classes.avatarWithMargin,
      }}
      onClick={handleClickCallClient}
    >
      <Phone />
    </IconButton>

    {allowEditarOportunidade(activity) && !offlinePendente && (
      <IconButton
        size="small"
        className={clsx(classes.buttonCardThird)}
        classes={{
          root: classes.avatarWithMargin,
        }}
        onClick={handleClickEditOpportunity}
      >
        <Edit />
      </IconButton>
    )}

    <IconButton
      size="small"
      className={clsx(classes.buttonCardThird)}
      classes={{
        root: classes.avatarWithMargin,
      }}
      onClick={handleClickEditClient}
    >
      <AccountBox />
    </IconButton>

    {allowReagendarAtividade(activity) && !offlinePendente && (
      <IconButton
        size="small"
        className={clsx(classes.buttonCardThird)}
        classes={{
          root: classes.avatarWithMargin,
        }}
        onClick={handleClickRescheduleActivity}
      >
        <Update />
      </IconButton>
    )}

    {allowVerTimeline(activity) && (
      <IconButton
        size="small"
        className={clsx(classes.buttonCardThird)}
        classes={{
          root: classes.avatarWithMargin,
        }}
        onClick={handleClickSeeHistory}
      >
        <TimelineIcon />
      </IconButton>
    )}

    {allowTestDrive(activity) && (
      <IconButton
        size="small"
        className={clsx(classes.buttonCardThird)}
        classes={{
          root: classes.avatarWithMargin,
        }}
        onClick={handleClickTestDrive}
      >
        <SvgIcon>
          <Icon path={mdiSteering} size={1} />
        </SvgIcon>
      </IconButton>
    )}
  </div>
);
