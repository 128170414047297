import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';
import { genericQueryHoc } from '../../utils/crud/genericQueryHoc';

const queryName = 'vendedoresAtivosIndicadores';

const query = `
  query ${queryName}Query {
    ${queryName} {
      id
      nome
      dealersGrupo {
        id
      }
    }
  }
`;

export const vendedoresAtivosQueryGql = gql`
  ${query}
`;

export const [
  ,
  VendedoresAtivosQueryHoc,
  VendedoresAtivosQueryName,
  VendedoresAtivosQueryDataName,
] = genericQueryHoc({
  name: queryName,
  gql: vendedoresAtivosQueryGql,
  options: null,
  skip: ({ usuarioVendedor }) => usuarioVendedor !== null,
});

export const withVendedoresAtivos = component =>
  compose(
    VendedoresAtivosQueryHoc,
    withProps(input => {
      const {
        [VendedoresAtivosQueryName]: {
          [VendedoresAtivosQueryDataName]: vendedoresAtivos = [],
        } = {},
      } = input;

      return { vendedoresAtivos };
    })
  )(component);
