import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit';

import React from 'react';
import { withHandlers, withState } from 'recompose';
import { compose } from 'redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';

function EditSettingsTextDialogForm({
  handleClose,
  handleSubmit,
  onChange,
  open = false,
  setValue,
  title = '',
  text = '',
  sucessButton,
  cancelButon,
  value,
  fullScreen,
  input: { label, name } = {},
}) {
  function closeDialog() {
    setValue('');
    handleClose();
  }

  function submitDialog() {
    closeDialog();
    handleSubmit({ [name]: value });
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          value={value}
          name={name}
          label={label}
          onChange={onChange}
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {cancelButon.label}
        </Button>
        <Button onClick={submitDialog} color="primary">
          {sucessButton.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const EditSettingsTextDialog = compose(
  withState(
    'value',
    'setValue',
    ({ input: { initialValue } = {} }) => initialValue || ''
  ),
  withHandlers({
    onChange: ({ setValue }) => e => setValue(e.target.value),
  }),
  withMobileDialog()
)(EditSettingsTextDialogForm);
