import React from 'react';
import { AtualizaModeloInativoContext } from './AtualizaModeloInativoContext';

export function AtualizaModeloInativoConsumer(props) {
  const { children, ...rest } = props;

  return (
    <AtualizaModeloInativoContext.Consumer>
      {({
        openAtualizaModeloInativoDialog,
        setOpenAtualizaModeloInativoDialog,
        veiculos,
        setAtualizaModeloInativoVeiculos,
        atualizaModeloInativoEAbreDialog,
      }) => (
        <>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              openAtualizaModeloInativoDialog,
              setOpenAtualizaModeloInativoDialog,
              veiculos,
              setAtualizaModeloInativoVeiculos,
              atualizaModeloInativoEAbreDialog,
              ...rest,
            })
          )}
        </>
      )}
    </AtualizaModeloInativoContext.Consumer>
  );
}
