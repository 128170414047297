import clsx from 'clsx';
import moment from 'moment';
import { IconButton } from '@tecsinapse/ui-kit';
import React from 'react';
import { BotaoAtividadesAtrasadas } from '../BotaoAtividadesAtrasadas/BotaoAtividadesAtrasadas';
import { Day } from '../Day/Day';
import { WeekDatePicker } from '../WeekDatePicker/WeekDatePicker';
import { useWeekdaysStyles } from './weekdaysComponentHooks';
import { updateAppTitle } from '../../../utils/uiUtils';

export const WeekdaysComponent = React.memo(
  ({
    classes,
    selectedDayMoment,
    setSelectedDayAndSendToAnalytics,
    daysOfWeek,
    qtdAtrasadas,
    showAtrasadas,
    diasComAtrasadas = [],
    setShowAtrasadasAndSendToAnalytics,
    selectedWeekDay,
    selectedDay,
    setSelectedDay,
  }) => {
    const classes2 = useWeekdaysStyles();

    const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
      const dateClone = moment(date);
      const selectedDateClone = moment(selectedDate);

      const start = moment(selectedDateClone).startOf('week');
      const end = moment(selectedDateClone).endOf('week');

      const dayIsBetween = moment().range(start, end).contains(dateClone);
      const isFirstDay = dateClone.isSame(start, 'day');
      const isLastDay = dateClone.isSame(end, 'day');

      const wrapperClassName = clsx({
        [classes2.highlight]: dayIsBetween,
        [classes2.firstHighlight]: isFirstDay,
        [classes2.endHighlight]: isLastDay,
      });

      const dayClassName = clsx(classes2.day, {
        [classes2.nonCurrentMonthDay]: !dayInCurrentMonth,
        [classes2.highlightNonCurrentMonthDay]:
          !dayInCurrentMonth && dayIsBetween,
      });

      return (
        <div className={wrapperClassName}>
          <IconButton className={dayClassName} href="">
            <span> {dateClone.format('D')} </span>
          </IconButton>
        </div>
      );
    };

    return (
      <>
        {updateAppTitle(
          !showAtrasadas ? selectedDayMoment.format('MMMM, YYYY') : 'Atrasadas',
          false,
          () => {
            return (
              <div className={classes.weekDatePickerContainer}>
                <WeekDatePicker
                  setSelectedDay={setSelectedDay}
                  selectedDay={selectedDay}
                  renderWrappedWeekDay={renderWrappedWeekDay}
                  showAtrasadas={showAtrasadas}
                />
              </div>
            );
          }
        )}
        <div className={classes.root}>
          <BotaoAtividadesAtrasadas
            dayLabel="Atrasadas"
            qtdAtrasadas={qtdAtrasadas}
            showAtrasadas={showAtrasadas}
            setShowAtrasadasAndSendToAnalytics={
              setShowAtrasadasAndSendToAnalytics
            }
            classes={classes}
          />
          {daysOfWeek.map((day, index) => (
            <Day
              key={`Weekday-${day.name}`}
              today={selectedDayMoment}
              showAtrasadas={showAtrasadas}
              day={day}
              index={index}
              selectedWeekDay={selectedWeekDay}
              setSelectedDayAndSendToAnalytics={
                setSelectedDayAndSendToAnalytics
              }
              classes={classes}
              showUnderDot={diasComAtrasadas.includes(
                day.moment.startOf('day').format()
              )}
            />
          ))}
        </div>
      </>
    );
  }
);
