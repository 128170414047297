import { isNotEmptyOrNull } from '@tecsinapse/es-utils/build';

export const getEnderecoCompletoString = ({ endereco }) =>
  `${getEnderecoString({ endereco })}, ${getCidadeEstadoString({ endereco })}.`;

export const getEnderecoString = ({ endereco }) => {
  const { logradouro, numero, bairro, complemento, cep } = endereco;

  let enderecoString = '';

  if (isNotEmptyOrNull(logradouro)) {
    enderecoString += `${logradouro}`;
  }

  if (isNotEmptyOrNull(numero)) {
    enderecoString = `${enderecoString}, ${numero}`;
  }

  if (isNotEmptyOrNull(bairro)) {
    enderecoString = `${enderecoString}, ${bairro}`;
  }

  if (isNotEmptyOrNull(complemento)) {
    enderecoString = `${enderecoString}, ${complemento}`;
  }

  if (isNotEmptyOrNull(cep)) {
    enderecoString = `${enderecoString}, ${cep}`;
  }

  return enderecoString;
};

export const getCidadeEstadoString = ({ endereco = {} }) => {
  const { cidade, estado } = endereco;

  let enderecoString = '';

  if (isNotEmptyOrNull(cidade)) {
    enderecoString += `${cidade}`;
  }

  if (isNotEmptyOrNull(estado)) {
    enderecoString = `${enderecoString} - ${estado.sigla}`;
  }

  return enderecoString;
};

export const getEnderecoAndCidade = cliente => {
  if (!cliente?.endereco?.logradouro) {
    return null;
  }

  const endereco = getEnderecoString(cliente);
  const cidade = getCidadeEstadoString(cliente);

  return `${endereco}, ${cidade}`;
};
