import React, { useEffect } from 'react';
import PushUtils from './pushUtils';

/* eslint no-console: 0 */
export default ({ subscribeUrl, vapidPubKey }) => {
  useEffect(() => {
    console.log('REGISTER PUSH');

    if (Notification.permission === 'default') {
      Notification.requestPermission()
        .then(status => {
          if (status === 'granted') {
            console.log('Permission granted.');
            PushUtils.subscribeToServer(subscribeUrl, vapidPubKey);
          } else {
            console.log('Permission not granted.');
          }
        })
        .catch(err => {
          console.error(`Permission not granted due to error: ${err}`);
        });
    } else if (
      Notification.permission === 'granted' &&
      [null, undefined].includes(
        JSON.parse(localStorage.getItem('registeredForPush'))
      )
    ) {
      PushUtils.subscribeToServer(subscribeUrl, vapidPubKey);
    }
  }, [subscribeUrl, vapidPubKey]);

  return <></>;
};
