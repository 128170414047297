import pick from 'lodash.pick';
import { compose, withProps } from 'recompose';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { OpportunityVehiclesPerformTestDrive } from './OpportunityVehiclesPerformTestDrive';
import { PerformTestDriveMutation } from '../data/mutations/OpportunityMutations';
import { TemperaturasQueryHoc } from '../../RealizarAtividade/data/queries/TemperaturasQuery';
import { TiposDeContatoQueryHoc } from '../../RealizarAtividade/data/queries/TiposDeContatoQuery';
import { reduxFormAutoFocus } from '../../utils/reduxFormAutoFocus';
import { validateRequired } from '../../utils/FormUtils';
import { getLocalDateString, getLocalTimeString } from '../../utils/dateUtils';
import { withMotivosTestDriveNaoRealizado } from '../../RealizarAtividade/data/queries/MotivosTestDriveNaoRealizadoQuery';
import { eventConfirmPerformTestDrive } from '../../utils/GAUtils';

export const OpportunityVehiclesPerformTestDriveContainer = compose(
  withRouter,
  TemperaturasQueryHoc,
  withProps(() => {
    return {
      tipoAtividade: 'TEST_DRIVE',
    };
  }),
  TiposDeContatoQueryHoc,
  withMotivosTestDriveNaoRealizado(),
  PerformTestDriveMutation,
  withProps(({ location: { state: modelo } }) => {
    return {
      initialValues: {
        data: moment(),
        hora: moment(),
      },
    };
  }),
  reduxFormAutoFocus({
    // a unique name for the form
    form: 'testDriveForm',
    validate: validateRequired([
      'data',
      'hora',
      'tipoContato',
      'temperatura',
      'observacao',
      'motivo',
      'justificativa',
    ]),
    onSubmit: (values, dispatch, { history, match, performTestDrive }) => {
      const submitValues = pick(
        {
          ...values,
          idOportunidade: match.params.idOportunidade,
          idModelo: match.params.id,
        },
        [
          'idModelo',
          'testDriveRealizado',
          'data',
          'hora',
          'tipoContato',
          'temperatura',
          'observacao',
          'motivo',
          'justificativa',
        ]
      );

      submitValues.data = getLocalDateString(submitValues.data);
      submitValues.hora = getLocalTimeString(submitValues.hora);
      eventConfirmPerformTestDrive();

      return performTestDrive({
        variables: {
          informarTestDrive: { ...submitValues },
        },
      }).then(() => history.goBack());
    },
  })
)(OpportunityVehiclesPerformTestDrive);
