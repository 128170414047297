import { Grid, MenuItem, withStyles } from '@material-ui/core/index';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import { Field } from 'redux-form';
import { Estados } from '../../../core/Enums';
import { globalStyle } from '../../app/globalStyle';
import { getUrlPromisseOfCep, isValidCep } from '../cepRestUtils';
import { NumberField, RenderInput } from '../FormUtils';
import { RenderSelectField } from '../select/RenderSelectField';

const EnderecoComponent = ({ classes, handleCepChange }) => (
  <>
    <Grid item xs={12}>
      <Typography variant="h6" color="inherit" noWrap className={classes.title}>
        Endereço
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Field
        fullWidth
        name="cep"
        label="CEP"
        textMask="cep"
        component={RenderInput}
        onChange={event => handleCepChange({ event })}
      />
    </Grid>

    <Grid item xs={12}>
      <Field
        fullWidth
        name="logradouro"
        label="Logradouro"
        component={RenderInput}
      />
    </Grid>

    <Grid item xs={6}>
      <Field
        fullWidth
        name="numero"
        label="Nº"
        onChange={() => {}}
        component={NumberField}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name="complemento"
        label="Complemento"
        component={RenderInput}
      />
    </Grid>
    <Grid item xs={12}>
      <Field fullWidth name="bairro" label="Bairro" component={RenderInput} />
    </Grid>

    <Grid item xs={6}>
      <Field
        name="estado.sigla"
        label="UF"
        component={RenderSelectField}
        fullWidth
        menuPlacement="top"
      >
        {Estados.enumValues.map(({ sigla }) => (
          <MenuItem value={sigla} key={sigla}>
            {sigla}
          </MenuItem>
        ))}
      </Field>
    </Grid>

    <Grid item xs={6}>
      <Field
        fullWidth
        name="cidade"
        label="Municipio"
        component={RenderInput}
      />
    </Grid>
  </>
);

export const Endereco = compose(
  withStyles(globalStyle),
  withHandlers({
    handleCepChange: ({ change, name }) => ({ event }) => {
      const cepFormatado = event.target.value.replace('-', '');

      if (!isValidCep(cepFormatado)) {
        return;
      }
      getUrlPromisseOfCep(cepFormatado)
        .then(json => {
          if (json) {
            const { cidade, uf, logradouro, bairro, tipo } = json;

            if (cidade) {
              change(`${name}.cidade`, cidade);
            }

            if (uf) {
              change(`${name}.estado.sigla`, uf);
            }

            if (logradouro) {
              change(
                `${name}.logradouro`,
                tipo ? `${tipo} ${logradouro}` : logradouro
              );
            }

            if (bairro) {
              change(`${name}.bairro`, bairro);
            }
          }
        })
        .catch(error => {});
    },
  })
)(EnderecoComponent);
