import React from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { SearchTabContainer } from './SearchTabContainer';

export const SearchTabDialog = ({
  setShowSearchDialog,
  showSearchDialog,
  activityOnClick,
  onBottomSheetClick,
  ...props
}) => (
  <Dialog
    fullScreen
    onClose={() => setShowSearchDialog(false)}
    open={showSearchDialog}
  >
    <SearchTabContainer
      activityOnClick={activityOnClick}
      setShowSearchDialog={setShowSearchDialog}
      onBottomSheetClick={onBottomSheetClick}
      {...props}
    />
  </Dialog>
);
