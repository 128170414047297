import gql from 'graphql-tag';

export const USUARIO_LOGADO_QUERY_GQL = gql`
  query GetUsuarioLogado {
    usuarioLogado {
      id
      nome
      consultorExterno
      vendedorCrm
      gerente
      administrador
      montadora
      roteirizacaoV2
      dealersVendedorLogado {
        id
        nome
        roteirizacao
        possuiAcessoDivisaoRegional
      }
      marcas {
        id
        nome
      }
      ultimoAcesso
      setores
    }
  }
`;
