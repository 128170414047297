import { useQuery } from '@apollo/react-hooks';
import { USUARIO_LOGADO_QUERY_GQL } from './USUARIO_LOGADO_QUERY_GQL';
import { useUsuarioLogadoConsultorExterno } from './useUsuarioLogadoConsultorExterno';

export const useUsuarioLogado = () => {
  const { data } = useQuery(USUARIO_LOGADO_QUERY_GQL, {
    fetchPolicy: 'cache-first',
  });
  let usuarioLogado = data && data.usuarioLogado;
  const consultorExterno = useUsuarioLogadoConsultorExterno();

  usuarioLogado = { ...usuarioLogado, consultorExterno };

  return {
    usuarioLogado,
    marcas: usuarioLogado && usuarioLogado.marcas,
    usuarioGerente: usuarioLogado && usuarioLogado.gerente,
    usuarioAdmin: usuarioLogado && usuarioLogado.administrador,
    usuarioVendedor:
      usuarioLogado &&
      usuarioLogado.vendedorCrm &&
      !(usuarioLogado.gerente || usuarioLogado.administrador),
    usuarioConsultorExterno: usuarioLogado && usuarioLogado.consultorExterno,
    exibeIndicadores: usuarioLogado && !usuarioLogado.consultorExterno,
    usuarioInterno:
      usuarioLogado &&
      usuarioLogado.email &&
      usuarioLogado.email.includes('@tecsinapse.com.br'),
  };
};
