import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../../utils/graphql/graphqlOfflineMutation';
import removerVeiculoFrotaGql from './RemoverVeiculoFrotaMutationGql.graphql';

export const RemoverVeiculoFrotaMutation = graphqlOfflineMutation(
  removerVeiculoFrotaGql,
  {
    name: 'removerVeiculoFrota',
    mutationName: 'removerVeiculoFrotaGql',
    description: async ({ variables: { id }, apolloClient }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `Veiculo:${id}`,
          fragment: gql`
            fragment VeiculoInativacao on Veiculo {
              id
              modelo {
                id
                nome
              }
              cliente {
                id
                nome
              }
            }
          `,
        });
        const {
          modelo: { nome },
          cliente: { nome: nomeCliente },
        } = data;

        return `Remoção de veículo da frota, modelo ${nome} do cliente ${nomeCliente}`;
      } catch (e) {
        return `Remoção de veículo da frota.`;
      }
    },
  }
);
