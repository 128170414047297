import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../utils/graphql/graphqlOfflineMutation';
import { UltimaRoteirizacaoByConsultorQueryGql } from './ultimaRoterizacaoByConsultorQuery';

const createReRoteirizacaoMutationGql = gql`
  mutation reRoteirizar(
    $latitudeOrigem: String!
    $longitudeOrigem: String!
    $diasCongeladosIds: [String]!
    $horarioDaChegadaAoDestinoDeHoje: String!
  ) {
    reRoteirizar(
      latitudeOrigem: $latitudeOrigem
      longitudeOrigem: $longitudeOrigem
      diasCongeladosIds: $diasCongeladosIds
      horarioDaChegadaAoDestinoDeHoje: $horarioDaChegadaAoDestinoDeHoje
    ) {
      id
      consultorExterno {
        id
      }
      roteirizacaoRotas {
        id
        data
        mapLink
        roteirizacaoClientes {
          id
          data
          obrigatorio
          endereco
          clienteVo {
            id
            nome
            cpfCnpj
            podeRealizarAtividadeFichaVisita
            ultimaAtividadeFichaVisitaAbertaPk
            consultorExternoPk
            endereco {
              id
              logradouro
              numero
              cidade
              estado {
                id
                nome
                sigla
              }
            }
            clienteVisitadoNoPeriodoDaUltimaRoteirizacao
          }
          distanciaKm
          tempoDeslocamento
          tempoDuracao
        }
      }
    }
  }
`;

export const createReRoteirizacaoMutation = graphqlOfflineMutation(
  createReRoteirizacaoMutationGql,
  {
    name: 'criarReRoteirizacao',
    mutationName: 'createReRoteirizacaoMutationGql',
    options: {
      refetchQueries: [{ query: UltimaRoteirizacaoByConsultorQueryGql }],
    },
  }
);
