import React, { useState } from 'react';
import { postInformCallActions } from 'components/Client/clienteBottomSheet/postInformCallActions';
import { getFirstFromSingleElementArrayNotNull } from '@tecsinapse/es-utils';
import { ClientCreateOptionEnum } from 'core/Enums';
import { InformarContatoDialog } from 'components/utils/InformarContatoDialog';
import { CheckInOrdenacao } from '../CheckInList/enum/CheckInOrdenacaoEnum';
import { useVendedoresAtivosCRM } from '../CheckInList/data/useVendedoresAtivosCRM';
import { CheckInList } from '../CheckInList/CheckInList';

export const CheckInListCRM = ({
  checkInSort = CheckInOrdenacao.MAIS_RECENTES.name,
  checkInApenasComImagem = false,
  checkInApenasVisitaRegistrada = false,
  checkInConsultorExterno = null,
  checkInDealer = null,
  history = null,
  setCheckInFiltro = null,
  criarFichaVisitaAtividade = null,
  isCheckInMobile = false,
  useWindowInfiniteScroll = false,
  informarContatoCliente = null,
  createModal = null,
  atualizaModeloInativoEAbreDialog = null,
  ...others
}) => {
  const { vendedoresAtivos } = useVendedoresAtivosCRM();
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [atividadesSelecionadas, setAtividadesSelecionadas] = useState([]);
  const [showInformCallModal, setShowInformCallModal] = useState(false);

  const urlRetorno = 'checkIn';

  const handleRealizarAtividade = cliente => {
    const unidadeNegocioUnica = getFirstFromSingleElementArrayNotNull(
      cliente.unidadesNegocioAtendidos || []
    );

    if (cliente && (cliente.unidadesNegocioAtendidos || []).length > 1) {
      createModal({
        id: cliente.id,
        clientCreateOption: ClientCreateOptionEnum.INFORM_CALL,
        message: null,
        fullWidth: true,
      });
    } else {
      informarContatoCliente({
        variables: {
          idCliente: cliente.id,
          idUnidadeNegocio: unidadeNegocioUnica && unidadeNegocioUnica.id,
          idTipoContato: null,
        },
      }).then(
        postInformCallActions({
          history,
          createModal,
          atualizaModeloInativoEAbreDialog,
          urlRetorno,
          setAtividades: atividades => {
            setAtividadesSelecionadas(atividades);
            setClienteSelecionado(cliente);
          },
          setShowInformCallModal,
        })
      );
    }
  };

  return (
    <>
      {clienteSelecionado && (
        <InformarContatoDialog
          client={clienteSelecionado}
          atividades={atividadesSelecionadas}
          open={showInformCallModal}
          urlRetorno={urlRetorno}
          handleClose={() => {
            setShowInformCallModal(false);
          }}
        />
      )}
      <CheckInList
        checkInSort={checkInSort}
        checkInApenasComImagem={checkInApenasComImagem}
        checkInApenasVisitaRegistrada={checkInApenasVisitaRegistrada}
        checkInConsultorExterno={checkInConsultorExterno}
        checkInDealer={checkInDealer}
        history={history}
        setCheckInFiltro={setCheckInFiltro}
        criarFichaVisitaAtividade={criarFichaVisitaAtividade}
        isCheckInMobile={isCheckInMobile}
        useWindowInfiniteScroll={useWindowInfiniteScroll}
        modulo="CRM"
        vendedores={vendedoresAtivos}
        labelFiltroAtividadeRealizada="Apenas sem contato registrado"
        labelFiltroVendedores="Vendedores"
        labelAtividadeRealizada="Contato Realizado"
        labelButtonRealizarAtividade="Informar Contato"
        handleRealizarAtividade={handleRealizarAtividade}
      />
    </>
  );
};
