import { Tab, Tabs, Toolbar } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { globalStyle } from '../../../app/globalStyle';

const useClientTabStyleTab = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: '48px',
  },
  child: {
    flex: 1,
  },
  tabCliente: {
    paddingLeft: '5px',
    minWidth: '48px',
  },
  frota: {
    minWidth: '48px',
  },
  contacts: {
    minWidth: '48px',
    marginRight: '8px',
  },
  ...globalStyle(theme),
}));

export const ClienteTabs = ({
  selectedTab,
  setSelectedTab,
  renderFrota = true,
  renderContacts,
}) => {
  const classes = useClientTabStyleTab();

  return (
    <Toolbar className={classes.root}>
      <Tabs
        centered
        className={classes.child}
        value={selectedTab}
        onChange={(e, v) => setSelectedTab(v)}
      >
        <Tab label="Dados" className={classes.tabCliente} />
        {renderFrota && <Tab label="Frota" className={classes.frota} />}
        {renderContacts && (
          <Tab label="Contatos" className={classes.contacts} />
        )}
      </Tabs>
    </Toolbar>
  );
};
