import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';

const createRegistrarCheckInMutationGql = gql`
  mutation registrarCheckIn(
    $idCliente: ID!
    $registrouVisita: Boolean!
    $longitude: String!
    $latitude: String!
    $file: FileInput
    $atualizaLocalizacao: Boolean!
    $modulo: String!
  ) {
    registrarCheckIn(
      idCliente: $idCliente
      registrouVisita: $registrouVisita
      longitude: $longitude
      latitude: $latitude
      file: $file
      atualizaLocalizacao: $atualizaLocalizacao
      modulo: $modulo
    ) {
      id
      clienteVo {
        id
        nome
        telefone
      }
    }
  }
`;

export const createRegistrarCheckInMutation = graphqlOfflineMutation(
  createRegistrarCheckInMutationGql,
  {
    name: 'registrarCheckIn',
    mutationName: 'createRegistrarCheckInMutationGql',
  }
);
