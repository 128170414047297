import reagendarAtividadeMutationGql from '../ReagendarAtividade/reagendarAtividadeMutationGql.graphql';
import editarVeiculoFrotaMutationGql from '../Client/clienteFrota/data/mutations/EditarVeiculoFrotaMutationGql.graphql';
import PaymentMutationGql from '../proposal/payment/editor/PaymentMutationGql.graphql';
import encerrarFichaMutationGql from '../RealizarAtividade/data/mutations/encerrarFichaMutationGql.graphql';
import ProposalApprovalMutationGql from '../opportunity/data/mutations/ProposalApprovalMutationGql.graphql';
import ProposalGenerateMutationGql from '../opportunity/data/mutations/ProposalGenerateMutationGql.graphql';
import reeditarPropostaMutationGql from '../opportunity/data/mutations/reeditarPropostaMutationGql.graphql';
import removerModeloInteresseGql from '../opportunity/data/mutations/removerModeloInteresseGql.graphql';
import atualizarStatusModeloInteresseGql from '../opportunity/data/mutations/atualizarStatusModeloInteresseGql.graphql';
import inativarContatoMutationGql from '../Client/clienteContacts/data/mutations/inativarContatoMutationGql.graphql';
import salvarContatoMutationGql from '../Client/clienteContacts/data/mutations/salvarContatoMutationGql.graphql';
import editOpportunityVehicleMutationGql from '../opportunity/editor/editOpportunityVehicleMutationGql.graphql';
import addCommentTimelineMutationGql from '../timeline/comment/addCommentTimelineMutationGql.graphql';
import removerVeiculoFrotaGql from '../Client/clienteFrota/data/mutations/RemoverVeiculoFrotaMutationGql.graphql';
import createOportunityMutationGql from '../opportunity/create/createOportunityMutationGql.graphql';
import { editClientMutationGql } from '../Client/client/data/mutations/EditClientMutation';
import {
  criarERealizarAtividadeMutationGql,
  realizarAtividadeMutationGql,
} from '../RealizarAtividade/data/mutations/RealizarAtividadeMutation';
import {
  criarENegocioPerdidoMutationGql,
  negocioPerdidoMutationGql,
} from '../RealizarAtividade/data/mutations/NegocioPerdidoMutation';
import {
  criarEInformarResultadoProspeccaoMutationGql,
  informarResultadoProspeccaoMutationGql,
} from '../RealizarAtividade/data/mutations/InformarResultadoProspeccaoMutation';
import { negociacaoAceitaMutationGql } from '../RealizarAtividade/data/mutations/NegociacaoAceitaMutation';

export const mutationsList = {
  reagendarAtividadeMutationGql,
  informarResultadoProspeccaoMutationGql,
  criarEInformarResultadoProspeccaoMutationGql,
  criarENegocioPerdidoMutationGql,
  encerrarFichaMutationGql,
  negocioPerdidoMutationGql,
  removerModeloInteresseGql,
  atualizarStatusModeloInteresseGql,
  ProposalGenerateMutationGql,
  ProposalApprovalMutationGql,
  reeditarPropostaMutationGql,
  inativarContatoMutationGql,
  salvarContatoMutationGql,
  createOportunityMutationGql,
  editOpportunityVehicleMutationGql,
  addCommentTimelineMutationGql,
  removerVeiculoFrotaGql,
  editClientMutationGql,
  editarVeiculoFrotaMutationGql,
  PaymentMutationGql,
  realizarAtividadeMutationGql,
  criarERealizarAtividadeMutationGql,
  negociacaoAceitaMutationGql,
};
