import {
  flatMap,
  getAnyFromArray,
  getFirstElementOfArray,
  isNotUndefOrNull,
} from '@tecsinapse/es-utils';

export function atualizaMapsModelosVeiculoVarianteModeloInativo(
  modeloInteresse,
  modeloMapInput,
  modeloVersaoMapInput,
  versaoVarianteMapInput
) {
  const modeloMap = modeloMapInput;
  const modeloVersaoMap = modeloVersaoMapInput;
  const versaoVarianteMap = versaoVarianteMapInput;

  const modeloInteresseVar = modeloInteresse && modeloInteresse.modeloInteresse;
  const modeloModeloInteresse =
    modeloInteresseVar && modeloInteresse.modeloInteresse.modelo;

  if (
    modeloInteresseVar &&
    modeloModeloInteresse &&
    modeloModeloInteresse.id &&
    !isNotUndefOrNull(modeloMap[modeloModeloInteresse.id])
  ) {
    const { id } = modeloModeloInteresse;

    modeloMap[id] = modeloModeloInteresse;

    const versaoModeloInteresse = modeloInteresse.modeloInteresse.versao;

    if (
      modeloInteresseVar &&
      versaoModeloInteresse &&
      versaoModeloInteresse.id
    ) {
      const versoeVelhas = isNotUndefOrNull(modeloVersaoMap[id])
        ? modeloVersaoMap[id]
        : [];

      modeloVersaoMap[id] = [...versoeVelhas, versaoModeloInteresse];

      const varianteModeloInteresse = modeloInteresse.modeloInteresse.variante;

      if (
        modeloInteresseVar &&
        varianteModeloInteresse &&
        varianteModeloInteresse.id
      ) {
        const variantesVelhos = isNotUndefOrNull(
          versaoVarianteMap[versaoModeloInteresse.id]
        )
          ? versaoVarianteMap[versaoModeloInteresse.id]
          : [];

        versaoVarianteMap[versaoModeloInteresse.id] = [
          ...variantesVelhos,
          varianteModeloInteresse,
        ];
      }
    }
  }

  return {
    modeloMap,
    modeloVersaoMap,
    versaoVarianteMap,
  };
}

export const getItemTabelaPreco = ({
  itens,
  idVariante,
  idModelo,
  idVersao,
}) => {
  const itemTabela = (itens || []).filter(
    obj =>
      obj.variante &&
      obj.variante.id === idVariante &&
      obj.modelo &&
      obj.modelo.id === idModelo &&
      obj.versao &&
      obj.versao.id === idVersao
  );

  return getAnyFromArray(itemTabela);
};

export const getFromVariante = ({ idVariante, versaoVarianteMap }) => {
  if (versaoVarianteMap === undefined || !versaoVarianteMap) {
    return null;
  }

  const values = Object.values(versaoVarianteMap);

  const variante = flatMap(
    variantes => variantes.filter(v => v.id === idVariante),
    values
  );

  return getAnyFromArray(variante);
};

export const getAnoFabricacaoEAnoModeloSeparados = anoFabricacaoAnoModelo => {
  if (isNotUndefOrNull(anoFabricacaoAnoModelo)) {
    return anoFabricacaoAnoModelo.split('/', 2);
  }

  return null;
};

export const filtrarVersaoPorAnoFabricacaoEAnoModelo = (
  v,
  anoFabricacaoAnoModelo,
  usaTabelaPreco = false
) => {
  if (!isNotUndefOrNull(anoFabricacaoAnoModelo)) {
    return true;
  }

  const [anoFabricacao, anoModelo] = getAnoFabricacaoEAnoModeloSeparados(
    anoFabricacaoAnoModelo
  );

  return (
    (v.anoFabricacaoInicial === null ||
      v.anoFabricacaoInicial >= anoFabricacao) &&
    (v.anoModeloInicial === null || v.anoModeloInicial >= anoModelo) &&
    (v.anoFabricacaoFinal === null || v.anoFabricacaoFinal <= anoFabricacao) &&
    (v.anoModeloFinal === null || v.anoModeloFinal <= anoModelo) &&
    (v.ativo || usaTabelaPreco)
  );
};

export const selecionarPrimeiraVersao = (
  idModelo,
  modeloVersaoMap,
  anoFabricacaoAnoModelo,
  change
) => {
  if (!modeloVersaoMap || !modeloVersaoMap[idModelo]) {
    return null;
  }

  const versoes = Object.values(modeloVersaoMap[idModelo]).filter(v =>
    filtrarVersaoPorAnoFabricacaoEAnoModelo(v, anoFabricacaoAnoModelo)
  );

  versoes.sort((a, b) => a.nome.localeCompare(b.nome));

  const idVersao = versoes[0] ? versoes[0].id : null;

  change('idVersao', idVersao);

  return idVersao;
};

export const getAnoFabricacaoAnoModelo = (anosModeloMap, idModelo) => {
  const anosModelo = anosModeloMap
    .filter(a => parseInt(a.modeloId, 10) === parseInt(idModelo, 10))
    .map(({ anoModelo }) => anoModelo);

  return anosModelo.length ? getFirstElementOfArray(anosModelo) : null;
};

export const selecionarPrimeiroAnoModelo = (
  idModelo,
  anosModeloMap,
  change
) => {
  const anoFabricacaoAnoModelo = getAnoFabricacaoAnoModelo(
    anosModeloMap,
    idModelo
  );

  if (!isNotUndefOrNull(anoFabricacaoAnoModelo)) {
    return null;
  }

  change('anoFabricacaoAnoModelo', anoFabricacaoAnoModelo);
  const [, modelo] = getAnoFabricacaoEAnoModeloSeparados(
    anoFabricacaoAnoModelo
  );

  change('anoModelo', parseInt(modelo, 10));

  return anoFabricacaoAnoModelo;
};

export const selecionarPrimeiraVariante = (
  idVersao,
  versaoVarianteMap = [],
  change,
  anoFabricacaoAnoModelo,
  priceTable,
  idModelo
) => {
  let anoModelo = null;

  if (anoFabricacaoAnoModelo) {
    [, anoModelo] = getAnoFabricacaoEAnoModeloSeparados(anoFabricacaoAnoModelo);
  }

  versaoVarianteMap &&
    versaoVarianteMap[idVersao] &&
    versaoVarianteMap[idVersao].sort((a, b) => a.nome.localeCompare(b.nome));

  const variantesFiltrados =
    versaoVarianteMap &&
    versaoVarianteMap[idVersao] &&
    (versaoVarianteMap[idVersao].filter(({ ano }) => {
      if (!isNotUndefOrNull(anoModelo)) {
        return true;
      }

      return ano === parseInt(anoModelo, 10);
    }) ||
      []);

  const idVariante =
    variantesFiltrados && variantesFiltrados[0] && variantesFiltrados[0].id;

  change('idVariante', idVariante);

  setPrecoUnitario({
    change,
    priceTable,
    idModelo,
    idVersao,
    versaoVarianteMap,
    idVariante,
  });

  return idVariante;
};

export const setPrecoUnitario = ({
  change,
  priceTable,
  idModelo,
  idVersao,
  versaoVarianteMap,
  idVariante,
}) => {
  const { tabelaPreco } = priceTable || {};
  const itemTabela = getItemTabelaPreco({
    itens: tabelaPreco && tabelaPreco.itens,
    idVariante,
    idModelo,
    idVersao,
  });

  if (itemTabela && itemTabela.variante) {
    change('descricao', itemTabela.variante.descricao);
    change('precoUnitario', itemTabela.preco);
  } else {
    const variante = getFromVariante({
      idVariante,
      versaoVarianteMap,
    });

    if (variante) {
      change('descricao', variante.descricao);
    } else {
      change('descricao', null);
      change('precoUnitario', null);
    }
  }
};
