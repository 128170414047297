import { isStringNotBlank } from '@tecsinapse/es-utils/build';
import { isNotUndefOrNull } from '@tecsinapse/es-utils/build/object';
import { getRawAdressString } from '../utils/RouteDialog';
import { TipoAtividade } from '../../core/Enums';

export const allowInformarContato = ({ activity, usuarioLogado }) => {
  const usuarioTemAcesso =
    ((usuarioLogado.gerente || usuarioLogado.administrador) &&
      usuarioLogado.vendedorCrm) ||
    !(usuarioLogado.gerente || usuarioLogado.administrador);

  return (
    usuarioTemAcesso &&
    (activity || {}).tipo !== 'FATURAMENTO' &&
    !(activity || {}).finalizado &&
    ((activity || {}).tipoOrigem === 'OPORTUNIDADE' ||
      (activity || {}).tipoOrigem === 'FICHA_VISITA' ||
      (activity || {}).tipoOrigem === 'PROSPECCAO')
  );
};

export const shouldShowSegments = segments => {
  const segmentIds = segments.map(({ id }) => id);

  return new Set(segmentIds).size > 1;
};
export const allowRoute = activity =>
  activity &&
  activity.cliente &&
  isStringNotBlank(getRawAdressString(activity.cliente)) > 0;

export const allowTestDrive = activity =>
  activity?.tipoOrigem === 'OPORTUNIDADE' &&
  (activity || {}).tipo !== 'FATURAMENTO';

export const allowReagendarAtividade = activity =>
  !(activity || {}).finalizado &&
  ((activity || {}).tipoOrigem === 'OPORTUNIDADE' ||
    (activity || {}).tipoOrigem === 'FICHA_VISITA');

export const allowVerTimeline = activity =>
  activity &&
  ['OPORTUNIDADE', 'FICHA_VISITA', 'PROSPECCAO'].indexOf(activity.tipoOrigem) >
    -1;

export const allowEditarOportunidade = activity =>
  activity &&
  activity.tipoOrigem &&
  activity.tipoOrigem === 'OPORTUNIDADE' &&
  (activity || {}).tipo !== 'FATURAMENTO';

export const realizarAtividadeVisita = activity =>
  !(
    activity &&
    activity.tipoOrigem &&
    (activity.tipoOrigem === 'OPORTUNIDADE' ||
      activity.tipoOrigem === 'PROSPECCAO')
  );

export const textRealizarAtividade = activity =>
  realizarAtividadeVisita(activity) ? 'Realizar Atividade' : 'Informar Contato';

export const allowInformarNegocioPerdido = (activity, criarAtividade) => {
  if (criarAtividade) {
    return true;
  }

  const { statusOportunidade = '' } = activity || {};

  return (
    statusOportunidade === 'NOVO' || statusOportunidade === 'EM_NEGOCIACAO'
  );
};

export const allowInformarNegociacaoAceita = (
  activity,
  criarAtividade,
  crmIsNegociacaoAceitaAtivo,
  usuarioLogado
) => {
  if (criarAtividade) {
    return false;
  }

  const {
    statusOportunidade = '',
    tipo,
    naoPermiteNegocioFechadoOuNegocioPerdido,
    oportunidade: { propostaNecessitaAprovacaoGerente, propostas } = {},
    proposta: propostaInput = null,
  } = activity || {};

  const proposta = isNotUndefOrNull(propostaInput)
    ? propostaInput
    : propostas && propostas[0];

  return (
    (proposta != null || (proposta === undefined && !navigator.onLine)) &&
    ((!propostaNecessitaAprovacaoGerente && usuarioLogado.vendedorCrm) ||
      (propostaNecessitaAprovacaoGerente &&
        (proposta?.status?.id === 'APROVADA' ||
          usuarioLogado.gerente ||
          usuarioLogado.administrador))) &&
    crmIsNegociacaoAceitaAtivo &&
    naoPermiteNegocioFechadoOuNegocioPerdido &&
    tipo !== TipoAtividade.VENDA_PERDIDA.name &&
    (statusOportunidade === 'NOVO' || statusOportunidade === 'EM_NEGOCIACAO')
  );
};

export const allowOnlyInformarNegocioPerdido = activity => {
  const { tipo = '' } = activity || {};

  return tipo === 'VENDA_PERDIDA';
};

export function modelosInativos(activity) {
  if (!activity?.oportunidade?.modelosInteresse) {
    return [];
  }

  const modelosInteresseInativos =
    activity?.oportunidade?.modelosInteresse &&
    activity?.oportunidade?.modelosInteresse.filter(
      mi => mi.modelo && mi.modeloInteresseInativo
    );

  return modelosInteresseInativos;
}

export const allowActionsModeloAtivo = (atividade, callback) => {
  const temModelos = atividade?.oportunidade?.modelosInteresse;

  if (
    !(atividade?.tipoOrigem === 'OPORTUNIDADE') ||
    (!navigator.onLine && !temModelos)
  ) {
    return true;
  }

  const modelosInteresseInativos = modelosInativos(atividade);

  const podeRealizarAcao = temModelos && modelosInteresseInativos.length === 0;

  if (callback && !podeRealizarAcao) {
    callback(modelosInteresseInativos, atividade.idOrigem);
  }

  return podeRealizarAcao;
};

export const contarFormasPagamento = atividade => {
  if (!atividade || !atividade.oportunidade) {
    return 0;
  }
  const pagamentos = (atividade?.oportunidade?.propostas ?? []).filter(
    proposta => proposta?.formasPagamento?.length > 0
  );

  return new Set(pagamentos).size;
};

export const verificarPrecoUnitarioInformado = atividade => {
  if (!atividade || !atividade.oportunidade) {
    return 0;
  }

  const possuiVeiculoInconsiste = (
    atividade?.oportunidade?.modelosInteresse ?? []
  ).find(veiculo => !isNotUndefOrNull(veiculo.precoUnitario));

  return !!possuiVeiculoInconsiste;
};

export const calcularQuantidadeVeiculosComModelo = atividade => {
  if (!atividade || !atividade.oportunidade) {
    return 0;
  }
  const codigosAgrupamento = (atividade?.oportunidade?.modelosInteresse ?? [])
    .filter(
      veiculo =>
        isNotUndefOrNull(veiculo.modelo) &&
        isNotUndefOrNull(veiculo.modelo.id) &&
        isNotUndefOrNull(veiculo.versao) &&
        isNotUndefOrNull(veiculo.versao.id)
    )
    .map(veiculo => veiculo.codigoAgrupamento);

  return new Set(codigosAgrupamento).size;
};

export function realizaTesteDrive(args = {}) {
  const {
    actionActivity = {},
    showAlert = {},
    history = {},
    selecaoModelosEAbreDialog = {},
  } = args;

  if (args === {}) {
    return;
  }

  const quantidadeVeiculosComModelo = calcularQuantidadeVeiculosComModelo(
    actionActivity
  );
  const nenhumVeiculoPossuiModeloInformado = quantidadeVeiculosComModelo <= 0;
  const apenasUmVeiculoPossuiModeloInformado =
    quantidadeVeiculosComModelo === 1;
  const maisDeUmVeiculoPossuiModeloInformado = quantidadeVeiculosComModelo > 1;

  // Tanto no Caso A quanto no Caso B,
  // caso nenhum veículo de interesse tenha o modelo preenchido,
  // devemos  facilitar para o usuário e abrir um tela solicitando que ele informe o modelo de interesse e,
  // após usuário selecionar o modelo de interesse, a gente configura esse modelo como sendo o modelo do primeiro
  // veiculo de interesse em negociação e já abre a tela para informar o test-drive.
  if (nenhumVeiculoPossuiModeloInformado) {
    showAlert({
      message:
        'Para informar o test drive é necessário preencher o modelo de um dos veículos que está sendo negociado.',
      title: 'Atenção',
      variant: 'error',
    });
    history.push(`/oportunidade/${actionActivity?.idOrigem}`);
  }

  // A) Se só existe 1 veículo de interesse em negociação, já assumir que este é o veículo onde o test-drive foi realizado
  else if (apenasUmVeiculoPossuiModeloInformado) {
    history.push(
      `/oportunidade/${actionActivity?.idOrigem}/realizarTestDrive/${actionActivity?.oportunidade?.modelosInteresse[0].id}`
    );
  }

  // B) Se existir + de 1 veículo de interesse em negociação,
  // abrir tela (não precisa ser popup, pode ser igual a tela de agendamento
  // pedindo para o usuário selecionar qual veículo de interesse o TD foi realizado
  else if (maisDeUmVeiculoPossuiModeloInformado) {
    const modelos = actionActivity.oportunidade.modelosInteresse;
    const codigosUnicos = new Set(modelos.map(m => m.codigoAgrupamento));
    const veiculos = [];

    for (let i = 0; i < modelos.length; i++) {
      if (!codigosUnicos.size) {
        break;
      }
      const veiculo = modelos[i];

      if (codigosUnicos.has(veiculo.codigoAgrupamento)) {
        veiculos.push(veiculo);
        codigosUnicos.delete(veiculo.codigoAgrupamento);
      }
    }
    selecaoModelosEAbreDialog({
      veiculos,
      oportunidadeId: actionActivity.oportunidade.id,
    });
  }
}

export const isPerformCheckinBlocked = usuario => {
  return usuario?.administrador || usuario?.gerente;
};
