import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { Field } from 'redux-form';
import { isEmptyOrNull, isNotEmptyOrNull } from '@tecsinapse/es-utils/build';
import { Add } from '@material-ui/icons';
import { IconButton } from '@tecsinapse/ui-kit';
import { isNotUndefOrNull } from '@tecsinapse/es-utils';
import {
  NumberField,
  RenderInput,
  SubmitButton,
} from '../../../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../../utils/uiUtils';
import { useGlobalStyle } from '../../../app/globalStyle';
import { RenderSelectField } from '../../../utils/select/RenderSelectField';
import { eventSaveFleet } from '../../../utils/GAUtils';
import { useUsuarioLogado } from '../../../../hooks/useUsuarioLogado';

export const ClienteFrotaEditor = ({
  handleSubmit,
  initialValues,
  history,
  chassi,
  placa,
  quantidade,
  idMarca,
  idSubSegmento,
  idSegmento,
  onChassiPlacaChange,
  data: { marcasFrota = [], segmentosVendaAtivos = [], idSegmentoUnico },
  getModelosByMarcaSegmentoSubSegmento,
  modelosFrota,
  subSegmentos,
  change,
  addQuantidade,
  formName = null,
  crmModeloTermo: modeloLabel = 'Modelo',
  ...rest
}) => {
  const classes = useGlobalStyle();
  const {
    usuarioLogado: { dealersVendedorLogado = [] },
  } = useUsuarioLogado();
  const initialModel =
    modelosFrota || [].find(m => m.id === initialValues.idModelo);
  const showInitialModel =
    isEmptyOrNull(initialModel) && initialValues?.modelo?.nome;
  const min = initialValues.quantidade || 1;

  return (
    <form onSubmit={handleSubmit} className={classes.form} name={formName}>
      <RenderAppLogo />
      {updateAppTitle('Editar Item da Frota')}
      <ShowBackButton history={history} />
      <div className={classes.flexColumnCenter}>
        <Field
          disabled={initialValues.id}
          id="quantidade"
          name="quantidade"
          label="Quantidade"
          min={min}
          component={NumberField}
          fullWidth
        />
        {initialValues.id && navigator.onLine && (
          <IconButton size="small" color="primary" onClick={addQuantidade}>
            <Add />
          </IconButton>
        )}
      </div>
      <Grid container alignItems="flex-end">
        <Grid item xs={6}>
          <Field
            id="anoModelo"
            name="anoModelo"
            label="Ano Modelo"
            textMask="year"
            component={RenderInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            id="anoFabricacao"
            name="anoFabricacao"
            textMask="year"
            label="Ano de Fabricação"
            component={RenderInput}
            fullWidth
          />
        </Grid>
        {!idSegmentoUnico && (
          <Grid item xs={12}>
            <Field
              name="idSegmento"
              label="Segmento"
              component={RenderSelectField}
              required
              fullWidth
            >
              {segmentosVendaAtivos.map(({ id, descricao }) => (
                <MenuItem value={id} key={id}>
                  {descricao}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        )}
        {!idSegmentoUnico && (
          <Grid item xs={12}>
            <Field
              name="idSubSegmento"
              label="Sub Segmento"
              component={RenderSelectField}
              fullWidth
            >
              {(subSegmentos || [])
                .filter(c => (c.segmento || {}).id === idSegmento)
                .map(({ id, descricao }) => (
                  <MenuItem value={id} key={id}>
                    {descricao}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
        )}
        <Grid item xs={12}>
          <Field
            name="idMarca"
            label="Marca"
            component={RenderSelectField}
            menuPlacement="top"
            required
            fullWidth
          >
            {marcasFrota.map(({ id, nome }) => (
              <MenuItem value={id} key={id}>
                {nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="idModelo"
            label={modeloLabel}
            component={RenderSelectField}
            menuPlacement="top"
            onChange={(e, value) => {
              const modelo = modelosFrota.find(m => m.id === value) || {};
              const idSubSegmentoSelecionado = (modelo.subSegmento || {}).id;
              const idSegmentoSelecionado = (modelo.segmento || {}).id;
              const idMarcaSelecionada = (modelo.marca || {}).id;

              change('idMarca', idMarcaSelecionada);

              if (isNotUndefOrNull(idSegmentoSelecionado)) {
                change('idSegmento', idSegmentoSelecionado);
              }

              if (isNotUndefOrNull(idSubSegmentoSelecionado)) {
                change('idSubSegmento', idSubSegmentoSelecionado);
              }
            }}
            fullWidth
          >
            <MenuItem value={null} key="selecione">
              Selecione...
            </MenuItem>

            {getModelosByMarcaSegmentoSubSegmento({
              idMarca,
              idSubSegmento,
              idSegmento,
            }).map(({ id, nome }) => (
              <MenuItem value={id} key={id}>
                {nome}
              </MenuItem>
            ))}
            {showInitialModel && (
              <MenuItem
                value={initialValues.idModelo}
                key={initialValues.idModelo}
                disabled
              >
                {initialValues.modelo.nome}
              </MenuItem>
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          {isEmptyOrNull(initialValues.idDealer) && (
            <Field
              name="idDealer"
              label="Dealer"
              component={RenderSelectField}
              disabled={isNotEmptyOrNull(initialValues.id)}
              fullWidth
            >
              {dealersVendedorLogado.map(({ id, nome }) => (
                <MenuItem value={id} key={id}>
                  {nome}
                </MenuItem>
              ))}
            </Field>
          )}
        </Grid>
        <SubmitButton
          onClick={eventSaveFleet()}
          fullWidth
          label="Salvar"
          {...rest}
        />
      </Grid>
    </form>
  );
};
