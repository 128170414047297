import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

const defaultState = {
  selectedDay: moment(),
  showAtrasadas: false,
  lastSelectedDays: [],
  origensOportunidade: [],
  prospeccoesSemContato: false,
};

export const setSelectedDayTab = createAction('SET_SELECTED_DAY');
export const setShowAtrasadasTab = createAction('SET_SHOW_ATRASADAS');
export const resetWeekDays = createAction('RESET_WEEKDAYS');
export const setAgendaFiltroBottomSheet = createAction('SET_AGENDA_FILTRO');

export const weekDaysReducer = handleActions(
  {
    [setSelectedDayTab]: (state, { payload }) => ({
      ...state,
      selectedDay: payload,
    }),
    [setShowAtrasadasTab]: (state, { payload }) => ({
      ...state,
      showAtrasadas: payload,
    }),
    [resetWeekDays]: state => ({
      ...state,
      ...defaultState,
    }),
    [setAgendaFiltroBottomSheet]: (
      state,
      { payload: { origensOportunidade = [], prospeccoesSemContato = false } }
    ) => ({
      ...state,
      origensOportunidade,
      prospeccoesSemContato,
    }),
  },
  defaultState
);
