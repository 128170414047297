import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

export const defaultGreen = '#4caf50';
export const defaultRed = '#e6433f';
export const defaultOrange = '#ff9d0d';

export const defaultGrey = grey[700];
export const defaultGreyLight = grey[500];
export const defaultGreyLight2 = grey[600];
export const defaultGreyDisabled = grey[400];
export const defaultGreyLight3 = grey[300];
export const defaultGreyLight4 = grey[50];
export const defaultGreyTextChip = '#747472';
export const defaultGreyBackgroundChip = '#e9e7e6';

export const cardGreen = 'rgba(109, 157, 100, .2)';
export const cardOrange = 'rgba(252, 166, 64, .2)';
export const cardGreenDark = '#628d5a';
export const cardRedDark = '#ed4242';
export const disableBackgroundColor = '#ffd08a52';

export const defaultGold = '#eb9c00';
export const defaultBronze = '#b2783d';

export const globalBackgroundColor = '#f5f5f5';
export const buttonCardThirdColor = 'rgba(108, 104, 98, .85 )';

export const globalStyle = theme => ({
  sticky: {
    position: 'sticky',
    top: 0,
  },
  form: {
    margin: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(1),
    padding: 0,
    marginBottom: 0,
  },
  circularSize: {
    width: '85px',
  },
  circularTinySize: {
    width: '75px',
  },
  cardBasis: {
    padding: 0,
    marginBottom: 0,
  },
  flexColumnCenter: {
    display: 'flex',
    direction: 'column',
    alignItems: 'center',
    padding: 8,
  },
  cardContent: { padding: '8px 16px' },
  overflow: { overflow: 'visible' },
  // Margens
  marginBottom20: {
    marginBottom: theme.spacing(2),
  },
  marginBottom10: {
    marginBottom: theme.spacing(1),
  },
  marginBottom15: {
    marginBottom: '15px',
  },
  marginRight10: {
    marginRight: theme.spacing(1),
  },
  marginTop10: {
    marginTop: theme.spacing(1),
  },
  marginTop15: {
    marginTop: '15px',
  },
  marginZero: {
    margin: '0',
    border: 'none',
  },
  margin5: {
    margin: '5px',
  },
  marginTopZero: {
    marginTop: '0',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  marginTop5: {
    marginTop: '5px',
  },

  // paddings
  padding20: {
    padding: '20px',
  },
  paddingZero: {
    // Teste antes de colocar - cards não obedecem essa classe
    padding: '0',
  },
  paddingRightZero: {
    paddingRight: '0',
  },
  paddingBottomZero: {
    paddingBottom: 0,
  },
  paddingCard: {
    paddingBottom: 0,
    paddingTop: '8px',
  },
  paddingTopZero: {
    paddingTop: 0,
  },
  paddingTopBottomZero: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonCardPrimary: {
    color: defaultGrey,
  },
  buttonCardSecundary: {
    color: defaultOrange,
  },
  buttonCardThird: {
    color: buttonCardThirdColor,
    position: 'initial',
  },

  buttonColorSuccess: {
    backgroundColor: defaultGreen,
    color: 'white',
    '&$disabled': {
      backgroundColor: defaultGrey,
    },
  },
  buttonColorSecundary: {
    backgroundColor: defaultOrange,
    color: 'white',
    '&$disabled': {
      backgroundColor: defaultGrey,
    },
  },
  buttonColorThird: {
    backgroundColor: defaultRed,
    color: 'white',
    '&$disabled': {
      backgroundColor: defaultGrey,
    },
  },
  buttonColorPrimaryWithMarginTop: {
    backgroundColor: defaultGreen,
    color: 'white',
    marginTop: '5px',
    '&$disabled': {
      backgroundColor: defaultGrey,
    },
  },
  snackbarFragment: {
    display: 'flex',
    alignContent: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
  title: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  disabled: {
    backgroundColor: defaultGreyDisabled,
  },
  iconBackButton: {
    marginLeft: '-12px',
    marginRight: '10px',
  },
  gridFlex: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const useGlobalStyle = makeStyles(globalStyle);
