import { connect } from 'react-redux';
import { branch, compose, renderComponent, withHandlers } from 'recompose';
import { isNotEmptyOrNull } from '@tecsinapse/es-utils/build';
import { NotAllowed } from '../../../NotAllowed';
import { Funcionalidades } from '../../../core/Enums';

export function RoleException(message) {
  this.message = message;
  this.name = 'RoleException';
}

export const FuncionalidadeHOC = ({ requiredRoles = null } = {}) =>
  compose(
    connect(state => ({
      kc: state.keycloak,
    })),
    withHandlers({
      userHasRole: ({ kc }) => role => {
        if (isNotEmptyOrNull(role) && role instanceof Funcionalidades) {
          return kc.hasResourceRole(role.value, kc.clientId);
        }
        throw new RoleException('Invalid Role');
      },
    }),
    branch(({ userHasRole }) => {
      if (requiredRoles) {
        return userHasRole.reduce((v, role) => v && userHasRole(role));
      }

      return false;
    }, renderComponent(NotAllowed))
  );
