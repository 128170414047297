import { compose, getContext, withProps } from 'recompose';
import { connect } from 'react-redux';
import { informarContatoClienteMutation } from 'components/Client/clienteBottomSheet/data/informarContatoClienteMutation';
import { withAtualizaModeloInativo } from 'components/AtualizaModeloInativo/withAtualizaModeloInativo';
import PropTypes from 'prop-types';
import { createFichaVisitaAtividadeMutation } from '../../opportunity/create/ClientCreateOptionsMutations';
import { setCheckinFiltroBottomSheet } from '../../../reducers/checkInReducer';
import { CheckInOrdenacao } from './CheckInList/enum/CheckInOrdenacaoEnum';
import { CheckInListCRM } from './CheckInListCRM/CheckInListCRM';
import { CheckInListCE } from './CheckInListCE/CheckInListCE';

const mapDispatchToProps = dispatch => ({
  setCheckInFiltro: (
    checkInSort,
    checkInApenasComImagem,
    checkInApenasVisitaRegistrada,
    checkInConsultorExterno,
    checkInDealer
  ) => {
    dispatch(
      setCheckinFiltroBottomSheet({
        checkInSort,
        checkInApenasComImagem,
        checkInApenasVisitaRegistrada,
        checkInConsultorExterno,
        checkInDealer,
      })
    );
  },
});

const mapStateToProps = ({
  checkIn: {
    checkInSort,
    checkInApenasComImagem,
    checkInApenasVisitaRegistrada,
    checkInConsultorExterno,
    checkInDealer,
  },
}) => ({
  checkInSort,
  checkInApenasComImagem,
  checkInApenasVisitaRegistrada,
  checkInConsultorExterno,
  checkInDealer,
});

const isCheckInMobile = true;
const isCrmModule = process.env.REACT_APP_MODULOS === 'CRM';

export const CheckInListContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      checkInSort = CheckInOrdenacao.MAIS_RECENTES.name,
      checkInApenasComImagem = false,
      checkInApenasVisitaRegistrada = false,
      checkInConsultorExterno = null,
      checkInDealer = null,
      useWindowInfiniteScroll = false,
    }) => ({
      checkInSort,
      checkInApenasComImagem,
      checkInApenasVisitaRegistrada,
      checkInConsultorExterno,
      checkInDealer,
      isCheckInMobile,
      useWindowInfiniteScroll,
    })
  ),
  createFichaVisitaAtividadeMutation,
  informarContatoClienteMutation,
  getContext({
    createModal: PropTypes.func,
  }),
  withAtualizaModeloInativo
)(isCrmModule ? CheckInListCRM : CheckInListCE);
