import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { MenuItem } from '@material-ui/core';
import { defaultGrey } from '../app/globalStyle';

export const periodos = [
  {
    nome: 'Mês Atual',
    value: 'month',
  },
  {
    nome: 'Mês anterior',
    value: 'lastMonth',
  },
];

const useSelectStyles = makeStyles({
  iconColored: {
    color: props => props && props.cor,
  },
});

export const SelectData = ({ periodo, setPeriodo, cor = defaultGrey }) => {
  const classes = useSelectStyles({ cor });

  return (
    <TextField
      select
      value={periodo}
      onChange={e => {
        setPeriodo(e.target.value);
      }}
      SelectProps={{
        style: {
          color: cor,
          fontWeight: '600',
        },
        classes: {
          icon: classes.iconColored,
        },
        disableUnderline: true,
      }}
    >
      {periodos.map(p => (
        <MenuItem value={p.value} key={p.value}>
          {p.nome}
        </MenuItem>
      ))}
    </TextField>
  );
};
