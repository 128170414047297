import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';
import AtividadeFragment from '../../../Atividade/AtividadeFragment.graphql';

export const negocioPerdidoMutationGql = gql`
  mutation informarNegocioPerdidoMutation(
    $negocioPerdido: InformarNegocioPerdido!
  ) {
    informarNegocioPerdido(negocioPerdido: $negocioPerdido) {
      ...AtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

export const criarENegocioPerdidoMutationGql = gql`
  mutation criarEinformarNegocioPerdidoMutation(
    $negocioPerdido: InformarNegocioPerdido!
  ) {
    criarEInformarNegocioPerdido(negocioPerdido: $negocioPerdido) {
      ...AtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

const genericNegocioPerdidoMutation = ({ mutationGql, name, mutationName }) =>
  graphqlOfflineMutation(mutationGql, {
    name,
    mutationName,
    idName: 'idAtividade',
    entityName: 'negocioPerdido',
    savedEntityName: 'atividade',
    description: async ({
      variables: {
        negocioPerdido: { idAtividade },
      },
      apolloClient,
    }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `Atividade:${idAtividade}`,
          fragment: gql`
            fragment NegocioPerdidoOffline on Atividade {
              id
              cliente {
                id
                nome
              }
            }
          `,
        });
        const {
          cliente: { nome },
        } = data;

        return `Informe de negócio perdido de cliente com nome: ${nome}.`;
      } catch (e) {
        return `Informe de negócio perdido.`;
      }
    },
  });

export const negocioPerdidoMutation = genericNegocioPerdidoMutation({
  mutationGql: negocioPerdidoMutationGql,
  name: 'negocioPerdido',
  mutationName: 'negocioPerdidoMutationGql',
});

export const criarENegocioPerdidoMutation = genericNegocioPerdidoMutation({
  mutationGql: criarENegocioPerdidoMutationGql,
  name: 'criarENegocioPerdido',
  mutationName: 'criarENegocioPerdidoMutationGql',
});
