import * as Sentry from '@sentry/browser';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_KC_ENV_TYPE === 'prod'
) {
  Sentry.init({
    dsn:
      process.env.REACT_APP_KC_ENV_TYPE !== 'staging'
        ? 'https://1aa07175907c4718a67a23ff9e91e65c@sentry.io/1538097'
        : 'https://1e264cdcb83643e48532430cc491c4f9@sentry.io/2027130',
  });
}

export default Sentry;
