/* eslint no-console: 0 */
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

// Links uteis para debug
export const linkOnError = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    console.error(`Operation: ${operation.operationName}`);
    console.error(operation.variables);

    if (graphQLErrors) {
      const gqlErros = graphQLErrors.map(err => {
        try {
          const { message, locations, path } = err;
          const messageError = JSON.parse(message);

          console.error(
            `[GraphQL error]:   
          Message: ${message}, 
          Location: ${JSON.stringify(locations)}, 
          Path: ${path},
          Error: ${JSON.stringify(err)}`
          );

          if (messageError.bundle) {
            return {
              message: messageError.message,
              bundle: messageError.bundle,
            };
          }
        } catch (e) {
          const { message } = err;

          console.error('ApolloLink:', e.message);
          console.error('Server error:', message);

          return { message };
        }

        return null;
      });

      response.errors = gqlErros;
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors && graphQLErrors.length === 0) {
      response.errors = null;
    }

    return null;
  }
);

export const consoleLink = new ApolloLink((operation, forward) => {
  console.debug(`starting request for ${operation.operationName}`);

  return forward(operation).map(data => {
    console.debug(`ending request for ${operation.operationName}`);

    return data;
  });
});
