import { graphql } from '@apollo/react-hoc';
import { compose, withProps } from 'recompose';
import ModelosFrotaQueryGql from './ModelosFrotaQueryGql';
import { displayLoadingState } from '../../../../../utils/displayLoadingState';
import { withUsuarioLogado } from '../../../../../UsuarioLogado/withUsuarioLogado';

export const ModelosFrotaQuery = Component =>
  compose(
    withUsuarioLogado,
    graphql(ModelosFrotaQueryGql, {
      name: 'modelosFrotaQuery',
    }),
    displayLoadingState({
      name: 'modelosFrotaQuery',
      onCacheLoadOnlyQueryName: 'modelosAtivos',
    }),
    withProps(({ modelosFrotaQuery: { modelosAtivos = [] } }) => {
      if (modelosAtivos) {
        return {
          modelosFrota: modelosAtivos,
          getModelosByMarcaSegmentoSubSegmento: ({
            idMarca = null,
            idSegmento = null,
            isSubSegmento = null,
          }) =>
            modelosAtivos.filter(modelo => {
              const { marca = {}, segmento = {}, subSegmento = {} } = modelo;

              return (
                (idMarca == null || (marca || {}).id === idMarca) &&
                (idSegmento == null ||
                  (segmento || {}).id === idSegmento ||
                  segmento === undefined ||
                  segmento === null) &&
                (isSubSegmento == null ||
                  (subSegmento || {}).id === isSubSegmento ||
                  subSegmento === undefined ||
                  subSegmento === null)
              );
            }),
        };
      }

      return {};
    })
  )(Component);
