import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const NDI_BLOQUEAR_ALTERACOES_CPF_CNPJ_CLIENTE = gql`
  query GetNdiBloquearAlteracoesCpfCnpjCliente($id: ID!) {
    ndiBloquearAlteracoesCpfCnpjCliente(id: $id)
  }
`;

export function useNdiBloquearAlteracoesCpfCnpjCliente() {
  const [ndiBloquearAlteracoesCpfCnpjCliente, resultado] = useLazyQuery(
    NDI_BLOQUEAR_ALTERACOES_CPF_CNPJ_CLIENTE
  );

  const isBloquearAlteracoesCpfCnpjCliente = React.useCallback(
    idCliente => {
      ndiBloquearAlteracoesCpfCnpjCliente({
        variables: { id: idCliente },
        fetchPolicy: 'cache-and-network',
      });
    },
    [ndiBloquearAlteracoesCpfCnpjCliente]
  );

  return {
    bloquearAlteracoesCpfCnpj:
      resultado.data?.ndiBloquearAlteracoesCpfCnpjCliente || false,
    isBloquearAlteracoesCpfCnpjCliente,
  };
}
