import { Chip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { FilterList } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { AtividadeBottomSheet } from '../../Home/AtividadeBottomSheet';
import { useUnidadeNegocio } from '../../Home/data/queries/useUnidadeNegocio';
import { ReagendamentoAtividadeDialogContainer } from '../../ReagendarAtividade/ReagendarAtividadeContainer';
import { EntrarEmContatoDialog } from '../../utils/EntrarEmContatoDialog';
import { FloatingButton } from '../../utils/FloatingButton';
import { eventIndicatorAdvancedSearchButton } from '../../utils/GAUtils';
import { RouteDialog } from '../../utils/RouteDialog';
import { RenderAppLogo, updateAppTitle } from '../../utils/uiUtils';
import { IndicadoresExcellenceClubDealer } from './Cards/ExcellenceClub/IndicadoresExcellenceClubDealer/IndicadoresExcellenceClubDealer';
import { IndicadoresExcellenceClubVendedor } from './Cards/ExcellenceClub/IndicadoresExcellenceClubVendedor/IndicadoresExcellenceClubVendedor';
import { IndicadorAtividadesAtrasadasCard } from './Cards/IndicadorAtividadesAtrasadasCard/IndicadorAtividadesAtrasadasCard';
import { indicadoresStyles } from './Cards/indicadoresStyles';
import { IndicadorLeadsSemTratamento } from './Cards/IndicadorLeadsSemTratamento';
import { DialogAtividadesIndicadores } from './DialogAtividadesIndicadores/DialogAtividadesIndicadores';
import { IndicadoresBottomSheet } from './IndicadoresBottomSheet';
import { IndicadoresComponentsContainer } from './IndicadoresComponents/IndicadoresComponentsContainer';
import { useExibirExcellenceClub } from './indicadoresHooks';
import { FloatButtonSpacePlaceholder } from './IndicadoresStyledComponents';
import IconUnidadeNegocio from '../../IconUnidadeNegocio';

const stylesIndicadores = makeStyles(indicadoresStyles);

const divChipStyle = {
  display: 'flex',
  justifyContent: 'center',
};
const chipStyle = {
  minWidth: 100,
  margin: 8,
};

export const Indicadores = ({
  temperaturas,
  menuOpen,
  showMenuBottomSheet,
  menuBottomSheetOnClose,
  dealerFiltro,
  vendedorFiltro,
  setShowActivitiesDialog,
  showActivitiesDialog,
  exibirMensagens,
  filtrosBusca,
  showDialogAtividades,
  usuarioLogado,
  periodoGlobal,
  setShowRescheduleModal,
  showRescheduleModal,
  showCallClientModal,
  setPeriodoGlobal,
  setShowCallClientModal,
  crmIndicadorFupMobileAtivo,
  urlRetorno,
  title,
  colocacao,
  marcaGlobal,
  setMarcaGlobal,
  onCloseDialogAtividadesIndicadores,
  usuarioAdmin,
  unidadeNegocioFiltro,
  setUnidadeNegocioFiltro,
  ...rest
}) => {
  const [unidadesNegocioVendaAtivas] = useUnidadeNegocio();
  const [idUnidadeNegocioUnica, setIdUnidadeNegocioUnica] = useState(null);
  const { descricaoNormalizado: descricaoSegmento } =
    unidadeNegocioFiltro?.segmento || {};

  useEffect(() => {
    if (unidadesNegocioVendaAtivas.length > 0) {
      setUnidadeNegocioFiltro(unidadesNegocioVendaAtivas[0]);
    }

    if (unidadesNegocioVendaAtivas.length === 1) {
      setIdUnidadeNegocioUnica(unidadeNegocioFiltro);
    }
  }, [unidadesNegocioVendaAtivas]); //eslint-disable-line

  const classes = stylesIndicadores();
  const { selectedActivity, setVendedorFiltro } = rest;
  const { marcas = [] } = usuarioLogado;

  const { exibeIndicadorExcellenceClubVendedor } = useExibirExcellenceClub(
    process.env.REACT_APP_SHOW_EXCELLENCE_CLUB === 'true',
    usuarioLogado
  );

  const dealerId = dealerFiltro && dealerFiltro.id;
  const userId = vendedorFiltro && vendedorFiltro.id;

  const handleClose = () => setShowRescheduleModal(false);
  const handleClose1 = () => setShowCallClientModal(false);
  const onClick = () => {
    eventIndicatorAdvancedSearchButton();
    showMenuBottomSheet({});
  };
  const icon = <FilterList />;

  return (
    <>
      <RenderAppLogo />
      {unidadeNegocioFiltro && (
        <>
          {updateAppTitle('Indicadores')}
          <div className={classes.fullList}>
            {unidadeNegocioFiltro && !idUnidadeNegocioUnica && (
              <div style={divChipStyle}>
                <Chip
                  label={unidadeNegocioFiltro?.descricao}
                  classes={{
                    root: classes.chipMarginTop,
                  }}
                  avatar={
                    <Avatar>
                      <IconUnidadeNegocio
                        descricaoNormalizado={descricaoSegmento}
                      />
                    </Avatar>
                  }
                  style={chipStyle}
                />
              </div>
            )}

            <IndicadorLeadsSemTratamento {...rest} />

            <IndicadoresExcellenceClubDealer
              showDialogAtividades={showDialogAtividades}
              dealerId={dealerId}
              unidadeNegocioId={unidadeNegocioFiltro?.id}
              marcas={marcas}
              userId={userId}
              setVendedorFiltro={setVendedorFiltro}
              exibir={false}
            />
            <IndicadoresExcellenceClubVendedor
              showDialogAtividades={showDialogAtividades}
              marca={marcaGlobal}
              setMarca={setMarcaGlobal}
              marcas={marcas}
              userId={userId || (usuarioLogado && usuarioLogado.id)}
              unidadeNegocioId={unidadeNegocioFiltro?.id}
              dealerId={dealerId}
              periodo={periodoGlobal}
              setVendedorFiltro={setVendedorFiltro}
              exibir={exibeIndicadorExcellenceClubVendedor}
            />
            <IndicadoresComponentsContainer
              periodoGlobal={periodoGlobal}
              periodo={periodoGlobal}
              setPeriodoGlobal={setPeriodoGlobal}
              setPeriodo={setPeriodoGlobal}
              exibirMensagens={exibirMensagens}
              dealerFiltro={dealerFiltro}
              unidadeNegocioFiltro={unidadeNegocioFiltro}
              vendedorFiltro={vendedorFiltro}
              showDialogAtividades={showDialogAtividades}
              dealer={dealerFiltro}
              unidadeNegocio={unidadeNegocioFiltro}
              usuario={vendedorFiltro}
              idSegmentoUnico={idUnidadeNegocioUnica}
              temperaturas={temperaturas}
              crmIndicadorFupMobileAtivo={crmIndicadorFupMobileAtivo}
              urlRetorno={urlRetorno}
            />

            <IndicadorAtividadesAtrasadasCard
              onClick={showDialogAtividades}
              dealer={dealerFiltro}
              unidadeNegocio={unidadeNegocioFiltro}
              usuario={vendedorFiltro}
              idSegmentoUnico={idUnidadeNegocioUnica}
              temperaturas={temperaturas}
            />

            <FloatingButton icon={icon} onClick={onClick} />
            {/* Daqui pra frente só componentes que não aparecem na tela instaneamente quando carrega */}
            <FloatButtonSpacePlaceholder />
            <DialogAtividadesIndicadores
              title={title}
              colocacao={colocacao}
              setShowActivitiesDialog={setShowActivitiesDialog}
              showActivitiesDialog={showActivitiesDialog}
              filtrosBusca={filtrosBusca}
              usuario={vendedorFiltro}
              dealer={dealerFiltro}
              unidadeNegocio={unidadeNegocioFiltro}
              onClose={onCloseDialogAtividadesIndicadores}
              {...rest}
            />
            <AtividadeBottomSheet usuarioLogado={usuarioLogado} {...rest} />
            <EntrarEmContatoDialog
              selectedActivityId={
                selectedActivity ? selectedActivity.id : undefined
              }
              selectedActivity={selectedActivity}
              client={selectedActivity ? selectedActivity.cliente : undefined}
              open={showCallClientModal}
              handleClose={handleClose1}
              {...rest}
            />
            <RouteDialog {...rest} />
            <IndicadoresBottomSheet
              open={menuOpen}
              onClose={menuBottomSheetOnClose}
              dealerFiltro={dealerFiltro}
              unidadeNegocioFiltro={unidadeNegocioFiltro}
              setUnidadeNegocioFiltro={setUnidadeNegocioFiltro}
              vendedorFiltro={vendedorFiltro}
              periodoGlobal={periodoGlobal}
              setPeriodoGlobal={setPeriodoGlobal}
              idSegmentoUnico={idUnidadeNegocioUnica}
              marcaGlobal={marcaGlobal}
              setMarcaGlobal={setMarcaGlobal}
              usuarioLogado={usuarioLogado}
              setVendedorFiltro={setVendedorFiltro}
              unidadesNegocioVendaAtivas={unidadesNegocioVendaAtivas}
              {...rest}
            />
            <ReagendamentoAtividadeDialogContainer
              {...rest}
              idAtividade={selectedActivity && selectedActivity.id}
              open={showRescheduleModal}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </>
  );
};
