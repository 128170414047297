import moment from 'moment';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import {
  getFirstElementOfArray,
  isNotUndefOrNull,
} from '@tecsinapse/es-utils/build';
import { getAlertContext } from '../utils/alertContext';
import { displayLoadingState } from '../utils/displayLoadingState';
import {
  criarEInformarResultadoProspeccaoMutation,
  informarResultadoProspeccaoMutation,
} from './data/mutations/InformarResultadoProspeccaoMutation';
import { RealizarAtividadeWizard } from './RealizarAtividadeWizard';
import getAtividadeQueryGql from './data/queries/getAtividadeQueryGql.graphql';
import {
  criarERealizarAtividadeMutation,
  realizarAtividadeMutation,
} from './data/mutations/RealizarAtividadeMutation';
import { NegociacaoAceitaMutation } from './data/mutations/NegociacaoAceitaMutation';
import {
  criarENegocioPerdidoMutation,
  negocioPerdidoMutation,
} from './data/mutations/NegocioPerdidoMutation';
import { encerrarFichaMutation } from './data/mutations/EncerrarFichaMutation';
import { TemperaturasQueryHoc } from './data/queries/TemperaturasQuery';
import { MotivosArquivarContatoQueryHoc } from './data/queries/MotivosArquivarContatoQuery';
import { TiposDeContatoQueryHoc } from './data/queries/TiposDeContatoQuery';
import { MotivosEncerramentoFichaVisitaSelecionaveisQueryHOC } from './data/queries/MotivosEncerramentoFichaVisitaSelecionaveisQuery';
import { RazoesVisitaDisponiveisQueryHoc } from './data/queries/RazoesVisitaDisponiveisQuery';
import {
  OpcoesDataProximaAtividade,
  TipoEntrarEmContato,
  TipoOrigemAtividade,
} from '../../core/Enums';
import { MotivosVendaPerdidaQueryHOC } from './data/queries/MotivosVendaPerdidaQuery';
import { TiposVendaPerdidaQueryHOC } from './data/queries/TiposVendaPerdidaQuery';
import { MarcasConcorrentesQueryHOC } from './data/queries/MarcasConcorrentesQuery';
import { TipoVendaPerdidaObrigatorioHoc } from '../opportunity/data/queries/crmTipoVendaPerdidaObrigatorio/crmTipoVendaPerdidaObrigatorioQuery';
import {
  withCrmObrigarPreenchimentoDadosVeiculoOportunidade,
  withObrigarModeloInteresseAtivo,
} from '../opportunity/data/queries/crmTermosQuery';

export const getAtividadeQueryHoc = compose(
  graphql(getAtividadeQueryGql, {
    options: ({ match }) => ({
      fetchPolicy: 'cache-and-network',
      skip: !isNotUndefOrNull(match.params.id),
      returnPartialData: !navigator.onLine,
      variables: {
        id: match.params.id,
      },
    }),
  }),
  displayLoadingState({
    onCacheLoadOnlyQueryName: 'atividade',
    skipable: false,
  })
);

const enhance = compose(
  withObrigarModeloInteresseAtivo,
  withRouter,
  getAtividadeQueryHoc,
  TemperaturasQueryHoc,
  MotivosArquivarContatoQueryHoc,
  TiposDeContatoQueryHoc,
  MotivosEncerramentoFichaVisitaSelecionaveisQueryHOC,
  RazoesVisitaDisponiveisQueryHoc,
  MotivosVendaPerdidaQueryHOC,
  TiposVendaPerdidaQueryHOC,
  MarcasConcorrentesQueryHOC,
  TipoVendaPerdidaObrigatorioHoc,
  realizarAtividadeMutation,
  criarERealizarAtividadeMutation,
  NegociacaoAceitaMutation,
  negocioPerdidoMutation,
  criarENegocioPerdidoMutation,
  encerrarFichaMutation,
  informarResultadoProspeccaoMutation,
  criarEInformarResultadoProspeccaoMutation,
  getAlertContext,
  withCrmObrigarPreenchimentoDadosVeiculoOportunidade,
  withProps(props => {
    const {
      tiposDeContatoQuery: { tiposContato = [] },
      data: {
        atividade: { id, tipoOrigem, dataHoraExibicao, cliente = {} } = {},
        atividade,
      } = {},
      match: {
        params: {
          tipoAcaoInformarContato,
          clientId,
          idContato: idContatoInput,
          tipoContato,
          unidadeNegocioId,
          urlRetorno,
          roteirizacaoClienteId = null,
        },
      },
    } = props;

    let idContato = null;

    try {
      idContato = parseInt(idContatoInput, 10);

      if (!isNaN(idContato)) {
        idContato = `${idContato}`;
      }

      // eslint-disable-next-line no-empty
    } catch (e) {}

    let tipoContatoId = null;

    if (tipoContato) {
      const tipoContatoName = TipoEntrarEmContato.enumValueOf(tipoContato);
      const tiposContatos = tiposContato.filter(
        tc => tc.nome === tipoContatoName.nome
      );
      const tipoContatoResolved = getFirstElementOfArray(tiposContatos);

      tipoContatoId = tipoContatoResolved ? tipoContatoResolved.id : null;
    }

    const dataHoraExibicaoMoment =
      (dataHoraExibicao && moment(dataHoraExibicao)) || moment();

    const dataHoraRealizacao = moment().isBefore(dataHoraExibicaoMoment)
      ? moment()
      : dataHoraExibicaoMoment;

    return {
      formName: 'RealizarAtividadeForm',
      activityBranch: atividade,
      idOportunidade:
        atividade && atividade.oportunidade && atividade.oportunidade.id,
      clienteNome: cliente && cliente.nome,
      tipoAcaoInformarContato,
      clientId,
      unidadeNegocioId,
      urlRetorno,
      initialValues: {
        idContato:
          TipoOrigemAtividade.OPORTUNIDADE.name === tipoOrigem
            ? idContato
            : undefined,
        tipoContato:
          TipoOrigemAtividade.FICHA_VISITA.name !== tipoOrigem
            ? tipoContatoId
            : undefined,
        idAtividade: id,
        dataHoraReagendamento: moment(),
        dataHoraRealizacao,
        ...(tipoOrigem === TipoOrigemAtividade.FICHA_VISITA.name
          ? {
              opcaoDataProximaAtividade:
                OpcoesDataProximaAtividade.CALCULADA_PELA_SISTEMATICA.name,
            }
          : {}),
      },
      roteirizacaoClienteId,
    };
  }),
  getAlertContext
);

export const RealizarAtividadeContainer = enhance(RealizarAtividadeWizard);
