import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import ModeloInteresseQueryGql from './ModeloInteresseQueryGql.graphql';
import GetPodeEditarPrecoUnitarioQueryGql from './GetPodeEditarPrecoUnitarioQueryGql.graphql';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import { LoadingContained } from '../../../utils/Loading';

export const ModeloInteresseQuery = graphql(ModeloInteresseQueryGql, {
  name: 'modeloInteresse',
  options: ({ match, idModeloInteresse }) => ({
    variables: {
      id: idModeloInteresse || match.params.id,
      nullId: isEmptyOrNull(idModeloInteresse || match.params.id),
    },
  }),
});

export const PodeEditarPrecoUnitarioQuery = graphql(
  GetPodeEditarPrecoUnitarioQueryGql,
  {
    name: 'permissoesUsuario',
  }
);

export const PodeEditarPrecoUnitarioQueryHOC = compose(
  PodeEditarPrecoUnitarioQuery,
  displayLoadingState({
    name: 'permissoesUsuario',
    onCacheLoadOnlyQueryName: 'podeEditarPrecoUnitarioModeloInteresse',
    LoadingComponent: LoadingContained,
  })
);
