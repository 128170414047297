import { makeStyles } from '@material-ui/styles';
import { Tunnel } from 'react-tunnels';
import SearchBar from 'material-ui-search-bar';
import React from 'react';

const styles = () => ({
  iconButton: {
    marginTop: '-4px',
  },
});

const userAgendaSearchBarStyles = makeStyles(styles);

const style = {
  margin: '0 10px 10px',
  height: '40px',
  boxShadow: 'none',
};

const AgendaSearchBar = React.memo(({ action }) => {
  const classes = userAgendaSearchBarStyles();

  return (
    <SearchBar
      style={style}
      classes={{ searchIconButton: classes.iconButton }}
      placeholder="O que deseja buscar?"
      onClick={action}
      onRequestSearch={action}
      readOnly
    />
  );
});

export const showAgendaSearch = ({ action } = {}) => (
  <Tunnel id="app-search-bar">
    <AgendaSearchBar action={action} />{' '}
  </Tunnel>
);
