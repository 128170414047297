import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';
import encerrarFichaMutationGql from './encerrarFichaMutationGql.graphql';

export const encerrarFichaMutation = graphqlOfflineMutation(
  encerrarFichaMutationGql,
  {
    name: 'encerrarFichaVisita',
    mutationName: 'encerrarFichaMutationGql',
    idName: 'idAtividade',
    entityName: 'fichaVisita',
    savedEntityName: 'atividade',

    description: async ({
      variables: {
        fichaVisita: { idAtividade },
      },
      apolloClient,
    }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `Atividade:${idAtividade}`,
          fragment: gql`
            fragment EncerrarFichaVisitaOffline on Atividade {
              id
              cliente {
                id
                nome
              }
            }
          `,
        });
        const {
          cliente: { nome },
        } = data;

        return `Encerramento de Ficha de Visita de cliente com nome: ${nome}.`;
      } catch (e) {
        return `Encerramento de Ficha de Visita.`;
      }
    },
  }
);
