import React, { useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, Form } from 'redux-form';
import moment from 'moment';
import {
  RenderDateTimePicker,
  RenderInput,
  required,
  SubmitButton,
  submitWithAction,
} from '../../utils/FormUtils';
import { RenderSelectField } from '../../utils/select/RenderSelectField';
import { ToggleSimNao } from './ToggleSimNao';
import {
  eventCreateOpportunityProspection,
  eventRescheduleClientContactedProspection,
} from '../../utils/GAUtils';
import { IconButtonMaiusculas } from '../IconButtonMaiusculas/IconButtonMaiusculas';

export const ConseguiContato = ({
  handleSubmit,
  onSubmit,
  classes,
  initialValues,
  setTextAction,
  observacao,
  motivosArquivarContatoQuery: { motivosArquivarContato = [] },
  tiposDeContatoQuery: { tiposContato = [] },
  temperaturasQuery: { temperaturas = [] },
  goToNextPage,
  formName = null,
  ...rest
}) => {
  const [reagendarContato, setReagendarContato] = useState(false);
  const [criarOportunidade, setCriarOportunidade] = useState(true);
  const handleReagendamento = (event, reagendar) => {
    eventRescheduleClientContactedProspection();
    setReagendarContato(reagendar);
    setCriarOportunidade(!reagendar);
  };

  const handleOportunidade = (event, novaOportunidade) => {
    eventCreateOpportunityProspection();
    setCriarOportunidade(novaOportunidade);
  };
  const podeTentarContato = reagendarContato || criarOportunidade;

  return (
    <Form onSubmit={handleSubmit} name={formName}>
      <Grid container>
        <Grid item xs={12} container>
          <ToggleSimNao
            label="Deseja reagendar o contato?"
            handleChange={handleReagendamento}
            booleanValue={reagendarContato}
          />

          {!reagendarContato && (
            <ToggleSimNao
              label="Deseja criar uma oportunidade?"
              handleChange={handleOportunidade}
              booleanValue={criarOportunidade}
            />
          )}
        </Grid>

        {!reagendarContato && !criarOportunidade && (
          <Grid item xs={12}>
            <Field
              name="motivoArquivarContato"
              validate={[required]}
              label="Selecione um motivo para o arquivamento"
              required
              component={RenderSelectField}
              fullWidth
            >
              {motivosArquivarContato.map(({ id, descricao }) => (
                <MenuItem value={id} key={id}>
                  {descricao}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        )}

        {reagendarContato && (
          <Grid item xs={12}>
            <Field
              fullWidth
              name="dataHoraReagendamento"
              label="Data de reagendamento"
              minDate={moment()}
              component={RenderDateTimePicker}
            />
          </Grid>
        )}

        {!reagendarContato && (
          <>
            <Grid item xs={12}>
              <Field
                name="temperatura"
                label="Temperatura"
                required
                validate={[required]}
                component={RenderSelectField}
                fullWidth
              >
                {temperaturas
                  .filter(t => t.id !== 'BOLSAO')
                  .map(({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  ))}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="tipoContato"
                validate={[required]}
                label="Forma de Contato"
                required
                component={RenderSelectField}
                fullWidth
              >
                {tiposContato
                  .filter(({ prospeccao = false }) => prospeccao)
                  .map(({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  ))}
              </Field>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Field
            name="observacao"
            label="Observações"
            required
            multiline
            component={RenderInput}
            fullWidth
            endAdornment={
              <IconButtonMaiusculas
                observacao={observacao}
                setTextAction={setTextAction}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          {podeTentarContato ? (
            <SubmitButton
              label="confirmar"
              fullWidth
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'CONSEGUI_CONTATO',
                payload: { criarOportunidade, reagendarContato },
              })}
              {...rest}
            />
          ) : (
            <SubmitButton
              label="arquivar"
              fullWidth
              secundary
              onClick={submitWithAction({
                handleSubmit,
                onSubmit,
                action: 'CONSEGUI_CONTATO_ARQUIVAR',
              })}
              {...rest}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
};
