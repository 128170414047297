import { graphql } from '@apollo/react-hoc';
import { compose } from 'recompose';
import GetCrmTipoVendaPerdidaObrigatorioGql from './GetCrmTipoVendaPerdidaObrigatorioGql.graphql';
import { displayLoadingState } from '../../../../utils/displayLoadingState';

export const GetCrmTipoVendaPerdidaObrigatorioQueryGql = graphql(
  GetCrmTipoVendaPerdidaObrigatorioGql,
  {
    name: 'tipoVendaPerdidaObrigatorio',
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
  }
);
export const TipoVendaPerdidaObrigatorioHoc = compose(
  GetCrmTipoVendaPerdidaObrigatorioQueryGql,
  displayLoadingState({
    name: 'tipoVendaPerdidaObrigatorio',
    onCacheLoadOnlyQueryName: 'tipoVendaPerdidaObrigatorio',
  })
);
