import Typography from '@material-ui/core/Typography/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton/ToggleButton';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { defaultGreyLight3, globalStyle } from '../../app/globalStyle';

const useLeadButtonStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  image: {
    height: 100,
    zIndex: 1,
    '& $imageBackdrop': {
      opacity: 0.05,
    },
    '& $imageMarked': {
      opacity: 1,
    },
    '& $bordered': {
      borderStyle: 'solid',
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    opacity: 0.0,
    transition: theme.transitions.create('opacity'),
  },
  bordered: {
    borderStyle: 'none',
    borderRadius: '5px',
    borderWidth: '1px',
    transition: theme.transitions.create('borderStyle'),
  },
  imageTitle: {
    position: 'relative',
    fontSize: '2em',
  },
  imageMarked: {
    height: 3,
    width: 18,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
  },
  margin: {
    marginTop: `${theme.spacing(1)}px`,
  },
  ...globalStyle(theme),
}));

const style2 = {
  textTransform: 'capitalize',
  padding: '3px',
  color: 'rgb(97,97,97)',
};

export const LeadButton = ({
  onClick,
  title,
  value,
  icon,
  Component = ToggleButton,
  selected,
  color,
  style,
}) => {
  const borderColor = selected ? color : defaultGreyLight3;
  const backDropBackgroundColor = selected ? color : 'white';
  const classes = useLeadButtonStyles();

  const style1 = {
    ...style,
    color,
  };

  return (
    <Component
      focusRipple
      onClick={onClick}
      className={clsx(classes.image)}
      focusVisibleClassName={classes.focusVisible}
      value={value}
      variant="contained"
      style={style1}
    >
      <span
        className={clsx(classes.imageButton, classes.bordered)}
        style={{ borderColor }}
      >
        <Typography
          component="span"
          variant="subtitle1"
          color="inherit"
          className={classes.imageTitle}
        >
          {icon}
          <span
            className={selected ? classes.imageMarked : ''}
            style={{ backgroundColor: color }}
          />
        </Typography>

        <span
          className={classes.imageBackdrop}
          style={{ backgroundColor: backDropBackgroundColor }}
        />

        {title && (
          <Typography
            variant="caption"
            gutterBottom
            align="center"
            color="inherit"
            className={classes.margin}
            style={style2}
          >
            {title}
          </Typography>
        )}
      </span>
    </Component>
  );
};
