import { graphql } from '@apollo/react-hoc';
import { isEmptyOrNull } from '@tecsinapse/es-utils/build';
import PriceTableQueryQueryGql from './PriceTableQueryQueryGql.graphql';

export const PriceTableQuery = graphql(PriceTableQueryQueryGql, {
  name: 'priceTable',
  options: (props = {}) => {
    const {
      graphqlOptions: { idTabelaPreco = null } = {},
      setGraphqlOptions,
      getIdTabelaPreco,
    } = props;

    const tabela = getIdTabelaPreco();

    if (isEmptyOrNull(idTabelaPreco) && !isEmptyOrNull(tabela)) {
      setGraphqlOptions({ idTabelaPreco: tabela });
    }

    return {
      variables: {
        nullId: isEmptyOrNull(idTabelaPreco),
        id: idTabelaPreco || 0,
      },
      skip: isEmptyOrNull(idTabelaPreco),
    };
  },
});
