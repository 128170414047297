import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { Field } from 'redux-form';
import WarningIcon from '@material-ui/icons/Warning';
import styled from 'styled-components';
import {
  NumberField,
  RenderInput,
  SubmitButton,
} from '../../../utils/FormUtils';
import {
  ShowBackButton,
  updateAppTitle,
  RenderAppLogo,
} from '../../../utils/uiUtils';
import { RenderSelectField } from '../../../utils/select/RenderSelectField';
import { eventSavePaymentMethods } from '../../../utils/GAUtils';
import { currencyMask, percentageMask } from '../../../utils/Masks';
import { useGlobalStyle } from '../../../app/globalStyle';

const VeiculoSemPreco = styled.div`
  margin: 0 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;
`;

const Icone = styled(WarningIcon)`
  margin: 0 10px;
  color: #ffd08acc;
`;

export const ProposalPaymentEditor = ({
  handleSubmit,
  history,
  metodosPagamentoAtivos,
  financeirasQuery,
  validateIdMetodoPagamento,
  percentual,
  valor,
  disabledForm,
  recalculateFields,
  validatePricesValue,
  formName = null,
  temModelosSemPrecoUnitario,
  ...rest
}) => {
  const classes = useGlobalStyle();

  return (
    <form onSubmit={handleSubmit} className={classes.form} name={formName}>
      <RenderAppLogo />
      {updateAppTitle('Editar Pagamento')}
      <ShowBackButton history={history} />
      <Grid container>
        {temModelosSemPrecoUnitario && (
          <VeiculoSemPreco>
            <Icone />
            <p>Existem veículos em negociação sem preço unitário informado.</p>
          </VeiculoSemPreco>
        )}
        <Grid item xs={12}>
          <Field
            name="idMetodoPagamento"
            label="Método de Pagamento"
            required
            validate={validateIdMetodoPagamento}
            disabled={disabledForm}
            component={RenderSelectField}
            fullWidth
          >
            <MenuItem value={null}>Selecione...</MenuItem>
            {(
              (metodosPagamentoAtivos &&
                metodosPagamentoAtivos.metodosPagamentoAtivos) ||
              []
            ).map(({ id, nome }) => (
              <MenuItem value={id} key={id}>
                {nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="valor"
            label="Valor"
            component={RenderInput}
            required
            fullWidth
            disabled={disabledForm}
            validate={validatePricesValue}
            {...currencyMask(value => {
              return recalculateFields({ valor: value });
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="percentual"
            label="Percentual"
            component={RenderInput}
            required
            fullWidth
            disabled={disabledForm}
            validate={validatePricesValue}
            {...percentageMask(value => {
              return recalculateFields({ percentual: value });
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="idFinanceira"
            disabled={disabledForm}
            label="Financeira"
            component={RenderSelectField}
            fullWidth
          >
            <MenuItem value={null}>Selecione...</MenuItem>
            {(
              (financeirasQuery && financeirasQuery.financeirasAtivas) ||
              []
            ).map(({ id, nome }) => (
              <MenuItem value={id} key={id}>
                {nome}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="numeroParcelas"
            label="Parcelas"
            disabled={disabledForm}
            min={1}
            component={NumberField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            fullWidth
            disabled={disabledForm}
            label="Salvar"
            onClick={eventSavePaymentMethods}
            {...rest}
          />
        </Grid>
      </Grid>
    </form>
  );
};
