import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';
import focusFirstInvalidInputControl from './domUtils/focusFirstInvalidInputControl';

function reduxFormAutoFocus({ form: name, ...rest }) {
  return component =>
    compose(
      reduxForm({
        form: name,
        onSubmitFail: (errors, dispatch, submitError, props) => {
          if (submitError) {
            console.error('SUBMIT ERRO: ', submitError);
          }
          focusFirstInvalidInputControl(name, errors);
        },
        ...rest,
      }),
      withProps(() => ({ formName: name }))
    )(component);
}
export { reduxFormAutoFocus };
