import {
  styleCardAlerta,
  styleCardAtrasado,
  styleCardDone,
} from '../../../../../../../Home/ActivityCard/activityCardFunctions';

export const useCorCardStyle = porcentageInput => {
  if (porcentageInput <= 50) {
    return styleCardAtrasado;
  }

  if (porcentageInput < 100) {
    return styleCardAlerta;
  }

  return styleCardDone;
};
