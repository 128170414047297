import React from 'react';
import { IndicadorExcellenceClubVendedorGenerico } from '../IndicadorExcellenceClubVendedorGenerico/IndicadorExcellenceClubVendedorGenerico';
import { eventFup48IndicadorClick } from '../../../../../../utils/GAUtils';
import { TipoIndicadorExcellenceClubVendedorCard } from '../../../../../../../core/Enums';

const IndicadorFollowUp48h = React.memo(
  ({
    showDialogAtividades,
    exibirColocacaoTexto = false,
    exibirMarca,
    marcas,
    marca,
    setMarca,
    totalFup48,
    pendentesFup48,
    idsFup48,
    nomeUsuario,
    colocacao,
    totalVendedores,
    porcentagemFup,
    idUsuario,
    setVendedorFiltro,
  }) => {
    return (
      <IndicadorExcellenceClubVendedorGenerico
        titulo={nomeUsuario || 'Follow-up 48hrs'}
        total={totalFup48}
        executado={pendentesFup48}
        idsAtividades={idsFup48}
        showDialogAtividades={showDialogAtividades}
        mensagemAction="MELHORAR ESSE INDICADOR"
        textoItemDireita="Total de Follow-Up"
        textoItemEsquerda="Follow-Up Pendentes"
        marcas={marcas}
        marca={marca}
        setMarca={setMarca}
        exibirColocacaoTexto={exibirColocacaoTexto}
        exibirMarca={exibirMarca}
        mensagemDialog="Follow-up 48h"
        gaEventClick={eventFup48IndicadorClick}
        colocacao={colocacao}
        totalVendedores={totalVendedores}
        porcentagem={porcentagemFup}
        idUsuario={idUsuario}
        setVendedorFiltro={setVendedorFiltro}
        tipoIndicador={TipoIndicadorExcellenceClubVendedorCard.FUP48.name}
      />
    );
  }
);

export { IndicadorFollowUp48h };
export default IndicadorFollowUp48h;
