import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { genericQueryHoc } from '../../utils/crud/genericQueryHoc';
import { getLocalDateTimeString } from '../../utils/dateUtils';
import { enumGetNames, TipoAtividade } from '../../../core/Enums';
import { CardLoading } from '../../utils/CardLoading';

const GetAtividadesCountQuery = gql`
  query getAtividadesCountQuery(
    $initialDate: DateTime
    $endDate: DateTime
    $dealerPk: ID
    $types: [TipoAtividade]
    $temperatura: TemperaturaNegociacao
    $unidadeNegocioId: ID
    $semTemperatura: Boolean
    $usuarioId: ID
    $somenteAtividadesGerente: Boolean = false
    $dataHoraFinalTestDriveOferecido: DateTime
    $dataHoraInicialTestDriveOferecido: DateTime
    $ofereceuTestDrive: Boolean
    $dataInicialCriacaoOportunidade: DateTime
  ) {
    getAtividadesCount(
      filter: {
        dataHoraFinalTestDriveOferecido: $dataHoraFinalTestDriveOferecido
        dataHoraInicialTestDriveOferecido: $dataHoraInicialTestDriveOferecido
        ofereceuTestDrive: $ofereceuTestDrive
        dataInicialCriacaoOportunidade: $dataInicialCriacaoOportunidade
        dataInicio: $initialDate
        dealerPk: $dealerPk
        dataFim: $endDate
        tipos: $types
        finalizado: false
        temperatura: $temperatura
        unidadeNegocioId: $unidadeNegocioId
        semTemperatura: $semTemperatura
        usuarioId: $usuarioId
        somenteAtividadesGerente: $somenteAtividadesGerente
      }
    )
  }
`;

export const [
  AtividadesAtrasadasQuery,
  AtividadesAtrasadasQueryHoc,
  AtividadesAtrasadasQueryName,
  AtividadesAtrasadasQueryDataName,
] = genericQueryHoc({
  card: true,
  name: 'getAtividadesCount',
  gql: GetAtividadesCountQuery,
  options: ({ dealer, temperaturaAtual, usuario, unidadeNegocio }) => {
    const dataFim = getLocalDateTimeString(moment().startOf('day'));
    const variables = {
      initialDate: null,
      endDate: dataFim,
      dealerPk: dealer && dealer.id,
      types: enumGetNames(TipoAtividade),
      temperatura: temperaturaAtual && temperaturaAtual.id,
      usuarioId: usuario && usuario.id,
      somenteAtividadesGerente: false,
      semTemperatura: temperaturaAtual && temperaturaAtual.semTemperatura,
      unidadeNegocioId: unidadeNegocio && unidadeNegocio.id,
    };

    return {
      fetchPolicy: 'cache-and-network',
      variables,
    };
  },
  skip: null,
  LoadingComponent: () => <CardLoading />,
});
