import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { MonetizationOn, Delete, Edit } from '@material-ui/icons';
import { Table } from '@tecsinapse/table/build/Table/Table';
import { EmptyState } from '@tecsinapse/ui-kit';

import { globalStyle } from '../../app/globalStyle';
import { FloatingButton } from '../../utils/FloatingButton';
import { formatMoney } from '../../utils/moneyUtils';
import {
  eventAddPaymentMethods,
  eventEditPaymentMethods,
  eventRemovePaymentMethods,
} from '../../utils/GAUtils';

const useStyle = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ProposalPaymentMethods = withStyles(globalStyle)(
  ({ proposta, onEditPayment, goToCreation, removeOnClick, disabledForm }) => {
    const classes = useStyle();
    const columns = [
      {
        title: 'Método Pagamento',
        field: 'metodoPagamento.nome',
      },
      {
        title: 'Valor',
        field: 'valor',
        customRender: row => (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {formatMoney(row.valor)}
              {row.numeroParcelas && ` em ${row.numeroParcelas}x`}
            </Typography>
          </div>
        ),
      },
      {
        title: 'Percentual da Proposta',
        field: 'percentual',
        customRender: row => (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {row.percentual}%
            </Typography>
          </div>
        ),
      },
      {
        title: 'Financeira',
        field: 'financeira.nome}',
        customRender: row => (
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              {row.financeira && row.financeira.nome}
            </Typography>
          </div>
        ),
      },
    ];
    const editAction = [
      {
        icon: <Edit />,
        tooltip: 'Editar Pagamento',
        label: 'Editar Pagamento',
        onClick: payment => {
          eventEditPaymentMethods();
          onEditPayment(payment);
        },
      },
    ];
    const deleteAction = [
      {
        icon: <Delete />,
        tooltip: 'Deletar Pagamento',
        label: 'Deletar Pagamento',
        onClick: payment => {
          eventRemovePaymentMethods();
          removeOnClick(payment);
        },
      },
    ];

    return (
      <>
        {proposta && proposta.formasPagamento && (
          <Table
            columns={columns}
            data={proposta.formasPagamento}
            rowId={row => row.id}
            onRowClick={payment => {
              eventEditPaymentMethods();
              onEditPayment(payment);
            }}
            actions={
              disabledForm ? editAction : [...editAction, ...deleteAction]
            }
            classes={classes}
            empytStateComponent={
              <EmptyState
                IconComponent={MonetizationOn}
                message="Não há nenhuma forma de pagamento por aqui ainda."
              />
            }
          />
        )}

        <FloatingButton
          onClick={() => {
            eventAddPaymentMethods();
            goToCreation({});
          }}
          disabled={disabledForm}
        />
      </>
    );
  }
);
