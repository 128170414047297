import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Chip, makeStyles, MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { DirectionsCar } from '@material-ui/icons';
import { mdiAccountCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { EmptyState } from '@tecsinapse/ui-kit';
import styled from 'styled-components';
import { FloatingButton } from '../utils/FloatingButton';
import { eventOpportunityAddVehicle } from '../utils/GAUtils';
import { OpportunityVehiclesCard } from './OpportunityVehiclesCard';
import { SelectComponentStyled } from '../utils/select/RenderSelectField';
import { useUnidadeNegocioResponsavel } from '../Home/data/queries/useUnidadeNegocio';
import alterarUnidadeNegocioGql from './data/mutations/alterarUnidadeNegocioGql.graphql';
import { confirm } from '../utils/dialogs/ConfirmationDialog';

const useComponenteEstilos = makeStyles({
  topo: {
    textAlign: 'center',
    paddingTop: 8,
  },
  chip: {
    width: '95%',
    margin: 4,
    textTransform: 'uppercase',
  },
  avatar: {
    position: 'initial',
  },
});

const RenderSelectUnidadeNegocio = styled(SelectComponentStyled)`
  margin: 8px 8px 0px 8px;
`;

export const OpportunityVehicles = ({
  modelosInteresse,
  modelosInteresseMapByAgrupamento,
  onEditClick,
  onPerformTestDrive,
  goToAddModeloInteresse,
  disabledForm,
  removeOnClick,
  onChangeEmNegociacao,
  modeloLabel,
  oportunidade,
  crmAnoModeloAtivo,
  crmVersaoTermo,
  usuarioLogado,
}) => {
  const classes = useComponenteEstilos();

  const [alterarUnidadeNegocioMutation] = useMutation(alterarUnidadeNegocioGql);

  const {
    unidadeNegocio: {
      segmento: { id: segmentoId },
    },
  } = oportunidade;

  const [unidadesNegocioSelecionaveis, loading] = useUnidadeNegocioResponsavel(
    segmentoId,
    oportunidade.cliente.clienteUnidadesNegocio,
    usuarioLogado.id
  );

  const [unidadeNegocioSelected, setUnidadeNegocioSelected] = useState(null);

  useEffect(() => {
    if (
      !unidadeNegocioSelected &&
      !loading &&
      unidadesNegocioSelecionaveis.length > 0
    ) {
      setUnidadeNegocioSelected(
        getUnidadeSelected(oportunidade.unidadeNegocio?.id)
      );
    }
  }, [loading, unidadesNegocioSelecionaveis]); //eslint-disable-line

  const getUnidadeSelected = useCallback(
    idUnidadeNegocio =>
      unidadesNegocioSelecionaveis.find(u => u.id === idUnidadeNegocio),
    [unidadesNegocioSelecionaveis]
  );

  const onChangeUnidadeNegocio = unidadeNegocio => {
    const confirmation =
      'Você realmente deseja alterar a unidade de negócio? Se você prosseguir, os dados de veículos dessa oportunidade serão resetados.';

    confirm({ confirmation }).then(() => {
      alterarUnidadeNegocioMutation({
        variables: {
          id: oportunidade.id,
          idUnidadeNegocio: unidadeNegocio.id,
        },
      }).then(result =>
        setUnidadeNegocioSelected(
          getUnidadeSelected(
            result.data.alterarUnidadeNegocio.unidadeNegocio.id
          )
        )
      );
    });
  };

  return (
    <>
      <div className={classes.topo}>
        <Chip
          label={oportunidade.cliente.nome}
          className={classes.chip}
          avatar={
            <Avatar className={classes.avatar}>
              <Icon path={mdiAccountCircle} size={1} />
            </Avatar>
          }
        />
      </div>

      <RenderSelectUnidadeNegocio
        input={{
          value: unidadeNegocioSelected,
          onChange: onChangeUnidadeNegocio,
        }}
        placeholder="Unidade de Negócio"
        label="Unidade de Negócio"
        fullWidth
        disabled={unidadesNegocioSelecionaveis.length <= 1}
      >
        {(unidadesNegocioSelecionaveis || [unidadeNegocioSelected]).map(u => {
          const { id, descricao } = u;

          return (
            <MenuItem value={u} key={id}>
              {descricao}
            </MenuItem>
          );
        })}
      </RenderSelectUnidadeNegocio>

      {modelosInteresse ? (
        Object.values(modelosInteresseMapByAgrupamento).map(modelo => (
          <OpportunityVehiclesCard
            key={modelo.id}
            onEditClick={onEditClick}
            onRemoveClick={removeOnClick}
            onPerformTestDrive={onPerformTestDrive}
            onChangeEmNegociacao={onChangeEmNegociacao}
            disableEdit={disabledForm || false}
            vehicle={modelo}
            oportunidade={oportunidade}
            crmAnoModeloAtivo={crmAnoModeloAtivo}
            modeloLabel={modeloLabel}
            crmVersaoTermo={crmVersaoTermo}
          />
        ))
      ) : (
        <EmptyState
          IconComponent={DirectionsCar}
          message="Não há nenhum veículo por aqui ainda."
        />
      )}
      <FloatingButton
        disabled={disabledForm}
        onClick={() => {
          eventOpportunityAddVehicle();
          goToAddModeloInteresse();
        }}
      />
    </>
  );
};
