import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, getContext, withHandlers } from 'recompose';
import React from 'react';
import { GenericSearchBar } from '../../../utils/Search/GenericSearchBar';
import { ClienteSearchListing } from './ClienteSearchListing';
import { withGenericSearchBarState } from '../../../utils/Search/WithGenericSearchBarState';
import { clienteBottomSheet } from '../../clienteBottomSheet/clienteBottomSheet';
import { informarContatoClienteMutation } from '../../clienteBottomSheet/data/informarContatoClienteMutation';
import { withAtualizaModeloInativo } from '../../../AtualizaModeloInativo/withAtualizaModeloInativo';

const enhance = compose(
  withAtualizaModeloInativo,
  withGenericSearchBarState({
    textSearchPlaceHolder: 'Busca por nome ou cpf/cnpj do cliente',
    textSearchPlaceHolderExpansion: 'Nome, CPF ou CNPJ',
    itemPlaceHolder:
      'Digite o nome ou CPF/CNPJ do cliente para realizar uma busca',
    SearchListener: ClienteSearchListing,
    isToUpdateAppTitle: true,
    isShowFloatingButton: true,
  }),
  getContext({
    createModal: PropTypes.func,
    showCreateOpportunityModal: PropTypes.func,
    showCreateProspectionModal: PropTypes.func,
    showCreateAtividadeFichaVisitaModal: PropTypes.func,
  }),
  withRouter,
  informarContatoClienteMutation,
  withHandlers({
    cardOnClick: ({ setSelectedClient }) => (e, selectedClient) => {
      if (e) {
        e.preventDefault();
      }
      setSelectedClient(selectedClient);
    },
    goToAddClient: ({ history }) => () => {
      history.push('/editarCliente');
    },
    hideDrawer: ({ setSelectedClient }) => () => {
      setSelectedClient(null);
    },
    onBottomSheetClick: clienteBottomSheet,
  })
);

export const ClienteSearchContainer = enhance(props => (
  <GenericSearchBar {...props} />
));
