import { graphql } from '@apollo/react-hoc';
import GetClientesComPaginacaoQueryGql from './GetClientesComPaginacaoQueryGql.graphql';

export const GetClientsComPaginacaoQuery = graphql(
  GetClientesComPaginacaoQueryGql,
  {
    options: ({ textSearch, cidade, uf, localizacao }) => {
      const geoLocalizacao = {
        latitude: localizacao?.latitude,
        longitude: localizacao?.longitude,
      };
      const { latitude, longitude } = geoLocalizacao;

      return {
        variables: { cursor: 0, textSearch, cidade, uf, latitude, longitude },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
      };
    },
    props: ({ data }) => ({
      data: {
        ...data,
        loadMore: () =>
          data.fetchMore({
            variables: {
              cursor: data.clientes.cursor ? data.clientes.cursor : 0,
            },
            updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => ({
              ...previousResult,
              clientes: {
                ...fetchMoreResult.clientes,
                clientes: [
                  ...previousResult.clientes.clientes,
                  ...fetchMoreResult.clientes.clientes,
                ],
              },
            }),
          }),
      },
    }),
  }
);
