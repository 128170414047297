/* eslint  react/require-default-props: 0 */
import React from 'react';
import { Chip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Card, ValueSlider } from '@tecsinapse/ui-kit';
import PropTypes from 'prop-types';
import {
  ExcellenceClubBottomActions,
  ExcellenceClubChipContainer,
  ExcellenceClubSliderTitle,
  ExcellenceClubTitle,
  ExcellenceClubTitleContainer,
} from '../../utils/ExcellenceClubStyledComponents/ExcellenceClubStyledComponents';
import { chipStyles } from '../../../../../../Home/ActivityCard/ActivityCardStyles';
import { SeletorDeMarca } from '../../utils/SeletorDeMarca/SeletorDeMarca';
import { ExcellenceClubItem } from '../../utils/ExcellenceClubItem/ExcellenceClubItem';
import { gaEventExcellenceClubClick } from '../../../../../../utils/GAUtils';
import { TipoIndicadorExcellenceClubCard } from '../../../../../../../core/Enums';
import { CardLoading } from '../../../../../../utils/CardLoading';
import { marks } from '../utils/IndicadoresExcellenceClubDealerUtils';
import { useExecellenceClubCardStyles } from '../../utils/ExcellenceClubStyledComponents/useExecellenceClubCardStyles';
import { useCorCardStyle } from './hooks/useCorCardStyle';

const ExcellenceClubDealerCardGenerico = ({
  setMarcaDialog,
  setDataInicialDialog,
  setDataFinallDialog,
  dataInicial,
  dataFinal,
  titulo = `Indicador`,
  qtdFup48 = 0,
  qtdTD = 0,
  qtdVendas = 0,
  qtdEmplacados = 0,
  qtdPontos = 0,
  porcentagemFup48Realizado = 0,
  porcentagemTDRealizado = 0,
  qtdPorcentagemVendasxEmplacados = 0,
  slidePorcentagem = false,
  porcentagem = 0.0,
  total = 0,
  marca = {},
  marcas = [],
  setMarca,
  setTituloDialogVendedores,
  setShowVendedoresExcellenceClub,
  setDialogComponent,
  setPorcentagemDialog,
  tipoIndicadorExcellenceClubCard,
  showDialogAtividades,
  idsVendas,
  setVendedorFiltro,
  valorIncompleto = 0,
}) => {
  const classes = useExecellenceClubCardStyles({
    valorIndicadorExcellenceClub: porcentagem,
  });
  const styleCard = useCorCardStyle(porcentagem);

  const onClickFup48 = () => {
    setMarcaDialog(marca);
    setDataInicialDialog(dataInicial);
    setDataFinallDialog(dataFinal);
    gaEventExcellenceClubClick(tipoIndicadorExcellenceClubCard, 'FUP 48');
    setPorcentagemDialog(porcentagemFup48Realizado);
    setDialogComponent('IndicadorFollowUp48h');
    setTituloDialogVendedores('Follow-up 48h');
    setShowVendedoresExcellenceClub(true);
  };

  const onClickTestDrive = () => {
    setMarcaDialog(marca);
    setDataInicialDialog(dataInicial);
    setDataFinallDialog(dataFinal);
    gaEventExcellenceClubClick(tipoIndicadorExcellenceClubCard, 'Oferta TD');
    setDialogComponent('IndicadorOfertaTesteDrive');
    setTituloDialogVendedores('Oferta de Test-drive');
    setShowVendedoresExcellenceClub(true);
  };

  const onClickVendasXEmplacados = () => {
    gaEventExcellenceClubClick(tipoIndicadorExcellenceClubCard, 'Vendas');
    setVendedorFiltro(null);
    showDialogAtividades(
      {
        ids: idsVendas.filter(Boolean),
        exibirAtividadesDeAdmins: true,
      },
      `${qtdVendas} Vendas realizadas`
    );
  };

  const mensagem = (valor, valorIncompletoInput, tipo) => {
    let msg = '';
    const meta =
      tipo === TipoIndicadorExcellenceClubCard.BONUS_PERFORMANCE ? 2 : 5;

    if (valor < meta) {
      msg = `Você precisa alcançar a meta de pelo menos mais ${valorIncompletoInput} indicador(es).`;
    } else {
      msg = '';
    }

    return msg;
  };

  const mensagemActions = mensagem(
    qtdPontos,
    valorIncompleto,
    tipoIndicadorExcellenceClubCard
  );
  const mensagemSliderPorcentagemAtingida =
    porcentagem && porcentagem === 100 ? 'Atingida' : 'Não atingida';

  return (
    <Card
      styleCard={styleCard}
      singleTitle
      title={{
        components: (
          <ExcellenceClubTitleContainer>
            <ExcellenceClubTitle color={styleCard.customSubtitleColor}>
              {titulo}
            </ExcellenceClubTitle>
            <ExcellenceClubChipContainer>
              <Chip
                label="Prévia"
                size="small"
                style={chipStyles}
                classes={{
                  label: classes.chipTitle,
                }}
              />
            </ExcellenceClubChipContainer>
            <SeletorDeMarca marcas={marcas} marca={marca} setMarca={setMarca} />
          </ExcellenceClubTitleContainer>
        ),
      }}
      content={
        <Grid container spacing={0}>
          <ExcellenceClubItem
            valor={qtdFup48}
            valorPorcentagem={porcentagemFup48Realizado}
            limite={70}
            texto="FUP48"
            onClick={onClickFup48}
          />
          <ExcellenceClubItem
            valor={qtdTD}
            valorPorcentagem={porcentagemTDRealizado}
            limite={70}
            texto="Oferta TD"
            onClick={onClickTestDrive}
          />
          <ExcellenceClubItem
            valor={qtdVendas}
            limite={70}
            valorPorcentagem={qtdPorcentagemVendasxEmplacados}
            texto="Vendas"
            onClick={onClickVendasXEmplacados}
          />
          <ExcellenceClubItem
            valor={qtdEmplacados}
            limite={70}
            valorPorcentagem={null}
            texto="Emplacados"
            onClick={() => {}}
          />
        </Grid>
      }
      subContent={
        <>
          {!slidePorcentagem ? (
            <div className={classes.valueSliderContainer}>
              <ValueSlider
                colorSlider={styleCard.customSubtitleColor}
                value={qtdPontos}
                step={3}
                marks={marks}
                min={0}
                max={total}
              />
            </div>
          ) : (
            <div className={classes.porcentagemSliderContainer}>
              <ValueSlider
                colorSlider={styleCard.customSubtitleColor}
                value={porcentagem}
                defaultValue={porcentagem}
                min={0}
                max={total}
              />
              <ExcellenceClubSliderTitle>
                {mensagemSliderPorcentagemAtingida}
              </ExcellenceClubSliderTitle>
            </div>
          )}
        </>
      }
      actions={
        <ExcellenceClubBottomActions>
          {mensagemActions}
        </ExcellenceClubBottomActions>
      }
      classes={classes}
      onLoad={() => <CardLoading />}
    />
  );
};

ExcellenceClubDealerCardGenerico.propTypes = {
  marca: PropTypes.string,
  marcas: PropTypes.array,
  porcentagem: PropTypes.number,
  qtdEmplacados: PropTypes.number,
  qtdFup48: PropTypes.number,
  qtdPontos: PropTypes.number,
  porcentagemFup48Realizado: PropTypes.number,
  porcentagemTDRealizado: PropTypes.number,
  qtdPorcentagemVendasxEmplacados: PropTypes.number,
  qtdTD: PropTypes.number,
  qtdVendas: PropTypes.number,
  setDialogComponent: PropTypes.func,
  setMarca: PropTypes.func,
  setPorcentagemDialog: PropTypes.func,
  setShowVendedoresExcellenceClub: PropTypes.func,
  setTituloDialogVendedores: PropTypes.func,
  slidePorcentagem: PropTypes.bool,
  tipoIndicadorExcellenceClubCard: PropTypes.object,
  titulo: PropTypes.string,
  total: PropTypes.number,
  showDialogAtividades: PropTypes.func,
  setVendedorFiltro: PropTypes.func,
  valorIncompleto: PropTypes.number,
};

export { ExcellenceClubDealerCardGenerico };
