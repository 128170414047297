import { compose } from 'recompose';
import { graphql } from '@apollo/react-hoc';
import GetDataSistematicaQueryGql from './GetDataSistematicaQueryGql.graphql';
import { getLocalDateTimeString } from '../../../utils/dateUtils';
import { displayLoadingState } from '../../../utils/displayLoadingState';

export const queryDataSistematicaHoc = Component =>
  compose(
    graphql(GetDataSistematicaQueryGql, {
      options: ({ match, dataHoraRealizacao }) => ({
        variables: {
          id: match.params.id,
          dataHora: getLocalDateTimeString(dataHoraRealizacao),
        },
      }),
      name: 'sistematica',
    }),
    displayLoadingState({ name: 'sistematica' })
  )(Component);
