import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  compose,
  getContext,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import omit from 'lodash.omit';
import {
  getFirstFromSingleElementArrayNotNull,
  isEmptyOrNull,
  omitDeep,
} from '@tecsinapse/es-utils/build';
import PropTypes from 'prop-types';
import { getAlertContext } from '../../../utils/alertContext';
import {
  connectToFormFields,
  validateRequired,
} from '../../../utils/FormUtils';
import { reduxFormAutoFocus } from '../../../utils/reduxFormAutoFocus';
import { ClienteEditor } from './ClienteEditor';
import {
  EditClientMutation,
  editClientMutationOptions,
} from '../data/mutations/EditClientMutation';
import { EditClientQueryHoc } from '../data/queries/EditClientQuery';
import { GetDivisoesRegionaisDealerVendedorLogadoQueryHoc } from '../data/queries/GetDivisoesRegionaisDealerVendedorLogadoQuery';
import { ClientCreateOptionEnum } from '../../../../core/Enums';
import { getErrorMessage } from '../../../utils/uiUtils';
import { showClienteDuplicadoDialog } from '../../../../reducers/clienteDuplicadoDialogReducer';
import { clienteBottomSheet } from '../../clienteBottomSheet/clienteBottomSheet';
import { informarContatoClienteMutation } from '../../clienteBottomSheet/data/informarContatoClienteMutation';
import { withAtualizaModeloInativo } from '../../../AtualizaModeloInativo/withAtualizaModeloInativo';
import { withUsuarioLogado } from '../../../UsuarioLogado/withUsuarioLogado';
import { mutationsErrorsHandler } from '../../../utils/mutationsErrorsHandler';

export const postActionEditClient = ({
  values,
  usuarioLogado,
  history,
  createModal,
  action,
}) => ({ data }) => {
  if (
    isEmptyOrNull(values.id) &&
    !usuarioLogado.consultorExterno &&
    !usuarioLogado.gerente
  ) {
    createModal({
      id: data.editarCliente.id,
      clientCreateOption: ClientCreateOptionEnum.INFORM_CALL,
      message: 'Cliente criado com sucesso, deseja informar contato?',
    });
  }
  history.replace(`/editarCliente/${data.editarCliente.id}`);

  if (action !== null && action !== undefined) {
    action();
  }
};

export const mapDispatchToProps = dispatch => ({
  createClienteDuplicadoDialog: ({ message, cliente }) =>
    dispatch(showClienteDuplicadoDialog({ message, cliente })),
});

const formName = 'EditClientForm';

const enhance = compose(
  withRouter,
  withState('showInformCallModal', 'setShowInformCallModal', false),
  withState('atividades', 'setAtividades', []),
  withState('selectedClient', 'setSelectedClient', null),
  withState('showCallClientModal', 'setShowCallClientModal', false),
  withState('showInformCallModal', 'setShowInformCallModal', false),
  withState('showRouteModal', 'setShowRouteModal', false),
  withUsuarioLogado,
  EditClientQueryHoc,
  GetDivisoesRegionaisDealerVendedorLogadoQueryHoc,
  EditClientMutation,
  getContext({
    createModal: PropTypes.func,
    showCreateOpportunityModal: PropTypes.func,
    showCreateProspectionModal: PropTypes.func,
    showCreateAtividadeFichaVisitaModal: PropTypes.func,
  }),
  connect(null, mapDispatchToProps),
  withProps(
    ({
      chatData,
      editClientQuery: { cliente = {} } = {},
      usuarioLogado: { dealersVendedorLogado = [] },
      match,
    }) => {
      const values = {
        ...omit(cliente, [
          'contatos',
          'clienteFrota',
          'ultimaOportunidadeAberta',
        ]),
        idDealer:
          cliente.idDealer ||
          getFirstFromSingleElementArrayNotNull(dealersVendedorLogado).id,
      };

      if (chatData?.name) {
        values.nome = chatData.name;
      }

      if (chatData?.phone) {
        values.celular = chatData.phone;
      }

      /**
       * !! Alterar isso vai causar impacto no carregamento do cliente pela notificação de nova oportunidade
       * e na exibição do combo de dealers no cadastro de cliente.
       */
      if (match.params.id && !cliente?.id) {
        return null;
      }

      return { initialValues: values };
    }
  ),
  withAtualizaModeloInativo,
  informarContatoClienteMutation,
  withHandlers({
    notRenderIfConsultorExterno: ({ usuarioLogado }) => () =>
      !usuarioLogado?.consultorExterno,
    criarOportunidade: ({ createModal, initialValues }) => () => {
      createModal({
        id: initialValues.id,
        clientCreateOption: ClientCreateOptionEnum.OPPORTUNITY,
        message: null,
        fullWidth: true,
      });
    },
    criarProspeccao: ({ createModal, initialValues }) => () => {
      createModal({
        id: initialValues.id,
        clientCreateOption: ClientCreateOptionEnum.PROSPECTION,
        message: null,
        fullWidth: true,
      });
    },
    clearSelected: ({ setShowCallClientModal }) => () => {
      setShowCallClientModal(false);
    },
    exibirMaisOpcoes: ({ setSelectedClient }) => selectedClient => {
      setSelectedClient(selectedClient);
    },
    hideDrawer: ({ setSelectedClient }) => () => {
      setSelectedClient(null);
    },
    onBottomSheetClick: clienteBottomSheet,
  }),
  getAlertContext,
  connectToFormFields(formName, ['endereco']),
  reduxFormAutoFocus({
    // a unique name for the form
    form: formName,
    validate: validateRequired([
      'nome',
      'idDealer',
      ['cpfCnpj', 'celular', 'telefone', 'telefoneComercial', 'email'],
    ]),
    onSubmit: (
      values,
      dispatch,
      {
        editClient,
        history,
        createModal,
        showAlert,
        createClienteDuplicadoDialog,
        client,
        usuarioLogado,
        idClient,
        chatData,
        updateChat,
        chatAtivo,
      }
    ) => {
      let submitValues = omitDeep(values, '__typename');
      const originalValues = { ...values };

      if (idClient) {
        submitValues.id = idClient;
        originalValues.id = idClient;
      }

      submitValues = submitValues.endereco
        ? {
            ...submitValues,
            ...omitDeep(submitValues.endereco, 'id'),
            estadoNome: submitValues.endereco.estado
              ? submitValues.endereco.estado.sigla
              : null,
          }
        : submitValues;

      submitValues.idDivisaoRegional = submitValues.divisaoRegional
        ? submitValues.divisaoRegional.id
        : null;
      submitValues = omitDeep(submitValues, 'divisaoRegional');
      submitValues = omitDeep(submitValues, 'endereco');
      submitValues = omitDeep(submitValues, 'estado');
      submitValues = omitDeep(submitValues, 'podeRealizarAtividadeFichaVisita');
      submitValues = omitDeep(
        submitValues,
        'ultimaAtividadeFichaVisitaAbertaPk'
      );
      submitValues = omitDeep(submitValues, 'consultorExternoPk');
      submitValues = omitDeep(submitValues, 'unidadesNegocioAtendidos');
      submitValues = omitDeep(
        submitValues,
        'possuiAcessoDealerDivisoesRegionais'
      );
      submitValues = omitDeep(submitValues, 'distanciaKm');

      // se jah existe esse cliente, nao edita idDealer e idUnidadeNegocio
      if (!isEmptyOrNull(originalValues.id)) {
        delete submitValues.idUnidadeNegocio;
      }

      delete submitValues.clienteUnidadesNegocio;

      if (chatData?.chatId) {
        submitValues = {
          ...submitValues,
          chatIdClienteRecemCriado: chatData.chatId,
        };
      }

      const variables = { client: submitValues };

      return editClient({ variables })
        .then(
          postActionEditClient({
            values: originalValues,
            usuarioLogado,
            history,
            createModal,
          })
        )
        .then(() => {
          if (chatAtivo) {
            updateChat();
          }
        })
        .catch(error => {
          const regex = new RegExp(
            /Já existe\(m\) \d* cliente\(s\) com o mesmo nome e\/ou informação de contato e\/ou CPF\/CNPJ./g
          );
          const errorMessage = getErrorMessage(error.graphQLErrors);

          if (regex.test(errorMessage)) {
            createClienteDuplicadoDialog({
              message: errorMessage,
              cliente: submitValues,
            });

            return;
          }

          const description = editClientMutationOptions.description({
            variables,
            apolloClient: client,
          });

          mutationsErrorsHandler({
            showAlert,
            mutationName: editClientMutationOptions.mutationName,
            variables,
            description,
            chip: false,
          })(error);
        });
    },
  })
);

export const ClienteEditorContainer = enhance(ClienteEditor);
