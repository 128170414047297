import { Grid, MenuItem, Snackbar } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { isNotEmptyOrNull, isNotUndefOrNull } from '@tecsinapse/es-utils/build';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { makeStyles } from '@material-ui/styles';
import { Funcionalidades } from '../../../core/Enums';
import { globalStyle } from '../../app/globalStyle';
import {
  NumberField,
  RenderDatePicker,
  RenderInput,
  required,
  SubmitButton,
  SwitchField,
} from '../../utils/FormUtils';
import {
  eventOpportunitySaveVehicle,
  eventOpportunityVehicleStatus,
} from '../../utils/GAUtils';
import { currencyMask } from '../../utils/Masks';
import { RenderSelectField } from '../../utils/select/RenderSelectField';
import {
  RenderAppLogo,
  ShowBackButton,
  updateAppTitle,
} from '../../utils/uiUtils';
import { useMarca } from '../components/useMarca';

export const snackStyle = theme => ({
  snackbar: {
    top: 56,
  },
  snackbarContent: {
    width: 360,
  },
  moveUp: {
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  moveDown: {
    paddingTop: '52px',
    transform: 'translate3d(0, 0, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  snackbarFragment: {
    display: 'flex',
    alignContent: 'center',
  },
  ...globalStyle(theme),
});

const useSnackStyle = makeStyles(snackStyle);

export const OpportunityVehiclesEditor = ({
  handleSubmit,
  history,
  activePriceTable: { tabelasPreco = [] } = {},
  userHasRole,
  priceTable,
  modeloMap,
  modeloVersaoMap,
  versaoVarianteMap,
  familiasFiltradasPorMarca,
  idModelo,
  idFamilia,
  idVersao,
  idTabelaPreco,
  anoModelo,
  anoFabricacaoAnoModelo,
  onChangeAnoModelo,
  onChangeModelo,
  onChangeFamilia,
  onChangeVersao,
  validateMoney,
  initialValues: { disabledForm, disabledPrice, disableStatus },
  onVarianteChange,
  formName = null,
  tabelaInativa,
  modeloInteresse,
  varianteLabel,
  versaoLabel,
  modeloLabel,
  exibeCor,
  exibeInterior,
  utilizaModelo,
  utilizaFamilia,
  utilizaVariante,
  utilizaVersao,
  crmAnoModeloAtivo,
  anosModeloMap,
  filtrarVersaoPorAnoFabricacaoEAnoModelo,
  variant,
  submitLabel = 'Salvar',
  formStyle = {},
  containerStyle = {},
  obrigaVariante,
  getOpportunitySegmento: {
    oportunidade,
    oportunidade: { id: idOportunidadeQuery } = {},
  } = {},
  idMarca,
  idOportunidade,
  setGraphqlOptions,
  usuarioLogado,
  ...rest
}) => {
  const {
    exibirMarca,
    dataMarcas,
    verificarExibirMarca,
    buscaMarca,
  } = useMarca();

  const classes = useSnackStyle();

  useEffect(() => {
    verificarExibirMarca(idOportunidadeQuery);
  }, [idOportunidadeQuery, verificarExibirMarca]); // eslint-disable-line

  useEffect(() => {
    if (exibirMarca) {
      buscaMarca(idOportunidadeQuery);
    }
  }, [exibirMarca, buscaMarca, idOportunidadeQuery]); // eslint-disable-line

  const tabelasPrecoToList =
    tabelaInativa && variant !== 'embedded'
      ? [modeloInteresse.modeloInteresse.tabelaPreco]
      : tabelasPreco;

  const isEmbedded = variant === 'embedded';
  const exibeDescricao = !isEmbedded;

  const existeVariante =
    idVersao &&
    versaoVarianteMap &&
    versaoVarianteMap[idVersao] &&
    versaoVarianteMap[idVersao].filter(
      v => parseInt(v.ano, 10) === parseInt(anoModelo, 10)
    ).length > 0;

  const variantes =
    versaoVarianteMap &&
    versaoVarianteMap[idVersao] &&
    Object.values(versaoVarianteMap[idVersao])
      .sort((a, b) => a.nome.localeCompare(b.nome))
      .filter(({ ano }) => {
        if (!isNotUndefOrNull(anoModelo)) {
          return true;
        }

        return ano === parseInt(anoModelo, 10);
      });

  return (
    <>
      {!isEmbedded && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={classes.snackbar}
          open={tabelaInativa}
          message={
            <div className={classes.snackbarFragment}>
              <ErrorIcon className={classes.marginRight10} />
              <span id="message-id">
                Edição Bloqueada - Tabela inativa: Remova o veículo caso queira
                usar nova tabela
              </span>
            </div>
          }
          ContentProps={{
            'aria-describedby': 'snackbar-fab-message-id',
            className: classes.snackbarContent,
          }}
        />
      )}

      <div
        className={
          tabelaInativa && disabledForm ? classes.moveDown : classes.moveUp
        }
      />

      <form
        onSubmit={handleSubmit}
        className={classes.form}
        name={formName}
        style={formStyle}
      >
        <RenderAppLogo />
        {updateAppTitle('Veículo de Interesse')}
        <ShowBackButton history={history} />
        <Grid container style={containerStyle}>
          {!isEmbedded && (
            <>
              <Grid item xs={6}>
                <Field
                  name="quantidade"
                  label="Quantidade"
                  disabled={disabledForm}
                  min={1}
                  component={NumberField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="emNegociacao"
                  label="Em Negociação"
                  component={SwitchField}
                  disabled={disableStatus || disabledForm}
                  fullWidth
                  onChange={eventOpportunityVehicleStatus}
                />
              </Grid>
            </>
          )}

          {oportunidade.utilizaTabelaPreco && (
            <Grid item xs={12}>
              <Field
                name="idTabelaPreco"
                label="Tabela de Preços"
                component={RenderSelectField}
                disabled={disabledForm}
                fullWidth
                onChange={e => {
                  setGraphqlOptions({ idTabelaPreco: e });
                }}
              >
                <MenuItem value={null}>Selecione...</MenuItem>
                {tabelasPrecoToList.map(({ id, descricao }) => (
                  <MenuItem value={id} key={id}>
                    {descricao}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}

          {exibirMarca && !dataMarcas.loading && (
            <Grid item xs={12}>
              <Field
                name="idMarca"
                label="Marca"
                component={RenderSelectField}
                disabled={disabledForm}
                fullWidth
              >
                {(dataMarcas.data?.marcasModeloInteresse || []).map(
                  ({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  )
                )}
              </Field>
            </Grid>
          )}

          {utilizaModelo && (
            <Grid item xs={isEmbedded ? 12 : 6}>
              <Field
                name="idModelo"
                label={isEmbedded ? `Novo ${modeloLabel}` : modeloLabel}
                component={RenderSelectField}
                onChange={onChangeModelo}
                disabled={disabledForm}
                fullWidth
              >
                <MenuItem value={null}>Selecione...</MenuItem>
                {modeloMap &&
                  Object.values(modeloMap)
                    .filter(
                      m =>
                        (exibirMarca &&
                          isNotUndefOrNull(idMarca) &&
                          idMarca === m.marca?.id) ||
                        !exibirMarca
                    )
                    .sort((a, b) => a.nome.localeCompare(b.nome))
                    .map(({ id, nome }) => (
                      <MenuItem value={id} key={id}>
                        {nome}
                      </MenuItem>
                    ))}
              </Field>
            </Grid>
          )}
          {utilizaFamilia && (
            <Grid item xs={isEmbedded ? 12 : 6}>
              <Field
                name="idFamilia"
                label="Série"
                component={RenderSelectField}
                onChange={onChangeFamilia}
                disabled={disabledForm}
                fullWidth
              >
                <MenuItem value={null}>Selecione...</MenuItem>

                {familiasFiltradasPorMarca &&
                  Object.values(familiasFiltradasPorMarca)
                    .sort((a, b) => a.nome.localeCompare(b.nome))
                    .map(({ id, nome }) => (
                      <MenuItem value={id} key={id}>
                        {nome}
                      </MenuItem>
                    ))}
              </Field>
            </Grid>
          )}
          {crmAnoModeloAtivo && (
            <Grid item xs={isEmbedded ? 12 : 6}>
              <Field
                name="anoFabricacaoAnoModelo"
                label="Ano Modelo"
                component={RenderSelectField}
                onChange={onChangeAnoModelo}
                disabled={disabledForm}
                fullWidth
              >
                <MenuItem value={null}>Selecione...</MenuItem>
                {(anosModeloMap || [])
                  .filter(
                    a => parseInt(a.modeloId, 10) === parseInt(idModelo, 10)
                  )
                  .map(({ anoModelo: anoFabricacaoEModelo }) => (
                    <MenuItem
                      value={anoFabricacaoEModelo}
                      key={anoFabricacaoEModelo}
                    >
                      {anoFabricacaoEModelo}
                    </MenuItem>
                  ))}
              </Field>
            </Grid>
          )}
          <Grid item xs={isEmbedded ? 12 : 6}>
            <Field
              name="idVersao"
              label={isEmbedded ? `Nova ${versaoLabel}` : versaoLabel}
              onChange={onChangeVersao}
              component={RenderSelectField}
              disabled={disabledForm}
              fullWidth
              validate={utilizaVersao ? [required] : undefined}
            >
              <MenuItem value={null}>Selecione...</MenuItem>
              {idModelo &&
                modeloVersaoMap &&
                modeloVersaoMap[idModelo] &&
                Object.values(modeloVersaoMap[idModelo])
                  .filter(v => {
                    return filtrarVersaoPorAnoFabricacaoEAnoModelo(
                      v,
                      anoFabricacaoAnoModelo,
                      !tabelaInativa
                    );
                  })
                  .sort((a, b) => a.nome.localeCompare(b.nome))
                  .map(({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  ))}
              {idFamilia &&
                modeloVersaoMap &&
                modeloVersaoMap[idFamilia] &&
                Object.values(modeloVersaoMap[idFamilia]).map(
                  ({ id, nome }) => (
                    <MenuItem value={id} key={id}>
                      {nome}
                    </MenuItem>
                  )
                )}
            </Field>
          </Grid>

          {!isEmbedded && (
            <Grid item xs={6} hidden>
              <Field
                name="anoFabricacao"
                textMask="year"
                label="Ano de Fabricação"
                disabled={disabledForm}
                component={RenderInput}
                format={null}
                fullWidth
              />
            </Grid>
          )}

          {utilizaVariante && (
            <Grid item xs={isEmbedded ? 12 : 6}>
              <Field
                name="idVariante"
                label={isEmbedded ? `Novo ${varianteLabel}` : varianteLabel}
                component={RenderSelectField}
                disabled={disabledForm}
                required={obrigaVariante && existeVariante}
                validate={
                  obrigaVariante && existeVariante ? [required] : undefined
                }
                fullWidth
                onChange={onVarianteChange}
              >
                <MenuItem value={null}>Selecione...</MenuItem>
                {idVersao &&
                  versaoVarianteMap &&
                  versaoVarianteMap[idVersao] &&
                  variantes.map(
                    ({ id, nome, ano }) =>
                      ano === parseInt(anoModelo, 10) && (
                        <MenuItem value={id} key={id}>
                          {nome}
                        </MenuItem>
                      )
                  )}
              </Field>
            </Grid>
          )}

          {!isEmbedded && (
            <>
              <Grid item xs={6}>
                <Field
                  name="precoUnitario"
                  label="Preço Unitário"
                  component={RenderInput}
                  required
                  validate={validateMoney}
                  disabled={
                    (isNotEmptyOrNull(idTabelaPreco) && disabledPrice) ||
                    disabledForm
                  }
                  fullWidth
                  {...currencyMask()}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="previsaoAquisicao"
                  label="Previsão Aquisição"
                  minDate={moment()}
                  disabled={disabledForm}
                  fullWidth
                  component={RenderDatePicker}
                />
              </Grid>
            </>
          )}

          {exibeCor && !isEmbedded && (
            <Grid item xs={12}>
              <Field
                name="corInteresse"
                label="Cor"
                disabled={disabledForm}
                fullWidth
                component={RenderInput}
              />
            </Grid>
          )}

          {exibeInterior && !isEmbedded && (
            <Grid item xs={12}>
              <Field
                name="interiorVeiculo"
                label="Interior"
                disabled={disabledForm}
                fullWidth
                component={RenderInput}
              />
            </Grid>
          )}

          {exibeDescricao && (
            <Grid item xs={12}>
              <Field
                name="descricao"
                label="Descrição"
                disabled={
                  disabledForm ||
                  !userHasRole(
                    Funcionalidades.CRM_PARAMETRIZACOES_DEALER_CONFIGURAR_PARAMETROS_PROPOSTA
                  )
                }
                fullWidth
                multiline
                component={RenderInput}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <SubmitButton
              fullWidth
              disabled={disabledForm}
              label={submitLabel}
              onClick={eventOpportunitySaveVehicle}
              {...rest}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
