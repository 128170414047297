import React, { useReducer } from 'react';

const DEFAULT_STATE = {
  chatAtivo: false,
  time: new Date(),
};

const chatStore = React.createContext(DEFAULT_STATE);
const { Provider } = chatStore;

const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer((chatState, action) => {
    switch (action.type) {
      case 'active':
        return {
          ...chatState,
          chatAtivo: true,
        };
      case 'update':
        return {
          ...chatState,
          time: new Date(),
        };

      default:
        throw new Error();
    }
  }, DEFAULT_STATE);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { chatStore, ChatProvider };
