import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

export const GerenteAlert = ({ show = false, closeAlert }) => {
  return (
    <Dialog
      open={show}
      onClose={closeAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Informação</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você não é responsável por este cliente. Para informar o contato, por
          favor acesse a versão web.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAlert} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
