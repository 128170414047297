import PropTypes from 'prop-types';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { ListItemIcon } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ListItemText from '@material-ui/core/ListItemText';

const ErrorMessages = React.memo(
  ({
    msgErro,
    showVeiculosInteresseSemModelo,
    showPagamentoPendente,
    possuiVeiculoComPrecoUnitarioNaoInformado,
  }) => {
    const veiculosInteresseText = 'Veículo(s) de Interesse';
    const opcoesPagamentoText = 'Forma(s) de Pagamento';
    const possuiVeiculoComPrecoUnitarioNaoInformadoText =
      'Possui modelo com preço unitário não informado';

    const items = [];

    if (msgErro) {
      items.push(msgErro);
    }

    if (!msgErro && showVeiculosInteresseSemModelo) {
      items.push(veiculosInteresseText);
    }

    if (!msgErro && showPagamentoPendente) {
      items.push(opcoesPagamentoText);
    }

    if (possuiVeiculoComPrecoUnitarioNaoInformado) {
      items.push(possuiVeiculoComPrecoUnitarioNaoInformadoText);
    }

    return (items || ['Algum erro aconteceu']).map(text => (
      <ListItem key={text} role={undefined} dense button>
        <ListItemIcon>
          <WarningIcon />
        </ListItemIcon>
        <ListItemText primary={text} data-testid="list-item" />
      </ListItem>
    ));
  }
);

ErrorMessages.propTypes = {
  // eslint-disable-next-line react/require-default-props
  msgErro: PropTypes.string,
  possuiVeiculoComPrecoUnitarioNaoInformado: PropTypes.bool.isRequired,
  showPagamentoPendente: PropTypes.bool.isRequired,
  showVeiculosInteresseSemModelo: PropTypes.bool.isRequired,
};

export { ErrorMessages };
