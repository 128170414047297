import { graphql } from '@apollo/react-hoc';
import { compose, withProps } from 'recompose';
import { displayLoadingState } from '../../../utils/displayLoadingState';
import { shouldShowSegments } from '../../../Atividade/atividadeFunctions';
import GetSegmentosVendaAtivosQueryGql from './GetSegmentosVendaAtivosQueryGql.graphql';

const hoc = graphql(GetSegmentosVendaAtivosQueryGql, {
  name: 'segmentos',
  options: {
    fetchPolicy: 'cache-first',
  },
});

export const withSegmentosVendaAtivosQuery = compose(
  hoc,
  displayLoadingState({
    name: 'segmentos',
    onCacheLoadOnlyQueryName: 'segmentosVendaAtivos',
  }),
  withProps(({ segmentos: { segmentosVendaAtivos = [] } = {} }) => ({
    showSegments: shouldShowSegments(segmentosVendaAtivos),
    idSegmentoUnico:
      segmentosVendaAtivos && segmentosVendaAtivos.length === 1
        ? segmentosVendaAtivos[0].id
        : null,
  }))
);
