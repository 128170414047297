import { Button } from '@tecsinapse/ui-kit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import styled from 'styled-components';
import { AppThemeProvider } from '../../../core/AppTheme';

const ConfirmationDialog = ({
  confirmation,
  show,
  proceed,
  dismiss,
  cancel,
}) => (
  <AppThemeProvider>
    <Dialog open={show} onClose={dismiss}>
      <DialogTitle id="alert-dialog-title"> Você tem certeza?</DialogTitle>
      <Message>{confirmation}</Message>
      <DialogActions>
        <Button
          onClick={() => cancel('arguments will be passed to the callback')}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => proceed('same as cancel')}
          color="primary"
          autoFocus
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  </AppThemeProvider>
);

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const ConfirmableConfirmationDialog = confirmable(ConfirmationDialog);

export const confirm = createConfirmation(ConfirmableConfirmationDialog);

const Message = styled(DialogContentText)`
  padding: 0 20px;
`;
