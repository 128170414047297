import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

function CheckboxListComponent({
  classes,
  options,
  selected,
  handleToggle,
  renderCheckbox = true,
  optionSecundaryIconFunc,
}) {
  return (
    <div className={classes.root}>
      <List>
        {options.map(({ id, description, secondary, ...rest }) => (
          <ListItem
            key={id}
            role={undefined}
            dense
            button
            onClick={handleToggle(id)}
          >
            {renderCheckbox && (
              <Checkbox
                checked={selected.indexOf(id) !== -1}
                tabIndex={-1}
                disableRipple
              />
            )}
            <ListItemText primary={description} secondary={secondary} />
            {optionSecundaryIconFunc && optionSecundaryIconFunc(rest)}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export const CheckboxList = withStyles(styles)(CheckboxListComponent);
