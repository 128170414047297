import { IndicadorFollowUp48h } from '../../IndicadoresExcellenceClubVendedor/IndicadorFollowUp48h/IndicadorFollowUp48h';
import { ActivityCard } from '../../../../../../Home/ActivityCard/ActivityCard';
import { IndicadorOfertaTestDrive } from '../../IndicadoresExcellenceClubVendedor/IndicadorOfertaTesteDrive/IndicadorOfertaTestDrive';

export const componentePorTipo = component => {
  switch (component) {
    case 'IndicadorFollowUp48h':
      return {
        Component: IndicadorFollowUp48h,
        ordenacaoPadraoLista: (indicadorB, indicadorA) => {
          if (indicadorA.totalFup48 === 0 && indicadorA.pendentesFup48 === 0) {
            return -1;
          }

          const number = indicadorA.porcentagemFup - indicadorB.porcentagemFup;

          const number1 =
            number === 0
              ? indicadorA.totalFup48 - indicadorB.totalFup48
              : number;

          const number2 =
            number1 === 0
              ? indicadorB.pendentesFup48 - indicadorA.pendentesFup48
              : number1;

          return number2;
        },
      };

    case 'ActivityCard':
      return {
        Component: ActivityCard,
        ordenacaoPadraoLista: () => 0,
      };
    case 'IndicadorOfertaTesteDrive':
    default:
      return {
        Component: IndicadorOfertaTestDrive,
        ordenacaoPadraoLista: (indicadorB, indicadorA) => {
          if (indicadorA.totalTD === 0 && indicadorA.pendentesTD === 0) {
            return -1;
          }

          const number = indicadorA.porcentagemTD - indicadorB.porcentagemTD;
          const number1 =
            number === 0 ? indicadorA.totalTD - indicadorB.totalTD : number;

          const number2 =
            number1 === 0
              ? indicadorB.pendentesTD - indicadorA.pendentesTD
              : number1;

          return number2;
        },
      };
  }
};
