import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../../../utils/graphql/graphqlOfflineMutation';
import AtividadeFragment from '../../../Atividade/AtividadePosRealizacaoAtividadeFragment.graphql';

export const realizarAtividadeMutationGql = gql`
  mutation realizarAtividade($atividade: RealizacaoAtividade!) {
    realizarAtividade(atividade: $atividade) {
      ...AtividadePosRealizacaoAtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

export const criarERealizarAtividadeMutationGql = gql`
  mutation criarERealizarAtividade($atividade: RealizacaoAtividade!) {
    criarERealizarAtividade(atividade: $atividade) {
      ...AtividadePosRealizacaoAtividadeFragment
    }
  }
  ${AtividadeFragment}
`;

export const genericRealizarAtividadeMutation = ({
  mutationGql,
  name,
  mutationName,
}) =>
  graphqlOfflineMutation(mutationGql, {
    name,
    mutationName,
    idName: 'idAtividade',
    entityName: 'atividade',
    savedEntityName: 'atividade',
    description: async ({
      variables: {
        atividade: { idAtividade },
      },
      apolloClient,
    }) => {
      try {
        const data = await apolloClient.readFragment({
          id: `Atividade:${idAtividade}`,
          fragment: gql`
            fragment RealizacaoAtividadeOffline on Atividade {
              id
              cliente {
                id
                nome
              }
            }
          `,
        });
        const {
          cliente: { nome },
        } = data;

        return `Realização de atividade de cliente com nome: ${nome}.`;
      } catch (e) {
        return `Realização de atividade.`;
      }
    },
  });

export const realizarAtividadeMutation = genericRealizarAtividadeMutation({
  mutationGql: realizarAtividadeMutationGql,
  name: 'realizarAtividade',
  mutationName: 'realizarAtividadeMutationGql',
});

export const criarERealizarAtividadeMutation = genericRealizarAtividadeMutation(
  {
    mutationGql: criarERealizarAtividadeMutationGql,
    name: 'criarERealizarAtividade',
    mutationName: 'criarERealizarAtividadeMutationGql',
  }
);
