import {
  mdiAlertCircle,
  mdiBusSide,
  mdiCarSide,
  mdiTractor,
  mdiTractorVariant,
  mdiTruck,
  mdiVanPassenger,
} from '@mdi/js';

const Segmentos = {
  CAMINHAO: 'caminhao',
  VAN: 'van',
  ONIBUS: 'onibus',
  MAQUINAS: 'maquinas',
  TRATOR: 'trator',
  AUTOS: 'autos',
};

const iconesMap = new Map();

iconesMap.set(Segmentos.CAMINHAO, mdiTruck);
iconesMap.set(Segmentos.VAN, mdiVanPassenger);
iconesMap.set(Segmentos.ONIBUS, mdiBusSide);
iconesMap.set(Segmentos.MAQUINAS, mdiTractor);
iconesMap.set(Segmentos.TRATOR, mdiTractorVariant);
iconesMap.set(Segmentos.AUTOS, mdiCarSide);

export const getIconeSegmento = descricao => {
  if (iconesMap.has(descricao)) {
    return iconesMap.get(descricao);
  }

  return mdiAlertCircle;
};

export const procuraRelacaoSegmento = nome => {
  if (nome.includes('van')) {
    return Segmentos.VAN;
  }

  if (nome.includes('caminh')) {
    return Segmentos.CAMINHAO;
  }

  if (nome.includes('carro') || nome.includes('auto')) {
    return Segmentos.AUTOS;
  }

  if (nome.includes('onibus')) {
    return Segmentos.ONIBUS;
  }

  if (nome.includes('implementos')) {
    return 'implementos';
  }

  return '';
};
