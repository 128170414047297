import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  selectedRoute: null,
};

export const setSelectedRoute = createAction('SET_SELECTED_ROUTE');

export const routesReducer = handleActions(
  {
    [setSelectedRoute]: (state, { payload }) => ({
      ...state,
      selectedRoute: payload,
    }),
  },
  defaultState
);
