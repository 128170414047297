import React from 'react';
import { NotificacaoCardBase } from '../NotificacaoCardBase';

export const TopicoLembreteAtividade = {
  topicoURI: '/lembrete-atividade-app',

  onClick: (notificacao, hook, history) => {
    hook.handleMarcarComoVisualizado([notificacao.pk]);
    history.push(`/realizarAtividade/${notificacao.relacionamentoId}`);
  },

  renderizar: notificacao => {
    const { visualizado } = notificacao;
    const { nomeCliente, descricao, dataHora } = notificacao.payload || {};

    return (
      <NotificacaoCardBase
        titulo={nomeCliente}
        descricao={descricao}
        dataHora={dataHora}
        visualizado={visualizado}
      />
    );
  },
};
