import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withState,
  getContext,
  withHandlers,
} from 'recompose';
import { informarContatoClienteMutation } from 'components/Client/clienteBottomSheet/data/informarContatoClienteMutation';
import { withAtualizaModeloInativo } from 'components/AtualizaModeloInativo/withAtualizaModeloInativo';
import { CheckInConfirmacaoComponent } from './CheckInConfirmacaoComponent';
import { EditClientQueryHoc } from '../../Client/client/data/queries/EditClientQuery';
import { createRegistrarCheckInMutation } from '../data/mutations/createRegistrarCheckInMutationGpl';
import { createFichaVisitaAtividadeMutation } from '../../opportunity/create/ClientCreateOptionsMutations';

export const CheckInCEConfirmacaoContainer = compose(
  withProps(({ match }) => ({
    idClient: match.params.idCliente,
    titleButtonRealizarAtividade: 'Registrar Visita',
  })),
  EditClientQueryHoc,
  createRegistrarCheckInMutation,
  createFichaVisitaAtividadeMutation,
  withState('loading', 'setLoading', false),
  informarContatoClienteMutation,
  withAtualizaModeloInativo,
  getContext({
    createModal: PropTypes.func,
  }),
  withHandlers({
    handleRealizarAtividade: ({
      editClientQuery: { cliente },
      criarFichaVisitaAtividade,
      history,
    }) => () => {
      const { ultimaAtividadeFichaVisitaAbertaPk } = cliente;
      const retornoInformado = history.location.pathname.split('/')[3];
      const retornarPara = retornoInformado || 'checkIn';

      if (ultimaAtividadeFichaVisitaAbertaPk) {
        return history.push(
          `/realizarAtividade/${ultimaAtividadeFichaVisitaAbertaPk}/${retornarPara}`
        );
      }

      const variables = { idCliente: cliente.id };

      return criarFichaVisitaAtividade({
        variables,
      }).then(
        ({
          data: {
            fichaVisitaAgendarAtividade: { id },
          },
        }) => {
          history.push(`/realizarAtividade/${id}/${retornarPara}`);
        }
      );
    },
  })
)(CheckInConfirmacaoComponent);
