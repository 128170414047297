import { useCallback, useEffect } from 'react';
import { useNotificadorHook } from './NotificadorHook';
import { TOPICOS } from './topicos/Topicos';

let clicouNoCard = false;
let notificacaoPks = [];

export const useNotificacoesContainer = history => {
  const hook = useNotificadorHook();

  const {
    notificacoesEnviadas,
    notificacoesNaoVisualizadas,
    handleMarcarComoVisualizado,
  } = hook;

  const handleNotificacaoClick = useCallback(
    notificacao => {
      clicouNoCard = true;
      const topico = TOPICOS.get(notificacao.topicoNotificador);

      topico.onClick && topico.onClick(notificacao, hook, history);
    },
    [history, hook]
  );

  useEffect(() => {
    notificacaoPks = notificacoesNaoVisualizadas.map(
      notificacao => notificacao.pk
    );
  }, [notificacoesNaoVisualizadas]);

  useEffect(() => {
    return () => {
      if (!clicouNoCard && history.location.pathname !== '/notificacoes') {
        notificacaoPks.length > 0 &&
          handleMarcarComoVisualizado(notificacaoPks);
      }
      clicouNoCard = false;
    };
  }, [handleMarcarComoVisualizado, history]);

  return {
    notificacoesEnviadas,
    possuiNotificacoes: notificacoesEnviadas.length > 0,
    handleNotificacaoClick,
  };
};
