import gql from 'graphql-tag';
import { graphqlOfflineMutation } from '../utils/graphql/graphqlOfflineMutation';

export const registrarAcesso = gql`
  mutation registrarAcessoMutationGql($acessoRegistro: RegistrarAcessoInput!) {
    registrarAcesso(acessoRegistro: $acessoRegistro)
  }
`;

export const RegistrarAcessoMutation = graphqlOfflineMutation(registrarAcesso, {
  name: 'registrarAcesso',
  mutationName: 'registrarAcessoMutationGql',
  customErrorsHandler: false,
  showSucessAlert: false,
});
