import { isNotEmptyOrNull } from '@tecsinapse/es-utils/build';

export const getTrimestre = datemoment => (datemoment.month() + 1) / 3;

export const getLocalDateTimeString = date =>
  isNotEmptyOrNull(date) ? date.format('YYYY-MM-DD[T]HH:mm:ss') : null;
export const getLocalDateTimeStringNoSeconds = date =>
  isNotEmptyOrNull(date) ? date.format('YYYY-MM-DD[T]HH:mm:00') : null;
export const getLocalTimeString = date =>
  isNotEmptyOrNull(date) ? date.format('HH:mm') : null;
export const getLocalDateString = date =>
  isNotEmptyOrNull(date) ? date.format('YYYY-MM-DD') : null;
export const getLocalDateBrString = date =>
  isNotEmptyOrNull(date) ? date.format('DD/MM/YYYY') : null;
export const getLocalDateBrTracoString = date =>
  isNotEmptyOrNull(date) ? date.format('DD-MM-YYYY') : null;
export const getLocalDateTimeBrazilString = date =>
  isNotEmptyOrNull(date) ? date.format('DD/MM/YYYY HH:mm:ss') : null;
