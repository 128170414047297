import { useContext } from 'react';
import { UsuarioLogadoContext } from '../components/UsuarioLogado/UsuarioLogadoContext';

export const useUsuarioLogado = () => {
  const { usuarioLogado } = useContext(UsuarioLogadoContext);

  return {
    usuarioLogado,
    marcas: usuarioLogado?.marcas,
    usuarioGerente: usuarioLogado?.gerente,
    usuarioAdmin: usuarioLogado?.administrador,
    usuarioVendedor:
      usuarioLogado?.vendedorCrm &&
      !(usuarioLogado.gerente || usuarioLogado.administrador),
    usuarioConsultorExterno: usuarioLogado?.consultorExterno,
    exibeIndicadores: !usuarioLogado?.consultorExterno,
    usuarioInterno:
      usuarioLogado?.email &&
      usuarioLogado.email.includes('@tecsinapse.com.br'),
    exibeRoteirizacao: usuarioLogado && usuarioLogado.roteirizacaoV2,
  };
};
