import React, { useCallback, useState } from 'react';
import { EmptyState } from '@tecsinapse/ui-kit/build/components/EmptyState/EmptyState';
import { FilterList, ThumbDown } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/styles';
import Icon from '@mdi/react';
import { mdiFilterVariant } from '@mdi/js';
import { useMediaQuery } from '@material-ui/core';
import { Button } from '@tecsinapse/ui-kit/build/components/Buttons/Button';
import Typography from '@material-ui/core/Typography';
import { CheckInCard } from './CheckInCard/CheckInCard';
import { CheckInFilterBottomSheet } from './CheckInFilterBottomSheet/CheckInFilterBottomSheet';
import { CheckInOrdenacao } from './enum/CheckInOrdenacaoEnum';
import InfiniteScroll from './CheckInInfiniteScroll/CheckInInfiniteScroll';
import { checkInListStyles } from './styles';
import { useCheckIns } from './data/useCheckIns';
import { FloatingButton } from './FloatingButton/FloatingButton';
import { useUsuarioLogado } from './data/useUsuarioLogado';

const useCheckInListStyles = makeStyles(checkInListStyles);

export const CheckInList = ({
  checkInSort = CheckInOrdenacao.MAIS_RECENTES.name,
  checkInApenasComImagem = false,
  checkInApenasVisitaRegistrada = false,
  checkInConsultorExterno = null,
  checkInDealer = null,
  history = null,
  setCheckInFiltro = null,
  criarFichaVisitaAtividade = null,
  isCheckInMobile = false,
  useWindowInfiniteScroll = false,
  modulo = null,
  vendedores = null,
  labelFiltroAtividadeRealizada = null,
  labelFiltroVendedores = null,
  labelAtividadeRealizada = null,
  labelButtonRealizarAtividade = null,
  handleRealizarAtividade,
}) => {
  const classes = useCheckInListStyles();
  const filter = {
    checkInSort,
    checkInApenasComImagem,
    checkInApenasVisitaRegistrada,
    checkInConsultorExterno,
    checkInDealer,
    modulo,
  };

  setCheckInFiltro(
    checkInSort,
    checkInApenasComImagem,
    checkInApenasVisitaRegistrada,
    checkInConsultorExterno,
    checkInDealer
  );

  const { checkIns, loadMore, loading, hasNext } = useCheckIns(filter);
  const { usuarioLogado = {} } = useUsuarioLogado();

  const [checkInFilterOpen, setCheckInFilterOpen] = useState(false);
  const handleCloseCheckInFilter = useCallback(() => {
    setCheckInFilterOpen(false);
  }, [setCheckInFilterOpen]);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <>
      {!isMobile && (
        <div className={classes.filterDiv}>
          <Button
            id="checkInFiltros"
            customVariant="warning"
            type="button"
            size="small"
            onClick={() => setCheckInFilterOpen(true)}
          >
            <Icon
              path={mdiFilterVariant}
              size={1}
              color="white"
              className={classes.filterIcon}
            />
            <Typography className={classes.filterTitle}>Filtros</Typography>
          </Button>
        </div>
      )}
      <InfiniteScroll
        pageStart={0}
        useCapture
        loadMore={loadMore}
        hasMore={hasNext}
        isLoading={loading}
        initialLoad={false}
        useWindow={useWindowInfiniteScroll}
        loader={
          <CircularProgress
            color="secondary"
            className={classes.circularProgress}
          />
        }
      >
        {checkIns.length ? (
          checkIns.map(checkIn => (
            <CheckInCard
              key={checkIn.id}
              checkIn={checkIn}
              history={history}
              criarFichaVisitaAtividade={criarFichaVisitaAtividade}
              isCheckInMobile={isCheckInMobile}
              usuarioLogado={usuarioLogado}
              labelAtividadeRealizada={labelAtividadeRealizada}
              labelButtonRealizarAtividade={labelButtonRealizarAtividade}
              handleRealizarAtividade={handleRealizarAtividade}
            />
          ))
        ) : (
          <EmptyState
            IconComponent={ThumbDown}
            message="Não há nenhum Check-In por aqui ainda."
          />
        )}
      </InfiniteScroll>

      {isMobile && (
        <FloatingButton
          icon={<FilterList />}
          onClick={() => setCheckInFilterOpen(true)}
        />
      )}

      <CheckInFilterBottomSheet
        open={checkInFilterOpen}
        onClose={handleCloseCheckInFilter}
        checkInSort={checkInSort}
        checkInApenasComImagem={checkInApenasComImagem}
        checkInApenasVisitaRegistrada={checkInApenasVisitaRegistrada}
        checkInConsultorExterno={checkInConsultorExterno}
        checkInDealer={checkInDealer}
        setCheckInFiltro={setCheckInFiltro}
        vendedores={vendedores}
        labelFiltroAtividadeRealizada={labelFiltroAtividadeRealizada}
        labelFiltroVendedores={labelFiltroVendedores}
      />
    </>
  );
};
